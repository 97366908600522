import React, { useEffect, useState } from 'react'
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios"
// import { useNavigate, useParams } from 'react-router-dom';
import Sessions from './Components/Sessions';
import Assessments from './Components/Assessments';
import Assignments from './Components/Assignments';

const ModuleComp = ({ module, setModuleState, setShowLockSessions, showLockSessions }) => {
    const [sessions, setSessions] = useState([]);
    const [assignment, setAssignment] = useState(null);
    const [assessment, setAssessment] = useState(null);
    const [showSessions, setShowSessions] = useState(false);
    const { setSessionExpire } = useAuth();
    // const navigate = useNavigate()
    // const { courseid, programid } = useParams()

    const getSessionByModule = async () => {
        await Axios({
            url: `/api/mentorModules/sessions/module/${module._id}`,
            method: "get",
        }).then((res) => {

            setSessions(res.data.session);
            setAssessment(res.data.assessment);
            setAssignment(res.data.assignment);
            setShowLockSessions(false)
            // setModules(res.data.mod);
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }



    useEffect(() => {
        getSessionByModule();
    }, [])

    useEffect(() => {
        getSessionByModule();
    }, [showLockSessions])


    return <div className="userCourses_tabs">
        <div className="userCourses_tabs_module pl-4 pt-2 pb-2 point" onClick={() => {
            setShowSessions(!showSessions);
            setShowLockSessions(true);
        }}>
            <p className="subtitle color_dark_blue">{module.title}</p>
            <p className="overline color_light_gret">
                <span>{sessions && sessions.length}</span>
                {/* <span>|</span> */}
                {/* <span>2 hr 20 min</span> */}
            </p>
        </div>
        {
            showSessions ? <div className="userCourses_tabs_sessions_main">
                {
                    sessions && sessions.length > 0 ?
                        sessions.map((session, i) => <Sessions
                            session={session}
                            key={i}
                            module={module}
                            setModuleState={setModuleState}
                            getSessionByModule={getSessionByModule}
                            isLast = {(assessment !== null || assignment !== null )? false : true }
                            i={i}
                            length = {sessions.length}
                        />) : null
                }
                {
                    assessment && assessment !== null ?
                        <Assessments
                            assessment={assessment}
                            module={module}
                            setModuleState={setModuleState}
                            getSessionByModule={getSessionByModule}
                            isLast = {(assignment !== null )? false : true }
                        />
                        : null
                }
                {
                    assignment && assignment !== null ?
                        <Assignments
                            assignment={assignment}
                            module={module}
                            setModuleState={setModuleState}
                            getSessionByModule={getSessionByModule}
                            isLast = {true}
                        /> : null
                }
            </div> : null
        }

    </div>
}

export default ModuleComp