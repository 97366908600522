import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

export default function index({
  country,
  setcountry,
  state,
  setstate,
  city,
  setcity,
  states,
  cities,
  setstates,
  setcities,
}) {
  const [fetchingstates, setfetchstates] = useState(false);
  const [fetchingcities, setfetchcitites] = useState(false);
  const [countries, setcountries] = useState(null);
  const fetchcountries = async () => {
    const response = await axios.get("/api/location/countries");
    setcountries(response.data);
  };

  const [countrycode, setcountrycode] = useState(null);
  const [statecode, setstatecode] = useState(null);
  const fetchstates = async () => {
    if (countries && countries.length > 0) setfetchstates(true);
    const response = await axios.get(`/api/location/country/${countrycode}`);
    setstates(response.data);
    setfetchstates(false);
  };

  const fetchcities = async () => {
    setfetchcitites(true);
    const response = await axios.get(
      `/api/location/cities/${countrycode}/${statecode}`
    );
    setcities(response.data);
    setfetchcitites(false);
  };

  useEffect(() => {
    fetchcountries();
  }, []);
  useEffect(() => {
    if (countrycode !== null) fetchstates();
  }, [country]);
  useEffect(() => {
    if (statecode !== null) fetchcities();
  }, [state]);
  return (
    <>
    <div className="d-flex w-100 p-0">
    <div className="col flex-fill p-0">
      <select
        className="auth_input"
        name="grossValue"
        value={country}
        onChange={(e) => {
          setcountrycode(e.target.value.split("#")[0]);
          setcountry(e.target.value.split("#")[1]);
        }}
      >
        <option className="color_black" value="">
          {country ? country : "Country"}
        </option>
        {countries !== null
          ? countries.map((option, i) => (
              <option
                className="color_black"
                key={i}
                value={`${option.iso2}` + "#" + `${option.name}`}
              >
                {option.name}
              </option>
            ))
          : null}
      </select>
      </div>

      {countries && countries.length > 0 && fetchingstates ? (
        <CircularProgress style={{ color: "white" }} />
      ) : null}
      {states !== null && states.length > 0 ? (
      <div className="col flex-fill pr-0">
        <select
          className="auth_input"
          name="grossValue"
          value={state}
          onChange={(e) => {
            setstatecode(e.target.value.split("#")[0]);
            setstate(e.target.value.split("#")[1]);
          }}
        >
          <option className="color_black" value="">
            {state ? state : "State"}
          </option>
          {states !== null
            ? states.map((option, i) => (
                <option
                  className="color_black"
                  key={i}
                  value={`${option.iso2}` + "#" + `${option.name}`}
                >
                  {option.name}
                </option>
              ))
            : null}
        </select>
        </div>
      ) : null}
      {states && states.length > 0 && fetchingcities ? (
        <CircularProgress style={{ color: "white" }} />
      ) : null}
      {cities !== null && cities.length > 0 ? (
      <div className="col flex-fill pr-0">
        <select
          className="auth_input"
          name="grossValue"
          value={city}
          onChange={(e) => setcity(e.target.value)}
        >
          <option className="color_black" value="">
            City
          </option>
          {cities !== null
            ? cities.map((option, i) => (
                <option className="color_black" key={i} value={option.name}>
                  {option.name}
                </option>
              ))
            : null}
        </select>
        </div>
      ) : null}
      </div>
    </>
  );
}
