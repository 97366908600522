import React, { useEffect, useState } from 'react';
import './index.css';
import Axios from "axios";
import { useAuth } from '../../../Utils/Auth';
import { useNavigate } from 'react-router-dom';

const StartupInvestment = () => {

    const [startups, setStartups] = useState([])
    const { setSessionExpire, user } = useAuth();
    const navigate = useNavigate();
    const getStartupInvestment = async () => {
        Axios({
            url: `/api/dashboard/startup/fundraise`,
            method: "GET",
        }).then((res) => {
            setStartups(res.data.data)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    useEffect(() => {
        getStartupInvestment()
    }, [])

    return <div className="mentor_startups_root">
        <div className="mentor_startups_head">
            <p className="subtitle color_dark_black2">Startup looking for investments</p>
            {/* <Link to={`/dashboard/${user.type}?tab=2`} >
                <p className="w_fit bold" style={{ marginTop: "5px" }}
                // onClick={() => setconnectionState(1)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </p>
            </Link> */}
        </div>
        <div className="startupLooking_body">
            {
                startups.length > 0 ?
                startups.map((startup, i) => <div className="startupLooking_body_div point" key={i}
                onClick={()=>navigate(`/dashboard/${user.type}?tab=1651&startup=${startup.id}`)}
                >
                    <div className="startupLooking_body_divleft">
                        <img src={startup.logo} alt="profile  " />
                        <div className="startupLooking_body_divleft_name pl-16">
                            <p className="body1 color_dark_blue">{startup.companyName}</p>
                            <p className="caption color_dark_grey2" style={{ paddingTop: 4 }}>
                                <span>Startup | {startup.location.state}</span>
                            </p>

                        </div>
                    </div>
                    <div className="startupLooking_body_divright">
                        <p className="title">${" "}{startup.fundraise}</p>
                    </div>
                </div>) : null
            }

        </div>

    </div>
}

export default StartupInvestment