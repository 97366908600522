import React from 'react'
import card from "./../../../../Assets/images/icons/card.png";
import {ReactComponent  as RecordCircle} from '../../../../Assets/images/record-circle.svg'
import './card.css'
import { useNavigate } from 'react-router-dom';

const IncubatorsCard = (props) => {
    const { setAssetView, data,setIncubatorId } = props;
    const navigate = useNavigate();

    return (
        <div className="col-lg-3 col-md-4 col-12 mb-24">
            <div className="startup_assets_card_root point" onClick={() => {
                setAssetView(1);
                setIncubatorId(`${data.id}`)
                navigate(`/dashboard/startup?tab=9&cw=4&incubator=${data.id}`)
            }}>
                <div className="startup_assets_card_main">
                    <div className="startup_assets_card_tag">
                        {
                            data.interestedIndustries.length > 0 ?  <p className='incubation_chips'>{data.interestedIndustries[0]}</p> : <br/>
                        }
                    </div>
                    <div className="startup_assets_card_profile mt-16">
                        <img src={data.logo} alt={data.name}/>
                        <div className="s_asset_card_profile_name ml-12">
                            <p className="body1">{data.name}</p>
                            {/* <p className="overline color_dark_grey2 d-flex align-items-center">
                                <span>{data.category}</span>
                                <span className='startup_assets_ellipse'></span>
                                <span>{data.sub_category}</span>
                            </p> */}
                        </div>
                    </div>
                    <div className="startup_assets_card_rs_info mt-16">
                        <div className="startup_assets_card_rs">
                            <img src={card} alt="card" style={{ marginRight: 4 }} />
                            <p className="overline color_dark_blue">{data.asset_count} assets</p>
                        </div>
                        <div className="startup_assets_card_rs">
                        <RecordCircle/>
                            <p className="overline color_dark_blue">{data.asset_on_lease} active leases</p>
                        </div>
                    </div>
                </div>
                <div className="startup_assets_card_footer mt-16">
                    <p className="overline" style={{ color: "rgba(0, 0, 0, 0.75)" }}>{data.order_completed} orders completed until now</p>
                </div>
            </div>
        </div>
    )
}

export default IncubatorsCard