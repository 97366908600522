import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";

import { MemberSkeleton } from "../../../../../Components/SkeletonLoader/ProgramDashboard";
import {
  ParticipantsInvite,
  ParticipantsEmailInvite,
} from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";

import MentorProfile from "./MentorProfile";
// import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
// import { Link } from "react-router-dom";
import "./index.css";
import MentorInvite from "./mentorinvite";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AssignModal from "./assignmodal";
import { Skeleton } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const index = ({ programid, setparticipantState }) => {
  const [participants, setparticipants] = useState([]);
  const [activementors, setactivementors] = useState([]);

  const [requests, setrequests] = useState([]);
  const { setSessionExpire } = useAuth(); // ,user
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [mentorid, setmentorid] = useState("");
  const [open, setOpen] = useState(false);

  const [Assignopen, setAssignOpen] = React.useState(false);
  const handleAssignOpen = () => setAssignOpen(true);
  const handleAssignClose = () => setAssignOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [already, setalready] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // getting data
  const fetchallmentors = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/investor`,
      // url: `api/incubatorProgram/accepted/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setparticipants(data.resultModels);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false);
        }
      });
  };

  const fetchInvites = async () => {
    await Axios({
      url: `/api/incinvestor/applications/incubator/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setrequests(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const fetchactivementors = async () => {
    await Axios({
      url: `/api/incinvestor/activementors/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setactivementors(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchallmentors();
    fetchInvites();
    fetchactivementors();
  }, []);

  // console.log(participants);

  return (
    <>
      {step === 1 ? (
        <>
          <MentorProfile
            setstep={setstep}
            id={participantid}
            fetchInvites={fetchInvites}
            fetchactivementors={fetchactivementors}
            setparticipantState={setparticipantState}
            programid={programid}
            mentorid={mentorid}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row m-0">
                <p className="p_spartan_20 mb-4 mt-2">Active Investors</p>
                {isLoading ? (
                  <MemberSkeleton />
                ) : activementors.length > 0 ? (
                  <>
                    {activementors &&
                      activementors.length != 0 &&
                      activementors.map((v, i) => {
                        return (
                          <>
                            <div
                              className="col-12 no-padding mt-1 row dashboard_mentor_card"
                              key={i}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-12 col-md-2 d-flex align-items-center">
                                <Avatar
                                  src={v.profile.profilePic}
                                  alt="connection"
                                  sx={{ width: 90, height: 90 }}
                                  onClick={() => {
                                    setstep(1);
                                    setparticipantid(v.profile._id);
                                    setmentorid(v.profile.id);
                                  }}
                                />
                              </div>
                              <div
                                className="col-12 col-md-5 d-flex flex-column justify-content-center"
                                style={{ borderRight: "1px solid #262C5B" }}
                              >
                                <h5
                                  className="row"
                                  style={{ color: "#262C5B" }}
                                  onClick={() => {
                                    setstep(1);
                                    setparticipantid(v.profile._id);
                                    setmentorid(v.profile.id);
                                  }}
                                >
                                  {v.profile.name.length > 12
                                    ? v.profile.name.slice(0, 12) + "..."
                                    : v.profile.name}
                                </h5>
                                <div className="row">
                                  <p className=" no-margin font12 bold">
                                    Investor | &nbsp;
                                  </p>
                                  <p className=" no-margin font12 bold">
                                    {" "}
                                    {v.profile.location.city}, -
                                    {v.profile.location.country}
                                  </p>
                                </div>
                                {v.assigned_user &&
                                v.assigned_user.length > 0 ? (
                                  <div
                                    className="row align-items-center mt-16"
                                    onClick={() => {
                                      handleAssignOpen();
                                      setmentorid(v._id);
                                      setalready(v.assigned_user);
                                    }}
                                  >
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      className="mr-8"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5.3125 9.14062C5.3125 9.05469 5.38281 8.98438 5.46875 8.98438H6.15625C6.24219 8.98438 6.3125 9.05469 6.3125 9.14062V11.8125H11.8594V8.79688C11.8594 6.66563 10.1312 4.9375 8 4.9375C5.86875 4.9375 4.14062 6.66563 4.14062 8.79688V11.8125H5.3125V9.14062Z"
                                        fill="url(#paint0_linear_3075_25224)"
                                      />
                                      <path
                                        d="M3.38905 4.85156L4.0078 4.23281C4.05624 4.18437 4.05624 4.10469 4.0078 4.05625L2.94686 2.99531C2.92337 2.97205 2.89164 2.959 2.85858 2.959C2.82552 2.959 2.79379 2.97205 2.7703 2.99531L2.15155 3.61406C2.12828 3.63756 2.11523 3.66928 2.11523 3.70234C2.11523 3.73541 2.12828 3.76713 2.15155 3.79063L3.21249 4.85156C3.26092 4.9 3.33905 4.9 3.38905 4.85156ZM13.8516 3.61406L13.2328 2.99531C13.2093 2.97205 13.1776 2.959 13.1445 2.959C13.1115 2.959 13.0797 2.97205 13.0562 2.99531L11.9953 4.05625C11.972 4.07974 11.959 4.11147 11.959 4.14453C11.959 4.17759 11.972 4.20932 11.9953 4.23281L12.6141 4.85156C12.6625 4.9 12.7422 4.9 12.7906 4.85156L13.8516 3.79063C13.9 3.74063 13.9 3.6625 13.8516 3.61406ZM7.56249 2.8125H8.43749C8.50624 2.8125 8.56249 2.75625 8.56249 2.6875V1.1875C8.56249 1.11875 8.50624 1.0625 8.43749 1.0625H7.56249C7.49374 1.0625 7.43749 1.11875 7.43749 1.1875V2.6875C7.43749 2.75625 7.49374 2.8125 7.56249 2.8125ZM13 13.9375H2.99999C2.72342 13.9375 2.49998 14.1609 2.49998 14.4375V14.8125C2.49998 14.8813 2.55623 14.9375 2.62498 14.9375H13.375C13.4437 14.9375 13.5 14.8813 13.5 14.8125V14.4375C13.5 14.1609 13.2766 13.9375 13 13.9375ZM3.01561 12.4375C3.01561 12.7141 3.23905 12.9375 3.51561 12.9375H12.4844C12.7609 12.9375 12.9844 12.7141 12.9844 12.4375V8.79688C12.9844 6.04375 10.7531 3.8125 7.99999 3.8125C5.24686 3.8125 3.01561 6.04375 3.01561 8.79688V12.4375ZM4.14061 8.79688C4.14061 6.66563 5.86874 4.9375 7.99999 4.9375C10.1312 4.9375 11.8594 6.66563 11.8594 8.79688V11.8125H6.31249V9.14062C6.31249 9.05469 6.24218 8.98438 6.15624 8.98438H5.46874C5.3828 8.98438 5.31249 9.05469 5.31249 9.14062V11.8125H4.14061V8.79688Z"
                                        fill="url(#paint1_linear_3075_25224)"
                                      />
                                      <defs>
                                        <linearGradient
                                          id="paint0_linear_3075_25224"
                                          x1="11.4656"
                                          y1="4.9375"
                                          x2="3.04506"
                                          y2="6.76906"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#F53939" />
                                          <stop
                                            offset="0.203125"
                                            stopColor="#FF6F1E"
                                          />
                                          <stop
                                            offset="1"
                                            stopColor="#FFC939"
                                          />
                                        </linearGradient>
                                        <linearGradient
                                          id="paint1_linear_3075_25224"
                                          x1="13.2872"
                                          y1="1.0625"
                                          x2="0.190542"
                                          y2="3.21534"
                                          gradientUnits="userSpaceOnUse"
                                        >
                                          <stop stopColor="#F53939" />
                                          <stop
                                            offset="0.203125"
                                            stopColor="#FF6F1E"
                                          />
                                          <stop
                                            offset="1"
                                            stopColor="#FFC939"
                                          />
                                        </linearGradient>
                                      </defs>
                                    </svg>

                                    <span
                                      className="overline"
                                      style={{ color: "#262C5B" }}
                                    >
                                      Currently assigned{" "}
                                      {v.assigned_user.length} participant
                                      {v.assigned_user.length > 1 ? "s" : null}
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className="row align-items-center mt-16"
                                    onClick={() => {
                                      handleAssignOpen();
                                      setmentorid(v._id);
                                      setalready(v.assigned_user);
                                    }}
                                  >
                                    <svg
                                      style={{ cursor: "pointer" }}
                                      className="mr-8"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 2.88889C0 2.12271 0.304364 1.38791 0.846136 0.846136C1.38791 0.304364 2.12271 0 2.88889 0H13.1111C13.8773 0 14.6121 0.304364 15.1539 0.846136C15.6956 1.38791 16 2.12271 16 2.88889V13.1111C16 13.8773 15.6956 14.6121 15.1539 15.1539C14.6121 15.6956 13.8773 16 13.1111 16H2.88889C2.12271 16 1.38791 15.6956 0.846136 15.1539C0.304364 14.6121 0 13.8773 0 13.1111V2.88889Z"
                                        fill="#FF6F1E"
                                      />
                                      <path
                                        d="M7.99999 3.55566C8.1768 3.55566 8.34637 3.6259 8.47139 3.75093C8.59642 3.87595 8.66665 4.04552 8.66665 4.22233V7.33344H11.7778C11.9546 7.33344 12.1241 7.40368 12.2492 7.5287C12.3742 7.65373 12.4444 7.8233 12.4444 8.00011C12.4444 8.17692 12.3742 8.34649 12.2492 8.47151C12.1241 8.59654 11.9546 8.66678 11.7778 8.66678H8.66665V11.7779C8.66665 11.9547 8.59642 12.1243 8.47139 12.2493C8.34637 12.3743 8.1768 12.4446 7.99999 12.4446C7.82318 12.4446 7.65361 12.3743 7.52858 12.2493C7.40356 12.1243 7.33332 11.9547 7.33332 11.7779V8.66678H4.22221C4.0454 8.66678 3.87583 8.59654 3.7508 8.47151C3.62578 8.34649 3.55554 8.17692 3.55554 8.00011C3.55554 7.8233 3.62578 7.65373 3.7508 7.5287C3.87583 7.40368 4.0454 7.33344 4.22221 7.33344H7.33332V4.22233C7.33332 4.04552 7.40356 3.87595 7.52858 3.75093C7.65361 3.6259 7.82318 3.55566 7.99999 3.55566Z"
                                        fill="white"
                                      />
                                    </svg>

                                    <span
                                      className="overline"
                                      style={{ color: "#262C5B" }}
                                    >
                                      Assign participants to this investor
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-md-5">
                                <p className=" font12 p_grey_chip mt-3 mb-3">
                                  {v.profile.investedValue}
                                </p>
                                <p
                                  className="caption mb-3"
                                  style={{ color: "#303030" }}
                                >
                                  {v.profile.investortype}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                ) : (
                  <p>
                    <br />
                    <br />
                    No Active Investor
                  </p>
                )}
                {participants && participants.length === 0 ? <p></p> : null}
              </div>
            </div>

            <div className="col-lg-4 col-sm-4">
              <div className="d-flex justify-content-center ">
                {isLoading ? (
                  <ParticipantsEmailInvite />
                ) : (
                  <button
                    onClick={() => handleOpen()}
                    type="button"
                    className="gradient_CTA"
                  >
                    INVITE Investors BY EMAIL
                  </button>
                )}
              </div>
              <div className="side_box">
                <div
                  className="subtitle"
                  style={{
                    padding: "8px 16px",
                    background: "rgba(226, 232, 240, 0.5)",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  {" "}
                  {isLoading ? (
                    <Skeleton animation="wave" width="60%" height={50} />
                  ) : (
                    "Investor Requests"
                  )}
                </div>
                {requests.length > 0 ? (
                  <div className="row">
                    {isLoading ? (
                      <ParticipantsInvite value={4} />
                    ) : (
                      <>
                        {requests.map((v, i) => {
                          return (
                            <>
                              <>
                                <div key={i} className="col-12">
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ padding: "16px 24px" }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        src={v.profile.profilePic}
                                        sx={{ width: 40, height: 40 }}
                                        className="mr-3"
                                        variant="rounded"
                                      />
                                      <p
                                        className="body2"
                                        style={{ color: "#262C5B" }}
                                      >
                                        {v.profile.name}
                                      </p>
                                    </div>
                                    <div
                                      className="overline"
                                      onClick={() => {
                                        setstep(1);
                                        setparticipantid(v.profile._id);
                                        setmentorid(v.profile.id);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "#989898",
                                      }}
                                    >
                                      View Investor
                                    </div>
                                  </div>
                                </div>
                              </>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : (
                  <p
                    className="body2"
                    style={{ padding: "16px 24px", color: "#989898" }}
                  >
                    {isLoading ? "" : "  No Investor requests"}
                  </p>
                )}
              </div>
              <div className="side_box">
                <div
                  className="subtitle"
                  style={{
                    padding: "8px 16px",
                    background: "rgba(226, 232, 240, 0.5)",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  {isLoading ? (
                    <Skeleton animation="wave" width="60%" height={50} />
                  ) : (
                    "Invite Investors"
                  )}
                </div>
                {isLoading ? (
                  <ParticipantsInvite value={4} />
                ) : (
                  <>
                    {participants.length > 0 ? (
                      <div className="row">
                        {participants.map((v, i) => {
                          return (
                            <>
                              <>
                                <div key={i} className="col-12">
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ padding: "16px 24px" }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        src={v.profilePic}
                                        sx={{ width: 40, height: 40 }}
                                        className="mr-3"
                                        variant="rounded"
                                      />
                                      <p
                                        className="body2"
                                        style={{ color: "#262C5B" }}
                                      >
                                        {v.name}
                                      </p>
                                    </div>
                                    <div
                                      className="overline"
                                      onClick={() => {
                                        setstep(1);
                                        setparticipantid(v._id);
                                        setmentorid(v.id);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "#989898",
                                      }}
                                    >
                                      View Investors
                                    </div>
                                  </div>
                                </div>
                              </>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <p
                        className="body2"
                        style={{ padding: "16px 24px", color: "#989898" }}
                      >
                        {isLoading ? "" : "Start connecting with Investors"}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <MentorInvite handleClose={handleClose} />
        </Box>
      </Modal>
      <Modal
        open={Assignopen}
        onClose={handleAssignClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 620 }}>
          <AssignModal
            programid={programid}
            mentorid={mentorid}
            already={already}
            handleAssignClose={handleAssignClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default index;
