import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
// import industries from "./../../../Lists/Industries.json";
// import ClearIcon from "@mui/icons-material/Clear";
// import CircularProgress from "@mui/material/CircularProgress";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
// import Axios from "axios";
import Multiselect from "multiselect-react-dropdown";

const Step2 = (props) => {
  const {
    CurrentEvaluation,

    // localprofile,

    setValuation,
    // setProfile,
    // otherIndustry,
    // setOtherIndustry,
    setOtherGrowth,
    otherGrowth,
    otherFundingStatus,
    setOtherFundingStatus,
    targetAudience,
    setTargetAudience,
    // DocumentsNeed,
    // setCompanyDocuments,
    // industry,
    // setIndustry,
    growth,
    setGrowth,
    FundingStatus,
    NumberOfEmployees,
    setNumberOfEmployees,
    setFundingStatus,

    revenue,
    setrevenue,
    servicesector,
    setservicesector,
    options,
    initial,
    setinitial
  } = props;
  
  useEffect(()=>{
    if(servicesector.length===1)
    {
      setinitial(false);
      // console.log(initial)
    }
  },[servicesector])

  
  useEffect(()=>{
    setinitial(true);
    // console.log(initial)
  },[])

  // const [docType, setDocType] = useState("");
  // const [isdocloading, setisdocloading] = useState(false);
  const [formatedValue, setFormatedValue] = useState(CurrentEvaluation);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      // industry.length === 0 ||
      targetAudience.length === 0 ||
      growth.length === 0 ||
      NumberOfEmployees.length === 0 ||
      FundingStatus.length === 0
    )
      return toast.error("Empty fields");
    // if (DocumentsNeed.length < 1) {
    //   toast.error("Please upload documents", { position: "bottom-right" });
    //   return;
    // } else {
    //   setProfile({
    //     ...localprofile,
    //     DocumentsNeed,
    //   });
    //   localStorage.setItem("Profile", JSON.stringify(localprofile));
    //   // setisdocloading(false);
    // }
  };

  // const handleDocumentsChange = (e) => {
  //   const file = e.target.files[0];
  //   if (
  //     file.type !== "image/jpg" &&
  //     file.type !== "image/png" &&
  //     file.type !== "image/jpeg" &&
  //     file.type !== "application/pdf"
  //   ) {
  //     toast.error("Only Image And PDF files are allowed!");
  //     return;
  //   }
  //   if (file.size > 2048 * 1024) {
  //     toast.error("File size exceeded 2MB!");
  //     return;
  //   }

  //   const formdata = new FormData();
  //   formdata.append("document", file);
  //   setisdocloading(true);
  //   Axios({
  //     method: "post",

  //     url: " /api/upload/document",

  //     data: formdata,
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       const filename = {
  //         docType: docType,
  //         docName: e.target.files[0].name,
  //         docExt: e.target.files[0].type,
  //         downloadUrl: data.location,
  //       };
  //       setCompanyDocuments((docs) => [...docs, filename]);
  //       setisdocloading(false);
  //       toast.success("Document uploaded", { position: "bottom-right" });

  //       setDocType(null);
  //     })
  //     .catch(() => {
  //       toast.error("Error in upload", { position: "bottom-right" });
  //     });
  // };

  function handleValuation(e) {
    setFormatedValue(e.target.value);
    let s = e.target.value;
    if (s) {
      var n = s.split("€", 2)[1];
      setValuation(Number(n.split(",").join("")));
    }
  }

  // const deleteDocument = (e, i) => {
  //   e.preventDefault();
  //   setCompanyDocuments((oldItems) => {
  //     return oldItems.filter((arrelement, index) => {
  //       return index != i;
  //     });
  //   });
  // };

  const addInputEvent3 = (e) => {
    setservicesector(e.map((i) => i.label));
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      {/* <div className="auth_head">
        <p className="p_small_whiteGray">Please fill company domain details</p>
        <hr style={{ background: "rgb(159, 159, 159)" }} />
      </div> */}
      <div
        className=" register_page_form row"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div className="col-12 col-md-6">
          <label>Number of Employees*</label>
          <select
            className="auth_input auth_select"
            name="no_of_emp"
            value={NumberOfEmployees}
            onChange={(e) => setNumberOfEmployees(e.target.value)}
          >
            <option value="">Select Number of Employees</option>
            <option value="0-5">0-5</option>
            <option value="5-15">5-15</option>
            <option value="15-30">15-30</option>
            <option value="30-50">30-50</option>
            <option value="More than 50">More than 50</option>
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label>Target Audience*</label>
          <select
            className="auth_input auth_select"
            name="target_audience"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
          >
            <option value="">Select Target Audience</option>
            <option value="Business To Business">Business To Business</option>
            <option value="Business To Customers">Business To Customers</option>
            <option value="Customers To Customers">
              Customers To Customers
            </option>
            <option value="Business To Government">
              Business To Government
            </option>
            <option value="Government To Customers">
              Government To Customers
            </option>
            <option value="Government To Business">
              Government To Business
            </option>
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label>Funding Status*</label>
          <select
            className="auth_input auth_select"
            name="funding_status"
            value={FundingStatus}
            onChange={(e) => setFundingStatus(e.target.value)}
          >
            <option value="">Select Funding Status</option>
            <option value="Bootstrap">Bootstrap</option>
            <option value="Pre-Seed">Pre-Seed</option>
            <option value="Seed">Seed</option>
            <option value="Series A">Series A</option>
            <option value="Series B">Series B</option>
            <option value="Series C">Series C</option>
            <option value="None">None</option>
            <option value="others">Others</option>
          </select>
        </div>

        {FundingStatus === "others" ? (
          <>
            <div className="col-12 col-md-6">
              <label>Funding Status</label>
              <input
                type="text"
                placeholder="Please specify your funding status"
                className="auth_input"
                value={otherFundingStatus}
                onChange={(e) => setOtherFundingStatus(e.target.value)}
              />
            </div>
          </>
        ) : null}
        <div className="col-12 col-md-6">
          <label>Current Growth*</label>
          <select
            className="auth_input auth_select"
            name="current_growth"
            value={growth}
            onChange={(e) => setGrowth(e.target.value)}
          >
            <option value="">Select Current Growth</option>
            <option value="Pre Startup/R&D">Pre-Startup/R&amp;D</option>
            <option value="MVP/Prototype">MVP/Prototype</option>
            <option value="Early Customers and Iteration">
              Early Customers &amp; Iteration
            </option>
            <option value="Scaling and Expansion">
              Scaling &amp; Expansion
            </option>
            <option value="Product Market Fit and Monestisation">
              Product Market Fit &amp; Monestisation
            </option>
            <option value="others">Others</option>
          </select>
        </div>

        {growth === "others" ? (
          <>
            <div className="col-12 col-md-6">
              <label>Current Growth</label>
              <input
                type="text"
                placeholder="Please specify your current growth"
                className="auth_input"
                value={otherGrowth}
                onChange={(e) => setOtherGrowth(e.target.value)}
              />
            </div>
          </>
        ) : null}

        {/* <div className="col-12 col-md-6">
          <label>Industry</label>
          <select
            className="auth_input auth_select"
            name="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value="">Select Industry</option>
            {industries.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div> */}

        {/* {industry === "Other" ? (
          <>
            <div className="col-12 col-md-6">
              <label>Industry</label>
              <input
                type="text"
                placeholder="Please specify your industry"
                className="auth_input"
                value={otherIndustry}
                onChange={(e) => setOtherIndustry(e.target.value)}
              />
            </div>
          </>
        ) : null} */}

        
        <div className="col-12 col-md-6">
          <label>Estimated Revenue Range*</label>
          <select
            className="auth_input auth_select"
            name="current_growth"
            value={revenue}
            onChange={(e) => setrevenue(e.target.value)}
          >
          <option value="">Select Revenue range</option>
          <option value="0 to 10 Thousands">0 to 10 Thousands</option>
          <option value="10 Thousands to 40 Thousands">10 Thousands to 40 Thousands</option>
          <option value="40 Thousands to 1 Crore">40 Thousands to 1 Crore</option>
          <option value="1 Crore to 10 Crore">1 Crore to 10 Crore</option>
          <option value="More than 10 Crore">More than 10 Crore</option>
          </select>
        </div>

        <div className="col-12 col-md-6">
          <label>Current Valuation</label>
          <NumberFormat
            placeholder="Current Valuation"
            displayType={"number"}
            thousandSeparator={true}
            prefix={"€ "}
            className="auth_input some"
            inputmode="numeric"
            value={formatedValue}
            onChange={(e) => handleValuation(e)}
          />
        </div>

        
        <div className="col-12" style={{ height: "100%" }}>
          <label className="auth_label">
            Sector of Services*
          </label>
          <Multiselect
            isMulti
            options={options}
            className=""
            displayValue="label"
            placeholder="Select your Sector of Services"
            name="industries"
            closeIcon="cancel"
            value={servicesector}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>
      </div>
      
        <div className="col-12 " style={{ height: "100%" }}>
        <div className="d-flex flex-wrap">
          {initial && servicesector && servicesector.length>0 ? 
            servicesector.map((v,i)=>
                <>
                <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                  <p className="program_text">{v}</p>
                </span>
              </>
              )
          : null}
          </div>
        </div>
      


      {/* {isdocloading ? (
        <CircularProgress style={{ color: "black" }} />
      ) : (
        <>
          <label>Select Document*</label>
          <div
            className="auth_form reg_form col-12"
            style={{ padding: "0 0px" }}
          >
            <select
              className="auth_input auth_select"
              name="doc_type"
              style={{ color: "#9f9f9f" }}
              onChange={(e) => setDocType(e.target.value)}
              value={docType}
            >
              <option value="">Document Type</option>
              <option value="Company Registration Document">
                Company Registration Document
              </option>
              <option value="Incubation Document">Incubation Document</option>
              <option value="Grant Document">Grant Document</option>
              <option value="others">Others</option>
            </select>

            {docType ? (
              <>
                <input
                  type="file"
                  className="auth_file_choose"
                  accept="application/pdf, image/*"
                  name="main_doc"
                  id="documents"
                  style={{ display: "none" }}
                  onChange={handleDocumentsChange}
                />
                <label htmlFor="documents">
                  <Tooltip title="Click here to add document" placement="left">
                    <div className="d-flex align-items-center ">
                      <p className="text-dark no-margin pb-2">
                        <strong>Attach</strong>
                      </p>
                      &nbsp; &nbsp;
                      <svg className="mr-16" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B"/>
                      </svg>
                    </div>
                  </Tooltip>
                </label>
              </>
            ) : (
              ""
            )}
            {DocumentsNeed
              ? DocumentsNeed.map((docs, i) => (
                <div className="col-12 mt-16" key={docs.docName}>
                <p className="body1 w-fit" style={{color:"#636363"}}>
                  
                  <svg className="mr-16" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B"/>
                  </svg>
  
                  {docs.docType} 
                  <span
                  className="overline ml-16"
                    style={{ cursor: "pointer",color: "#F53939" }}
                    onClick={(e) => deleteDocument(e, i)}
                  >
                    Delete
                  </span>
                </p>
              </div>
                ))
              : null}
          </div>
        </>
      )} */}

    </form>
  );
};

export default Step2;
