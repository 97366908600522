import React, { useEffect, useState } from 'react'
import {
    VideocamOutlined, DescriptionOutlined,
    // RemoveCircleOutlined 
} from '@mui/icons-material';
import Axios from "axios"
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useAuth } from "../../../../../Utils/Auth";


const AddNewSession = ({ courseId, setAddSessionState }) => {
    React.useEffect(() => {
        scrollTo(0, 0)
    }, [])
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const navigate = useNavigate();
    const query = useQuery();
    // const { user } = useAuth();
    const [sessionName, setSessionName] = useState("")
    const [videoUrl, setVideoUrl] = useState("")
    const [videoURlBool, setVideoUrlBool] = useState(false);
    const [ExtvideoURl, setExtvideoURl] = useState("");
    const [materialUrl, setMaterialUrl] = useState("")
    const [materialUrlBool, setMaterialUrlBool] = useState(false);
    const [resourceName, setResourceName] = useState(null)
    const [resourceExtLink, setResourceExtLink] = useState(null)
    const module_id = query.get("module")
    const session_id = query.get("session")
    const course_id = query.get("course")
    const [uSesBool, setUSesBool] = useState(false);
    // newgetsession
    // const [editSession, setEditSession] = useState("")

    const {programid} = useParams()

    const getSession = async () => {
        await Axios({
            url: `/api/mentorModules/session/${session_id}`,
            method: "get"
        }).then((res) => {
            setSessionName(res.data.data.session_name)
            setVideoUrl(res.data.data.video_link)
            setMaterialUrl(res.data.data.resource_link)
            setResourceName(res.data.data.resource_name)
            setResourceExtLink(res.data.data.resource_website)
            setVideoUrl(true)
            setMaterialUrlBool(true)
            setUSesBool(true)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (session_id) {
            getSession();
        }
    }, [])

    const handleVideoUpload = async (e) => {
        const video = e.target.files[0];
        if (video.size > 40 * 1024 * 1024) {
            toast.error("File size exceeded 40MB!", { position: "bottom-right" });
            return;
        }
        setVideoUrlBool(true)
        const formdata = new FormData();
        formdata.append("video", video);
        await Axios({
            method: "post",
            url: `/api/mentorModules/video/upload`,
            data: formdata,
        }).then((res) => {
            const { data } = res;
            setVideoUrl(data.location);
            setVideoUrlBool(false)
            toast.success("Video uploaded", { position: "bottom-right" });
        }).catch(() => {
            setVideoUrlBool(false)
            toast.error("Error in upload", { position: "bottom-right" });
        });
    };

    const handleRemoveVdo = async (e) => {
        // console.log(sessionStorage.getItem('currentModule'))
        e.preventDefault()
        await Axios({
            method: "post",
            url: `/api/mentorModules/video/delete`,
            data: {
                location: videoUrl
            },
        }).then(() => {
            // const { data } = res;
            setVideoUrl("");
            setVideoUrlBool(false)
            toast.success("Video Deleted", { position: "bottom-right" });
        }).catch(() => {
            setVideoUrlBool(false)
            toast.error("Error in upload", { position: "bottom-right" });
        });
    }

    const handleMaterialUpload = async (e) => {
        const doc = e.target.files[0];
        if (doc.size > 2 * 1024 * 1024) {
            toast.error("File size exceeded 2MB!", { position: "bottom-right" });
            return;
        }
        setMaterialUrlBool(true)
        const formdata = new FormData();
        formdata.append("material", doc);
        await Axios({
            method: "post",
            url: `/api/mentorModules/material/upload`,
            data: formdata,
        }).then((res) => {
            const { data } = res;
            setMaterialUrl(data.location);
            setMaterialUrlBool(false)
            toast.success("File uploaded", { position: "bottom-right" });
        }).catch(() => {
            setMaterialUrlBool(false)
            toast.error("Error in upload", { position: "bottom-right" });
        });
    };

    const createSession = async (e) => {
        e.preventDefault()
        await Axios({
            method: "post",
            url: `/api/mentorModules/session/create`,
            data: {
                module_id: module_id,
                course_id: courseId,
                session_name: sessionName,
                session_type: "video",
                video_link: videoUrl,
                external_video_url: ExtvideoURl,
                resource_link: materialUrl,
                resource_name: resourceName,
                resource_website: resourceExtLink,
            },
        }).then(() => {
            // const { data } = res;
            // setMaterialUrl(data.location);
            // setMaterialUrlBool(false)
            setAddSessionState(0)
            toast.success("session created", { position: "bottom-right" });
        }).catch(() => {
            toast.error("Error in upload", { position: "bottom-right" });
        });
    }

    const UpdateSession = async (e) => {
        e.preventDefault()
        await Axios({
            method: "put",
            url: `/api/mentorModules/session/update/${session_id}`,
            data: {
                session_name: sessionName,
                session_type: "video",
                video_link: videoUrl,
                resource_link: materialUrl,
                resource_name: resourceName,
                resource_website: resourceExtLink,
            },
        }).then(() => {
            // const { data } = res;
            // setMaterialUrl(data.location);
            // setMaterialUrlBool(false)
            setAddSessionState(0);
            navigate(
                `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
            );

            toast.success("session Updated", { position: "bottom-right" });
        }).catch(() => {
            toast.error("Error in update", { position: "bottom-right" });
        });
    }
    // const createModule

    return <div className='my-5'>
        <p className='body1 mb-3 point' onClick={() => setAddSessionState(0)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left mb-1 mx-2" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
            </svg>
            Back</p>

        <h4 onClick={() => {

        }}>Session Details</h4>
        <div className="create_course_form_main">
            <div className="row">
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="Module 1" className='caption color_dark_blue'>Name of session</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Enter the name of session'
                        value={sessionName}
                        onChange={(e) => setSessionName(e.target.value)}
                    />
                </div>
                {
                    ExtvideoURl.length > 1 ? null : <div className="col-12 create_course_form_maindiv mt-4">
                        <label htmlFor="videoUpload" className='title color_dark_blue'>Video content</label>
                        {
                            videoUrl ? <div className='session-video'>
                                <video controls width="250">
                                    <source src={videoUrl} type="video/webm" />
                                    <source src={videoUrl} type="video/mp4" />
                                </video>
                                <br />
                                <p className='point font12' onClick={handleRemoveVdo}>Remove</p>

                            </div>
                                : videoURlBool ? <CircularProgress /> : <div className="create_newSession_uploadVideoDi mt-2">
                                    <label htmlFor="sessionVideo" className='caption color_dark_blue d-flex justify-content-center align-items-center point'><VideocamOutlined /> Drop files here to upload or choose file</label>
                                    <input
                                        type="file"
                                        name="sessionVideo"
                                        accept='video/*'
                                        id="sessionVideo"
                                        onChange={handleVideoUpload}
                                    />
                                </div>
                        }

                    </div>
                }

                {
                    videoUrl ? null : <>
                        {
                            ExtvideoURl.length > 1 ? null : <div className="col-12 create_course_form_maindiv">
                                <p>OR</p>
                            </div>
                        }

                        <div className="col-12 create_course_form_maindiv">
                            <label htmlFor="S3URL" className='caption color_dark_blue'>External Video URL</label>
                            <input
                                type="url"
                                className='body1'
                                placeholder='https://websitename.com'
                                value={ExtvideoURl}
                                onChange={(e) => setExtvideoURl(e.target.value)}
                            />
                        </div>
                    </>
                }

                <div className="col-12 create_course_form_maindiv mt-4">
                    <label htmlFor="videoUpload" className='title color_dark_blue'>Material and resources</label>
                    {
                        materialUrl ? <div className='session-video'>
                            <a href={`${materialUrl}`} target='_blank' rel='noreferrer'>View Material </a>
                            <br />
                            <p className='point font12' onClick={handleRemoveVdo}>Remove</p>
                        </div>
                            : materialUrlBool ? <CircularProgress /> : <div className="create_newSession_uploadVideoDi mt-2 ">
                                <label htmlFor="sessionmaterial" className='caption color_dark_blue d-flex justify-content-center align-items-center point'><DescriptionOutlined /> Drop files here to upload or choose file</label>
                                <input
                                    type="file"
                                    name="sessionmaterial"
                                    id="sessionmaterial"
                                    onChange={handleMaterialUpload}
                                    accept="image/*, application/pdf,text/plain,application/msword,.doc, .docx"
                                />
                            </div>
                    }
                </div>
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="ResourcesName" className='caption color_dark_blue'>Resources Name</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Enter resource name'
                        value={resourceName}
                        onChange={(e) => setResourceName(e.target.value)}
                    />
                </div>
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="ResourcesName" className='caption color_dark_blue'>Resourse Link</label>
                    <input
                        type="url"
                        className='body1'
                        placeholder='https://websitename.com'
                        value={resourceExtLink}
                        onChange={(e) => setResourceExtLink(e.target.value)}
                    />
                </div>
                {
                    uSesBool ? <div className="col-12 create_course_form_maindivBtn">
                        <button className='primaryFilled_activeCta' onClick={UpdateSession}>Update</button>
                    </div> : <div className="col-12 create_course_form_maindivBtn">
                        <button className='primaryFilled_activeCta' onClick={createSession}>Save</button>
                    </div>
                }

            </div>
        </div>
    </div>
}

export default AddNewSession