import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Avatar } from "@mui/material";
import AvatarGroup from '@mui/material/AvatarGroup';
import moment from "moment-timezone";

const Component = ({ id, title, duration, startDate, endDate }) => {

    const getDur = (startdate,enddate) => {
        // var dateTime = date + "T" + time;
        var scheduleTime = moment(enddate);
        var current = moment(startdate);
        // var ofset = moment().utcOffset() / 60;
    
        var dayDiff = scheduleTime.diff(current, "days");
        if (dayDiff > -1) {
          return Math.abs(dayDiff) + " days";
        } else if (dayDiff === -1) return Math.abs(dayDiff) + " day";
        else {
          var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
          //- parseFloat(timezone.split(" ", 3)[2]) +
          // ofset;
          if (hoursDiff <= -1) {
            let hoursDiffRound = Math.round(hoursDiff);
            return Math.abs(hoursDiffRound) == 1
              ? Math.abs(hoursDiffRound) + " hour"
              : Math.abs(hoursDiffRound) + " hours";
          } else {
            return Math.abs(Math.round(hoursDiff * 60)) + " mins";
          }
        }
      };

    const [members, setmembers] = useState([]);

    const fetchprogrammembers = async (id) => {
        await Axios({
          url: `/api/incubatorProgram/members/${id}`,
          method: "get",
        })
          .then((res) => {
            if (res.data.success) setmembers(res.data.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              console.log(err);
            }
          });
      };

      useEffect(() => {
        fetchprogrammembers(id);
      }, []);
      // useEffect(() => {
      //   console.log(members);
      // });

  return (
    <div className="program-box w-100 d-flex justify-content-between pt-4 pb-4" >
              <div className="row w-100 d-flex justify-content-between">
                <div className="col-7 d-flex flex-column justify-content-between" style={{borderRight: "2px solid #E6E6E6",height : "90px"}}>
                <div className="caption">
                  {title}
                </div>
                <div>
                <div className="overline mt-3">
                  Participants
                </div>
                <div className="w_fit program-avatar">
                <AvatarGroup max={4} className="">
                    {members && members.length>0 ? members.map((mem,i)=>
                    <Avatar key={i} sx={{ width: 24, height: 24 }} alt={mem.profile.companyName} src={mem.profile.logo} />
                    ):
                    <p className="overline" style={{color: "#989898"}}>No Participants</p>}
                </AvatarGroup>
                </div>
                </div>
                </div>
                <div className="col-5 d-flex jutify-content-center">
                <div className="d-flex flex-column justify-content-center ">
                  <div className="mb-2" style={{width: "max-content"}}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.99594 0.332031C3.31594 0.332031 0.335938 3.3187 0.335938 6.9987C0.335938 10.6787 3.31594 13.6654 6.99594 13.6654C10.6826 13.6654 13.6693 10.6787 13.6693 6.9987C13.6693 3.3187 10.6826 0.332031 6.99594 0.332031ZM9.66927 9.66537C9.6076 9.72717 9.53434 9.7762 9.45369 9.80965C9.37304 9.84311 9.28658 9.86033 9.19927 9.86033C9.11196 9.86033 9.0255 9.84311 8.94485 9.80965C8.86421 9.7762 8.79095 9.72717 8.72927 9.66537L6.53594 7.47203C6.47295 7.41029 6.42283 7.33666 6.3885 7.25541C6.35417 7.17416 6.3363 7.0869 6.33594 6.9987V4.33203C6.33594 3.96536 6.63594 3.66536 7.0026 3.66536C7.36927 3.66536 7.66927 3.96536 7.66927 4.33203V6.72537L9.66927 8.72537C9.92927 8.98537 9.92927 9.40537 9.66927 9.66537Z" fill="#262C5B"/>
                  </svg>
                  <span className="overline ml-3" style={{color:"#262C5B"}}>{duration !== "0month" ? duration : getDur(startDate,endDate)}</span>
                  </div>
                  <div className="mb-2" style={{width: "max-content"}}>
                  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.666504 2.62255C0.666504 2.1039 0.872535 1.6065 1.23927 1.23976C1.60601 0.873023 2.10341 0.666992 2.62206 0.666992H13.3776C13.8963 0.666992 14.3937 0.873023 14.7604 1.23976C15.1271 1.6065 15.3332 2.1039 15.3332 2.62255V4.08922H0.666504V2.62255Z" fill="#262C5B"/>
                  <path d="M0.666504 5.06738H15.3332V9.46738C15.3332 9.98603 15.1271 10.4834 14.7604 10.8502C14.3937 11.2169 13.8963 11.4229 13.3776 11.4229H2.62206C2.10341 11.4229 1.60601 11.2169 1.23927 10.8502C0.872535 10.4834 0.666504 9.98603 0.666504 9.46738V5.06738ZM11.4221 8.97849C11.2924 8.97849 11.168 9.03 11.0764 9.12169C10.9847 9.21337 10.9332 9.33772 10.9332 9.46738C10.9332 9.59704 10.9847 9.7214 11.0764 9.81308C11.168 9.90476 11.2924 9.95627 11.4221 9.95627H13.3776C13.5073 9.95627 13.6316 9.90476 13.7233 9.81308C13.815 9.7214 13.8665 9.59704 13.8665 9.46738C13.8665 9.33772 13.815 9.21337 13.7233 9.12169C13.6316 9.03 13.5073 8.97849 13.3776 8.97849H11.4221Z" fill="#262C5B"/>
                  </svg>
                  <span className="overline ml-3" style={{color:"#262C5B"}}>Free</span>
                  </div>
                  <div className="" style={{width: "max-content"}}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#E2E8F0" stroke="white"/>
                  </svg>
                  <span className="overline ml-3" style={{color:"#262C5B"}}>Active</span>
                  </div>
                </div>
              </div>
              </div>
              </div>
  );
};

export default Component;