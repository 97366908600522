import React, { useEffect, useState } from "react";
import "./index.css";
import Axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../../../Utils/Auth";
import StartupRightScreen from "./StartupRightScreen";
import { Avatar } from "@mui/material";
import Profile from "../../../Participants/ParticipantProfile";
const Startup = () => {
  const { programid } = useParams();
  const { setSessionExpire } = useAuth();
  const navigate = useNavigate();
  //   const [courses, setCourses] = useState([]);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  //   const moduleid = query.get("module");
  const startupid = query.get("startupid");
  const [startups, setstartups] = useState([]);
  const [step, setstep] = useState(0);

  const getstartups = () => {
    Axios({
      method: "get",
      url: `/api/incchat/startups/${programid}`,
    })
      .then((res) => {
        setstartups(res.data.data);
        if (res.data.data.length > 0) {
          navigate(
            `/service/incprogram/${programid}?tab=8&startupid=${res.data.data[0].user_id._id}`
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getstartups();
  }, []);

  return (
    <>
      {step === 1 ? (
        <Profile startupid={startupid} setstep={setstep} />
      ) : (
        <div className="incubator_analytics_root">
          <div className="row">
            <div className="col-lg-3">
              <div className="incubator_analytics_courses_root">
                <div className="incubator_analytics_courses_head">
                  <p className="caption">Startups</p>
                </div>
                <div className="incubator_analytics_courses_main">
                  {startups.length > 0
                    ? startups.map((item, i) => (
                        <>
                          <div
                            key={i}
                            className="incubator_analytics_courses mt-24"
                          >
                            <div
                              className={`incubator_analytics_coursename mb-8 point ${
                                item.user_id._id === startupid
                                  ? "showAnalyticsBg"
                                  : ""
                              }`}
                              onClick={() => {
                                navigate(
                                  `/service/incprogram/${programid}?tab=8&startupid=${item.user_id._id}`
                                );
                              }}
                            >
                              <div className="row my-1 mx-0 align-items-center p-10">
                                <div className="width_auto no-padding">
                                  <Avatar
                                    src={
                                      item.user_id ? item.user_id.avatar : null
                                    }
                                    className="dashboard_block_item_img"
                                    sx={{ width: 45, height: 45 }}
                                  />
                                </div>
                                <div className="col">
                                  <p className="p_small_black_bold no-margin no-padding">
                                    {item.user_id ? item.user_id.name : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <StartupRightScreen setstep={setstep} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Startup;
