import React, { useState } from "react";
// import ClearIcon from "@mui/icons-material/Clear";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import profile_img from "./../../Icons/profile.png";
import { toast } from "react-toastify";
import Axios from "axios";
// import OTPService from "../../../../Utils/OTP";
// import AttachmentIcon from "@mui/icons-material/Attachment";

const Step3 = (props) => {
  const {
    // activeStep,
    profile,
    setProfile,
    handleOnSubmit,
    profilePic,
    setProfilePicUrl,
    jobTitle,
    setJobTitle,
    // photoId,
    linkedProfile,
    setlinkedProfile,
    // setPhotoID,
    // phone,
    // setPhone,
    // setphoneVerified,
    // handleBack,
  } = props;

  // const [idType, setIDType] = useState("");
  // const [isdocuploading, setisdocuploading] = useState(false);
  const [ispicloading, setispicloading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (profilePic === null) return toast.warning("Upload Profile picture");
    handleOnSubmit(e);
  };

  // const handleIDUpload = (e) => {
  //   const image = e.target.files[0];
  //   if (image.size > 2048 * 1024) {
  //     toast.error("File size exceeded 2MB!", { position: "bottom-right" });
  //     return;
  //   }
  //   if (
  //     image.type !== "image/jpg" &&
  //     image.type !== "image/png" &&
  //     image.type !== "image/jpeg" &&
  //     image.type !== "application/pdf"
  //   ) {
  //     toast.error("Only Image And PDF files are allowed!", {
  //       position: "bottom-right",
  //     });
  //     return;
  //   }
  //   const formdata = new FormData();
  //   formdata.append("photoID", image);
  //   setisdocuploading(true);
  //   Axios({
  //     method: "post",
  //     url: " /api/upload/photoID",
  //     data: formdata,
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       if (data) {
  //         const filename = {
  //           docType: idType,
  //           downloadUrl: data.location,
  //         };
  //         setPhotoID(filename);
  //         setProfile({
  //           ...profile,
  //           photoId: filename,
  //         });
  //       } else {
  //         toast.error("Error in upload", { position: "bottom-right" });
  //       }
  //       setisdocuploading(false);
  //       toast.success("Photo ID Uploaded", { position: "bottom-right" });
  //     })
  //     .catch(() => {
  //       toast.error("Error in upload", { position: "bottom-right" });
  //     });
  // };

  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("profilepic", image);
    setispicloading(true);
    Axios({
      method: "post",
      url: "/api/startup/profilepic",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        data
          ? setProfilePicUrl(data.location)
          : toast.error("Error in upload", { position: "bottom-right" });
        setProfile({
          ...profile,
          profilePic: data.location,
        });
        setispicloading(false);
        toast.success("Profile Image upload", { position: "bottom-right" });
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="register_page_form_upload">
        {ispicloading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            {profilePic ? (
              <>
                <div className="profile_pic_block">
                  <Avatar
                    alt="Profile Pic"
                    src={profilePic}
                    sx={{ width: 90, height: 90 }}
                    variant="rounded"
                  />
                </div>
                <p
                  className="text-dark mt-2"
                  onClick={() => setProfilePicUrl(null)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </p>
              </>
            ) : (
              <>
                <label htmlFor="clogo">
                  <div className="register_page_form_com_logo" onClick={{}}>
                    <img src={profile_img} />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_pic"
                    id="clogo"
                    onChange={(e) => handlePicUpload(e)}
                  />
                </label>
                <p className="text-dark mt-1">Profile Picture</p>
              </>
            )}
          </>
        )}
      </div>

      <div className="register_page_form row">
        <div className="col-12">
          <label>Job Title*</label>
          <input
            placeholder="Job Title"
            required
            type="text"
            className="auth_input"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
          />
        </div>
        <div className="col-12">
          <label>LinkedIn Profile Url*</label>
          <input
            placeholder="LinkedIn Profile Url"
            required
            type="text"
            className="auth_input"
            value={linkedProfile}
            onChange={(e) => setlinkedProfile(e.target.value)}
          />
        </div>
        

      </div>
      {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
      {/* <div className="register_page_form row px-3 py-0">
        <div className="auth_news col-12">
          <label
            htmlFor="terms_condition"
            className="text-dark text_align_start"
          >
            By signing up, you agree to Zefyron Incubation&apos;s{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="nonreferrer"
              className="text-dark"
            >
              Terms & Conditions{" "}
            </a>
          </label>
        </div>
      </div> */}
        <div className="d-flex mb-14" style={{ marginTop: "163px" }}>
          <div className="">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM9 5V7H11V5H9Z" fill="#989898"/>
            </svg>
          </div>
          <div className="">
            <p style={{marginLeft:"6px"}}>
            All the documents submitted here will be treated as strictly confidential and will not be used/disclosed anywhere
            </p>
          </div>

        </div>
      <button
        className="gradient_CTA"
        onClick={(e) => handleSubmit(e)}
        style={{ width: "50%" }}
      >
        Edit
      </button>

      {/* <div className="loginbtns">
        {activeStep === 0 ? null : (
          <button
            type="submit"
            className="backbtn"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            <ArrowBackIosNewIcon />
          </button>
        )}

        {activeStep === 2 ? (
          <button
            type="submit"
            className="forbtn txt"
            onClick={(e) => handleSubmit(e)}
          >
            Submit
          </button>
        ) : (
          <button
            type="submit"
            className="forbtn"
            onClick={(e) => handleSubmit(e)}
          >
            <ArrowForwardIosIcon />
          </button>
        )}
      </div> */}
    </form>
  );
};

export default Step3;
