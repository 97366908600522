import React from "react";
import ViewCourse from "./ViewCourses/ViewCourse";
import CreatCourse from "./CreatCourses/CreatCourse";

const LandingPage = (props) => {
  const { setLearningState, learningState } = props;
  return (
    <>
      {
        learningState === 1 ? <ViewCourse
          setLearningState={setLearningState}
        /> : learningState === 2 ? <CreatCourse
          setLearningState={setLearningState}
        /> : null
      }
    </>
  );
};

export default LandingPage;
