import React, { useEffect, useState } from 'react'
import search from "./../../../../Assets/images/icons/search.png";
import bi_cart from "./../../../../Assets/images/icons/bi_cart.png";
import Card from './Card'
import './asset.css'
import ViewAsset from "./ViewAsset/ViewAsset"
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { useNavigate } from 'react-router-dom';
import { KeyboardBackspace} from "@mui/icons-material"
import { AssetsView } from '../../../../Components/SkeletonLoader/MyAssetsSkeleton';
const Assets = ({setCoWorkingStep,incubatorId,setAssetView}) => {
    const [assetViewDetails, setAssetViewDetails] = useState(0)
    const [assets, setAssets] = useState([])
    const { setSessionExpire, user } = useAuth();
    const [isLoading,setIsLoading] = useState(false)
    const navigate = useNavigate()
    // const getAssets = async () => {
    //     await Axios({
    //         url: `/api/asset/all`,
    //         method: "get",
    //     }).then((res) => {
    //         setAssets(res.data.data);
    //         console.log(res.data.data)
    //     }).catch((err) => {
    //         if (err.response && err.response.status === 401) {
    //             setSessionExpire(true);
    //         }
    //     })
    // }
    
    const getIncubatorAssets = async () => {
        setIsLoading(true)
        await Axios({
            url: `/api/asset/incubator/${incubatorId}`,
            method: "get",
        }).then((res) => {
            setAssets(res.data.data);
            setIsLoading(false)
            // console.log(res.data.data)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        // getAssets()
        getIncubatorAssets();
    }, [])

    return (
        <>
            {
                assetViewDetails === 0 ?
                    <div className='startup_assets_root'>
                         <div className="viewReq_head">
                <button className='transparent_cta btncta' onClick={() => {
                    setAssetView(0)
                }}><KeyboardBackspace /> Back</button>
            </div>
            <hr/>
                        <div className="incubation_prorgram_search_head">
                            <div className="row">
                                <div className="col-lg-9 col-md-8 col-6">
                                    <div className="incubation_programm_searchBoxDiv w_100">
                                        <input type="text" name="" id="" placeholder='Type here to search' />
                                        <button className="p_Bold_secBlue14">
                                            <img src={search} alt="search" />
                                            Search
                                        </button>
                                    </div>

                                </div>
                                <div className="col-lg-3 col-md-4 col-6">
                                    <div className="startup_assets_head_addToCard" onClick={() => {
                                        setCoWorkingStep(6)
                                        navigate(`/dashboard/${user.type}?tab=9&cw=6&back=asset`)
                                    }}>
                                        <p className="body1 mr-8">My Cart</p>
                                        <img src={bi_cart} alt="bi_cart" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="startup_assets_main mt-24">
                            <div className="row">
                                {
                                    isLoading ? <AssetsView isAsset={true}/> : assets.length > 0 ?
                                        assets.map((data, i) => <Card
                                            key={i}
                                            setAssetView={setAssetViewDetails}
                                            assetView={assetViewDetails}
                                            data={data}
                                        />) : null
                                }
                            </div>
                        </div>

                    </div> : assetViewDetails === 1 ? <ViewAsset
                        setAssetView={setAssetViewDetails}
                        incubatorId={incubatorId}
                    /> : null
            }
        </>
    )
}

export default Assets