import React from "react";
// import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";

import supportimage from "../../../Assets/images/events/ri_customer-service-fill.jpg";
import priceimage from "../../../Assets/images/events/cil_view-module.jpg";
import moment from "moment-timezone";

const Card = (props) => {
  const { service, handleserviceid, customer, createdAt } = props;
  // const navigate = useNavigate();
  // const get_Date = (date) => {
  //   let d = new Date(date);
  //   return d.toLocaleDateString("en-US", {
  //     weekday: "short",
  //     month: "long",
  //     day: "numeric",
  //   });
  // };

  return (
    <div className="col-12 col-md-6 point mb-24">
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide"></div>
        <div className=" w_100" style={{padding:"20px"}}>
          <div
            onClick={() => handleserviceid(customer._id, service._id)}
            className="row"
          >
            <div className="col-md-8 col-12">
              <div className="">
                  <div className="user_incubation_course_AvatarGroup_root m-0 mb-16">
                    <div className="user_incubation_course_AvatarGroups">
                      <Avatar src={customer.avatar} variant="rounded" />
                    </div>
                    <div className="user_incubation_course_startupEnrolled">
                      <p className="subtitle">
                        {customer.name}
                      </p>
                    </div>
                  </div>
                  <div className="user_incubation_course_rightTitle mb-16">
                    <div className="caption"><span style={{ color:"#262C5B"}}>Service : </span> {service.servicename}</div>
                  </div>
                  {service.pricing ?
                <div className="user_course_tags d-flex align-items-center">
                  <p className="incubation_chips">{service.keyword}</p>
                </div>
                : null
                  }
              </div>
            </div>
            <div className="col-md-4 col-12" style={{borderLeft: "2px solid #E6E6E6"}}>
                {/* <div className="user_course_tags d-flex align-items-center">
                  <p className="incubation_chips">{service.keyword}</p>
                </div> */}
                  <div className=" d-flex flex-column h-100">
                    <div className="user_incubation_course_right_th mb-20">
                      <span className="caption" style={{ color: "#565656" }}>
                        {/* {createdAt} */}
                        {moment(`${createdAt}`).format("DD/MM/YYYY")}
                      </span>
                    </div>
                    {service.pricing ?
                      null
                    : 
                    <div className="user_course_tags d-flex align-items-center">
                      <p className="incubation_chips">{service.keyword}</p>
                    </div>
                    }
                    {service.pricing ?
                    <div className="user_incubation_course_right_th d-flex align-items-center mb-20">
                      <span className="mr-2">
                        <img src={priceimage} />
                      </span>
                      <p className="caption">€ {service.pricing}</p>
                    </div>
                    : null}
                    {service.customerSupport ?
                    <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2">
                        <img src={supportimage} />
                      </span>
                      <p className="caption"> {service.customerSupport}</p>
                    </div>
                    : null }
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
