import React from 'react'

const HeadSquare = ({name, value, img}) => {
    return (
        <div className="col-12 col-md-3 col-lg-3">
            <div className="analytictop-box">
                <div className="row m-0">
                    <p className="mb-12 subtitle color_dark_black2">
                        {name}
                    </p>
                    <div className="w-100 d-flex justify-content-between align-items-center">
                        <h3 className="w-fit" style={{ color: "#580167" }}>
                            {value}
                        </h3>
                        <img className="" src={img} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeadSquare