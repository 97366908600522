import React, {useEffect, useState} from 'react'
import DeclinedCard from "./DeclinedCard"
import Axios from 'axios'
import { useAuth } from "../../../../../Utils/Auth";


const Decline = ({setIncubationState}) => {
    const [programs, setPrograms] = useState([])
    const { setSessionExpire } = useAuth();

    const fetchAllDecline = async () => {
        await Axios({
            url: `/api/incubatorProgram/declined`,
            method: "get",
        }).then((res) => {
            if (res.data.success)
                setPrograms(res.data.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    useEffect(() => {
        fetchAllDecline()
    }, [])
    return <div className="incubation_profile_card_root">
        <div className="row">
            {
                programs && programs.length > 0 ?
                programs.map((program, i) => <DeclinedCard
                    key={i}
                    setIncubationState={setIncubationState}
                    program={program}
                />):""
            }
        </div>
    </div>
}

export default Decline