import React, { useEffect, useState } from 'react'
import search from "./../../../../Assets/images/icons/search.png";
import bi_cart from "./../../../../Assets/images/icons/bi_cart.png";
import Card from "./Card"
import ViewAsset from "./ViewEstate/ViewEstate"
// import MyCart from '../MyCart/MyCart';
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { useNavigate } from 'react-router-dom';

const RealEstate = ({setCoWorkingStep}) => {
  const [assetView, setAssetView] = useState(0)
  const [estate, setEstate] = useState([])
  const { setSessionExpire, user } = useAuth();
  const navigate = useNavigate()

  const getEstate = async () => {
    await Axios({
      url: `/api/realEstate/all`,
      method: "get",
    }).then((res) => {
      setEstate(res.data.data);
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    })
  }

  useEffect(() => {
    getEstate()
  }, [])

  return (
    <>
      {
        assetView === 0 ?
          <div className='startup_assets_root'>
            <div className="incubation_prorgram_search_head">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-6">
                  <div className="incubation_programm_searchBoxDiv w_100">
                    <input type="text" name="" id="" placeholder='Type here to search' />
                    <button className="p_Bold_secBlue14">
                      <img src={search} alt="search" />
                      Search
                    </button>
                  </div>

                </div>
                <div className="col-lg-4 col-md-4 col-6">
                  <div className="startup_assets_head_addToCard" onClick={() => {
                    setCoWorkingStep(6)
                    navigate(`/dashboard/${user.type}?tab=9&cw=6&back=estate`)
                  }}>
                    <p className="body1 mr-8">My Cart</p>
                    <img src={bi_cart} alt="bi_cart" />
                  </div>
                </div>
              </div>
            </div>
            <div className="startup_assets_main mt-24">
              <div className="row">
                {
                  estate.length > 0 ?
                    estate.map((data, i) => <Card
                      key={i}
                      setAssetView={setAssetView}
                      assetView={assetView}
                      data={data}
                    />) : null
                }
              </div>
            </div>
          </div> : assetView === 1 ? <ViewAsset
            setAssetView={setAssetView}
          /> : null
      }
    </>
  )
}

export default RealEstate