import React, { useEffect, useState } from "react";
import cloud_upload from "../../../../Assets/images/upload_doc.png";
import Delete from "../../../../Assets/images/incubatorDash/delete.png";
import { toast } from "react-toastify";
import Axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useAuth } from "../../../../Utils/Auth";

const CreateAssignment = ({ setAddSessionState }) => {
  const navigate = useNavigate();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const module_id = query.get("module");
  const assignment_id = query.get("assignment");
  const course_id = query.get("course");
  // const { user } = useAuth();
  const { programid } = useParams();
  const [assignmentName, setAssignmentName] = useState("");
  const [assignmentInstruction, setAssignmentInstruction] = useState("");
  const [assignmentDocUrl, setAssignmentDocUrl] = useState("");
  const [assignmentupdateBool, setAssignmentupdateBool] = useState(false);

  const getAssignment = async (id) => {
    await Axios({
      url: `/api/mentorModules/assignment/${id}`,
      method: "get",
    })
      .then((res) => {
        setAssignmentupdateBool(true);
        setAssignmentName(res.data.data.name);
      })
      .catch(() => {
        toast.error("Try again");
      });
  };

  useEffect(() => {
    if (assignment_id) {
      getAssignment(assignment_id);
    }
  }, []);

  const handleDocUpload = async (e) => {
    const doc = e.target.files[0];
    if (doc.size > 2 * 1024 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("material", doc);
    await Axios({
      method: "post",
      url: `/api/mentorModules/material/upload`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setAssignmentDocUrl(data.location);
        toast.success("File uploaded", { position: "bottom-right" });
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  const CreateAssign = async (e) => {
    e.preventDefault();
    await Axios({
      url: `/api/mentorModules/assignment/create/${course_id}/${module_id}`,
      method: "post",
      data: {
        name: assignmentName,
        instructions: assignmentInstruction,
        document: assignmentDocUrl,
      },
    })
      .then(() => {
        setAddSessionState(0);
        toast.success("Assigment Created");
        navigate(
          `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
        );
        // setassessment(res.data.data);
      })
      .catch((err) => {
        toast.error("Try again");
        console.log(err);
      });
  };

  const UpdateAssignment = async (e) => {
    e.preventDefault();
    await Axios({
      url: `/api/mentorModules/assignment/update/${assignment_id}`,
      method: "put",
      data: {
        name: assignmentName,
        instructions: assignmentInstruction,
        document: assignmentDocUrl,
      },
    })
      .then(() => {
        setAddSessionState(0);
        toast.success("Assigment Updated");
        navigate(
          `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
        );
        // setassessment(res.data.data);
      })
      .catch(() => {
        toast.error("Try again");
      });
  };

  return (
    <div className="mt-4">
      <p
        className="body1 mb-3 point"
        onClick={() => {
          setAddSessionState(0);
          navigate(
            `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
          );
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-left mb-1 mx-2"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
          />
        </svg>
        Back
      </p>
      <h4>Add assignment</h4>
      <div className="create_course_form_main">
        <div className="row">
          <div className="col-12 create_course_form_maindiv">
            <label htmlFor="course_title" className="caption color_dark_blue">
              Name of assigment
            </label>
            <input
              type="text"
              className="body1"
              placeholder="Enter the name of assigment"
              value={assignmentName}
              onChange={(e) => setAssignmentName(e.target.value)}
            />
          </div>
          <div className="col-12 create_course_form_maindiv">
            <label htmlFor="course_desc" className="caption color_dark_blue">
              Instructions
            </label>
            <textarea
              type="text"
              className="body1"
              placeholder="Enter industry or keyword"
              value={assignmentInstruction}
              onChange={(e) => setAssignmentInstruction(e.target.value)}
            />
          </div>
          <div className="col-12 create_course_form_maindiv">
            {assignmentDocUrl ? (
              <div className="program_upload_imglater w_80">
                <div className="d-flex align-items-center">
                  <div className="program_upload_imgImg">
                    <img
                      src={cloud_upload}
                      alt="cloud_upload"
                      style={{ padding: "20px" }}
                    />
                  </div>
                  <div className="program_upload_imgName">
                    <p className="caption color_dark_blue">{assignmentName}</p>
                  </div>
                </div>
                <div className="program_upload_imgdlt">
                  <img
                    src={Delete}
                    alt="cloud_upload"
                    style={{ padding: "20px", cursor: "pointer" }}
                    onClick={() => setAssignmentDocUrl("")}
                  />
                </div>
              </div>
            ) : (
              <div className="program_upload_img w_80">
                {/* <label htmlFor="uplaodImg"> */}
                <img
                  src={cloud_upload}
                  alt="cloud_upload"
                  style={{ padding: "20px" }}
                />
                <p className="caption color_dark_blue">Upload Document</p>
                {/* </label> */}
                <input 
                  type="file" 
                  id="uplaodImg" 
                  onChange={handleDocUpload}
                  accept="image/*, application/pdf,text/plain,application/msword,.doc, .docx"
                />
              </div>
            )}
          </div>
          <div className="col-12 create_course_form_maindivBtn">
            {assignmentupdateBool ? (
              <button
                className="primaryFilled_activeCta"
                onClick={UpdateAssignment}
              >
                Update
              </button>
            ) : (
              <button
                className="primaryFilled_activeCta"
                onClick={CreateAssign}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAssignment;
