import React, { useState, useEffect } from 'react'
import AssessmentCard from './AssessmentCard'
import './assessment.css'
import Axios from 'axios';
import { useAuth } from "../../../../../Utils/Auth";
import AssessmentResult from "./Assessmentresult"
import { useParams } from 'react-router-dom';

const Assessment = () => {
  const [assessment, setAssessment] = useState([]);
  const [assessmentState, setAssessmentState] = useState(1);
  const { setSessionExpire } = useAuth();
  const {programid} = useParams()

  const getAssessment = async () => {
    await Axios({
      url: `/api/mentorModules/assessment/incubator/course/${programid}`,
      method: "GET",
    }).then((res) => {
      setAssessment(res.data.data)
    }).catch(() => {
      // if (err.response.status === 401) {
        setSessionExpire(true);
      // }
    });
  }

  useEffect(()=>{
    getAssessment()
  },[])

  return <div className="incubator_assessment_root">
    {
      assessmentState === 1 ?
      <div className="incubator_assessment_main">
        <div className="incubator_assessment_tableRoot">
          <table className="incubator_assessment_table">
            <thead className="incubator_assessment_thead">
              <tr className="incubator_assessment_thead_tr">
                <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                  <p className="body2 color_dark_grey2">Submitted By</p>
                </td>
                <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                  <p className="body2 color_dark_grey2">Course Name</p>
                </td>
                <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                  <p className="body2 color_dark_grey2">Assessment Title</p>
                </td>
                <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                  <p className="body2 color_dark_grey2">Submitted on</p>
                </td>
                <td className="incubator_assessment_thead_td incubator_assessment_td_score">
                  <p className="body2 color_dark_grey2">Score</p>
                </td>
                <td className="incubator_assessment_thead_td incubator_assessment_td_view">

                </td>
              </tr>
            </thead>
            <tbody className="incubator_assessment_tbody">
              {
                assessment && assessment.length > 0 ?
                assessment.map((assessment, i) => <AssessmentCard
                  key={i}
                  assessment={assessment}
                  setAssessmentState={setAssessmentState}
                />): null
              }
            </tbody>
          </table>
        </div>
      </div> : 
      <AssessmentResult
        setAssessmentState={setAssessmentState}
      />
    }
  </div>
}

export default Assessment