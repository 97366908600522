import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import Axios from "axios"
import { toast } from 'react-toastify'

const UploadDocComp = (props) => {
    const [isdocloading, setisDocUploading] = useState(false)
    const { v, documentsRequired, i, setdocumentsRequired } = props;

    const handlecontractUpload = (e) => {
        const image = e.target.files[0];
        const fileSize = image.size / 1024 / 1024; // in MiB
        if (fileSize > 25) {
            toast.error("File size exceeded 25MB!");
            return;
        }
        const formdata = new FormData();
        formdata.append("incDocuments", image);
        setisDocUploading(true);
        Axios({
            method: "post",
            url: "/api/upload/incDocuments",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                if (data.success) {
                    let documents = [...documentsRequired];
                    documents[i].doc_link = data.location;
                    setdocumentsRequired(documents);
                    setisDocUploading(false);
                    toast.success(`document uploaded`);
                }
            })
            .catch(() => {
                toast.error("Error in upload");
            });
    };

    return <div className="authIdCard justify-content-between">
        <div className="">
            <label onClick={() => console.log(documentsRequired)}>Please attach your {v.doc_name}</label>
        </div>
        {isdocloading ? (
            <CircularProgress style={{ color: "black" }} />
        ) : (
            <div className="">
                <label htmlFor="investor_id" className="point">
                    <div className='startup_uploaddoc'>
                        <p className="caption d-flex align-items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="22"
                                fill="none"
                                viewBox="0 0 18 22"
                            >
                                <path
                                    fill="#262C5B"
                                    d="M17.03 5.764L11.986.72a.749.749 0 00-.53-.22H1.5a.75.75 0 00-.75.75v19.5c0 .415.335.75.75.75h15a.75.75 0 00.75-.75V6.296c0-.2-.08-.391-.22-.532zm-1.51.877h-4.41V2.23l4.41 4.41zm.043 13.171H2.437V2.188h7.079V7.25a.984.984 0 00.984.984h5.063v11.579zm-6.75-6.328H4.5a.188.188 0 00-.188.188v1.125c0 .103.085.187.188.187h4.313A.188.188 0 009 14.797v-1.125a.188.188 0 00-.188-.188zm-4.5-3v1.125c0 .104.084.188.187.188h9a.188.188 0 00.188-.188v-1.125a.188.188 0 00-.188-.187h-9a.188.188 0 00-.188.187z"
                                ></path>
                            </svg>
                            <span className='ml-8'>Upload</span>
                        </p>
                    </div>
                    <input
                        type="file"
                        accept="application/pdf,image/*,.doc,.docx,.ppt"
                        name="investor_id"
                        id="investor_id"
                        className="mt-0 point"
                        onChange={(e) => handlecontractUpload(e)}
                    />
                </label>
            </div>
        )}
    </div>
}

export default UploadDocComp