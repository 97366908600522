import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
import { toast } from "react-toastify";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from "@mui/material/TextField";
import Tooltip from '@mui/material/Tooltip';
import { Avatar } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
// import Checkbox from "@mui/material/Checkbox";

const index = ({ programid, handleClose, getmeets }) => {
  const [time, setTime] = useState(new Date());
  const { setSessionExpire,user } = useAuth();
  const [sendto, setsendto] = useState("");
  const [startup, setstartup] = useState(false);
  const [mentor, setmentor] = useState(false);
  const [student, setStudent] = useState(false);
  const [custom, setcustom] = useState(false);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState(0.5);

  const [startups, setstartups] = useState([]);
  const [assigned, setassigned] = useState([]);
  const [totalmentor, settotalmentor] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [drop, setdrop] = useState(false);
  const [students,setStudents] = useState([])
  const [allUsersAccess,setAllUsersAccess] = useState(true);

  const createmeet = async () => {
    if (title.length === 0) return toast.warning("Title is missing");
    if(user.type === 'incubator'){
      var notify = assigned;
      console.log(notify);
    await Axios({
      method: "post",
      url: `/api/incmeet/create/${programid}`,
      data: {
        schedule: time,
        title,
        startup:startup.toString(),
        mentor:mentor.toString(),
        student:student.toString(),
        duration,
        notify,
        custom:custom.toString(),
        allAccess:allUsersAccess.toString()
      },
    })
      .then((res) => {
        if (res.data.success) toast.success("Meeting scheduled");
        getmeets();
        handleClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
        handleClose();
      });
    }else if(user.type === 'mentor' || user.type === 'investor'){
      await Axios({
        method: "post",
        url: `/api/incmeet/particular/user/meet/create`,
        data: {
          schedule: time,
          title,
          startup,
          mentor,
          student,
          duration,
          notify,
          programid,
        },
      })
        .then((res) => {
          if (res.data.success) toast.success("Meeting scheduled");
          getmeets();
          handleClose();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            setSessionExpire(true);
          } else {
            toast.error(err.response.data.error);
          }
          handleClose();
        });
    }
    
  };

  //  assiged startup
  const [addUsers,setAddUsers] = useState([])
  const assign = (companyName, logo, id,_id,data) => {
    // console.log('here is the id',_id)
    const user_id = { companyName, logo, id};
    setassigned((prev) => [...prev, user_id]);
    setAddUsers((prev)=>[...prev,data])
    setAllUsersArr(allUsersArr.filter((user) => user?._id !== _id));
  };
  const removeassign = (companyName, logo, id,_id,data) => {
    // const user_id = { profile: { companyName, logo, id,_id } };
    setAddUsers(addUsers.filter((user)=>user?._id !== _id))
    setAllUsersArr((prev) => [...prev, data]);
    setassigned(assigned.filter((user) => user.id !== id));
    // console.log(companyName,logo);
  };

  const fetchallstartups = async () => {
    await Axios({
      url: `/api/incubatorProgram/members/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setstartups(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const fetchAllStudents = async () => {
    await Axios({
      url: `/api/incubator-student/students/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setStudents(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const fetchallmentors = async () => {
    await Axios({
      url: `/api/incmentor/activementors/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {

        settotalmentor(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchallstartups();
    fetchallmentors();
    fetchAllStudents()
  }, []);

  useEffect(() => {
    if (totalmentor.length > 0) {
      totalmentor.map((ele) => {
        const user_id = { profile: { companyName: ele.profile.name, logo: ele.profile.profilePic, id: ele.profile.id } };
        // console.log(user_id,"mentor data");
        setstartups((prev) => [...prev, user_id]);
      })
    }
  }, [totalmentor]);

  useEffect(() => {
    // console.log("SearchText",startups)
    if (SearchText !== "") {
      setdrop(true);
    } else {
      setdrop(false);
    }
  }, [SearchText]);


  const [allUsersArr,setAllUsersArr] = useState([])

  const allUsers = () => {
    const users = []
    startups.map(startup=>{
      users.push(startup)
    })
    students.map(student=>{
      users.push(student)
    })
    totalmentor.map(mentor=>{
      users.push(mentor)
    })
    console.log('users',users);
    setAllUsersArr(users);
  }


  useEffect(()=>{
    allUsers()
  },[custom])

  useEffect(()=>{
    if(allUsersAccess){
      setsendto('all')
    }
  },[allUsersAccess])
  return (
    <>
      <div className="">
        <svg
          onClick={() => handleClose()}
          style={{ position: "absolute", right: "24px" }}
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 0C16.295 0 21 4.70505 21 10.5C21 16.295 16.295 21 10.5 21C4.70505 21 0 16.295 0 10.5C0 4.70505 4.70505 0 10.5 0Z"
            fill="#0D0D0D"
          />
          <path
            d="M13.641 8.35441L11.5588 10.4371L13.641 12.5198C13.9512 12.83 13.9512 13.3317 13.641 13.6415C13.4864 13.7961 13.2841 13.8739 13.0809 13.8739C12.8781 13.8739 12.6745 13.7966 12.5198 13.6415L10.4367 11.5588L8.35356 13.6415C8.1989 13.7961 7.99656 13.8739 7.79336 13.8739C7.59059 13.8739 7.38739 13.7966 7.23231 13.6415C6.92257 13.3317 6.92257 12.83 7.23231 12.5198L9.31543 10.4371L7.23231 8.35441C6.92257 8.04467 6.92257 7.54204 7.23231 7.2323C7.54205 6.92257 8.04382 6.92257 8.35356 7.2323L10.4367 9.31542L12.5198 7.2323C12.8295 6.92257 13.3313 6.92257 13.641 7.2323C13.9512 7.54204 13.9512 8.04467 13.641 8.35441Z"
            fill="#E2E2E2"
          />
        </svg>
        <div className="w-100 d-flex">
          <h5 className="w-fit" style={{ color: "#262C5B" }}>
            Schedule Meet
          </h5>
        </div>

        <div className="register_page_form row" style={{ marginTop: "40px" }}>
          <div className="col-12">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <label style={{ margin: "0px" }} className="mr-8">Title</label>
                <Tooltip title="Link will be shared with the schedule">
                  <ErrorIcon sx={{ color: "#565656", fontSize: "medium" }} />
                </Tooltip>
              </div>
              <input
                placeholder="Enter Title"
                required
                type="text"
                className="auth_input"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />

              <p className="overline pb-1">Link will be shared with the schedule</p>
            </div>
            
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <label>Time</label>
                  <div className="auth_back_loginform ">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={time}
                        onChange={(v) => setTime(v)}
                        renderInput={(params) => (
                          <TextField {...params} variant={"standard"} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-6">
                  <label>Duration</label>
                  <select name="" id="" onChange={(e) => setDuration(e.target.value)}>
                    <option value="1800">30 Minutes</option>
                    <option value="3600">1 Hour</option>
                    <option value="5400">1 Hour 30 Minutes</option>
                    <option value="7200">2 Hour</option>
                    <option value="9000">2 Hour 30 Minutes</option>
                    <option value="10800">3 Hour</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div className="col-12">
              <label className="auth_label mt-3"> For </label>
            </div>
            <div className="col-12 d-flex align-items-center">
              <div className="w-fit d-flex align-items-center mr-16">
                <div className="w-fit mr-12" onClick={() => {
                  setstartup(false);
                  setmentor(false);
                  setcustom(false);
                  setStudent(false);
                  setAllUsersAccess(true)
                  setsendto("all")
                }}>
                  {sendto === "all"  ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
                <span
                  className="caption"
                  style={{ color: "#262C5B", marginTop: "4px" }}
                >
                  All
                </span>
              </div>

              <div className="w-fit d-flex align-items-center mr-16">
                <div className="w-fit mr-12" onClick={() => {
                  setstartup(true);
                  setmentor(false);
                  setcustom(false);
                  setStudent(false);
                  setAllUsersAccess(false)
                  setsendto("startup")
                }}>
                  {sendto === "startup" ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
                <span
                  className="caption"
                  style={{ color: "#262C5B", marginTop: "4px" }}
                >
                  Startup
                </span>
              </div>
              <div className="w-fit d-flex align-items-center mr-16">
                <div className="w-fit mr-12" onClick={() => {
                  setstartup(false);
                  setmentor(true);
                  setcustom(false);
                  setStudent(false);
                  setAllUsersAccess(false)
                  setsendto("mentor")
                }}>
                  {sendto === "mentor" ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
                <span
                  className="caption"
                  style={{ color: "#262C5B", marginTop: "4px" }}
                >
                  {" "}
                  Mentor
                </span>
              </div>
              <div className="w-fit d-flex align-items-center mr-16">
                <div className="w-fit mr-12" onClick={() => {
                  setstartup(false);
                  setmentor(false);
                  setcustom(false);
                  setStudent(true);
                  setAllUsersAccess(false)
                  setsendto("student")
                }}>
                  {sendto === "student" ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
                <span
                  className="caption"
                  style={{ color: "#262C5B", marginTop: "4px" }}
                >
                  {" "}
                  Student
                </span>
              </div>
              <div className="w-fit d-flex align-items-center">
                <div className="w-fit mr-12" onClick={() => {
                  setstartup(false);
                  setmentor(false);
                  setcustom(true);
                  setStudent(false);
                  setAllUsersAccess(false)
                  setsendto("custom")
                }}>
                  {sendto === "custom" ? (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                        fill="url(#paint0_linear_2010_18837)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2010_18837"
                          x1="18.9796"
                          y1="6.24106e-07"
                          x2="-3.04433"
                          y2="4.26681"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
                <span
                  className="caption"
                  style={{ color: "#262C5B", marginTop: "4px" }}
                >
                  {" "}
                  Custom
                </span>
              </div>
            </div>

            {custom ?
              <>
                <div className="col-12">
                  <label className="auth_label">Custom Search</label>
                  <input
                    placeholder="Custom Search"
                    required
                    type="text"
                    className="auth_input col-12 "
                    onClick={() => setdrop(!drop)}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={SearchText}
                  />
                </div>
                {drop ? (
                  <div className="col-12">
                    <div
                      className={`mentor_tasks_options_root row ${drop ? "show" : "hide"
                        }`}
                    >
                      {allUsersArr && allUsersArr.length > 0
                        ? allUsersArr
                          .filter((ele) => {
                            if (SearchText === "") return ele;
                            else if (
                              ele.profile?.companyName
                                ?.toLowerCase()
                                ?.includes(SearchText.toLocaleLowerCase())
                            ) {
                              return ele;
                            } else if(ele.profile?.name?.includes(SearchText.toLocaleLowerCase())){
                              return ele;
                            }else {
                              return ele.student?.name?.includes(SearchText.toLocaleLowerCase())
                            }
                          })
                          .map((data, i) => (
                            <div
                              className="mentor_tasks_options point w-100"
                              key={i}
                            >
                              <div className="d-flex justify-content-between w-100 mx-2">
                                <div className="d-flex align-items-center">
                                  <img src={data.profile?.logo ? data.profile?.logo : data.profile?.profilePic ? data.profile?.profilePic : data.student?.profile_pic  } alt="profile" />
                                  <p className="caption color_dark_black2">
                                    {data.profile?.companyName ?data.profile?.companyName : data.profile?.name ? data.profile?.name : data.student?.name}
                                   
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    assign(
                                      data.profile?.companyName ?data.profile?.companyName : data.profile?.name ? data.profile?.name : data.student?.name,
                                      data.profile?.logo ? data.profile?.logo : data.profile?.profilePic ? data.profile?.profilePic : data.student?.profile_pic,
                                      
                                      data.profile?.id ? data.profile?.id : data.student?.id,
                                      data._id,
                                      data
                                    );
                                    setdrop(!drop);
                                  }}
                                >
                                  <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 3.25C0 2.38805 0.34241 1.5614 0.951903 0.951903C1.5614 0.34241 2.38805 0 3.25 0H14.75C15.612 0 16.4386 0.34241 17.0481 0.951903C17.6576 1.5614 18 2.38805 18 3.25V14.75C18 15.612 17.6576 16.4386 17.0481 17.0481C16.4386 17.6576 15.612 18 14.75 18H3.25C2.38805 18 1.5614 17.6576 0.951903 17.0481C0.34241 16.4386 0 15.612 0 14.75V3.25Z"
                                      fill="#262C5B"
                                    />
                                    <path
                                      d="M9 4C9.19891 4 9.38968 4.07902 9.53033 4.21967C9.67098 4.36032 9.75 4.55109 9.75 4.75V8.25H13.25C13.4489 8.25 13.6397 8.32902 13.7803 8.46967C13.921 8.61032 14 8.80109 14 9C14 9.19891 13.921 9.38968 13.7803 9.53033C13.6397 9.67098 13.4489 9.75 13.25 9.75H9.75V13.25C9.75 13.4489 9.67098 13.6397 9.53033 13.7803C9.38968 13.921 9.19891 14 9 14C8.80109 14 8.61032 13.921 8.46967 13.7803C8.32902 13.6397 8.25 13.4489 8.25 13.25V9.75H4.75C4.55109 9.75 4.36032 9.67098 4.21967 9.53033C4.07902 9.38968 4 9.19891 4 9C4 8.80109 4.07902 8.61032 4.21967 8.46967C4.36032 8.32902 4.55109 8.25 4.75 8.25H8.25V4.75C8.25 4.55109 8.32902 4.36032 8.46967 4.21967C8.61032 4.07902 8.80109 4 9 4Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                ) : null}

                <div className="col-12 d-flex flex-wrap mt-24">
                  {addUsers && addUsers.length > 0
                    ? addUsers.map((ele, i) => (
                      <div
                        className="w-fit mt-8 assigned-items d-flex mr-8"
                        key={i}
                      >
                        <Avatar
                          src={ele.profile?.logo ? ele.profile?.logo : ele.profile?.profilePic ? ele.profile?.profilePic : ele.student?.profile_pic}
                          sx={{ width: 24, height: 24 }}
                          style={{ marginRight: "4px" }}
                        />
                        <div className="ele_hover" style={{ paddingLeft: "40px" }}>
                          <div className=" d-flex align-items-center">
                            <p
                              className="caption mr-12"
                              style={{ color: "#262C5B" }}
                            >
                              {ele.profile?.companyName ?ele.profile?.companyName : ele.profile?.name ? ele.profile?.name : ele.student?.name}
                            </p>

                            <svg
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                
                                removeassign(ele.profile?.companyName ?ele.profile?.companyName : ele.profile?.name ? ele.profile?.name : ele.student?.name,
                                  ele.profile?.logo ? ele.profile?.logo : ele.profile?.profilePic ? ele.profile?.profilePic : ele.student?.profile_pic,
                                  
                                  ele.profile?.id ? ele.profile?.id : ele.student?.id,
                                  ele._id,
                                  ele);
                              }}
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 5C0 2.235 2.235 0 5 0C7.765 0 10 2.235 10 5C10 7.765 7.765 10 5 10C2.235 10 0 7.765 0 5ZM6.445 7.15C6.64 7.345 6.955 7.345 7.15 7.15C7.34 6.955 7.34 6.635 7.15 6.445L5.705 5L7.15 3.555C7.345 3.36 7.345 3.045 7.15 2.85C6.955 2.655 6.64 2.655 6.445 2.85L5 4.295L3.555 2.85C3.36 2.655 3.045 2.655 2.85 2.85C2.75637 2.94341 2.70376 3.07024 2.70376 3.2025C2.70376 3.33476 2.75637 3.46158 2.85 3.555L4.295 5L2.85 6.445C2.75637 6.53842 2.70376 6.66524 2.70376 6.7975C2.70376 6.92976 2.75637 7.05658 2.85 7.15C3.045 7.345 3.36 7.345 3.555 7.15L5 5.705L6.445 7.15Z"
                                fill="#262C5B"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    ))
                    : null}
                </div>
              </>
              : null
            }

          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button
            onClick={() => createmeet()}
            className="gradient_CTA width_fit mt-3 color-black"
            type="button"
          >
            SCHEDULE
          </button>
        </div>
      </div>
    </>
  );
};

export default index;
