import React, { useEffect } from "react";
import MyServices from "./MyServices";
import Explore from "./Explore";
import ActiveServices from "./ActiveServices";

const LandingPage = (props) => {
  const { servicebool, setservicebool } = props;
  useEffect(()=>{
    var flag=false;
    if(flag)
      console.log(setservicebool);
  },[]);
  

  return (
    <>
      {servicebool === 1 ? (
        <ActiveServices />
      ) : servicebool === 2 ? (
        <MyServices />
      ) : servicebool === 3 ? (
        <Explore />
      ) : (
        <Explore />
      )}
    </>
  );
};

export default LandingPage;
