import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../Utils/Auth";
import { KeyboardBackspace } from "@mui/icons-material";
import Axios from "axios";
import "./AddAccount.css";
import Query from "../../../../../Utils/Query/Query";
const AddAccount = ({ setAccountStep }) => {
  const query = Query();
  const isEdit = Boolean(query.get("account-edit"));
  const accountId = query.get("id");
  const { setUser, user } = useAuth();
  const [holderName, setHolderName] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [ifsc, setIfsc] = useState(null);
  const [accNo, setAccNo] = useState(null);

  const [businessName, setBusinessName] = useState(user.name);
  const [businessType, setBusinessType] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gst, setGst] = useState("");

  const handleBankDetailsUpload = async (e) => {
    e.preventDefault();
    if (
      accNo === null ||
      bankName === null ||
      ifsc === null ||
      holderName === null ||
      businessName === null ||
      businessType === null ||
      address.length < 0 ||
      pinCode.length < 0 ||
      gst.length < 0 ||
      city.length < 0 ||
      state.length < 0
    ) {
      toast.warning("Please fill mandatory fields");
      return;
    } else {
      const params = {
        account_no: accNo,
        bank_name: bankName,
        ifsc_code: ifsc,
        name: holderName,
        business_type: businessType,
        business_name: businessName,
        billing_address: {
          address,
          pinCode,
          gstNumber: gst,
          city,
          state,
        },
      };
      await Axios({
        url: `/api/account-details/create`,
        method: "post",
        data: params,
      }).then((res) => {
        if (res.data.success === true) {
          toast.success(`Account added`);
          setUser((prevState) => ({ ...prevState, is_account: true }));
          setAccountStep(0);
        } else {
          toast.success(`Something went wrong`);
        }
      });
    }
  };

  const fetchAccountDetails = async () => {
    await Axios({
      url: `/api/account-details/user/${user?.id}`,
      method: "get",
    }).then((res) => {
      const {
        account_no,
        bank_name,
        billing_address: { address, pinCode, gstNumber, city, state },
        business_name,
        business_type,
        ifsc_code,
        name,
      } = res.data.data;

      setHolderName(name);
      setBankName(bank_name);
      setIfsc(ifsc_code);
      setAccNo(account_no);
      setBusinessName(business_name);
      setBusinessType(business_type);
      setAddress(address);
      setCity(city);
      setGst(gstNumber);
      setPinCode(pinCode);
      setState(state);
    });
  };
  useEffect(() => {
    if (isEdit) {
      fetchAccountDetails();
    } else {
      return;
    }
  }, []);

  const handleBankDetailsUpdate = async (e) => {
    e.preventDefault();
    if (
      accNo === null ||
      bankName === null ||
      ifsc === null ||
      holderName === null ||
      businessName === null ||
      businessType === null ||
      address.length < 0 ||
      pinCode.length < 0 ||
      gst.length < 0 ||
      city.length < 0 ||
      state.length < 0
    ) {
      toast.warning("Please fill mandatory fields");
      return;
    } else {
      const params = {
        account_no: accNo,
        bank_name: bankName,
        ifsc_code: ifsc,
        name: holderName,
        business_type: businessType,
        business_name: businessName,
        billing_address: {
          address,
          pinCode,
          gstNumber: gst,
          city,
          state,
        },
      };
      await Axios({
        url: `/api/account-details/update/${accountId}`,
        method: "put",
        data: params,
      }).then((res) => {
        if (res.data.success === true) {
          toast.success(`Account updated`);
          setUser((prevState) => ({ ...prevState, is_account: true }));
          setAccountStep(0);
        } else {
          toast.success(`Something went wrong`);
        }
      });
    }
  };
  return (
    <div className="incubator_create_program_root">
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="coworking_back_btn btncta"
          onClick={() => {
            setAccountStep(0);
          }}
        >
          <span>
            <KeyboardBackspace />
          </span>
          <span>BACK</span>
        </button>
      </div>
      <div className="incubator_create_program_main">
        <div className="incubator_create_program_head">
          <h4>{isEdit ? "Update " : "Add "} Bank Details</h4>
        </div>
        <div className="register_page_form row bg-white p-6">
          <form className="coworking_addAssets_form">
            <div className="row p-3">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="coworking_addAssets_input_main">
                  <label
                    htmlFor="assetName"
                    className="caption color_dark_blue"
                  >
                    Account number *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Account number"
                    className="body1"
                    value={accNo}
                    onChange={(e) => setAccNo(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="coworking_addAssets_input_main">
                  <label
                    htmlFor="assetName"
                    className="caption color_dark_blue"
                  >
                    IFSC code *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter IFSC code"
                    className="body1"
                    value={ifsc}
                    onChange={(e) => setIfsc(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 mt-1">
                <div className="coworking_addAssets_input_main">
                  <label
                    htmlFor="assetName"
                    className="caption color_dark_blue"
                  >
                    Bank name *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Bank name"
                    className="body1"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 mt-1">
                <div className="coworking_addAssets_input_main">
                  <label
                    htmlFor="assetName"
                    className="caption color_dark_blue"
                  >
                    Account holder name *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Account holder name"
                    className="body1"
                    value={holderName}
                    onChange={(e) => setHolderName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 mt-1">
                <div className="coworking_addAssets_input_main">
                  <label
                    htmlFor="assetName"
                    className="caption color_dark_blue"
                  >
                    Business name *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Business name"
                    className="body1"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 mt-1">
                <div className="coworking_addAssets_input_main">
                  <label
                    htmlFor="businessType"
                    className="caption color_dark_blue"
                  >
                    Business type *
                  </label>
                  <select
                    name="businessType"
                    className="body1"
                    onChange={(e) => setBusinessType(e.target.value)}
                    value={businessType}
                    required
                  >
                    <option value="" className="body1">
                      Select Business type
                    </option>
                    <option value="Private Limited" className="body1">
                      Private Limited
                    </option>
                    <option value="Proprietorship" className="body1">
                      Proprietorship
                    </option>
                    <option value="Partnership" className="body1">
                      Partnership
                    </option>
                    <option value="Individual" className="body1">
                      Individual
                    </option>
                    <option value="Public Limited" className="body1">
                      Public Limited
                    </option>
                    <option value="LLP" className="body1">
                      LLP
                    </option>
                    <option value="Trust" className="body1">
                      Trust
                    </option>
                    <option value="Society" className="body1">
                      Society
                    </option>
                    <option value="NGO" className="body1">
                      NGO
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-12 mt-1">
                <label className="auth_label p_secNaviBlue12 mt-0">
                  Billing Address *
                </label>
                <input
                  placeholder="Enter the billing address"
                  type="text"
                  className="auth_input mt-0"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 mt-1">
                <label className="auth_label p_secNaviBlue12 mt-0">
                  City *
                </label>
                <input
                  placeholder="Enter the your city"
                  type="text"
                  className="auth_input mt-0"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 mt-1">
                <label className="auth_label p_secNaviBlue12 mt-0">
                  State *
                </label>
                <input
                  placeholder="Enter the your state"
                  type="text"
                  className="auth_input mt-0"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 mt-1">
                <label className="auth_label p_secNaviBlue12 mt-0">
                  Pin Code *
                </label>
                <input
                  placeholder="Enter the your pin code"
                  type="text"
                  className="auth_input mt-0"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                />
              </div>
              <div className="col-12 mt-1">
                <label className="auth_label p_secNaviBlue12 mt-0">
                  GST Number
                </label>
                <input
                  placeholder="Enter the GST number"
                  type="text"
                  className="auth_input mt-0"
                  value={gst}
                  onChange={(e) => setGst(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {isEdit && (
                <button
                  type="button"
                  className="gradient_CTA"
                  onClick={handleBankDetailsUpdate}
                >
                  Update Account
                </button>
              )}
              {!isEdit && (
                <button
                  type="button"
                  className="gradient_CTA"
                  onClick={handleBankDetailsUpload}
                >
                  Add Account
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
