import React, { useState, useEffect } from "react";
// import Navbar from '../../Layout/Navbar/Navbar'
import logo from "./../../Assets/images/logo/logo.png";
import signup_img from "./../../Assets/images/signup.png";
// import BackgroundImg from '../Home/backgroundImg'
// import GoogleLogo from "../../Assets/images/Signup/googleLogo.png"
// import LinkdinLogo from "../../Assets/images/Signup/linkdinLogo.png"
import "./signup.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useAuth } from "./../../Utils/Auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Signup = () => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const [validPassword, setValidPassword] = useState(true);
  const [showPassword, setshowPassword] = useState(false);
  const [disable, setdisable] = useState(false);

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [agreed, setAgreed] = useState(0);
  const search = useLocation().search;
  const programid = new URLSearchParams(search).get("program");
  const request_id = new URLSearchParams(search).get('request_id')
  const incubatorid = new URLSearchParams(search).get("incubator_id");
  const token = new URLSearchParams(search).get("tk");
  const invitetype = new URLSearchParams(search).get("type");
  const event_id = new URLSearchParams(search).get('event_id')
  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!agreed) {
      toast.error("Agree to the terms & conditions and then proceed", {
        position: "bottom-right",
      });
      return;
    }

    if (firstName.length === 0 || lastName.length === 0)
      return toast.warning("Enter fullname");
    if (email.length === 0) return toast.warning("Enter email");
    if (role.length === 0) return toast.warning("Select role");

    if (!validPassword) return toast.warning("Enter valid password");
    let data;

    if (programid !== null && token !== null) {
      data = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        type: role,
        programid,
        tk: token,
      };
    }
    else if(programid !== null && token === null && incubatorid === null){
      data = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        type: role,
        programid,
      };
    }
    else if (incubatorid && token) {
      data = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        type: role,
        incubator_id: incubatorid,
        tk: token,
      };
    }else if(request_id !==null && programid === null && token === null && incubatorid === null){
      data = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        type: role,
        request_id
      };
    }else if(event_id !== null && request_id ===null && programid === null && token === null && incubatorid === null){
      data = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        type: role,
        event_id
      };
    }else {
      data = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        type: role,
      };
    }

    await Axios.post(`/api/user/signup`, data)
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.code === 11) {
            toast.success("Signup Complete", { position: "bottom-right" });
            // navigate(`/verify-email/${role}`);
            navigate('/login')
          } else if (res.data.code === 10) {
            toast.success("Signup Complete", { position: "bottom-right" });
            window.open(`/register/${invitetype}`);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error, { position: "bottom-right" });
      });
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const txt = e.target.value;
    if (
      regex.test(txt)
    ) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  useEffect(() => {
    if ((programid !== null && token !== null) || (incubatorid && token)) {
      setRole(invitetype);
      setdisable(true);
    }
  }, []);


  
  /*=============================================
  =            Handling login btn urls            =
  =============================================*/
  
  let LOGIN = '/login';

  if(event_id !== null && request_id ===null && programid === null && token === null && incubatorid === null){
    LOGIN = `/login?event_id=${event_id}`
    
  }else if(request_id !==null && programid === null && token === null && incubatorid === null){
    LOGIN = `/login?request_id=${request_id}`
  }else{
    LOGIN = '/login'
  }
  
  
  

  return (
    <>
      {/* <Navbar /> */}

      <div className="auth_back_part">
        <div className="twf_signup_left">
          <div className="twf_signup_left_imgs">
            <div className="twf_signup_left_logo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </div>
            <div className="twf_singup_left_bg">
              <img
                className={` img-fluid smooth-image auth_option_img image-${imageLoaded ? "visible" : "hidden"
                  } auth_image`}
                onLoad={() => setImageLoaded(true)}
                src={signup_img}
                alt="twf signup"
              />
            </div>

          </div>
          <div
            className="auth_back back_CTA"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </div>

        </div>
        <div className="twf_signup_right_main">
          <div className="auth_back_loginform-head">
            <h4>Welcome</h4>
          </div>
          <form className="row mt-20" onSubmit={handleOnSubmit}>
            <div className="col-12">
              <div className="row">
                <div className="auth_back_loginform col-lg-6 col-md-6 col-12">
                  <label className="caption color_dark_blue">First Name</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setFirstname(e.target.value);
                    }}
                    className="body1 color_dark_grey"
                    placeholder="Enter First Name*"
                    value={firstName}
                  />
                </div>
                <div className="auth_back_loginform col-lg-6 col-md-6 col-12">
                  <label className="caption color_dark_blue">Last Name</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setLastname(e.target.value);
                    }}
                    className="body1 color_dark_grey"
                    placeholder="Last Name*"
                    value={lastName}
                  />
                </div>
              </div>
            </div>
            <div className="auth_back_loginform col-12">
              <label className="caption color_dark_blue">Signing up as</label>
              <select
                className="col-12"
                name="Role"
                disabled={disable}
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">Select</option>
                
                {
                  !programid && <>
                    <option value="startup">Startup</option>
                    <option value="incubator">Incubator</option>
                    <option value="mentor">Mentor</option>
                    {/* <option value="investor">Investor</option>
                    <option value="corporate">Corporate</option>
                    <option value="serviceprovider">Service Provider</option> */}
                  </>
                }
                {
                  invitetype ? (<>
                    {/* <option value="student">Student</option> 
                    <option value="prestartup">Pre-Startup</option>
                    <option value="individual">Individual</option> */}
                    <option value="employee">Employee</option>
                  </>) : null
                }
                {
                  programid && <>
                    <option value="startup">Startup</option>
                    <option value="mentor">Mentor</option>
                    {/* <option value="investor">Investor</option> */}
                  </>
                }

              </select>
            </div>
            <div className="auth_back_loginform col-12">
              <label className="caption color_dark_blue">Email Address</label>
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email ID*"
                value={email}
              />
            </div>
            <div className="auth_back_loginform col-12">
              <div className="signup-form-frgetPas">
                <label className="caption color_dark_blue">Create Password</label>
                <input
                  type={!showPassword ? "password" : "text"}
                  onChange={(e) => {
                    handlePasswordChange(e);
                  }}
                  placeholder="Password*"
                  value={password}
                />
                <button
                  className="auth_input_pas_btn"
                  type="button"
                  onClick={() => handleClickShowPassword()}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </button>
              </div>
              <div style={{ textAlign: "left" }}>
                <p
                  className="p_small_black no-margin"
                  style={{ display: validPassword ? "none" : "block" }}
                >
                  Password should include atleast an uppercase letter, a
                  lowercase letter,a number and a special character like
                  @,!,etc.
                </p>
              </div>
            </div>
            <div className="signup-checkbox col-12 mt-24 pl-16">
              <div className="signup-check">
                <input
                  type="checkbox"
                  onChange={() => {
                    setAgreed(!agreed);
                  }}
                  name=""
                  id=""
                />
              </div>
              <p className="auth_back_loginform-text">
                By signing up, you agree to our Terms & Conditions
              </p>
            </div>

            <div className="signup-btn col-12">
              <button type="submit" className="gradient_CTA">
                SIGN-UP
              </button>
            </div>

            <div className="col-12">
              <p
                className="auth_back_loginform-text"
                style={{ textAlign: "center" }}
              >
                Already a member?{" "}
                <Link to={LOGIN} style={{ color: "#000" }}>
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      {/* <BackgroundImg /> */}
    </>
  );
};

export default Signup;



