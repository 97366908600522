import React, { useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import { toast } from "react-toastify";
import getDiff from "../../../Utils/diffleft/getDiff";

const Post = ({ post }) => {
  const { setSessionExpire } = useAuth();

  // const deletePost = async (id) => {
  //     await Axios({
  //         url: `/api/post/delete/${id}`,
  //         method: 'delete',
  //     }).then(() => {
  //         toast.success("Post Deleted");
  //         getPosts()
  //         handleClose()
  //     }).catch((err) => {
  //         if (err.response && err.response.status === 401) {
  //             setSessionExpire(true)
  //         }
  //     });
  // }

  const getUserInfo = async () => {
    try {
      // console.log(post);
      await Axios({
        url: `/api/post/post/user/${post.byUser._id}`,
        method: "get",
      })
        .then(() => {
          // setPostUser(res.data.data)
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            setSessionExpire(true);
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Some Internal Errorr");
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [open]);
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      <div className="post_header_root">
        <div className="post_avatar_main">
          <img
            src={post.byUser ? post.byUser.avatar : null}
            className="post_avatar post_avatar2 profile_img"
            alt="avatar"
          />
        </div>
        {post.byUser ? (
          <div className="post_header_info">
            <p className="body1">{`${post.byUser.name}`}</p>
            <p className="overline color_dark_grey2">
              {post.byUser.type==="serviceprovider"?'Service Provider':<span style={{textTransform:"capitalize"}}>{post.byUser.type}</span>}
               | {getDiff(post.createdAt)}
            </p>
          </div>
        ) : null}
      </div>
      <div className="post_body_root mt-3">
        <p
          dangerouslySetInnerHTML={{
            __html: String(post.desc).replaceAll("\n", "<br/>"),
          }}
          className="body2 color_light_black"
        ></p>

        {post.photoUrl ? (
          <img
            src={post.photoUrl}
            className="feed_image mt-2"
            alt="feed-image"
            loading="lazy"
          />
        ) : null}
      </div>
    </>
  );
};

export default Post;
