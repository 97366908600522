import React from "react";

import MyApplicatins from "./MyProgram/MyApplicatins";
import Explore from "./Explore/Explore";
import ViewProgram from "./ViewProgram/ViewProgram";

const IncubationTool = (props) => {
  const { incubationState, setIncubationState } = props;
  return (
    <div className="startUp_incubation_tool_root">
      {incubationState === 1 ? (
        <Explore setIncubationState={setIncubationState} />
      ) : incubationState === 2 ? (
        <MyApplicatins setIncubationState={setIncubationState} />
      ) : incubationState === 3 ? (
        <ViewProgram setIncubationState={setIncubationState} />
      ) : (
        <Explore setIncubationState={setIncubationState} />
      )}
    </div>
  );
};

export default IncubationTool;
