import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios"
import { LocalOffer, CalendarViewMonth, EventNoteOutlined, CheckCircleSharp,  ExpandMore } from "@mui/icons-material"
import './viewCourse.css'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CourseSessions from './CourseSessions';
import { toast } from 'react-toastify';

const ViewCourse = ({ setCourseState }) => {
  const navigate = useNavigate()
  const search = useLocation().search;
  const course_id = new URLSearchParams(search).get("course");
  const [course, setCourse] = useState(null);
  const [modules, setModules] = useState([]);
  const [enrolled, setEnrolled] = useState(false);
  const [totalAssessment, setTotalAssessment] = useState(0)
  const [totalAssignement, setTotalAssignement] = useState(0)
  const [has_started, setHas_started] = useState(false);
  const { setSessionExpire } = useAuth();
  const { programid } = useParams()

  const getCourseDetail = async () => {
    await Axios({
      url: `/api/mentorModules/course/${course_id}`,
      method: "get",
    })
      .then((res) => {
        setCourse(res.data.course);
        setModules(res.data.mod);
        setTotalAssessment(res.data.assessment)
        setTotalAssignement(res.data.assignment)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  }

  const enrollIntoCourse = async () => {
    await Axios({
      url: `/api/mentorModules/enroll/create/${course_id}`,
      method: "post",
    }).then((res) => {
      if (res.data.success) {
        toast.success("Successfully Enrolled");
        setEnrolled(true)
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  const getEnrolledUser = async () => {
    await Axios({
      url: `/api/mentorModules/enrolled/course/${course_id}`,
      method: "get",
    }).then((res) => {
      setHas_started(res.data.data.has_started)
    }).catch((err) => {
      if (err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  const startCourse = async () => {
    await Axios({
      url: `/api/mentorModules/enroll/update/${course_id}`,
      method: "PUT",
      data: {
        has_started: true,
      }
    }).then(() => {
      navigate(`/service/userCourses/${course_id}/${programid}`)
    }).catch((err) => {
      if (err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  useEffect(() => {
    getCourseDetail();
    getEnrolledUser();
  }, [])

  return <div className="viewStartupCouse_root">
    <div className="view_startup_course_back">
      <p className="body1 mb-3 point"
        onClick={() => {
          setCourseState(0)
          navigate(`/service/userprogram/${programid}?tab=4`)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-left mb-1 mx-2"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
          />
        </svg>
        Back
      </p>
    </div>
    <hr />
    {
      course === null ? null : <div className="viewStartupCouse_main">
        <div className="row">
          <div className="col-lg-9 col-md-8 col-12">
            <div className="viewStartupCouse_main_card onboarding_cards p-3">
              <div>
                <h5>{course.title}</h5>
                <p className="body1 color_dark_black">{course.summary}</p>
              </div>
              <div>
                {
                  course.has_enrolled ?
                    <>
                      {
                        has_started ? <button className='secondaryOutlined_activeCta mt-16'
                          onClick={() => {
                            navigate(`/service/userCourses/${course._id}/${programid}`)
                          }} >Continue</button> : <button className='primaryFilled_activeCta mt-16'
                            onClick={startCourse} >Start Course</button>
                      }

                    </>
                    : <>
                      {
                        enrolled ? <button className='primaryFilled_activeCta mt-16'
                          onClick={startCourse}
                        >Start Course</button> : <button className='primaryFilled_activeCta mt-16'
                          onClick={enrollIntoCourse}
                        >Enroll</button>
                      }
                    </>
                }

              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12">
            <div className="viewStartupCouse_main_cardsubInfo onboarding_cards">
              <div className="viewStartUpCourse_card_subInfoTags">
                <span><LocalOffer /></span>
                <p className="body2">{course.tag[0]}</p>
              </div>
              {/* <div className="viewStartUpCourse_card_subInfoTags">
                <span><WatchLater /></span>
                <p className="body2">3 Months</p>
              </div> */}
              <div className="viewStartUpCourse_card_subInfoTags">
                <span><CalendarViewMonth /></span>
                <p className="body2">{modules.length} Modules</p>
              </div>
              <div className="viewStartUpCourse_card_subInfoTags">
                <span><EventNoteOutlined /></span>
                <p className="body2">{totalAssignement} Assignments</p>
              </div>
              <div className="viewStartUpCourse_card_subInfoTags">
                <span><CheckCircleSharp /></span>
                <p className="body2">{totalAssessment} Assessments</p>
              </div>
              {/* <div className="viewStartUpCourse_card_subInfoTags">
                <span><Videocam /></span>
                <p className="body2">24.5 hours</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    }

    <div className="viewStartupCourse_content_root mt-40">
      <div className="viewStartupCourse_content_root_head">
        <h6>Course Content</h6>
      </div>
      <div className="viewStartupCourse_content_main mt-3">
        {
          modules && modules.length > 0 ?
            modules.map((module, i) => <Accordion key={i} className='viewStartupCourse_content_Accordion'>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${i + 1}a-content`}
                id={`panel${i + 1}a-header`}
                className='viewStartupCourse_content_AccordionSum'
              >
                <h6 className='color_dark_blue'>{module.title}</h6>
              </AccordionSummary>
              <AccordionDetails className='viewStartupCourse_content_AccordionDetail'>
                <CourseSessions
                  module={module}
                />
              </AccordionDetails>
            </Accordion>) : null
        }

      </div>
    </div>

  </div>
}

export default ViewCourse