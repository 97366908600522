import React, { useEffect, useState } from 'react'
import alertImg from "../../../../Assets/images/icons/alert.png"
import { KeyboardBackspace } from "@mui/icons-material"
import './viewReq.css'
import ViewReqCard from './ViewReqCard'
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../Utils/Auth';
import Axios from "axios";

const ViewReq = (props) => {
    const { setView } = props
    const [asset, setAsset] = useState(null)
    const { setSessionExpire } = useAuth();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const assetId = query.get("asset");

    const getAssets = async () => {
        await Axios({
            url: `/api/asset/asset/${assetId}`,
            method: "get",
        }).then((res) => {
            setAsset(res.data.data);
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    useEffect(() => {
        getAssets()
    }, [])

    return (
        <div className='viewReq_root'>
            <div className="viewReq_head">
                <button className='transparent_cta btncta' onClick={() => {
                    setView(0)
                }}><KeyboardBackspace /> Back</button>
            </div>
            <div className="viewReq_main mt-24">
                <div className="viewReq_main_header">
                    <div className="row">
                        <div className="col-lg-8 col-md-7 col-12">
                            {
                                asset !== null ?
                                    <div className="viewReq_main_header_cardleft">
                                        <div className="viewReq_m_header_lcard_head">
                                            <div className='d-flex align-items-center'>
                                                <img src={alertImg} alt="alert" />
                                                <p className='overline color_dark_black_sec'>{asset.stock_booked} orders placed until now</p>
                                            </div>
                                            <div>
                                                <p className="caption color_dark_grey2">Asset id : {asset.asset_id}</p>
                                            </div>
                                        </div>
                                        <div className='pt-16 pl-24 pr-24 pb-40'>
                                            <div className="viewReq_m_header_lcard_profile d-flex align-items-center">
                                                <img src="https://picsum.photos/64" alt="profile" />
                                                <div className="s_asset_card_profile_name ml-12">
                                                    <p className="body1">{asset.asset_name}</p>
                                                    <p className="overline color_dark_grey2 d-flex align-items-center">
                                                        <span>{asset.category}</span>
                                                        <span className='startup_assets_ellipse'></span>
                                                        <span>{asset.sub_category}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="viewReq_m_header_lcard_desc mt-16">
                                                <p className="body2 color_dark_grey2">
                                                    {asset.details}
                                                </p>
                                            </div>
                                            <div className="viewReq_m_header_lcard_table_main mt-24">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <table className="viewReq_m_header_lcard_table w_100">
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Order ID </td>
                                                                <td className='body1 color_dark_blue pb-16'>OD16VC20052022</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Amount</td>
                                                                <td className='body1 color_dark_blue pb-16'>{asset.price}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1'>Duration</td>
                                                                <td className='body1 color_dark_blue'>3 months</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <table className="viewReq_m_header_lcard_table w_100">
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Booked from</td>
                                                                <td className='body1 color_dark_blue pb-16'>{new Date(asset.available_from).toLocaleDateString()}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Booked till</td>
                                                                <td className='body1 color_dark_blue pb-16'>{new Date(asset.available_till).toLocaleDateString()}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1'>Condition</td>
                                                                <td className='body1 color_dark_blue'>{asset.condition}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-40 d-flex justify-content-end">
                                                <button className='primaryFilled_activeCta'>Withdraw Request</button>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                        </div>

                        <div className="col-lg-4 col-md-5 col-12">
                            <div className="viewReq_main_header_cardRight">
                                <div className="viewReq_main_header_cardRight_head">
                                </div>
                                <div className="viewReq_head_cardRight_profile_img">
                                    <img src="https://picsum.photos/64" alt="profile" />
                                    <div className="viewReq_head_cardRight_profile_name">
                                        <p className="title">Incubator Name</p>
                                        <p className="caption color_dark_grey2">Service Provider  |  Karnataka</p>
                                    </div>
                                </div>
                                <div className="viewReq_m_header_Rcard_table_main p-24">
                                    <table className="viewReq_m_header_rcard_table w_100">
                                        <tr className='viewReq_m_header_rcard_tr'>
                                            <td className='body2 pb-20'>Industry</td>
                                            <td className='body2 color_dark_grey2 pb-20'>Agritech</td>
                                        </tr>

                                        <tr className='viewReq_m_header_rcard_tr'>
                                            <td className='body2 pb-20'>Funding Status</td>
                                            <td className='body2 color_dark_grey2 pb-20'>Pre-seed</td>
                                        </tr>

                                        <tr className='viewReq_m_header_rcard_tr'>
                                            <td className='body2 pb-20'>Incubation Date</td>
                                            <td className='body2 color_dark_grey2 pb-20'>2020</td>
                                        </tr>

                                        <tr className='viewReq_m_header_rcard_tr'>
                                            <td className='body2 pb-20'>Website</td>
                                            <td className='body2 color_dark_grey2 pb-20'>borneco.io</td>
                                        </tr>

                                        <tr className='viewReq_m_header_rcard_tr'>
                                            <td className='body2'>Contact</td>
                                            <td className='body2 color_dark_grey2'>123 456 7890</td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="viewReq_more_from_incubator_root mt-40">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <p className='body2'>More orders from incubator</p>
                            <div className="viewReq_more_from_incubator_main">
                                <table className='w-100 incubator_assessment_table'>
                                    <tbody className="incubator_assessment_tbody">
                                        {
                                            [1, 2, 3].map((data, i) => <ViewReqCard
                                                key={i}
                                                data={data}
                                            />)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewReq