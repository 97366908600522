import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";

// import search from "./../../../../../Assets/images/icons/search.png";
import Card from "./Card";
import Infopage from "./Infopage";

const ViewCourse = () => {
  const { setSessionExpire } = useAuth();
  const [services, setservices] = useState([]);
  const [step, setstep] = useState(0);
  const [userid, setuserid] = useState(0);
  const [serviceid, setserviceid] = useState(0);
  const handleServiceid = (id, id1) => {
    setstep(1);
    setuserid(id);
    setserviceid(id1);
  };

  const fetchservices = async () => {
    await Axios({
      url: "/api/service/explorerequests/",
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setservices(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    fetchservices();
  }, []);

  return (
    <>
      {step === 1 ? (
        <Infopage setstep={setstep} startupid={userid} serviceid={serviceid} />
      ) : (
        <div className="incubation_program_root">
          <div className="incubation_program_main mt-8">
            <div className="row">
              {services && services.length > 0
                ? services.map((v, i) => (
                    <Card
                      key={i}
                      service={v}
                      handleServiceid={handleServiceid}
                    />
                  ))
                : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewCourse;
