import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import LogoNavbar from "../../../Layout/Navbar/LogoNavbar";
import NotFoundPage from "../../404Pages";
import { toast } from "react-toastify";
import Axios from "axios";
import LocationComponent from "../../../Utils/Location/index";
import { Tooltip, CircularProgress, IconButton } from "@mui/material";
import { Attachment } from "@mui/icons-material";

const Register_individual = () => {
    const { user } = useAuth();
    const [country, setCountry] = useState("");
    const [state, setstate] = useState("");
    const [photoId, setPhotoID] = useState("");
    const [idType, setIDType] = useState("");
    const [city, setcity] = useState("");
    const [name, setName] = useState(``);
    const [gender, setGender] = useState(``);
    const [collegeName, setCollegeName] = useState();
    const [latestEduction, setLatestEducation] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [jobrole, setJobRole] = useState("")
    const [profilepic, setprofilepic] = useState(null);
    const [islogoloading, setislogoloading] = useState(false);
    const [cities, setcities] = useState(null);
    const [states, setstates] = useState(null);
    const [isdocuploading, setisdocuploading] = useState(false);

    useEffect(() => {
        if (user) {
            setName(`${user.firstname} ${user.lastname}`);
        }
    }, [user]);

    const handleSubmit = async () => {
        if (profilepic === null) return toast.warning("Upload profile image");
        if (
            name ||
            profilepic ||
            collegeName ||
            latestEduction ||
            city ||
            state ||
            country ||
            idType ||
            gender
        ) {
            await Axios({
                method: "post",
                url: "/api/individual/register",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    name: name,
                    company_name: companyName,
                    job_role: jobrole,
                    latest_education: latestEduction,
                    profile_pic: profilepic,
                    college_name: collegeName,
                    Doc_id: photoId,
                    gender: gender,
                    location: {
                        country: country,
                        state: state,
                        city: city,
                    },
                },
            })
                .then((res) => {
                    if (res.data.success) {
                        toast.success("Individual registered", { position: "bottom-right" });
                        // navigate("/dashboard/individual?tab=1920");
                        location.href="/dashboard/individual?tab=1920"
                    }
                })
                .catch(() => {
                    toast.error("Error in upload", { position: "bottom-right" });
                });
        } else {
            toast.warning("All Fields are required");
        }
    };

    const handleLogoUpload = (e) => {
        const image = e.target.files[0];

        if (
            image.type !== "image/jpg" &&
            image.type !== "image/png" &&
            image.type !== "image/jpeg"
        ) {
            toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
            return;
        }
        if (image.size > 2048 * 1024) {
            toast.error("File size exceeded 2MB!", { position: "bottom-right" });
            return;
        }
        const formdata = new FormData();
        formdata.append("individual-profile", image);
        setislogoloading(true);
        Axios({
            method: "post",
            url: "/api/individual/profile",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                if (data) {
                    setprofilepic(data.location);
                } else {
                    toast.error("Error in upload", { position: "bottom-right" });
                }
                // setProfile({
                //     ...profile,
                //     profilepic: data.location,
                // });
                setislogoloading(false);
                toast.success("Profile Picture uploaded", { position: "bottom-right" });
                // setProgress(25);
            })
            .catch(() => {
                setislogoloading(false);
                toast.error("Error in upload", { position: "bottom-right" });
            });
    };

    const handleIDUpload = (e) => {
        const image = e.target.files[0];
        if (image.size > 2048 * 1024) {
            toast.error("File size exceeded 2MB!", { position: "bottom-right" });
            return;
        }
        if (
            image.type !== "image/jpg" &&
            image.type !== "image/png" &&
            image.type !== "image/jpeg" &&
            image.type !== "application/pdf"
        ) {
            toast.error("Only Image And PDF files are allowed!", {
                position: "bottom-right",
            });
            return;
        }
        const formdata = new FormData();
        formdata.append("photoID", image);
        setisdocuploading(true);
        Axios({
            method: "post",
            url: " /api/upload/photoID",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                if (data) {
                    const filename = {
                        docName: idType,
                        docUrl: data.location,
                    };
                    setPhotoID(filename);

                } else {
                    toast.error("Error in upload", { position: "bottom-right" });
                }
                setisdocuploading(false);
                toast.success("Photo ID Uploaded", { position: "bottom-right" });
            })
            .catch(() => {
                toast.error("Error in upload", { position: "bottom-right" });
            });
    };

    if (!user) return <NotFoundPage />;
    return (
        <div className="regis">
            <LogoNavbar />

            <div className="register_page">
                {/* -------------STEP 1------------------ */}
                <div className="container pt-5">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-4">
                            <div className="register_page_sideborder">
                                <div className="mb-16">
                                    <a
                                        href="#part1"
                                        className="register_page_sideborder-head w-100"
                                    >
                                        Individual Details
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-1"></div> */}
                        <div className="col-12 col-md-8">
                            <div className="register_page_form" id="part1">
                                <form>
                                    <div className="register_page_form_upload">
                                        {islogoloading ? (
                                            <CircularProgress style={{ color: "black" }} />
                                        ) : (
                                            <>
                                                {profilepic ? (
                                                    <>
                                                        <img
                                                            src={profilepic}
                                                            alt="profilepic"
                                                            className="companyLogo"
                                                        />
                                                        <p
                                                            className="p_small_black mt-2"
                                                            onClick={() => setprofilepic(null)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Delete
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label htmlFor="clogo">
                                                            <div
                                                                className="register_page_form_com_logo"
                                                                onClick={{}}
                                                            >
                                                                Upload
                                                            </div>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                name="profilepic_pic"
                                                                id="clogo"
                                                                value={profilepic}
                                                                onChange={handleLogoUpload}
                                                            />
                                                        </label>
                                                        <p className="register_page_form_upload-text mt-1">
                                                            Profile Image
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div className="register_page_form row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Individual Name</label>
                                                    <input
                                                        id="name"
                                                        placeholder="Enter Name"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    // disabled
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Gender</label>
                                                    <select
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={gender}
                                                        onChange={(e) => setGender(e.target.value)}
                                                    >
                                                        <option value="">Choose Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Not to disclose">Not to disclose</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label>Latest Education</label>
                                            <select
                                                placeholder="Enter Course Name"
                                                required
                                                id="swebsite"
                                                type="text"
                                                className="auth_input"
                                                value={latestEduction}
                                                onChange={(e) => setLatestEducation(e.target.value)}
                                            >
                                                <option value="">
                                                    Choose your latest education
                                                </option>
                                                <option value="High School">High School</option>
                                                <option value="Intermidiate">Intermidiate</option>
                                                <option value="Undergraduate">Undergraduate</option>
                                                <option value="Graduate">Graduate</option>
                                                <option value="Postgraduate">Postgraduate</option>
                                                <option value="Ph.D.">Ph.D.</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <label>College Name</label>
                                            <input
                                                id="scompanyname"
                                                placeholder="Enter College's Name"
                                                required
                                                type="text"
                                                className="auth_input"
                                                value={collegeName}
                                                onChange={(e) => setCollegeName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Company Name</label>
                                                    <input
                                                        id="scompanyname"
                                                        placeholder="Enter Company's Name"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={companyName}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Job Role</label>
                                                    <input
                                                        id="jobrole"
                                                        placeholder="Enter Job Role"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={jobrole}
                                                        onChange={(e) => setJobRole(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label>Select a Photo ID (optional)</label>
                                            <select
                                                className="auth_input auth_select"
                                                name="photo_id"
                                                value={idType}
                                                onChange={(e) => setIDType(e.target.value)}
                                            >
                                                <option value="">Select Photo ID you want to upload</option>
                                                <option value="Aadhar Card">Aadhar Card</option>
                                                <option value="Driving Licence">Driving license</option>
                                                <option value="Voter ID">Voter ID Card</option>
                                                <option value="Passport">Passport</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>

                                        {idType.length > 1 && !photoId ? (
                                            <>
                                                {isdocuploading ? (
                                                    <CircularProgress style={{ color: "black" }} />
                                                ) : (
                                                    <>
                                                        <input
                                                            type="file"
                                                            className="auth_file_choose col-12"
                                                            accept="application/pdf, image/*"
                                                            name="main_doc"
                                                            id="documents"
                                                            style={{ display: "none" }}
                                                            onChange={handleIDUpload}
                                                        />
                                                        <label htmlFor="documents">
                                                            <Tooltip title="Click here to add document" placement="left">
                                                                <div className="d-flex pl-3 align-items-center ">
                                                                    <p className="text-dark no-margin pb-2">
                                                                        <strong>Attach</strong>
                                                                    </p>
                                                                    &nbsp; &nbsp;
                                                                    <IconButton
                                                                        color="primary"
                                                                        aria-label="upload picture"
                                                                        component="span"
                                                                    >
                                                                        <Attachment />
                                                                    </IconButton>
                                                                </div>
                                                            </Tooltip>
                                                        </label>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {photoId ? (
                                            <>
                                                <div className="col-12 mt-16">
                                                    <p className="body1" style={{ color: "#636363" }}>

                                                        <svg className="mr-16" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B" />
                                                        </svg>

                                                        {photoId.docName}
                                                        <span
                                                            className="overline ml-16"
                                                            style={{ cursor: "pointer", color: "#F53939" }}
                                                            onClick={() => {
                                                                setIDType(" ");
                                                                setPhotoID(null);
                                                            }}
                                                        >
                                                            Delete
                                                        </span>
                                                    </p>
                                                    &nbsp;
                                                </div>
                                            </>
                                        ) : null}
                                        <div className="col-12">
                                            <label>Location</label>
                                        </div>
                                        <div className="col-12">
                                            <LocationComponent
                                                country={country}
                                                setcountry={setCountry}
                                                state={state}
                                                setstate={setstate}
                                                city={city}
                                                setcity={setcity}
                                                states={states}
                                                cities={cities}
                                                setstates={setstates}
                                                setcities={setcities}
                                            />
                                        </div>
                                        <div className="col-12 mt-24 mb-40 d-flex justify-content-center align-items-center">
                                            <button
                                                type="button"
                                                className="primaryFilled_activeCta"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register_individual