import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
// import Element from '../Elements';
// import { FormContext } from "../Context/FormContext";
import TransacElement from "../TransacElement";
import "./step3.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Tooltip } from "@material-ui/core";
import Axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Step3 = (props) => {
  const navigate = useNavigate();

  const {
    transactions,
    // handleNext,
    fundingStatus,
    fundingRow,
    deleteFundingRow,
    setFundingRows,
    setFundingStatus,
    handleFundingStatus,
    setValuationStep
  } = props;
  const [value, setValue] = React.useState(0);
  let { contents } = transactions ?? {};

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const AddFundingRow = () => {

    if(  isNaN(fundingStatus.equity))
      return toast.error("Enter numeric values");
    if( fundingStatus.equity > 100 )
      return toast.error("Enter valid values");
    if(  isNaN(fundingStatus.capitalRaised))
      return toast.error("Enter numeric values");
    if(  isNaN(fundingStatus.valuation))
      return toast.error("Enter numeric values");


    if(
      fundingStatus.equity.length === 0 ||
      fundingStatus.capitalRaised.length === 0 ||
      fundingStatus.valuation.length === 0 ||
      fundingStatus.closingDate.length === 0
    )
      return toast.error("Fill all the fields");

    setFundingRows((prev) => [...prev, fundingStatus]);
    setFundingStatus({
      id: new Date().getTime().toString(),
      equity: "",
      capitalRaised: "",
      valuation: "",
      closingDate: "",
    });
  };

  const submitCurrentFunding = async (e) => {

    e.preventDefault();
    var sum = parseInt(contents[1].fields[0].fieldvalue) + parseInt(contents[1].fields[1].fieldvalue) + parseInt(contents[1].fields[2].fieldvalue) + 
              parseInt(contents[1].fields[3].fieldvalue) + parseInt(contents[1].fields[4].fieldvalue) + parseInt(contents[1].fields[5].fieldvalue);

    if(parseInt(contents[0].fields[0].fieldvalue) != sum)
    {
      toast.error("Enter valid values");
      return ;
    }
    else
    {
      await Axios({
        method: "post",
        url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
        data: {
          contents: transactions.contents,
          transFilled: true,
        },
      }).then((res) => {
        if (res.data) {
          setValue(1);
          toast.success("Saved Successfully");
        }
      });
    }
    
  };

  const submitPastFunding = (e) => {
    e.preventDefault();
    saveFormProgress();
    handleGenerateReport();
  };
  const handleGenerateReport = async () => {
    await Axios({
      url: `/api/valuation/generateReport/${localStorage.getItem('valuation_id')}`,
      method: "post"
    })
      .then(() => {
        toast.success("Report Requested");
        // setQuestionnare(null)
        // setBalanceSheet(null)
        // setTransactions(null)
        // setFinantial(null)
        // setFundingRows([])
      })
      .catch(() => {
        toast.error("Reports Already Pending");
      });
  };
  const saveFormProgress = () => {
    // console.log(questionnare);
    Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        fundingRounds: fundingRow,
      },
    }).then((res) => {
      if (res.data) {
        navigate("/dashboard/startup?tab=4");
        setValuationStep(1)
        toast.success("Saved Successfully");

      }
    });
  };

  // console.log(contents);

  // const NextBtn = () => {
  //   return (
  //     <>
  //       <div className="savenextbtn">
  //         <button className="gradient_CTA" onClick={() => handleNext()}>
  //           SAVE AND NEXT
  //         </button>
  //       </div>
  //     </>
  //   );
  // };
  return (
    <>
      <div className="main-tab-content">
        <div className="valuationHeading">
          <h3>FUNDING ROUNDS</h3>
          <p>
            Here you can give more information and context to investors on your
            current funding round. Keep track of your previous valuations and
            their development over time.
          </p>
        </div>
        <div className="tabs-content">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabs}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              label="CURRENT FUNDINGS"
              {...a11yProps(0)}
              className="tabscont"
            />
            <Tab label="PAST FUNDINGS" {...a11yProps(1)} className="tabscont" />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            className="tabpanelcontent tabpanelcontnet2"
          >
            <div className="transectionMain">
              <div className="currentFunding">
                <div className="currentFunHead">
                  <h1>Current Funding Round</h1>
                  <p>
                    Give investors a complete picture of your current funding
                    round by specifying here the capital needed and how it will
                    be used.
                  </p>
                </div>
                <hr />
                <form onSubmit={submitCurrentFunding}>
                  {contents
                    ? contents.map((content, i) => (
                        <TransacElement key={i} content={content} />
                      ))
                    : null}
                  <div className="savenextbtn">
                    <button className="gradient_CTA">SAVE AND NEXT</button>
                  </div>
                </form>
              </div>
            </div>
            {/* <NextBtn /> */}
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className="tabpanelcontent tabpanelcontnet2"
          >
            <form onSubmit={submitPastFunding}>
              <table className="pastfundingtable">
                <thead className="pastfundingthead nunito">
                  <tr>
                    <th>Equity{"(%)"}</th>
                    <th>Capital Raised{"(€)"}</th>
                    <th>Valuation{"(€)"}</th>
                    <th>Closing Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="pastfundingtbody">
                  <tr>
                    <td>
                      <input
                        type="text"
                        name="equity"
                        value={fundingStatus.equity}
                        onChange={handleFundingStatus}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="capitalRaised"
                        value={fundingStatus.capitalRaised}
                        onChange={handleFundingStatus}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="valuation"
                        value={fundingStatus.valuation}
                        onChange={handleFundingStatus}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="closingDate"
                        value={fundingStatus.closingDate}
                        onChange={handleFundingStatus}
                      />
                    </td>
                    <td>
                      <Tooltip title="Add More Rows">
                        <button
                          type="button"
                          className="addrowsbtn"
                          onClick={AddFundingRow}
                        >
                          <AddIcon />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>

                  {fundingRow
                    ? fundingRow.map((row) => {
                        return (
                          <>
                            <hr />
                            <tr>
                              <td>{row.equity}</td>
                              <td>{row.capitalRaised}</td>
                              <td>{row.valuation}</td>
                              <td>{row.closingDate}</td>
                              <td>
                                <Tooltip title="Remove Row">
                                  <button
                                    type="button"
                                    className="removebtn btn btn-outline-danger"
                                    onClick={() => deleteFundingRow(row.id)}
                                  >
                                    <RemoveIcon />
                                  </button>
                                </Tooltip>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ""}
                </tbody>
              </table>
              <div className="savenextbtn">
                <button className="gradient_CTA">GENERATE REPORT</button>
              </div>
            </form>
            {/* <NextBtn /> */}
          </TabPanel>
        </div>
      </div>
    </>
  );
};

Step3.propTypes = {
  transactions: PropTypes.any,
  handleNext: PropTypes.any,
  fundingStatus: PropTypes.any,
  handleFundingStatus: PropTypes.any,
  AddFundingRow: PropTypes.any,
  fundingRow: PropTypes.any,
  deleteFundingRow: PropTypes.any,
  setFundingRows: PropTypes.any,
  setFundingStatus: PropTypes.func,
};
export default Step3;
