import React from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../../Utils/Auth";
import axios from "axios";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add } from "@mui/icons-material";
import { TextField } from "@mui/material";

const AddTask = (props) => {
  const {
    title,
    settitle,
    description,
    setdescription,
    dueDate,
    setdueDate,
    handleFormClose,
    problem,
    setProblem,
    problemArr,
    setProblemArr,
    id,
    gettasks,
  } = props;
  const { setSessionExpire } = useAuth();

  const CreateTask = async () => {
    if (title === "") {
      toast.error("Please provide a title");
      return;
    }
    await axios({
      url: `/api/mentor/create-task/${id}`,
      method: "post",
      data: {
        title,
        description,
        dueDate,
        checklist: problemArr,
      },
    })
      .then((res) => {
        if (res.data.success) toast.success("Task Created");
        gettasks();
        handleFormClose();
        setProblemArr([]);
        settitle("");
        setdescription("");
        setdueDate(new Date());
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.error("Could not create, try again");
      });
  };

  return (
    <div className="create_task_form">
      <div className="row no-margin justify-content-between">
        <h4 className="p_spartan_20 bold">Add Task</h4>
        <div
          className="width_fit back_CTA no-padding"
          onClick={() => handleFormClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="#9f9f9f"
            className="bi bi-x-circle-fill "
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </div>
      </div>
      <div className="partner_addtask_form">
        <div>
          <label className="">Task</label>
          <input
            value={title}
            onChange={(e) => settitle(e.target.value)}
            type="text"
            required
            placeholder="Enter Task Title"
          />
        </div>
        <div>
          <label className="p_just_midsmall_black14">Task Description</label>
          <textarea
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            type="text"
            required
            placeholder="Enter Task Description"
          />
        </div>
        <div>
          <label className="p_just_midsmall_black14">Checklists</label>
          <div className="addProblem-mai">
            <input
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              type="text"
              required
            />
            <button
              onClick={() => {
                if (problem.length > 0) {
                  setProblemArr((prev) => [...prev, problem]);
                  setProblem("");
                }
              }}
            >
              <Add />
            </button>
          </div>
          <div className="allProblem-div mt-2">
            {problemArr.length > 0
              ? problemArr.map((data, i) => (
                  <div className="newTask-problem" key={i}>
                    <p className="p_just_midsmall_black14 mb-0">{data}</p>
                    <p
                      className="transparent_btn_noBorder p_just_small_darkGrey"
                      style={{ color: "#FF4F4F" }}
                      onClick={() => {
                        const newArr = problemArr.filter(
                          (item, index) => i !== index
                        );
                        setProblemArr(newArr);
                      }}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle mx-2" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
                    </p>
                  </div>
                ))
              : ""}
          </div>
          <div className="newTask-duedate-main">
            <label
              className="p_just_small_darkgrey12"
              style={{ marginBottom: 10 }}
            >
              Due Date
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={dueDate}
                onChange={(newValue) => {
                  setdueDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} variant='standard'  />}
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="mt-2">
          <button onClick={() => CreateTask()} className="twf_gradoutlinedbtn text-dark">
            CREATE TASK
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
