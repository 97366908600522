import React, { useState, useEffect } from 'react'
import Axios from "axios"
import { useAuth } from "../../../../Utils/Auth";
import {CheckCircleOutline} from "@mui/icons-material"

const CourseSessions = ({ module }) => {
    const [sessions, setSessions] = useState([]);
    const { setSessionExpire } = useAuth();

    const getSessionByModule = async () => {
        await Axios({
            url: `/api/mentorModules/sessions/module/${module._id}`,
            method: "get",
        }).then((res) => {

            setSessions(res.data.session);
            // setModules(res.data.mod);
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    useEffect(() => {
        getSessionByModule()
    }, [])

    return <div className="viewStartupCourse_content_session_root">
        {
            sessions && sessions.length > 0 ?
                sessions.map((session, i) => <div className="viewStartupCourse_content_session_main" key={i}>
                    <div className="viewStartupCourse_content_session_name d-flex align-items-center">
                        <span className='mr-2'><CheckCircleOutline/></span>
                        <p className='body1'>{session.session_name}</p>
                    </div>
                    <div className="viewStartupCourse_content_session_name">
                        <p className='body1 color_light_grey'>4 min</p>
                    </div>
                </div>) : null
        }

    </div>
}

export default CourseSessions