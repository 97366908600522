import React, { useEffect, useState } from "react";
import Card from "./card";
import Axios from "axios";
import { useAuth } from "./../../../Utils/Auth";
import { EventPreviewSkeleton } from "../../../Components/SkeletonLoader/EventSkeleton";

const Explore = () => {
  const [events, setEvents] = useState([]);
  const { setSessionExpire } = useAuth();
  const [isLoading,setIsLoading] = useState(false)
  // getting data
  useEffect(() => {
    setIsLoading(true)
    Axios({
      url: "/api/events/my-events",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setEvents(data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  }, []);

  return (
    <>
    {
      isLoading ? <EventPreviewSkeleton/> : events && events.map((event, i) => {
            return (
              <Card
                key={i}
                thumbnail={event.thumbnail}
                name={event.hostCompany}
                title={event.title}
                description={event.description}
                eventFees={event.eventFees}
                date={event.eventTime.date}
                time={event.eventTime.time}
                timezone={event.eventTime.timezone}
                isFree={event.isFree}
                keywords={event.keywords}
                evenyKey={event.key}
                participants={event.participants}
              />
            );
          })}
    </>
  );
};

export default Explore;
