import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
// import ProgramProfile from "../ProgramProfile";
// import { useNavigate } from "react-router-dom";
import host_img from "./../../../Assets/images/events/host.png";
import search from "./../../../Assets/images/icons/search.png";
import IncubationProgramSkeleton from '../../../Components/SkeletonLoader/ProgramSkeleton'
import "./index.css";
import Card from "./Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DraftCard from './DraftCard';
import { useLocation } from "react-router-dom";
const index = ({ setEventActiveStep }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const query = useQuery();

const isDraft = query.get("draft");
const [tabValue, setTabValue] = useState(0);
const { setSessionExpire } = useAuth();
  // const navigate = useNavigate();
const [programs, setprograms] = useState([]);
  // const [step, setstep] = useState(0);
const [isLoading,setIsLoading] = useState(false)
const [draftPrograms,setDraftPrograms] = useState([false])

const fetchprograms = async () => {
    setIsLoading(true)
    await Axios({
      url: `/api/incubatorProgram/programs`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setprograms(res.data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };
  const fetchDraftPrograms =  () => {
    setIsLoading(true)
    Axios({
      url: `/api/incubatorProgram/programs/draft`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setDraftPrograms(res.data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };

  useEffect(() => {
    fetchprograms();
  }, []);
  
  useEffect(() => {
    fetchDraftPrograms();
  }, []);



  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(()=>{
    if(isDraft){
      handleTabChange(0,1)
    }
  },[isDraft])
  return (
    <div className="incubation_program_root">
     
      <div className="incubation_program_main mt-4">
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          className="servicer-tabs"
        >
          <Tab
            label={
              <div className="row align-items-center events-tabs-rows">
                <p className="no-margin cta">Publish</p>{" "}
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            className=""
            label={
              <div className="row align-items-center events-tabs-rows">
                <p className="no-margin cta">Draft</p>{" "}
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} className="service-tabbpanel-main">
      <div className="incubation_prorgram_search_head">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-10">
            <div className="incubation_programm_searchBoxDiv">
              <input type="text" name="" id="" />
              <button className="p_Bold_secBlue14">
                <img src={search} alt="search" />
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-2">
            {/* <div className="incubation_program_filterDiv">filter......</div> */}
          </div>
        </div>
      </div>
      <br/>
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-12 point"
            onClick={() => setEventActiveStep(2)}
          >
            <div className="incubation_create_programFirst">
              <img src={host_img} alt="host_img" />
              <p>Create A Program</p>
            </div>
          </div>
          {isLoading ? <IncubationProgramSkeleton/> : (programs && programs.length > 0
            ? programs.map((program, i) => <>
              {
                !program.isDeleted ? <Card
                  key={i}
                  program={program}
                  setEventActiveStep={setEventActiveStep}
                /> : null
              }
            </>)
            : null)}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="service-tabbpanel-main">
      <div className="incubation_prorgram_search_head">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-10">
            <div className="incubation_programm_searchBoxDiv">
              <input type="text" name="" id="" />
              <button className="p_Bold_secBlue14">
                <img src={search} alt="search" />
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-2">
            {/* <div className="incubation_program_filterDiv">filter......</div> */}
          </div>
        </div>
      </div>
      <br/>
        <div className="row">
          {isLoading ? <IncubationProgramSkeleton/> : (draftPrograms && draftPrograms.length > 0
            ? draftPrograms.map((program, i) => <>
              {
                !program.isDeleted ? <DraftCard
                  key={i}
                  program={program}
                  setEventActiveStep={setEventActiveStep}
                /> : null
              }
            </>)
            : <p className="px-3">No data found</p>)}
        </div>
      </TabPanel>
       
      </div>
    </div>
  );
};

export default index;
