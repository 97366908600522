import React, { useState } from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Received from './Received';
import Paid from './Paid';
import Invoice from './Invoice'
const PayRequest = () => {
  const [value, setValue] = React.useState("1");
  const [transactionStep,setTransactionStep] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return <>
    {
      transactionStep === 0 ? <Box className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="paid" value="1" />
            <Tab label="received" value="2" /> 
          </TabList>
        </Box>
        <TabPanel value="1">
          <Paid setTransactionStep={setTransactionStep}/>
        </TabPanel>
        <TabPanel value="2">
          <Received setTransactionStep={setTransactionStep}/>
        </TabPanel>
        
      </TabContext>
    </Box> : transactionStep === 1 ? <Invoice setTransactionStep={setTransactionStep}/> : null
    }
  </>
}

export default PayRequest