import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import host_img from "./../../../../Assets/images/events/host.png";
import search from "./../../../../Assets/images/icons/search.png";
import Card from "./Card";
import "./course.css";

const ViewCourse = ({ setLearningState }) => {
  const { setSessionExpire, user } = useAuth();
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    await Axios({
      url: `/api/mentorModules/course/user/my-courses/${user.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setCourses(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    fetchCourses();
  }, []);
  return (
    <div className="incubation_program_root">
      <div className="incubation_prorgram_search_head">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-10">
            <div className="incubation_programm_searchBoxDiv">
              <input type="text" name="" id="" />
              <button className="p_Bold_secBlue14">
                <img src={search} alt="search" />
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-2">
            {/* <div className="incubation_program_filterDiv">filter......</div> */}
          </div>
        </div>
      </div>
      <div className="incubation_program_main mt-4">
        <div className="row">
          <div
            className="col-lg-3 col-md-6 col-12 mb-3 point"
            onClick={() => setLearningState(2)}
          >
            <div className="onboarding_cards incubation_create_courseFirst">
              <img src={host_img} alt="host_img" />
              <p className="mt-2">Create A Course</p>
            </div>
          </div>
          {courses && courses.length > 0
            ? courses.map((course, i) => (
                <Card
                  key={i}
                  course={course}
                  setLearningState={setLearningState}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default ViewCourse;
