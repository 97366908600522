import React, { useEffect, useState } from 'react'
import profile_short from "../../../../Assets/images/incubatorDash/profile_short.png"
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import DeleteModal from '../../../../Utils/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';

const RightScreen = ({setMyAssetState, getRealEstate}) => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const estateId = query.get("estate");
    const [estateData, setEstateData] = useState(null)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getEstaeData = async () => {
        await Axios({
            url: `/api/realEstate/estate/${estateId}`,
            method: 'GET'
        }).then((res) => {
            setEstateData(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteEstate = async () => {
        await Axios({
            url: `/api/realEstate/update/${estateId}`,
            method: 'put',
            data:{
                is_deleted: true
            }
        }).then((res) => {
            if(res.data.success)
            {
                getRealEstate()
                toast.success("Real estate deleted Successfully")
                handleClose()
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (estateId) {
            getEstaeData()
        }
    }, [estateId])
    return (
        <>
            <div className="incubator_coworking_myAssets_right_root">
                {
                    estateData !== null ?
                        <div className="coworking_myAssets_right_details_main">
                            <div className="coworking_myAssets_right_details_head">
                                <p className="subtitle">{estateData.real_estate_name}</p>
                                <div>
                                <button className='coworking_myAssets_right_editbtn caption' onClick={()=>{
                                    setMyAssetState(1)
                                }} >Edit</button>
                                <button className='coworking_myAssets_right_editbtn caption ml-16' onClick={handleOpen} >Delete</button>
                                </div>
                            </div>
                            <div className="coworking_myAssets_right_details_id">
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Id</p>
                                    <p className="body1 mt-14">{(estateData.real_estate_id).slice(-5)}</p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Status</p>
                                    <p className="body1 mt-14">{estateData.status}</p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Max quantity</p>
                                    <p className="body1 mt-14">25</p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Price</p>
                                    <p className="body1 mt-14">Euro {(estateData.price).toLocaleString('en', {
                                        minimumFractionDigits: 2
                                    })}</p>
                                </div>
                            </div>
                            <div className="coworking_myAssets_right_details_category_main">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="myAssets_right_category_box_main">
                                            <table className='myAssets_right_category_box_table'>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Category</p> </td>
                                                    <td> <p className='body1'>{estateData.category}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Sub-category</p> </td>
                                                    <td> <p className='body1'>{estateData.sub_category}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Details</p> </td>
                                                    <td> <p className='body1'>{estateData.details}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Area Size</p> </td>
                                                    <td> <p className='body1'>{(estateData.area_size).toLocaleString('en', {
                                                        minimumFractionDigits: 2
                                                    })} Sqft</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td className='pb-24'> <p className='body1 color_dark_blue'>Quantity</p> </td>
                                                    <td className='pb-24'> <p className='body1'>{estateData.stock}</p> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="myAssets_right_category_box_main" style={{ borderRight: 0 }}>
                                            <table className='myAssets_right_category_box_table'>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Manager</p> </td>
                                                    <td> <p className='body1'>{estateData.real_estate_manager}</p> </td>
                                                </tr>
                                                {/* <tr className='myAssets_right_category_box_table_tr'>
                                                <td> <p className='body1 color_dark_blue'>Condition</p> </td>
                                                <td> <p className='body1'>{estateData.condition}</p> </td>
                                            </tr> */}
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Available from</p> </td>
                                                    <td> <p className='body1'>{new Date(estateData.available_from).toLocaleDateString()}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Available till</p> </td>
                                                    <td> <p className='body1'>{new Date(estateData.available_till).toLocaleDateString()}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td className='pb-24'> <p className='body1 color_dark_blue'>Discount</p> </td>
                                                    <td className='pb-24'> <p className='body1'>{estateData.discount === null ? '-' : `${estateData.discount}%`}</p> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
                <div className="coworking_myAssets_right_bookings_root mt-40" style={{display:'none'}}>
                    <div className="coworking_myAssets_right_bookings_head mb-24">
                        <img src={profile_short} alt="profile_booking" />
                        <p className="subtitle pl-16">Bookings</p>
                    </div>
                    <div className="coworking_myAssets_right_bookings_main">
                        <Accordion className='coworking_myAssets_bookings_Acc mb-24'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='coworking_myAssets_bookings_AccSumm'
                            >
                                <div className="coworking_myAssets_bookings_summ">
                                    <div className="coworking_myAssets_bookings_profile">
                                        <img src={profile_short} alt="profile_booking" />
                                        <p className="body1">Cal Techs</p>
                                    </div>
                                    <div className="coworking_myAssets_bookings_">
                                        <p className='caption color_light_grey'>
                                            <span>Requested on :</span>
                                            <span>15/05/2022</span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='coworking_myAssets_bookings_AccDetail'>
                                <div className="coworking_myAssets_bookings_details">
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Discount(-)</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Grand total</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='coworking_myAssets_bookings_Acc mb-24'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='coworking_myAssets_bookings_AccSumm'
                            >
                                <div className="coworking_myAssets_bookings_summ">
                                    <div className="coworking_myAssets_bookings_profile">
                                        <img src={profile_short} alt="profile_booking" />
                                        <p className="body1">Cal Techs</p>
                                    </div>
                                    <div className="coworking_myAssets_bookings_">
                                        <p className='caption color_light_grey'>
                                            <span>Requested on :</span>
                                            <span>15/05/2022</span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='coworking_myAssets_bookings_AccDetail'>
                                <div className="coworking_myAssets_bookings_details">
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Discount(-)</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Grand total</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='coworking_myAssets_bookings_Acc mb-24'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='coworking_myAssets_bookings_AccSumm'
                            >
                                <div className="coworking_myAssets_bookings_summ">
                                    <div className="coworking_myAssets_bookings_profile">
                                        <img src={profile_short} alt="profile_booking" />
                                        <p className="body1">Cal Techs</p>
                                    </div>
                                    <div className="coworking_myAssets_bookings_">
                                        <p className='caption color_light_grey'>
                                            <span>Requested on :</span>
                                            <span>15/05/2022</span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='coworking_myAssets_bookings_AccDetail'>
                                <div className="coworking_myAssets_bookings_details">
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Discount(-)</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Grand total</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>


                    </div>
                </div>
            </div>
            <DeleteModal
                open={open}
                handleClose={handleClose}
                deleteFunction={deleteEstate}
            />
        </>
    )
}

export default RightScreen