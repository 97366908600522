import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../../../../Utils/Auth";
import { Rating } from "@mui/material";
// import { KeyboardArrowDown } from "@mui/icons-material";
const StartupRightScreen = ({ setstep }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const startupid = query.get("startupid");
  const { programid } = useParams();
  // const [startup, setstartup] = useState(null);
  const [ratingAvg, setRatingAvg] = useState(0);
  const [length, setLength] = useState(0);
  const [paticipantFeed, setParticipantFeed] = useState(null);
  const { setSessionExpire } = useAuth();
  const [analytics, setanalytics] = useState([]);
  const [membertype, setmembertype] = useState("mentor");
  // const [drop, setDrop] = useState(false);
  const getFeedback = async () => {
    await Axios({
      method: "get",
      url: `/api/incubatorProgram/startup/feedback/${programid}/${startupid}`,
    })
      .then((res) => {
        setParticipantFeed(res.data.data);
        const { data } = res.data;
        var accessibility = data.accessibility;
        var active_participation = data.active_participation;
        var overall_experience = data.overall_experience;
        var professionalism = data.professionalism;

        var accessibilitySum =
          accessibility.one * 1 +
          accessibility.two * 2 +
          accessibility.three * 3 +
          accessibility.four * 4 +
          accessibility.five * 5;
        var active_participationSum =
          active_participation.one * 1 +
          active_participation.two * 2 +
          active_participation.three * 3 +
          active_participation.four * 4 +
          active_participation.five * 5;
        var overall_experienceSum =
          overall_experience.one * 1 +
          overall_experience.two * 2 +
          overall_experience.three * 3 +
          overall_experience.four * 4 +
          overall_experience.five * 5;
        var professionalismSum =
          professionalism.one * 1 +
          professionalism.two * 2 +
          professionalism.three * 3 +
          professionalism.four * 4 +
          professionalism.five * 5;

        var accessibilityAvg =
          accessibilitySum /
          (accessibility.one +
            accessibility.two +
            accessibility.three +
            accessibility.four +
            accessibility.five);

        var active_participationAvg =
          active_participationSum /
          (active_participation.one +
            active_participation.two +
            active_participation.three +
            active_participation.four +
            active_participation.five);

        var overall_experienceAvg =
          overall_experienceSum /
          (overall_experience.one +
            overall_experience.two +
            overall_experience.three +
            overall_experience.four +
            overall_experience.five);

        var professionalismAvg =
          professionalismSum /
          (professionalism.one +
            professionalism.two +
            professionalism.three +
            professionalism.four +
            professionalism.five);
        setLength(
          professionalism.one +
            professionalism.two +
            professionalism.three +
            professionalism.four +
            professionalism.five
        );
        var avg =
          (accessibilityAvg +
            active_participationAvg +
            overall_experienceAvg +
            professionalismAvg) /
          4;
        setRatingAvg(avg.toFixed(1));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const getAnalytics = () => {
    Axios({
      method: "get",
      url: `/api/incubatorProgram/startupratingbytype/${programid}/${startupid}/${membertype}`,
    })
      .then((res) => {
        setanalytics(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    if (startupid && membertype) {
      getAnalytics();
      getFeedback();
      setmembertype(membertype);
    }
  }, [startupid, membertype]);

  return (
    <div className="incubator_analytics_rating_info_root">
      <div className="row mb-24 analy-profile">
        <div className="incubator_analytics_rating_progress w_100 col-12 col-md-10">
          <div className="incubator_analytics_rating_proQuality_main mb-16">
            <p className="overline">Accessibility</p>
            <div className="incubator_analytics_rating_progress_main">
              <div
                className="incubator_analytics_rating_pr"
                style={{
                  width: `${
                    paticipantFeed === null
                      ? 0
                      : paticipantFeed.accessibility_percent
                  }%`,
                }}
              >
                {paticipantFeed ===
                null ? null : paticipantFeed.accessibility_percent >= 87 ? (
                  <p className="overline color_dark_grey2 pr-16 ml-8">
                    {length} members
                  </p>
                ) : null}
              </div>
              {paticipantFeed ===
              null ? null : paticipantFeed.accessibility_percent < 87 ? (
                <p className="overline color_dark_grey2 ml-8">
                  {length} members
                </p>
              ) : null}
            </div>
          </div>
          <div className="incubator_analytics_rating_proQuality_main mb-16">
            <p className="overline">Active participation</p>
            <div className="incubator_analytics_rating_progress_main">
              <div
                className="incubator_analytics_rating_pr"
                style={{
                  width: `${
                    paticipantFeed === null
                      ? 0
                      : paticipantFeed.active_participation_percent
                  }%`,
                }}
              >
                {paticipantFeed ===
                null ? null : paticipantFeed.active_participation_percent >=
                  87 ? (
                  <p className="overline color_dark_grey2 pr-16 ml-8">
                    {length} members
                  </p>
                ) : null}
              </div>
              {paticipantFeed ===
              null ? null : paticipantFeed.active_participation_percent < 87 ? (
                <p className="overline color_dark_grey2 ml-8">
                  {length} members
                </p>
              ) : null}
              {/* <p className="overline color_dark_grey2 ml-8">{length} members</p> */}
            </div>
          </div>
          <div className="incubator_analytics_rating_proQuality_main mb-16">
            <p className="overline">Professionalism</p>
            <div className="incubator_analytics_rating_progress_main">
              <div
                className="incubator_analytics_rating_pr"
                style={{
                  width: `${
                    paticipantFeed === null
                      ? 0
                      : paticipantFeed.professionalism_percent
                  }%`,
                }}
              >
                {paticipantFeed ===
                null ? null : paticipantFeed.professionalism_percent >= 87 ? (
                  <p className="overline color_dark_grey2 ml-8 pr-16">
                    {length} members
                  </p>
                ) : null}
              </div>
              {paticipantFeed ===
              null ? null : paticipantFeed.professionalism_percent < 87 ? (
                <p className="overline color_dark_grey2 ml-8">
                  {length} members
                </p>
              ) : null}
            </div>
          </div>
          <div className="incubator_analytics_rating_proQuality_main">
            <p className="overline">Overall Experience</p>
            <div className="incubator_analytics_rating_progress_main">
              <div
                className="incubator_analytics_rating_pr"
                style={{
                  width: `${
                    paticipantFeed === null
                      ? 0
                      : paticipantFeed.overall_experience_percent
                  }%`,
                }}
              >
                {paticipantFeed ===
                null ? null : paticipantFeed.overall_experience_percent >=
                  87 ? (
                  <p className="overline color_light_grey ml-8 pr-16">
                    {length} members
                  </p>
                ) : null}
              </div>
              {paticipantFeed ===
              null ? null : paticipantFeed.overall_experience_percent < 87 ? (
                <p className="overline color_dark_grey2 ml-8">
                  {length} members
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2 ">
          {ratingAvg > 0 ? (
            <div className="participant_profile_right_rating_root mb-12">
              <div className="participant_profile_right_rating_main">
                <h5 className="color_dark_black2">{ratingAvg}</h5>
                <div className="incubator_aly_rating__">
                  <Rating
                    name="read-only"
                    value={ratingAvg}
                    readOnly
                    className="incubator_aly_rating_read"
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="caption "
            style={{ cursor: "pointer", color: "#565656" }}
            onClick={() => setstep(1)}
          >
            View Profile
            <svg
              className="ml-12"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.34115 7.91648H10.6495L6.58281 12.3898C6.25781 12.7473 6.25781 13.334 6.58281 13.6915C6.90781 14.049 7.43281 14.049 7.75781 13.6915L13.2495 7.65065C13.5745 7.29315 13.5745 6.71565 13.2495 6.35815L7.76615 0.308151C7.61045 0.136503 7.39908 0.0400391 7.17865 0.0400391C6.95821 0.0400391 6.74684 0.136503 6.59115 0.308151C6.26615 0.665651 6.26615 1.24315 6.59115 1.60065L10.6495 6.08315H1.34115C0.882812 6.08315 0.507812 6.49565 0.507812 6.99982C0.507812 7.50398 0.882812 7.91648 1.34115 7.91648Z"
                fill="#565656"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="incubator_analytics_rating_info_main">
        <table className="incubator_analytics_rating_table">
          <thead className="incubator_analytics_rating_thead">
            <tr className="incubator_analytics_rating_thead_tr">
              <th className="caption">
                {/* <div className="mentor_tasks_select_root w-100">
                  <div
                    className="mentor_tasks_bodyCmpName point"
                    onClick={() => setDrop(!drop)}
                  >
                    <div className="mentor_tasks_bodyCmpprf">
                      <p className="caption color_dark_black2">{membertype}</p>
                    </div>
                    <span>
                      <KeyboardArrowDown
                        className={`mentorDownbtn ${
                          drop ? "rotateDown" : "antirotateDown"
                        }`}
                      />
                    </span>
                  </div>

                  <div
                    className={`mentor_tasks_options_root ${
                      drop ? "show" : "hide"
                    }`}
                  >
                    <div
                      className="mentor_tasks_options point"
                      onClick={() => {
                        setmembertype("mentor");
                      }}
                    >
                      <p className="caption color_dark_black2">Mentors</p>
                    </div>
                  </div>
                </div> */}
                <select
                  className="auth_input"
                  style={{ width: "30%" }}
                  name="target_audience"
                  onChange={(e) => setmembertype(e.target.value)}
                  value={membertype}
                >
                  <option value="mentor">Mentors</option>
                  <option value="investor">Investors</option>
                  <option value="serviceprovider">Service Provider</option>
                  <option value="incubator">Incubator</option>
                </select>
              </th>
              <th className="caption">Accessibility</th>
              <th className="caption">Active participation</th>
              <th className="caption">Professionalism</th>
              <th className="caption">Overall Experience</th>
            </tr>
          </thead>
          <tbody className="incubator_analytics_rating_tbody">
            {analytics.length > 0
              ? analytics.map((v, i) => {
                  return (
                    <>
                      <tr
                        key={i}
                        className="incubator_analytics_rating_tbody_tr"
                      >
                        <td className="incubator_analytics_rating_tbody_td_img">
                          <img src={v.by_user.avatar} alt="" />
                          <p className="subtitle color_dark_blue">
                            {v.by_user.name}
                          </p>
                        </td>
                        <td className="body2 color_dark_blue">
                          {v.accessibility}/5
                        </td>
                        <td>{v.active_participation}/5</td>
                        <td>{v.professionalism}/5</td>
                        <td>{v.overall_experience}/5</td>
                      </tr>
                    </>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StartupRightScreen;
