import React from 'react'
import { Attachment } from "@mui/icons-material";

const ShowDoc = ({ v, handleremovedoc }) => {
    return <div className="col-12 mt-1 p-0">
        &nbsp;
        <label className="p_small_black width_auto d-flex align-items-center justify-content-between">
            <p className="me-3">{v.doc_name}</p>
            <div className='d-flex align-items-center'>
                <p><Attachment /></p>
                <p className="caption ml-6">Document Attached</p>
                <p className="overline point ml-6" style={{ color: "#F53939" }} onClick={() => {
                    handleremovedoc(v.doc_name);
                }}>Delete</p>
            </div>

        </label>
    </div>
}

export default ShowDoc