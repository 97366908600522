import React, { useState } from "react";
import "./../../authentication.css";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
// import ClearIcon from "@mui/icons-material/Clear";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import Axios from "axios";
import LocationComponent from "../../../../Utils/Location/index";
// import industries2 from "../../../../Pages/Lists/Industries2.json";
// import Multiselect from "multiselect-react-dropdown";
// import industries from "./../../../Lists/Industries.json";
import Multiselect from "multiselect-react-dropdown";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@material-ui/core";



const Step1 = (props) => {
  const {
    IncorporationDate,
    setIncorporationDate,
    name,
    setName,
    country,
    setcountry,
    state,
    setstate,
    city,
    setcity,
    states,
    cities,
    setstates,
    setcities,
    description, 
    setdescription,
    companyWebsite,
    setcompanyWebsite,
    companyLogo,
    setCompanyLogoUrl,
    setservices,
    services,
    profile,
    setProfile,


    options,
    interestedIndustries,
    setinterestedIndustries,

    
    // industry,
    // setIndustry,
    // otherIndustry,
    // setOtherIndustry,

  } = props;
  // const [options] = useState(industries2);

  const [islogoloading, setislogoloading] = useState(false);

  const addInputEvent3 = (e) => {
    setinterestedIndustries(e.map((i) => i.label));
  };

  // const [isdocloading, setisdocloading] = useState(false);
  // const [docType, setDocType] = useState("");

  // const handleDocumentsChange = (e) => {
  //   const file = e.target.files[0];
  //   if (
  //     file.type !== "image/jpg" &&
  //     file.type !== "image/png" &&
  //     file.type !== "image/jpeg" &&
  //     file.type !== "application/pdf"
  //   ) {
  //     toast.error("Only Image And PDF files are allowed!");
  //     return;
  //   }
  //   if (file.size > 2048 * 1024) {
  //     toast.error("File size exceeded 2MB!");
  //     return;
  //   }

  //   const formdata = new FormData();
  //   formdata.append("document", file);
  //   setisdocloading(true);
  //   Axios({
  //     method: "post",

  //     url: " /api/upload/document",

  //     data: formdata,
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       const filename = {
  //         docType: docType,
  //         docName: e.target.files[0].name,
  //         docExt: e.target.files[0].type,
  //         downloadUrl: data.location,
  //       };
  //       setCompanyDocuments((docs) => [...docs, filename]);
  //       setisdocloading(false);
  //       toast.success("Document uploaded", { position: "bottom-right" });

  //       setDocType(null);
  //     })
  //     .catch(() => {
  //       toast.error("Error in upload", { position: "bottom-right" });
  //     });
  // };

  // const deleteDocument = (e, i) => {
  //   e.preventDefault();
  //   setCompanyDocuments((oldItems) => {
  //     return oldItems.filter((arrelement, index) => {
  //       return index != i;
  //     });
  //   });
  // };

  
  const [benefit, setbenefit] = useState("");
  const addObjective = (arr, seto, v, setv) => {
    if (arr) {
      seto((prev) => [...prev, v]);
      setv("");
    }
  };
  const removeObjective = (arr, seto, id) => {
    const newArr = arr.filter((obj, i) => {
      return id !== i;
    });
    seto(newArr);
  };

  const handleLogoUpload = (e) => {
    const image = e.target.files[0];

    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("companyLogo", image);
    setislogoloading(true);
    Axios({
      method: "post",

      url: "/api/upload/companylogo",

      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          setCompanyLogoUrl(data.location);
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }
        setProfile({
          ...profile,
          companyLogo: data.location,
        });
        setislogoloading(false);
        toast.success("Company logo uploaded", { position: "bottom-right" });
        // setProgress(25);
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };
  // const addInputEvent3 = (e) => {
  //   setInterest(e.map((i) => i.label));
  // };

  return (
    <form>
      {/* <p className="text-dark mt-2">Company Details</p> */}
      <div className="register_page_form_upload">
        {islogoloading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            {companyLogo ? (
              <>
                <img src={companyLogo} alt="logo" className="companyLogo" />
                <p
                  className="text-dark mt-2"
                  onClick={() => setCompanyLogoUrl(null)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </p>
              </>
            ) : (
              <>
                <label htmlFor="clogo">
                  <div className="register_page_form_com_logo" onClick={{}}>
                    Upload
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_pic"
                    id="clogo"
                    value={companyLogo}
                    onChange={(e) => handleLogoUpload(e)}
                  />
                </label>
                <p className="register_page_form_upload-text mt-1">
                  Company Logo
                </p>
              </>
            )}
          </>
        )}
      </div>

      <div className="register_page_form row">
        <div className="col-12">
          <label className="auth_label">Company Name*</label>
          <input
            placeholder="Company Name"
            required
            type="text"
            className="auth_input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-12">
          <label className="auth_label">Short Description*</label>
        <textarea
          required
          id="sdesc"
          name="short_desc"
          className="auth_input auth_textarea"
          placeholder="Write  a short description"
          value={description}
          onChange={(e) => setdescription(e.target.value)}
        ></textarea>
        </div>
        <div className="col-12">
          <label className="auth_label">Company&#39;s Website</label>
          <input
            placeholder="Enter Company’s Website"
            required
            type="text"
            className="auth_input"
            value={companyWebsite}
            onChange={(e) => setcompanyWebsite(e.target.value)}
          />
        </div>
        
          <div className="col-12">
              <label>Incorporation Date*</label>
              <input
                required
                id="sinc"
                type="text"
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                className="auth_input"
                placeholder="Enter Incorporation Date"
                value={IncorporationDate}
                onChange={(e) => setIncorporationDate(e.target.value)}
              />
          </div>

          <div className="col-12" style={{ height: "100%" }}>
          <label className="auth_label">
            Interested Industry*
          </label>
          <Multiselect
            isMulti
            options={options}
            className=""
            displayValue="label"
            placeholder="Select Industries"
            name="industries"
            closeIcon="cancel"
            value={interestedIndustries}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>

        <div className="col-12">
          <label className="auth_label">Location*</label>
          <LocationComponent
            country={country}
            setcountry={setcountry}
            state={state}
            setstate={setstate}
            city={city}
            setcity={setcity}
            states={states}
            cities={cities}
            setstates={setstates}
            setcities={setcities}
          />
        </div>

        
        <div className="col-12">
          <label className="auth_label">Core Products/Services</label>
            <div className="goalsdiv">
              <input
                type="text"
                name="objective"
                className="auth_input"
                placeholder="Enter your Products/Services"
                id="objective"
                value={benefit}
                onChange={(e) => setbenefit(e.target.value)}
              />
              <Tooltip title="Add More Rows">
                <button
                  type="button"
                  onClick={() =>
                    addObjective(
                      services,
                      setservices,
                      benefit,
                      setbenefit
                    )
                  }
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </div>

            <div className="goalsscroll">
              {services
                ? services.map((obj, i) => {
                    return (
                      <>
                        <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                          <p className="program_text">{obj}</p>
                          <Tooltip title="Delete Row">
                            <button
                              type="button"
                              title="Remove"
                              onClick={() =>
                                removeObjective(
                                  services,
                                  setservices,
                                  i
                                )
                              }
                              className="program_btn"
                            >
                              x 
                            </button>
                          </Tooltip>
                        </span>
                      </>
                    );
                  })
                : null}
            </div>
          </div>


        {/* <div className="col-12">
          <label className="auth_label">LinkedIn Profile</label>
          <input
            placeholder="LinkedIn Profile Url"
            required
            type="text"
            className=" auth_input"
            value={linkedProfile}
            onChange={(e) => setlinkedProfile(e.target.value)}
          />
        </div>
        <div className="col-12">
          <label className="auth_label">Interested Industries</label>
          <Multiselect
            isMulti
            options={options}
            // className="auth_input"
            displayValue="label"
            placeholder="Interested Industries"
            name="industries"
            closeIcon="cancel"
            value={interest}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div> */}


        {/* {isdocloading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            <div className="col-12">
              <label>Company Documents</label>
              <select
                className="auth_input auth_select"
                name="doc_type"
                style={{ color: "#9f9f9f" }}
                onChange={(e) => setDocType(e.target.value)}
                value={docType}
              >
                <option value="">Document Type</option>
                <option value="Company Registration Document">
                  Company Registration Document
                </option>
                <option value="Incubation Document">Incubation Document</option>
                <option value="Grant Document">Grant Document</option>
                <option value="others">Others</option>
              </select>

              {docType ? (
                <>
                  <div className="col-12">
                    <input
                    type="file"
                    className="auth_file_choose"
                    accept="application/pdf image/*"
                    name="main_doc"
                    id="documents"
                    style={{ display: "none" }}
                    onChange={handleDocumentsChange}
                  />
                  <label htmlFor="documents">
                    <Tooltip title="Click here to add document" placement="left">
                      <div className="d-flex align-items-center ">
                        <p className="text-dark no-margin pb-2">
                          <strong>Attach</strong>
                        </p>
                        &nbsp; &nbsp;
                        <svg className="mr-16" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B"/>
                        </svg>
                      </div>
                    </Tooltip>
                  </label>
                  </div>
                </>
              ) : (
                ""
              )}
              {DocumentsNeed
                ? DocumentsNeed.map((docs, i) => (
                  <div className="col-12 mt-16" key={docs.docName}>
                  <p className="body1 w-fit" style={{color:"#636363"}}>
                    
                    <svg className="mr-16" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B"/>
                    </svg>
    
                    {docs.docType} 
                    <span
                    className="overline ml-16"
                      style={{ cursor: "pointer",color: "#F53939" }}
                      onClick={(e) => deleteDocument(e, i)}
                    >
                      Delete
                    </span>
                  </p>
                </div>
                  ))
                : null}
            </div>
          </>
        )} */}
      </div>
    </form>
  );
};

export default Step1;
