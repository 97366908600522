import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import LogoNavbar from "../../../Layout/Navbar/LogoNavbar";
import NotFoundPage from "../../404Pages";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import LocationComponent from "../../../Utils/Location/index";

const Register_student = () => {
  const { user } = useAuth();
  const [country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [name, setName] = useState(``);
  const [collegeName, setCollegeName] = useState();
  const [year, setYear] = useState("");
  const [course, setCourse] = useState("");
  const [stream, setStream] = useState("");
  const [profilepic, setprofilepic] = useState(null);
  const [islogoloading, setislogoloading] = useState(false);
  const [cities, setcities] = useState(null);
  const [states, setstates] = useState(null);


  useEffect(() => {
    if (user) {
      setName(`${user.firstname} ${user.lastname}`);
    }
  }, [user]);

  const handleSubmit = async () => {
    if (profilepic === null) return toast.warning("Upload profile image");
    if (
      name ||
      profilepic ||
      collegeName ||
      year ||
      course ||
      stream ||
      cities ||
      state ||
      country
    ) {
      await Axios({
        method: "post",
        url: "/api/student/register",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          name: name,
          profile_pic: profilepic,
          college: collegeName,
          Location: {
            country: country,
            state: state,
            city: city,
          },
          year: year,
          course: course,
          stream: stream,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Student registered", { position: "bottom-right" });
            location.href = "/dashboard/student/?tab=1920";
          }
        })
        .catch(() => {
          toast.error("Error in upload", { position: "bottom-right" });
        });
    } else {
      toast.warning("All Fields are required");
    }
  };

  const handleLogoUpload = (e) => {
    const image = e.target.files[0];

    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("student-profile", image);
    setislogoloading(true);
    Axios({
      method: "post",
      url: "/api/student/profile",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          setprofilepic(data.location);
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }
        // setProfile({
        //     ...profile,
        //     profilepic: data.location,
        // });
        setislogoloading(false);
        toast.success("Profile Picture uploaded", { position: "bottom-right" });
        // setProgress(25);
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  if (!user) return <NotFoundPage />;
  return (
    <div className="regis">
      <LogoNavbar />

      <div className="register_page">
        {/* -------------STEP 1------------------ */}
        <div className="container pt-5">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                <div className="mb-16">
                  <a
                    href="#part1"
                    className="register_page_sideborder-head w-100"
                  >
                    Student Details
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-1"></div> */}
            <div className="col-12 col-md-8">
              <div className="register_page_form" id="part1">
                <form>
                  <div className="register_page_form_upload">
                    {islogoloading ? (
                      <CircularProgress style={{ color: "black" }} />
                    ) : (
                      <>
                        {profilepic ? (
                          <>
                            <img
                              src={profilepic}
                              alt="profilepic"
                              className="companyLogo"
                            />
                            <p
                              className="p_small_black mt-2"
                              onClick={() => setprofilepic(null)}
                              style={{ cursor: "pointer" }}
                            >
                              Delete
                            </p>
                          </>
                        ) : (
                          <>
                            <label htmlFor="clogo">
                              <div
                                className="register_page_form_com_logo"
                                onClick={{}}
                              >
                                Upload
                              </div>
                              <input
                                type="file"
                                accept="image/*"
                                name="profilepic_pic"
                                id="clogo"
                                value={profilepic}
                                onChange={handleLogoUpload}
                              />
                            </label>
                            <p className="register_page_form_upload-text mt-1">
                              Profile Image
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className="register_page_form row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <label>Student Name</label>
                          <input
                            id="scompanyname"
                            placeholder="Enter Name"
                            required
                            type="text"
                            className="auth_input"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            // disabled
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <label>Current Year</label>
                          <select
                            id="scompanyname"
                            placeholder="20** - 20**"
                            required
                            type="text"
                            className="auth_input"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                          >
                            <option value="">Choose your current year</option>

                            <option value="1st">1st</option>
                            <option value="2nd">2nd</option>
                            <option value="3rd">3rd</option>
                            <option value="4th">4th</option>
                            <option value="5th">5th</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <label>College Name</label>
                      <input
                        id="scompanyname"
                        placeholder="Enter College's Name"
                        required
                        type="text"
                        className="auth_input"
                        value={collegeName}
                        onChange={(e) => setCollegeName(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <label>Course Name</label>
                          <select
                            placeholder="Enter Course Name"
                            required
                            id="swebsite"
                            type="text"
                            className="auth_input"
                            value={course}
                            onChange={(e) => setCourse(e.target.value)}
                          >
                            <option value="">
                              Choose your current course name
                            </option>
                            <option value="12th Pass">12th Pass</option>
                            <option value="Undergraduate">Undergraduate</option>
                            <option value="Graduate">Graduate</option>
                            <option value="Postgraduate">Postgraduate</option>
                            <option value="Ph.D.">Ph.D.</option>
                          </select>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <label>Course Stream</label>
                          <select
                            placeholder="Enter Stream"
                            required
                            id="swebsite"
                            type="text"
                            className="auth_input"
                            value={stream}
                            onChange={(e) => setStream(e.target.value)}
                          >
                            <option value="Management MBA/BBA">
                              Choose your course stream
                            </option>

                            <option value="Management MBA/BBA">
                              Management MBA/BBA
                            </option>
                            <option value=" Engineering B.Tech and B.Arch, M.Tech, ME, BE">
                              {" "}
                              Engineering B.Tech and B.Arch, M.Tech, ME, BE
                            </option>
                            <option value="Computer Application-BCA/MCA">
                              Computer Application-BCA/MCA
                            </option>
                            <option value="Designing - Fashion/Interior/Web">
                              Designing - Fashion/Interior/Web
                            </option>
                            <option value="Mass-communication/Journalism BJMC">
                              Mass-communication/Journalism BJMC
                            </option>
                            <option value="Hospitality (Hotel) - Hotel Management">
                              Hospitality (Hotel) - Hotel Management
                            </option>
                            <option value="Medical-BDS and MBBS">
                              Medical-BDS and MBBS
                            </option>
                            <option value="Finance -B.Com/CA">
                              Finance -B.Com/CA
                            </option>
                            <option value="Arts Psychology and Sociology">
                              Arts Psychology and Sociology
                            </option>
                            <option value="Law B.ALLB/LLB">
                              Law B.ALLB/LLB
                            </option>
                            <option value="Education Teaching-B.Ed/M.Ed">
                              Education Teaching-B.Ed/M.Ed
                            </option>
                            <option value="Pharmacy B.Pharma/M.Pharma">
                              Pharmacy B.Pharma/M.Pharma
                            </option>
                            <option value="Tourism management - B.Sc.">
                              Tourism management - B.Sc.
                            </option>
                            <option value="Fine Arts B.F.A">
                              Fine Arts B.F.A
                            </option>
                            <option value="Nursing B.Sc. and M.Sc. in Nursing">
                              Nursing B.Sc. and M.Sc. in Nursing
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <label>Location</label>
                    </div>
                    <div className="col-12">
                      <LocationComponent
                        country={country}
                        setcountry={setCountry}
                        state={state}
                        setstate={setstate}
                        city={city}
                        setcity={setcity}
                        states={states}
                        cities={cities}
                        setstates={setstates}
                        setcities={setcities}
                      />
                    </div>
                    <div className="col-12 mt-24 d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="primaryFilled_activeCta"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register_student;
