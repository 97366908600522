import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "axios";
import { LinkedIn } from "@mui/icons-material";
import Query from "../../../../../Utils/Query/Query";
import Loader from "../../../../../Components/Loader";
// import { useNavigate } from "react-router-dom";

const ViewStartupProfile = () => {
    const [startup, setstartup] = useState(null);
    const query = Query()
    const startupid = query.get('startup')
    // const navigate = useNavigate()
    const { setSessionExpire } = useAuth();
    const [isLoading,setIsLoading] = useState(false)
    const getDetail = async () => {
        setIsLoading(true)
        await Axios({
            method: "get",
            url: `/api/startup/user-by-id/${startupid}`,
        })
            .then((res) => {
                setstartup(res.data.data);
                window.scrollTo(0, 0);
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                    setIsLoading(false)
                }
            });
    };

    useEffect(() => {
        if (startupid) {
            getDetail();
        }
    }, [startupid]);

    return (
        <>
            {
                isLoading ? <Loader/>: <>
                    {/* <div className="col-12 participants_profile_head">
                <span
                    className="cta color_light_grey"
                    onClick={() => {
                        setStep(0);
                        navigate(`/dashboard/${user.type}?tab=10&mm=1`)
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-left mx-1"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                    </svg>
                    Back
                </span>
            </div> */}
            {
                startup && <div className="col-12 row">
                <div className="col-12 col-lg-7 col-md-6">
                    <div
                        className="partipant_profile_main_left"
                        id="connection_profile_root"
                    >
                        <div className="partipant_profile_main_left_head">
                            <div className="dashboard01_connection_profile_head_img">
                                <img src={startup.logo} alt="logo" />
                                <div className="ml-12">
                                    <p className="subtitle color_dark_blue">
                                        {startup.companyName}
                                    </p>
                                    <p className="caption color_dark_grey2">
                                        Startup | {startup.location.country}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="mt-40">
                            <p className="body2 grey">{startup.description}</p>
                        </div>

                        <div className="mt-40">
                            <p className="incubation_chips caption">{startup.industry} </p>
                        </div>

                        {startup.id ? (
                            <div className="mt-40">
                                <div className="participants_right_main_founder_head mb-16">
                                    <p className="title">Founder Details</p>
                                </div>
                                <div className="participants_right_main_founder_body">
                                    <img src={startup.profilePic} alt="profile" />
                                    <div className="participants_right_main_right ml-16">
                                        <p className="subtitle">
                                            {startup.id.firstname} {startup.id.lastname}
                                        </p>
                                        <p className="caption grey">{startup.contact.jobTitle}</p>
                                        <p>
                                            <a
                                                href={`//${startup.socialConnects.linkedin}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <LinkedIn style={{ fill: "#0077B5" }} />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    </div>
                </div>
                <div className="col-12 col-lg-5 col-md-6">
                    <div className="connection_profile_root_highligts">
                        <div className="participants_profile_root_highligts_head">
                            <p className="subtitle">Highlights</p>
                        </div>
                        <div className="participants_profile_root_highligts_table_main">
                            <table className="participants_profile_root_highligts_table">
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Industry
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                        {startup.industry}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Location
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {startup.location.country}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Audience
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {startup.audience}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Employees
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {startup.employees}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Funding Status
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {startup.fundingSeries}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Growth
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {startup.growth}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Incorporation Date
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {new Date(startup.incorporationDate).toLocaleDateString(
                                            "nl",
                                            { year: "numeric" }
                                        )}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Website
                                    </td>
                                    <td
                                        className="body2 color_dark_grey2 participants_profile_root_highligts_td"
                                        style={{ wordBreak: "break-all" }}
                                    >
                                        <a
                                            href={`//${startup.website}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {startup.website}
                                        </a>
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Contact
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                        {startup.contact.phone}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            }
                </>
            }

        </>
    );
}

export default ViewStartupProfile