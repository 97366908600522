import * as React from "react";
import Box from "@mui/material/Box";
// import Button from '@mui/material/Button';
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { styled } from "@mui/material/styles";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
// import "./feedback.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color:
      "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)",
  },
  "& .MuiRating-iconHover": {
    color:
      "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)",
  },
});

let labels = ["Poor", "Bad", "Good", "Best", "Excellent"];
let mglabel = ["-5px", "4px", "5px", "2px", "4px"];

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

export default function Feedback({ open, handleClose, mentor, getFeedback }) {
  // const [open, setOpen] = React.useState(false);
  const [accessibility, setAccessibility] = React.useState(0);
  const [active_participation, setActiveParticipation] = React.useState(0);
  const [professionalism, setProfessionalism] = React.useState(0);
  const [overall_experience, setOverallExperience] = React.useState(0);
  // const query = useQuery();
  // const module_id = query.get("module");
  // console.log
  const { programid } = useParams();
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleOnSubmit = (e) => {
    e.preventDefault();
    Axios({
      method: "post",
      url: `/api/incmentor/rating/create/${mentor.id}`,
      data: {
        accessibility: accessibility,
        active_participation: active_participation,
        professionalism: professionalism,
        overall_experience: overall_experience,
        program_id: programid,
      },
    })
      .then(() => {
        toast.success("Feedback Submitted");
        handleClose();
        getFeedback();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        handleClose();
      });
  };

  // 622d88e97629f6e367d8fe41

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="participant_feedback_head">
            <div className="caption color_light_grey">
              How would you rate your experience on scale of 1 to 5
            </div>
          </div>
          <div className="participant_feadback_body">
            <div className="row mb-40">
              <div className="col-6 body1 color_light_black">Accessibility</div>
              <StyledRating
                name="customized-color"
                value={accessibility}
                className="col-6"
                onChange={(event, newValue) => {
                  setAccessibility(newValue);
                }}
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              />
              <div className="col-6"></div>
              <div className="col-6 d-flex">
                {labels.map((val, index) => {
                  return (
                    <span
                      className="feedback-starlabel"
                      key={index}
                      style={{ marginLeft: `${mglabel[index]}` }}
                    >
                      {val}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row mb-40">
              <div className="col-6 body1 color_light_black">
                Active participation
              </div>
              <StyledRating
                name="customized-color"
                value={active_participation}
                className="col-6"
                onChange={(event, newValue) => {
                  setActiveParticipation(newValue);
                }}
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              />
              <div className="col-6"></div>
              <div className="col-6 d-flex">
                {labels.map((val, index) => {
                  return (
                    <span
                      className="feedback-starlabel"
                      key={index}
                      style={{ marginLeft: `${mglabel[index]}` }}
                    >
                      {val}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row mb-40">
              <div className="col-6 body1 color_light_black">
                Professionalism
              </div>
              <StyledRating
                name="customized-color"
                value={professionalism}
                className="col-6"
                onChange={(event, newValue) => {
                  setProfessionalism(newValue);
                }}
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              />
              <div className="col-6"></div>
              <div className="col-6 d-flex">
                {labels.map((val, index) => {
                  return (
                    <span
                      className="feedback-starlabel"
                      key={index}
                      style={{ marginLeft: `${mglabel[index]}` }}
                    >
                      {val}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="row mb-40">
              <div className="col-6 body1 color_light_black">
                Overall performance
              </div>
              <StyledRating
                name="customized-color"
                value={overall_experience}
                className="col-6"
                onChange={(event, newValue) => {
                  setOverallExperience(newValue);
                }}
                icon={<StarIcon fontSize="inherit" />}
                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
              />
              <div className="col-6"></div>
              <div className="col-6 d-flex">
                {labels.map((val, index) => {
                  return (
                    <span
                      className="feedback-starlabel"
                      key={index}
                      style={{ marginLeft: `${mglabel[index]}` }}
                    >
                      {val}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                onClick={(e) => handleOnSubmit(e)}
                className="primaryFilled_activeCta"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
