import Skeleton from '@mui/material/Skeleton';
export const MessagesSkeleon = () => {
  const renderMesssages = () => {
    const list = []
    for (let i = 0; i < 5; i++) {
      list.push(<div className="row my-1 mx-0 align-items-center messages_recent_block_item"
        style={{ cursor: "pointer" }}>
        <div className="width_auto no-padding">
          <Skeleton animation="wave" variant="wave" width={50} height={50} />
        </div>
        <div className="col">
          <p className="p_small_black_bold no-margin no-padding">
            <Skeleton animation="wave" width={90} height={30} />
          </p>
          <p className="p_small_grey_bold">
            <Skeleton animation="wave" width={140} height={20} />
          </p>
        </div>

      </div>)
    }
    return list
  }
  return (
    // <div className="dashboard01_block mt-0 mh-80 messages_recent_block">
    //   <div className="row no-margin align-items-center">
    //     <div className="col-10 no-padding">
    //       <h5 className="p_spartan_20 mt-2">
    //         <Skeleton animation="wave" width={150} height={45} />
    //       </h5>
    //     </div>
    //     <div className="col-2" style={{ textAlign: "right" }}>
    //       <Skeleton variant="circular" animation="wave" width={25} height={25} />
    //     </div>
    //   </div>
    //   <hr style={{ margin: "1rem -10px" }} />
    //   <div className="row no-margin align-items-center">
    //     <Skeleton animation="wave" width="100%" height={55} />
    //   </div>
    //   <div>
    //     {renderMesssages()}
    //   </div>
    // </div>
    <>
      {renderMesssages()}
    </>
  )
}


// export const Message = () => {
//   const renderMesssages = () => {
//     const list = []
//     for (let i = 0; i < 5; i++) {
//       list.push(
//       <div className="row my-1 mx-0 align-items-center messages_recent_block_item"
//         style={{ cursor: "pointer" }}>
//         <div className="width_auto no-padding">
//           <Skeleton animation="wave" variant="wave" width={50} height={50} />
//         </div>
//         <div className="col">
//           <p className="p_small_black_bold no-margin no-padding">
//             <Skeleton animation="wave" width={90} height={30} />
//           </p>
//           <p className="p_small_grey_bold">
//             <Skeleton animation="wave" width={140} height={20} />
//           </p>
//         </div>

//       </div>
//       )
//     }
//     return list
//   }
//   return (
//     <>
//       {renderMesssages()}
//     </>
//   )
// }


