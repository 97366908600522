import React, { useEffect, useState } from "react";
// import AddNewSession from "./AddNewSession";
import Axios from "axios";
import { toast } from "react-toastify";
import ModuleComponent from "./ModuleComponent";

const CourseCurriculam = (props) => {
  const { setAddSessionState, courseId, setLearningState } = props;
  const [moduleName, setModulelName] = useState("");
  const [moduleDesc, setModulelDesc] = useState("");
  //   const [ setModule] = useState("");
  const [allModules, setAllModules] = useState([]);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [moduleLength, setModuleLength] = useState(0);


  const getModules = async () => {
    await Axios({
      url: `/api/mentorModules/course/${courseId}`,
      method: "get",
    })
      .then((res) => {
        setAllModules(res.data.mod);
        let count = 0;
        for (let i = 0; i < res.data.mod.length; i++) {
          const element = res.data.mod[i];
          if (!element.is_deleted) {
            count = count + 1;
          }
        }
        setModuleLength(count)
        // toast.success("Module created");
        // setAddSessionState(1);
      })
      .catch(() => toast.error("Try Again"));
  };

  const createModule = async (e) => {
    e.preventDefault();
    if ((moduleName || moduleDesc) === "") {
      toast.error("Fields cannot be empty");
      return;
    }
    await Axios({
      url: `/api/mentorModules/module/create`,
      method: "post",
      data: {
        title: moduleName,
        description: moduleDesc,
        course_id: courseId,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // setModule(res.data.data);
          // sessionStorage.setItem("currentModule", JSON.stringify(res.data.data));
          toast.success("Module created");
          getModules();
          setModulelName("");
          setModulelDesc("");
          setModuleOpen(false);
        }
      })
      .catch(() => toast.error("Try Again"));
  };

  useEffect(() => {
    getModules();
    // console.log(setAddSessionState)
  }, []);

  return (
    <div className="my-5 w_100">
      {/* {addSessionState === 0 ? ( */}
      <>
        <h4>Course Curriculum</h4>
        <div className="create_course_form_main">
          <div className="row">
            {allModules.length > 0
              ? allModules.map((data, i) => (
                <>
                  {
                    !data.is_deleted ?
                      <div className="col-12 mb-24 p-0" key={i}>
                        <ModuleComponent
                          setAddSessionState={setAddSessionState}
                          data={data}
                          courseId={courseId}
                          allModules={allModules}
                          index={i}
                          getModules={getModules}
                          len = {allModules.length}
                        />
                      </div> : null
                  }
                </>
              ))
              : ""}

            {moduleOpen ? (
              <>
                <div className="col-12 create_module_form_maindiv2">
                  <div className="create_course_form_maindiv">
                    <label
                      htmlFor="Module 1"
                      className="caption color_dark_blue"
                    >
                      Module {moduleLength + 1}
                    </label>
                    <input
                      type="text"
                      className="body1"
                      placeholder="Enter Title"
                      value={moduleName}
                      onChange={(e) => setModulelName(e.target.value)}
                    />
                  </div>
                  <div className="create_course_form_maindiv">
                    <label
                      htmlFor="course_desc"
                      className="caption color_dark_blue"
                    >
                      Description
                    </label>
                    <textarea
                      type="text"
                      className="body1"
                      placeholder="Enter industry or keyword"
                      value={moduleDesc}
                      onChange={(e) => setModulelDesc(e.target.value)}
                    />
                  </div>
                  <div className="d-flex justify-content-end align-items-center create_course_form_maindivBtn">

                    <button
                      className="secondaryFilled_activeCta"
                      onClick={createModule}
                    >
                      Save Module
                    </button>

                  </div>

                </div>
               

              </>
            ) : (
              <button
                className="cta newModuleBtn"
                onClick={(e) => {
                  e.preventDefault();
                  setModuleOpen(true);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4a.5.5 0 01.5.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3A.5.5 0 018 4z"></path>
                </svg> Add new Module
              </button>
            )}

            <div className="d-flex align-items-center create_course_form_maindivBtn">
                  <button
                    className="primaryFilled_activeCta"
                    onClick={(e) => {
                      e.preventDefault();
                      if(allModules.length > 0){
                        setLearningState(1)
                        
                      }else{
                        return toast.error('Add modules')
                      }
                      
                    }}
                  >
                    SAVE AND PUBLISH
                  </button>
            </div>
          </div>
        </div>
      </>
      {/* ) : (
        <AddNewSession
          module={module}
          courseId={courseId}
          setAddSessionState={setAddSessionState}
        />
      )} */}
    </div>
  );
};

export default CourseCurriculam;
