import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
import ParticipantProfile from "./ParticipantProfile";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import rocketimg from "../../../Assets/images/Rocket.png";

const index = ({ programid, setparticipantState }) => {
  const [participants, setparticipants] = useState([]);
  const [invites, setinvites] = useState([]);
  const { setSessionExpire, user } = useAuth();
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [title, settitle] = useState("");
  const [count, setcount] = useState("");

  // getting data
  const fetchparticipants = async () => {
    await Axios({
      url: `/api/incubatorProgram/members/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setparticipants(data.data);
        settitle(data.program.title);
        setcount(data.count);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const fetchInvites = async () => {
    await Axios({
      url: "/api/mentor/mentorshipstatus",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setinvites(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const cancelRequest = async (id) => {
    await Axios({
      url: `/api/mentor/delete/request/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          toast.success("Request cancelled");
          fetchInvites();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.error(err.response.data.error);
      });
  };

  useEffect(() => {
    fetchparticipants();
    fetchInvites();
  }, []);

  if (participants.length === 0) return <p>No Participants available</p>;

  return (
    <>
      {step === 1 ? (
        <>
          <ParticipantProfile
            setstep={setstep}
            startupid={participantid}
            title={title}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row m-0">
                {participants &&
                  participants.length != 0 &&
                  participants.map((v, i) => {
                    return (
                      <>
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                          key={i}
                          onClick={() => {
                            setstep(1);
                            setparticipantid(v.profile.id);
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={`dashboard_01_connections_list_item`}
                          >
                            <div className="dashboard_01_connections_logo_list_block">
                              <Avatar
                                src={v.profile.logo}
                                alt="connection"
                                className="dashboard_01_connections_logo"
                              />
                            </div>

                            <div className="dashboard_01_connections_list_item_desc">
                              <p className="p_spartan_20">
                                {v.profile.companyName.length > 12
                                  ? v.profile.companyName.slice(0, 12) + "..."
                                  : v.profile.companyName}
                              </p>
                              <p className=" no-margin font12 bold">
                                {v.profile.location.state},{" "}
                                {v.profile.location.country}
                              </p>
                              <div className="row justify-content-center">
                                <p className=" font12 p_grey_chip">
                                  {v.profile.industry}
                                </p>
                                <p className=" font12 p_grey_chip">
                                  {v.profile.audience}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {participants && participants.length === 0 ? (
                  <p>No Participants available</p>
                ) : null}
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mt-2">Total Startups</p>
                <div className="row mx-0 my-2 r_a">
                  <div className="col-8">
                    <p className="bold my-0 h4" style={{ fontSize: "32px" }}>
                      {count}
                    </p>
                  </div>
                  <div className="col-4">
                    <img src={rocketimg} />
                  </div>
                </div>
              </div>
              {/* <div className="dashboard01_block">
              <p className="p_spartan_20 mt-2">Location</p>
                <div className='row mx-0 my-2 r_a'>
                  <div className="col-8">
                  <p className="bold my-0 h4" style={{fontSize: "32px"}}>10</p>
                  </div>
                </div>
            </div>
            <div className="dashboard01_block">
              <p className="p_spartan_20 mt-2">Industry</p>
                <div className='row mx-0 my-2 r_a'>
                  <div className="col-8">
                  <p className="bold my-0 h4" style={{fontSize: "32px"}}>5</p>
                  </div>
                </div>
            </div> */}
              <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mb-4 mt-2">Invite participants</p>
                {invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {!v.is_accepted ? (
                          <>
                            <div key={i} className="row mx-0 my-2 r_a">
                              <Avatar src={v.receiver.avatar} />
                              <div className="col-8">
                                <p className="bold my-0">{v.receiver.name}</p>
                                <p className="font12 grey">Pending</p>
                              </div>
                              <p
                                className="font12 grey mx-auto back_CTA"
                                onClick={() => cancelRequest(v._id)}
                              >
                                Cancel
                              </p>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p>Start connecting with participants</p>
                )}
              </div>
              <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mb-4 mt-2">Active participants</p>
                {invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {v.is_accepted ? (
                          <>
                            <div key={i} className="row mx-0 my-2 r_a">
                              <Avatar src={v.receiver.avatar} />
                              <div className="col-8">
                                <p className="bold my-0">{v.receiver.name}</p>
                              </div>
                              <Link
                                to={`/dashboard/${user.type}?tab=5&id=${v.connect_id}`}
                              >
                                <p
                                  className="font12 grey mx-auto back_CTA"
                                  onClick={() => setparticipantState(2)}
                                >
                                  View
                                </p>
                              </Link>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p>No participants</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default index;
