import React, { useEffect, useState } from 'react'
import profile_short from "../../../../Assets/images/incubatorDash/profile_short.png"
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import DeleteModal from '../../../../Utils/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import {AssetsDetailsSkeleton} from '../../../../Components/SkeletonLoader/MyAssetsSkeleton';
const AssetDetails = ({ setMyAssetState, getAssets,assetsType}) => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const assetId = query.get("asset");
    const [assetData, setAssetData] = useState(null)
    const [open, setOpen] = useState(false);
    const [isLoading,setIsLoading] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getAssetData = async () => {
        setIsLoading(true)
        await Axios({
            url: `/api/asset/asset/${assetId}`,
            method: 'GET'
        }).then((res) => {
            setAssetData(res.data.data)
            setIsLoading(false)
        }).catch(() => {
            // console.log(err)
            setIsLoading(false)
        })
    }

    const deleteAsset = async () => {
        await Axios({
            url: `/api/asset/update/${assetId}`,
            method: 'put',
            data:{
                is_deleted: true
            }
        }).then((res) => {
            if(res.data.success)
            {
                getAssets()
                toast.success("Asset deleted Successfully")
                handleClose()
            }
        }).catch(() => {
            // console.log(err)
        })
    }

    useEffect(() => {
        if (assetId) {
            getAssetData()
        }
    }, [assetId])

    return (
        <>
        {
            isLoading ? <AssetsDetailsSkeleton/> : (
                <div className="incubator_coworking_myAssets_right_root">
                {
                    assetData !== null ?
                        <div className="coworking_myAssets_right_details_main">
                            <div className="coworking_myAssets_right_details_head">
                                <p className="subtitle">{assetData.asset_name}</p>
                                <div>
                                    <button className='coworking_myAssets_right_editbtn caption' onClick={() => {
                                        setMyAssetState(1)
                                    }}>Edit</button>
                                    <button className='coworking_myAssets_right_editbtn caption ml-16' onClick={handleOpen}>Delete</button>
                                </div>
                            </div>
                            <div className="coworking_myAssets_right_details_id">
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Id</p>
                                    <p className="body1 mt-14">{(assetData.asset_id).slice(-5)}</p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Status</p>
                                    <p className="body1 mt-14">{assetData.status}</p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Max quantity</p>
                                    <p className="body1 mt-14">{assetData.stock}</p>
                                </div>
                                {
                                    assetsType === 'External' && (
                                        <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Price</p>
                                    <p className="body1 mt-14">{
                                    assetData.price !== null ?
                                    <> 
                                    Rs
                                    {(assetData.price).toLocaleString('en', {
                                        minimumFractionDigits: 2
                                    })}
                                    </>: "-"
                                    }
                                    </p>
                                </div>
                                    )
                                }
                                {
                                    assetsType === "Internal" && (
                                        <div className="coworking_myAssets_right_details_id_status">
                                    <p className="caption color_dark_grey2">Condition</p>
                                    <p className="body1 mt-14">{assetData.condition}</p>
                                </div>
                                    )
                                }
                            </div>
                            <div className="coworking_myAssets_right_details_category_main">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="myAssets_right_category_box_main">
                                            <table className='myAssets_right_category_box_table'>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Category</p> </td>
                                                    <td> <p className='body1'>{assetData.category}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Sub-category</p> </td>
                                                    <td> <p className='body1'>{assetData.sub_category}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Details</p> </td>
                                                    <td> <p className='body1'>{assetData.details}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Brand</p> </td>
                                                    <td> <p className='body1'>{assetData.brand}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td className='pb-24'> <p className='body1 color_dark_blue'>Stock</p> </td>
                                                    <td className='pb-24'> <p className='body1'>{assetData.stock}</p> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="myAssets_right_category_box_main" style={{ borderRight: 0 }}>
                                            <table className='myAssets_right_category_box_table'>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Usage type</p> </td>
                                                    <td> <p className='body1'>{assetData.asset_usage_type}</p> </td>
                                                </tr>
                                                {
                                                    assetsType === "External" && (
                                                        <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Condition</p> </td>
                                                    <td> <p className='body1'>{assetData.condition}</p> </td>
                                                </tr>
                                                    )
                                                }
                                                
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Available from</p> </td>
                                                    <td> <p className='body1'>{new Date(assetData.available_from).toLocaleDateString()}</p> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <p className='body1 color_dark_blue'>Available till</p> </td>
                                                    <td> <p className='body1'>{new Date(assetData.available_till).toLocaleDateString()}</p> </td>
                                                </tr>
                                               {
                                                assetsType === "External" && (
                                                    <tr className='myAssets_right_category_box_table_tr'>
                                                    <td className='pb-24'> <p className='body1 color_dark_blue'>Discount</p> </td>
                                                    <td className='pb-24'> <p className='body1'>{assetData.discount === null ? '-' : `${assetData.discount}%`}</p> </td>
                                                </tr>
                                                )
                                               }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }
                <div className="coworking_myAssets_right_bookings_root mt-40" style={{ display: 'none' }}>
                    <div className="coworking_myAssets_right_bookings_head mb-24">
                        <img src={profile_short} alt="profile_booking" />
                        <p className="subtitle pl-16">Bookings</p>
                    </div>
                    <div className="coworking_myAssets_right_bookings_main">
                        <Accordion className='coworking_myAssets_bookings_Acc mb-24'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='coworking_myAssets_bookings_AccSumm'
                            >
                                <div className="coworking_myAssets_bookings_summ">
                                    <div className="coworking_myAssets_bookings_profile">
                                        <img src={profile_short} alt="profile_booking" />
                                        <p className="body1">Cal Techs</p>
                                    </div>
                                    <div className="coworking_myAssets_bookings_">
                                        <p className='caption color_light_grey'>
                                            <span>Requested on :</span>
                                            <span>15/05/2022</span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='coworking_myAssets_bookings_AccDetail'>
                                <div className="coworking_myAssets_bookings_details">
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Discount(-)</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Grand total</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='coworking_myAssets_bookings_Acc mb-24'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='coworking_myAssets_bookings_AccSumm'
                            >
                                <div className="coworking_myAssets_bookings_summ">
                                    <div className="coworking_myAssets_bookings_profile">
                                        <img src={profile_short} alt="profile_booking" />
                                        <p className="body1">Cal Techs</p>
                                    </div>
                                    <div className="coworking_myAssets_bookings_">
                                        <p className='caption color_light_grey'>
                                            <span>Requested on :</span>
                                            <span>15/05/2022</span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='coworking_myAssets_bookings_AccDetail'>
                                <div className="coworking_myAssets_bookings_details">
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Discount(-)</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Grand total</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className='coworking_myAssets_bookings_Acc mb-24'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='coworking_myAssets_bookings_AccSumm'
                            >
                                <div className="coworking_myAssets_bookings_summ">
                                    <div className="coworking_myAssets_bookings_profile">
                                        <img src={profile_short} alt="profile_booking" />
                                        <p className="body1">Cal Techs</p>
                                    </div>
                                    <div className="coworking_myAssets_bookings_">
                                        <p className='caption color_light_grey'>
                                            <span>Requested on :</span>
                                            <span>15/05/2022</span>
                                        </p>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className='coworking_myAssets_bookings_AccDetail'>
                                <div className="coworking_myAssets_bookings_details">
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Cabin</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Discount(-)</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                    <div className="coworking_myAssets_booking_detail mb-16">
                                        <div className="coworking_myAssets_booking_cabin">
                                            <p className="caption color_light_grey">Grand total</p>
                                        </div>
                                        <div className="coworking_myAssets_booking_rs">
                                            <p className="caption color_light_grey">Rs.22000</p>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>


                    </div>
                </div>
            </div>
            )
        }
        
          
            <DeleteModal
                open={open}
                handleClose={handleClose}
                deleteFunction={deleteAsset}
            />
        </>
    )
}

export default AssetDetails