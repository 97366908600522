import React, { useEffect, useState } from 'react'
import { KeyboardBackspace, Add } from "@mui/icons-material"
// import bi_cart from "./../../../Assets/images/icons/bi_cart.png";
import Card from './Card';
import './cart.css'
import { Box, Modal } from '@mui/material';
import ConfirmModal from './ConfirmModal';
import Axios from "axios"
import { useAuth } from "../../../../Utils/Auth";
import { useLocation, useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 621,
    width: 621,
    maxWidth: "90%",
    bgcolor: '#ffffff',
    boxShadow: 24,
};

const MyCart = ({ setCoWorkingStep }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate()
    const [cartData, setCartData] = useState([])
    const [subtotal, setSubTotal] = useState(0)
    const [cardIds, setCartIds] = useState([])
    const { setSessionExpire, user } = useAuth();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const back = query.get("back");

    const getCart = async () => {
        await Axios({
            url: `/api/asset/cart/status/user/${false}/${false}/${false}`,
            method: 'get',
        }).then((res) => {
            setCartData(res.data.data)
            const { data } = res.data;
            let sum = 0;
            data.forEach(e => {
                sum += (e.quantity * e.asset_id.price)
            });
            setSubTotal(sum)

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                if (!cardIds.includes(element._id)) {
                    setCartIds((prev) => [...prev, element._id])
                }
            }

        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    const RequestToIncubator = async () => {
        await Axios({
            url: `/api/asset/request/cart`,
            method: "put",
            data: {
                cart_ids: [... new Set(cardIds)]
            }
        }).then((res) => {
            if (res.data.success) {
                getCart()
                handleClose()
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    useEffect(() => {
        getCart()
    }, []);

    return (
        <>
            <div className="startup_my_cart_root">
                <div className="startup_my_cart_head">
                    {/* <div className="row"> */}
                    {/* <div className="col-8"> */}
                    <div className="viewReq_head">
                        <button className='transparent_cta btncta' onClick={() => {
                            if (back === "estate") {
                                setCoWorkingStep(2)
                                navigate(`/dashboard/${user.type}?tab=9&cw=2`)
                            }
                            else {
                                setCoWorkingStep(4)
                                navigate(`/dashboard/${user.type}?tab=9&cw=4`)
                            }
                        }}><KeyboardBackspace /> Back</button>
                    </div>
                    {/* </div> */}
                    {/* <div className="col-4">
                            <div className="startup_assets_head_addToCard" onClick={() => setAssetView(2)}>
                                <p className="body1 mr-8">My Cart</p>
                                <img src={bi_cart} alt="bi_cart" />
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
                <div className="startup_my_cart_main mt-24">
                    <p className="subtitle">My Cart</p>
                    <div className="startup_my_cart_cards_main mt-24">
                        {
                            cartData.length > 0 ?
                                cartData.map((data, i) => <Card
                                    key={i}
                                    data={data}
                                    getCart={getCart}
                                />) : <div className='Empty_cart_main'>
                                    <h5>Cart is Empty</h5>
                                </div>
                        }

                    </div>
                </div>
                <div className="startup_my_cart_add_asset_main">
                    <div className="startup_my_cart_add_asset d-flex align-items-center point" onClick={() => {
                        setCoWorkingStep(4)
                        navigate(`/dashboard/${user.type}?tab=9&cw=4`)
                    }}>
                        <button><Add /></button>
                        <p className="btncta color_dark_black ml-12">Add assets</p>
                    </div>
                    <div className="startup_my_cart_add_asset d-flex align-items-center mt-16" onClick={() => {
                        setCoWorkingStep(2)
                        navigate(`/dashboard/${user.type}?tab=9&cw=2`)
                    }}>
                        <button><Add /></button>
                        <p className="btncta color_dark_black ml-12">Add real estate</p>
                    </div>
                </div>
                <div className="startup_my_cart_subtotal_main mt-24 d-flex align-items-end flex-column">
                    <div className="startup_my_cart_subtotal d-flex align-items-center point">
                        <p className="body1">Subtotal({cartData.length} items):</p>
                        <p className="body1 ml-24">€ {subtotal}</p>
                    </div>
                    <div className="startup_my_cart_request mt-24">
                        <button className='primaryFilled_activeCta' style={{ padding: "14px 60px" }} onClick={handleOpen}>Request all</button>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <ConfirmModal
                        handleClose={handleClose}
                        RequestToIncubator={RequestToIncubator}
                        cartData={cartData}
                        subtotal={subtotal}
                    />
                </Box>
            </Modal>
        </>

    )
}

export default MyCart