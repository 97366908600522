import React from 'react'
import Insights from './Insights/Insights'
import Invoicing from './Invoicing/Invoicing'
import PayRequest from './PayRequest/PayRequest'
import Transactions from './Transactions';
const FInanceMain = ({ finantialStep, setFinantialStep, setFinantialBool }) => {
    return (
        <div>
            {
                finantialStep === 1 ? <Insights
                    setFinantialStep={setFinantialStep}
                    finantialStep={finantialStep}
                /> : finantialStep === 2 ? <Invoicing
                    finantialStep={finantialStep}
                    setFinantialStep={setFinantialStep}
                    setFinantialBool={setFinantialBool}
                /> : finantialStep === 3 ? <PayRequest
                    finantialStep={finantialStep}
                    setFinantialStep={setFinantialStep}
                /> : finantialStep === 4 ? <Transactions
                finantialStep={finantialStep}
                setFinantialStep={setFinantialStep}
        />: <div className='coming_soon_root'>
                    <h4 style={{ color: "#988e83" }}>Coming Soon...</h4>
                </div>
            }
        </div>
    )
}

export default FInanceMain