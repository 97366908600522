import axios from "axios";
import React, { useEffect, useState } from "react";
import Barchart from "../Charts/barchart";
import Donutchart from "../Charts/donut";
import { useAuth } from "../../../../Utils/Auth";
import { toast } from "react-toastify";
import "./index.css";
// import analytic_rocket from "./../../../../Assets/images/analytic_rocket.png";
// import analytic_program from "./../../../../Assets/images/analytic_program.png";
// import analytic_turnover from "./../../../../Assets/images/analytic_turnover.png";
// import analytic_partner from "./../../../../Assets/images/analytic_partner.png";
import Rating from "@mui/material/Rating";
import { KeyboardArrowDown } from "@mui/icons-material";

const index = () => {
  const { setSessionExpire } = useAuth();

  const [partners] = useState([
    { name: "Mentors", value: 10 },
    { name: "Investors", value: 20 },
    { name: "Corporates", value: 15 },
    { name: "Service Providers", value: 15 },
  ]);
  // const [partners, setpartners] = useState([

  const [menteedata] = useState([
    { name: "Pentafield Tech", value: 10 },
    { name: "Rebase GmbH", value: 20 },
    { name: "AeroType Ventures", value: 15 },
  ]);
  // const [menteedata, setmenteedata] = useState([

  const [programs] = useState([
    { name: "Startup Acceleration", value: 10 },
    { name: "Fastrack Innovation", value: 20 },
    { name: "Zefyron Innovation", value: 15 },
  ]);
  // const [programs, setprograms] = useState([

  const [programid, setprogramid] = useState();
  const [startupnum] = useState(20);
  // const [startupnum, setstartupnumber] = useState(20);

  // const [engagement, setEngagement] = useState([

  const [engagement] = useState([
    { name: "Pentafield Tech", value: 10 },
    { name: "Rebase GmbH", value: 20 },
    { name: "AeroType Ventures", value: 15 },
  ]);

  const [startupbm] = useState([
    { name: "Pentafield Tech", value: 10 },
    { name: "Rebase GmbH", value: 20 },
    { name: "AeroType Ventures", value: 15 },
  ]);
  // const [startupbm, setstartupbm] = useState([]);

  const [startuprevenue] = useState([
    { name: "Pentafield Tech", value: 10 },
    { name: "Rebase GmbH", value: 20 },
    { name: "AeroType Ventures", value: 15 },
  ]);
  // const [startuprevenue, setstartuprevenue] = useState([

  const [mentorexperince] = useState([
    { name: "1-2 Years", value: 10 },
    { name: "5 Years", value: 20 },
    { name: "More than 10 years", value: 15 },
  ]);
  // const [mentorexperince, setmentorexperince] = useState([

  // const [, setspindustry] = useState([]);
  // const [, setcorpindustry] = useState([]);
  const [, setcorpostage] = useState();
  const [studentcollege] = useState([
    { name: "TU Berlin", value: 10 },
    { name: "Heidelberg University", value: 20 },
    { name: "Technische Universität München", value: 15 },
  ]);
  // const [studentcollege, setstudentcollege] = useState([

  const [studentstatus, setstudentstatus] = useState([
    { name: "Graduated", value: 10 },
    { name: "Incubating", value: 20 },
  ]);
  const [startupRating, setStartupRating] = useState({
    fiveRate: 0,
    fivePer: 0,
    fourRate: 0,
    fourPer: 0,
    threeRate: 0,
    threePer: 0,
    twoRate: 0,
    twoPer: 0,
    OneRate: 0,
    OnePer: 0,
    rating: 0,
    totalRating: 0,
    incubatorRating: 0,
    mentorRating: 0,
  });
  const [mentorRating, setMentorRating] = useState({
    fiveRate: 0,
    fivePer: 0,
    fourRate: 0,
    fourPer: 0,
    threeRate: 0,
    threePer: 0,
    twoRate: 0,
    twoPer: 0,
    OneRate: 0,
    OnePer: 0,
    rating: 0,
    totalRating: 0,
    incubatorRating: 0,
    investorRating: 0,
  });
  const [spRating, setSpRating] = useState({
    fiveRate: 0,
    fivePer: 0,
    fourRate: 0,
    fourPer: 0,
    threeRate: 0,
    threePer: 0,
    twoRate: 0,
    twoPer: 0,
    OneRate: 0,
    OnePer: 0,
    rating: 0,
    totalRating: 0,
    incubatorRating: 0,
    mentorRatings: 0,
  });
  const [CorporateRating, setCorporateRating] = useState({
    fiveRate: 0,
    fivePer: 0,
    fourRate: 0,
    fourPer: 0,
    threeRate: 0,
    threePer: 0,
    twoRate: 0,
    twoPer: 0,
    OneRate: 0,
    OnePer: 0,
    rating: 0,
    totalRating: 0,
    incubatorRating: 0,
    mentorRatings: 0,
  });
  const [investorRating, setInvestorRating] = useState({
    fiveRate: 0,
    fivePer: 0,
    fourRate: 0,
    fourPer: 0,
    threeRate: 0,
    threePer: 0,
    twoRate: 0,
    twoPer: 0,
    OneRate: 0,
    OnePer: 0,
    rating: 0,
    totalRating: 0,
    incubatorRating: 0,
    mentorRatings: 0,
  });
  const [studentrating, setstudentrating] = useState({
    fiveRate: 0,
    fivePer: 0,
    fourRate: 0,
    fourPer: 0,
    threeRate: 0,
    threePer: 0,
    twoRate: 0,
    twoPer: 0,
    OneRate: 0,
    OnePer: 0,
    rating: 0,
    totalRating: 0,
    incubatorRating: 0,
    mentorRatings: 0,
  });
  const [drop, setDrop] = useState(false);
  const [flag, setflag] = useState(true);
  const [selected, setselected] = useState("Select a program");

  const fetchprograms = async () => {
    await axios({
      url: `/api/incubatorProgram/programs`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          // setprograms(res.data.data);
          // if (res.data.data.length > 0) {
          //   setprogramid(res.data.data[0]._id);
          // }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getcorporateindustry = async () => {
    try {
      const response = await axios.get(`/api/stats/corp/industry/${programid}`);

      if (response.data.success) {
        // setcorpindustry(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getstartupnumber = async () => {
    try {
      const response = await axios.get(`/api/stats/startupsnum/${programid}`);

      if (response.data.success) {
        // setstartupnumber(response.data.data);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };

  const getpartners = async () => {
    try {
      const response = await axios.get(`/api/stats/partners/${programid}`);

      if (response.data.success) {
        // setpartners(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getmenteeratio = async () => {
    try {
      const response = await axios.get(
        `/api/incmentor/ratio/mentors/622ceefcd4da882df70349ca`
      );

      if (response.data.success) {
        // setmenteedata(response.data.data);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getstartupbm = async () => {
    try {
      const response = await axios.get(`/api/stats/startups/bm/${programid}`);

      if (response.data.success) {
        // setstartupbm(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getstartuprevenue = async () => {
    try {
      const response = await axios.get(
        `/api/stats/startups/revenue/${programid}`
      );
      if (response.data.success) {
        // setstartuprevenue(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getmentorexperince = async () => {
    try {
      const response = await axios.get(
        `/api/stats/mentors/experience/${programid}`
      );

      if (response.data.success) {
        // setmentorexperince(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getEngagement = async () => {
    await axios({
      url: `/api/mentorModules/durations/program/${programid}`,
      method: "GET",
    })
      .then(() => {
        // setEngagement(res.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.error);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getspindustry = async () => {
    try {
      const response = await axios.get(`/api/stats/sp/industry/${programid}`);

      if (response.data.success) {
        // setspindustry(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getcorpostage = async () => {
    try {
      const response = await axios.get(`/api/stats/corpo/stage/${programid}`);

      if (response.data.success) {
        setcorpostage(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };

  const getStartupRating = async () => {
    await axios({
      url: `/api/incubatorProgram/ratings/startups/${programid}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        const total =
          data.five_count +
          data.four_count +
          data.three_count +
          data.two_count +
          data.one_count;

        five = (data.five_count * 100) / total;
        four = (data.four_count * 100) / total;
        three = (data.three_count * 100) / total;
        two = (data.two_count * 100) / total;
        one = (data.one_count * 100) / total;

        setStartupRating(() => ({
          ...startupRating,
          OnePer: one,
          twoPer: two,
          threePer: three,
          fourPer: four,
          fivePer: five,
          OneRate: data.one_count,
          twoRate: data.two_count,
          threeRate: data.three_count,
          fourRate: data.four_count,
          fiveRate: data.five_count,
          incubatorRating: data.incubator_count,
          mentorRating: data.mentor_count,
          totalRating: data.mentor_count + data.incubator_count,
          rating:
            total === 0
              ? 0
              : (
                  (data.one_count * 1 +
                    data.two_count * 2 +
                    data.three_count * 3 +
                    data.four_count * 4 +
                    data.five_count * 5) /
                  total
                ).toFixed(1),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMentorRating = async () => {
    await axios({
      url: `/api/incmentor/ratings/mentors/${programid}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;

        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        const total =
          data.five_count +
          data.four_count +
          data.three_count +
          data.two_count +
          data.one_count;

        five = (data.five_count * 100) / total;
        four = (data.four_count * 100) / total;
        three = (data.three_count * 100) / total;
        two = (data.two_count * 100) / total;
        one = (data.one_count * 100) / total;

        setMentorRating(() => ({
          ...mentorRating,
          OnePer: one,
          twoPer: two,
          threePer: three,
          fourPer: four,
          fivePer: five,
          OneRate: data.one_count,
          twoRate: data.two_count,
          threeRate: data.three_count,
          fourRate: data.four_count,
          fiveRate: data.five_count,
          incubatorRating: data.incubator_count,
          investorRating: data.investor_count,
          totalRating: data.investor_count + data.incubator_count,
          rating:
            total === 0
              ? 0
              : (
                  (data.one_count * 1 +
                    data.two_count * 2 +
                    data.three_count * 3 +
                    data.four_count * 4 +
                    data.five_count * 5) /
                  total
                ).toFixed(1),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSpRating = async () => {
    await axios({
      url: `/api/sp-rating/ratings/${programid}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        const total =
          data.five_count +
          data.four_count +
          data.three_count +
          data.two_count +
          data.one_count;

        five = (data.five_count * 100) / total;
        four = (data.four_count * 100) / total;
        three = (data.three_count * 100) / total;
        two = (data.two_count * 100) / total;
        one = (data.one_count * 100) / total;

        setSpRating(() => ({
          ...spRating,
          OnePer: one,
          twoPer: two,
          threePer: three,
          fourPer: four,
          fivePer: five,
          OneRate: data.one_count,
          twoRate: data.two_count,
          threeRate: data.three_count,
          fourRate: data.four_count,
          fiveRate: data.five_count,
          incubatorRating: data.incubator_count,
          mentorRatings: data.mentor_count,
          totalRating: data.mentor_count + data.incubator_count,
          rating:
            total === 0
              ? 0
              : (
                  (data.one_count * 1 +
                    data.two_count * 2 +
                    data.three_count * 3 +
                    data.four_count * 4 +
                    data.five_count * 5) /
                  total
                ).toFixed(1),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCorporateRating = async () => {
    await axios({
      url: `/api/corporate-rating/ratings/${programid}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        const total =
          data.five_count +
          data.four_count +
          data.three_count +
          data.two_count +
          data.one_count;

        five = (data.five_count * 100) / total;
        four = (data.four_count * 100) / total;
        three = (data.three_count * 100) / total;
        two = (data.two_count * 100) / total;
        one = (data.one_count * 100) / total;

        setCorporateRating(() => ({
          ...CorporateRating,
          OnePer: one,
          twoPer: two,
          threePer: three,
          fourPer: four,
          fivePer: five,
          OneRate: data.one_count,
          twoRate: data.two_count,
          threeRate: data.three_count,
          fourRate: data.four_count,
          fiveRate: data.five_count,
          incubatorRating: data.incubator_count,
          mentorRatings: data.mentor_count,
          totalRating: data.mentor_count + data.incubator_count,
          rating:
            total === 0
              ? 0
              : (
                  (data.one_count * 1 +
                    data.two_count * 2 +
                    data.three_count * 3 +
                    data.four_count * 4 +
                    data.five_count * 5) /
                  total
                ).toFixed(1),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getstudentRating = async () => {
    await axios({
      url: `/api/incubator-student/ratings/${programid}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        const total =
          data.five_count +
          data.four_count +
          data.three_count +
          data.two_count +
          data.one_count;

        five = (data.five_count * 100) / total;
        four = (data.four_count * 100) / total;
        three = (data.three_count * 100) / total;
        two = (data.two_count * 100) / total;
        one = (data.one_count * 100) / total;

        setstudentrating(() => ({
          ...studentrating,
          OnePer: one,
          twoPer: two,
          threePer: three,
          fourPer: four,
          fivePer: five,
          OneRate: data.one_count,
          twoRate: data.two_count,
          threeRate: data.three_count,
          fourRate: data.four_count,
          fiveRate: data.five_count,
          incubatorRating: data.incubator_count,
          mentorRatings: data.mentor_count,
          totalRating: data.mentor_count + data.incubator_count,
          rating:
            total === 0
              ? 0
              : (
                  (data.one_count * 1 +
                    data.two_count * 2 +
                    data.three_count * 3 +
                    data.four_count * 4 +
                    data.five_count * 5) /
                  total
                ).toFixed(1),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getInvestorRating = async () => {
    await axios({
      url: `/api/investor-rating/ratings/${programid}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        const total =
          data.five_count +
          data.four_count +
          data.three_count +
          data.two_count +
          data.one_count;

        five = (data.five_count * 100) / total;
        four = (data.four_count * 100) / total;
        three = (data.three_count * 100) / total;
        two = (data.two_count * 100) / total;
        one = (data.one_count * 100) / total;

        setInvestorRating(() => ({
          ...investorRating,
          OnePer: one,
          twoPer: two,
          threePer: three,
          fourPer: four,
          fivePer: five,
          OneRate: data.one_count,
          twoRate: data.two_count,
          threeRate: data.three_count,
          fourRate: data.four_count,
          fiveRate: data.five_count,
          incubatorRating: data.incubator_count,
          mentorRatings: data.mentor_count,
          totalRating: data.mentor_count + data.incubator_count,
          rating:
            total === 0
              ? 0
              : (
                  (data.one_count * 1 +
                    data.two_count * 2 +
                    data.three_count * 3 +
                    data.four_count * 4 +
                    data.five_count * 5) /
                  total
                ).toFixed(1),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getstudentcollege = async () => {
    try {
      const response = await axios.get(
        `/api/stats/students/college/${programid}`
      );

      if (response.data.success) {
        // setstudentcollege(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getstudentstatus = async () => {
    try {
      const response = await axios.get(
        `/api/stats/students/status/${programid}`
      );

      if (response.data.success) {
        setstudentstatus(response.data.chartdata);
      }
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };

  useEffect(() => {
    if (programid) {
      //   getstartupindustry();
      getstudentRating();
      getstudentstatus();
      getstudentcollege();
      getcorpostage();
      getcorporateindustry();
      getstartupbm();
      getpartners();
      getmentorexperince();
      getmenteeratio();
      getstartupnumber();
      getstartuprevenue();
      getEngagement();
      getspindustry();
      getStartupRating();
      getMentorRating();
      getSpRating();
      getCorporateRating();
      getInvestorRating();
    }
  }, [programid]);
  useEffect(() => {
    fetchprograms();
  }, []);

  useEffect(() => {
    if (flag && programs && programs.length > 0) {
      setflag(false);
      setselected(programs[0].title);
    }
  });
  return (
    <>
      <div className="row m-0 w-100 mb-40">
        <div className="mentor_tasks_select_root w-100">
          <div className="searchbar_analytics w-100">
            <div className="searchbar_analytics_text">
              {/* <span className="mr-8"></span>  */}
              <p className="body2">{selected}</p>
            </div>
            <span
              className="searchbar_analytics_btn"
              onClick={() => setDrop(!drop)}
            >
              <span
                className="searchbar_analytics_btntext mr-14"
                style={{ COLOR: "#262C5B" }}
              >
                SELECT
              </span>
              <KeyboardArrowDown
                className={`mentorDownbtn ${
                  drop ? "rotateDown" : "antirotateDown"
                }`}
              />
            </span>
          </div>
          {drop ? (
            <div
              className={`mentor_tasks_options_root ${drop ? "show" : "hide"}`}
            >
              {programs && programs.length > 0
                ? programs.map((data, i) => (
                    <div
                      className="mentor_tasks_options point"
                      key={i}
                      onClick={() => {
                        setselected(data.title);
                        setprogramid(data._id);
                        setDrop(!drop);
                      }}
                    >
                      <span className="mr-8"></span>
                      <p className="caption color_dark_black2">{data.title}</p>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="row m-0 mb-40">
        <div className="col-12 col-md-3">
          <div className="analytictop-box">
            <div className="row m-0">
              <p className="mb-12 subtitle color_dark_black2">
                {" "}
                Total Startups
              </p>
              <div className="w-100 d-flex justify-content-between align-items-center">
                <h3 className="w-fit" style={{ color: "#580167" }}>
                  20
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#00BFD7"
                    d="M40.5 34.5c0-5.231-3.066-9.75-7.5-11.855v-7.772c0-.707-.248-1.392-.708-1.93l-7.144-8.471a1.498 1.498 0 00-2.296 0l-7.144 8.47A3 3 0 0015 14.873v7.772C10.566 24.75 7.5 29.27 7.5 34.5h7.336a3.622 3.622 0 00-.164 1.116 4.551 4.551 0 003.023 4.284A6.834 6.834 0 0024 44.063a6.822 6.822 0 003.816-1.163 6.803 6.803 0 002.484-3 4.547 4.547 0 002.02-1.43 4.57 4.57 0 001.003-2.85c0-.393-.051-.768-.145-1.115L40.5 34.5zM24 16.5a2.25 2.25 0 010 4.5 2.25 2.25 0 010-4.5zm5.442 20.26c-.244.14-.525.196-.801.159l-.914-.113-.132.91A3.65 3.65 0 0124 40.833a3.65 3.65 0 01-3.595-3.117l-.132-.914-.914.117a1.298 1.298 0 01-.801-.164 1.32 1.32 0 01-.661-1.144c0-.497.276-.91.684-1.116h10.842c.413.211.685.624.685 1.116a1.33 1.33 0 01-.666 1.148z"
                    opacity="0.4"
                  ></path>
                </svg>
                {/* <img className="" src={analytic_rocket} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="analytictop-box">
            <div className="row m-0">
              <p className="mb-12 subtitle color_dark_black2">Programs</p>
              <div className="w-100 d-flex justify-content-between align-items-center m-0">
                <h3 className="w-fit">12</h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <g fill="#00BFD7" opacity="0.4">
                    <path d="M39.75 6H8.25C7.125 6 6 7.125 6 8.25v25.313c0 1.125 1.125 2.25 2.25 2.25H19.5v2.25c0 1.562-2.027 2.25-4.5 2.25V42h18v-1.688c-2.474 0-4.5-.687-4.5-2.25v-2.25h11.25c1.125 0 2.25-1.125 2.25-2.25V8.25C42 7.125 40.875 6 39.75 6zm1.05 27.563c0 .461-.59 1.05-1.05 1.05H8.25c-.461 0-1.05-.589-1.05-1.05v-2.25h33.6v2.25z"></path>
                    <path d="M24 36.657a.704.704 0 100-1.407.704.704 0 000 1.407z"></path>
                  </g>
                </svg>
                {/* <img className="" src={analytic_program} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="analytictop-box">
            <div className="row m-0">
              <p className="mb-12 subtitle color_dark_black2">Partners</p>
              <div className="w-100 d-flex justify-content-between align-items-center m-0">
                <h3 className="w-fit" style={{ color: "#262C5B" }}>
                  35
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#00BFD7"
                    d="M0 33.8l4.8.003c1.331 0 2.4-1.06 2.4-2.377V14.83H0V33.8zm3.6-4.735c.656 0 1.2.529 1.2 1.187 0 .649-.544 1.187-1.2 1.187-.656 0-1.2-.54-1.2-1.186 0-.66.544-1.188 1.2-1.188zm22.56-19.007a2.41 2.41 0 00-1.614.618l-7.378 6.678c-.008.014-.008.03-.023.03a2.93 2.93 0 00-.157 4.155c.956.973 2.953 1.308 4.21.205.012-.011.02-.011.027-.019l5.991-5.425c.487-.436 1.256-.408 1.697.074a1.18 1.18 0 01-.076 1.679l-1.959 1.771L37.8 28.59c.216.186.413.371.59.575V14.74A16.459 16.459 0 0026.81 10l-.65.058zm-1.065 11.385l-2.25 2.04c-2.231 2.012-5.644 1.818-7.635-.324a5.307 5.307 0 01.337-7.542l6.136-5.56h-.495A16.492 16.492 0 009.614 14.8L9.6 31.418l1.369.003 6.787 6.072c2.063 1.66 5.081 1.345 6.75-.686l1.36 1.131c1.19.898 2.953.78 3.928-.398l2.353-2.865.403.334c1.032.816 2.541.668 3.375-.353l.716-.874a2.351 2.351 0 00-.352-3.335l-11.194-9.004zM40.8 14.838v16.595c0 1.308 1.069 2.379 2.333 2.379L48 33.808V14.815l-7.2.023zm3.6 16.595c-.656 0-1.2-.538-1.2-1.187 0-.659.544-1.187 1.2-1.187a1.2 1.2 0 011.2 1.194c0 .645-.54 1.18-1.2 1.18z"
                    opacity="0.4"
                  ></path>
                </svg>
                {/* <img className="" src={analytic_partner} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="analytictop-box">
            <div className="row m-0">
              <p className="mb-12 subtitle color_dark_black2">Turnover</p>
              <div className="w-100 d-flex justify-content-between align-items-center m-0">
                <h3 className="w-fit" style={{ color: "#000" }}>
                  20M
                </h3>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="48"
                  height="48"
                  fill="none"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#00BFD7"
                    d="M24 3C12.403 3 3 12.403 3 24s9.403 21 21 21 21-9.403 21-21S35.597 3 24 3zm2.977 17.616c.206 0 .375.168.375.375v1.275a.376.376 0 01-.375.375h-6.375c-.015.206-.015.426-.015.647v1.687h6.385c.206 0 .375.169.375.375v1.275a.376.376 0 01-.375.375h-6.117c.717 2.906 2.873 4.622 6.084 4.622.933 0 1.74-.056 2.428-.192a.373.373 0 01.445.365v2.007a.376.376 0 01-.3.365c-.745.16-1.607.24-2.592.24-5.147 0-8.578-2.757-9.384-7.407h-1.411a.376.376 0 01-.375-.375V25.35c0-.206.169-.375.375-.375h1.223v-1.73c0-.206 0-.412.015-.6h-1.238a.376.376 0 01-.375-.375v-1.275c0-.206.169-.375.375-.375h1.486c.923-4.415 4.312-7.026 9.31-7.026.979 0 1.846.089 2.591.253a.372.372 0 01.296.366v2.006h.005a.372.372 0 01-.45.365c-.69-.136-1.491-.206-2.424-.206-3.066 0-5.175 1.57-5.981 4.238h6.019z"
                    opacity="0.4"
                  ></path>
                </svg>
                {/* <img className="" src={analytic_turnover} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="analysis-allPartners-root">
        <p className="title">Startups</p>
        <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Revenue</p>
              <div className="row">
                <div className="col-6">
                  <Barchart data={startuprevenue} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratings</p>
              <div className="incubator_analytics_rating_root mt-40">
                <div className="incubator_analytics_rating_main_root">
                  <div className="incubator_analytics_rating_main">
                    <div className="incubator_analytics_rating_left">
                      {/* <h3>{startupRating.rating}</h3> */}
                      <h3>4.5</h3>

                      <div className="incubator_analytics_rating_">
                        <Rating
                          name="half-rating-read"
                          defaultValue={4.5}
                          precision={0.5}
                          readOnly
                          className="incubator_analytics_rating"
                          value={4.5}
                        />
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_star_root">
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">5</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${startupRating.fivePer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">4</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${startupRating.fourPer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">3</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${startupRating.threePer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">2</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${startupRating.twoPer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">1</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${startupRating.OnePer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_down mt-26">
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Total ratings : {startupRating.totalRating} */}
                        Total ratings : 15
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Mentors : {startupRating.mentorRating} */}
                        Mentors : 15
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Incubators : {startupRating.incubatorRating} */}
                        Incubators : 15
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Business Model</p>
              <Donutchart totalcount={startupnum} data={startupbm} />
            </div>
          </div>
        </div>
      </div>
      <div className="analysis-allPartners-root">
        <p className="title">Students</p>
        <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue">College</p>
              <div className="row">
                <div className="col-6">
                  <Barchart data={studentcollege} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratings</p>
              <div className="incubator_analytics_rating_root mt-40">
                <div className="incubator_analytics_rating_main_root">
                  <div className="incubator_analytics_rating_main">
                    <div className="incubator_analytics_rating_left">
                      {/* <h3>{studentrating.rating}</h3> */}
                      <h3>4.5</h3>
                      <div className="incubator_analytics_rating_">
                        <Rating
                          name="half-rating-read"
                          // defaultValue={studentrating.rating}
                          defaultValue={4.5}
                          precision={0.5}
                          readOnly
                          className="incubator_analytics_rating"
                          // value={studentrating.rating}
                          value={4.5}
                        />
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_star_root">
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">5</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${studentrating.fivePer}%` }}
                            style={{ width: `75%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">4</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${studentrating.fourPer}%` }}
                            style={{ width: `60%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">3</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${studentrating.threePer}%` }}
                            style={{ width: `55%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">2</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${studentrating.twoPer}%` }}
                            style={{ width: `55%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">1</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${studentrating.OnePer}%` }}
                            style={{ width: `55%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_down mt-26">
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Total ratings : {studentrating.totalRating} */}
                        Total ratings : 20
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Mentors : {studentrating.mentorRating} */}
                        Mentors : 15
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Incubators : {studentrating.incubatorRating} */}
                        Incubators : 5
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Status</p>
              <Donutchart
                // totalcount={
                //   studentstatus.length > 0
                //     ? studentstatus[0].value + studentstatus[1].value
                //     : 0
                // }
                totalcount={20}
                data={studentstatus}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="analysis-allPartners-root">
        <p className="title">PARTNERS</p>
        <div className="row mt-24">
          <div className="col-lg-8 col-12">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> All Partners</p>
              <div className="row">
                <div className="col-6">
                  <Donutchart
                    totalcount={10}
                    data={partners}
                    type={"allpartners"}
                  />
                </div>
                <div className="col-6">
                  <div className="allparterns_analytic_total_allmain">
                    <div className="allparterns_analytic_total_perticular">
                      <div className="allparterns_analytic_mentor_total_head">
                        <p className="body2">Mentors</p>
                        <p>{partners.length > 0 ? partners[1].value : 0} </p>
                      </div>
                      <div className="allparterns_analytic_mentor_total_body">
                        <div
                          className="allparterns_analytic_mentor_total_progress"
                          style={{
                            width: `${
                              partners.length > 0
                                ? (partners[1].value /
                                    (partners[0].value +
                                      partners[1].value +
                                      partners[2].value +
                                      partners[3].value)) *
                                  100
                                : 0
                            }%`,
                            backgroundColor: "#121843",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="allparterns_analytic_total_perticular">
                      <div className="allparterns_analytic_mentor_total_head">
                        <p className="body2">Corporates</p>
                        <p>{partners.length > 0 ? partners[3].value : 0} </p>
                      </div>
                      <div className="allparterns_analytic_mentor_total_body">
                        <div
                          className="allparterns_analytic_mentor_total_progress"
                          style={{
                            width: `${
                              partners.length > 0
                                ? (partners[3].value /
                                    (partners[0].value +
                                      partners[1].value +
                                      partners[2].value +
                                      partners[3].value)) *
                                  100
                                : 0
                            }%`,
                            backgroundColor: "#98B3D6",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="allparterns_analytic_total_perticular">
                      <div className="allparterns_analytic_mentor_total_head">
                        <p className="body2">Investors</p>
                        <p>{partners.length > 0 ? partners[0].value : 0} </p>
                      </div>
                      <div className="allparterns_analytic_mentor_total_body">
                        <div
                          className="allparterns_analytic_mentor_total_progress"
                          style={{
                            width: `${
                              partners.length > 0
                                ? (partners[0].value /
                                    (partners[0].value +
                                      partners[1].value +
                                      partners[2].value +
                                      partners[3].value)) *
                                  100
                                : 0
                            }%`,
                            backgroundColor: "#FF6F1E",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="allparterns_analytic_total_perticular">
                      <div className="allparterns_analytic_mentor_total_head">
                        <p className="body2">Service providers</p>
                        <p>{partners.length > 0 ? partners[2].value : 0} </p>
                      </div>
                      <div className="allparterns_analytic_mentor_total_body">
                        <div
                          className="allparterns_analytic_mentor_total_progress"
                          style={{
                            width: `${
                              partners.length > 0
                                ? (partners[2].value /
                                    (partners[0].value +
                                      partners[1].value +
                                      partners[2].value +
                                      partners[3].value)) *
                                  100
                                : 0
                            }%`,
                            backgroundColor: "#580167",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue">
                {" "}
                Engagement (Program wise)
              </p>
              <Donutchart data={engagement} totalcount={10} />
            </div>
          </div>
        </div>
      </div>
      <div className="analysis-allPartners-root">
        <p className="title">Mentors</p>
        <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Experience</p>
              <div className="row">
                <div className="col-12">
                  <Donutchart
                    totalcount={partners.length > 0 ? partners[1].value : 0}
                    data={mentorexperince}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratings</p>

              <div className="incubator_analytics_rating_root mt-40">
                <div className="incubator_analytics_rating_main_root">
                  <div className="incubator_analytics_rating_main">
                    <div className="incubator_analytics_rating_left">
                      {/* <h3>{mentorRating.rating}</h3> */}
                      <h3>{4.5}</h3>

                      <div className="incubator_analytics_rating_">
                        <Rating
                          name="half-rating-read"
                          // defaultValue={mentorRating.rating}
                          defaultValue={4.5}
                          precision={0.5}
                          readOnly
                          className="incubator_analytics_rating"
                          // value={mentorRating.rating}
                          value={4.5}
                        />
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_star_root">
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">5</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${mentorRating.fivePer}%` }}
                            style={{ width: `40%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">4</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${mentorRating.fourPer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">3</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `50%` }}

                            // style={{ width: `${mentorRating.threePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">2</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `50%` }}

                            // style={{ width: `${mentorRating.twoPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">1</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            // style={{ width: `${mentorRating.OnePer}%` }}
                            style={{ width: `50%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_down mt-26">
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Total ratings : {mentorRating.totalRating} */}
                        Total ratings : 4
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Incubators : {mentorRating.incubatorRating} */}
                        Incubators : 5
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        {/* Investors : {mentorRating.investorRating} */}
                        Investors : 3
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratio of Mentees</p>
              <Barchart data={menteedata} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="analysis-allPartners-root">
        <p className="title">Service Providers</p>
        <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Industry</p>
              <div className="row">
                <div className="col-6">
                 <Donutchart
                    totalcount={partners.length > 0 ? partners[2].value : 0}
                    data={spindustry}
                  /> 
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratings</p>
            
              <div className="incubator_analytics_rating_root mt-40">
                <div className="incubator_analytics_rating_main_root">
                  <div className="incubator_analytics_rating_main">
                    <div className="incubator_analytics_rating_left">
                      <h3>{spRating.rating}</h3>
                      <div className="incubator_analytics_rating_">
                        <Rating
                          name="half-rating-read"
                          defaultValue={spRating.rating}
                          precision={0.5}
                          readOnly
                          className="incubator_analytics_rating"
                          value={spRating.rating}
                        />
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_star_root">
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">5</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${spRating.fivePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">4</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${spRating.fourPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">3</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${spRating.threePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">2</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${spRating.twoPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">1</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${spRating.OnePer}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_down mt-26">
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Total ratings : {spRating.totalRating}
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Mentors : {spRating.mentorRatings}
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Incubators : {spRating.incubatorRating}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue">Service Provided </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="analysis-allPartners-root">
        {/* <p className="title">Corporates</p>
        <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Industry</p>
              <div className="row">
                <div className="col-6">
                   <Donutchart
                    totalcount={partners.length > 0 ? partners[3].value : 0}
                    data={corpindustry}
                  /> 
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratings</p>
          
              <div className="incubator_analytics_rating_root mt-40">
                <div className="incubator_analytics_rating_main_root">
                  <div className="incubator_analytics_rating_main">
                    <div className="incubator_analytics_rating_left">
                      <h3>{CorporateRating.rating}</h3>
                      <div className="incubator_analytics_rating_">
                        <Rating
                          name="half-rating-read"
                          defaultValue={CorporateRating.rating}
                          precision={0.5}
                          readOnly
                          className="incubator_analytics_rating"
                          value={CorporateRating.rating}
                        />
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_star_root">
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">5</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${CorporateRating.fivePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">4</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${CorporateRating.fourPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">3</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${CorporateRating.threePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">2</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${CorporateRating.twoPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">1</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${CorporateRating.OnePer}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_down mt-26">
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Total ratings : {CorporateRating.totalRating}
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Mentors : {CorporateRating.mentorRatings}
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Incubators : {CorporateRating.incubatorRating}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Funds/Sponsor</p>
              <Donutchart
                totalcount={partners.length > 0 ? partners[3].value : 0}
                data={corpostage}
              /> 
            </div>
          </div>
        </div> */}
        {/* <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue">
                {" "}
                Service Providers (sector)
              </p>
              <Donutchart data={spindustry} totalcount={spsum} />
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue">
                Corporates according to industry
              </p>
              <Barchart data={corpoindustry} />
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue">
                Investors according to industry
              </p>
              <Barchart />
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="analysis-allPartners-root">
        <p className="title">Investors</p>
        <div className="row mt-24">
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Industry</p>
              <div className="row">
                <div className="col-12">
                 <Donutchart totalcount={startupnum} data={engagement} /> 
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Ratings</p>
             
              <div className="incubator_analytics_rating_root mt-40">
                <div className="incubator_analytics_rating_main_root">
                  <div className="incubator_analytics_rating_main">
                    <div className="incubator_analytics_rating_left">
                      <h3>{investorRating.rating}</h3>
                      <div className="incubator_analytics_rating_">
                        <Rating
                          name="half-rating-read"
                          defaultValue={investorRating.rating}
                          precision={0.5}
                          readOnly
                          className="incubator_analytics_rating"
                          value={investorRating.rating}
                        />
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_star_root">
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">5</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${investorRating.fivePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">4</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${investorRating.fourPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">3</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${investorRating.threePer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main mb-20">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">2</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${investorRating.twoPer}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="incubator_analytics_rating_star_main">
                        <div className="incubator_analytics_star_left5">
                          <p className="overline color_dark_grey2">1</p>
                          <p className="smallVector">&#9733;</p>
                        </div>
                        <div className="incubator_analytics_rating_star">
                          <div
                            className="incubator_analytics_rating_star_per"
                            style={{ width: `${investorRating.OnePer}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_down mt-26">
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Total ratings : {investorRating.totalRating}
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Mentors : {investorRating.mentorRatings}
                      </p>
                    </div>
                    <div className="incubator_analytics_rating_down1">
                      <p className="overline color_dark_grey2">
                        Incubators : {startupRating.incubatorRating}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="analysis-allPartners-main">
              <p className="body1 color_dark_blue"> Investment value</p>
              <p>Funds</p>
            </div>
          </div>
        </div>
       
      </div> */}
    </>
  );
};

export default index;
