import React, { useEffect, useState } from 'react'
import HeaderComp from './HeaderComp'
import './analytics.css'
import { ChevronRight } from "@mui/icons-material"
// import App_center from "../../../../../Assets/images/financial/App_center.png"
// import checkout from "../../../../../Assets/images/financial/checkout.png"
import Invoic_img from "../../../../../Assets/images/financial/Invoic_img.png"
import Req_pay from "../../../../../Assets/images/financial/Req_pay.png"
// import Send_pay from "../../../../../Assets/images/financial/Send_pay.png"
import BankSVG from '../../../../../Assets/images/financial/finance-bank.svg'
import transaction from "../../../../../Assets/images/financial/transaction.png"
import Axios from "axios";
import { useAuth } from '../../../../../Utils/Auth'
import { useNavigate } from 'react-router-dom'

const Analytics = ({setAccountStep,setFinantialStep}) => {
    const { setSessionExpire, user, } = useAuth();
    const [moneyIn, setMoneyIn] = useState(0)
    const [moneyReq, setMoneyReq] = useState(0)
    const [accountDetails, setAccountDetails] = useState(null)
    const navigate = useNavigate()
    const getMoneyIn = async () => {
        await Axios({
            url: `/api/inc-finance/leases/amount`,
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setMoneyIn(res.data.data);
        }).catch(() => {
            setSessionExpire(true)
        })
    }
    const getMoneyReq = async () => {
        await Axios({
            url: `/api/inc-finance/invoices/amount`,
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setMoneyReq(res.data.data);
        }).catch(() => {
            setSessionExpire(true)
        })
    }

 
    const getAccountDetails = async () => {
        await Axios({
            url: `/api/account-details/user/${user?.id}`,
            method: "get",
        }).then(res => {
            // console.log(res.data.data)
            setAccountDetails(res.data.data)
        })
    }
    const replaceString = (string) => {
        if (string?.length > 0) {
            return string.replace(/.(?=.{4})/g, 'X');
        } else {
            return string
        }

    }
    useEffect(() => {
        getMoneyIn()
        getMoneyReq()
        getAccountDetails()
    }, [])
    return <div className="incubator_insights_root">
        <div className="incubator_insights_head">
            <div className="d-flex justify-content-between align-items-center">
                <p className="title">Insights</p>
                <select name="days" className='incubator_insights_head_sct'>
                    <option value="past 7 days">Past 7 Days</option>
                    <option value="past 15 days">Past 15 Days</option>
                    <option value="past 30 days">Past 30 Days</option>
                </select>
            </div>
            <div className="incubator_insights_head_main mt-24 mb-40">
                <div className="row">
                    <HeaderComp
                        heading="Money In"
                        subHead="Total recieved"
                        money={moneyIn}
                    />
                    <HeaderComp
                        heading="Money Out"
                        subHead="Total sent"
                        money={0}
                    />
                    <HeaderComp
                        heading="Money requested"
                        subHead="Pending payments"
                        money={moneyReq}
                    />
                </div>
            </div>
        </div>
        <div className="incubator_insights_main mt-40">
            <div className="row">
                <div className="col-lg-7 col-md-6 col-12">
                    <div className="incubator_insights_glance_root">
                        <p className="total">At a glance</p>
                        <div className="incubator_insights_glance_main mt-24 onboarding_cards3 p-24">
                            <div className="incubator_insights_glance_head">
                                <div className="row align-items-center">
                                    <div className="col-10 d-flex align-items-center justify-content-between">
                                        <p className="subtitle">Invoices and estimates</p>
                                        <select name="glance" className='glance_select incubator_insights_head_sct  color_dark_grey2'>
                                            <option value="Last 6 months">Last 6 months</option>
                                            <option value="Last 1 year">Last 1 year</option>
                                            <option value="Last 2 years">Last 2 years</option>
                                        </select>
                                    </div>
                                    <div className="col-2 p-0">
                                        <p className="caption">More <ChevronRight
                                            style={{
                                                height: 16,
                                                width: 16
                                            }}
                                        /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="incubator_insights_glance_body mt-24">
                                <div className="incubator_insights_unpaid_invoice mb-24">
                                    <div className="incubator_insights_unpaid_">
                                        <select name="invoice" className='incuba_unpaid_invoice body2'>
                                            <option value="Unpaid Invoices">Unpaid Invoices (4)</option>
                                            <option value="Paid Invoices">Paid Invoices (4)</option>
                                        </select>
                                    </div>
                                    <div className="incubator_insights_c_inv mt-12 d-flex align-items-center">
                                        <p className="body2 color_slaty_dark pl-24">Create Invoice</p>
                                        <p className="body2 color_slaty_dark pl-40">Manage Invoice</p>
                                    </div>
                                </div>
                                <div className="incubator_insights_estimate_root">
                                    <div className="incubator_insights_estimate_main">
                                        <p className="body2">Send estimates to grow your business</p>
                                    </div>
                                    <div className="incubator_insights_estimate_ pl-24 mt-12">
                                        <p className="body2 color_slaty_dark">Create an Estimate</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                    <div className="incubator_insights_glance_qLinks">
                        <p className="total">Getting Started</p>
                        <div className="incubator_insights_quick_links mt-24">
                            <div className="row incubator_insights_glance_main mt-24 onboarding_cards3 p-24">
                                {
                                    user?.is_account === false && <div className="col-12">

                                        <p>Setup checkout with quick setup for individual item and make your first purchase.</p>

                                        <div className='d-flex flex-column justify-content-center align-items-center my-5'>
                                            <img src={BankSVG} alt="bank" />
                                            <br />
                                            <button className='primaryFilled_activeCta d-flex justify-content-center align-items-center' style={{ background: '#262C5B', color: '#FFF', fontSize: '.8rem' }}
                                            onClick={()=>setAccountStep(1)}
                                            >
                                                Add your Account
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    user?.is_account && <div className="col-12">
                                        {/* <div className='my-2'>
                                            <img src={BankSVG} alt="bank" />
                                            
                                        </div> */}
                                        <div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6' >A/C Number </td>
                                                <td className='col-6'>{accountDetails?.account_no && replaceString(accountDetails?.account_no)}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6' >IFSC code </td>
                                                <td className='col-6'>{accountDetails?.ifsc_code && accountDetails?.ifsc_code}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6' >Bank Name </td>
                                                <td className='col-6'>{accountDetails?.bank_name && accountDetails?.bank_name}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6'>A/C Holder Name </td>
                                                <td className='col-6'>{accountDetails?.account_no && accountDetails?.name}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6'>Business Name</td>
                                                <td className='col-6'>{accountDetails?.business_name && accountDetails?.business_name}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6' >Business Type</td>
                                                <td className='col-6'>{accountDetails?.business_type && accountDetails?.business_type}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6'>GST Number</td>
                                                <td className='col-6'>{accountDetails?.billing_address.gstNumber.length >0 ? accountDetails?.billing_address.gstNumber : '--'}</td>
                                            </div>
                                            <div className='row'>
                                                <td className='font-weight-bold col-6'>Address</td>
                                                <td className='col-6'>{accountDetails?.billing_address && <span>{accountDetails?.billing_address.address}, {accountDetails?.billing_address.city}, {accountDetails?.billing_address.state}, {accountDetails?.billing_address.pinCode }</span>}</td>
                                            </div>
                                        </div>
                                        <button className='primaryFilled_activeCta float-right mt-2'
                                        onClick={()=>{
                                            setAccountStep(1)
                                            navigate(`/dashboard/incubator?tab=8&ft=1&account-edit=${true}&id=${accountDetails._id}`)
                                        }} 
                                        >
                                            Edit
                                        </button>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="incubator_insights_glance_qLinks">
                        <p className="total">Quick links</p>
                        <div className="incubator_insights_quick_links mt-24">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12" onClick={()=>{
                                        setFinantialStep(2)
                                        navigate(`/dashboard/${user.type}?tab=8&ft=2&in=0`)
                                    }}
                                    style={{cursor:'pointer'}}
                                    >
                                    <div className="incubator_insights_appCard_root onboarding_cards3">
                                        <img src={Invoic_img} alt="Invoicing" />
                                        <p className="body1 mt-20">Invoicing</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12" style={{cursor:'pointer'}}
                                    onClick={()=>{
                                        setFinantialStep(3)
                                        navigate(`/dashboard/${user.type}?tab=8&ft=3`);
                                    }}
                                >
                                    <div className="incubator_insights_appCard_root onboarding_cards3">
                                        <img src={Req_pay} alt="Request Payment" />
                                        <p className="body1 mt-20">Request Payment</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12" style={{cursor:'pointer'}}
                                    onClick={()=>{
                                        setFinantialStep(4)
                                        navigate(`/dashboard/${user.type}?tab=8&ft=4`);
                                    }}>
                                    <div className="incubator_insights_appCard_root onboarding_cards3">
                                        <img src={transaction} alt="Transactions"  />
                                        <p className="body1 mt-20">Transactions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Analytics