import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import Checkbox from "@mui/material/Checkbox";

const CreateAnn = ({ id, getannoucements, handleClose }) => {
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [startup, setstartup] = useState(false);
  const [mentor, setmentor] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios({
        method: "put",
        url: `/api/announcement/update/${id}`,
        data: {
          title,
          description,
          startup,
          mentor,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Annoucement Updated");
          }
          getannoucements();
          handleClose();
        })
        .catch((err) => {
          return toast.error(err.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getannoucement = async () => {
    const response = await Axios.get(`/api/announcement/one/${id}`);
    if (response.data.success) {
      setTitle(response.data.data.title);
      setdescription(response.data.data.description);
      setstartup(response.data.data.startup);
      setmentor(response.data.data.mentor);
    }
  };

  useEffect(() => {
    getannoucement();
  }, []);

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="register_page_form row">
          <div className="col-12">
            <label>Title</label>
            <input
              placeholder="Enter Title"
              required
              type="text"
              className="auth_input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label>Description </label>
            <textarea
              required
              name="msg"
              className="auth_input auth_textarea"
              placeholder="Write a description..."
              value={description}
              onChange={(e) => setdescription(e.target.value)}
            ></textarea>
          </div>
          <label> Send to</label>
          <div className="col-12">
            <label> Startup</label>
            <Checkbox
              checked={startup}
              onClick={(e) => setstartup(e.target.checked)}
            />
          </div>
          <div className="col-12">
            <label> Mentor</label>
            <Checkbox
              checked={mentor}
              onClick={(e) => setmentor(e.target.checked)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button type="submit" className="gradient_CTA">
            &nbsp; &nbsp; UPDATE&nbsp; &nbsp;{" "}
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateAnn;
