import React, { useState, useEffect } from "react";
import "./index.css";
// import Avatar from "@mui/material/Avatar";
// // import AvatarGroup from "@mui/material/AvatarGroup";
// import Stack from "@mui/material/Stack";
import { useAuth } from "../../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import CreateNotes from "./createNotes";
import { toast } from "react-toastify";
import get_isodiff from "../../../Utils/ISOdiff/isodiff";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FeedbackModal from "../../../Utils/Feedback";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "#F8F9FA",
  boxShadow: 24,
  p: 4,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function meet({ programid }) {
  const navigate = useNavigate();
  const [currmeet, setCurrmeet] = useState("");
  const [notesopen, setNotesOpen] = useState(false);
  const [meets, setmeets] = useState([]);
  let query = useQuery();
  const handleNotesOpen = (id) => {
    setNotesOpen(true);
    setCurrmeet(id);
  };
  const handleNotesClose = () => setNotesOpen(false);
  const { setSessionExpire, user } = useAuth();

  const getmeets = async () => {
    try {
      const response = await Axios.get(
        `/api/incmeet/particular/meets/${programid}`
      );
      console.log(response);
      if (response.data.success) setmeets(response.data.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      } else {
        toast.error("Error");
      }
    }
  };

  const handleStart = (key, title, name) => {
    navigate(
      `/jitsi-meet/${key}?title=${title}&user=${name}&programid=${programid}&d=userprogram`
    );
  };
  useEffect(() => {
    getmeets();
  }, []);

  const key = query.get("key");
  const feedback = query.get("feedback");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log("jd33344");
    if (key && feedback) {
      handleOpen();
    }
  }, [key, feedback]);

  return (
    <>
      <FeedbackModal
        open={open}
        handleClose={handleClose}
        program_id={programid}
      />
      <div className="meet-container row m-0">
        <div className="col-12 col-md-8">
          <div className="row">
            {meets?.length > 0 ? (
              meets.map((v, i) => {
                return (
                  <>
                    <div key={i} className="col-12 mb-4 ">
                      <div className="p-3 meet-head-box">
                        <div className="row">
                          <div className="col-10">
                            <p className="title mb-3">{v.title}</p>
                            <div className="meet-card-smalltext mb-4">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.99301 0.333008C3.31301 0.333008 0.333008 3.31967 0.333008 6.99967C0.333008 10.6797 3.31301 13.6663 6.99301 13.6663C10.6797 13.6663 13.6663 10.6797 13.6663 6.99967C13.6663 3.31967 10.6797 0.333008 6.99301 0.333008ZM9.19301 10.1397L6.33301 7.27301V3.66634H7.66634V6.72634L10.1397 9.19967L9.19301 10.1397Z"
                                  fill="#989898"
                                />
                              </svg>
                              &nbsp;
                              {new Date(v.schedule).toLocaleString()},{" "}
                              {get_isodiff(v.schedule) === "Live"
                                ? "Live"
                                : get_isodiff(v.schedule) === "Expired"
                                ? "Expired"
                                : "Starts in " + get_isodiff(v.schedule)}
                            </div>
                          </div>
                          <div className="col-2 text-right">
                            <div
                              className="secondaryFilled_activeCta p-0 "
                              style={{ fontSize: "10px", cursor: "pointer" }}
                              onClick={() => handleNotesOpen(v._id)}
                            >
                              Notes
                            </div>
                          </div>
                        </div>
                        {get_isodiff(v.schedule) === "Live" ? (
                          <>
                            <div className="btn_pos">
                              <button
                                type="button"
                                onClick={() =>
                                  handleStart(v.meet_id, v.title, user.name)
                                }
                                className="gradient_CTA"
                              >
                                JOIN NOW
                              </button>
                            </div>
                          </>
                        ) : get_isodiff(v.schedule) === "Expired" ? (
                          <>
                            <>
                              <div className="btn_pos">
                                <button
                                  type="button"
                                  disabled
                                  className="gradient_CTA"
                                >
                                  EXPIRED
                                </button>
                              </div>
                            </>
                          </>
                        ) : (
                          <>
                            <div className="btn_pos">
                              <button
                                disabled
                                type="button"
                                className="gradient_CTA"
                              >
                                {get_isodiff(v.schedule)} left
                              </button>
                            </div>
                          </>
                        )}

                        <p>Hosted by {v.hostid.name}</p>
                        {/* <Stack direction="row" spacing={2}>
                        <Avatar
                          variant="circular"
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                        />
                        <Avatar
                          variant="circular"
                          alt="Travis Howard"
                          src="/static/images/avatar/2.jpg"
                        />
                        <Avatar
                          variant="circular"
                          alt="Cindy Baker"
                          src="/static/images/avatar/3.jpg"
                        />
                        <Avatar
                          variant="circular"
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                        />
                      </Stack> */}
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <p>No Recent Meetings</p>
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={notesopen}
        onClose={handleNotesClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={style}>
          <CreateNotes meetid={currmeet} handleClose={handleNotesClose} />
        </Box>
      </Modal>
    </>
  );
}

export default meet;
