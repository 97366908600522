import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import Axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Utils/Auth";
import "./createProgram.css";
import cloud_upload from "../../Assets/images/upload_doc.png";
import industries2 from "../../Pages/Lists/Industries2.json";
import documentsList from "./documents.json";
import studentsdocumentsList from "./studentdocuments.json";
import Multiselect from "multiselect-react-dropdown";
import Query from "../../Utils/Query/Query";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import NumberFormat from "react-number-format";
const createProgram = ({ setEventActiveStep }) => {
  const navigate = useNavigate();
  const { user, setSessionExpire } = useAuth();
  const query = Query();
  const programid = query.get("programid");
  const ifclone = query.get("clone");

  const [options] = useState(industries2);
  const [options2] = useState(documentsList);
  const [options3] = useState(studentsdocumentsList);
  const [programDeadline, setprogramDeadline] = useState("");
  const [programStartDate, setprogramStartDate] = useState("");
  const [programCriteria, setprogramCriteria] = useState([]);
  const [programHighlights, setprogramHighlights] = useState([]);
  const [programBenefits, setprogramBenefits] = useState([]);
  const [DocumentsNeed, setDocumentsNeed] = useState([]);
  const [programWebsite, setprogramWebsite] = useState("");
  const [programDescription, setprogramDescription] = useState("");
  const [programTitle, setprogramTitle] = useState("");
  const [programsector, setprogramsector] = useState([]);
  const [Programtype, setProgramtype] = useState(null);
  const [coverImage, setCoverImageUrl] = useState(null);
  const [contract, setcontract] = useState(null);
  const [contractname, setcontractname] = useState("");
  const [islogoloading, setislogoloading] = useState(false);
  const [benefit, setbenefit] = useState("");
  const [highlight, sethighlight] = useState("");
  const [eligibility, seteligibility] = useState("");
  const [isdocloading, setisDocUploading] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [isClone, setisClone] = useState(false);

  const [isFree, setIsFree] = useState(true);
  const [participationAmmount, setParticipationAmmount] = useState(null);
  const [programPaymentType, setProgramPaymentType] = useState("free");

  const [isDraft, setIsDraft] = useState(false);
  const [formatedValue, setFormatedValue] = useState(participationAmmount);

  function handleValuation(e) {
    setFormatedValue(e.target.value);
    let result = e.target.value;
    if (result) {
      var n = result.split("€", 2)[1];
      setParticipationAmmount(Number(n.split(",").join("")));
    }
  }

  const fetchEvent = async () => {
    await Axios({
      method: "get",
      url: `/api/incubatorProgram/program/${programid}`,
    })
      .then((res) => {
        const { data } = res.data;
        console.log(data);
        if (data.programStatus === "publish") {
          setisUpdate(true);
          if (ifclone == "true") {
            setisClone(true);
          }
          setIsDraft(false);
        } else if (data.programStatus === "draft") {
          setisUpdate(false);
          setIsDraft(true);
        }
        setprogramTitle(data.title);
        setProgramtype(data.type);
        setprogramsector(data.sector);
        setCoverImageUrl(data.coverImage);
        setprogramDescription(data.description);
        setprogramWebsite(data.website);
        setprogramBenefits(data.benefits);
        setprogramHighlights(data.highlights);
        setprogramCriteria(data.eligibility);
        setDocumentsNeed(data.requiredDocs);
        // console.log(new Date(data.startDate).toLocaleDateString('en-GB'))
        setprogramStartDate(data.startDate);
        // setprogramStartDate(new Date(data.startDate).toLocaleDateString('en-GB'))
        setprogramDeadline(new Date(data.endDate).toLocaleDateString("en-GB"));
        setprogramDeadline(data.endDate);
        setcontractname(data.contracts.title);
        setcontract(data.contracts.link);
        if (data.programPaymentType === "paid") {
          setIsFree(false);
          setParticipationAmmount(data.participationAmmount);
          setFormatedValue(data.participationAmmount);
          setProgramPaymentType(data.programPaymentType);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        if (error.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    if (programid) {
      fetchEvent();
    }
  }, [programid]);

  const handleLogoUpload = (e) => {
    e.preventDefault();
    const image = e.target.files[0];

    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 10 * 1048 * 1024) {
      toast.error("File size exceeded 10MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("companyLogo", image);
    setislogoloading(true);
    Axios({
      method: "post",

      url: "/api/upload/companylogo",

      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          setCoverImageUrl(data.location);
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }

        setislogoloading(false);
        toast.success("Cover uploaded", { position: "bottom-right" });
        // setProgress(25);
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  const addObjective = (arr, seto, v, setv) => {
    if (arr && v.length > 0) {
      seto((prev) => [...prev, v]);
      setv("");
    }
  };

  const removeObjective = (arr, seto, id) => {
    const newArr = arr.filter((obj, i) => {
      return id !== i;
    });
    seto(newArr);
  };

  const handlecontractUpload = (e) => {
    e.preventDefault();
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!");
      return;
    }
    const formdata = new FormData();
    formdata.append("photoID", image);
    setisDocUploading(true);
    Axios({
      method: "post",
      url: "/api/upload/photoID",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;

        if (data) setcontract(data.location);
        setisDocUploading(false);
        toast.success("Contract uploaded");
        setcontract(data.location);
      })
      .catch(() => {
        toast.error("Error in upload");
        setcontract(null);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      programDeadline.length === 0 ||
      programStartDate.length === 0 ||
      // programCriteria.length === 0 ||
      DocumentsNeed.length === 0 ||
      programBenefits.length === 0 ||
      programDescription.length === 0 ||
      programTitle.length === 0 ||
      Programtype.length === 0 ||
      programHighlights.length === 0
    )
      return toast.warning("Some fields are missing");

    if (coverImage === null) return toast.warning("Upload cover image");
    await Axios({
      method: "post",
      url: "/api/incubatorProgram/create",
      data: {
        title: programTitle,
        type: Programtype,
        sector: programsector,
        coverImage: coverImage,
        description: programDescription,
        website: programWebsite,
        benefits: programBenefits,
        highlights: programHighlights,
        eligibility: programCriteria,
        startDate: new Date(programStartDate),
        endDate: new Date(programDeadline),
        requiredDocs: DocumentsNeed,
        contracts: {
          title: contractname,
          link: contract,
        },
        programPaymentType: programPaymentType,
        participationAmmount:
          participationAmmount === null ? 0 : participationAmmount,
        programStatus: "publish",
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (isClone) {
            toast.success("Program cloned");
          } else {
            toast.success("Program created");
          }

          setEventActiveStep(1);
          navigate(`/dashboard/${user.type}?tab=4`);
        }
      })
      .catch((err) => {
        return toast.error(err.response.data.error);
      });
  };

  const handleSubmitDraft = (e) => {
    e.preventDefault();
    Axios({
      method: "post",
      url: "/api/incubatorProgram/create",
      data: {
        title: programTitle,
        type: Programtype,
        sector: programsector,
        coverImage: coverImage,
        description: programDescription,
        website: programWebsite,
        benefits: programBenefits,
        highlights: programHighlights,
        eligibility: programCriteria,
        startDate: new Date(programStartDate),
        endDate: new Date(programDeadline),
        requiredDocs: DocumentsNeed,
        contracts: {
          title: contractname,
          link: contract,
        },

        programStatus: "draft",
        programPaymentType: programPaymentType,
        participationAmmount:
          participationAmmount === null ? 0 : participationAmmount,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Program saved in draft");
          setEventActiveStep(1);
          navigate(`/dashboard/${user.type}?tab=4&draft=${1}`);
        }
      })
      .catch((err) => {
        return toast.error(err.response.data.error);
      });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (
      programDeadline?.length === 0 ||
      programStartDate?.length === 0 ||
      // programCriteria.length === 0 ||
      DocumentsNeed?.length === 0 ||
      programBenefits?.length === 0 ||
      programDescription?.length === 0 ||
      programTitle?.length === 0 ||
      Programtype?.length === 0 ||
      programHighlights?.length === 0
    ) {
      return toast.warning("Some fields are missing");
    }

    if (coverImage === null) return toast.warning("Upload cover image");
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/update/program/${programid}`,
      data: {
        title: programTitle,
        type: Programtype,
        sector: programsector,
        coverImage: coverImage,
        description: programDescription,
        website: programWebsite,
        benefits: programBenefits,
        highlights: programHighlights,
        eligibility: programCriteria,
        startDate: new Date(programStartDate),
        endDate: new Date(programDeadline),
        requiredDocs: DocumentsNeed,
        contracts: {
          title: contractname,
          link: contract,
        },
        programStatus: "publish",
        programPaymentType: programPaymentType,
        participationAmmount:
          participationAmmount === null ? 0 : participationAmmount,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Program Updated & published");

          navigate(`/service/incprogram/${programid}?tab=0`);
        }
      })
      .catch((err) => {
        return toast.error(err.response.data.error);
      });
  };

  const handleUpdateDraft = async (e) => {
    e.preventDefault();
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/update/draft-program/${programid}`,
      data: {
        title: programTitle,
        type: Programtype,
        sector: programsector,
        coverImage: coverImage,
        description: programDescription,
        website: programWebsite,
        benefits: programBenefits,
        highlights: programHighlights,
        eligibility: programCriteria,
        startDate: new Date(programStartDate),
        endDate: new Date(programDeadline),
        requiredDocs: DocumentsNeed,
        contracts: {
          title: contractname,
          link: contract,
        },
        programStatus: "draft",
        programPaymentType: programPaymentType,
        participationAmmount:
          participationAmmount === null ? 0 : participationAmmount,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Program Updated");
          setEventActiveStep(1);
          navigate(`/dashboard/${user.type}?tab=4&draft=${1}`);
        }
      })
      .catch((err) => {
        return toast.error(err.response.data.error);
      });
  };

  const addInputEvent3 = (e) => {
    setprogramsector(e.map((i) => i.label));
  };

  const addInputEvent4 = (e) => {
    setDocumentsNeed(e.map((i) => i.label));
  };

  return (
    <>
      <div className="incubator_create_program_root">
        <div className="incubator_create_program_main">
          <div className="incubator_create_program_head">
            <h4>Basic Information</h4>
          </div>
          <div className="incubator_create_program_form_root mt-3">
            <form className="incubator_create_program_form">
              <div className="register_page_form row">
                <div className="col-12">
                  <label className="auth_label p_secNaviBlue12 mt-0">
                    Program Name <span className="text-danger">*</span>
                  </label>
                  <input
                    placeholder="Enter the program name"
                    type="text"
                    className="auth_input mt-0"
                    value={programTitle}
                    onChange={(e) => setprogramTitle(e.target.value)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label className="auth_label pb-20">
                    Program Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="w_100 auth_input auth_select p-0 pt-20"
                    name="program_type"
                    value={Programtype}
                    onChange={(e) => setProgramtype(e.target.value)}
                  >
                    <option value="">Select Program type</option>
                    <option value="Corporate Innovation Program">
                      Corporate Innovation Program
                    </option>
                    <option value="General">General</option>
                    <option value="Hackathon">Hackathon</option>
                    <option value="Incubation">Incubation</option>
                    <option value="International Market Access">
                      International Market Access
                    </option>
                    <option value="Market Access">Market Access</option>
                    <option value="Academia">Academia</option>
                  </select>
                </div>
                {Programtype !== "Academia" ? (
                  <div className="col-12 col-md-6 col-12">
                    <label className="auth_label">
                      Program Sector <span className="text-danger">*</span>
                    </label>
                    <Multiselect
                      isMulti
                      options={options}
                      // className="auth_input"
                      displayValue="label"
                      placeholder="Select sectors"
                      name="industries"
                      closeIcon="cancel"
                      value={programsector}
                      onSelect={addInputEvent3}
                      onRemove={addInputEvent3}
                    />
                  </div>
                ) : null}

                <div className="col-12">
                  <label className="auth_label p_secNaviBlue12 mt-3">
                    Cover Image <span className="text-danger">*</span>{" "}
                    <span className="p_LightGrey12">
                      (PNG and JPG files are allowed, below 10MB file size)
                    </span>{" "}
                  </label>
                  <div className="register_page_form_upload justify-content-start">
                    {islogoloading ? (
                      <CircularProgress style={{ color: "black" }} />
                    ) : (
                      <>
                        {coverImage ? (
                          <>
                            <img
                              src={coverImage}
                              alt="logo"
                              className="program_cover_image"
                              style={{ maxHeight: "300px" }}
                            />
                            <p
                              className="text-dark mt-2"
                              onClick={() => setCoverImageUrl(null)}
                              style={{ cursor: "pointer" }}
                            >
                              Delete
                            </p>
                          </>
                        ) : (
                          <div className="program_upload_img">
                            <img
                              src={cloud_upload}
                              alt="cloud_upload"
                              style={{ padding: "20px" }}
                            />
                            <label
                              htmlFor="uplaodImg"
                              style={{ margin: "12px 0px" }}
                              className="point"
                            >
                              Upload Cover image
                            </label>
                            <input
                              type="file"
                              id="uplaodImg"
                              accept="image/*"
                              onChange={(e) => {
                                handleLogoUpload(e);
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <label className="auth_label">
                    Program Description <span className="text-danger">*</span>
                  </label>
                  <textarea
                    name="short_desc"
                    className="auth_input auth_textarea"
                    style={{ height: "100px" }}
                    placeholder="Short Description"
                    value={programDescription}
                    onChange={(e) => setprogramDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="col-12">
                  <label className="auth_label">Program Website</label>
                  <input
                    placeholder="Program Website"
                    type="text"
                    className="auth_input"
                    value={programWebsite}
                    onChange={(e) => setprogramWebsite(e.target.value)}
                  />
                </div>
                <div className="auth_back_loginform col-12 col-md-12">
                  <label htmlFor="payment">
                    Payment <span className="text-danger">*</span>
                  </label>
                  <RadioGroup
                    row
                    aria-label="payment"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="free"
                      control={
                        <Radio
                          sx={{
                            color: "#000000",
                            "&.Mui-checked": {
                              color: "#000000",
                            },
                          }}
                          checked={isFree}
                        />
                      }
                      label="Free"
                      name="payment"
                      style={{ marginTop: "0" }}
                      onChange={() => {
                        setIsFree(true);
                        setProgramPaymentType("free");
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormControlLabel
                      value="paid"
                      control={
                        <Radio
                          sx={{
                            color: "#000000",
                            "&.Mui-checked": {
                              color: "#000000",
                            },
                          }}
                          checked={!isFree}
                        />
                      }
                      label="Paid"
                      name="payment"
                      style={{ marginTop: "0" }}
                      onChange={() => {
                        setIsFree(false);
                        setProgramPaymentType("paid");
                      }}
                    />
                  </RadioGroup>
                </div>

                {!isFree ? (
                  <>
                    <div className="col-12">
                      <label style={{ marginBottom: "10px" }}>
                        Add your account to accept payments for your program
                      </label>
                      {user.is_account === true ? (
                        <>
                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>{" "}
                            Your account already added
                          </p>
                        </>
                      ) : (
                        <>
                          <br />
                          <button
                            className="primaryFilled_activeCta d-flex justify-content-center align-items-center"
                            style={{
                              background: "#262C5B",
                              color: "#FFF",
                              fontSize: ".8rem",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/dashboard/${user.type}?tab=8&ft=1`);
                            }}
                          >
                            Add your Account
                          </button>
                        </>
                      )}
                    </div>
                  </>
                ) : null}

                {!isFree ? (
                  <>
                    <div className="col-12">
                      <label style={{ marginBottom: "10px" }}>
                        Participation Amount{" "}
                        <span className="text-danger">*</span>
                      </label>
                      {/* <input
                        type="number"
                        id="Pricing"
                        placeholder='€1,000'
                        className='body1'
                        value={participationAmmount}
                        onChange={(e) => setParticipationAmmount(e.target.value)}
                        required
                      /> */}
                      <NumberFormat
                        placeholder="€ 1000"
                        displayType={"number"}
                        thousandSeparator={true}
                        prefix={"€ "}
                        className="some auth_input"
                        inputmode="numeric"
                        value={formatedValue}
                        onChange={(e) => handleValuation(e)}
                      />
                    </div>
                  </>
                ) : null}

                <div className="col-12">
                  <label className="auth_label">
                    Program Benefits <span className="text-danger">*</span>
                  </label>
                  <div className="goalsdiv">
                    <input
                      type="text"
                      name="objective"
                      className="auth_input"
                      placeholder="Add benefits"
                      id="objective"
                      value={benefit}
                      onChange={(e) => {
                        setbenefit(e.target.value);
                      }}
                    />
                    <Tooltip title="Add More Rows">
                      <button
                        type="button"
                        onClick={() =>
                          addObjective(
                            programBenefits,
                            setprogramBenefits,
                            benefit,
                            setbenefit
                          )
                        }
                      >
                        <AddIcon />
                      </button>
                    </Tooltip>
                  </div>

                  <div className="goalsscroll">
                    {programBenefits
                      ? programBenefits.map((obj, i) => {
                          return (
                            <>
                              <span
                                className="badge badge-pill badge-dark goalsvalues program_box mr-2 mt-3 pb-1 ps-2"
                                key={i}
                              >
                                <p className="program_text">{obj}</p>
                                <Tooltip title="Delete Row">
                                  <button
                                    type="button"
                                    title="Remove"
                                    onClick={() =>
                                      removeObjective(
                                        programBenefits,
                                        setprogramBenefits,
                                        i
                                      )
                                    }
                                    className="program_btn"
                                  >
                                    x
                                  </button>
                                </Tooltip>
                              </span>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="col-12">
                  <label className="auth_label">
                    Program Highlights <span className="text-danger">*</span>
                  </label>

                  <div className="goalsdiv">
                    <input
                      type="text"
                      name="objective"
                      className="auth_input"
                      placeholder="Add highlights of your program"
                      id="objective"
                      value={highlight}
                      onChange={(e) => sethighlight(e.target.value)}
                    />
                    <Tooltip title="Add More Rows">
                      <button
                        type="button"
                        onClick={() =>
                          addObjective(
                            programHighlights,
                            setprogramHighlights,
                            highlight,
                            sethighlight
                          )
                        }
                      >
                        <AddIcon />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="goalsscroll">
                    {programHighlights
                      ? programHighlights.map((obj, i) => {
                          return (
                            <>
                              <span
                                className="badge badge-pill badge-dark goalsvalues  mr-2 mt-3"
                                key={i}
                              >
                                <p className="program_text">{obj}</p>
                                <Tooltip title="Delete Row">
                                  <button
                                    type="button"
                                    title="Remove"
                                    onClick={() =>
                                      removeObjective(
                                        programHighlights,
                                        setprogramHighlights,
                                        i
                                      )
                                    }
                                    className="program_btn"
                                  >
                                    x
                                  </button>
                                </Tooltip>
                              </span>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                {Programtype !== "Academia" ? (
                  <div className="col-12">
                    <label className="auth_label">Program Eligibility</label>
                    <div className="goalsdiv">
                      <input
                        type="text"
                        name="objective"
                        className="auth_input"
                        placeholder="Eligibility"
                        id="objective"
                        value={eligibility}
                        onChange={(e) => seteligibility(e.target.value)}
                      />
                      <Tooltip title="Add More Rows">
                        <button
                          type="button"
                          onClick={() =>
                            addObjective(
                              programCriteria,
                              setprogramCriteria,
                              eligibility,
                              seteligibility
                            )
                          }
                        >
                          <AddIcon />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="goalsscroll">
                      {programCriteria
                        ? programCriteria.map((obj, i) => {
                            return (
                              <>
                                <span
                                  className="badge badge-pill badge-dark goalsvalues  mr-2 mt-3"
                                  key={i}
                                >
                                  <p className="program_text">{obj}</p>
                                  <Tooltip title="Delete Row">
                                    <button
                                      type="button"
                                      title="Remove"
                                      onClick={() =>
                                        removeObjective(
                                          programCriteria,
                                          setprogramCriteria,
                                          i
                                        )
                                      }
                                      className="program_btn"
                                    >
                                      x
                                    </button>
                                  </Tooltip>
                                </span>
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ) : null}

                <div className="col-12">
                  <label className="auth_label">
                    Documents Required from{" "}
                    {Programtype !== "Academia" ? "startups" : "students"}
                    <span className="text-danger">*</span>
                  </label>
                  <Multiselect
                    isMulti
                    options={Programtype !== "Academia" ? options2 : options3}
                    // className="auth_input"
                    displayValue="label"
                    placeholder="Select documents"
                    name="industries"
                    closeIcon="cancel"
                    value={DocumentsNeed}
                    onSelect={addInputEvent4}
                    onRemove={addInputEvent4}
                  />
                </div>

                <div className="col-12 col-md-6 col-12">
                  <label className="auth_label">
                    Program Start Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="w_100 auth_input"
                    placeholder="Start Date"
                    // value={programStartDate}
                    defaultValue={programStartDate}
                    onChange={(e) => setprogramStartDate(e.target.value)}
                  />
                </div>
                <div className="col-12 col-md-6 col-12">
                  <label className="auth_label">
                    Program End Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="w_100 auth_input"
                    placeholder="Deadline"
                    value={programDeadline}
                    onChange={(e) => setprogramDeadline(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <label className="auth_label">
                    Contract Title (optional)
                  </label>
                  <input
                    placeholder="Contract Title"
                    type="text"
                    className="auth_input"
                    value={contractname}
                    onChange={(e) => setcontractname(e.target.value)}
                  />
                  <label>Contract Document (optional)</label>
                  {contract ? (
                    <div className="col-12 mt-1 p-0">
                      &nbsp;
                      <label
                        className="p_small_black  width_auto d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setcontract(null);
                        }}
                      >
                        <p className="me-3">Contract Document</p>
                        <ClearIcon />
                      </label>
                    </div>
                  ) : (
                    <div className="authIdCard pb-2 row align-items-center">
                      <div className="authIdCardFirst col-12 col-md-10">
                        <label
                          className="mt-2"
                          title="Click here to add verification of the credibility of our users helps us to ensure the quality of platform."
                        >
                          Please attach your contract document
                        </label>
                      </div>
                      {isdocloading ? (
                        <CircularProgress style={{ color: "black" }} />
                      ) : (
                        <div className="authIdCardSecond col-12 col-md-2">
                          <label htmlFor="investor_id">
                            <div
                              className="investor_logo"
                              title="Click here to add verification of the credibility of our users helps us to ensure the quality of platform."
                            >
                              <i
                                style={{ color: "black" }}
                                className="fas fa-paperclip"
                              ></i>
                            </div>
                            <input
                              type="file"
                              accept="application/pdf"
                              name="investor_id"
                              id="investor_id"
                              onChange={handlecontractUpload}
                              title="Click here to add verification of the credibility of our users helps us to ensure the quality of platform."
                            />
                          </label>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {isUpdate == true && isClone == false ? (
                <button
                  type="button"
                  className="gradient_CTA"
                  style={{ padding: "13px 32px" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              ) : isDraft ? (
                <>
                  <button
                    type="button"
                    className="primaryOutlined_activeCta"
                    style={{ padding: "7px 20px", margin: ".5rem" }}
                    onClick={handleUpdateDraft}
                  >
                    Update as draft
                  </button>
                  <button
                    type="button"
                    className="primaryFilled_activeCta"
                    style={{ padding: "10px 20px", margin: ".5rem" }}
                    onClick={handleUpdate}
                  >
                    Publish
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="primaryOutlined_activeCta"
                    style={{ padding: "7px 20px", margin: ".5rem" }}
                    onClick={handleSubmitDraft}
                  >
                    Save as draft
                  </button>
                  <button
                    type="button"
                    className="primaryFilled_activeCta"
                    style={{ padding: "10px 20px", margin: ".5rem" }}
                    onClick={handleSubmit}
                  >
                    {isClone ? "Clone" : "Create"}
                  </button>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default createProgram;
