import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";

// import search from "./../../../../../Assets/images/icons/search.png";
import Card from "./Card";
import "./index.css";

const ViewCourse = ({ id }) => {
  const { setSessionExpire } = useAuth();
  const [services, setservices] = useState([]);

  const fetchservices = async () => {
    await Axios({
      url: `/api/service/services/user/${id._id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setservices(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchservices();
  }, []);
 
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="incubation_program_root">
            <div className="incubation_program_main mt-8">
              <div className="row">
                {services && services.length > 0
                  ? services.map((v, i) => <Card key={i} service={v} />)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCourse;
