import React from 'react'

const Card = ({data}) => {
    return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black px-2">{new Date(data.createdAt).toLocaleDateString('en-GB')}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black text-capitalize">{data.paid_to.name}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black text-capitalize">€ {data.program_id?.participationAmmount}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black text-capitalize">{data.program_id?.title}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black text-capitalize">Success</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
            <p className="caption color_dark_black text-capitalize" style={{cursor:"pointer"}}>Click here</p>
        </td>
    </tr>
}

export default Card