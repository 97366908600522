import React from 'react'
import profile_short from "../../../../Assets/images/incubatorDash/profile_short.png"

const ViewReqCard = () => {
    return (
        <tr className="incubator_assessment_tbody_tr">
            <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_by">
                <img src={profile_short} alt="submitted By" />
                <p className="subtitle color_dark_black">Aman Gupta</p>
            </td>

            <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title pl-40">
                <p className="caption color_dark_black">€ 15,000</p>
            </td>
            <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on pl-40">
                <p className="caption color_dark_black">Completed</p>
            </td>
            
            <td className="incubator_assessment_tbody_td incubator_assessment_tbody_view">
                <button className='incubator_assessment_viewBtn caption color_dark_grey'>View</button>
                {/* <p className="caption color_dark_grey" onClick={()=>{
                setAssessmentState(2);
                navigate(`/service/incprogram/${programid}?tab=6&course=${assessment.course_id._id}&assessment=${assessment.assessment_id._id}&user=${assessment.user_id._id}`)
            }}>View <ArrowForwardOutlined /></p> */}
            </td>
        </tr>
    )
}

export default ViewReqCard