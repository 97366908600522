import React, { useState } from 'react'
// import Card from './Card'
import { ArrowBack } from "@mui/icons-material"
import './letstart.css';
import { useAuth } from "../../../Utils/Auth";
import { useNavigate } from "react-router-dom"
import Axios from "axios"
import { toast } from 'react-toastify';
// import Valuation1 from "../../../Assets/Images/NewDash/Valuation1.png"
// import Valuation2 from "../../../Assets/Images/NewDash/Valuation2.png"
// import Valuation3 from "../../../Assets/Images/NewDash/Valuation3.png"

const LetStart = ({ setValuationStep }) => {
  const { user } = useAuth();
  const navigate = useNavigate()
  const [title, setTitle] = useState("");

  const createReport = async () => {
    if (title === "")
      return toast.error("Report title cannot be empty")
    await Axios({
      url: `/api/valuation/create`,
      method: "post",
      data: {
        reportTitle: title
      }
    }).then((res) => {
      if (res.data) {
        setValuationStep(3)
        localStorage.setItem('valuation_id', res.data.valuation_id)
        localStorage.setItem('valuation_title', title)
        localStorage.setItem('valuation_cretedDate', new Date())
        navigate(`/dashboard/${user.type}?tab=4&valuationTab=0&valuate=3`)

      }

    }).catch(() => toast.error("try again"))
  }
  return (
    <div className="newValuation-main">
      <div className="letstart-backBtnRoot">
        <div className="letstart-backBtnMain">
          <button onClick={() => {
            setValuationStep(1)
            navigate(`/dashboard/${user.type}?tab=4&valuate=1`)
          }} ><ArrowBack /></button>
          {/* <p className='letstartp'>Watch Tutorial</p> */}
        </div>
        <h6 className='p_spartan_20'>Report generation</h6>
        <p className=''>A clear and detailed company valuation report is the best way to communicate your value to potential investors, buyers, stakeholders or employees.</p>

      </div>
      <div className="letstart-cards-root mt-4">
        {/* <div className="row">
          <Card 
            img = {""}
            type= "Questionnaire"
            typeContent = "The qualitative aspects of a company are valuable as guarantee of future success, especially in early stage companies"
          />
          <Card 
            img = {""}
            type= "Financial Information"
            typeContent = "Financial projections are valuable to take into account the future cash flows of a company, and to compute investors’ returns"
          />
          <Card 
            img = {""}
            type= "Funding rounds"
            typeContent = "Here you can give more information and context to investors on your current funding round."
          />
        </div> */}
        <div className="report_title-main">
          <label htmlFor="reportTitle" className='p_spartan_20'>Title of your Report</label>
          <input type="text" id='reportTitle' value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
      </div>
      <div className="mt-2">
        <button className='gradient_CTA' onClick={()=>createReport()}>Let Start</button>
      </div>
    </div>
  )
}

export default LetStart