import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom"
import { useAuth } from "../../../../Utils/Auth";
import Axios from 'axios';
import QuestionsAnswers from './QuestionsAnswers';
// import { toast } from 'react-toastify';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AssessmentResult = () => {
    const query = useQuery();
    const assessment_id = query.get("assessment");
    const { setSessionExpire } = useAuth()
    const { courseid } = useParams()
    const [result, setResult] = useState(null);
    const [assessment, setAssessment] = useState([]);
    const [assessmentMain, setAssessmentMain] = useState(null);
    const [Answers, setAnswers] = useState([]);

    const getAssessment = async () => {
        await Axios({
            url: `/api/mentorModules/assessment/${courseid}/${assessment_id}`,
            method: "get",
        }).then((res) => {
            setAssessment(res.data.data.assessment);
            setAssessmentMain(res.data.data);
            // setModules(res.data.mod);
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    const getQuestionsAns = async () => {
        await Axios({
            url: `/api/mentorModules/result/${assessment_id}`,
            method: "get",
        }).then((res) => {
            // console.log(res);
            setAnswers(res.data.data.results);
            // setAssessmentMain(res.data.data);
            // setModules(res.data.mod);
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }
    const getResult = async () => {
        await Axios({
            url: `/api/mentorModules/result/${courseid}/${assessment_id}`,
            method: "get",
        }).then((res) => {
            setResult(res.data.data.result);
            // setAssessmentMain(res.data.data);
            // setModules(res.data.mod);
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    useEffect(() => {
        getResult();
        getQuestionsAns()
        getAssessment()
    }, [])

    return <div className="userCourses_tabPanel_content_main">
        <div className="row">
            <div className="col-lg-9 col-md-8 col-12">
                <div className="userCourses_tabPanel_assessment_main w_100">
                    <div className="userCourses_tabPanel_assessment_head mb-4">
                        <p className="title">{assessmentMain !== null ? assessmentMain.name : ""}</p>
                    </div>
                    <div className="userCourses_tabPanel_assess_ques_main">
                        {
                            assessment && assessment.length > 0 ?
                                assessment.map((assess, i) => <QuestionsAnswers
                                    key={i}
                                    assess={assess}
                                    i={i}
                                    Answers={Answers}
                                />) : null
                        }
                        {/* <div className="w_100 d-flex justify-content-center align-items-center">
                            <button className='primaryFilled_activeCta' onClick={submitAssessment} >Submit</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {
                result && result !== null ?
                    <div className="col-lg-3 col-md-4 col-12">
                        <div className="userCourses_tabPanel_assesmentResultRoot">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="userCourses_tabPanel_assesmentCorrectmain">
                                        <p className="subtitle">Correct</p>
                                        <h4 style={{ color: "#FF6F1E" }}>{result.correct}/{result.total}</h4>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <div className="userCourses_tabPanel_assesmentCorrectmain">
                                        <p className="subtitle">Incorrect</p>
                                        <h4 style={{ color: "#580167" }}>{result.wrong}/{result.total}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null

            }
        </div>
    </div>
}

export default AssessmentResult