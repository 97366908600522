import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import UserImg from "../../../../../Assets/images/user.png";
// import { useAuth } from '../../../../../Utils/Auth';
const Card = ({ data }) => {
  // const { user } = useAuth()

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      return toast("Payment failed to load, try again later");
    }
    await Axios({
      url: `/api/coworingPayment/razorpay/${data._id}`,
      method: "post",
    })
      .then((res) => {
        const { data, item } = res.data;
        var options = {
          key: "rzp_test_0ajbWdUbbV6vTl",
          amount: data.amount,
          currency: data.currency,
          name: "Zefyron Incubation",
          description: "Assets",
          image:
            "https://twf-attachment.s3.ap-south-1.amazonaws.com/user-registration-uploads/620fc4214bbfd96516f22edb/event-cover/AMbQG_5ZKVOf5q8SG_uJN.png",
          order_id: data.id,
          callback_url: `/api/coworingPayment/check-success/${data.id}/${item.created_by}`,
          prefill: {
            name: item.client_name,
            email: item.email_id,
            contact: item.contact_no,
          },
          notes: {
            name: item.client_name,
            email: item.email_id,
            contact: item.contact_no,
            cart_id: item.cart_id,
            created_by: item.created_by,
            for_user: item.for_user,
            tools: item.tools,
            item_details: item.item_details,
            item_local_invoice_id: item._id,
          },
          theme: {
            color: "#ff6f1e",
          },
        };
        const PaymentObject = new window.Razorpay(options);
        PaymentObject.open();
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
        script.onerror = () => {
          resolve(false);
        };
      };
      document.body.appendChild(script);
    });
  }

  return (
    <tr className="incubator_assessment_tbody_tr">
      <td className="incubator_assessment_tbody_td finance_received_from_td">
        <img
          src={data.created_by.avatar ? data.created_by.avatar : UserImg}
          alt="twf finance"
        />
        <p className="subtitle color_dark_black">
          {data.created_by.name
            ? data.created_by.name
            : `${data.created_by.firstname} ${data.created_by.lastname}`}
        </p>
      </td>
      <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
        <p className="caption color_dark_black">{data.tools}</p>
      </td>
      <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
        <p className="caption color_dark_black">
          {new Date(data.date).toLocaleDateString("en-GB")}
        </p>
      </td>
      <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
        <p className="caption color_dark_black">
          {data.is_paid ? "Paid" : "Unpaid"}
        </p>
      </td>
      <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
        <p className="caption color_dark_black">Euro {data.total_amount}</p>
      </td>
      {data.is_paid ? null : (
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
          <button
            onClick={displayRazorpay}
            className="gradient_CTA"
            style={{ margin: 0 }}
          >
            Pay
          </button>
        </td>
      )}
    </tr>
  );
};

export default Card;
