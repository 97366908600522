import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import logo from "../../Assets/images/logo/TWF_logo_white_400H.svg";
import { useAuth } from "../../Utils/Auth";
import { Link,useLocation } from "react-router-dom";
import UserProfile from '../../Components/UserProfile/UserProfile';
const Navbar = () => {
  const { isUser, signOut,user } = useAuth();
  const navigate = useNavigate();
  let location = useLocation();
  const [loc,setlocation] = useState(location.pathname);

  useEffect(() => {
    setlocation(location.pathname);
  }, [location]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light container">
      {/* <a className="navbar-brand" href="#">Navbar</a> */}
      <div className="nav_logo navbar-brand">
        <figure>
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={logo}
            alt="Logo"
          />
        </figure>
      </div>
      <button
        className="navbar-toggler p-0"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
       <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#fff" className="bi bi-list" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg>
        
      </button>

      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className="nav-li d-flex align-items-center justify-content-center navli" style={{marginRight: "40px"}}>
            <Link to="/">HOME</Link>
          </li>
          <li className="nav-li d-flex align-items-center justify-content-center navli" style={{marginRight: "40px"}}>
          <div className="dropdown" style={{textAlign: "center"}}>
            <button className="dropdown-toggle nav_btn bold" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              MEMBERS
            </button>
            <div className="dropdown-menu" style={{textAlign: "center"}} aria-labelledby="dropdownMenuButton">
            <p style={{background: loc==="/member/startup"?"#e9ecef":""}} onClick={() => navigate("/member/startup")} className="dropdown-item text-dark navdrop_box">Startup</p>
            <p style={{background: loc==="/member/investor"?"#e9ecef":""}} onClick={() => navigate("/member/investor")} className="dropdown-item text-dark navdrop_box">Investor</p>
            <p style={{background: loc==="/member/mentor"?"#e9ecef":""}} onClick={() => navigate("/member/mentor")} className="dropdown-item text-dark navdrop_box">Mentor</p>
            <p style={{background: loc==="/member/incubator"?"#e9ecef":""}} onClick={() => navigate("/member/incubator")} className="dropdown-item text-dark navdrop_box">Incubator</p>
            <p style={{background: loc==="/member/corporates"?"#e9ecef":""}} onClick={() => navigate("/member/corporates")} className="dropdown-item text-dark navdrop_box">Corporates</p>
            <p style={{background: loc==="/member/serviceprovider"?"#e9ecef":""}} onClick={() => navigate("/member/serviceprovider")} className="dropdown-item text-dark navdrop_box">Service Provider</p>
            </div>
          </div>
            {/* <Link to="/"></Link> */}
          </li>
          {/* <li className="nav-li mr-5 d-flex align-items-center justify-content-center navli">
               <Link to="/">SERVICES</Link>
             </li> */}
          <li className="nav-li d-flex align-items-center justify-content-center navli" style={{marginRight: "40px"}}>
            <Link to="/pricing">PRICING</Link>
          </li>
          {isUser ? (
            <>
              {/* <li className="nav-li d-flex align-items-center justify-content-center navli" style={{marginRight: "40px"}}>
                <Link to={`/dashboard/${user.type}`}>
                  DASHBOARD
                </Link>
              </li>
              <li className="nav-li d-flex align-items-center justify-content-center navli">
                <Link onClick={() => signOut()} to="/login">
                  LOG OUT
                </Link>
              </li>  */}
              <UserProfile user={user} signOut={signOut} navType="home"/>
            </>
          ) : (
            <>
              <li className="nav-li d-flex align-items-center justify-content-center navli" style={{marginRight: "40px"}}>
                <Link to="/login">LOG IN</Link>
              </li>
              <li className="nav-li d-flex align-items-center justify-content-center navli nav-signup gradient_CTA mt-0">
                <Link className="" to="/signup" style={{ color: "#000" }}>
                  SIGN UP FOR FREE
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
