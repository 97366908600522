import React from "react";
import "./Components/Css/common.css";
import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
// pages
// import Home from "./Pages/Home/index";
// import Footer from "./Layout/Footer/Footer";
import Signup from "./Pages/SignUp/Signup";
import Login from "./Pages/Login/Login";
// import Option2 from "./Pages/Role";
import Welcome from "./Pages/Authentication/Welcome";
import VerifyEmail from "./Pages/Authentication/verifyEmail";
import Register_Startup from "./Pages/Registration/Register_Startup";
import Edit_Startup from "./Pages/EditDetails/EditStartup";
import Edit_Incubator from "./Pages/EditDetails/EditIncubator";
import Edit_Investor from "./Pages/EditDetails/EditInvestor";
import Edit_mentor from "./Pages/EditDetails/Editmentor";
import Edit_Corporate from "./Pages/EditDetails/EditCorporate";
import Edit_Sp from "./Pages/EditDetails/EditSp";
import Edit_Student from "./Pages/EditDetails/EditStudent/EditStudent";

import Register_incubator from "./Pages/Registration/Register_incubator";
import Register_mentor from "./Pages/Registration/Register_mentor";
import Register_investor from "./Pages/Registration/Register_investor";
import Register_ServicePro from "./Pages/Registration/Register_SP";
import Register_corporate from "./Pages/Registration/Register_corporate";
import ThanksPage from "./Pages/ThanksPage";
import GetinTouch from "./Pages/Authentication/GetinTouch";
import UserCheck from "./Utils/UserCheck";
import { ProvideAuth } from "./Utils/Auth";
import NotFoundPage from "./Pages/404Pages";
import ForgetPassword from "./Pages/Authentication/ForgetPassword/ForgetPassword";
import CheckEmail from "./Pages/Authentication/ForgetPassword/CheckEmail";
import ResetPassword from "./Pages/Authentication/ForgetPassword/ResetPassword";
// import Pricing from "./Pages/Pricing";
// import Billing from "./Pages/Pricing/Billing";

// import Privacy from "./Pages/TNCpages/privacy";
// import Contact from "./Pages/Contact";
import EventsForm from "./Services/Events/host";
import Event from "./Services/Events/event";
import Events from "./Services/Events/allevents";
import StartupDashboard from "./Dashboard/Startup";
import IncubatorDashboard from "./Dashboard/Incubator";
import UserIncubationProgramDashboard from "./Services/UserIncubationPrograms";
import WatchCourseDash from "./Services/UserIncubationPrograms/LearningMaterial/WatchCourse/WatchCourseDash";
import MentorDashboard from "./Dashboard/Mentor";
import CorporateDashboard from "./Dashboard/Corporate";
import InvestorDashboard from "./Dashboard/Investor";
import StudentDashboard from "./Dashboard/Student";
import IndividualDashboard from "./Dashboard/Individual";
import PreStartDash from './Dashboard/PreStartup'
import Service_Program from "./Services/IncubatorProgram/ProgramDashboard/Incubator";

// import MemberStartup from "./Pages/Member/Startup";
// import Member_investor from "./Pages/Member/Investor";
// import Member_mentor from "./Pages/Member/Mentor";
// import Member_incubator from "./Pages/Member/Incubator";
// import Member_corporates from "./Pages/Member/Corporates";
// import Member_serviceprovider from "./Pages/Member/Serviceprovider";
import Jitsi from "./Services/Events/jitsi";
import JitsiProgram from "./Services/IncubatorProgram/ProgramDashboard/Meet/jitsi";
// import Faqs from "./Pages/Faqs";
// import Tnc from "./Pages/TNCpages/tnc";
import { ToastContainer } from "react-toastify";
import MentorIncubationProgramDashboard from "./Services/MentorIncubationPrograms/Mentor/index";
import InvestorIncubationProgram from "./Services/InvestorIncubationPrograms/Investor/index";
import CorporateIncubationProgram from "./Services/CorporateIncubationPrograms/Corporate/index";
import SPIncubationProgram from "./Services/SPIncubationPrograms/Investor/index";

import { useMediaQuery } from "react-responsive";
import cookie from "./Assets/images/cookie.svg";
// import AboutUs from "./Pages/AboutUs";
import ServiceproviderDashboard from "./Dashboard/ServiceProvider";
import Register_student from "./Pages/Registration/Register_student/Register_student";
import Register_preStartups from "./Pages/Registration/Register_preStartups";
import Edit_PreStart from "./Pages/EditDetails/EditPrestartup/EditPrestartup";
import Register_individual from "./Pages/Registration/Register_Individual/Register_individual";
import EmployeeDashboard from "./Dashboard/Employee";
import Register_Employee from "./Pages/Registration/Register_employee/Register_Employee";
import SharedProgramView from './Services/IncubatorProgram/SharedProgram';
import ShareEvents from "./Services/Events/shareEvents/ShareEvents";

const App = () => {
  const isTab = useMediaQuery({ query: "(max-width:825px)" });
  return (
    <>
      <div className="App">
        <CookieConsent
          buttonText="ACCEPT COOKIES"
          style={{
            background: "#FFFFFF",
            color: "#000",
            fontFamily: "Inter",
            margin: "20px 0",
            width: isTab ? "90%" : "50%",
          }}
          buttonStyle={{
            background:
              "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)",
            fontFamily: "Inter",
            color: "#000000",
            borderRadius: "35px",
            padding: "16px 24px",
            fontWeight: "600",
          }}
          expires={150}
        >
          <img src={cookie} alt="cookie" className="cookie_img" />
          &nbsp; This website uses cookies to enhance the user experience.
        </CookieConsent>
        <ToastContainer theme="dark" hideProgressBar={true} />
        <Router>
          <ProvideAuth>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <>
                     <Login />
                  </>
                }
              />
              <Route
                exact
                path="/signup"
                element={
                  <>
                    <Signup />
                  </>
                }
              />
              <Route
                exact
                path="/login"
                element={
                  <>
                    <Login />
                  </>
                }
              />
              {/* <Route
                exact
                path="/role"
                element={
                  <>
                    <Option2 />
                  </>
                }
              /> */}
              <Route
                exact
                path="/verify-email/:role"
                element={
                  <>
                    <VerifyEmail />
                  </>
                }
              />
              <Route
                exact
                path="/user-check/:role"
                element={
                  <>
                    <UserCheck />
                  </>
                }
              />
              <Route
                exact
                path="/welcome"
                element={
                  <>
                    <Welcome />
                  </>
                }
              />
              <Route
                exact
                path="/register/startup"
                element={
                  <>
                    <Register_Startup />
                  </>
                }
              />
              <Route
                exact
                path="/edit/startup"
                element={
                  <>
                    <Edit_Startup />
                  </>
                }
              />
              <Route
                exact
                path="/edit/incubator"
                element={
                  <>
                    <Edit_Incubator />
                  </>
                }
              />
              <Route
                exact
                path="/edit/investor"
                element={
                  <>
                    <Edit_Investor />
                  </>
                }
              />
              <Route
                exact
                path="/edit/mentor"
                element={
                  <>
                    <Edit_mentor />
                  </>
                }
              />
              <Route
                exact
                path="/edit/corporate"
                element={
                  <>
                    <Edit_Corporate />
                  </>
                }
              />
              <Route
                exact
                path="/edit/serviceprovider"
                element={
                  <>
                    <Edit_Sp />
                  </>
                }
              />
              <Route
                exact
                path="/edit/student"
                element={
                  <>
                    <Edit_Student />
                  </>
                }
              />
              <Route
                exact
                path="/edit/prestartup"
                element={
                  <>
                    <Edit_PreStart />
                  </>
                }
              />
              <Route
                exact
                path="/register/incubator"
                element={
                  <>
                    <Register_incubator />
                  </>
                }
              />
              <Route
                exact
                path="/register/mentor"
                element={
                  <>
                    <Register_mentor />
                  </>
                }
              />
              <Route
                exact
                path="/register/investor"
                element={
                  <>
                    <Register_investor />
                  </>
                }
              />
              <Route
                exact
                path="/register/serviceprovider"
                element={
                  <>
                    <Register_ServicePro />
                  </>
                }
              />
              <Route
                exact
                path="/register/corporate"
                element={
                  <>
                    <Register_corporate />
                  </>
                }
              />
              <Route
                exact
                path="/register/student"
                element={
                  <>
                    <Register_student />
                  </>
                }
              />
              <Route
                exact
                path="/register/individual"
                element={
                  <>
                    <Register_individual />
                  </>
                }
              />
              <Route
                exact
                path="/register/prestartup"
                element={
                  <>
                    <Register_preStartups />
                  </>
                }
              />
              <Route
                exact
                path="/register/employee"
                element={
                  <>
                    <Register_Employee />
                  </>
                }
              />
              <Route
                exact
                path="/get-in-touch/corporate"
                element={
                  <>
                    <GetinTouch />
                  </>
                }
              />
              <Route
                exact
                path="/thankyou"
                element={
                  <>
                    <ThanksPage />
                  </>
                }
              />
              <Route
                exact
                path="/forget-password"
                element={
                  <>
                    <ForgetPassword />
                  </>
                }
              />
              <Route
                exact
                path="/check-email"
                element={
                  <>
                    <CheckEmail />
                  </>
                }
              />
              <Route
                exact
                path="/reset-password"
                element={
                  <>
                    <ResetPassword />
                  </>
                }
              />
              {/* <Route
                exact
                path="/member/startup"
                element={
                  <>
                    <MemberStartup />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/member/investor"
                element={
                  <>
                    <Member_investor />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/member/mentor"
                element={
                  <>
                    <Member_mentor />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/member/incubator"
                element={
                  <>
                    <Member_incubator />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/member/corporates"
                element={
                  <>
                    <Member_corporates />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/member/serviceprovider"
                element={
                  <>
                    <Member_serviceprovider />
                    <Footer />
                  </>
                }
              /> */}
              {/* <Route
                exact
                path="/pricing"
                element={
                  <>
                    <Pricing />
                    <Footer />
                  </>
                }
              /> */}
              {/* <Route
                exact
                path="/billing/:plan"
                element={
                  <>
                    <Billing />
                  </>
                }
              /> */}
              {/* events Explore page */}
              <Route
                exact
                path="/service/events"
                element={
                  <>
                    <Events />
                  </>
                }
              />
              {/* host event form */}
              <Route
                exact
                path="/service/events-form"
                element={
                  <>
                    <EventsForm />
                  </>
                }
              />
              {/* event profile page */}
              <Route
                exact
                path="/service/events/:id_event"
                element={
                  <>
                    <Event />
                  </>
                }
              />
              <Route
                exact
                path="/jitsi-event/:key"
                element={
                  <>
                    <Jitsi />
                  </>
                }
              />
              {/* <Route
                exact
                path="/privacy-policy"
                element={
                  <>
                    <Privacy />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/faqs"
                element={
                  <>
                    <Faqs />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/about"
                element={
                  <>
                    <AboutUs />
                    <Footer />
                  </>
                }
              />
              <Route
                exact
                path="/contact"
                element={
                  <>
                    <Contact />
                    <Footer />
                  </>
                }
              /> */}
              <Route
                exact
                path="/service/incprogram/:programid"
                element={
                  <>
                    <Service_Program />
                  </>
                }
              />             
              <Route
                exact
                path="*"
                element={
                  <>
                    <NotFoundPage />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/startup"
                element={
                  <>
                    <StartupDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/incubator"
                element={
                  <>
                    <IncubatorDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/student"
                element={
                  <>
                    <StudentDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/employee"
                element={
                  <>
                    <EmployeeDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/individual"
                element={
                  <>
                    <IndividualDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/prestartup"
                element={
                  <>
                    <PreStartDash />
                  </>
                }
              />
              <Route
                exact
                path="/service/userprogram/:programid"
                element={
                  <>
                    <UserIncubationProgramDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/service/userCourses/:courseid/:programid"
                element={
                  <>
                    <WatchCourseDash />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/mentor"
                element={
                  <>
                    <MentorDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/corporate"
                element={
                  <>
                    <CorporateDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/investor"
                element={
                  <>
                    <InvestorDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/dashboard/serviceprovider"
                element={
                  <>
                    <ServiceproviderDashboard />
                  </>
                }
              />
              {/* <Route
                exact
                path="/terms-and-conditions"
                element={
                  <>
                    <Tnc />
                    <Footer />
                  </>
                }
              /> */}
              <Route
                exact
                path="/jitsi-meet/:key"
                element={
                  <>
                    <JitsiProgram />
                  </>
                }
              />
              <Route
                exact
                path="/service/mentorprogram/:programid"
                element={
                  <>
                    <MentorIncubationProgramDashboard />
                  </>
                }
              />
              <Route
                exact
                path="/service/investorprogram/:programid"
                element={
                  <>
                    <InvestorIncubationProgram />
                  </>
                }
              />
              <Route
                exact
                path="/service/serviceproviderprogram/:programid"
                element={
                  <>
                    <SPIncubationProgram />
                  </>
                }
              />
              <Route
                exact
                path="/service/corporateprogram/:programid"
                element={
                  <>
                    <CorporateIncubationProgram />
                  </>
                }
              />
              <Route
                exact
                path="/twf-program/:programid/:program_name"
                element={
                  <>
                    <SharedProgramView/>
                  </>
                }
              />
              <Route
                exact
                path="/twf-event/:event_id/:event_key/:event_name"
                element={
                  <>
                    <ShareEvents/>
                  </>
                }
              />
            </Routes>
          </ProvideAuth>
        </Router>
      </div>
    </>
  );
};

export default App;
