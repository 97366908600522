import React, { useEffect, useState } from "react";
import "./index.css";
import Axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

// steps
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
// import Step3 from "./Steps/Step3";
import { useAuth } from "../../../Utils/Auth";
import ReactGa from "react-ga";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const index = ({ seteventState }) => {
  useEffect(() => {
    ReactGa.initialize("UA-214453456-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);
  let query = useQuery();

  const active = Number(query.get("active"));

  useEffect(() => {
    if (active === 0 || active === 1) {
      setActiveStep(active);
    }
  }, [active]);

  const navigate = useNavigate();
  const { profile, user } = useAuth();
  // step1
  const [activeStep, setActiveStep] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [objectiveVal, setObjectiveVal] = useState("");
  const [objectives, setObjectives] = useState([]);
  const [criteriaVal, setCriteriaVal] = useState("");
  const [criteria, setCriteria] = useState([]);
  const [coverImg, setCoverImg] = useState(null);

  // step2
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timezone, setTimezone] = useState("");
  const [duration, setDuration] = useState("1 01:00 hr");
  const [isFree, setIsFree] = useState(false);
  const [isPublicEvent, setIsPublicEvent] = useState(null);
  const [isPrivateEvent, setIsPrivateEvent] = useState(null);
  const [eventFees, setEventFees] = useState(0);
  const [hostAccountType, setHostAccountType] = useState("");
  const [keywordTag, setKeywordTag] = useState("");
  const [keywords, setKeyword] = useState([]);
  const [documentUrl, setDocumentUrl] = useState({
    docName: "",
    docUrl: "",
  });
  const [documentFile, setDocumentFile] = useState("");
  const [documentName, setDocumentName] = useState("");
  // step3
  // const [as, setAs] = useState("As Name");
  // const [hostName, setHostName] = useState("");
  // const [hostEmail, setHostEmail] = useState("");
  // const [hostContactNumber, setHostContactNumber] = useState("");
  // const [hostWebsite, setHostWebsite] = useState("");

  // const steps = getSteps();
  const [eventDetails, seteventDetails] = useState(null);
  // save to localstorage

  useEffect(() => {
    if (!eventDetails) {
      const event = JSON.parse(localStorage.getItem("event"));
      if (event) {
        seteventDetails(event);
      }
      if (event) {
        if (event.title) setTitle(event.title);
        if (event.description) setDescription(event.description);
        if (event.coverImg) setCoverImg(event.coverImg);
        if (event.objectives) setObjectives(event.objectives);

        if (event.criteria) setCriteria(event.criteria);

        // if(objectives.length===0 && ! {

        //   setObjectives())
        // }
        // if(criteria.length===0 && !))
        if (event.date) setDate(event.date);
        if (event.timezone) setTimezone(event.timezone);
        if (event.time) setTime(event.time);
        if (event.duration) setDuration(event.duration);

        if (event.isPublicEvent) setIsPublicEvent(event.isPublicEvent);
        if (event.isPrivateEvent) setIsPrivateEvent(event.isPrivateEvent);
        if (event.isFree) setIsFree(event.isFree);
        if (event.eventFees) setEventFees(event.eventFees);
        if (event.hostAccountType) setHostAccountType(event.hostAccountType);
        if (event.documentUrl) setDocumentUrl(event.documentUrl);
        if (event.documentName) setDocumentName(event.documentName);
        // if (event.as) setAs(event.as);
        // if (event.hostName) setHostName(event.hostName);
        // if (event.hostEmail) setHostEmail(event.hostEmail);
        // if (event.hostContactNumber)
        //   setHostContactNumber(event.hostContactNumber);
        // if (event.hostWebsite) setHostWebsite(event.hostWebsite);
        if (event.keyword) setKeyword(event.keyword);
        if (event.isFree) setIsFree(event.isFree);
        if (event.pType) setIsFree();
        if (event.documentFile) setDocumentFile(event.documentFile);
      }
    }
    localStorage.setItem("event", JSON.stringify(eventDetails));
  }, [eventDetails]);

  useEffect(() => {});

  // const handleNext = () => {
  //   if (activeStep < 1) {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   }
  // };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // add objectives
  const addObjective = () => {
    if (objectiveVal) {
      setObjectives((prev) => [...prev, objectiveVal]);
      setObjectiveVal("");
    }
  };
  const removeObjective = (id) => {
    const newArr = objectives.filter((obj, i) => {
      return id !== i;
    });
    setObjectives(newArr);
  };

  // add criteria
  const addCriteria = () => {
    if (criteriaVal) {
      setCriteria((prev) => [...prev, criteriaVal]);
      setCriteriaVal("");
    }
  };
  const removeCriteria = (id) => {
    const newArr = criteria.filter((obj, i) => {
      return id !== i;
    });
    setCriteria(newArr);
  };

  // keywords
  const addKeyword = () => {
    if (keywordTag) {
      setKeyword((prev) => [...prev, keywordTag]);
      setKeywordTag("");
    }
  };
  const removeKeyword = (id) => {
    const newArr = keywords.filter((obj, i) => {
      return id !== i;
    });
    setKeyword(newArr);
  };

  // handle cover image
  const handleCoverImg = (e) => {
    const image = e.target.files[0];
    if (image.size > 10 * 1048 * 1024) {
      toast.error("File size exceeded 10MB!");
      return;
    }
    const formdata = new FormData();
    formdata.append("event-cover", image);
    Axios({
      method: "post",
      url: "/api/events/upload-cover",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setCoverImg(data.location);
        toast.success("Successfully image uploaded", {
          position: "top-right",
        });
      })
      .catch(() =>
        toast.error("Error in upload", {
          position: "top-right",
        })
      );
  };

  // handle Document
  const handleDocUpload = async (e) => {
    const image = e.target.files[0];
    if (image.size > 10 * 1048 * 1024) {
      toast.error("File size exceeded 10MB!");
      return;
    }

    const formdata = new FormData();
    formdata.append("event-document", image);
    await Axios({
      method: "post",
      url: "/api/events/upload-document",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setDocumentFile(data.location);
        setDocumentName(image.name);
        seteventDetails({
          ...eventDetails,
          documentFile: data.location,
          documentName,
        });
        toast.success("Successfully document uploaded", {
          position: "top-right",
        });
      })
      .catch(() =>
        toast.error("Error in upload", {
          position: "top-right",
        })
      );
  };

  const [disable, setdisable] = useState(false);

  // handleSubmit
  const handleSubmit = async (e) => {
    setdisable(true);
    e.preventDefault();
    await Axios({
      method: "post",
      url: "/api/events/host-register",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        title: title,
        documentUrl: documentFile ? documentFile : documentUrl.docUrl,
        documentName: documentName,
        thumbnail: coverImg,
        description: description,
        objectives: objectives,
        criteria: criteria,
        eventTime: {
          isoDate: new Date(date),
          date: date,
          timezone: timezone,
          time: time,
          duration: duration,
        },
        isPublicEvent: isPublicEvent,
        isPrivateEvent: isPrivateEvent,
        isFree: eventDetails.isFree,
        eventFees: isFree ? 0 : eventFees,
        // paymentAccountType: isFree ? "free" : hostAccountType,
        hostStripeAccount: isFree ? "free" : "StripeAccountId",

        keywords: keywords,
        // as: as,
        // hostName: hostName,
        // hostEmail: hostEmail,
        // hostContactNumber: as === "As Company" ? "000" : hostContactNumber,
        // hostWebsite: as === "As Company"? hostWebsite: "",
      },
    })
      .then((res) => {
        seteventState(1);
        setdisable(false);
        toast.success("Event hosted", {
          position: "bottom-right",
        });
        navigate(`/dashboard/${user.type}?tab=6&event=${res.data.key}`);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Could not host, try later", {
          position: "top-right",
        });
        setdisable(false);
      });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step1
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            objectiveVal={objectiveVal}
            setObjectiveVal={setObjectiveVal}
            addObjective={addObjective}
            removeObjective={removeObjective}
            objectives={objectives}
            criteriaVal={criteriaVal}
            setCriteriaVal={setCriteriaVal}
            criteria={criteria}
            addCriteria={addCriteria}
            removeCriteria={removeCriteria}
            handleCoverImg={handleCoverImg}
            coverImg={coverImg}
            setCoverImg={setCoverImg}
            eventDetails={eventDetails}
            seteventDetails={seteventDetails}
          />
        );
      case 1:
        return (
          <Step2
            user={user}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleBack={handleBack}
            date={date}
            setDate={setDate}
            time={time}
            setTime={setTime}
            timezone={timezone}
            setTimezone={setTimezone}
            duration={duration}
            setDuration={setDuration}
            isFree={isFree}
            setIsFree={setIsFree}
            isPublicEvent={isPublicEvent}
            setIsPublicEvent={setIsPublicEvent}
            isPrivateEvent={isPrivateEvent}
            setIsPrivateEvent={setIsPrivateEvent}
            eventFees={eventFees}
            setEventFees={setEventFees}
            hostAccountType={hostAccountType}
            setHostAccountType={setHostAccountType}
            keywordTag={keywordTag}
            setKeywordTag={setKeywordTag}
            keyword={keywords}
            setKeyword={setKeyword}
            addKeyword={addKeyword}
            removeKeyword={removeKeyword}
            documentUrl={documentUrl}
            setDocumentUrl={setDocumentUrl}
            documentFile={documentFile}
            setDocumentFile={setDocumentFile}
            handleDocUpload={handleDocUpload}
            documentName={documentName}
            eventDetails={eventDetails}
            seteventDetails={seteventDetails}
            handleSubmit={handleSubmit}
            disable={disable}
          />
        );

      default:
        return "Unknown step";
    }
  }
  if (profile)
    return (
      <>
        <div className="incubator_create_program_root">
          <div className="incubator_create_program_main">
            <div className="incubator_create_program_head">
              <h4>Basic Information</h4>
            </div>
            <div className="incubator_create_program_form_root mt-3">
              {getStepContent(0)}
            </div>
            <div className="incubator_create_program_form_root mt-5">
              {getStepContent(1)}
            </div>
          </div>
        </div>
        {/*         
        <div className="container mt-5 mb-5">
          {getStepContent(1)}
        </div> */}
      </>
    );

  return (
    <div
      style={{
        height: "100vh",
        position: "relative",
      }}
    >
      <div
        className="text-center"
        style={{
          margin: "30vh auto",
        }}
      >
        <h5 className="h2_white_40">SESSION EXPIRED</h5>
        <button className="gradient_CTA" onClick={() => navigate(`/login`)}>
          LOGIN AGAIN
        </button>
      </div>
    </div>
  );
};

export default index;
