/** @format */
import { Tooltip } from "@material-ui/core";
import { React, useContext } from "react";
import { FormContext } from "./Context/FormContext";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";

export default function TrasacElement({ content: { h1, p, fields } }) {
  const { handletransaction } = useContext(FormContext);
  return (
    <div className="valuation-transaction">
      <h1>{h1}(€)</h1>
      <p>{p}</p>
      {fields
        ? fields.map((field, i) => (
            <div key={i} className="row align-items-end">
              <div className="col-6">
                <label
                  htmlFor={field.field_id}
                  className="form-label valuation_input_label"
                >
                  {field.field_title}
                </label>
                <Tooltip title="€" placement="left-start">
                  <input
                    // type='text'
                    className="valuation_input"
                    type={field.field_type}
                    placeholder={field.field_placeholder}
                    id={field.field_id}
                    required
                    value={field.fieldvalue}
                    // eslint-disable-next-line react/no-unknown-property
                    startAdornment={
                      <InputAdornment position="start">€</InputAdornment>
                    }
                    onChange={(event) =>
                      handletransaction(field.field_id, event)
                    }
                  />
                </Tooltip>
              </div>
              <div className="col-4">
                <p>{field.meta}</p>
              </div>
            </div>
          ))
        : null}
    </div>
  );
}
TrasacElement.propTypes = {
  content: PropTypes.any.isRequired,
  // handleNext: PropTypes.any.isRequired,
  // balanceSheet: PropTypes.any.isRequired
};
