import React from "react";
import "./index.css";
import impactimg from "./../../../Assets/images/dashboard_impact.png";
// import { Link } from "react-router-dom";

export default function OnBoardUpdated() {

  return (
    <div className="Dashboard01_events dashboard01_block mt-0 impact-box justify-content-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="body2">Make your profile impactful</div>
        <div className="row d-flex justify-content-center align-items-center mt-24">
          <img className="col-12 col-md-6" src={impactimg} />
        <div className="overline mt-8 col-12 col-md-6" style={{color: "#989898", fontSize: "12px"}}>Seems like you missed some data while onboarding fill all the detail to attract more users </div>
      </div>
      </div>
    </div>
  );
}