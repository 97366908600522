import React from "react";
import {
  AccessTime,
  AccountBalanceWallet,
  ArrowForward,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Card = (props) => {
  const { program, setIncubationState } = props;
  const navigate = useNavigate();
  return (
    <div
      className="col-lg-3 col-md-6 col-12 mb-4"
      onClick={() => {
        setIncubationState(3);
        navigate(`/dashboard/startup?tab=7&id=${program.to._id}`);
      }}
    >
      {program.to === null ? null : (
        <div className="incubation_program_card_root">
          <div
            className="incubation_program_card_head"
            style={{ backgroundImage: `url('${program.to.coverImage}')` }}
          >
            <p className="p_normalBlack10">{program.to.sector}</p>
          </div>
          <div className="incubation_program_card_main pl-3 pr-3">
            <div className="incubation_program_card_title mt-2">
              <h5 className="p_black20">{program.to.title}</h5>
            </div>
            <div className="incubation_program_card_info d-flex justify-content-between mt-3">
              <div className="incubation_program_card_small">
                <span>
                  {" "}
                  <AccountBalanceWallet />{" "}
                </span>
                <span className="p_secBlack10"> {!program?.programPaymentType || program?.programPaymentType === 'free' ? 'FREE' : '€ ' + program.participationAmmount} </span>
              </div>
              <div className="incubation_program_card_small">
                <span>
                  {" "}
                  <AccessTime />{" "}
                </span>
                <span className="p_secBlack10"> {program.to.duration} </span>
              </div>
            </div>
            <div className="incubator_program_card_applicants_root mt-3">
              <p className="p_secBlack10" style={{ color: "#580167" }}>
                Hosted By
              </p>

              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center mt-2">
                  <Avatar
                    src={program.to.id.avatar}
                    style={{ width: 32, height: 32 }}
                    variant="circular"
                  />
                  <p className="body2 ml-2 color_dark_blue">
                    {program.to.id.name}
                  </p>
                </div>
                <div>
                  <ArrowForward style={{ fill: "#6D7D8B", width: "0.78em" }} />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              program.is_accepted
                ? "incubator_program_footer_accept"
                : "incubator_program_footer_pending"
            } incubator_program_card_footer`}
          >
            <p className="p_secBlack10" style={{ color: "rgba(0,0,0,0.75)" }}>
              {program.is_accepted
                ? "ACCEPTED"
                : program.is_short_listed && program.vc_requested
                ? "Scheduled Interview"
                : program.is_short_listed
                ? "Shortlisted"
                : "Pending"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
