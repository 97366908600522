import React from "react";
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const trendchart = ({ data }) => {
  return (
    <ResponsiveContainer width={`100%`} height={250}>
      <LineChart height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" padding={{ left: 30, right: 30 }} />

        <Tooltip />
        <Legend />
        <Line
          type="category"
          dataKey="Startups"
          stroke="#580167"
          activeDot={{ r: 8 }}
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default trendchart;
