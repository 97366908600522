import React, { useEffect, useState } from 'react'
import Card from "./Card"
import Axios from "axios"
import { toast } from "react-toastify"
import { useAuth } from "../../../../../Utils/Auth"
import CardEstate from './CardEstate'

const Completed = ({ viewLeases, setViewLeases }) => {
  const [leases, setLeases] = useState([])
  const { setSessionExpire } = useAuth();

  const getCompletedLeases = async () => {
    try {
      await Axios({
        method: "get",
        url: `/api/incubator-dashboard/leases/incubator/${true}`,
      }).then((res) => {
        const { data } = res.data
        let newArr = [...data.asset, ...data.estate]
        newArr.sort(function (a, b) {
          return new Date(a.createdAt) - new Date(b.createdAt);
        })
        setLeases(newArr)
      }).catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      })
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  }

  useEffect(() => {
    getCompletedLeases()
  }, [])
  return (
    <div className='leases_active_root'>
      <div className="row">
        {
          leases.length > 0 ?
            leases.map((data) => <>
              {
                data.asset_id ? <Card
                  key={data._id}
                  data={data}
                  viewLeases={viewLeases}
                  setViewLeases={setViewLeases}
                /> : <CardEstate
                  key={data._id}
                  data={data}
                  viewLeases={viewLeases}
                  setViewLeases={setViewLeases}
                />
              }
            </>) : null
        }
      </div>
    </div>
  )
}

export default Completed