import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import MentorProfile from "./MentorProfile";
// import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
// import { Link } from "react-router-dom";
import "./index.css";

const index = ({ programid, setparticipantState }) => {
  const [activementors, setactivementors] = useState([]);

  const { setSessionExpire } = useAuth(); // ,user
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [mentorid, setmentorid] = useState("");

  // const cancelRequest = async (id) => {
  //   await Axios({
  //     url: `/api/mentor/delete/request/${id}`,
  //     method: "delete",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       if (data.success) {
  //         toast.success("Request cancelled");
  //         fetchInvites();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response.status === 401) {
  //         setSessionExpire(true);
  //       }
  //       toast.error(err.response.data.error);
  //     });
  // };

  const fetchactivementors = async () => {
    await Axios({
      url: `/api/incsp/activementors/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setactivementors(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchactivementors();
  }, []);

  // console.log(participants);

  return (
    <>
      {step === 1 ? (
        <>
          <MentorProfile
            setstep={setstep}
            id={participantid}
            fetchactivementors={fetchactivementors}
            setparticipantState={setparticipantState}
            programid={programid}
            mentorid={mentorid}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row m-0">
                <p className="p_spartan_20 mb-4 mt-2">
                  Active Service Providers
                </p>
                {activementors &&
                  activementors.length != 0 &&
                  activementors.map((v, i) => {
                    return (
                      <>
                        <div
                          className="col-12 no-padding mt-1 row dashboard_mentor_card"
                          key={i}
                          onClick={() => {
                            setstep(1);
                            setparticipantid(v.profile._id);
                            setmentorid(v.profile.id);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="col-12 col-md-2 d-flex align-items-center">
                            <Avatar
                              src={v.profile.logo}
                              alt="connection"
                              sx={{ width: 90, height: 90 }}
                            />
                          </div>
                          <div
                            className="col-12 col-md-5 d-flex flex-column justify-content-center"
                            style={{ borderRight: "1px solid #262C5B" }}
                          >
                            <h5 className="row" style={{ color: "#262C5B" }}>
                              {v.profile.companyName &&
                              v.profile.companyName.length > 12
                                ? v.profile.companyName.slice(0, 12) + "..."
                                : v.profile.companyName}
                            </h5>
                            <div className="row">
                              <p className=" no-margin font12 bold">
                                Service Provider | &nbsp;
                              </p>
                              <p className=" no-margin font12 bold">
                                {" "}
                                {v.profile.location.city},{" "}
                                {v.profile.location.country}
                              </p>
                            </div>
                          </div>
                          <div className=" col-12 col-md-5">
                            <p className=" font12 p_grey_chip mt-3 mb-3">
                              {v.profile.industry}
                            </p>

                            <p
                              className="caption mb-3"
                              style={{ color: "#303030" }}
                            >
                              {v.profile.description}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default index;
