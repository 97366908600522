import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
import host_img from "../../../Assets/images/events/codicon_new-business.jpg";
// import search from "./../../../../../Assets/images/icons/search.png";
import Card from "./Card";
import "./index.css";
import CreateService from "./CreateService";
import ServiceProfile from "../ServiceProfile";
import Infopage from "../Explore/Infopage";
import { Avatar } from "@mui/material";

const ViewCourse = () => {
  const [step, setstep] = useState(0);
  // console.log(step);
  const { setSessionExpire } = useAuth();
  const [services, setservices] = useState([]);
  const [serviceid, setserviceid] = useState(null);
  const [userid, setuserid] = useState(0);
  const [requests, setrequests] = useState([]);
  const fetchservices = async () => {
    await Axios({
      url: "/api/service/myservices/",
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setservices(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const fetchrequests = async () => {
    await Axios({
      url: "/api/service/requests-sp/",
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setrequests(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchservices();
    fetchrequests();
  }, []);
  // console.log(services);
  const handleserviceid = (id) => {
    setserviceid(id);
    setstep(2);
  };
  const handlerequest = (id, id1) => {
    setuserid(id);
    setserviceid(id1);
    setstep(3);
  };

  return (
    <>
      {step === 1 ? (
        <CreateService setstep={setstep} fetchservices={fetchservices} />
      ) : step === 2 ? (
        <ServiceProfile setstep={setstep} serviceid={serviceid} />
      ) : step === 3 ? (
        <Infopage setstep={setstep} startupid={userid} serviceid={serviceid} />
      ) : (
        <div className="row">
          <div className="col-12 point mb-24" onClick={() => setstep(1)}>
            <div className="onboarding_cards incubation_create_courseFirst2">
              <img src={host_img} alt="host_img" className="mr-16" />
              <p className="mt-2">HOST A SERVICE</p>
            </div>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="incubation_program_root">
              <div className="incubation_program_main mt-8">
                <div className="row">
                  {services && services.length > 0
                    ? services.map((v, i) => (
                        <Card
                          key={i}
                          service={v}
                          handleserviceid={handleserviceid}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-4">
            <div className="side_box mt-0">
              <div
                className="subtitle"
                style={{
                  padding: "8px 16px",
                  background: "rgba(226, 232, 240, 0.5)",
                  borderRadius: "10px 10px 0 0",
                }}
              >
                Requests Sent
              </div>
              {requests.length > 0 ? (
                <div className="row">
                  {requests.map((v, i) => {
                    return (
                      <>
                        <>
                          <div key={i} className="col-12">
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ padding: "16px 24px" }}
                            >
                              <div className="d-flex align-items-center">
                                <Avatar
                                  src={v.customerid.avatar}
                                  sx={{ width: 40, height: 40 }}
                                  className="mr-3"
                                  variant="rounded"
                                />
                                <p
                                  className="body2"
                                  style={{ color: "#262C5B" }}
                                >
                                  {v.serviceid.servicename}
                                </p>
                              </div>
                              <div
                                onClick={() =>
                                  handlerequest(
                                    v.customerid._id,
                                    v.serviceid._id
                                  )
                                }
                                className="overline"
                                style={{ cursor: "pointer", color: "#989898" }}
                              >
                                View Request
                              </div>
                            </div>
                          </div>
                        </>
                      </>
                    );
                  })}
                </div>
              ) : (
                <p
                  className="body2"
                  style={{ padding: "16px 24px", color: "#989898" }}
                >
                  No Requests yet
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewCourse;
