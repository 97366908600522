import React, { useEffect, useState } from "react";
// import profile_short from "../../../Assets/images/incubatorDash/profile_short.png";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
import Applicantcard from "./Applicantcard";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Vcmodal from "./Vcmodal";
import getDiff from "../../../../Utils/diffleft/getDiff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ShortListedCard = ({ programid }) => {
  const [applicants, setapplicants] = useState([]);
  const { setSessionExpire } = useAuth();
  const [step, setstep] = useState(0);
  const [startupid, setstartupid] = useState("");
  const [requestid, setrequestid] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/incubatorProgram/shortlists/${programid}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {step === 1 ? (
        <Applicantcard
          setstep={setstep}
          startupid={startupid}
          requestid={requestid}
          callbackfunc={fetchapplicants}
          programid={programid}
        />
      ) : (
        <>
          {applicants.length > 0 ? (
            <>
              {applicants.map((v, i) => {
                return (
                  <div
                    className="col-12 incunation_shortlisted_main mb-4"
                    key={i}
                  >
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-4 d-flex align-items-center">
                        <img
                          src={v.profile.logo}
                          alt="profile_short"
                          className="mr-2"
                          height={"80px"}
                          width={"80px"}
                        />
                        <p className="p_Bold_secBlue18_spartan">
                          {v.profile.companyName}
                        </p>
                      </div>
                      <div className="col-lg-1 col-md-1 col-2 d-flex justify-content-center align-items-center">
                        <p
                          className="p_LightGrey12"
                          style={{ color: "#565656" }}
                        >
                          Startup
                        </p>
                      </div>
                      <div className="col-lg-2 col-md-2 col-3 d-flex align-items-center">
                        <div className="incubation_chips">
                          {v.profile.industry}
                        </div>
                       
                      </div>
                      <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center">
                        <p
                          className="p_LightGrey12"
                          style={{ color: "#565656" }}
                        >
                          {getDiff(v.updatedAt)}
                        </p>
                      </div>
                      {!v.vc_requested ? (
                        <>
                          <div
                            onClick={() => {
                              handleOpen();
                              setrequestid(v._id);
                            }}
                            className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
                            style={{ cursor: "pointer" }}
                          >
                            <p className="transparntChip mr-2">
                              SCHEDULE INTERVIEW
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center">
                          <p className="transparntChip mr-2">
                            INTERVIEW REQUESTED
                          </p>
                        </div>
                      )}

                      <div
                        onClick={() => {
                          setstep(1);
                          setstartupid(v.profile._id);
                          setrequestid(v._id);
                        }}
                        style={{ cursor: "pointer" }}
                        className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
                      >
                        <p
                          className="p_LightGrey12"
                          style={{ color: "#565656" }}
                        >
                          See Profile
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropProps={{
              timeout: 300,
            }}
          >
            <Box sx={style}>
              <Vcmodal requestid={requestid} callbackfunc={fetchapplicants} />
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default ShortListedCard;
