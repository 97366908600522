import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../../../../Utils/Auth';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import download from "../../../../../Assets/images/financial/download.png"

const ReportCard = () => {
    // const navigate = useNavigate()
    // const {user} = useAuth()

    return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_by">
            <p className="body2 color_dark_black">Monthly assets reports</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="body2 color_dark_black">Monthly assets reports for July 2022</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
            <p className="body2 color_dark_black">08/07/2022</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_score">
            <p className="body2 color_dark_black">08/07/2022</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_score">
            <p className="body2 color_dark_black">Euro 40000</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_view d-flex justify-content-center">
            <button className='transparent_cta caption color_dark_grey'><img src={download} alt='zefyron' /></button>
        </td>
    </tr>
}

export default ReportCard