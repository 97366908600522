import React, { useEffect, useState } from "react";
import AddTask from "./addtask";
import { Modal, Box, Popover, Avatar } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { IconButton } from "@mui/material";
import moment from "moment-timezone";
import { KeyboardArrowLeft } from "@mui/icons-material";
import "./index.css";

import { MoreVertTwoTone } from "@mui/icons-material";
// import Checklists from "./checklists";
import Chatroom from "../../Chatroom";
import EditTask from "./edittask";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "8px",
  boxShadow: 24,
  bgcolor: "#fff",
  p: 4,
};

const index = ({ setstep }) => {
  const [mentor, setmentor] = useState(null);
  const [roomstep, setroomstep] = useState(0);
  const [formOpen, setFormOpen] = useState(false);
  const handleFormOpen = () => setFormOpen(true);
  const handleFormClose = () => setFormOpen(false);
  const { setSessionExpire, user } = useAuth();
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [dueDate, setdueDate] = useState(new Date());
  const [problem, setProblem] = useState("");
  const [problemArr, setProblemArr] = useState("");

  const [tasks, settasks] = useState([]);
  const [editData, setEditData] = useState(null);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const history = useNavigate()
  // edit task modal
  const [EditTaskOpen, setEditTaskOpen] = useState(false);
  const handleEditTaskOpen = () => {
    setEditTaskOpen(true);
    handlePopClose();
  };
  const handleEditTaskClose = () => {
    setEditTaskOpen(false);
    handlePopClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setEditData(data);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const popopen = Boolean(anchorEl);
  const popid = popopen ? "simple-popover" : undefined;

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleClick2 = (event, data) => {
    setAnchorEl2(event.currentTarget);
    setEditData(data);
  };

  const handlePopClose2 = () => {
    setAnchorEl2(null);
  };

  const popopen2 = Boolean(anchorEl2);
  const popid2 = popopen2 ? "simple-popover" : undefined;

  const gettasks = async () => {
    await axios({
      method: "get",
      url: `/api/mentor/tasks/${id}`,
    })
      .then((res) => {
        settasks(res.data.tasks);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const MarkAsComplete = async (id) => {
    await axios({
      method: "get",
      url: `/api/mentor/task/complete/${id}`,
    })
      .then(() => {
        gettasks();
        handlePopClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getMentor = async () => {
    await axios({
      method: "get",
      url: `/api/mentor/mentor-by-connection/${id}`,
    })
      .then((res) => {
        setmentor(res.data.mentor);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const MarkAsUnComplete = async (id) => {
    await axios({
      method: "get",
      url: `/api/mentor/task/un-complete/${id}`,
    })
      .then(() => {
        gettasks();
        handlePopClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getMentor();
    gettasks();
  }, []);

  const getDiff = (date) => {
    // var dateTime = date + "T" + time;
    var scheduleTime = moment(date);
    var current = moment();
    // var ofset = moment().utcOffset() / 60;

    var dayDiff = scheduleTime.diff(current, "days");
    if (dayDiff > 1) {
      return Math.abs(dayDiff) + " days";
    } else if (dayDiff === 1) return Math.abs(dayDiff) + " day";
    else {
      var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
      //- parseFloat(timezone.split(" ", 3)[2]) +
      // ofset;
      if (hoursDiff >= 1) {
        let hoursDiffRound = Math.round(hoursDiff);
        return Math.abs(hoursDiffRound) == 1
          ? Math.abs(hoursDiffRound) + " hour"
          : Math.abs(hoursDiffRound) + " hours";
      } else if (hoursDiff < 0 && hoursDiff > 0) {
        return Math.abs(Math.round(hoursDiff * 60)) + " mins";
      } else return false;
    }
  };

  return (
    <div className="mentor_room_root">
      {roomstep === 1 ? (
        <>
          <Chatroom setroomstep={setroomstep} />
        </>
      ) : (
        <>
          <div
            className="back_CTA pointer"
            onClick={() => {
              setstep(0);
              history(`/dashboard/${user.type}?tab=5`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>

          <div>
            <div className="row mx-0 my-3 r_a">
              <div className="col-lg-4 col-sm-6 col-xs-12">
                {mentor ? (
                  <div className="mentor_room_head_block white_bg">
                    <div className="row r_a m-0 py-2">
                      <Avatar
                        sx={{ width: 50, height: 50 }}
                        className=""
                        src={mentor.profilePic}
                      />
                      <div>
                        <p className="p_spartan_20 px-3">{mentor.name}</p>
                        <p className="px-3 grey bold">
                          {mentor.location.country}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="col-lg-3 col-sm-4 col-xs-12">
                <div className="mentor_room_head_block pink_bg ">
                  <p className="p_spartan_20 pt-1">To-Do Tasks</p>
                  <p className="spartan font25 purple bold">
                    {tasks &&
                      tasks.filter((t) => {
                        return !t.isComplete;
                      }).length}
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-4 col-xs-12">
                <div className="mentor_room_head_block light_bg ">
                  <p className="p_spartan_20 pt-1">Ongoing</p>
                  <p className="spartan font25 orange bold ">
                    {tasks &&
                      tasks.filter((t) => {
                        return !t.isComplete;
                      }).length}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-4 col-xs-12">
                <div className="mentor_room_head_block grey_bg ">
                  <p className="p_spartan_20 pt-1">Completed</p>
                  <p className="spartan font25 bold">
                    {tasks &&
                      tasks.filter((t) => {
                        return t.isComplete;
                      }).length}
                  </p>
                </div>
              </div>
            </div>
            <div className="row mx-0 my-3">
              <div className="col-lg-4 col-sm-6 col-xs-12">
                <div className="dashboard01_block">
                  <p className="p_spartan_20 px-2 py-2">To Do</p>
                  {tasks &&
                    tasks.map((data, i) => {
                      return (
                        <>
                          {!data.isComplete ? (
                            <div key={i}>
                              <div className="newTask-card-main">
                                <div className="newPartnership-head">
                                  <div className="newTask-headName w_100 d-flex justify-content-between">
                                    <div className="newTask-title">
                                      <p className="font10">
                                        {new Date(
                                          data.createdAt
                                        ).toLocaleDateString()}
                                      </p>
                                      <p className="bold font18">
                                        {data.title}
                                      </p>
                                    </div>
                                    <div className="newTask-right">
                                      <p
                                        className="transparent_btn_noBorder"
                                        onClick={(e) => handleClick(e, data)}
                                      >
                                        <IconButton>
                                          <MoreVertTwoTone />
                                        </IconButton>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div className="newTask-desc my-3">
                                  <p className="grey2 font12">
                                    {data.description.slice(0, 80)}
                                    {data.description.length > 79 ? "..." : ""}
                                  </p>
                                </div>
                                <hr />

                                {/* {data.isComplete ? null : (
                      <div className="newTask-addFile-main task-dash-root mt-2">
                        <Checklists data={data} getCheckLists={getCheckLists} />
                      </div>
                    )} */}

                                <div className="row my-2 mx-0 justify-content-between r_a">
                                  <p className="purple">
                                    <span className="">Due Date: </span>
                                    <span className="p_just_small_black12">
                                      {new Date(
                                        data.dueDate
                                      ).toLocaleDateString()}
                                    </span>
                                  </p>
                                  {getDiff(data.dueDate) ? (
                                    <p className="alert_CTA">
                                      <span className="p_just_small_black12">
                                        {getDiff(data.dueDate)}
                                      </span>
                                    </p>
                                  ) : (
                                    <p className="alert_CTA">
                                      <span className="p_just_small_black12">
                                        Due
                                      </span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-xs-12">
                <div className="dashboard01_block">
                  <p className="p_spartan_20 px-2 py-2">Completed Tasks</p>
                  {tasks &&
                    tasks.map((data, i) => {
                      return (
                        <>
                          {data.isComplete ? (
                            <div key={i}>
                              <div className="newTask-card-main">
                                <div className="newPartnership-head">
                                  <div className="newTask-headName w_100 d-flex justify-content-between">
                                    <div className="newTask-title">
                                      <p className="font10">
                                        Submitted{" "}
                                        {new Date(
                                          data.updatedAt
                                        ).toLocaleDateString()}
                                      </p>
                                      <p className="bold font18">
                                        {data.title}
                                      </p>
                                    </div>
                                    <div className="newTask-right">
                                      <p
                                        className="transparent_btn_noBorder"
                                        onClick={(e) => handleClick2(e, data)}
                                      >
                                        <IconButton>
                                          <MoreVertTwoTone />
                                        </IconButton>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-xs-12">
                <div className="dashboard01_block">
                  <div className="row mx-0 r_sa r_a">
                    {user && mentor && user.id === mentor._id ? (
                      <p className="py-2 back_CTA" onClick={handleFormOpen}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ff6f1e"
                          className="bi bi-list-task mx-2"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z"
                          />
                          <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                          <path
                            fillRule="evenodd"
                            d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z"
                          />
                        </svg>
                        Create Task
                      </p>
                    ) : null}

                    <p className="py-2 back_CTA" onClick={() => setroomstep(1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#ff6f1e"
                        className="bi bi-chat-left-text-fill mx-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                      </svg>
                      Chat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={formOpen}
            onClose={handleFormClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <AddTask
                title={title}
                settitle={settitle}
                description={description}
                setdescription={setdescription}
                dueDate={dueDate}
                setdueDate={setdueDate}
                handleFormClose={handleFormClose}
                problem={problem}
                setProblem={setProblem}
                problemArr={problemArr}
                setProblemArr={setProblemArr}
                id={id}
                gettasks={gettasks}
              />
            </Box>
          </Modal>
          <Modal
            open={EditTaskOpen}
            onClose={handleEditTaskClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <EditTask
                handleEditTaskClose={handleEditTaskClose}
                editData={editData}
                gettasks={gettasks}
              />
            </Box>
          </Modal>
          <Popover
            id={popid}
            open={popopen}
            anchorEl={anchorEl}
            onClose={handlePopClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="popOver-rootTask">
              <p className="" onClick={handleEditTaskOpen}>
                Edit
              </p>
              <p className="" onClick={() => MarkAsComplete(editData._id)}>
                Complete
              </p>
            </div>
          </Popover>

          <Popover
            id={popid2}
            open={popopen2}
            anchorEl={anchorEl2}
            onClose={handlePopClose2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="popOver-rootTask">
              <p className="" onClick={handleEditTaskOpen}>
                Edit
              </p>
              <p className="" onClick={() => MarkAsUnComplete(editData._id)}>
                Re-open
              </p>
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

export default index;
