import React, { useState } from "react";
import logo from "../../Assets/images/logo/logo.png";
import "./verifymail.css";
import forget_img from "../../Assets/images/signup.png";
import { Link, useNavigate } from "react-router-dom";
// import { useAuth } from "./../../../Utils/Auth";

const CheckEmail = () => {
  // const { setLoadCurrent, loadCurrent } = useAuth();

  const navigate = useNavigate();

  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <div className="auth_back_part">
        <div className="twf_signup_left">
          <div className="twf_signup_left_imgs">
            <div className="twf_signup_left_logo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </div>
            <div className="twf_singup_left_bg">
              <img
                className={` img-fluid smooth-image auth_option_img image-${imageLoaded ? "visible" : "hidden"
                  } auth_image`}
                onLoad={() => setImageLoaded(true)}
                src={forget_img}
                alt="twf signup"
              />
            </div>

          </div>
          <div
            className="auth_back point"
            onClick={() => {
              navigate('/');
            }}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </div>

        </div>
        <div className="twf_signup_right_main">
          <div className="auth_back_loginform-head">
            <h3>Thankyou for signing up!</h3>
          </div>
          <p className="auth_back_loginform-para">
            We just mailed you with the instructions to verify your email.
          </p>
          <form onSubmit={() => navigate("/login")} className="row">
            <div className="signup-btn col-12">
              <button type="submit" className="gradient_CTA">
                LOGIN BACK
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CheckEmail;


