import { Avatar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import gifmeet from "./../../../Assets/images/meet.gif";

export default function Events({seteventState}) {
  // const { user } = useAuth();

  const [meetings, setmeetings] = useState([]);
  const { setSessionExpire } = useAuth();
  const getmeetings = async () => {
    axios({
      url: "/api/incmeet/recent-meets",
      method: "get",
    })
      .then((res) => {
        setmeetings(res.data.meets);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    getmeetings();
    // console.log("meet",meetings);
  }, []);
  
  const options = { dateStyle:'short',timeStyle:'short' };

  return (
    <div className="Dashboard01_events dashboard01_block mt-0">
      <div className="row justify-content-between mx-1 my-1">
        <h5 className="w_fit subtitle">Meetings</h5>
      </div>
      <div className="">
      <div className="row">
      {meetings && meetings.length > 0
            ? meetings.slice(0,3).map((meet, i) => (
              
              <div className="col-12 meetbox-hover" key={i} style={{borderBottom: i==2? "none" : "0.5px solid rgba(228, 228, 228, 0.5)" }}>
                <div className="meetbox">
                  <div className="gradient-div">
                  <div className="mt-2 mb-2 main-top-gradient">
                  <div className="row">
                    <div className="col-9">
                    <div className="caption">
                      {meet.title}
                    </div>
                    <div className="overline mt-2 d-flex" style={{color: "#989898"}}>
                      <div className="pr-2" style={{borderRight: "1px solid #E4E4E4"}}>
                        {meet.programid?.title}
                      </div>
                      <div className="pl-2">
                       {new Date(meet.schedule).toLocaleString('en-US', options)}
                      </div>
                    </div>
                    </div>
                    <div className="col-3 d-flex justify-content-end">
                    <div className="d-flex justify-content-center align-items-center">

                    <div className="w_fit mr-3">
                      <Avatar sx={{ width: 32, height: 32 }} alt="Remy Sharp" src={meet.programid?.coverImage} style={{background:"rgba(0,0,0,0.1)"}} />
                    </div>
                    {/* href={`/jitsi-meet/${meet.meet_id}?title=${meet.title}&host=${user.name}&programid=${meet.programid}&d=incprogram`} */}
                    <Link to={`/service/incprogram/${meet.programid?._id}?tab=5`} className="w_fit mr-0" rel='noreferrer' style={{height:"fit-content"}}>
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.47257 6.6668H8.91924L5.66591 9.92013C5.40591 10.1801 5.40591 10.6068 5.66591 10.8668C5.92591 11.1268 6.34591 11.1268 6.60591 10.8668L10.9992 6.47347C11.2592 6.21347 11.2592 5.79347 10.9992 5.53347L6.61257 1.13347C6.48802 1.00863 6.31892 0.938477 6.14257 0.938477C5.96623 0.938477 5.79713 1.00863 5.67257 1.13347C5.41257 1.39347 5.41257 1.81347 5.67257 2.07347L8.91924 5.33347H1.47257C1.10591 5.33347 0.805908 5.63347 0.805908 6.00013C0.805908 6.3668 1.10591 6.6668 1.47257 6.6668Z" fill="#989898"/>
                      </svg>
                    </Link> 
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
              ))
            : (
              <div className="meeting_main_no_meetings d-flex mt-16">
                <div className="meeting_main_gif">
                  <img src={gifmeet} alt="gif" />
                </div>
                <p className="body1 color_dark_blue pl-16"
                  onClick={()=>seteventState(3)}
                >There are no meetings scheduled</p>
              </div>
            )
      }
      </div>
      </div>

      {/* <div className="col-12 mx-2 events01_block_list mt-2 d-flex flex-column align-items-center justify-content-center" style={{height:"140px",background: "#fff"}}>
                <img src={gifmeet} style={{witdh:"100px",height:"100px",margin:"14px"}} />
              <p className="text-center body1 mx-auto" style={{color:"#262C5B"}}
              onClick={()=>seteventState(3)}
              >
                There are no meetings scheduled
              </p>
              </div> */}


      {/* <div className="">
      <div className="row">
      <div className="col-12">
      <div className="pt-2 pb-2" style={{borderBottom: "0.5px solid rgba(228, 228, 228, 0.5)"}}>
      <div className="row">
        <div className="col-8">
        <div className="caption">
          Update Mentor Meet
        </div>
        <div className="overline mt-2 d-flex" style={{color: "#989898"}}>
          <div className="pr-2" style={{borderRight: "1px solid #E4E4E4"}}>
            AI 4 INNOVATORS
          </div>
          <div className="pl-2">
            19:00 HRS
          </div>
        </div>
        </div>
        <div className="col-4 d-flex justify-content-end">
        <div className="d-flex justify-content-center align-items-center">
          
        <div className="w_fit mr-3">
          <Avatar sx={{ width: 32, height: 32 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </div>
        <Link className="w_fit mr-0" to={`/dashboard/${user.type}`} style={{height:"fit-content"}}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.47257 6.6668H8.91924L5.66591 9.92013C5.40591 10.1801 5.40591 10.6068 5.66591 10.8668C5.92591 11.1268 6.34591 11.1268 6.60591 10.8668L10.9992 6.47347C11.2592 6.21347 11.2592 5.79347 10.9992 5.53347L6.61257 1.13347C6.48802 1.00863 6.31892 0.938477 6.14257 0.938477C5.96623 0.938477 5.79713 1.00863 5.67257 1.13347C5.41257 1.39347 5.41257 1.81347 5.67257 2.07347L8.91924 5.33347H1.47257C1.10591 5.33347 0.805908 5.63347 0.805908 6.00013C0.805908 6.3668 1.10591 6.6668 1.47257 6.6668Z" fill="#989898"/>
          </svg>
        </Link> 
        </div>
      </div>
      </div>
      </div>
      </div>
      <div className="col-12">
      <div className="pt-2 pb-2" style={{borderBottom: "0.5px solid rgba(228, 228, 228, 0.5)"}}>
      <div className="row">
        <div className="col-8">
        <div className="caption">
          Update Mentor Meet
        </div>
        <div className="overline mt-2 d-flex" style={{color: "#989898"}}>
          <div className="pr-2" style={{borderRight: "1px solid #E4E4E4"}}>
            AI 4 INNOVATORS
          </div>
          <div className="pl-2">
            19:00 HRS
          </div>
        </div>
        </div>
        <div className="col-4 d-flex justify-content-end">
        <div className="d-flex justify-content-center align-items-center">
          
        <div className="w_fit mr-3">
          <Avatar sx={{ width: 32, height: 32 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </div>
        <Link className="w_fit mr-0" to={`/dashboard/${user.type}`} style={{height:"fit-content"}}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.47257 6.6668H8.91924L5.66591 9.92013C5.40591 10.1801 5.40591 10.6068 5.66591 10.8668C5.92591 11.1268 6.34591 11.1268 6.60591 10.8668L10.9992 6.47347C11.2592 6.21347 11.2592 5.79347 10.9992 5.53347L6.61257 1.13347C6.48802 1.00863 6.31892 0.938477 6.14257 0.938477C5.96623 0.938477 5.79713 1.00863 5.67257 1.13347C5.41257 1.39347 5.41257 1.81347 5.67257 2.07347L8.91924 5.33347H1.47257C1.10591 5.33347 0.805908 5.63347 0.805908 6.00013C0.805908 6.3668 1.10591 6.6668 1.47257 6.6668Z" fill="#989898"/>
          </svg>
        </Link> 
        </div>
      </div>
      </div>
      </div>
      </div>
      <div className="col-12">
      <div className="pt-2 pb-2">
      <div className="row">
        <div className="col-8">
        <div className="caption">
          Update Mentor Meet
        </div>
        <div className="overline mt-2 d-flex" style={{color: "#989898"}}>
          <div className="pr-2" style={{borderRight: "1px solid #E4E4E4"}}>
            AI 4 INNOVATORS
          </div>
          <div className="pl-2">
            19:00 HRS
          </div>
        </div>
        </div>
        <div className="col-4 d-flex justify-content-end">
        <div className="d-flex justify-content-center align-items-center">
          
        <div className="w_fit mr-3">
          <Avatar sx={{ width: 32, height: 32 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </div>
        <Link className="w_fit mr-0" to={`/dashboard/${user.type}`} style={{height:"fit-content"}}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.47257 6.6668H8.91924L5.66591 9.92013C5.40591 10.1801 5.40591 10.6068 5.66591 10.8668C5.92591 11.1268 6.34591 11.1268 6.60591 10.8668L10.9992 6.47347C11.2592 6.21347 11.2592 5.79347 10.9992 5.53347L6.61257 1.13347C6.48802 1.00863 6.31892 0.938477 6.14257 0.938477C5.96623 0.938477 5.79713 1.00863 5.67257 1.13347C5.41257 1.39347 5.41257 1.81347 5.67257 2.07347L8.91924 5.33347H1.47257C1.10591 5.33347 0.805908 5.63347 0.805908 6.00013C0.805908 6.3668 1.10591 6.6668 1.47257 6.6668Z" fill="#989898"/>
          </svg>
        </Link> 
        </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div> */}
    </div>
  );
}