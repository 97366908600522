import React from "react";
import { useNavigate } from "react-router-dom";
import "./navbar.css";
import logo from "../../Assets/images/logo/TWF_logo_white_400H.svg";
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-lg navbar-light container">
      {/* <a className="navbar-brand" href="#">Navbar</a> */}
      <div className="nav_logo navbar-brand">
        <figure>
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={logo}
            alt="Logo"
          />
        </figure>
      </div>
     
    </nav>
  );
};

export default Navbar;
