import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../Assets/images/logo/logoblack.png";
import "../dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import embedLogo from "../../Assets/images/logo/TWF_emblem_black_400H.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import DashboardNav from "../Components/DashboardNav";
import { useAuth } from "../../Utils/Auth";

import ConnectionProfile from "../Components/Connections/ConnectionProfile";
import Messages from "../Components/Messages";

import Feed from "../Components/Feed/Feed";
import StudentPrograms from "../Components/PreStartupPrograms";
import Profile from "../Components/Profile/PreStartup";
import NotFoundPage from "../../Pages/404Pages";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function DashboardTabs() {


  // setActiveStep
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const { user, profile, isLoading } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isTab = useMediaQuery({ query: "(max-width:768px)" });
  const query = useQuery();
  var currentStep = Number(query.get("tab"));

  // var ps = Number(query.get("ps"));

  useEffect(() => {
    if (currentStep) {
      setValue(currentStep);
    }
  }, [currentStep]);


  useEffect(() => {
    if (currentStep) {
      setValue(currentStep);
    }
  }, [currentStep]);



  // useEffect(() => {
  //   // if (!isLoading && !profile && !isVerified) return navigate("/");
  //   if (!isLoading && !isVerified) return navigate("/thankyou");
  // }, [isLoading]);

  if (isLoading) {
    return <div></div>;
  }
  else if(user.type !== "prestartup") 
  {
    return <NotFoundPage/>
  }
  else {
    return (
      <>
        {profile ? (
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: 224,
            }}
            className="dashboard01_main_box"
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="dashboard01_tabs"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <div className="dashboard01_nav_head">
                <Link to="/">
                  <img
                    src={isTab ? embedLogo : logo}
                    className="dashboard_left_nav_logo"
                  />
                </Link>
              </div>
              <Tab
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=1`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-house-door-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
                      </svg>
                    </div>

                    <p>Programs</p>
                  </div>
                }
                {...a11yProps(1)}
              />
              <Tab
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=2`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </div>
                    <p>Profile</p>
                  </div>
                }
                {...a11yProps(2)}
              />

            </Tabs>
            <DashboardNav />
            <TabPanel value={value} className="dashboard01_tabpanel" index={1}>
              <StudentPrograms />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={2}>
              <Profile />
            </TabPanel>

            <TabPanel value={value} className="dashboard01_tabpanel" index={1158}>
              <Messages />
            </TabPanel>

            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1651}
            >
              <ConnectionProfile />
            </TabPanel>

            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1561}
            ></TabPanel>
            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1920}
            >
              <Feed />
            </TabPanel>
          </Box>
        ) : (
          <div
            style={{
              height: "100vh",
              position: "relative",
            }}
          >
            <div
              className="text-center"
              style={{
                margin: "30vh auto",
              }}
            >
              <h5 className="h2_white_40">SESSION EXPIRED</h5>
              <button
                className="gradient_CTA"
                onClick={() => navigate(`/login`)}
              >
                LOGIN AGAIN
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
