import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../Assets/images/logo/logoblack.png";
import "../dashboard.css";
import "./index.css";
import { useNavigate } from "react-router-dom";

import embedLogo from "../../Assets/images/logo/TWF_emblem_black_400H.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import Connections from "../Components/Connections";

import DashboardNav from "../Components/DashboardNav";
import { useAuth } from "../../Utils/Auth";

import EventsForm from "./../../Services/Events/host";
import Myevents from "./../../Services/Events/myevents";
import Event from "./../../Services/Events";

import IncubationTool from "../../Services/IncubatorProgram/LandingPage";
import IncHome from "./Home";
import Messages from "../Components/Messages";
import Profile from "../Components/Profile/incubator";
import ConnectionProfile from "../Components/Connections/ConnectionProfile";
import Requests from "../Components/Connections/Requests";
import MyConnections from "../Components/Connections/MyConnections";
import Feed from "../Components/Feed/Feed";
import CoworkingMain from "../../Services/IncubatorProgram/Coworking/CoworkingMain";
import FinanceMain from "../../Services/IncubatorProgram/FinanceTool/FInanceMain";
import NotFoundPage from "../../Pages/404Pages";
import Membership from "../../Services/IncubatorProgram/Membership/Membership";
import LeadGeneration from "../../Services/LeadGeneration";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  // useEffect(()=>{
  //   console.log('value',value);
  //   console.log('index',index);
  //   console.log('chil',children);
  // },[value,index,children])

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function DashboardTabs() {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  // const [EventValue, seteventState] = useState(0);
  const [eventState, seteventState] = useState(0);
  // const [cur, setcur] = useState(1);
  // const [incubationValue, setIncubationValue] = useState(1);
  const [incubationBool, setIncubationBool] = useState(0);
  const [EventBool, setEventBool] = useState(false);
  const [eventOption, seteventOption] = useState(false);
  const [eventActiveStep, setEventActiveStep] = useState(1);
  const [, setReportState] = useState(false); //reportBool,
  // const [valuationStep, setValuationStep] = useState(1);
  const [connectionState, setconnectionState] = useState(0);
  const [, setActiveStep] = useState(0); //ActiveStep,

  const [, setmentorState] = useState(0); //mentorState,

  const [coworkingBool, setCoWorkingBool] = useState(false);
  const [coworkingStep, setCoWorkingStep] = useState(1);
  const [finantialBool, setFinantialBool] = useState(false);
  const [finantialStep, setFinantialStep] = useState(1);
  const [, setLeadStep] = useState(false); //leadStep
  const { user, isVerified, isLoading, profile } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isTab = useMediaQuery({ query: "(max-width:768px)" });
  const query = useQuery();
  var currentStep = Number(query.get("tab"));
  var currentProStep = Number(query.get("ps"));
  var currentCWStep = Number(query.get("cs"));
  var currentFTStep = Number(query.get("ft"));
  var state = Number(query.get("event-state"));
  useState(() => {
    if (state) {
      seteventState(1);
    }
  }, []);
  useEffect(() => {
    if (currentStep) {
      setValue(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentProStep) {
      setEventActiveStep(currentProStep);
      setIncubationBool(true);
    }
  }, [currentProStep]);
  useEffect(() => {
    if (currentCWStep) {
      setCoWorkingStep(currentCWStep);
      setCoWorkingBool(true);
    }
  }, [currentCWStep]);
  useEffect(() => {
    if (currentFTStep) {
      setFinantialStep(currentFTStep);
      setFinantialBool(true);
    }
  }, [currentFTStep]);

  useEffect(() => {
    if (value == 1920 || value == 11) {
      setEventBool(false);
      setIncubationBool(0);
      setEventBool(0);
      setReportState(false);
      setconnectionState(0);
      setmentorState(0);
    }
  }, [value]);

  useEffect(() => {
    if (!isLoading && !profile && !isVerified) return navigate("/welcome");
    if (!isLoading && !isVerified) return navigate("/thankyou");
  }, [isLoading]);

  if (isLoading) {
    return <div></div>;
  } else if (user.type !== "incubator") {
    return <NotFoundPage />;
  } else {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 224,
        }}
        className="dashboard01_main_box"
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="dashboard01_tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <div className="dashboard01_nav_head">
            {/* <Link to="/"> */}
            <img
              src={isTab ? embedLogo : logo}
              className="dashboard_left_nav_logo"
            />
            {/* </Link> */}
          </div>
          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=1`);
              setIncubationBool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
              setmentorState(0);
              setCoWorkingBool(false);
              setFinantialBool(false);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-house-door-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
                  </svg>
                </div>

                <p>Home</p>
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            className="hidden"
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=2`);
              setIncubationBool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(1);
              setmentorState(0);
              setCoWorkingBool(false);
              setFinantialBool(false);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.3996 4.80039C10.3996 5.43691 10.1468 6.04736 9.69667 6.49745C9.24658 6.94753 8.63613 7.20039 7.99961 7.20039C7.36309 7.20039 6.75264 6.94753 6.30255 6.49745C5.85247 6.04736 5.59961 5.43691 5.59961 4.80039C5.59961 4.16387 5.85247 3.55342 6.30255 3.10333C6.75264 2.65325 7.36309 2.40039 7.99961 2.40039C8.63613 2.40039 9.24658 2.65325 9.69667 3.10333C10.1468 3.55342 10.3996 4.16387 10.3996 4.80039Z" />
                    <path d="M14.3997 6.40078C14.3997 6.82513 14.2311 7.23209 13.9311 7.53215C13.631 7.83221 13.2241 8.00078 12.7997 8.00078C12.3754 8.00078 11.9684 7.83221 11.6683 7.53215C11.3683 7.23209 11.1997 6.82513 11.1997 6.40078C11.1997 5.97643 11.3683 5.56947 11.6683 5.26941C11.9684 4.96935 12.3754 4.80078 12.7997 4.80078C13.2241 4.80078 13.631 4.96935 13.9311 5.26941C14.2311 5.56947 14.3997 5.97643 14.3997 6.40078Z" />
                    <path d="M4.79961 6.40078C4.79961 6.82513 4.63104 7.23209 4.33098 7.53215C4.03092 7.83221 3.62396 8.00078 3.19961 8.00078C2.77526 8.00078 2.3683 7.83221 2.06824 7.53215C1.76818 7.23209 1.59961 6.82513 1.59961 6.40078C1.59961 5.97643 1.76818 5.56947 2.06824 5.26941C2.3683 4.96935 2.77526 4.80078 3.19961 4.80078C3.62396 4.80078 4.03092 4.96935 4.33098 5.26941C4.63104 5.56947 4.79961 5.97643 4.79961 6.40078Z" />
                    <path d="M12.7997 14.4004V12.0004C12.8009 11.1871 12.5943 10.3869 12.1997 9.67564C12.5544 9.58487 12.9251 9.57631 13.2836 9.65061C13.6421 9.72491 13.9789 9.88011 14.2683 10.1044C14.5576 10.3286 14.792 10.616 14.9534 10.9446C15.1148 11.2732 15.1991 11.6343 15.1997 12.0004V14.4004H12.7997Z" />
                    <path d="M3.7998 9.67582C3.4052 10.3871 3.19868 11.1873 3.1998 12.0006V14.4006H0.799805V12.0006C0.799651 11.6343 0.883375 11.2727 1.04456 10.9437C1.20575 10.6147 1.44012 10.327 1.72971 10.1026C2.0193 9.87814 2.35643 9.723 2.71525 9.64903C3.07408 9.57506 3.44507 9.58423 3.7998 9.67582Z" />
                    <rect
                      x="4.7998"
                      y="8.80078"
                      width="6.4"
                      height="5.6"
                      rx="2.8"
                    />
                  </svg>
                </div>
                <p>Connections</p>
              </div>
            }
            {...a11yProps(2)}
          />
          {connectionState ? (
            <div className="dashboard01_tab_Valuate">
              <button
                onClick={() => setconnectionState(1)}
                className={`${connectionState === 1 ? "reportSelected" : ""}`}
              >
                Explore
              </button>
              <button
                onClick={() => setconnectionState(2)}
                className={`${connectionState === 2 ? "reportSelected" : ""}`}
              >
                My Network
              </button>
              <button
                onClick={() => setconnectionState(3)}
                className={`${connectionState === 3 ? "reportSelected" : ""}`}
              >
                Invitations{" "}
              </button>
            </div>
          ) : null}

          <p className="tab_head">SERVICE PAGES</p>

          <Tab
            onClick={() => {
              // seteventOption(!eventOption);
              navigate(`/dashboard/${user.type}?tab=4&ps=1`);
              setEventBool(0);
              setIncubationBool(!incubationBool);
              setEventActiveStep(1);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
              setFinantialBool(false);
              setCoWorkingBool(false);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 9.08366C1 8.97315 1.0439 8.86717 1.12204 8.78903C1.20018 8.71089 1.30616 8.66699 1.41667 8.66699H2.66666C2.77717 8.66699 2.88315 8.71089 2.96129 8.78903C3.03943 8.86717 3.08333 8.97315 3.08333 9.08366V13.6653C3.08333 13.7758 3.03943 13.8818 2.96129 13.9599C2.88315 14.0381 2.77717 14.082 2.66666 14.082H1.41667C1.30616 14.082 1.20018 14.0381 1.12204 13.9599C1.0439 13.8818 1 13.7758 1 13.6653V9.08366ZM7.21457 8.74574C7.21457 8.74574 6.2654 8.56866 5.87707 8.74574C5.5879 8.87741 4.46874 9.27907 3.91666 9.47574V13.1674C4.23624 13.1491 4.87207 13.1153 5.14374 13.1261C6.70873 13.1891 7.50748 13.8486 9.06956 13.967C9.94331 14.0332 10.7087 14.1878 11.3129 13.967C11.9171 13.7457 15.3679 12.0645 15.7991 11.622C16.2308 11.1795 16.015 9.98449 14.6775 10.2057C13.34 10.427 11.5716 11.4891 10.8383 11.4449C10.105 11.4007 8.16331 11.0466 8.16331 11.0466L10.3925 11.0737C10.3925 11.0737 10.7075 11.0903 11.14 10.7812C11.5733 10.472 11.96 9.58657 11.3129 9.58657C10.6658 9.58657 9.97539 9.36491 9.97539 9.36491L7.21457 8.74574Z" />
                    <path d="M10.2 6.05556V5.66667H7.404L7.4 7.22222C7.4 7.65389 7.756 8 8.2 8H13.8C14.244 8 14.6 7.65389 14.6 7.22222V5.66667H11.8V6.05556H10.2ZM14.2 2.55556H12.596V1.77778L11.796 1H10.196L9.396 1.77778V2.55556H7.8C7.36 2.55556 7 2.90556 7 3.33333V4.5C7 4.93167 7.356 5.27778 7.8 5.27778H10.2V4.5H11.8V5.27778H14.2C14.64 5.27778 15 4.92778 15 4.5V3.33333C15 2.90556 14.64 2.55556 14.2 2.55556ZM11.8 2.55556H10.2V1.77778H11.8V2.55556Z" />
                  </svg>
                </div>
                <p> Incubation Tool </p>
              </div>
            }
            {...a11yProps(4)}
          />
          {incubationBool ? (
            <div className="event_dashboard_02">
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 1 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(1);
                  navigate(`/dashboard/${user.type}?tab=4&ps=1`);
                }}
              >
                View Programs
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 2 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(2);
                  navigate(`/dashboard/${user.type}?tab=4&ps=2`);
                }}
              >
                Add Programs
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(3);
                  navigate(`/dashboard/${user.type}?tab=4&ps=3`);
                }}
              >
                Analytics
              </button>
              {/* <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(3);
                  navigate(`/dashboard/${user.type}?tab=7&ps=3`);
                }}
              >
                Active Programs
              </button> */}
            </div>
          ) : null}

          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=5&cs=1`);
              setEventBool(0);
              setIncubationBool(false);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
              setCoWorkingBool(!coworkingBool);
              setCoWorkingStep(1);
              setFinantialBool(false);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill=""
                      d="M16.764 16.086c.55 0 .957-.428.957-.957a.943.943 0 00-.957-.957.97.97 0 00-.957.957c0 .529.45.957.957.957zM16.551 16.469c-.39 0-.763.107-1.085.301-.276.097-.845.835-.804.874l-.868 1.615c-.15.28-.067.627.232.777.28.151.627.046.777-.232l.622-1.194v.921c0 .424.363.766.765.766h1.148a.751.751 0 00.765-.766v-.92l.665 1.193a.574.574 0 001.009-.545l-.904-1.682a2.109 2.109 0 00-1.853-1.108h-.469z"
                    ></path>
                    <path
                      fill=""
                      d="M10.313 13.134h-2.25a.563.563 0 01-.563-.563v-2.252a.563.563 0 01.563-.563h2.25a.562.562 0 01.562.563v1.306c.39-.453.95-.743 1.575-.743h4.814l.234-.265a.582.582 0 00.127-.334.574.574 0 00-.167-.373l-2.083-1.843V4.689a.563.563 0 00-.563-.563h-1.124a.562.562 0 00-.563.563v1.383l-3.111-2.758A1.433 1.433 0 009.189 3c-.26 0-.63.14-.825.314L.918 9.91a.568.568 0 00-.168.373c.006.12.05.236.125.33l.66.75c.1.1.234.16.375.168a.58.58 0 00.329-.123L3 10.737v5.774a1.127 1.127 0 001.125 1.126h6.188v-4.503zm12.562 5.63h-1.313v-5.63c0-.622-.453-1.126-1.012-1.126h-8.1c-.56 0-1.012.504-1.012 1.126v5.63h-1.313a.375.375 0 00-.375.375v.375a1.507 1.507 0 001.498 1.501h10.504a1.505 1.505 0 001.498-1.501v-.375a.375.375 0 00-.375-.376zm-3 0h-6.75v-5.067h6.75v5.066z"
                    ></path>
                  </svg>
                </div>
                <p> Co-working </p>
              </div>
            }
            {...a11yProps(5)}
          />
          {coworkingBool ? (
            <div className="event_dashboard_02">
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  coworkingStep === 1 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setCoWorkingStep(1);
                  navigate(`/dashboard/${user.type}?tab=5&cs=1`);
                }}
              >
                Analytics
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  coworkingStep === 2 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setCoWorkingStep(2);
                  navigate(`/dashboard/${user.type}?tab=5&cs=2`);
                }}
              >
                My Leases
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  coworkingStep === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setCoWorkingStep(3);
                  navigate(`/dashboard/${user.type}?tab=5&cs=3`);
                }}
              >
                My Real Estate
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  coworkingStep === 4 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setCoWorkingStep(4);
                  navigate(`/dashboard/${user.type}?tab=5&cs=4`);
                }}
              >
                Real Estate Requests
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  coworkingStep === 5 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setCoWorkingStep(5);
                  navigate(`/dashboard/${user.type}?tab=5&cs=5`);
                }}
              >
                My Assets
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  coworkingStep === 6 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setCoWorkingStep(6);
                  navigate(`/dashboard/${user.type}?tab=5&cs=6`);
                }}
              >
                Asset Requests
              </button>
            </div>
          ) : null}

          <Tab
            onClick={() => {
              seteventOption(!eventOption);
              navigate(`/dashboard/${user.type}?tab=6`);
              setIncubationBool(0);
              setEventBool(!EventBool);
              seteventState(1);
              setReportState(false);
              setconnectionState(0);
              setmentorState(0);
              setCoWorkingBool(false);
              setFinantialBool(false);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="20"
                    fill="currentColor"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 6.79976C10.4244 6.79976 10.8313 6.96832 11.1314 7.26836C11.4314 7.56841 11.6 7.97535 11.6 8.39968V11.5995C11.6 12.0238 11.4314 12.4308 11.1314 12.7308C10.8313 13.0309 10.4244 13.1994 10 13.1994H7.6C7.17566 13.1994 6.76869 13.0309 6.46863 12.7308C6.16858 12.4308 6.00001 12.0238 6.00001 11.5995V8.39968C6.00001 7.97535 6.16858 7.56841 6.46863 7.26836C6.76869 6.96832 7.17566 6.79976 7.6 6.79976H10ZM12.4 10.9292V9.07004L13.7872 7.75651C13.8724 7.67577 13.9793 7.62178 14.0949 7.60122C14.2104 7.58067 14.3295 7.59444 14.4372 7.64084C14.545 7.68724 14.6369 7.76423 14.7013 7.86228C14.7658 7.96033 14.8001 8.07514 14.8 8.19249V11.8067C14.8001 11.9241 14.7658 12.0389 14.7013 12.1369C14.6369 12.235 14.545 12.312 14.4372 12.3584C14.3295 12.4048 14.2104 12.4185 14.0949 12.398C13.9793 12.3774 13.8724 12.3234 13.7872 12.2427L12.4 10.9292V10.9292ZM18 9.9996C18 5.58142 14.4184 2 10 2C5.58161 2 2.00001 5.58142 2.00001 9.9996C1.99834 11.2779 2.30429 12.5378 2.89201 13.673L2.03761 16.7313C1.98995 16.902 1.98855 17.0824 2.03355 17.2539C2.07855 17.4253 2.16833 17.5818 2.29369 17.7071C2.41906 17.8325 2.5755 17.9223 2.74699 17.9673C2.91847 18.0123 3.09884 18.0109 3.26961 17.9632L6.33041 17.1096C7.46466 17.6959 8.7232 18.001 10 17.9992C14.4184 17.9992 18 14.4178 18 9.9996ZM3.20001 9.9996C3.20044 8.50745 3.69171 7.05687 4.59807 5.87151C5.50444 4.68614 6.77557 3.83181 8.21549 3.44023C9.65542 3.04865 11.1842 3.14156 12.5661 3.70464C13.948 4.26772 15.1063 5.2697 15.8624 6.55611C16.6185 7.84251 16.9305 9.34191 16.7502 10.8231C16.5699 12.3043 15.9073 13.6851 14.8647 14.7526C13.8221 15.8201 12.4572 16.515 10.9806 16.7302C9.50396 16.9453 7.99755 16.6688 6.69361 15.9433L6.47761 15.8233L3.28881 16.7121L4.17921 13.525L4.05841 13.309C3.49367 12.2976 3.1981 11.158 3.20001 9.9996V9.9996Z" />
                  </svg>
                </div>
                <p>Events & Networking</p>
              </div>
            }
            {...a11yProps(6)}
          />
          {EventBool ? (
            <div className="event_dashboard_02">
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventState === 1 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => seteventState(1)}
              >
                My Events
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventState === 2 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => seteventState(2)}
              >
                Create Events
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventState === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => seteventState(3)}
              >
                Explore Events
              </button>
              {/* <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${eventState === 4 ? "event_dashboard_btn_01_selected" : ""
                  }`}
                onClick={() => seteventState(4)}
              >
                My Invitations
              </button> */}
            </div>
          ) : null}

          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=7&mm=1`);
              setEventBool(0);
              setIncubationBool(false);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
              setCoWorkingBool(false);
              setFinantialBool(false);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.356 8.355h7.289v1.37h.71v-2.08h-4V6.221h-.71v1.422h-4v2.08h.71V8.355zM6.222 10.222H1.778a.889.889 0 00-.89.89v2.666c0 .49.399.889.89.889h4.444c.49 0 .889-.398.889-.89v-2.666a.889.889 0 00-.889-.889zM14.222 10.222H9.778a.889.889 0 00-.89.89v2.666c0 .49.399.889.89.889h4.444c.49 0 .889-.398.889-.89v-2.666a.889.889 0 00-.889-.889zM10.222 1.333H5.778a.889.889 0 00-.89.89v2.666c0 .491.399.889.89.889h4.444c.49 0 .889-.398.889-.889V2.222a.889.889 0 00-.889-.889z"></path>
                  </svg>
                </div>
                <p> Membership </p>
              </div>
            }
            {...a11yProps(7)}
          />

          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=8&ft=1`);
              setEventBool(0);
              setIncubationBool(false);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
              setCoWorkingBool(false);
              setFinantialBool(!finantialBool);
              setFinantialStep(1);
              setLeadStep(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.375 2.176c.235-.235.615-.235.828 0l1.6 1.6A.54.54 0 0116 4.2a.47.47 0 01-.197.402l-1.6 1.6c-.213.255-.593.255-.828 0-.232-.212-.232-.592 0-.827l.578-.598H9.6c-.332 0-.6-.247-.6-.577a.6.6 0 01.6-.6l4.353-.022-.578-.554a.603.603 0 010-.848z"></path>
                    <path d="M2.603 11.4l-.554.598 4.329-.023c.354 0 .6.29.6.6 0 .353-.245.6-.6.6l-4.33.023.554.575c.256.235.256.615 0 .827-.212.255-.592.255-.826 0L.176 13C.063 12.91 0 12.758 0 12.575c0-.137.063-.29.176-.402l1.6-1.6c.234-.233.614-.233.827 0 .254.235.254.615 0 .827z"></path>
                    <path
                      d="M8.447 3.602h-6.07c-.86 0-1.6.716-1.6 1.6v5.362l.504-.482a1.301 1.301 0 011.984.172c.362.235.622.61.707 1.048l2.405-.023c.74 0 1.3.605 1.3 1.3 0 .24-.03.443-.125.623H13.6c.882 0 1.6-.718 1.6-1.6V6.239l-.48.483a1.302 1.302 0 01-1.985-.173 1.6 1.6 0 01-.708-1.07H9.6c-.718 0-1.3-.56-1.3-1.3 0-.194.052-.398.147-.577zm-4.47 1.6c0 .882-.695 1.6-1.6 1.6v-1.6h1.6zm9.623 4.8v1.6H12c0-.883.717-1.6 1.6-1.6zm-3.2-1.6c0 1.325-1.075 2.4-2.423 2.4a2.4 2.4 0 01-2.4-2.4c0-1.348 1.075-2.4 2.4-2.4 1.348 0 2.423 1.052 2.423 2.4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <p> Finance </p>
              </div>
            }
            {...a11yProps(8)}
          />
          {finantialBool ? (
            <div className="event_dashboard_02">
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  finantialStep === 1 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setFinantialStep(1);
                  navigate(`/dashboard/${user.type}?tab=8&ft=1`);
                }}
              >
                Insights
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  finantialStep === 2 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setFinantialStep(2);
                  navigate(`/dashboard/${user.type}?tab=8&ft=2&in=0`);
                }}
              >
                Invoicing
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  finantialStep === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setFinantialStep(3);
                  navigate(`/dashboard/${user.type}?tab=8&ft=3`);
                }}
              >
                Payment Requests
              </button>

              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  finantialStep === 4 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setFinantialStep(4);
                  navigate(`/dashboard/${user.type}?tab=8&ft=4`);
                }}
              >
                Transactions
              </button>
            </div>
          ) : null}

          <Tab
            className="hidden"
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=9`);
              setEventBool(0);
              setIncubationBool(false);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
              setCoWorkingBool(false);
              setFinantialBool(false);
              setFinantialStep(0);
              setLeadStep(true);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="14"
                    fill="none"
                    viewBox="0 0 11 14"
                  >
                    <path d="M3.929 1.5c0-.828.527-1.5 1.178-1.5h.786c.65 0 1.178.672 1.178 1.5v11c0 .828-.527 1.5-1.178 1.5h-.786c-.65 0-1.178-.672-1.178-1.5v-11zM0 7.5C0 6.672.528 6 1.179 6h.785c.651 0 1.179.672 1.179 1.5v5c0 .828-.528 1.5-1.179 1.5H1.18C.528 14 0 13.328 0 12.5v-5zM9.036 2h.785C10.472 2 11 2.672 11 3.5v9c0 .828-.528 1.5-1.179 1.5h-.785c-.651 0-1.179-.672-1.179-1.5v-9c0-.828.528-1.5 1.179-1.5z"></path>
                  </svg>
                </div>
                <p> Lead Generation </p>
              </div>
            }
            {...a11yProps(9)}
          />

          <p className="tab_head">ACCOUNT PAGES</p>
          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=11`);
              setIncubationBool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
              setFinantialBool(false);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                </div>
                <p>Profile</p>
              </div>
            }
            {...a11yProps(11)}
          />
        </Tabs>

        <DashboardNav />

        <TabPanel value={value} className="dashboard01_tabpanel" index={1}>
          <IncHome
            incubationBool={setIncubationBool}
            setconnectionState={setconnectionState}
            setActiveStep={setActiveStep}
            seteventState={seteventState}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={2}>
          {connectionState === 1 ? (
            <Connections />
          ) : connectionState === 2 ? (
            <MyConnections />
          ) : connectionState === 3 ? (
            <Requests />
          ) : null}
        </TabPanel>

        <TabPanel
          value={value}
          className="dashboard01_tabpanel"
          index={3}
        ></TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={3}>
          <Messages />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={4}>
          <IncubationTool
            setEventActiveStep={setEventActiveStep}
            eventActiveStep={eventActiveStep}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={5}>
          <CoworkingMain
            coworkingStep={coworkingStep}
            setCoWorkingStep={setCoWorkingStep}
            setCoWorkingBool={setCoWorkingBool}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={6}>
          {eventState === 1 ? (
            <Myevents seteventState={seteventState} />
          ) : eventState === 2 ? (
            <EventsForm seteventState={seteventState} />
          ) : eventState === 3 ? (
            <Event />
          ) : null}
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={7}>
          <Membership />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={8}>
          <FinanceMain
            finantialStep={finantialStep}
            setFinantialStep={setFinantialStep}
            setFinantialBool={setFinantialBool}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={9}>
          <LeadGeneration />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
          <Profile />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={1158}>
          <Messages />
        </TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={1651}>
          <ConnectionProfile setconnectionState={setconnectionState} />
        </TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={1920}>
          <Feed />
        </TabPanel>
        {/* <TabPanel value={value} className="dashboard01_tabpanel" index={10}>
        <Myevents />
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
        <EventsForm />
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={12}>
        <Events />
      </TabPanel>
      <TabPanel
        value={value}
        className="dashboard01_tabpanel"
        index={13}
      ></TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={23}>
        <MyIncubatorPrograms />
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
         <CreateProgram  /> 
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={30}>
      </TabPanel> */}
      </Box>
    );
  }
}
