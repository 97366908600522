import React, { useState } from "react";
import "./index.css";

import Step1 from "./Regsteps/Step1";
import Step2 from "./Regsteps/Step2";
import Step3 from "./Regsteps/Step3";
import { toast } from "react-toastify";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../Utils/Auth";
import Navbar from "../../../Layout/Navbar/Navbar";
import NotFoundPage from "./../../404Pages";
import industries from "../../Lists/Industries2.json";

const Edit_Sp = () => {
  const { user, profile, getUser } = useAuth();
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step1
            country={country}
            setCountry={setCountry}
            state={state}
            setstate={setstate}
            city={city}
            setcity={setcity}
            states={states}
            cities={cities}
            setstates={setstates}
            setcities={setcities}
            setIncorporationDate={setIncorporationDate}
            IncorporationDate={IncorporationDate}
            companyWebsite={companyWebsite}
            setcompanyWebsite={setcompanyWebsite}
            companylinkedin={companylinkedin}
            setcompanylinkedin={setcompanylinkedin}
            description={shortDescription}
            setDescription={setDescription}
            name={CompanyName}
            setName={setCompanyName}
            localprofile={profileDetails}
            setProfile={setProfile}
            setCompanyLogoUrl={setCompanyLogoUrl}
            // activeStep={activeStep}
            companyLogo={companyLogo}
            // // setActiveStep={setActiveStep}
            industry={industry}
            setIndustry={setIndustry}
            otherIndustry={otherIndustry}
            setOtherIndustry={setOtherIndustry}
          />
        );

      case 1:
        return (
          <Step2
          initial={initial}
          setinitial={setinitial}
            setValuation={setValuation}
            CurrentEvaluation={CurrentEvaluation}
            localprofile={profileDetails}
            setProfile={setProfile}
            setOtherGrowth={setOtherGrowth}
            otherGrowth={otherGrowth}
            otherFundingStatus={otherFundingStatus}
            setOtherFundingStatus={setOtherFundingStatus}
            targetAudience={targetAudience}
            setTargetAudience={setTargetAudience}
            NumberOfEmployees={NumberOfEmployees}
            setNumberOfEmployees={setNumberOfEmployees}
            // DocumentsNeed={DocumentsNeed}
            growth={growth}
            FundingStatus={FundingStatus}
            // setCompanyDocuments={setCompanyDocuments}
            setGrowth={setGrowth}
            setFundingStatus={setFundingStatus}
            hideid={hideid}
            revenue={revenue}
            setrevenue={setrevenue}    
            options={options}
            servicesector={servicesector}  
            setservicesector={setservicesector}        
            // // activeStep={activeStep}
            // // setActiveStep={setActiveStep}
          />
        );

      case 2:
        return (
          <Step3
            handleOnSubmit={handleOnSubmit}
            localprofile={profileDetails}
            setProfile={setProfile}
            // phone={phone}
            // setPhone={setPhone}
            linkedProfile={linkedProfile}
            setlinkedProfile={setlinkedProfile}
            // photoId={photoId}
            // setPhotoID={setPhotoID}
            jobTitle={jobTitle}
            setJobTitle={setJobTitle}
            profilePic={profilePic}
            setProfilePicUrl={setProfilePicUrl}
            // // activeStep={activeStep}
            // setActiveStep={setActiveStep}
            // setphoneVerified={setphoneVerified}
            hideid={hideid}
          />
        );
    }
  }

  const navigate = useNavigate();
  const [profileDetails, setProfile] = useState(null);

  const [CompanyName, setCompanyName] = useState("");
  const [shortDescription, setDescription] = useState("");
  const [companyWebsite, setcompanyWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [IncorporationDate, setIncorporationDate] = useState(null);
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [companyLogo, setCompanyLogoUrl] = useState(null);
  // const [DocumentsNeed, setCompanyDocuments] = useState([]);
  const [industry, setIndustry] = useState("");
  const [growth, setGrowth] = useState("");
  const [FundingStatus, setFundingStatus] = useState("");
  const [profilePic, setProfilePicUrl] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  // const [photoId, setPhotoID] = useState("");
  const [linkedProfile, setlinkedProfile] = useState("");
  // const [phone, setPhone] = useState(null);
  const [NumberOfEmployees, setNumberOfEmployees] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [otherFundingStatus, setOtherFundingStatus] = useState("");
  const [otherGrowth, setOtherGrowth] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");


  const [revenue, setrevenue] = useState("");
  const [companylinkedin, setcompanylinkedin] = useState("");
  const [options] = useState(industries);
  const [servicesector, setservicesector] = useState([]);


  const [CurrentEvaluation, setValuation] = useState();

  // const [phoneVerified, setphoneVerified] = useState(false);
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);

  // const [isDisable, setisDisable] = useState(false)

  const handleOnSubmit = async () => {
    if (companyLogo === null) return toast.warning("Upload company logo");
    if (CompanyName.length === 0)
      return toast.warning("Company Name field is empty");
    if (shortDescription.length === 0)
      return toast.warning("Description field is empty");
    if (IncorporationDate === null)
      return toast.warning("Incorporation Date field is empty");
    if (companyWebsite.length === 0)
    return toast.warning("Website field is empty");
  if (industry.length === 0) return toast.warning("Industry field is empty");
  if (revenue.length === 0) return toast.warning("revenue field is empty");
  if (servicesector.length === 0) return toast.warning("servicesector field is empty");

    if (targetAudience.length === 0)
      return toast.warning("Target audience field is empty");
    if (growth.length === 0) return toast.warning("Growth field is empty");
    if (NumberOfEmployees.length === 0)
      return toast.warning("Employees number field is empty");
    if (FundingStatus.length === 0)
      return toast.warning("Funding status field is empty");
    if (jobTitle.length === 0) return toast.warning("Job title field is empty");
    if (linkedProfile.length === 0)
      return toast.warning("Linkedin profile field is empty");
    // if (phone === null) return toast.warning("Phone number field is empty");

    // if (DocumentsNeed.length < 1) {
    //   return toast.warning("Please upload documents", {
    //     position: "bottom-right",
    //   });
    // }
    if(companylinkedin.length === 0)
     return toast.warning("Enter company linkedin url");

    if (profilePic === null) return toast.warning("Upload Profile picture");

    if (country.length === 0) return toast.warning("Enter address");
    if (states && states.length > 0 && (!state || state.length === 0))
      return toast.warning("Select state ");
    else if (cities && cities.length > 0 && (!city || city.length === 0))
      return toast.warning("Select city ");

    // if (!phoneVerified) return toast.warning("Please verify phone number");
    // console.log(phoneVerified);

    // if (!photoId) return toast.warning("Please upload PhotoID");
    // setisDisable(true)
    await Axios({
      method: "post",
      url: "/api/sp/onboard",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: {
        country: country,
        city: city,
        state: state,
        logo: companyLogo,
        website: companyWebsite,
        description: shortDescription,
        fundingSeries:
          FundingStatus === "others" ? otherFundingStatus : FundingStatus,
        incorporationDate: IncorporationDate,
        employees: NumberOfEmployees,
        growth: growth === "others" ? otherGrowth : growth,
        industry: industry === "other" ? otherIndustry : industry,
        audience: targetAudience,
        valuation: CurrentEvaluation,
        linkedin: linkedProfile,
        jobTitle: jobTitle,
        phone: profile.contact.phone,
        companyName: CompanyName,
        profilePic: profilePic,

        revenue,
        servicesector,
        companylinkedin,
      },
    })
      .then((res) => {
        if (res.status === 250) {
          return toast.warning(`Enter valid input in ${res.data.path}`, {
            position: "bottom-right",
          });
        } else if (res.status === 201) {
          toast.success("Service Provider Updated", {
            position: "bottom-right",
          });
          getUser();
          navigate("/dashboard/serviceprovider?tab=9");
        } else return toast.error("Something went wrong!");
        // setisDisable(false)
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
        // setisDisable(false)
      });
  };
  const [hideid, sethideid] = useState(false);
  const [initial,setinitial] = useState(true);

  React.useEffect(() => {
    // console.log(profile);
    if (profile) {
      sethideid(true);
      if (profile.companyName) {
        setCompanyName(profile.companyName);
      }
      if (profile.logo) {
        setCompanyLogoUrl(profile.logo);
      }
      if (profile.profilePic) {
        setProfilePicUrl(profile.profilePic);
      }
      if (profile.website) {
        setcompanyWebsite(profile.website);
      }
      if (profile.description) {
        setDescription(profile.description);
      }
      if (profile.fundingSeries) {
        setFundingStatus(profile.fundingSeries);
      }
      if (profile.growth) {
        setGrowth(profile.growth);
      }
      if (profile.employees) {
        setNumberOfEmployees(profile.employees);
      }
      if (profile.revenue) {
        setrevenue(profile.revenue);
      }
      if(profile.servicesector)
      {
        setservicesector(profile.servicesector);
      }
      if (profile.incorporationDate) {
        setIncorporationDate(profile.incorporationDate);
      }
      if (profile.industry) {
        setIndustry(profile.industry);
      }
      if (profile.audience) {
        setTargetAudience(profile.audience);
      }
      if (profile.contact.jobTitle) {
        setJobTitle(profile.contact.jobTitle);
      }
      // if (profile.contact.phone) {
      //   setPhone(profile.contact.phone);
      //   setphoneVerified(true);
      // }
      if (profile.socialConnects.companylinkedin) {
        setcompanylinkedin(profile.socialConnects.companylinkedin);
      }
      if (profile.socialConnects.linkedin) {
        setlinkedProfile(profile.socialConnects.linkedin);
      }
      if (profile.location.country) {
        setCountry(profile.location.country);
      }
      if (profile.location.state) {
        setstate(profile.location.state);
      }
      if (profile.location.city) {
        setstate(profile.location.city);
      }
    }
  }, [profile]);

  if (!user) return <NotFoundPage />;

  return (
    <>
      <div className="regis">
        <Navbar />

        <div className="register_page">
          {/* -------------STEP 1------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-head w-100">
                    COMPANY DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                    About your company
                  </p>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead w-100">
                    DOMAIN DETAILS 
                  </a>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                    PERSONAL DETAILS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part1">{getStepContent(0)}</div>
              </div>
            </div>
          </div>
          {/* -------------STEP 2------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  
                  <div  className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead-active">
                    COMPANY DOMAIN DETAILS
                  </a>
                  </div>
                  <div  className="mb-16">
                  <a href="#part2" className="register_page_sideborder-head">
                    DOMAIN DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead m-0">
                    About your company domain
                  </p>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-sidehead">
                    PERSONAL DETAILS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part2">{getStepContent(1)}</div>
              </div>
            </div>
          </div>
          {/* -------------STEP 3------------------ */}

          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div  className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead-active">
                    COMPANY DOMAIN DETAILS
                  </a>
                  </div>
                  <div  className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead">
                    DOMAIN DETAILS
                  </a>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-head">
                    PERSONAL DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead m-0">
                    About yourself
                  </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part3">{getStepContent(2)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit_Sp;
