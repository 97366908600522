import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router";
import axios from "axios";
import "./ShareEvents.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../Utils/Auth";
import calendar from "./../../../Assets/images/events/calendar.png";
import clock from "./../../../Assets/images/events/clock.png";
import amount from "./../../../Assets/images/events/amount.png";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import Loader from "../../../Components/Loader";
// import { useNavigate } from "react-router-dom";
// import { KeyboardArrowLeft } from "@mui/icons-material";
import moment from "moment-timezone";
// import { CopyToClipboard } from 'react-copy-to-clipboard';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ShareEvents = () => {
  // const { id_event: event_id } = useParams();
  const {event_id,event_key} = useParams()
  const navigate = useNavigate()
  const key = event_id
//   const { eventId } = props;
//   const key = event_id ? event_id : eventId;
  const [event, setevent] = useState(null);
  const { setSessionExpire, user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => setOpen(false);

  const fetchEvent = async () => {
    setLoader(true)
    await axios({
      method: "get",
      url: `/api/events/event/public/${key}`,
    })
      .then((res) => {
        setevent(res.data.data);
        setLoader(false)
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        if (error.response.status === 401) {
          setSessionExpire(true);
        }
        setLoader(false)
      });
  };

const handlePublicRegister = () => {
  if(user === null){
    const url = `https://incubation.zefyron.com/signup?event_id=${event_key}`
    window.location = (url)
  }else if(user){
    navigate(`/dashboard/${user.type}?tab=6&event=${event_key}&event-state=${1}`)
  }
 
}

  const get_Date = (date) => {
    let d = new Date(date);
    return d.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const get_time = (date, time, timezone) => {
    let datetime = date + "T" + time;
    var t = new Date(datetime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return t + " " + timezone.split(" ", 3)[0];
  };

  useEffect(() => {
    fetchEvent();
  }, [key]);

  if (event === null || event.event===null) return <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}><Loader /></div>;
  // return (
  //   <>
  //     <h1>{event.event.title}</h1>
  //     <p>{event.event.description}</p>
  //   </>
  // );
  else
    return (
      <div style={{backgroundColor:"#F8F9FA"}}>
     
        <div className="container py-5" >
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="event-page-box p-5">
                <div className="event-box-pill">
                  {event?.keywords.map((obj, ind) => {
                    return (
                      <span
                        key={ind}
                        style={{ marginRight: "5px" }}
                        className="badge badge-pill badge-light"
                      >
                        {obj}
                      </span>
                    );
                  })}
                  {/* <span className="badge badge-pill badge-light">Cryptocurrency</span> */}
                </div>
                <h4 className="event-box-head mt-3 mb-3">
                  {event?.title}
                </h4>
                <div className="event-box-organise">Organised by</div>
                <div className="event-box-image mt-3">
                  <img
                    src={event?.hostId && event?.hostId.avatar ? event?.hostId.avatar:null}
                    alt="logo"
                    className="mr-3"
                  />
                  {event?.hostCompany}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="event-banner">
                <img src={event?.thumbnail} alt="banner" />
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-5">
            
            <div className="col-12 col-md-8 w-100 mt-5">
              <div className="event-main p-3">
                <p className="event-main-head">About event</p>
                <div className="event-main-line"></div>
                <p className="event-main-text">{event?.description}</p>
                <p className="event-main-sidehead">Objectives</p>
                <div className="ml-4">
                  <ul>
                    {event?.objectives.map((obj, ind) => {
                      return (
                        <li key={ind} className="mb-3">
                          {obj}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className="event-main-sidehead">Criteria</p>
                <div>
                  <ol>
                    {event?.criteria.map((obj, ind) => {
                      return (
                        <li key={ind} className="mb-3">
                          {obj}
                        </li>
                      );
                    })}
                  </ol>
                </div>
                {/* <p className="event-main-sidehead">Documents</p> */}
                <div>
                  {/* <ol>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                    </ol>
                    */}
                </div>
                {/*  <p className="event-main-sidehead">Participants</p>
                   <AvatarGroup max={4} style={{justifyContent: "flex-end"}}>
                    
                      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                      <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                      <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                      <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                      <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                    </AvatarGroup>  */}
              </div>
            </div>
            <div className="col-12 col-md-4 mt-5">
              <div className="event-page-detail p-5">
                <div className="event-page-detail-section">
                  <img src={calendar} alt="calendar" />
                  <span>{moment.utc(`${event?.eventTime.date}`).format('DD/MM/YYYY')}</span>
                </div>
                <div className="event-page-detail-section">
                  <img src={clock} alt="clock" />
                  <span>
                    {get_time(
                      event?.eventTime.date,
                      event?.eventTime.time,
                      event?.eventTime.timezone
                    )}
                  </span>
                </div>
                <div className="event-page-detail-section">
                  <img src={amount} alt="amount" />
                  <span>
                    {event.isFree
                      ? "Free"
                      : "EUR " + event?.eventFees}
                  </span>
                </div>
                <div className="event-page-detail-section">
                  <img src={clock} alt="clock" />
                  <span>{event?.eventTime.duration}</span>
                  {/* <span>{event.diff}</span> */}
                </div>

                <div className="mt-3 d-flex justify-content-center">
                <div className="gradient_CTA" style={{cursor:'pointer'}} onClick={handlePublicRegister}>
                  REGISTER NOW
               </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 300,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div className="close-modal-main" style={{position: "absolute",right:"0"}}>
                  <button onClick={handleClose}>
                    <CancelIcon />
                  </button>
                </div>
                <div className="modal-main-text">
                  <div className="modalEvent-title">
                    <h6>EVENT TITLE: {event.title}</h6>
                  </div>
                  <div className="modalEvent-date-details">
                    <div>
                      <BusinessCenterIcon />{" "}
                      <p>{get_Date(event?.eventTime.date)}</p>
                    </div>
                    <div>
                      <WatchLaterIcon />{" "}
                      <p>
                        {get_time(
                          event?.eventTime.date,
                          event?.eventTime.time,
                          event?.eventTime.timezone
                        )}
                      </p>
                    </div>

                    <div>
                      <HourglassFullIcon />
                      <p>{event.diff}</p>
                    </div>
                  </div>
                  <div className="modalEvent-paybleAmount">
                    <p>Total Amount Payable</p>
                    <h5>
                      {" "}
                      {event?.isFree
                        ? "Free"
                        : "EUR " + event?.eventFees}{" "}
                    </h5>
                  </div>
                  {loader ? <></> : null}
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>
    );
};

export default ShareEvents;
