import React, { useState, useEffect } from "react";
import "./analytics.css";
import totalLease from "./../../../../Assets/images/coworking/totalLease.png";
import assets from "./../../../../Assets/images/coworking/assets.png";
import realEstate from "./../../../../Assets/images/coworking/realEstate.png";
// import fundraised from "./../../../../Assets/images/coworking/fundraised.png";
import PercentPiChat from "./Charts/percentPIChart";
import DoubleLineChart from "./Charts/DoubleLineChart";
import DoubleBarchart from "./Charts/DoubleBarChart";
import SimpleLineChart from "./Charts/SimpleLineChart";
import PiChart from "./Charts/PIChart";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../../Utils/Auth";

const Analytics = () => {
  const [totalAsset, setTotalAsset] = useState(0);
  const [totalEstate, setTotalEstate] = useState(0);
  const [totalLeases, setTotalLeases] = useState(0);
  const [totalFund, setTotalFund] = useState(0);
  const [partitionCategory, setPartitionCategory] = useState([]);
  const [spaceCategory, setSpaceCategory] = useState([]);
  const [fund, setFund] = useState([]);
  const [leased, setLeased] = useState([]);
  const [users, setUsers] = useState([]);
  const [assetRise, setAssetRise] = useState([]);
  const [estateRise, setEstateRise] = useState([]);
  const [rentalOcc, setRentalOcc] = useState(0);
  const [seaterOcc, setSeaterOcc] = useState(0);
  const [incubatees, setIncubatees] = useState([]);
  const { setSessionExpire } = useAuth();

  function convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(labelValue));
  }

  const getChartsData = async () => {
    try {
      await Axios({
        url: `/api/incubator-dashboard`,
        method: "get",
      })
        .then((res) => {
          const { data } = res.data;
          setTotalAsset(data.asset_count);
          setTotalEstate(data.estate_count);
          setTotalLeases(data.current_lease);
          setTotalFund(convertToInternationalCurrencySystem(data.total_fund));
          setAssetRise(data.asset_rise);
          setEstateRise(data.estate_rise);
          // setUsers(data.new_user);
          setUsers([
            {
              name: "Jan",
              User: 20,
            },
            {
              name: "Feb",
              User: 30,
            },
            {
              name: "Mar",
              User: 25,
            },
            {
              name: "Apr",
              User: 35,
            },
            {
              name: "May",
              User: 20,
            },
            {
              name: "Jun",
              User: 30,
            },
            {
              name: "Jul",
              User: 20,
            },
            {
              name: "Aug",
              User: 25,
            },
            {
              name: "Sep",
              User: 30,
            },
            {
              name: "Oct",
              User: 30,
            },
            {
              name: "Nov",
              User: 20,
            },
            {
              name: "Dec",
              User: 30,
            },
          ]);
          // setPartitionCategory(data.asset_category_partition);
          setPartitionCategory([
            { name: "Projector", value: 10 },
            { name: "Printer", value: 20 },
          ]);
          // setSpaceCategory(data.estate_category_partition);
          setSpaceCategory([
            { name: "Hall", value: 10 },
            { name: "Cabin", value: 20 },
            { name: "Auditorium", value: 20 },
          ]);
          setFund([
            {
              name: "Jan",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Feb",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Mar",
              Assets: 25,
              Real_Estate: 35,
            },
            {
              name: "Apr",
              Assets: 35,
              Real_Estate: 50,
            },
            {
              name: "May",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Jun",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Jul",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Aug",
              Assets: 25,
              Real_Estate: 35,
            },
            {
              name: "Sep",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Oct",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Nov",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Dec",
              Assets: 30,
              Real_Estate: 25,
            },
          ]);
          // setLeased(data.leased);
          setLeased([
            {
              name: "Jan",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Feb",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Mar",
              Assets: 25,
              Real_Estate: 35,
            },
            {
              name: "Apr",
              Assets: 35,
              Real_Estate: 50,
            },
            {
              name: "May",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Jun",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Jul",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Aug",
              Assets: 25,
              Real_Estate: 35,
            },
            {
              name: "Sep",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Oct",
              Assets: 30,
              Real_Estate: 25,
            },
            {
              name: "Nov",
              Assets: 20,
              Real_Estate: 30,
            },
            {
              name: "Dec",
              Assets: 30,
              Real_Estate: 25,
            },
          ]);
          setIncubatees(data.incubatees_by_sector);
          const assetTotal = data.remaining.asset_remain[0].count;
          const assetbooked = data.remaining.asset_remain[0].booked;
          const assetPer = (assetbooked / assetTotal) * 100;
          setRentalOcc(assetPer.toFixed(1));
          const seaterTotal = data.remaining.estate_remain[0].count;
          const seaterBooked = data.remaining.estate_remain[0].booked;
          const seaterPer = (seaterBooked / seaterTotal) * 100;
          setSeaterOcc(seaterPer.toFixed(1));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };

  useEffect(() => {
    getChartsData();
  }, []);

  return (
    <div className="coworking_incubator_analytics_root">
      <div className="coworking_incubator_analytics_head">
        <div className="row">
          <div className="col-lg-9 col-md-7 col-6">
            <div
              className="w_100 d-flex align-items-center justify-content-center pb-16"
              style={{ borderBottom: "1px solid #E4E4E4" }}
            >
              <p className="cta">Overall Analytical</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-5 col-6">
            <div className="d-flex justify-content-end ">
              <button className="primaryFilled_activeCta w_100">
                EXPORT DATA
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="coworking_incubator_analytics_main mt-40">
        <div className="row m-0 mb-40">
          <div className="col-12 col-md-3 col-lg-3">
            <div className="analytictop-box">
              <div className="row m-0">
                <p className="mb-12 subtitle color_dark_black2">Total Leases</p>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <h3 className="w-fit" style={{ color: "#580167" }}>
                    {totalLeases === 0 ? "5" : totalLease}
                  </h3>
                  <img className="" src={totalLease} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <div className="analytictop-box">
              <div className="row m-0">
                <p className="mb-12 subtitle color_dark_black2">Assests</p>
                <div className="w-100 d-flex justify-content-between align-items-center m-0">
                  <h3 className="w-fit" style={{ color: "#580167" }}>
                    {totalAsset}
                  </h3>
                  <img className="" src={assets} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <div className="analytictop-box">
              <div className="row m-0">
                <p className="mb-12 subtitle color_dark_black2">Real Estates</p>
                <div className="w-100 d-flex justify-content-between align-items-center m-0">
                  <h3 className="w-fit" style={{ color: "#580167" }}>
                    {totalEstate}
                  </h3>
                  <img className="" src={realEstate} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <div className="analytictop-box">
              <div className="row m-0">
                <p className="mb-12 subtitle color_dark_black2">Fund Raised</p>
                <div className="w-100 d-flex justify-content-between align-items-center m-0">
                  <h3
                    className="w-fit"
                    style={{ color: "#580167", fontSize: "2.3rem" }}
                  >
                    {totalFund}
                  </h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="none"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#00BFD7"
                      d="M24 3C12.403 3 3 12.403 3 24s9.403 21 21 21 21-9.403 21-21S35.597 3 24 3zm2.977 17.616c.206 0 .375.168.375.375v1.275a.376.376 0 01-.375.375h-6.375c-.015.206-.015.426-.015.647v1.687h6.385c.206 0 .375.169.375.375v1.275a.376.376 0 01-.375.375h-6.117c.717 2.906 2.873 4.622 6.084 4.622.933 0 1.74-.056 2.428-.192a.373.373 0 01.445.365v2.007a.376.376 0 01-.3.365c-.745.16-1.607.24-2.592.24-5.147 0-8.578-2.757-9.384-7.407h-1.411a.376.376 0 01-.375-.375V25.35c0-.206.169-.375.375-.375h1.223v-1.73c0-.206 0-.412.015-.6h-1.238a.376.376 0 01-.375-.375v-1.275c0-.206.169-.375.375-.375h1.486c.923-4.415 4.312-7.026 9.31-7.026.979 0 1.846.089 2.591.253a.372.372 0 01.296.366v2.006h.005a.372.372 0 01-.45.365c-.69-.136-1.491-.206-2.424-.206-3.066 0-5.175 1.57-5.981 4.238h6.019z"
                      opacity="0.4"
                    ></path>
                  </svg>
                  {/* <img className="" src={fundraised} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coworking_incubator_partition_cat">
          <div className="row m-0 mb-40">
            <div className="col-6">
              <div className="analytics-mainbox">
                <div className="pt-12 pl-24">
                  <p className="body1 color_dark_blue">Partition Categories</p>
                </div>
                <div className="row align-items-center">
                  <PercentPiChat data={partitionCategory} />
                  {/* <PercentPiChat data={corpoindustry} totalcount={corporatesum} /> */}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="analytics-mainbox">
                <div className="pt-12 pl-24">
                  <p className="body1 color_dark_blue">
                    Space Usage By Cluster
                  </p>
                </div>
                <div className="row align-items-center">
                  <PercentPiChat data={spaceCategory} />
                  {/* <PercentPiChat data={investorindustry} totalcount={investorsum} /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-12">
              {/* <div className="analytics-mainbox">
            <p className="body1 analytics-mainbox-head" style={{ color: "#262C5B" }}>Startups</p>
            <Barchart data={startupbyprogram} />
          </div> */}
            </div>
          </div>
        </div>
        <div className="analysis-allPartners-main">
          <p className="body1 color_dark_blue mb-4">
            Funds <span className="small_span">(In thousands)</span>
          </p>
          <div className="row">
            <div className="col-12">
              <DoubleLineChart data={fund} />
            </div>
          </div>
        </div>
        <div className="coworking_incubator_least_mon_root mt-40">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="analytics-mainbox">
                <div className="analytics-mainbox-head">
                  <p className="body1 color_dark_blue">
                    Items on lease monthly{" "}
                    <span className="small_span">(In thousands)</span>
                  </p>
                </div>
                <div className="row align-items-center mt-16">
                  <DoubleBarchart data={leased} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="analytics-mainbox">
                <div className="analytics-mainbox-head">
                  <p className="body1 color_dark_blue">
                    New Users <span className="small_span">(In thousands)</span>
                  </p>
                </div>
                <div className="row align-items-center mt-16">
                  <SimpleLineChart data={users} isEstate={2} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coworking_incubator_least_mon_root mt-40">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="analytics-mainbox">
                <div className="analytics-mainbox-head">
                  <p className="body1 color_dark_blue">Incubatees by Sector</p>
                </div>
                <div className="row align-items-center mt-16">
                  <PiChart data={incubatees} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="row h_100">
                <div className="col-6">
                  <div className="analytics-mainbox d-flex flex-column">
                    <div className="analytics-mainbox-head">
                      <p className="body1 color_dark_blue">Occupancy(Rental)</p>
                    </div>
                    <div className="coworking_incubator_percent">
                      <CircularProgressbar
                        value={rentalOcc}
                        text={`${rentalOcc}%`}
                        minValue={0}
                        maxValue={100}
                        styles={{ background: "red" }}
                        className="rental_per"
                        strokeWidth={5}
                      />
                      ;
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="analytics-mainbox d-flex flex-column">
                    <div className="analytics-mainbox-head">
                      <p className="body1 color_dark_blue">Occupancy(Seater)</p>
                    </div>
                    <div className="coworking_incubator_percent">
                      <CircularProgressbar
                        value={seaterOcc}
                        text={`${seaterOcc}%`}
                        minValue={0}
                        maxValue={100}
                        styles={{ background: "red" }}
                        className="seater_per"
                        strokeWidth={5}
                      />
                      ;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="analysis-allPartners-root mt-40">
          <p className="body1">Asset</p>
          <div className="analysis-allPartners-root">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="analysis-allPartners-main">
                  <p className="body1 color_dark_blue mb-4">
                    {" "}
                    Rise in no. of asset over the months
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <SimpleLineChart data={assetRise} isEstate={0} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-4 col-md-4 col-12">
                <div className="analysis-allPartners-main">
                  <p className="body1 color_dark_blue"> Status</p>
                  
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="analysis-allPartners-root mt-40">
          <p className="body1">Real Estate</p>
          <div className="analysis-allPartners-root">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="analysis-allPartners-main">
                  <p className="body1 color_dark_blue mb-4">
                    {" "}
                    Rise in no. of real estate over the months
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <SimpleLineChart data={estateRise} isEstate={1} />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-4 col-md-4 col-12">
                <div className="analysis-allPartners-main">
                  <p className="body1 color_dark_blue"> Status</p>
                  
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
