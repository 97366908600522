import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import QuestionComp from './QuestionComp'
import { toast } from 'react-toastify'
import Axios from "axios"

const AssesmentEditModule = (props) => {
    const { data, i, addQuestion, showAnsKey,  setAnsKey,assessment,getAssignment,setShowAddQ } = props;
    const [updateQ, setUpdateQ] = useState(false)
    const navigate = useNavigate();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const module_id = query.get('module')
    const course_id = query.get('course')
    const [answer, setAnswer] = useState("")
    const {programid} = useParams()
    const [eQuestion, setEQuestion] = useState(data.question)
    const [eQuestion1, setEQuestion1] = useState(data.options.option_one)
    const [eQuestion2, setEQuestion2] = useState(data.options.option_two)
    const [eQuestion3, setEQuestion3] = useState(data.options.option_three)
    const [eQuestion4, setEQuestion4] = useState(data.options.option_four)
    const [eQuestion5, setEQuestion5] = useState(data.options.option_five)
    const returnCount = (val) => {
        return val ? 1 : 0
    }

    const [count, setCount] = useState([1]);

    const setAnswerKeyVal = (e) => {
        const val = e.target.value;
        if (val === "1") {
            setAnswer(eQuestion1)
        }
        else if (val === "2") {
            setAnswer(eQuestion2)
        }
        else if (val === "3") {
            setAnswer(eQuestion3)
        }
        else if (val === "4") {
            setAnswer(eQuestion4)
        }
        else if (val === "5") {
            setAnswer(eQuestion5)
        }
    }

    useEffect(() => {
        const newVal = returnCount(eQuestion1) + returnCount(eQuestion2) + returnCount(eQuestion3) + returnCount(eQuestion4) + returnCount(eQuestion5);
        for(let i = 1; i<newVal;i++)
        {
            setCount((prev)=>[...prev, 1]);
        }
        // console.log(newVal)
    }, [])

    const updateQuestion = async(e)=>{
        // console.log(answer)
        e.preventDefault();
        if (answer === "") {
            return toast.warning("Please select answer key")
        }
        if (count.length < 2) {
            return toast.warning("At least 2 Question should be there")
        }
        await Axios({
            url: `/api/mentorModules/assessment/update/${assessment._id}/${data._id}`,
            method: "put",
            data: {
                question: eQuestion,
                option_type: "MCQ",
                options: {
                    option_one: eQuestion1,
                    option_two: eQuestion2,
                    option_three: eQuestion3,
                    option_four: eQuestion4,
                    option_five: eQuestion5,
                },
                answer: answer
            }
        }).then(() => {
            getAssignment();
            // setEQuestion("")
            // setEQuestion1("")
            // setEQuestion2("")
            // setEQuestion3("")
            // setEQuestion4("")
            // setEQuestion5("")
            // setAnswer("")
            // setCount([1])
            setAnsKey(false)
            setUpdateQ(false)
            // setAnsKey()
        }).catch((err) => {
            console.log(err)
        })
    }

    return <>
        <div className="assesmentEditHead">
            <div className="assmentEditHeadmain0">
                <div className="assesmentEditLEft">
                    <p className='body1 color_dark_black'>{i + 1}.  {data.question}</p>
                </div>
                <div className="assesmentEditright">
                    <p className='caption color_dark_blue point' onClick={() => {
                        navigate(`/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}&question=${data._id}`)
                        setUpdateQ(!updateQ)
                        setShowAddQ(false)
                    }}>Edit</p>
                </div>
            </div>
            <div className="assmentEditHeadmain">
                {
                    updateQ ? <QuestionComp
                        question={eQuestion}
                        question1={eQuestion1}
                        question2={eQuestion2}
                        question3={eQuestion3}
                        question4={eQuestion4}
                        question5={eQuestion5}
                        setQuestion={setEQuestion}
                        setQuestion1={setEQuestion1}
                        setQuestion2={setEQuestion2}
                        setQuestion3={setEQuestion3}
                        setQuestion4={setEQuestion4}
                        setQuestion5={setEQuestion5}
                        count={count}
                        setCount={setCount}
                        setAnsKey={setAnsKey}
                        showAnsKey={showAnsKey}
                        addQuestion={addQuestion}
                        setAnswerKeyVal={setAnswerKeyVal}
                        pos={i}
                        updateQ={updateQ}
                        updateQuestion={updateQuestion}
                    /> : null
                }
            </div>

        </div>
    </>
}

export default AssesmentEditModule