import React, { useEffect, useState } from 'react';
import iAsset from "../../../../../Assets/images/financial/iAsset.png"
import footerLogo from "../../../../../Assets/images/financial/footerLogo.png"

// import '../invoicePdf.css';
import Pdf from "react-to-pdf"
import { KeyboardBackspace } from "@mui/icons-material"
import { useNavigate } from 'react-router-dom';
import Query from '../../../../../Utils/Query/Query';
import { useAuth } from '../../../../../Utils/Auth';
import Axios from 'axios'

const ref = React.createRef();

const ViewInvoicing = ({ setTransactionStep }) => {
    const navigate = useNavigate()
    const query = Query()
    const { setSessionExpire } = useAuth()
    const invoiceId = query.get('transaction');
    const incubtaorId = query.get('inc');
    const [data, setData] = useState(null)
    const [incDetails, setIncDetails] = useState([])

    const getInvoice = async () => {
        await Axios({
            url: `/api/transaction/particular/${invoiceId}`,
            method: "GET",
        }).then((res) => {
            setData(res.data.data)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true)
            }
        })

    }

    const getAccountDetails = async () => {
        await Axios({
            url: `/api/account-details/user/${incubtaorId}`,
            method: "get",
        }).then(res => {
            setIncDetails(res.data.data)
        })
    }


    useEffect(() => {
        getAccountDetails();
        if (invoiceId) {
            getInvoice()
        }
    }, [invoiceId])

    return (
        <>
            {
                data ?
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button className='coworking_back_btn btncta' onClick={() => {
                                setTransactionStep(0)
                                navigate(-1)
                            }}>
                                <span><KeyboardBackspace /></span>
                                <span>BACK</span>
                            </button>
                            <Pdf targetRef={ref} filename="invoice-preview.pdf" x={0} y={0} scale={1}>
                                {({ toPdf }) => <button onClick={toPdf} className="primaryOutlined_activeCta">Generate Pdf</button>}
                            </Pdf>
                        </div>

                        <div ref={ref} className="incbator_invoice_pdf_root mt-40">
                            <div className="incbator_invoice_pdf_head">
                                <h5>INVOICE</h5>
                                <div>
                                    <p className="overline"> Date: {new Date(data.createdAt).toLocaleDateString('en-GB')}</p>
                                    {/* <p className="caption"> Invoice No: #2222</p> */}
                                </div>
                            </div>
                            <div className="incbator_invoice_pdf_main">
                                <div className="incbator_invoice_pdf_main_billto p-24">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="incbator_invoice_pdf_main_payto">
                                                <p className="body1">Bill to</p>
                                                <p className="overline mt-8">
                                                    {data.paid_by.name}
                                                    {/* <br />
                                                    40 Red house ,
                                                    <br />
                                                    My Kingdom, UK */}
                                                </p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline">{data.paid_by.email}</p>
                                                </div>
                                                {/* <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline"></p>
                                                </div> */}

                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="incbator_invoice_pdf_main_payto">
                                                <p className="body1">Pay to</p>
                                                <p className="overline mt-8">
                                                    {data.paid_to.name}
                                                    <br />
                                                    {incDetails.billing_address.address}
                                                    <br />
                                                    {incDetails.billing_address.city},{incDetails.billing_address.state},{incDetails.billing_address.pinCode}
                                                </p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline">{data.paid_to.email}</p>
                                                </div>
                                                {
                                                incDetails.billing_address.gstNumber && <div className='d-flex align-items-center'>
                                                <span className='pdf-email-bg'></span>
            
                                                <p className="overline">{incDetails.billing_address.gstNumber}</p>
                                            </div>
                                                }
                                                
                                                {/* <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline">jhskdjfa@gmai.com</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="incbator_invoice_pdf_main_payto">
                                                <div className='d-flex align-items-center'>
                                                    <img src={iAsset} alt="twf-finance" />
                                                    <p className="body1 color_dark_blue ml-10 caps">Program</p>
                                                </div>
                                                {/* <p className="overline mt-8">
                                                    {data.short_description}
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="incbator_invoice_pdf_table">
                                    <table className='w_100'>
                                        <thead>
                                            <tr>
                                                <td className='body2'>SL</td>
                                                <td className='body2'>Name</td>
                                                {/* <td className='body2'>Description</td> */}
                                                <td className='body2'>Rate</td>
                                                <td className='body2'>Qty.</td>
                                                <td className='body2'>Amount</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td className='overline'>1</td>
                                            <td className='overline'>{data.program_id.title}</td>
                                            <td className='overline'>{data.program_id.participationAmmount}</td>
                                            <td className='overline'>1</td>
                                            <td className='overline'>{Number(data.program_id.participationAmmount)*1}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="incbator_invoice_pdf_footer_main p-24">
                                    <div className="incbator_invoice_pdf_pay_info mt-40">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="body2">Payment Info:</p>
                                                <div className="incbator_invoice_pdf_pay_table mt-16">
                                                    <table>
                                                        <tr>
                                                            <td className='caption'>Paid via</td>
                                                            <td className='overline'>Razor pay</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption'>Payment id</td>
                                                            <td className='overline'>{data.source_id}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption'>Address</td>
                                                            <td className='overline'>{data.billingAdd.address}, {data.billingAdd.city}, {data.billingAdd.state},{data.billingAdd.pinCode} </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption'>GST Number</td>
                                                            <td className='overline'>{data.billingAdd.gstNumber}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="incbator_invoice_pdf_pay_total">
                                                    <div className='incbator_invoice_pdf_pay_subtotal'>
                                                        <p className="body2">SubTotal</p>
                                                        <p className="overline">{data.program_id.participationAmmount}</p>
                                                    </div>
                                                    {/* <div className='incbator_invoice_pdf_pay_subtotal'>
                                                        <p className="body2">Tax({data.tax ? data.tax : ""} {data.tax_value}%) </p>
                                                        <p className="overline">+{data.tax_amount}</p>
                                                    </div> */}
                                                    {/* <div className='incbator_invoice_pdf_pay_subtotal incbator_invoice_pdf_pay_discount'>
                                                        <p className="body2">Discount{data.discount === "percent" ? ` (${data.discount_value}%)`:``}</p>
                                                        <p className="overline">-{data.discount_amount}</p>
                                                    </div> */}
                                                    <div className='incbator_invoice_pdf_pay'>
                                                        <p className="body1">Total</p>
                                                        <p className="body1">Euro {data.program_id.participationAmmount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="incbator_invoice_pdf_footer">
                                        <p className="overline">Powered by</p>
                                        <img src={footerLogo} alt="twf Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div>Loading...</div>
            }
        </>
    )
}

export default ViewInvoicing