import React from 'react'
// import { KeyboardArrowDown } from '@mui/icons-material';

const QuestionComp = (props) => {
    const { question, setQuestion, question1, question2, question3, question4, question5, setQuestion1, setQuestion2, setQuestion3, setQuestion4, setQuestion5, count, setCount, addQuestion, setAnswerKeyVal, updateQ, updateQuestion,setAnsKey } = props;
    return <>
        <div className="col-12 mt-3">
            <div className="createAssessment_form_divMain">

                <div className="createAssignment_form_divQuestions">
                    <label htmlFor="Module 1" className='caption color_dark_blue'>Question</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Write a question'
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </div>
                <div className="createAssignment_form_divAnswers">
                    <div className="createAssignment_form_divAnswer">
                        <p className='body1'>1.</p>
                        <input
                            type="text"
                            name=""
                            id=""
                            className='body1'
                            placeholder='Option 1'
                            value={question1}
                            onChange={(e) => setQuestion1(e.target.value)}
                        />
                    </div>
                    {
                        count.length >= 2 ? <div className="createAssignment_form_divAnswer">
                            <p className='body1'>2.</p>
                            <input
                                type="text"
                                name=""
                                id=""
                                className='body1'
                                placeholder='Option 2'
                                value={question2}
                                onChange={(e) => setQuestion2(e.target.value)}
                            />
                        </div> : null
                    }
                    {
                        count.length >= 3 ? <div className="createAssignment_form_divAnswer">
                            <p className='body1'>3.</p>
                            <input
                                type="text"
                                name=""
                                id=""
                                className='body1'
                                placeholder='Option 3'
                                value={question3}
                                onChange={(e) => setQuestion3(e.target.value)}
                            />
                        </div> : null
                    }
                    {
                        count.length >= 4 ? <div className="createAssignment_form_divAnswer">
                            <p className='body1'>4.</p>
                            <input
                                type="text"
                                name=""
                                id=""
                                className='body1'
                                placeholder='Option 4'
                                value={question4}
                                onChange={(e) => setQuestion4(e.target.value)}
                            />
                        </div> : null
                    }
                    {
                        count.length >= 5 ? <div className="createAssignment_form_divAnswer">
                            <p className='body1'>5.</p>
                            <input
                                type="text"
                                name=""
                                id=""
                                className='body1'
                                placeholder='Option 5'
                                value={question5}
                                onChange={(e) => setQuestion5(e.target.value)}
                            />
                        </div> : null
                    }

                    {
                        count.length >= 5 ? null : <div className="createAssignment_form_divaddAnswer">
                            {/* <div className="radio_ques"></div> */}
                            <p className='body1 ml-2 point' onClick={() => {
                                setCount((prev) => [...prev, 1])
                            }}>Add Option</p>
                        </div>
                    }

                </div>
                
                <div className="createAssignment_form_divAnswerKey">
                    <select onChange={(e)=>{
                        setAnsKey(false)
                        setAnswerKeyVal(e)
                    }} className="createAssignment_selectAns color_dark_grey body1" onClick={()=>setAnsKey(true)}>
                        <option value="" className='body1 color_dark_grey' >Set Answer</option>
                        {
                            count.map((data, i) => <option value={i + 1} key={i} className='body1 color_dark_grey'>Option {i + 1}</option>)
                        }

                    </select>
                    {/* <KeyboardArrowDown
                        className={`${showAnsKey ? 'rotateAnsKey' : 'rotateAnsKey2'}`}
                    /> */}

                </div>
            </div>
        </div>
        {
            updateQ ? <div className="create_course_form_maindivBtn">
                <button className='primaryFilled_activeCta' onClick={updateQuestion}>Update</button>
            </div> : <div className="col-12 create_course_form_maindivBtn">
                <button className='cta newModuleassessAddBtn' onClick={addQuestion}>+ Add Question </button>
            </div>
        }

    </>
}

export default QuestionComp