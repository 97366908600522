import React, { useEffect, useState,useRef } from "react";
import likeIcon from "../../../Assets/images/icons/likeIcon.png";
import likeFilled from "../../../Assets/images/icons/likeFilled.png";
// import commentIcon from "../../../../Assets/Images/icons/comment icon (1).png";
import commentFilled from "../../../Assets/images/icons/comment.png";
import sendicon from "../../../Assets/images/icons/sendicon.png";
// import SendIcon from "@mui/icons-material/Send";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
// import { toast } from "react-toastify";
import './feed.css'
import groupLike from "../../../Assets/images/icons/groupLike.png"
import Comment from "./Comment";
import Post from "./Post";
// import { SentimentSatisfiedOutlined } from "@mui/icons-material"

const Posts = (props) => {
  const { post, getPosts } = props;
  const [userCommnets, setUserCommnets] = useState([]);
  const [commnentLength, setUserCommentLength] = useState(0);
  const [showAllComment, setShowAllComment] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likeLength, setLikeLength] = useState(0);
  const [comment, setComment] = useState("");
  const { setSessionExpire, user } = useAuth();
  const [firstLike, setLikePerson] = useState(null);
  // const [newComments, setNewCommnets] = useState([]);
  // like related post
  const commentInput = useRef();
  const focusComment = () =>{
    commentInput.current.focus()
  }

  const getLikes = async () => {
    await Axios({
      method: "get",
      url: `/api/post/liked-user/${post._id}`
    })
      .then((res) => {
        const { data } = res;

        setLikeLength(data.data.length);
        setLikePerson(data.data.length > 0 ? data.data[0] : null)

        if (data.data) {
          if (data.data.some((e) => e.byUser._id === user.id)) {
            setIsLiked(true);
          }
        } else {
          setIsLiked(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true)
        }
      });
  }

  const getCommnets = async () => {
    await Axios({
      url: `/api/post/commented-user/${post._id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        // setUserCommnets(data.data ? data.data : userCommnets);
        setUserCommnets(data.data);
        setUserCommentLength(data.data.length);
      })
      .catch((err) => {
        setUserCommnets([]);
        setUserCommentLength(0);

        if (err.response && err.response.status === 401) {

          setSessionExpire(true)
        }
      });
  };

  useEffect(() => {
    if (post._id) {
      getLikes();
      getCommnets()

    }
  }, [post]);


  const createComment = () => {
    getPosts()
    Axios({
      method: "post",
      url: `/api/post/comment/create/${post._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        body: comment,
      },
    })
      .then(() => {
        getPosts()
        getCommnets()
        setComment("");
      })
      .catch((err) => {
        setComment("");
        if (err.response.status === 401) {
          setSessionExpire(true)
        }
      });
  };

  const handleLike = () => {
    setIsLiked(true);
    // setLikeLength(likeLength + 1);
    Axios({
      method: "get",
      url: `/api/post/like/${post._id}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setIsLiked(true);
        getLikes()
        getCommnets()
        // setLikeLength(likeLength);
      })
      .catch((err) => {
        getLikes()
        getCommnets()
        setIsLiked(false);
        // setLikeLength(likeLength - 1);
        if (err.response.status === 401) {
          setSessionExpire(true)
        }
      });
  };

  const handleUnlike = () => {
    // setLikeLength(likeLength - 1);
    setIsLiked(false);
    Axios({
      method: "get",
      url: `/api/post/like/${post._id}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setLikeLength(likeLength);
        setIsLiked(false);
        getCommnets()
        getLikes()

      })
      .catch((err) => {
        getLikes()
        getCommnets()
        // setLikeLength(likeLength - 1);
        setIsLiked(true);
        if (err.response.status === 401) {
          setSessionExpire(true)
        }
      });
  };


  // const getCommentInfo = () => {
  //   try {
  //     Axios({
  //       method: "get",
  //       url: `/api/post/get/${post._id}`,
  //     })
  //       .then((res) => {
  //         setNewCommnets(res.data.data.comments)
  //       })
  //       .catch(() => {
  //         // if (err.response.status === 401) {
  //           setSessionExpire(true)
  //         // }
  //       });
  //   } catch (error) {
  //     toast.error("Some Internal Error")
  //   }
  // };

  useEffect(() => {
    // if (post) { getCommnets(); }
    // getCommentInfo()

  }, [post]);

  useEffect(() => {
    // getCommentInfo()
  }, [])

  // const deleteComment = (id) => {
  //   Axios({
  //     url: `/api/post/delete-comment/${post._id}/${id}`,
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then(() => {
  //       const newArr = userCommnets.filter((item) => item.commentId !== id);
  //       setUserCommnets(newArr);
  //       setUserCommentLength(newArr.length);
  //       toast.success("Comment Deleted");
  //     })
  //     .catch((err) => {
  //       const newArr = userCommnets.filter((item) => item.commentId !== id);
  //       setUserCommnets(newArr);
  //       setUserCommentLength(newArr.length);
  //       if (err.response && err.response.status === 401) {
  //         setSessionExpire(true)
  //       }
  //     });
  // };

  return <div className="dashboard01_block mt-0 post_root">
    <Post
      post={post}
      getPosts={getPosts}
      setSessionExpire={setSessionExpire}
    />

    <div className="post_total_likes_root">
      {
        firstLike !== null ? <p className="overline color_dark_grey2">
          <span className="post_total_likes_img">
            <img src={groupLike} alt="like" />
          </span>

          <span>{firstLike.byUser ? `${firstLike.byUser.firstname}` : null} </span>
          {likeLength - 1 !== 0 ? <span> and {likeLength - 1} others</span> : null}

        </p> : <p className="overline color_dark_grey2">0 Like</p>
      }

      <p className="overline">{commnentLength} Comments</p>
    </div>
    {/* <hr className="post_hr_root" /> */}

    <div className="post_likeComment_root">
      <div className="post_like_main">
        <span className="post_like_main_img">
          {isLiked ? (
            <img
              src={likeFilled}
              alt="thumb"
              className="feed_like_icon point"
              onClick={() => {
                handleUnlike();
                getPosts();
              }}
            />
          ) : (
            <img
              src={likeIcon}
              alt="thumb"
              className="feed_like_icon point"
              onClick={() => {
                handleLike();
                getPosts();
              }}
            />
          )}
        </span>
        
              {
                isLiked ? (
                  <p onClick={() => {
                    handleUnlike();
                    getPosts();
                  }} className="caption color_dark_blue point" style={{ width: "auto" }}>
              Liked
            </p>
                ):  <p onClick={() => {
                  handleLike();
                  getPosts();
                }} className="caption color_dark_blue point" style={{ width: "auto" }}>
            Like
          </p>
              }
      
      </div>
      <div className="post_comment_main" onClick={focusComment}>
        <span className="post_comment_main_img">
          <img src={commentFilled} alt="comment" className="feed_like_icon point" />
        </span>
        <p className="caption color_dark_blue point" style={{ width: "auto" }}>
          Comment
        </p>
      </div>

    </div>

    {
      showAllComment ?
        userCommnets.map((comment, i) => <Comment
          key={i}
          comment={comment}
        />) : userCommnets.reverse().slice(0, 1).map((comment, i) => <Comment
          key={i}
          comment={comment}
        />)
    }


    <div className="post__viewAll_root mt-24">
      {
        userCommnets.length > 0 ? showAllComment ? <p className="overline color_light_black" onClick={() => setShowAllComment(false)}>less comments</p> : <p className="overline color_light_black" onClick={() => setShowAllComment(true)}>View all {commnentLength} comments</p> : null
      }

    </div>
    <div className="feed_post_writeComment_root mt-16">
      <div className="feed_post_writeComment_img mr-24">
        <img src={user.avatar} alt="writeCImg" />
      </div>
      <div className="feed_post_writeComment_inputMain w_100">
        <div className="feed_post_writeComment_input">
          <input
          ref={commentInput}
            type="text"
            className="body2"
            placeholder="Write a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {/* <button><SentimentSatisfiedOutlined /></button> */}
          <button onClick={createComment}><img src={sendicon} alt="sendicon" className="mr-16" /></button>
        </div>
      </div>
    </div>

    {/* {showAllComment
      ? userCommnets.length > 0
        ? userCommnets.map((item, i) => <CommentMain
          item={item}
          key={i}
          post={post}
          user={user}
          deleteComment={deleteComment}
          setSessionExpire={setSessionExpire}
          // newComments={newComments}
        />)
        : null
      : userCommnets.length > 0
        ? userCommnets
          .reverse()
          .slice(0, 1)
          .map((item, i) => <CommentMain
            item={item}
            key={i}
            post={post}
            user={user}
            deleteComment={deleteComment}
            setSessionExpire={setSessionExpire}
            // newComments={newComments}
          />)
        : null}

    {showAllComment ? (
      userCommnets === null ? null : (
        <p
          style={{ cursor: "pointer" }}
          onClick={() => setShowAllComment(false)}
        >
          View less comments
        </p>
      )
    ) : userCommnets === null ? null : (
      <p
        style={{ cursor: "pointer" }}
        onClick={() => setShowAllComment(true)}
      >
        View all comments
      </p>
    )} */}


  </div>
};

export default Posts;
