import React, { useState } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";

const CreateAnn = ({ getannoucements, handleClose }) => {
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [startup, setstartup] = useState(false);
  const [mentor, setmentor] = useState(false);
  const { programid } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios({
        method: "post",
        url: `/api/announcement/create/${programid}`,
        data: {
          title,
          description,
          startup,
          mentor,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Annoucement Created");
          }
          getannoucements();
          handleClose();
        })
        .catch((err) => {
          return toast.error(err.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="register_page_form row">
          <div className="col-12">
            <label>Title</label>
            <input
              placeholder="Enter Title"
              required
              type="text"
              className="auth_input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label>Description </label>
            <textarea
              required
              name="msg"
              className="auth_input auth_textarea"
              placeholder="Write a description..."
              value={description}
              onChange={(e) => setdescription(e.target.value)}
            ></textarea>
          </div>
          <label> Send to</label>
          <div className="col-12">
            <label> Startup</label>
            <Checkbox onClick={(e) => setstartup(e.target.checked)} />
          </div>
          <div className="col-12">
            <label> Mentor</label>
            <Checkbox onClick={(e) => setmentor(e.target.checked)} />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button type="submit" className="gradient_CTA">
            &nbsp; &nbsp; ANNOUNCE&nbsp; &nbsp;{" "}
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateAnn;
