import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../../Utils/Auth";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Rating } from "@mui/material";
import Feedback from "./FeedBack";

const index = ({
  setstep,
  id,
  mentorid,
  fetchInvites,
  fetchactivementors,
  // setmentorState,
  programid,
}) => {
  const [mentor, setmentor] = useState(null);
  const [check, setcheck] = useState(null);
  const { setSessionExpire } = useAuth();
  const [ratingAvg, setRatingAvg] = useState(0);
  const [paticipantFeed, setParticipantFeed] = useState(null);
  const [length, setLength] = useState(0);
  // const [mentordata, setmentordata] = useState(null);

  const [open, setopen] = React.useState(false);
  const handleOpen = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  const fetchmentor = () => {
    Axios({
      url: `/api/mentor/mentorbyid/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setmentor(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const checkrequest = async () => {
    const checkmentor = await Axios.post(
      `/api/incmentor/checkmentor/${mentorid}`,
      {
        programid: programid,
      }
    );

    setcheck(checkmentor.data.data);
  };

  const getFeedback = async () => {
    await Axios({
      method: "get",
      url: `/api/incmentor/ratings/${mentorid}/${programid}`
    })
      .then((res) => {
        setParticipantFeed(res.data.data);
        const { data } = res.data;
        var accessibility = data.accessibility;
        var active_participation = data.active_participation;
        var overall_experience = data.overall_experience;
        var professionalism = data.professionalism;

        var accessibilitySum =
          accessibility.one * 1 +
          accessibility.two * 2 +
          accessibility.three * 3 +
          accessibility.four * 4 +
          accessibility.five * 5;
        var active_participationSum =
          active_participation.one * 1 +
          active_participation.two * 2 +
          active_participation.three * 3 +
          active_participation.four * 4 +
          active_participation.five * 5;
        var overall_experienceSum =
          overall_experience.one * 1 +
          overall_experience.two * 2 +
          overall_experience.three * 3 +
          overall_experience.four * 4 +
          overall_experience.five * 5;
        var professionalismSum =
          professionalism.one * 1 +
          professionalism.two * 2 +
          professionalism.three * 3 +
          professionalism.four * 4 +
          professionalism.five * 5;

        var accessibilityAvg =
          accessibilitySum /
          (accessibility.one +
            accessibility.two +
            accessibility.three +
            accessibility.four +
            accessibility.five);

        var active_participationAvg =
          active_participationSum /
          (active_participation.one +
            active_participation.two +
            active_participation.three +
            active_participation.four +
            active_participation.five);

        var overall_experienceAvg =
          overall_experienceSum /
          (overall_experience.one +
            overall_experience.two +
            overall_experience.three +
            overall_experience.four +
            overall_experience.five);

        var professionalismAvg =
          professionalismSum /
          (professionalism.one +
            professionalism.two +
            professionalism.three +
            professionalism.four +
            professionalism.five);
        setLength(
          professionalism.one +
            professionalism.two +
            professionalism.three +
            professionalism.four +
            professionalism.five
        );
        var avg =
          (accessibilityAvg +
            active_participationAvg +
            overall_experienceAvg +
            professionalismAvg) /
          4;
        setRatingAvg(avg.toFixed(1));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  // const getDetail = async () => {
  //   await Axios({
  //     method: "get",
  //     url: `/api/incmentor/ratings/${mentorid}/${programid}`,
  //   })
  //     .then((res) => {
  //       console.log("res.data.data",res.data.data);
  //       setmentordata(res.data.data);
  //       window.scrollTo(0, 0);
  //       getFeedback(mentorid);
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.status === 401) {
  //         setSessionExpire(true);
  //       }
  //     });
  // };

  useEffect(() => {
    // getDetail();
    getFeedback();
  }, []);


  useEffect(() => {
    fetchmentor();
    checkrequest();
  }, []);

  if (mentor === null) return <p>loading...</p>;

  const sendRequest = async () => {
    await Axios({
      url: `/api/incmentor/request/${mentorid}`,
      method: "post",
      data: {
        programid: programid,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Mentorship request sent");
          checkrequest();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const acceptRequest = async () => {
    await Axios({
      url: `/api/incmentor/accept/${check._id}`,
      method: "put",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Application accepted");
          checkrequest();
          fetchInvites();
          fetchactivementors();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  return (
    <>
      <div>
        <div
          className="back_CTA pointer"
          onClick={() => {
            setstep(0);
            // history(`/dashboard/${user.type}?tab=2`);
          }}
        >
          <p className="caption color_light_grey">
            <span>
              <KeyboardArrowLeft />
            </span>
            <span>BACK</span>
          </p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-8">
          <div className="connection_profile_root" id="connection_profile_root">
            <div className="row mt-2 mb-24 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
              <div className="row no-margin align-items-center col-8">
                <div className="width_auto no-padding">
                  <Avatar
                    src={mentor.profilePic}
                    className=""
                    alt="Profile"
                    sx={{ width: 90, height: 90 }}
                  />
                </div>
                <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                  <p className="p_spartan_20">{mentor.name}</p>
                  <p className="bold">Mentor | {mentor.location.country}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-6 text-right">
              {ratingAvg > 0 ? (
                <div className="participant_profile_right_rating_root">
                  <div className="participant_profile_right_rating_main">
                    <h5 className="color_dark_black2">{ratingAvg}</h5>
                    <div className="incubator_aly_rating__">
                      <Rating
                        name="read-only"
                        value={ratingAvg}
                        readOnly
                        className="incubator_aly_rating_read"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              </div>
            </div>
            <div className="mt-4 mx-2">
              <p className="grey bold">{mentor.description}</p>
            </div>

            <div className="row mx-2 my-3">
              {mentor.interestedDomains ? (
                <>
                  {mentor.interestedDomains.map((industry, i) => (
                    <p className="p_grey_chip" key={i}>
                      {industry}{" "}
                    </p>
                  ))}
                </>
              ) : null}
            </div>
            <hr />

            <div className="row no-margin p-2 white_bg">
              <div className=" no-padding">
                <div className="connections_highlights_investor">
                  {mentor.involvement ? (
                    <>
                      <div>
                        <p className="p_spartan_20"> Prefered Involvement </p>
                        {mentor.involvement.length > 0 ? (
                          <div className="mt-4">
                            {mentor.involvement.map((inv, i) => (
                              <p className="" key={i}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-dot"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                                {inv.name}
                              </p>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            
            <hr />
            <div className="partipant_profile_main_review_main mt-40">
              <div className="partipant_profile_main_review_head mb-24">
                <p className="title">Ratings</p>
                <button
                  className="primaryFilled_activeCta"
                  onClick={handleOpen}
                >
                  REVIEW
                </button>
              </div>
              <div className="incubator_analytics_rating_progress w_100">
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Accessibility</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${
                          paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.accessibility
                        }%`,
                      }} 
                    >
                      {paticipantFeed ===
                      null ? null : paticipantFeed.percent.accessibility >=
                        87 ? (
                        <p className="overline color_dark_grey2 pr-16 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                    null ? null : paticipantFeed.percent.accessibility < 87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Active participation</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${
                          paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.active_participation
                        }%`,
                      }}
                    >
                      {paticipantFeed ===
                      null ? null : paticipantFeed.percent.active_participation >=
                        87 ? (
                        <p className="overline color_dark_grey2 pr-16 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                    null ? null : paticipantFeed.percent.active_participation <
                      87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                    {/* <p className="overline color_dark_grey2 ml-8">{length} members</p> */}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Professionalism</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${
                          paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.professionalism
                        }%`,
                      }}
                    >
                      {paticipantFeed ===
                      null ? null : paticipantFeed.percent.professionalism >=
                        87 ? (
                        <p className="overline color_dark_grey2 ml-8 pr-16">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                    null ? null : paticipantFeed.percent.professionalism <
                      87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main">
                  <p className="overline">Overall Experience</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${
                          paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.overall_experience
                        }%`,
                      }}
                    >
                      {paticipantFeed ===
                      null ? null : paticipantFeed.percent.overall_experience >=
                        87 ? (
                        <p className="overline color_light_grey ml-8 pr-16">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                    null ? null : paticipantFeed.percent.overall_experience <
                      87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>


            <div>
              {check === null ? (
                <>
                  <button
                    type="button"
                    onClick={() => sendRequest()}
                    className="gradient_CTA width_fit"
                  >
                    REQUEST MENTOR
                  </button>
                </>
              ) : check !== null &&
                !check.is_accepted &&
                check.requested_from === "mentor" ? (
                <>
                  <button
                    type="button"
                    onClick={() => acceptRequest()}
                    className="gradient_CTA width_fit"
                  >
                    ACCEPT REQUEST
                  </button>
                </>
              ) : check !== null && check.is_accepted ? (
                <></>
              ) : (
                <>
                  <button
                    type="button"
                    disabled
                    className="gradient_CTA width_fit"
                  >
                    PENDING
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="connection_profile_root_highligts">
            <div className="connection_profile_root_highligts_head">
              <p className="p_spartan_20">Highlights</p>
            </div>
            <div className="connection_profile_root_highligts_desc">
              <table>
                <tr>
                  <td className="td_left">Current Company</td>
                  <td className="td_right">{mentor.currentCompany}</td>
                </tr>

                <tr>
                  <td className="td_left">Current Job Role</td>
                  <td className="td_right">{mentor.currentJobRole}</td>
                </tr>
                <tr>
                  <td className="td_left">Past Experience</td>
                  <td className="td_right">{mentor.pastExperience}</td>
                </tr>
                <tr>
                  <td className="td_left">Industry</td>
                  <td className="td_right">{mentor.industry}</td>
                </tr>

                <tr>
                  <td className="td_left">Linkedin</td>
                  <td className="td_right">{mentor.linkedin}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Feedback
        open={open}
        handleClose={handleClose}
        mentor = {mentor}
        getFeedback={getFeedback}
      />
    </>
  );
};

export default index;
