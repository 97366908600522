import React from "react";
import Skeleton from "@mui/material/Skeleton";

export const AssetsSkeleton = () => {
  const renderSkeleton = () => {
    const list = [];
    for(let index = 0; index < 5; index++) {
      list.push(
        <div className="coworking_myAssets_left_body">
          <div className="coworking_myAssets_left_profile">
            <div className="coworking_myAssets_left_profile_img">
              <Skeleton variant="circular" width={40} height={40} />
            </div>
            <div className="coworking_myAssets_left_profile_name ml-8">
              <p className="body1">
                <Skeleton animation="wave" width={60} height={15} />
              </p>
              <p className="overline">
                <Skeleton animation="wave" width={40} height={10} />
              </p>
            </div>

            <div className="coworking_myAssets_left_active background">
              <p className="overline">
                <Skeleton animation="wave" width={50} height={30} />
              </p>
            </div>
          </div>
        </div>
      );
    }
    return list;
  };
  return (
    <div className="incubator_coworking_myAssets_left_root">
      <div className="coworking_myAssets_left_head">
        <p className="caption">
          <Skeleton animation="wave" width={50} height={30} />
        </p>

        <Skeleton variant="circular" width={40} height={40} />
      </div>
      {renderSkeleton()}
    </div>
  );
};

export const AssetsDetailsSkeleton = () => {
     return (
          <div className="incubator_coworking_myAssets_right_root">
                        <div className="coworking_myAssets_right_details_main">
                            <div className="coworking_myAssets_right_details_head">
                                <p className="subtitle"><Skeleton animation="wave" width={180} height={50} /></p>
                                <div className="d-flex align-items-center">
                                    <div className="mx-1"><Skeleton animation="wave" width={50} height={50} /></div>
                                    <div className="mx-1"><Skeleton animation="wave" width={50} height={50} /></div>
                                </div>
                            </div>
                            <div className="coworking_myAssets_right_details_id">
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="body1 mt-14"><Skeleton animation="wave" width={60} height={40} /></p>
                                    <p className="body1 mt-1"><Skeleton animation="wave" width={90} height={40} /></p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="body1 mt-14"><Skeleton animation="wave" width={60} height={40} /></p>
                                    <p className="body1 mt-1"><Skeleton animation="wave" width={90} height={40} /></p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="body1 mt-14"><Skeleton animation="wave" width={60} height={40} /></p>
                                    <p className="body1 mt-1"><Skeleton animation="wave" width={90} height={40} /></p>
                                </div>
                                <div className="coworking_myAssets_right_details_id_status">
                                    <p className="body1 mt-14"><Skeleton animation="wave" width={60} height={40} />
                                    </p>
                                    <p className="body1 mt-1"><Skeleton animation="wave" width={90} height={40} />
                                    </p>
                                </div>
                            </div>
                            <div className="coworking_myAssets_right_details_category_main">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="myAssets_right_category_box_main">
                                            <table className='myAssets_right_category_box_table'>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    
                                                    <td><Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                  
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                   
                                                    <td className='pb-24'> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="myAssets_right_category_box_main" style={{ borderRight: 0 }}>
                                            <table className='myAssets_right_category_box_table'>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                   
                                                    <td> <Skeleton animation="wave" width={50} height={30} /></td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                  
                                                    <td><Skeleton animation="wave" width={50} height={30} /></td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                     
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                                <tr className='myAssets_right_category_box_table_tr'>
                                                   
                                                    <td className='pb-24'><Skeleton animation="wave" width={50} height={30} /></td>
                                                    <td> <Skeleton animation="wave" width={50} height={30} /> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
     </div>
     )
};




export const AssetsView = ({isAsset}) => {
  const renderList = () => {
    const list = [];
for(let i=0; i<8; i++){
  list.push(
    <div className="col-lg-3 col-md-4 col-12 mb-24">
          <div className="startup_assets_card_root point">
              <div className="startup_assets_card_main">
                  <div className="startup_assets_card_tag">
                    <td> <Skeleton animation="wave" width={90} height={40} /></td>
          
                  </div>
                  <div className="startup_assets_card_profile mt-16">
                  <td> <Skeleton animation="wave" variant="circular" width={30} height={30} /></td>
                      <div className="s_asset_card_profile_name ml-12">
                        <td> <Skeleton animation="wave" width={80} height={40} /></td>
                      </div>
                  </div>
                  <div className="startup_assets_card_rs_info mt-16">
                      <div className="startup_assets_card_rs">
                        <td> <Skeleton animation="wave" width={60} height={30} /></td>
                      </div>
                      <div className="startup_assets_card_rs">
                      <td> <Skeleton animation="wave" width={60} height={30} /></td>
                      </div>
                  </div>
                  {
                    isAsset === true && <div className="startup_assets_card_incubator_info mt-24">
                    <div className="startup_assets_card_incubator_profile">
                    <td> <Skeleton animation="wave" variant="circular" width={30} height={30} /></td>
                    <td className="ml-2"> <Skeleton animation="wave"  width={70} height={30} /></td>
                    </div>
                    <div className="startup_assets_card_inc_arrow">
                    <td> <Skeleton animation="wave" width={30} height={30} /></td>
                    </div>
                    </div>
                  }
              </div>
              
                 <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" 
                 className="startup_assets_card_footer mt-16"
                 style={{
                  borderBottomLeftRadius:'12px',
                  borderBottomRightRadius:'12px',
                  padding:"10px"
                 }}/>
             
          </div>
      </div>
  )
}
    
    return list;
  }
    return (
      <>
        {renderList()}
      </>
    )
}