import React, { useEffect, useState } from 'react'

const QuestionsAnswers = ({ assess, i, Answers }) => {

    const [correctRes, setCorrectRes] = useState(null);

    useEffect(() => {
        for (let i = 0; i < Answers.length; i++) {
            if (Answers[i].question_id === assess._id) {
                setCorrectRes(Answers[i])
                break;
            }

        }
    }, [assess])
    return <div className="userCourses_assessment_qAroot">
        <div className="userCourses_tabPanel_assess_ques_head d-flex justify-content-between align-content-center">
            <p className="caption color_dark_blue">Question {i + 1}</p>
            {correctRes !== null ? <>
                {
                    correctRes.is_correct ? <p className="caption" style={{ color: "#FF6F1E" }}>Correct
                    </p> : <p className="caption" style={{ color: "#580167" }} >Incorrect
                    </p>
                }
            </> : ""}
        </div>
        <hr />
        <div className="userCourses_tabPanel_assess_quesAns">
            <div className="userCourses_tabPanel_assess_question">
                <p className="body1 color_dark_grey2 mt-2">{assess.question} ?</p>

            </div>
            <div className="userCourses_tabPanel_assess_Ans_main mt-2">
                {
                    assess.options.option_one !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                        <div className="userCourse_radio_main">
                            <input
                                type="radio"
                                name={`Ans${i + 1}`}
                                value={assess.options.option_one}
                                disabled
                                checked={assess.answer === assess.options.option_one ? true : false}

                            />
                            <label className={`userCourse_radio ${assess.answer === assess.options.option_one ? 'userCourse_rCheckd' : 'userCourse_rNotCheckd'}`}></label>
                        </div>
                        <p className="body2">{assess.options.option_one}</p>
                    </div> : null
                }
                {
                    assess.options.option_two !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                        <div className="userCourse_radio_main">
                            <input
                                type="radio"
                                name={`Ans${i + 1}`}
                                value={assess.options.option_two}
                                disabled
                                checked={assess.answer === assess.options.option_two ? true : false}

                            />
                            <label className={`userCourse_radio ${assess.answer === assess.options.option_two ? 'userCourse_rCheckd' : 'userCourse_rNotCheckd'}`}></label>
                        </div>
                        <p className="body2">{assess.options.option_two}</p>
                    </div> : null
                }
                {
                    assess.options.option_three !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                        <div className="userCourse_radio_main">
                            <input
                                type="radio"
                                name={`Ans${i + 1}`}
                                value={assess.options.option_three}
                                disabled
                                checked={assess.answer === assess.options.option_three ? true : false}

                            />
                            <label className={`userCourse_radio ${assess.answer === assess.options.option_three ? 'userCourse_rCheckd' : 'userCourse_rNotCheckd'}`}></label>
                        </div>
                        <p className="body2">{assess.options.option_three}</p>
                    </div> : null
                }
                {
                    assess.options.option_four !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                        <div className="userCourse_radio_main">
                            <input
                                type="radio"
                                name={`Ans${i + 1}`}
                                value={assess.options.option_four}
                                disabled
                                checked={assess.answer === assess.options.option_four ? true : false}

                            />
                            <label className={`userCourse_radio ${assess.answer === assess.options.option_four ? 'userCourse_rCheckd' : 'userCourse_rNotCheckd'}`}></label>
                        </div>
                        <p className="body2">{assess.options.option_four}</p>
                    </div> : null
                }
                {
                    assess.options.option_five !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                        <div className="userCourse_radio_main">
                            <input
                                type="radio"
                                name={`Ans${i + 1}`}
                                value={assess.options.option_five}
                                disabled
                                checked={assess.answer === assess.options.option_five ? true : false}

                            />
                            <label className={`userCourse_radio ${assess.answer === assess.options.option_five ? 'userCourse_rCheckd' : 'userCourse_rNotCheckd'}`}></label>
                        </div>
                        <p className="body2">{assess.options.option_five}</p>
                    </div> : null
                }
            </div>
        </div>
    </div>
}

export default QuestionsAnswers