import React, { useEffect, useState } from 'react'
import { ArrowDropUp, ArrowDropDown, Delete } from "@mui/icons-material"
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { toast } from 'react-toastify';

const Card = ({ data, getCart }) => {
    const [quantity, setQuantity] = useState(data.quantity);
    const { setSessionExpire } = useAuth();

    const deleteCart = async () => {
        await Axios({
            url: `/api/asset/cart/delete/${data._id}`,
            method: "delete",
        }).then((res) => {
            if (res.data.success) {
                toast.success('Delete Item')
                getCart()
            }
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    const updateCart = async () => {
        await Axios({
            url: `/api/asset/cart/update/${data._id}`,
            method: "PUT",
            data: {
                quantity: quantity,
            }
        }).then((res) => {
            setQuantity(res.data.data.quantity);
            getCart();
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    useEffect(() => {
        updateCart()
    }, [quantity])

    return (
        <div className="startup_my_cart_card">
            <div className="startup_my_cart_select">
                <input type="checkbox" name="" id="" checked={true} />
                <div className="viewReq_m_header_lcard_profile d-flex align-items-center ml-12">
                    <img src="https://picsum.photos/64" alt="profile" />
                    <div className="s_asset_card_profile_name ml-12">
                        <p className="body1">{data.asset_id.asset_name}</p>
                        <p className="overline color_dark_grey2">
                            {data.incubator_id.name ? data.incubator_id.name : `${data.incubator_id.firstname} ${data.incubator_id.lastname}` }
                        </p>
                    </div>
                </div>
            </div>
            <div className="startup_my_cart_quantity">
                <div className="viewAsset_card_quantity d-flex align-items-center mr-16">
                    <p className="body2 color_dark_grey2 mr-8">Quantity : </p>
                    <div className="startup_my_cart_card_qty_main">
                        <input
                            type="number"
                            min={0}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            className="btncta"
                        />
                        <div className="myCart_card_quan_btn_main">
                            <button onClick={() => setQuantity(Number(quantity) + 1)}><ArrowDropUp /></button>
                            <button onClick={() => {
                                if (quantity > 0)
                                    setQuantity(quantity - 1)
                            }}><ArrowDropDown /></button>
                        </div>
                    </div>
                </div>
                <div className="startup_my_cart_money">
                    <p className="subtitle">€ {data.asset_id.price * quantity}</p>
                </div>
                <div className="startup_my_cart_delete">
                    <button className='transparent_cta' onClick={deleteCart}><Delete
                        style={{ fill: "#989898" }}
                    /></button>
                </div>
            </div>
        </div>
    )
}

export default Card