import React, { useState, useEffect } from "react";
import "./MyAssets.css";
import AssetDetails from "./AssetDetails";
import AddAssetsForm from "./AddAssetsForm";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AssetsCard from "./AssetsCard";
import {AssetsSkeleton} from '../../../../Components/SkeletonLoader/MyAssetsSkeleton';
const MyAssets = () => {
  const [externalAssetState, setExternalAssetState] = useState(0);
  const [internalAssetState, setInternalAssetState] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [externalAssets, setExternalAssets] = useState([]);
  const [internalAssets, setInternalAssets] = useState([]);
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false)
  const { user, incubatorDetails} = useAuth();
  
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
 
  const getExternalAssets = async () => {
    setIsLoading(true)
    
    const id = user.type === 'employee'?incubatorDetails?.id:user.id
    await Axios({
      url: `/api/asset/incubator/${id}/External`,
      method: "GET",
    })
      .then((res) => {
        setExternalAssets(res.data.data);
        setIsLoading(false)
        handleExternalAssetsOpen(res.data.data)
      })
      .catch(() => {
        // console.log(err);
        setIsLoading(false)
      });
  };
  const getInternalAssets = async () => {
    setIsLoading(true)
    const id = user.type === 'employee'?incubatorDetails?.id:user.id
    await Axios({
      url: `/api/asset/incubator/${id}/Internal`,
      method: "GET",
    })
      .then((res) => {
        setInternalAssets(res.data.data);
        setIsLoading(false)
        handleInternalAssetsOpen(res.data.data)
      })
      .catch(() => {
        // console.log(err);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    getExternalAssets();
    getInternalAssets();
  }, []);

  
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const handleExternalAssetsOpen = (assetsdata) =>{
    for (let i = 0; i < assetsdata.length; i++) {
      const e = assetsdata[i];
      if (!e.is_deleted) {
        navigate(
          `/dashboard/${user.type}?tab=5&cs=5&asset=${assetsdata[i]._id}`
        );
        break;
      }
    }
  }
  const handleInternalAssetsOpen = (assetsdata) =>{
    for (let i = 0; i < assetsdata.length; i++) {
      const e = assetsdata[i];
      if (!e.is_deleted) {
        navigate(
          `/dashboard/${user.type}?tab=5&cs=5&asset=${assetsdata[i]._id}`
        );
        break;
      }
    }
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if(newValue === 0){
      handleExternalAssetsOpen(externalAssets)
    }
    if(newValue === 1){
      handleInternalAssetsOpen(internalAssets);
    }
  };
  return (
    <>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          className="servicer-tabs"
        >
          <Tab
            label={
              <div className="row align-items-center events-tabs-rows">
                <p className="no-margin cta">External Assets</p>{" "}
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            className=""
            label={
              <div className="row align-items-center events-tabs-rows">
                <p className="no-margin cta">Internal Assets</p>{" "}
              </div>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0} className="service-tabbpanel-main">
        {externalAssetState === 0 ? (
          <div className="incubator_coworking_myAssets_root">
            <div className="row">
              <div className="col-lg-3">
                {
                  isLoading ? <AssetsSkeleton/> : (
                    <AssetsCard
                  setMyAssetState={setExternalAssetState}
                  assets={externalAssets}
                  assetsType="External"
                />
                  )
                }
                
              </div>
              <div className="col-lg-9">
                <AssetDetails
                  setMyAssetState={setExternalAssetState}
                  getAssets={getExternalAssets}
                  assetsType="External"
                />
              </div>
            </div>
          </div>
        ) : (
          <AddAssetsForm
            setMyAssetState={setExternalAssetState}
            getAssets={getExternalAssets}
            assetsType="External"
          />
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1} className="service-tabbpanel-main">
        {internalAssetState === 0 ? (
          <div className="incubator_coworking_myAssets_root">
            <div className="row">
              <div className="col-lg-3">
                <AssetsCard
                  setMyAssetState={setInternalAssetState}
                  assets={internalAssets}
                  assetsType="Internal"
                />
              </div>
              <div className="col-lg-9">
                <AssetDetails
                  setMyAssetState={setInternalAssetState}
                  getAssets={getInternalAssets}
                  assetsType="Internal"
                />
              </div>
            </div>
          </div>
        ) : (
          <AddAssetsForm
            setMyAssetState={setInternalAssetState}
            getAssets={getInternalAssets}
            assetsType="Internal"
          />
        )}
      </TabPanel>
    </>
  );
};

export default MyAssets;
