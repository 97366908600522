import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { CalendarToday, Money, AccessTime } from "@mui/icons-material";
import Axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import ShowDoc from "./ShowDocs";
// import UplaodDocs from './UploadDocs';
import "../../../Components/Css/common.css";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
// const style = {
//      position: "absolute",
//      top: "50%",
//      left: "50%",
//      transform: "translate(-50%, -50%)",
//      width: 500,
//      bgcolor: "background.paper",
//      boxShadow: 24,
//      p: 4,
// };
const SharedProgram = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { programid } = useParams();
  const [program, setProgram] = useState(null);
  const [, setRequest] = useState(null); //request
  const [monthDiff, setMonthDiff] = useState(""); //monthDiff
  const [, setisexpired] = useState(null);
  const [, setdiff] = useState(null);
  // const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (user === null) {
      const url = `https://incubation.zefyron.com/signup?program=${programid}`;
      window.location = url;
      toast.success("Please signup first");
    } else if (user.type === "startup") {
      navigate(`/dashboard/startup?tab=7&id=${programid}&program-state=${1}`);
    } else if (user.type === "mentor") {
      navigate(`/dashboard/mentor?tab=7&id=${programid}&program-state=${1}`);
    } else if (user.type === "incubator") {
      navigate(`/service/incprogram/${programid}?tab=0`);
    }
  };
  // const handleClose = () => setOpen(false);
  // const [documentsRequired, setdocumentsRequired] = useState([]);
  const [, setIsNeedUpload] = useState(true); //isNeedUpload

  const [isLoading, setIsLoading] = useState(false);
  // const [uploadedDocs,setUploadedDocs] = useState([]);
  const fetchprogram = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/incubatorProgram/share/program/${programid}`,
      method: "get",
    })
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          setProgram(res.data.data);
          setRequest(res.data.data.request);
          setMonthDiff(res.data.data.duration);
          setdiff(res.data.data.diff);
          setisexpired(res.data.data.isExpired);
          let documents = res.data.data.requiredDocs;
          let docs = [];
          for (let i = 0; i < documents.length; i++) {
            docs.push({ doc_name: documents[i], doc_link: null });
          }
          // setdocumentsRequired(docs);
          if (res.data.request) {
            if (res.data.data.requiredDocs.length > 0) {
              if (res.data.request.documents.length > 0) {
                setIsNeedUpload(false);
              } else {
                setIsNeedUpload(true);
              }
            } else {
              setIsNeedUpload(false);
            }
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  // const handleApply = () => {
  //      const url = `https://incubation.zefyron.com/signup?program=${programid}`
  //      window.location = (url)
  // }

  //   const sendRequest = async () => {

  //      for (let index = 0; index < documentsRequired.length; index++) {
  //           const element = documentsRequired[index];
  //           if (element.doc_link === null)
  //             return toast.error(`Upload ${element.doc_name}`);
  //      }
  //     await Axios({
  //       url: `/api/incubatorProgram/request/public/member`,
  //       method: "post",
  //       data: {
  //         to:programid,
  //         documents: documentsRequired,
  //       },
  //     })
  //       .then((res) => {
  //           const {_id} = res.data.data
  //           const url = `https://incubation.zefyron.com/signup?request_id=${_id}`
  //           window.location = (url)
  //           handleClose()
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 401) {
  //           toast.error("Something went wrong");
  //         }
  //       });
  //   };

  // const handleremovedoc = (doc_name) => {
  //      let array2 = documentsRequired.map((a) => {
  //           return { ...a };
  //      });
  //      array2.find((a) => a.doc_name == doc_name).doc_link = null;
  //      setdocumentsRequired(array2);
  // };

  useEffect(() => {
    fetchprogram();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Loader />
        </div>
      ) : (
        program && (
          <div style={{ backgroundColor: "#F8F9FA" }}>
            <div className="container py-5">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="incubation_profile__head_card1_root">
                    <div className="incubationprofile_card1_title mt-0 mt-2">
                      <h4>{program.title}</h4>
                    </div>
                    <div className="row mx-0 mt-2" style={{ maxWidth: "100%" }}>
                      {program.sector &&
                        program.sector.map((sec, i) => (
                          <span className="p_grey_chip p_normalBlack10" key={i}>
                            {sec}
                          </span>
                        ))}
                    </div>
                    <div className="incubationprofile_card1_link">
                      <a href={program.website}>{program.website}</a>
                    </div>
                    <div className="incubationprofile_card1_hostedBy mt-4">
                      <p className="p_lightGrey10">Hosted By</p>
                      <div className="incubationprofile_card1_hostedPro">
                        <Avatar
                          src={program.id.avatar}
                          style={{ width: 40, height: 40 }}
                        />
                        <p className="p_white20 ml-3">{program.id.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-12">
                  <div className="incubation_profile__head_card2_root">
                    <img
                      src={program.coverImage}
                      alt="profile_inc"
                      className=""
                      style={{ borderRadius: "12px", maxWidth: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <div className="row">
                <div className="col-lg-7 col-md-6 col-12">
                  <div className="startup_incubation_viewProprgam_main dahsboard_card">
                    <div className="startup_incubation_viewProprgam_head">
                      <p className="subtitle">{program.title} </p>
                    </div>
                    <div className="startup_incubation_viewProprgam_desc">
                      <p className="body2">{program.description} </p>
                    </div>
                    <div className="startup_incubation_viewProprgam_highlites">
                      <p className="body1 color_dark_blue">Highlights</p>
                      <div className="startup_incubation_viewProprgam_high_ul">
                        <ul>
                          {program.highlights && program.highlights.length > 0
                            ? program.highlights.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                    <div className="startup_incubation_viewProprgam_benElig">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 startup_incubation_viewProprgam_benefit">
                          <p className="body1 color_dark_blue">Benefits</p>
                          <ul>
                            {program.benefits && program.benefits.length > 0
                              ? program.benefits.map((data, i) => (
                                  <li key={i} className="body2 pb-3">
                                    {data}
                                  </li>
                                ))
                              : null}
                          </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 startup_incubation_benefit startup_incubationeligibility">
                          <p className="body1 color_dark_blue">Eligibility</p>
                          <ul>
                            {program.eligibility &&
                            program.eligibility.length > 0
                              ? program.eligibility.map((data, i) => (
                                  <li key={i} className="body2 pb-3">
                                    {data}
                                  </li>
                                ))
                              : null}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="startup_incubation_viewProprgam_documents">
                      <p className="body1 color_dark_blue">Documents</p>
                      <div className="startup_incubation_docs">
                        <ul>
                          {program.requiredDocs &&
                          program.requiredDocs.length > 0
                            ? program.requiredDocs.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  <a href={data}> {data}</a>
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                  <div className="startup_incubation_viewProprgam_infor dahsboard_card">
                    <div className="startup_incubation_viewProprgam_date">
                      <p className="body1 d-flex align-content-center">
                        <span>
                          <CalendarToday />
                        </span>
                        {new Date(program.startDate).toDateString()}
                      </p>
                    </div>
                    <div className="startup_incubation_viewProprgam_date">
                      <p className="body1 d-flex align-content-center">
                        <span>
                          <Money />
                        </span>
                        FREE
                      </p>
                    </div>
                    <div className="startup_incubation_viewProprgam_date">
                      <p className="body1 d-flex align-content-center caps">
                        <span>
                          <AccessTime />
                        </span>
                        {monthDiff}
                      </p>
                    </div>
                    <div className="startup_incubation_viewProprgam_btn d-flex justify-content-center align-items-center pb-3">
                      <button
                        className="cta"
                        onClick={handleOpen}
                        style={{
                          background:
                            "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)",
                          border: "none",
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="startup_incubation_app_info my-4">
                    <div className="startup_incubation_sDae">
                      <div className="startup_incubation_startDate">
                        <p className="overLine">
                          <p className="body2">Start Date</p>
                        </p>
                      </div>
                      <div className="startup_incubation_dText">
                        {new Date(program.startDate).toDateString()}
                      </div>
                    </div>
                    <span className="startup_incubation_heigth"></span>
                    <div className="startup_incubation_sDae">
                      <div className="startup_incubation_startDate">
                        <p className="overLine">
                          <p className="body2">End Date </p>
                        </p>
                      </div>
                      <div className="startup_incubation_dText">
                        {new Date(program.endDate).toDateString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      {/* <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropProps={{
                         timeout: 300,
                    }}
               >
                    <Box sx={{ ...style, width: 500 }}>
                         <div className="register_page_form row">
                              <div className="col-12">
                                   {documentsRequired.length > 0 ? (
                                        documentsRequired.map((v, i) => {
                                             return (
                                                  <div key={(10 * (Math.random())) + v.doc_name}>
                                                       {v.doc_link ? <ShowDoc
                                                            v={v}
                                                            handleremovedoc={handleremovedoc}
                                                       /> : <UplaodDocs
                                                            i={i}
                                                            v={v}
                                                            docname={v.doc_name}
                                                            documentsRequired={documentsRequired}
                                                            setdocumentsRequired={setdocumentsRequired}
                                                            handleOpen={handleOpen}
                                                            handleClose={handleClose}
                                                            uploadedDocs = {uploadedDocs}
                                                            setUploadedDocs = {setUploadedDocs}
                                                       />
                                                       }
                                                  </div>
                                             );
                                        })
                                   ) : (
                                        <p>No Documents required</p>
                                   )}

                                   <div className="d-flex justify-content-center mt-5">
                                        <button
                                                onClick={() => sendRequest()}
                                             type="button"
                                             className="gradient_CTA"
                                        >
                                             Apply
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </Box>
               </Modal> */}
    </>
  );
};

export default SharedProgram;
