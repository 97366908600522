import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../Utils/Auth";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import "./messages.css";
import ScrollableFeed from "react-scrollable-feed";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import VideocamIcon from "@mui/icons-material/Videocam";
import PhotoIcon from "@mui/icons-material/Photo";
import CancelIcon from "@mui/icons-material/Cancel";
import Popover from "@mui/material/Popover";
import { nanoid } from "nanoid";
import Modal from "@mui/material/Modal";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Box from "@mui/material/Box";
// import menu from "../../../Assets/images/icons/menu.png";
import { KeyboardArrowLeft } from '@mui/icons-material';
import attachbtn from "../../../Assets/images/paperclip (1) 1.png";
import sendbtn from "../../../Assets/images/sendbtn.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "5px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const index = ({ setroomstep }) => {
  const { setSessionExpire, user } = useAuth();
  const scrollRef = useRef();
  const socket = useRef();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [selectedChat, setselectedChat] = useState(null);
  const [currentReceiver, setcurrentReceiver] = useState(null);
  const [messageText, setmessageText] = useState("");
  const [sendingAttach, setsendingAttach] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAttachClose = () => {
    setAnchorEl(null);
  };

  const openAttach = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [meet_id, setMeet_id] = useState("");
  const handleOpen = () => {
    setMeet_id(nanoid());
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getchat = async () => {
    Axios({
      method: "get",
      url: `/api/chat/${id}`,
    })
      .then((res) => {
        setselectedChat(res.data.conversation);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getconnection = async () => {
    Axios({
      method: "get",
      url: `/api/mentor/getconnect/${id}`,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.connection.sender._id === user.id) {
            setcurrentReceiver(res.data.connection.receiver);
          } else {
            setcurrentReceiver(res.data.connection.sender);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };
  const sendAttachment = (e) => {
    const file = e.target.files[0];

    let type;
    if (file) {
      const fileInfo = file.name.split(".");
      type = fileInfo[1];
    }
    if (
      file &&
      type != "ppt" &&
      type != "pptx" &&
      type != "pdf" &&
      type != "docx" &&
      type != "doc" &&
      type != "xls" &&
      type != "xlsx" &&
      type != "html" &&
      type != "txt" &&
      type != "jpg" &&
      type != "jpeg" &&
      type != "png" &&
      type != "mp4"
    )
      return toast.error("File format not supported");
    let attachmentType;
    if (type === "jpeg" || type === "jpg" || type === "png")
      attachmentType = "image";
    else if (type === "mp4") attachmentType = "video";
    else attachmentType = "attachment";

    const formdata = new FormData();
    formdata.append("chat-attachment", file);
    handleAttachClose();
    setsendingAttach(true);
    Axios({
      method: "post",
      url: `/api/chat/attach/${id}?type=${attachmentType}`,
      data: formdata,
    })
      .then((res) => {
        if (res) {
          getchat();
          setsendingAttach(false);
        }
      })
      .catch(() => {
        toast.error("Error in upload");
        setsendingAttach(false);
      });
  };

  const deleteMessage = async (idd) => {
    await Axios.delete(`/api/chat/delete/${idd}`)
      .then((res) => {
        if (res) getchat();
      })
      .catch(() => {
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (
      messageText == null ||
      messageText === "" ||
      messageText === " " ||
      messageText === undefined
    ) {
      return;
    }

    // socket.current.emit("sendMessage", {
    //   senderId: user.id,
    //   receiverId: currentReceiver.id,
    //   text: messageText,
    //   avatar: currentReceiver.avatar,
    //   // profilePic: profile.profilePic,
    // });

    setselectedChat((prev) => [
      ...prev,
      {
        from: user.id,
        message: messageText,
        date: Date.now(),
        type: "text",
      },
    ]);

    await Axios({
      method: "post",
      url: `/api/chat`,
      data: {
        message: messageText,
        connectID: id,
      },
    }).then((res) => {
      if (res) getchat();
      setmessageText("");
    });
  };
  const sendVideoLink = async () => {
    socket.current.emit("sendMessage", {
      senderId: user.id,
      receiverId: currentReceiver.id,
      text: `https://incubation.zefyron.com/meet/${meet_id}`,
      avatar: currentReceiver.avatar,
      // profilePic: profile.profilePic,
    });
    setselectedChat((prev) => [
      ...prev,
      {
        from: user.id,
        message: `https://incubation.zefyron.com/meet/${meet_id}`,
        date: Date.now(),
        type: "meet-link",
      },
    ]);

    await Axios({
      method: "post",
      url: `/api/chat`,
      data: {
        message: `https://incubation.zefyron.com/meet/${meet_id}`,
        connectID: id,
        type: "meet-link",
      },
    }).then(() => {
      setmessageText("");
      handleClose();
    });
  };

 

  useEffect(() => {
    getchat();
    getconnection();
  }, []);
  const getTime = (date) => {
    const d = new Date(date);
    const t = d.toLocaleTimeString([], { timeStyle: "short" });
    return t;
  };
  return (
    <>
      <div
            className="back_CTA pointer my-2"
            onClick={() => setroomstep(0)}
          >
           <p className="caption color_light_grey"><span><KeyboardArrowLeft /></span>
          <span>BACK</span></p>
          </div>

      <div className="dashboard01_block">
        {currentReceiver ? (
          <>
            <div className="row no-margin align-items-center">
              <div className="width_auto">
                <Avatar
                  src={currentReceiver.avatar}
                  className="dashboard_block_item_img"
                  alt="avatar"
                />
              </div>
              <div className="col no-padding">
                <h5 className="p_spartan_20">{currentReceiver.name}</h5>
              </div>
              <div className="col-2 " style={{ textAlign: "right" }}>
                {/* <img src={menu} className="img-resposive" width="auto" /> */}
              </div>
            </div>
            <hr style={{ margin: "0.5rem -10px" }} />
          </>
        ) : null}

        <div className="chat_message_box">
          {selectedChat ? (
            <ScrollableFeed>
              {selectedChat.map((message, i) => (
                <div
                  ref={scrollRef}
                  key={i}
                  className={
                    user.id == message.from
                      ? "chatmessage self_message d-flex align-items-center"
                      : "chatmessage other_message  d-flex align-items-center"
                  }
                >
                  <div
                    className={
                      user.id == message.from
                        ? "mdmsg d-flex align-items-center flex-row-reverse"
                        : "mdmsg  d-flex align-items-center"
                    }
                  >
                    <div className="mesage_text">
                      {message.type === "text" ? (
                        <>
                          <p className="no-margin">{message.message}</p>
                         
                        </>
                      ) : message.type === "attachment" ? (
                        <>
                          <div
                            className={
                              "mdmsg d-flex align-items-center flex-row-reverse mdmsg  d-flex align-items-center"
                            }
                          >
                            <div className="message_doc">
                              <div className="message_doc-main">
                                <div className="doc_download_main">
                                  <a
                                    href={`${message.filelink}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <button>
                                      <FileDownloadIcon />
                                    </button>
                                  </a>
                                </div>
                                <div className="doc-name-type-main">
                                  <p className="docname">{message.filename}</p>
                                  <div className="time-type">
                                   
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : message.type === "image" ? (
                        <>
                          <a
                            href={`${message.filelink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={message.filelink}
                              className="image-fluid"
                              style={{ maxHeight: "150px", maxWidth: "200px" }}
                            />
                          </a>
                          <p className="p_small_black_10 chat_time">
                            {getTime(message.date)}
                          </p>
                        </>
                      ) : message.type === "video" ? (
                        <>
                          <video width="360" height="240" controls>
                            <source src={message.filelink} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                         
                        </>
                      ) : message.type === "meet-link" ? (
                        <>
                          <p>Started a video call, click to join</p>
                          <p className="no-margin">
                            <a
                              href={`${message.message}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "inherit" }}
                            >
                              JOIN
                            </a>
                          </p>
                         
                        </>
                      ) : null}
                       <p className="chat_time">
                            {getTime(message.date)}
                          </p>
                    </div>
                    {user.id === message.from ? (
                      <div className="deletemsg" type="button">
                        <button
                          onClick={() => {
                            deleteMessage(message._id);
                          }}
                        >
                          <CancelIcon />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </ScrollableFeed>
          ) : (
            " "
          )}
        </div>

        <div>
          <form onSubmit={handleSendMessage}>
            <label className="message_send_label">
              <input
                placeholder="Enter text here..."
                type={"text"}
                className="chat_input"
                onChange={(e) => setmessageText(e.target.value)}
                value={messageText}
              />
              <button className="message_btn attach_btn video_call_btn" style={{ display: "none" }}>
                <VideoCallIcon
                  className="send_btn_img"
                  onClick={() => handleOpen()}
                />
              </button>

              <div className="sendFileMsgMain">
                <button className="message_btn attach_btn" id="showIconbtn">
                  {sendingAttach ? (
                    <CircularProgress size={30} />
                  ) : (
                    <>
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        className=""
                        onClick={handleClick}
                      >
                        <img
                          src={attachbtn}
                          alt="attach"
                          className="send_btn_img_atch"
                        />
                      </Button>
                      <Popover
                        id={id}
                        open={openAttach}
                        anchorEl={anchorEl}
                        onClose={handleAttachClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="message_attachment_popper">
                          <div title="Document" htmlFor="docDoc">
                            <label htmlFor="docDoc">
                              <SummarizeIcon />
                            </label>
                            <input
                              type="file"
                              accept="application/doc,
                        application/ms-doc,
                        application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/pdf,
                        application/excel,
                        application/vnd.ms-excel,
                        application/x-excel,
                        application/x-msexcel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/mspowerpoint,
                        application/powerpoint,
                        application/vnd.ms-powerpoint,
                        application/x-mspowerpoint,
                        application/vnd.openxmlformats-officedocument.presentationml.presentation"
                              id="docDoc"
                              name="docDoc"
                              onChange={(e) => sendAttachment(e)}
                              style={{ display: "none" }}
                            />
                          </div>
                          <div title="Video" >
                            <label htmlFor="videoDoc">
                              <VideocamIcon />
                            </label>
                            <input
                              type="file"
                              accept="video/mp4"
                              id="videoDoc"
                              name="videoDoc"
                              onChange={(e) => sendAttachment(e)}
                              style={{ display: "none" }}
                            />
                          </div>
                          <div title="Image">
                            <label htmlFor="photodoc">
                              <PhotoIcon />
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              id="photodoc"
                              name="photodoc"
                              onChange={(e) => sendAttachment(e)}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </Popover>
                    </>
                  )}
                </button>
              </div>

              <button
                className="message_btn send_btn"
                onClick={(e) => {
                  handleSendMessage(e);
                }}
              >
                <img src={sendbtn} alt="send" className="send_btn_img" />
              </button>
            </label>
          </form>
        </div>
      </div>
      <Modal
        className="Modal_root"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center">
            <p className="p_small_white">Share meeting link</p>
            <p className="p_small_white p_button">
              <a
                style={{ color: "inherit" }}
                href={`https://incubation.zefyron.com/meet/${meet_id}`}
                target="_blank"
                rel="noreferrer"
              >{`https://incubation.zefyron.com/meet/${meet_id}`}</a>
              <>&nbsp;&nbsp;&nbsp;</>{" "}
              <span>
                {" "}
                <CopyToClipboard text={`https://incubation.zefyron.com/meet/${meet_id}`}>
                  <ContentCopyIcon />
                </CopyToClipboard>
              </span>
            </p>
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href={`https://incubation.zefyron.com/meet/${meet_id}?host=true`}
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="gradient_CTA"
                onClick={() => sendVideoLink(meet_id)}
              >
                START VIDEO CALL
              </button>
            </a>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default index;
