import Skeleton from '@mui/material/Skeleton';
export const FeedLoader = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 3; index++) {

               list.push(
                    <>
                    <div className="dashboard01_block mt-0 post_root">
                    <div className="post_header_root">
                              <div className="post_avatar_main">
                                   <Skeleton className='post_avatar post_avatar2 profile_img' animation="wave" variant="wave" width={50} height={50} />
                              </div>
                              <div className="post_header_info">
                                   <Skeleton animation="wave" variant="wave" width={200} height={25} />
                                   <Skeleton animation="wave" variant="wave" width={100} height={25} />
                              </div>
                              
                         </div>
                         <div className="post_body_root mt-3">
                              <Skeleton animation="wave" variant="wave" width="100%" height={300} />

                              <div className='mt-3'>
                              <Skeleton animation="wave" variant="wave" width="100%" height={40} />
                              </div>
                         </div>
                    </div>
                         
                    </>
               )
          }
          return list;
     }
     return (

          <>
               {renderSkeleton()}
          </>



     )
}