import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { KeyboardArrowLeft } from "@mui/icons-material";
import Query from "../../../../../Utils/Query/Query";
import Loader from "../../../../../Components/Loader";

const ViewInvestorProfile = () => {
    const [investor, setInvestor] = useState(null);
    const { setSessionExpire } = useAuth();
    const query = Query();
    const investorid = query.get('investor')
    // const navigate = useNavigate()
    const [isLoading,setIsLoading] = useState(false)
    const fetchinvestor = () => {
        setIsLoading(true)
        Axios({
            url: `/api/investor/investorbyid/${investorid}`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const { data } = res;
                setInvestor(data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    setSessionExpire(true);
                    setIsLoading(false)
                }
            });
    };

    useEffect(() => {
        if (investorid) {
            fetchinvestor();
        }
    }, [investorid]);

    return (
        <>
            {
                isLoading ? <Loader/> : <>
                    {/* <div>
                <div
                    className="back_CTA pointer"
                    onClick={() => {
                        setStep(0);
                        navigate(`/dashboard/${user.type}?tab=10&mm=1`);
                    }}
                >
                    <p className="caption color_light_grey">
                        <span>
                            <KeyboardArrowLeft />
                        </span>
                        <span>BACK</span>
                    </p>
                </div>
            </div>
            <hr /> */}
            {
                investor && <div className="row">
                    <div className="col-lg-7 col-12">
                        <div className="connection_profile_root" id="connection_profile_root">
                            <div className="row mt-2 mb-24 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                                <div className="row no-margin align-items-center col-8">
                                    <div className="width_auto no-padding">
                                        <Avatar
                                            src={investor.profilePic}
                                            className=""
                                            alt="Profile"
                                            sx={{ width: 90, height: 90 }}
                                        />
                                    </div>
                                    <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                                        <p className="p_spartan_20">{investor.name}</p>
                                        <p className="bold">investor | {investor.location.country}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-4 mx-2">
                                <p className="grey bold">{investor.description}</p>
                            </div>

                            <div className="row mx-2 my-3">
                                {investor.interestedDomains ? (
                                    <>
                                        {investor.interestedDomains.map((industry, i) => (
                                            <p className="p_grey_chip" key={i}>
                                                {industry}{" "}
                                            </p>
                                        ))}
                                    </>
                                ) : null}
                            </div>
                            <hr />

                            <div className="row no-margin p-2 white_bg">
                                <div className=" no-padding">
                                    <div className="connections_highlights_investor">
                                        {investor.involvement ? (
                                            <>
                                                <div>
                                                    <p className="p_spartan_20"> Prefered Involvement </p>
                                                    {investor.involvement.length > 0 ? (
                                                        <div className="mt-4">
                                                            {investor.involvement.map((inv, i) => (
                                                                <p className="" key={i}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        fill="currentColor"
                                                                        className="bi bi-dot"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                                    </svg>
                                                                    {inv.name}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="connection_profile_root_highligts">
                            <div className="connection_profile_root_highligts_head">
                                <p className="p_spartan_20">Highlights</p>
                            </div>
                            <div className="connection_profile_root_highligts_desc">
                                <table>
                                    <tr>
                                        <td className="td_left">Invested Value</td>
                                        <td className="td_right">{investor.investedValue}</td>
                                    </tr>
                                    <tr>
                                        <td className="td_left">LinkedIn</td>
                                        <td className="td_right" style={{ wordBreak: "break-all" }}>
                                            <a
                                                href={`//${investor.socialConnects.linkedProfile}`}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ fontSize: 12 }}
                                            >
                                                {investor.socialConnects.linkedProfile}
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }

                </>

            }

        </>
    );
}

export default ViewInvestorProfile