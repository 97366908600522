import React, { useEffect, useState } from 'react'
import Card from "./AcceptedCard";
import search from "../../../../Assets/images/icons/search.png";
import Axios from "axios"
import { useAuth } from "../../../../Utils/Auth";
import IncubatorExplore  from '../../../../Components/SkeletonLoader/IncubationProgramSkeleton'
const ViewCourses = (props) => {
  const { setIncubationState } = props
  const [programs, setPrograms] = useState([]);
  const { setSessionExpire } = useAuth();
  const [isLoading,setIsLoading] = useState(false)
  const fetchAllPrograms = async () => {
    setIsLoading(true)
    try {
      await Axios({
        url: `/api/incubatorProgram/Allprograms`,
        method: `get`
      }).then((res) => {
        setPrograms(res.data.data);
        setIsLoading(false)
      }).catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      })
    } catch (error) {
      setSessionExpire(true);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAllPrograms();

  },[])

  return <div className="incubation_program_root">
    <div className="incubation_prorgram_search_head">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-10">
          <div className="incubation_programm_searchBoxDiv">
            <input type="text" name="" id="" />
            <button className="p_Bold_secBlue14"><img src={search} alt="search" />Search</button>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-2">
          <div className="incubation_program_filterDiv">
            {/* filter...... */}
          </div>
        </div>
      </div>
    </div>
    <div className="incubation_profile_program_main">
      <div className="incubation_program_main mt-4">
        <div className="row">
          {
            isLoading ? <IncubatorExplore/> : (programs && programs.length > 0 ?
              programs.map((program, i) => <>
                {
                  !program.isDeleted ? <Card
                    key={i}
                    program={program}
                    setIncubationState={setIncubationState}
                  /> : null
                }
              </>) : null)
          }
        </div>
      </div>
    </div>
  </div>
}

export default ViewCourses