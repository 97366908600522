import React,{useEffect} from "react";
import "./../Components/Css/common.css";
import "./Registration/authentication.css";
import logo from "./../Assets/images/logo/logo.png";
import thankyou_img from "./../Assets/images/thankyou.png";
// import Navbar from "./../Layout/Navbar/Navbar";
// import BackgroundImg from './Home/backgroundImg';
import { NavLink } from "react-router-dom";
import {useAuth }from '../Utils/Auth';
const ThanksPage = () => {
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const {signOut } = useAuth();
    useEffect(()=>{
        signOut()
    },[])
    return (
        <>
            {/* <Navbar /> */}
            <div className="auth_back_up">
                <div className="container">
                <div className="row mt-5">
                    <a href="/"><img src={logo} alt="logo" /></a>
                </div>
                <div className="row mt-5">
                <div className="col-12 mt-5">
                    <h2 className="auth_back_head">That&#39;s it !</h2>
                </div>
                    <div className="col-12 mt-5 row">
                    <div className="col-12 col-md-5 mt-5">
                        <p className="auth_back_p">Thank you for registering at Zefyron Incubation & we’re looking forward to have you onboard.</p>

                        <p className="auth_back_p">Please hold on until we verify your details and give you access to your dashboard.</p>
                        <div className="d-flex">
                        <NavLink to={`/`} className="gradient_CTA">
                            {" "}
                            GO TO HOME{" "}
                        </NavLink>
                        <NavLink to={`/about`} style={{marginTop:"10px"}} className="ml-md-5 gradient_btn_main">
                            <div className="gradient_btn_light">{" "}ABOUT US{" "}</div>
                        </NavLink>
                        </div>
                    </div>
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-5">
                            <img
                                className={` img-fluid smooth-image auth_option_img image-${
                                    imageLoaded ? "visible" : "hidden"
                                } shift_img`}
                                onLoad={() => setImageLoaded(true)}
                                src={thankyou_img}
                                alt="thankyou"
                            />
                    </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* <BackgroundImg /> */}
        </>
    );
};

export default ThanksPage;
