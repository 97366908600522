import React, { useState } from 'react'
import logo from "./../../../Assets/images/logo/logo.png"
import "./forget.css";
import check_mail_img from "./../../../Assets/images/check_mail.png"
import { useNavigate } from "react-router-dom";

const CheckEmail = () => {
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);


    return (
        <>
            <div className="auth_back_part pt-5">
                <div className="container mt-5">
                <div className="row mt-5">
                    <div className="col-12 col-md-6">
                        <a href="/"><img src={logo} /></a>
                        <img
                            className={` img-fluid smooth-image auth_option_img image-${
                                imageLoaded ? "visible" : "hidden" 
                            } auth_image`}
                            onLoad={() => setImageLoaded(true)}
                            src={check_mail_img }
                            alt="check_mail_img "
                        />
                        <div className="auth_back" onClick={()=>{navigate(-1)}}>
                        <i className="fa fa-arrow-left"></i>
                        Back
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pl-md-5 mt-5 mt-md-0 d-flex flex-column justify-content-center">
                    <div className="auth_back_loginform-head">
                         <h3>Check in your mail!</h3>
                     </div>
                     <p className="auth_back_loginform-para">We just mailed you with the instructions to reset your password.
                        <br /><br />
                        For any queries please email us at 
                        <br />
                        <span>support@zefyron.com</span>
                     </p>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckEmail