import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import { Avatar } from "@mui/material";
// import { KeyboardArrowLeft } from "@mui/icons-material";
import Query from "../../../../../Utils/Query/Query";
import Loader from "../../../../../Components/Loader";
// import { useNavigate } from "react-router-dom";

const ViewMentorProfile = () => {
    const [mentor, setmentor] = useState(null);
    const { setSessionExpire } = useAuth();
    const [isLoading,setIsLoading] = useState(false)
    const query = Query()
    const mentorid = query.get('mentor')
// const history = useNavigate()
    const fetchmentor = () => {
        setIsLoading(true)
        Axios({
            url: `/api/mentor/mentorbyid/${mentorid}`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const { data } = res;
                setmentor(data);
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setSessionExpire(true);
                    setIsLoading(false)
                }
            });
    };

    useEffect(() => {
        if (mentorid) {
            fetchmentor();
        }
    }, [mentorid]);


    return (
        <>
            {
                isLoading ? <Loader/> : <>
                    {/* <div>
                <div
                    className="back_CTA pointer"
                    onClick={() => {
                        setStep(0);
                        history(`/dashboard/${user.type}?tab=10&mm=1`);
                    }}
                >
                    <p className="caption color_light_grey">
                        <span>
                            <KeyboardArrowLeft />
                        </span>
                        <span>BACK</span>
                    </p>
                </div>
            </div>
            <hr /> */}
            {
                mentor && <div className="row">
                    <div className="col-lg-7 col-12">
                        <div className="connection_profile_root" id="connection_profile_root">
                            <div className="row mt-2 mb-24 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                                <div className="row no-margin align-items-center col-8">
                                    <div className="width_auto no-padding">
                                        <Avatar
                                            src={mentor.profilePic}
                                            className=""
                                            alt="Profile"
                                            sx={{ width: 90, height: 90 }}
                                        />
                                    </div>
                                    <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                                        <p className="p_spartan_20">{mentor.name}</p>
                                        <p className="bold">Mentor | {mentor.location.country}</p>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-4 mx-2">
                                <p className="grey bold">{mentor.description}</p>
                            </div>

                            <div className="row mx-2 my-3">
                                {mentor.interestedDomains ? (
                                    <>
                                        {mentor.interestedDomains.map((industry, i) => (
                                            <p className="p_grey_chip" key={i}>
                                                {industry}{" "}
                                            </p>
                                        ))}
                                    </>
                                ) : null}
                            </div>
                            <hr />

                            <div className="row no-margin p-2 white_bg">
                                <div className=" no-padding">
                                    <div className="connections_highlights_investor">
                                        {mentor.involvement ? (
                                            <>
                                                <div>
                                                    <p className="p_spartan_20"> Prefered Involvement </p>
                                                    {mentor.involvement.length > 0 ? (
                                                        <div className="mt-4">
                                                            {mentor.involvement.map((inv, i) => (
                                                                <p className="" key={i}>
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        fill="currentColor"
                                                                        className="bi bi-dot"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                                    </svg>
                                                                    {inv.name}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="connection_profile_root_highligts">
                            <div className="connection_profile_root_highligts_head">
                                <p className="p_spartan_20">Highlights</p>
                            </div>
                            <div className="connection_profile_root_highligts_desc">
                                <table>
                                    <tr>
                                        <td className="td_left">Current Company</td>
                                        <td className="td_right">{mentor.currentCompany}</td>
                                    </tr>

                                    <tr>
                                        <td className="td_left">Current Job Role</td>
                                        <td className="td_right">{mentor.currentJobRole}</td>
                                    </tr>
                                    <tr>
                                        <td className="td_left">Past Experience</td>
                                        <td className="td_right">{mentor.pastExperience}</td>
                                    </tr>
                                    <tr>
                                        <td className="td_left">Industry</td>
                                        <td className="td_right">{mentor.industry}</td>
                                    </tr>

                                    <tr>
                                        <td className="td_left">Linkedin</td>
                                        <td className="td_right"><a href={`//${mentor.linkedin}`} target="_blank" rel="noopener noreferrer" className="incubation_chips">Open</a></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> 
            }

                </>
            }


        </>
    );
}

export default ViewMentorProfile