import React, { useState } from 'react';
import AttachmentIcon from '@mui/icons-material/Attachment';
import LaunchIcon from '@mui/icons-material/Launch';
const ListHoverElements = ({attach}) => {
     const [isHover, setIsHover] = useState(false)
     const handleMouseEnter = () => {
          setIsHover(true);

     }

     const handleMouseOut = () => {
          setIsHover(false);
     };
     const handleNavigate = (link) =>{
          window.open(link, '_blank');
     }
     return (
          <div className='d-flex align-items-center my-2'style={{cursor:'pointer'}} onMouseOver={handleMouseEnter} onMouseOut={handleMouseOut} onClick={()=>handleNavigate(attach.link)}>
               <AttachmentIcon /> <p  className='mx-2' >{attach.name.split('.')[0]}</p> {isHover && <LaunchIcon />}
          </div>
     )
}

export default ListHoverElements;