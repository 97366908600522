import React, { useEffect, useState } from 'react'
import DeclinedCard from './DeclinedCard';
import Axios from 'axios';
import { useAuth } from '../../../../../Utils/Auth';

const Declined = ({ setView }) => {
  const [allDeclineds, setAlldeclined] = useState([]);
  const { setSessionExpire } = useAuth();

  const getAllDeclinedRequests = async () => {
    await Axios({
      url: `/api/asset/asset/declined/${true}`,
      method: 'get',
    }).then((res) => {
      const { data } = res.data;
      setAlldeclined(data);
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    })
  }

  useEffect(() => {
    getAllDeclinedRequests()
  }, [])

  return <div className="incubator_assessment_root">
    <div className="incubator_assessment_main">
      <div className="incubator_assessment_tableRoot">
        <table className="incubator_assessment_table">
          <thead className="incubator_assessment_thead">
            <tr className="incubator_assessment_thead_tr">
              <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                <p className="body2 color_dark_grey2">Requested for</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                <p className="body2 color_dark_grey2">Incubator</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                <p className="body2 color_dark_grey2">Requested on </p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_score">
                <p className="body2 color_dark_grey2">Price</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_view text-end pr-24">
                {/* <p className="body2 color_dark_grey2">Details</p> */}
              </td>
            </tr>
          </thead>
          <tbody className="incubator_assessment_tbody">
            {
              allDeclineds && allDeclineds.length > 0 ?
                allDeclineds.map((data, i) => <DeclinedCard
                  key={i}
                  data={data}
                  setAlldeclined={setAlldeclined}
                  setView={setView}
                />) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
}

export default Declined