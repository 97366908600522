import React, { useState } from "react";
import logo from "./../../../Assets/images/logo/logo.png";
import "./forget.css";
import forget_img from "./../../../Assets/images/forgot_password.png";
import { toast } from "react-toastify";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "./../../../Utils/Auth";

const ForgetPassword = () => {
  // const { setLoadCurrent, loadCurrent } = useAuth();
  const [disable, setdisable] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    Axios({
      url: "/api/user/forget-password",
      method: "post",
      data: { email },
    })
      .then((res) => {
        if (res.data.success === true) {
          toast.success("Reset email sent");
          navigate("/check-email");
          setdisable(true);
        }
      })
      .catch((err) => {
        if (err.response.data.success === false)
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        else toast({ msg: "Something went wrong, try again later", code: 0 });
      });
  };

  return (
    <>
      <div className="auth_back_part pt-5">
        <div className="container mt-5">
          <div className="row mt-5">
            <div className="col-12 col-md-6">
              <a href="/">
                <img src={logo} />
              </a>
              <img
                className={` img-fluid smooth-image auth_option_img image-${
                  imageLoaded ? "visible" : "hidden"
                } auth_image`}
                onLoad={() => setImageLoaded(true)}
                src={forget_img}
                alt="forget_img"
              />
              <div
                className="auth_back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="fa fa-arrow-left"></i>
                Back
              </div>
            </div>
            <div className="col-12 col-md-6 pl-md-5 mt-5 mt-md-0 d-flex flex-column justify-content-center">
              <div className="auth_back_loginform-head">
                <h3>Forgot your password ?</h3>
              </div>
              <p className="auth_back_loginform-para">
                Enter the email address you used when you joined and we’ll send
                you instructions to reset your password.
                <br />
                <br />
                For security reaspomns, we do NOT store your password. So rest
                assured that we will never send your password via mail.
              </p>
              <form className="row" onSubmit={handleOnSubmit}>
                <div className="auth_back_loginform col-12">
                  <label>Email Address</label>
                  <input
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email ID*"
                    value={email}
                  />
                </div>

                <div className="signup-btn col-12">
                  <button
                    type="submit"
                    className="gradient_CTA"
                    disabled={disable}
                  >
                    SEND RESET INSTRUCTIONS
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
