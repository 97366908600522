import React from 'react'
import { useAuth } from '../../../Utils/Auth'
import UpcommingEvents from '../../Components/UpcommingEvents'
// import { useNavigate } from 'react-router-dom';
import HomeConnections from '../../Components/HomeRequests'
// import add from "../../../Assets/images/Home/add.png"
// import addLogo from "../../../Assets/images/Home/addLogo.png"
import Advertisement from "./../../Components/Advertisement"
import Recommendations from '../../Components/InvestorRecommendation/InvestorRecommendation'
import DashboardValuation from '../../Components/DashboardValuation';
import StartupInvestment from '../../Components/StartupInvestment/StartupInvestment'

export default function Home({ seteventState, setReportState, setconnectionState, setValuationStep, setActiveStep }) {
    // const navigate = useNavigate()
    const { user } = useAuth()
    return (
        <div>
            <div className='row'>
                <div className='col-lg-8 col-sm-12 p-0'>
                    <div className='dashboardhome01_banner1'>
                        <h4 className='color_dark_blue'>Hi {user.firstname}</h4>
                        <h6 className='color_dark_blue'>Welcome to Zefyron Incubation!</h6>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mt-16'>
                    <Advertisement />
                </div>
            </div>

            <div className="row mt-16">
                <div className="col-lg-6 col-12 mt-16">
                    <DashboardValuation setReportState={setReportState} setValuationStep={setValuationStep} setActiveStep={setActiveStep} />
                </div>
                <div className="col-lg-6 col-12 mt-16">
                    <Recommendations />
                </div>
            </div>
            <div className="row mt-16">
                <div className="col-lg-4 col-sm-12">
                    <UpcommingEvents seteventState={seteventState} />
                </div>
                <div className="col-lg-3">
                    <HomeConnections setconnectionState={setconnectionState} />
                </div>
                <div className="col-lg-5">
                    <StartupInvestment/>
                </div>
            </div>


            {/* <div className="row">

                <div className="col-lg-8 col-sm-12">
                    <HomeConnections />
                    <div className='row m-0'>
                        <div className='col-lg-6 col-sm-12 p-0'>
                            <div className='dashboard01_block py-3'>
                                <p className='font18 bold'>
                                    Have highly resourceful programs
                                    spanning industries and geographies right here!
                                </p>
                                <button className='mt-4 mb-2 twf_gradoutlinedbtn text-dark' onClick={() => {
                                    setmentorState(1)

                                    navigate(`/dashboard/${user.type}?tab=5`)

                                }}>
                                    ADD PROGRAM
                                </button>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 ps-2'>
                            <div className='dashboardhome01_banner2'>
                                <p className='font18 bold'>
                                    Explore our network
                                    <br /> to
                                    find curated
                                    <br /> connections !
                                </p>
                                <p className='bold font12 mt-2' onClick={() => {
                                    setconnectionState(1)
                                    navigate(`/dashboard/${user.type}?tab=2`)
                                }}>
                                    Find Connections
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
