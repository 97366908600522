import React, { useEffect } from "react";
import Loader from "./../../Components/Loader";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "./../Auth";
import NotFoundPage from "../../Pages/404Pages";
import axios from "axios";
import { toast } from "react-toastify";
export default function UserCheck() {
  const { user, setRole, setName, profile, isLoading, redirectId, redirect, redirectUpdateId,setRedirect,redirectType,meetRedirect,pageredirect,setMeetRedirect,setPageRedirect } = useAuth();
  const { role } = useParams();
  const navigate = useNavigate();
  setRole(role.toLowerCase());
  useEffect(() => {
    if (!isLoading) {
      if (user!=null) {
        if (profile) {
          if (profile.isVerified) {
            console.log("kyu nahi")
            if(meetRedirect.status===true){
              console.log(meetRedirect.link)
              
              navigate(meetRedirect.link)
              setMeetRedirect({status:false,link:""})
              return
             }
             if(pageredirect.status===true){
              console.log(pageredirect.link)
              navigate(pageredirect.link)
              setPageRedirect({status:false,link:""})
              return
             }
            if (redirect === null) {
              navigate(`/dashboard/${role}?tab=1920`);
            } else if (redirect === false) {
              axios({
                method: "put",
                url: `/api/user/update/redirection/${redirectUpdateId}`,
                data:{
                  completed:true
                }
              })
                .then(() => {
                  if(redirectType === 'event'){
                    navigate(`/dashboard/${user.type}?tab=6&event=${redirectId}&event-state=${1}`)
                  }else{
                    navigate(`/dashboard/${user.type}?tab=7&id=${redirectId}&program-state=${1}`)
                  }
                  toast.success(`Redirect to the ${redirectType}`)
                  setRedirect(null)
                })
                .catch(() => {
                  toast.error('Something went wrong')
                });     
            }

          } else if (!profile.isVerified) {
            navigate("/thankyou");
          }
        } else {
          setName(user.firstname);
          navigate("/welcome");
        }
      } else {
        return <NotFoundPage />;
      }
    }
  }, [isLoading, profile]);

  return (
    <div
      style={{
        display: "grid",
        textAlign: "center",
        height: "100vh",
        padding: "40vh 0 0",
        background: "#000",
      }}
    >
      <Loader />
    </div>
  );
}
