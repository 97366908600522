import React,{useState,useRef} from 'react';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { useNavigate } from "react-router-dom";
import './UserProfile.css'
import { useAuth } from '../../Utils/Auth';
import axios from "axios";
import { toast } from 'react-toastify';
const UserProfile = ({user,signOut,navType}) =>{
    const {redirectId,redirect,setRedirect,redirectUpdateId,profile,redirectType} = useAuth();
    // console.log('here is imp',redirectId,redirect)
     const navigate = useNavigate();
     const gotodashbord = () => {
      if(redirect === null){
        navigate(`/dashboard/${user.type}`)
      }else if(redirect === false && profile.isVerified){
        axios({
          method: "put",
          url: `/api/user/update/redirection/${redirectUpdateId}`,
          data:{
            completed:true
          }
        })
          .then(() => {
            if(redirectType === 'event'){
              navigate(`/dashboard/${user.type}?tab=6&event=${redirectId}&event-state=${1}`)
            }else{
              navigate(`/dashboard/${user.type}?tab=7&id=${redirectId}&program-state=${1}`)
            }
            
            setRedirect(null)
          })
          .catch(() => {
            toast.error('Something went wrong')
          });
      }
         
      };
     const [open, setOpen] = useState(false);
     const anchorRef = useRef(null);
     const handleToggle = () => {
          setOpen((prevOpen) => !prevOpen);
        };
        const handleClose = (event) => {
          if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
          }
          setOpen(false);
        };
        function handleListKeyDown(event) {
          if (event.key === "Tab") {
               
            event.preventDefault();
            setOpen(false);
          }
        }
     return (
         <>
         <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={`${navType==='home'? 'dashNav_profile':'dashNav_profile_heighted'}`}
        >
          {user ? (
            <>
              <Avatar src={user.avatar} />
            </>
          ) : (
            <>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </>
          )}

          {open ? <ExpandLess className={navType==='home'?'expand':'expand-black'} /> : <ExpandMore className={navType==='home'?'expand':'expand-black'} />}
        </Button>
        <Popper
          open={open}
          style={{ zIndex: "200", marginLeft: "10px" }}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                marginLeft: "-5px",
              }}
            >
              <Paper style={{ zIndex: "200" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={() => {
                        gotodashbord();
                        setOpen(false);
                      }}
                    >
                      Dashboard
                    </MenuItem>
                    
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        </>
     )
}

export default UserProfile