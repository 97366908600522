import React,{useState,useEffect} from "react";
import LeadTarget from './LeadTarget'
import LeadCategory from './LeadCategory';
import Leads from './Leads';
import Query from "../../Utils/Query/Query";
import Axios from "axios";
import { toast } from "react-toastify";

const LeadGeneration = () => {
  const query = Query();
  const targetId = query.get('target')
  const tab = query.get('tab')
  const [target,setTarget] = useState({})

  const fetchTarget = async () => {
    const { data } = await Axios({
         method: "get",
         url: `/api/leadGeneration/leadTargets/${targetId}`,
    })
    if (data.success) {
          setTarget(data.data)
    } else {
         toast.error('Error while fetching targets')
    }

}
  useEffect(()=>{
    if(targetId){
      fetchTarget()
    }
  },[targetId])
  const [generationStep,setGenerationStep]  = React.useState(0);
  
  return (
      generationStep === 0 ? <div className="startUp_incubation_tool_root">
        <LeadTarget setGenerationStep={setGenerationStep} tab={tab} generationStep={generationStep}/>
      </div> : generationStep === 1 ? <div className="startUp_incubation_tool_root">
        <LeadCategory setGenerationStep={setGenerationStep} tab={tab} generationStep={generationStep} target={target}/>
      </div> : generationStep === 2 && <div className="startUp_incubation_tool_root">
        <Leads setGenerationStep={setGenerationStep} tab={tab} generationStep={generationStep} target={target}/>
      </div>
    
    
  );
};

export default LeadGeneration;