import React, { useEffect, useState } from "react";
import "./dashRevampnav.css";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { useAuth } from "../../../Utils/Auth";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router";
import Notifications from "../../../Components/Notifications/whitebgNotyf";
import UserProfile from '../../../Components/UserProfile/UserProfile';
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function DashNav() {
  const { signOut, user, notificationsCount, getUser } = useAuth();
  React.useEffect(() => {
    if (!user) getUser();
  }, []);

  const [openNotyf, setOpenNotyf] = React.useState(false);
  const [cStep, setCStep] = useState(0);
  const anchorRef = React.useRef(null);
  const anchorRefNotf = React.useRef(null);

  const handleNotyfToggle = () => {
    setOpenNotyf((prevOpenNotyf) => !prevOpenNotyf);
  };
  const query = useQuery();
  var currentStep = Number(query.get("tab"));

  useEffect(() => {
    if (currentStep) {
      setCStep(currentStep);
    }
  }, [currentStep]);

  const handleCloseNotyf = (event) => {
    if (anchorRefNotf.current && anchorRefNotf.current.contains(event.target)) {
      return;
    }
    setOpenNotyf(false);
  };

  function handleListKeyDownNotyf(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenNotyf(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const prevOpenNotyf = React.useRef(openNotyf);

  React.useEffect(() => {
    if (prevOpenNotyf.current === true && openNotyf === false) {
      anchorRefNotf.current.focus();
    }
    prevOpenNotyf.current = openNotyf;
  }, [openNotyf]);

  if (!user) return <p></p>;

  return (
    <div className="dashboard01_nav row">
      <div className=" col-lg-6 col-md-6 col-sm-4 col-xs-0"></div>
      <div className="col-lg-6 col-sm-8 col-md-6 col-xs-12 text-end">
        
        {
          cStep === 1920 ?
            <Link to={`/dashboard/${user.type}?tab=1920`} className='feed_nav_cta'>
              FEED
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#ff6f1e"
                xmlns="http://www.w3.org/2000/svg"
                className=""
              >
                <path d="M3 3V21H21V3H3ZM18 18H6V17H18V18ZM18 16H6V15H18V16ZM18 12H6V6H18V12Z" fill="#FF6F1E" />
              </svg>
              {/* </p> */}

            </Link> :
            <Link
              to={`/dashboard/${user.type}?tab=1920`}
              className="feed_nav_cta"
            >
              FEED
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#99B3D6"
                xmlns="http://www.w3.org/2000/svg"
                className=""
              >
                <path
                  d="M3 3V21H21V3H3ZM18 18H6V17H18V18ZM18 16H6V15H18V16ZM18 12H6V6H18V12Z"
                  fill="#99B3D6"
                />
              </svg>
            </Link>
        }
        {
          user.type === "student" ? null : cStep === 1158 ? (
            <Link to={`/dashboard/${user.type}?tab=1158`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ff6f1e"
                className="bi bi-chat-left-text-fill mx-3"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
              </svg>
            </Link>
          ) : (
            <Link to={`/dashboard/${user.type}?tab=1158`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#99B3D6"
                className="bi bi-chat-left-text-fill mx-3"
                viewBox="0 0 16 16"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
              </svg>
            </Link>
          )
        }

        <Button
          ref={anchorRefNotf}
          aria-controls={openNotyf ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleNotyfToggle}
          style={{ margin: "0 px" }}
        >
          {notificationsCount ? (
            <Badge badgeContent={notificationsCount} color="primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#99B3D6"
                className="bi bi-bell-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
              </svg>
            </Badge>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#99B3D6"
              className="bi bi-bell-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
            </svg>
          )}
        </Button>
        <Popper
          open={openNotyf}
          style={{ zIndex: "200" }}
          anchorEl={anchorRefNotf.current}
          role={undefined}
          transition
          className="dashNav_popper"
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper style={{ zIndex: "200" }}>
                <ClickAwayListener onClickAway={handleCloseNotyf}>
                  <MenuList
                    autoFocusItem={openNotyf}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDownNotyf}
                  >
                    <Notifications />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        &nbsp;

        <UserProfile user={user} signOut={signOut} />
      </div>
    </div>
  );
}
DashNav.propTypes = {
  value: PropTypes.string.isRequired,
};
