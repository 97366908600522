import React, { useEffect, useState } from "react";
import "./index.css";
import questions from "./questions.json";
import { FormContext } from "./Context/FormContext";

import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Axios from "axios";
// import Loader2 from "../../components/Loader2";
import { useAuth } from "../../../Utils/Auth";
import { useNavigate, useLocation } from "react-router-dom";
// import ValuationNav from "./ValuationNav";
import ReactGa from 'react-ga'
import { ChevronRightRounded } from '@mui/icons-material';
// import CircularProgress from '@mui/material/CircularProgress';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const index = (props) => {
  const { ActiveStep, handleNext, setValuationStep,setActiveStep } = props

  useEffect(() => {
    ReactGa.initialize('UA-214453456-1')
    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])

  let query = useQuery();
  const navigate = useNavigate();
  const { profile, isLoading, user } = useAuth();
  const [questionnare, setQuestionnare] = useState(null);
  const [Finantial, setFinantial] = useState(null);
  const [balanceSheet, setBalanceSheet] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [quesFilled, setFillQuestion] = useState(false);
  const [finFilled, setFillFinancial] = useState(false);
  const [labFilled, setFillBalance] = useState(false);
  const [transFilled, setFillTransaction] = useState(false);

  const tabNo = Number(query.get("tab"));

  useEffect(() => {
    // if (tabNo) {
      
    // }
  }, [tabNo]);

  const [fundingStatus, setFundingStatus] = useState({
    id: new Date().getTime().toString(),
    equity: "",
    capitalRaised: "",
    valuation: "",
    closingDate: "",
  });
  const [fundingRow, setFundingRows] = useState([]);



  useEffect(() => {
    setQuestionnare(questions[0]);
    setFinantial(questions[1]);
    setTransactions(questions[2]);
    setBalanceSheet(questions[3]);
  }, []);

  const getquestionnarie = async () => {
    Axios({
      method: "get",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
    }).then((res) => {
      if (res.data) {
        const {
          Team,
          BusinessModel,
          ProductMarket,
          fields,
          contents,
          IPLegal,
          assets: Assets,
          liabilities: Liabilities,
          fundingRounds,
          quesFilled,
          finFilled,
          labFilled,
          transFilled,
        } = res.data;
        if (Team.length > 0 && BusinessModel.length > 0 && ProductMarket.length > 0 && IPLegal.length > 0)
          setQuestionnare({ Team, BusinessModel, ProductMarket, IPLegal });
        if (fields.length > 0) setFinantial({ fields });
        if (contents.length > 0) setTransactions({ contents });
        if (Assets.length > 0 && Liabilities.length > 0)
          setBalanceSheet({ Assets, Liabilities });
        if (fundingRounds.length > 0) setFundingRows(fundingRounds);
        if (quesFilled) setFillQuestion(quesFilled);
        if (finFilled) setFillFinancial(finFilled);
        if (labFilled) setFillBalance(labFilled);
        if (transFilled) setFillTransaction(transFilled);
      }
    });
  };

  useEffect(() => {
    getquestionnarie();
  }, []);

  const handleChange = (id, event) => {
    const newElements = { ...questionnare };
    const { Assets = [], Liabilities = [] } = balanceSheet;

    newElements.Team.forEach((field) => {
      const { field_type, field_id, field_options = [] } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "select":
            field["field_value"] = event.target.value;
            field_options.forEach((option) => {
              if (option.option_label === event.target.value) {
                option.checked = true;
              } else {
                option.checked = false;
              }
            });
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
        return;
      }
    });

    // const newElements1 = { ...questionnare }
    newElements.BusinessModel.forEach((field) => {
      const { field_type, field_id, field_options = [] } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "select":
            field["field_value"] = event.target.value;
            field_options.forEach((option) => {
              if (option.option_label === event.target.value) {
                option.checked = true;
              } else {
                option.checked = false;
              }
            });
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
        return;
      }
    });

    newElements.ProductMarket.forEach((field) => {
      const { field_type, field_id, field_options = [] } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "select":
            field["field_value"] = event.target.value;
            field_options.forEach((option) => {
              if (option.option_label === event.target.value) {
                option.checked = true;
              } else {
                option.checked = false;
              }
            });
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
        return;
      }
    });

    newElements.IPLegal.forEach((field) => {
      const { field_type, field_id, field_options = [] } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "select":
            field["field_value"] = event.target.value;
            field_options.forEach((option) => {
              if (option.option_label === event.target.value) {
                option.checked = true;
              } else {
                option.checked = false;
              }
            });
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
        return;
      }
    });

    Assets.forEach((field) => {
      const { field_type, field_id, field_options = [] } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "select":
            field["field_value"] = event.target.value;
            field_options.forEach((option) => {
              if (option.option_label === event.target.value) {
                option.checked = true;
              } else {
                option.checked = false;
              }
            });
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
        return;
      }
    });

    Liabilities.forEach((field) => {
      const { field_type, field_id, field_options = [] } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "select":
            field["field_value"] = event.target.value;
            field_options.forEach((option) => {
              if (option.option_label === event.target.value) {
                option.checked = true;
              } else {
                option.checked = false;
              }
            });
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
        return;
      }
    });
    setBalanceSheet({ Assets, Liabilities });
    setQuestionnare(newElements);
  };

  const handleChangeFinancial = (id, event) => {
    const newFinancials = { ...Finantial };
    newFinancials.fields.forEach((field) => {
      const { past_year, forcast, forcast1, forcast2 } = field;
      if (id === past_year["id"]) {
        past_year["value"] = event.target.value;
      }
      if (id === forcast.id) {
        forcast.value = event.target.value;
      }
      if (id === forcast1.id) {
        forcast1.value = event.target.value;
      }
      if (id === forcast2.id) {
        forcast2.value = event.target.value;
      }
    });
    setFinantial(newFinancials);
  };

  const handletransaction = (id, event) => {
    const newTransactions = { ...transactions };
    newTransactions.contents.forEach((content) => {
      const { fields } = content;
      fields.forEach((field) => {
        if (id === field.field_id) {
          field.fieldvalue = event.target.value;
        }
      });
    });
    setTransactions(newTransactions);
  };

  const handleFundingStatus = (e) => {
    const { name, value } = e.target;
    setFundingStatus({ ...fundingStatus, [name]: value });
  };

  const AddFundingRow = () => {
    setFundingRows((prev) => [...prev, fundingStatus]);
    setFundingStatus({
      id: new Date().getTime().toString(),
      equity: "",
      capitalRaised: "",
      valuation: "",
      closingDate: "",
    });
  };
  const deleteFundingRow = (id) => {
    const newArr = fundingRow.filter((row) => {
      return id !== row.id;
    });
    setFundingRows(newArr);
  };

  // const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const [value, setValue] = useState(0);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormContext.Provider value={{ handleChange }}>
            <Step1
              handleChange={handleChange}
              questionnare={questionnare}
              handleNext={handleNext}
              quesFilled={quesFilled}
              setFillQuestion={setFillQuestion}
              setValuationStep={setValuationStep}
              // value={value}
              // setValue={setValue}
            />
          </FormContext.Provider>
        );
      case 1:
        return (
          <FormContext.Provider value={{ handleChange, handleChangeFinancial }}>
            <Step2
              Finantial={Finantial}
              balanceSheet={balanceSheet}
              handleNext={handleNext}
              finFilled={finFilled}
              labFilled={labFilled}
              setFillFinancial={setFillFinancial}
              setFillBalance={setFillBalance}
              setValuationStep={setValuationStep}
              // value={value}
              // setValue={setValue}
            />
          </FormContext.Provider>
        );
      case 2:
        return (
          <FormContext.Provider value={{ handletransaction }}>
            <Step3
              transactions={transactions}
              handleNext={handleNext}
              fundingStatus={fundingStatus}
              handleFundingStatus={handleFundingStatus}
              AddFundingRow={AddFundingRow}
              setFundingRows={setFundingRows}
              setFundingStatus={setFundingStatus}
              fundingRow={fundingRow}
              deleteFundingRow={deleteFundingRow}
              transFilled={transFilled}
              setFillTransaction={setFillTransaction}
              setValuationStep={setValuationStep}
              // value={value}
              // setValue={setValue}
            />
          </FormContext.Provider>
        );

      default:
        return "Unknown step";
    }
  }

  

  if (!isLoading) {
    if (user && profile) {
      return (
        <>
          <div className="services-main">
            {/* <ValuationNav /> */}
            <div className="valuation-root container">
              <div className="valuationForm">
                <div className="valuation_nameOfTheReportDiv">
                  <div className="valuation_nameOfTheReport">
                    {
                      localStorage.getItem('valuation_title') ? <h4>{localStorage.getItem('valuation_title')}</h4> : null
                    }
                    {
                      localStorage.getItem('valuation_cretedDate') ? <p>Created on - {new Date(localStorage.getItem('valuation_cretedDate')).toLocaleDateString()}</p> : null
                    }
                    
                  </div>
                  {/* <div className="valuation_progress_bar">
                    <div className="circlularProgress_main">
                        <div className="progress_percent">
                          <svg>
                            <circle cx={35} cy={35} r={35}></circle>
                            <circle cx={35} cy={35} r={35}></circle>
                          </svg>
                          <div className="progress_persentage_number">
                            <h4>40<span>%</span></h4>
                          </div>
                        </div>
                    </div>
                  </div> */}
                </div>
                <div className="stepperMain">
                  <div className="valuation-newStepper_root">
                    <div className="valuation-newStepper_main">
                      <span style={{cursor:"pointer"}} className={`${ActiveStep === 0 ? "color_black":"color_98"}`} onClick={()=>setActiveStep(0)} >Questionnaire</span>
                      <span><ChevronRightRounded /></span>
                      <span style={{cursor:"pointer"}} className={`${ActiveStep === 1 ? "color_black":"color_98"}`} onClick={()=>setActiveStep(1)} >Financials</span>
                      <span><ChevronRightRounded /></span>
                      <span style={{cursor:"pointer"}} className={`${ActiveStep === 2 ? "color_black":"color_98"}`} onClick={()=>setActiveStep(2)} >Funding Rounds</span>
                    </div>
                  </div>
                  {getStepContent(ActiveStep)}

                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          style={{
            height: "100vh",
            position: "relative",
          }}
        >
          <div
            className="text-center"
            style={{
              margin: "30vh auto",
            }}
          >
            <h5 className="h2_white_40">SESSION EXPIRED</h5>
            <button
              className="gradient_CTA"
              // onClick={() => history(`/login`)}
              onClick={() => navigate(`/login`)}
            >
              LOGIN AGAIN
            </button>
          </div>
        </div>
      );
    }
  } else return null;
};

export default index;
