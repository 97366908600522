import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
import getDiff from "../../../../Utils/diffleft/getDiff";
import Applicantcard from "./Applicantcard";
import { toast } from "react-toastify";

const DeclinedCard = ({ programid }) => {
  const [applicants, setapplicants] = useState([]);
  const { setSessionExpire } = useAuth();
  const [step, setstep] = useState(0);
  const [startupid, setstartupid] = useState("");
  const [requestid, setrequestid] = useState("");

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/incubatorProgram/declined/${programid}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const handledecline = async (term, id) => {
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/update/request/${id}`,
      data: {
        isDeclined: term,
        programid,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (term) toast.success("Applicant declined");
          else toast.success("Removed from declined");
        }
        fetchapplicants();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };

  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {step === 1 ? (
        <Applicantcard
          setstep={setstep}
          startupid={startupid}
          requestid={requestid}
          callbackfunc={fetchapplicants}
          programid={programid}
        />
      ) : (
        <>
          {applicants.length > 0 ? (
            <>
              {applicants.map((v, i) => {
                return (
                  <div
                    key={i}
                    className="col-12 incunation_shortlisted_main mb-4"
                  >
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-4 d-flex align-items-center">
                        <img
                          src={v.profile.logo}
                          alt="profile_short"
                          className="mr-2"
                          height={"100px"}
                          width={"100px"}
                        />
                        <p className="p_Bold_secBlue18_spartan">
                          {v.profile.companyName}
                        </p>
                      </div>
                      <div className="col-lg-1 col-md-1 col-2 d-flex justify-content-center align-items-center">
                        <p
                          className="p_LightGrey12"
                          style={{ color: "#565656" }}
                        >
                          Startup
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-3 d-flex align-items-center">
                        <div className="incubation_chips">
                          {v.profile.industry}
                        </div>
                        <div className="incubation_chips">
                          {" "}
                          {v.profile.audience}
                        </div>
                      </div>
                      <div className="col-lg-1 col-md-2 col-2 d-flex justify-content-center align-items-center">
                        <p
                          className="p_LightGrey12"
                          style={{ color: "#565656" }}
                        >
                          {getDiff(v.updatedAt)}
                        </p>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handledecline(false, v._id);
                        }}
                        className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
                      >
                        <p className="transparntChip mr-2">
                          REMOVE FROM DECLINED
                        </p>
                      </div>
                      <div
                        onClick={() => {
                          setstep(1);
                          setstartupid(v.profile._id);
                          setrequestid(v._id);
                        }}
                        style={{ cursor: "pointer" }}
                        className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
                      >
                        <p
                          className="p_LightGrey12"
                          style={{ color: "#565656" }}
                        >
                          See Profile
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default DeclinedCard;
