import React from "react";
// import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
import { AvatarGroup, Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const Card = (props) => {
  const { course, setLearningState } = props;
  // console.log(setLearningState) // temp use
  const navigate = useNavigate();
  const {programid} = useParams()
  return (
    <div
      className="col-lg-3 col-md-6 col-12 mb-3 point"
      onClick={() => {
        setLearningState(2);
        navigate(`/service/incprogram/${programid}?tab=6&course=${course._id}`);
      }}
    >
      <div className="onboarding_cards incubation_courses_card_root">
        <div className="incubation_courses_card_main">
          <div className="incubation_courses_card_type_main">
            <div className="incubation_courses_card_type_fill">

            </div>
            <div className="incubation_courses_card_type_text">
              <p className="body2">{course.tag[0]}</p>
            </div>
          </div>
          <div className="incubation_course_card_name mt-3">
            <p className="subtitle color_dark_blue">{course.title}</p>
          </div>
          <div className="incubation_course_card_enrolled mt-4">
            <p className="caption" style={{ color: "#989898" }}>Startups Enrolled</p>
            <div className="incubation_course_card_startups">
              <AvatarGroup total={4}>
                <Avatar src="" style={{ width: 38, height: 38 }} />
                <Avatar src="" style={{ width: 38, height: 38 }} />
                <Avatar src="" style={{ width: 38, height: 38 }} />
                <Avatar src="" style={{ width: 38, height: 38 }} />
              </AvatarGroup>
            </div>
          </div>
        </div>
        {/* <div className="incubation_courses_card_footer">
          <p className="overLine">Active</p>
        </div> */}
      </div>
    </div>
  );
};

export default Card;
