import React from "react"; //  , { useState }
// import { Link } from "react-router-dom";
// import Checkbox from '@mui/material/Checkbox';
// import profile_img from "../../Icons/profile.png"; 
// import CircularProgress from "@mui/material/CircularProgress";
import OTPService from "../../../../Utils/OTP";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import { useAuth } from "../../../../Utils/Auth";
// import {toast} from "react-toastify"; 
// import Axios from "axios";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import Avatar from "@mui/material/Avatar";

import Multiselect from "multiselect-react-dropdown";

const Step4 = (props) => {
  const {
    options,
    interestedIndustry,
    setinterestedIndustry,
    interestedmodel,
    setinterestedmodel,
    interestedstage,
    setinterestedstage,
    chequsize,
    setchequsize,
    phone,
    setPhone,
    setphoneVerified,
  } = props;

  const addInputEvent3 = (e) => {
    setinterestedIndustry(e.map((i) => i.label));
  };

  return (
    <form>

      <div className="register_page_form row">
        <div className="col-12" style={{ height: "100%" }}>
          <label className="auth_label">
            Interested Industry*
          </label>
          <Multiselect
            isMulti
            options={options}
            className=""
            displayValue="label"
            placeholder="Select Industries"
            name="industries"
            closeIcon="cancel"
            value={interestedIndustry}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>
        <div className="col-12">
          <label className="auth_label">
            Interested Business Models*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={interestedmodel}
            onChange={(e) => setinterestedmodel(e.target.value)}
          >
            <option className="color_black" value="">
              Select business model
            </option>
            <option className="color_black" value="Business to Consumer">
              Business to Consumer
            </option>
            <option className="color_black" value="Business to Business">
              Business to Business
            </option>
            <option className="color_black" value="Consumer to Business">
              Consumer to Business
            </option>
            <option className="color_black" value="Consumer to Consumer">
              Consumer to Consumer
            </option>
          </select>
        </div>
        <div className="col-12">
          <label className="auth_label">
            Interested Funding Stages*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={interestedstage}
            onChange={(e) => setinterestedstage(e.target.value)}
          >
            <option className="color_black" value="">
              Select funding stages
            </option>
            <option className="color_black" value="Pre-Seed">
              Pre-Seed
            </option>
            <option className="color_black" value="Seed">
              Seed
            </option>
            <option className="color_black" value="Series A">
              Series A
            </option>
            <option className="color_black" value="Series B">
              Series B
            </option>
            <option className="color_black" value="All">
              All of the above
            </option>
            <option className="color_black" value="Not sure">
              Not sure
            </option>
          </select>
        </div>
        <div className="col-12">
          <label className="auth_label">
            Average Investment Check Size*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={chequsize}
            onChange={(e) => setchequsize(e.target.value)}
          >
            <option className="color_black" value="">
              Select Investment Check Size
            </option>
            <option className="color_black" value="0 - 10 Thousands">
              0 - 10 Thousands
            </option>
            <option className="color_black" value="10 Thousands - 50 Thousands">
              10 Thousands - 50 Thousands
            </option>
            <option className="color_black" value="50 Thousands - 5 Millions">
              50 Thousands - 5 Millions
            </option>
            <option className="color_black" value="5 Millions - 20 Millions">
              5 Millions - 20 Millions
            </option>
            <option className="color_black" value="20 Millions - 100 Millions">
              20 Millions - 100 Millions
            </option>
            <option className="color_black" value="More than 100 Millions">
              More than 100 Millions
            </option>
          </select>
        </div>

      </div>
        
      <div className="register_page_form row px-3 py-0">
        <label>Phone Number</label>
        <OTPService
          phone={phone}
          setPhone={setPhone}
          setphoneVerified={setphoneVerified}
          className="col-12"
        />
      </div>
    </form>
  );
};

export default Step4;
