import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export const ParticipantsSkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 6; index++) {
               list.push(<div className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding">
                    <div style={{ cursor: "pointer" }} className={`dashboard_01_connections_list_item`}>
                         <div className="dashboard_01_connections_logo_list_block" style={{ backgroundColor: "#D9D9D9" }}>

                              <Skeleton animation="wave" variant="rounded" style={{
                                   borderRadius: "20%",
                                   textAlign: "center",
                                   justifyContent: "center",
                                   margin: "0 auto",
                                   width: "64px",
                                   height: "64px",
                              }} />

                         </div>

                         <div className="dashboard_01_connections_list_item_desc">

                              <div className="d-flex flex-column align-items-center">

                                   <Skeleton animation="wave" width="60%" height={40} />

                                   <Skeleton animation="wave" width="60%" height={20} />

                                   <Skeleton animation="wave" width="60%" height={20} />

                              </div>
                         </div>

                    </div>
               </div>)
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}
export const ParticipantsCardSkeleton = () => {
     return (
          <>
               <div className="dashboard01_block h-fit">
                    <Skeleton animation="wave" width="60%" height={40} />
                    <div className="row mx-0 my-2 r_a">
                         <div className="col-8">
                              <Skeleton animation="wave" width="60%" height={40} />
                         </div>
                         <div className="col-4">
                              <Skeleton animation="wave" variant="rounded" style={{
                                   borderRadius: "20%",
                                   textAlign: "center",
                                   justifyContent: "center",
                                   margin: "0 auto",
                                   width: "64px",
                                   height: "64px",
                              }} />
                         </div>
                    </div>
               </div>
          </>


     )
}
export const ParticipantsEmailInvite = () => {
     return (
          <>
               <Skeleton animation="wave" width="60%" height={40} />
          </>
     )
}

export const ParticipantsInvite = ({value}) => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < value; index++) {
               list.push(<div className="row mx-0 my-2 r_a">
                    <Skeleton variant="circular" width={40} height={40} />
                    <div className="col-8">
                         <Skeleton animation="wave" width="100%" height={50} />
                         
                    </div>
               </div>)
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>
     )
}
export const ParticipantsMentee = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 3; index++) {
               list.push(
              <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='d-flex align-items-center'>
                    <Skeleton variant="circular" width={35} height={35} />
                    
                         <Skeleton animation="wave" width={100} height={40} className='mx-2' />
                    </div>
               </div>
               )
          }
          return list;
     }
     return (
          
           <div className="row">
               {renderSkeleton()}
          </div>
         
     )
}



export const ParticipantsDetails = () => {
     return (
          <>
               <div className="col-12 mb-5">
                    <div className='d-flex'>
                         <Skeleton animation="wave" width={40} height={40} style={{ marginRight: "1rem" }} />
                         <Skeleton animation="wave" width="40%" height={40} />
                    </div>
                    <hr/>
               </div>
              
               <div className="col-12 row">
                    <div className="col-12 col-lg-8">
                         <div className="connection_profile_root" id="connection_profile_root">
                              <div className="row mt-2 justify-content-center connection_profile_head dashboard01_connection_profile_head align-items-center">
                                   {/* <div className="row no-margin align-items-center"> */}
                                   <div className="width_auto no-padding">
                                        <Skeleton animation="wave" variant="rounded" style={{
                                             borderRadius: "20%",
                                             textAlign: "center",
                                             justifyContent: "center",
                                             margin: "0 auto",
                                             width: "64px",
                                             height: "64px",
                                        }} />
                                   </div>
                                   <div className="col-10 d-flex flex-column justify-content-center">
                                        <Skeleton animation="wave" width={50} height={40} />
                                        <Skeleton animation="wave" width={80} height={30} />
                                   </div>
                                   {/* </div> */}
                                   {/* <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div> */}
                              </div>

                              <div className="row mx-2 my-3">
                                   <Skeleton animation="wave" width="100%" height={50} />

                                   <div className='d-flex align-items-center'>
                                        <Skeleton animation="wave" width={70} height={30} style={{ marginRight: "1rem" }} />
                                        <Skeleton animation="wave" width={50} height={30} />
                                   </div>
                              </div>

                              <hr />

                              <div className="mb-4">
                                   <>
                                        <p className="p_spartan_20 mx-2 my-4 founder-title">
                                             <Skeleton animation="wave" width={80} height={30} />
                                        </p>
                                        <div className='d-flex'>

                                             <div className="row mx-0 my-4 align-items-center col-8">

                                                  <div className="width_auto no-padding">
                                                       <Skeleton variant="circular" width={60} height={60} />
                                                  </div>
                                                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                                                       <Skeleton animation="wave" width={80} height={20} />
                                                       <Skeleton animation="wave" width={70} height={20} />
                                                       <Skeleton animation="wave" width={30} height={20} />
                                                  </div>
                                             </div>


                                             <div>
                                                  <Skeleton animation="wave" width={100} height={30} />
                                                  <div className='d-flex'>
                                                       <Skeleton animation="wave" width={50} height={40} style={{ marginRight: "1rem" }} />
                                                       <Skeleton animation="wave" width={60} height={40} />
                                                  </div>
                                             </div>
                                        </div>

                                   </>

                              </div>
                         </div>
                    </div>
                    <div className="col-12 col-lg-4">
                         <div className="connection_profile_root_highligts">
                              <div className="connection_profile_root_highligts_head">
                                   <Skeleton animation="wave" width="60%" height={40} />
                              </div>
                              <div className="connection_profile_root_highligts_desc">
                                   <table>
                                        <tr>
                                             <td className="td_left"><Skeleton animation="wave" width="60%" height={40} /></td>
                                             <td className="td_right"><Skeleton animation="wave" width="60%" height={40} /></td>
                                        </tr>

                                        <tr>
                                             <td className="td_left"><Skeleton animation="wave" width="60%" height={40} /></td>
                                             <td className="td_right"><Skeleton animation="wave" width="60%" height={40} /></td>
                                        </tr>
                                        <tr>
                                             <td className="td_left"><Skeleton animation="wave" width="60%" height={40} /></td>
                                             <td className="td_right"><Skeleton animation="wave" width="60%" height={40} /></td>
                                        </tr>
                                        <tr>
                                             <td className="td_left"><Skeleton animation="wave" width="60%" height={40} /></td>
                                             <td className="td_right">
                                                  <Skeleton animation="wave" width="60%" height={40} />
                                             </td>
                                        </tr>

                                        <tr>
                                             <td className="td_left"><Skeleton animation="wave" width="60%" height={40} /></td>
                                             <td className="td_right" style={{ wordBreak: "break-all" }}>
                                                  <Skeleton animation="wave" width="60%" height={40} />
                                             </td>
                                        </tr>
                                   </table>
                              </div>
                         </div>
                    </div>
               </div>
          </>

     )
}