import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CreateAnn from "./CreateAnnouncement";
import EditAnnouncement from "./EditAnnouncement";
import Axios from "axios";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useAuth } from "../../../../../Utils/Auth";
import { Avatar } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const index = () => {
  const [open, setOpen] = useState(false);
  const [openp, setOpenp] = useState(false);
  const [announcements, setannoucements] = useState([]);
  const [announcementid, setannouncementid] = useState([]);
  const { programid } = useParams();
  const { user } = useAuth();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenp = (i) => {
    setOpenp(true);
    setannouncementid(i);
  };
  const handleClosep = () => {
    setOpenp(false);
  };

  const getannoucements = async () => {
    const response = await Axios.get(`/api/announcement/${programid}`);
    if (response.data.success) setannoucements(response.data.data);
  };
  const deleteannouncement = async (i) => {
    const response = await Axios.delete(`/api/announcement/update/${i}`);
    if (response.data.success) {
      toast.success("Annoucement deleted");
    }
    getannoucements();
  };

  useEffect(() => {
    getannoucements();
  }, []);

  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-lg-9 col-sm-12">
            {announcements.length > 0
              ? announcements.map((v, i) => {
                  return (
                    <>
                      <div
                        key={i}
                        className=" meet-head-box"
                        style={{ marginBottom: "24px" }}
                      >
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-10">
                              <div className="pb-2 mt-0 user_incubation_course_AvatarGroup_root">
                                <div className="user_incubation_course_AvatarGroups">
                                  <Avatar src={v.host_id.avatar} />
                                </div>
                                <div className="user_incubation_course_startupEnrolled">
                                  <p className="caption color_light_grey">
                                    {v.host_id.name}
                                  </p>
                                </div>
                              </div>
                              <p className="overline">
                                {new Date(v.createdAt).toDateString() +
                                  " " +
                                  new Date(v.createdAt).toLocaleTimeString(
                                    navigator.language,
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                              </p>
                            </div>
                            {user.id === v.host_id._id ? (
                              <>
                                <div className="col-2 text-right">
                                  <EditIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "#989898",
                                      width: "20px",
                                      marginRight: "10px",
                                    }}
                                    onClick={() => handleOpenp(v._id)}
                                  />
                                  <DeleteIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "#989898",
                                      width: "20px",
                                    }}
                                    onClick={() => deleteannouncement(v._id)}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>

                          <h5 className="p_Bold_secBlue18_spartan my-3 text-left">
                            {" "}
                            {v.title}{" "}
                          </h5>
                          <p className="caption">{v.description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
          <div className="col-lg-3 col-sm-4">
            <div className="d-flex justify-content-center w-100">
              <button
                onClick={() => handleOpen()}
                type="button"
                className="gradient_CTA w-100"
              >
                MAKE ANNOUNCEMENT
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <CreateAnn
            handleClose={handleClose}
            getannoucements={getannoucements}
          />
        </Box>
      </Modal>
      <Modal
        open={openp}
        onClose={handleClosep}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <EditAnnouncement
            handleClose={handleClosep}
            getannoucements={getannoucements}
            id={announcementid}
          />
        </Box>
      </Modal>
    </>
  );
};

export default index;
