import React, { useEffect, useState } from "react";

import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
// import getDiff from "../../../Utils/diffleft/getDiff";
import { Avatar } from "@mui/material";
// import { toast } from "react-toastify";

const AcceptedCard = ({ serviceid, setstartupid, setapplicantstep }) => {
  const { setSessionExpire } = useAuth();
  const [applicants, setapplicants] = useState([]);

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/service/applications/${serviceid}/true`,
      method: "get", 
    })
      .then((res) => {
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  // const removeaccept = (requestid) => {
  //   Axios({
  //     method: "put",
  //     url: `/api/service/removeaccept/${requestid}`,
  //   })
  //     .then((res) => {
  //       if (res.data.success) {
  //         toast.success("Service request removed");
  //         fetchapplicants();
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.status === 401) {
  //         setSessionExpire(true);
  //       }
  //       toast.err(err.response.data.error);
  //     });
  // };
  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {applicants.map((v, i) => {
        return (
          <>

              <div
                className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding mb-4"
                onClick={() => {
                  setapplicantstep(1);
                  setstartupid(v.customerid._id);
                }}
                key={i}
                style={{ cursor: "pointer" }}
              >
                  <div
                    style={{ cursor: "pointer" }}
                    className={`dashboard_01_connections_list_item`}
                  >
                    <div className="dashboard_01_connections_logo_list_block">
                      <Avatar
                        src={v.customerid.avatar}
                        alt="Profile"
                        className=""
                      />
                    </div>

                    <div className="dashboard_01_connections_list_item_desc">
                      <p className="p_spartan_20 pb-2">
                        {v.customerid.name.length > 12
                          ? v.customerid.name.slice(0, 12) + "..."
                          : v.customerid.name}
                      </p>
                      <p className=" no-margin font12 bold pb-2">
                        {v.sender_type}
                      </p>
                      {/* <div className="row justify-content-center">
                        <p className=" font12 p_grey_chip">
                          {startup.industry}
                        </p>
                      </div> */}
                    </div>

                  </div>
              </div>


          {/* <div className="col-12 incunation_shortlisted_main mb-4" key={i}>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-4 d-flex align-items-center">
                <img
                  src={v.customerid.avatar}
                  alt="profile_short"
                  className="mr-2"
                  height={"100px"}
                  width={"100px"}
                />
                <p className="p_Bold_secBlue18_spartan">{v.customerid.name}</p>
              </div>
              <div className="col-lg-1 col-md-1 col-2 d-flex justify-content-center align-items-center">
                <p className="p_LightGrey12" style={{ color: "#565656" }}>
                  Startup
                </p>
              </div>

              <div className="col-lg-1 col-md-2 col-2 d-flex justify-content-center align-items-center">
                <p className="p_LightGrey12" style={{ color: "#565656" }}>
                  {getDiff(v.updatedAt)}
                </p>
              </div>
              <div
                onClick={() => {
                  removeaccept(v._id);
                }}
                className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <p className="transparntChip mr-2">REMOVE MEMBER</p>
              </div>
              <div
                onClick={() => {
                  setapplicantstep(1);
                  setstartupid(v.customerid._id);
                }}
                style={{ cursor: "pointer" }}
                className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
              >
                <p className="p_LightGrey12" style={{ color: "#565656" }}>
                  See Profile
                </p>
              </div>
            </div>
          </div> */}
          </>
        );
      })}
    </>
  );
};

export default AcceptedCard;
