import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
// import Checkbox from "@mui/material/Checkbox";

const CreateAnn = ({ id, getannoucements, handleClose }) => {
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [startup, setstartup] = useState(false);
  const [mentor, setmentor] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios({
        method: "put",
        url: `/api/announcement/update/${id}`,
        data: {
          title,
          description,
          startup,
          mentor,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Annoucement Updated");
          }
          getannoucements();
          handleClose();
        })
        .catch((err) => {
          return toast.error(err.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getannoucement = async () => {
    const response = await Axios.get(`/api/announcement/one/${id}`);
    if (response.data.success) {
      setTitle(response.data.data.title);
      setdescription(response.data.data.description);
      setstartup(response.data.data.startup);
      setmentor(response.data.data.mentor);
    }
  };

  useEffect(() => {
    getannoucement();
  }, []);

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} style={{ position: "relative" }}>
        <svg
          onClick={() => handleClose()}
          style={{ position: "absolute", top: "0px", right: "0px" }}
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 0C16.295 0 21 4.70505 21 10.5C21 16.295 16.295 21 10.5 21C4.70505 21 0 16.295 0 10.5C0 4.70505 4.70505 0 10.5 0Z"
            fill="#0D0D0D"
          />
          <path
            d="M13.641 8.35441L11.5588 10.4371L13.641 12.5198C13.9512 12.83 13.9512 13.3317 13.641 13.6415C13.4864 13.7961 13.2841 13.8739 13.0809 13.8739C12.8781 13.8739 12.6745 13.7966 12.5198 13.6415L10.4367 11.5588L8.35356 13.6415C8.1989 13.7961 7.99656 13.8739 7.79336 13.8739C7.59059 13.8739 7.38739 13.7966 7.23231 13.6415C6.92257 13.3317 6.92257 12.83 7.23231 12.5198L9.31543 10.4371L7.23231 8.35441C6.92257 8.04467 6.92257 7.54204 7.23231 7.2323C7.54205 6.92257 8.04382 6.92257 8.35356 7.2323L10.4367 9.31542L12.5198 7.2323C12.8295 6.92257 13.3313 6.92257 13.641 7.2323C13.9512 7.54204 13.9512 8.04467 13.641 8.35441Z"
            fill="#E2E2E2"
          />
        </svg>
        <div className="w-100 d-flex">
          <h5 className="w-fit" style={{ color: "#262C5B" }}>
            Edit announcement
          </h5>
        </div>

        <div className="register_page_form row">
          <div className="col-12">
            <label>Title</label>
            <input
              placeholder="Enter Title"
              required
              type="text"
              className="auth_input"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label>Description </label>
            <textarea
              required
              name="msg"
              className="auth_input auth_textarea"
              placeholder="Write a description..."
              value={description}
              onChange={(e) => setdescription(e.target.value)}
            ></textarea>
          </div>
          <div className="col-12">
            <label> Send to</label>
          </div>
          <div className="col-12 d-flex align-items-center">
            <div className="w-fit d-flex align-items-center mr-16">
              <div className="w-fit mr-12" onClick={() => setstartup(!startup)}>
                {startup ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                      fill="url(#paint0_linear_2010_18837)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2010_18837"
                        x1="18.9796"
                        y1="6.24106e-07"
                        x2="-3.04433"
                        y2="4.26681"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F53939" />
                        <stop offset="0.203125" stopColor="#FF6F1E" />
                        <stop offset="1" stopColor="#FFC939" />
                      </linearGradient>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                      fill="url(#paint0_linear_2010_18837)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2010_18837"
                        x1="18.9796"
                        y1="6.24106e-07"
                        x2="-3.04433"
                        y2="4.26681"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F53939" />
                        <stop offset="0.203125" stopColor="#FF6F1E" />
                        <stop offset="1" stopColor="#FFC939" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
              <span
                className="caption"
                style={{ color: "#262C5B", marginTop: "4px" }}
              >
                Startup
              </span>
            </div>
            <div className="w-fit d-flex align-items-center">
              <div className="w-fit mr-12" onClick={() => setmentor(!mentor)}>
                {mentor ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10Z"
                      fill="url(#paint0_linear_2010_18837)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2010_18837"
                        x1="18.9796"
                        y1="6.24106e-07"
                        x2="-3.04433"
                        y2="4.26681"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F53939" />
                        <stop offset="0.203125" stopColor="#FF6F1E" />
                        <stop offset="1" stopColor="#FFC939" />
                      </linearGradient>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5  "
                      fill="url(#paint0_linear_2010_18837)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2010_18837"
                        x1="18.9796"
                        y1="6.24106e-07"
                        x2="-3.04433"
                        y2="4.26681"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F53939" />
                        <stop offset="0.203125" stopColor="#FF6F1E" />
                        <stop offset="1" stopColor="#FFC939" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
              <span
                className="caption"
                style={{ color: "#262C5B", marginTop: "4px" }}
              >
                {" "}
                Mentor
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button type="submit" className="gradient_CTA">
            &nbsp; &nbsp; UPDATE&nbsp; &nbsp;{" "}
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateAnn;
