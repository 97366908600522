import { Avatar } from "@mui/material";
import Axios from "axios";
const filterMenteeStartup = ({menteesProfile,userType,handleFunction}) => {
    async function handleClick(id){
        await Axios({
            url: `/api/incmentor/mentee/${id}`,
            method: "get",
          }).then((res)=>{
            const { data } = res;
            handleFunction(data.data)
          })
     
     }
     let startups = [];
  
     if(userType){
     startups = menteesProfile.filter(mentee=>mentee.type === userType);
     if(startups.length > 0){
       return  startups.map(startup=><div className='col-lg-4 col-md-4 col-sm-12' key={startup._id}>
       <div
        style={{ cursor: "pointer" }}
        className={`dashboard_01_connections_list_item`}
         onClick={()=>handleClick(startup._id)}
    >
        <div className="dashboard_01_connections_logo_list_block">
            <Avatar
                src={startup.avatar}
                alt="connection"
                className="dashboard_01_connections_logo"
            />
        </div>

        <div className="dashboard_01_connections_list_item_desc">
            {
                startup.name
            }
        </div>
    </div>
       </div>)
     }else{
       return <p className="mx-3">No mentee found.</p>
     }}
}
export default filterMenteeStartup;