import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./modal.css";
import { useNavigate } from "react-router-dom";
// import lockimg from "../../Assets/images/events/calendar.png";

export default function PlanModal({ open, handleClose }) {
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    borderRadius: "5px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      className="Modal_root"
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="text-center">
          {/* <img src={lockimg} className="modal_img" alt="Modal" /> */}
          <i className="fa-solid fa-tag tag"></i>
          <p className="text-dark">Please upgrade your pricing plan to continue</p>
          <button
            className="gradient_CTA"
            onClick={() => navigate(`/pricing`)}
          >
            UPGRADE YOUR PLAN
          </button>
        </div>
      </Box>
    </Modal>
  );
}
PlanModal.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
};
