import React, { useState, useEffect } from "react";
import "./index.css";
// import Avatar from "@mui/material/Avatar";
// import AvatarGroup from "@mui/material/AvatarGroup";
// import Stack from "@mui/material/Stack";
import { MeetCardSkeleton } from "../../../../Components/SkeletonLoader/ProgramDashboard";
import callimg from "../../../../Assets/images/meet_purple.png";
import schimg from "../../../../Assets/images/schedule.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CreateMeet from "./createMeet";
import CreateNotes from "./createNotes";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import get_isodiff from "../../../../Utils/ISOdiff/isodiff";
import DeleteIcon from "@mui/icons-material/Delete";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "#F8F9FA",
  boxShadow: 24,
  p: 4,
};

function meet({ programid }) {
  const navigate = useNavigate();
  const [meets, setmeets] = useState([]);
  const [currmeet, setCurrmeet] = useState("");
  const [open, setOpen] = useState(false);
  const [notesopen, setNotesOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setSessionExpire, user } = useAuth();
  const handleOpen = () => setOpen(true);
  const handleNotesOpen = (id) => {
    setNotesOpen(true);
    setCurrmeet(id);
  };
  const handleClose = () => setOpen(false);
  const handleNotesClose = () => setNotesOpen(false);
  const getmeets = async () => {
    // console.log('54f654sad8f4sd84f8s6')
    try {
      setIsLoading(true);
      if (user.type === "mentor" || user.type === "investor") {
        const response = await Axios.get(`/api/incmeet/meets/${programid}`);
        if (response.data.success) {
          setmeets(response.data.meets);
          setIsLoading(false);
        }
      } else if (user.type === "incubator") {
        const response = await Axios.get(
          `/api/incmeet/meets/incubator/${programid}`
        );
        if (response.data.success) {
          setmeets(response.data.meets);
          setIsLoading(false);
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      } else {
        toast.error(err.response.data.error);
      }
      setIsLoading(false);
    }
  };

  const createinstantmeet = async () => {
    if (user.type === "incubator") {
      await Axios({
        method: "post",
        url: `/api/incmeet/create/${programid}`,
        data: {
          schedule: new Date(),
          title: "Instant Meet",
          startup: true,
          mentor: true,
          duration: 0.5,
          allAccess: "true",
          hostname: user.name,
        },
      })
        .then((res) => {
          if (res.data.success) toast.success("Meeting scheduled");
          getmeets();
          handleClose();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            setSessionExpire(true);
          } else {
            toast.error(err.response.data.error);
          }
          handleClose();
        });
    } else if (user.type === "mentor" || user.type === "investor") {
      await Axios({
        method: "post",
        url: `/api/incmeet/particular/user/meet/create`,
        data: {
          schedule: new Date(),
          title: "Instant Meet",
          startup: true,
          mentor: true,
          duration: 0.5,
          programid,
          hostname: user.name,
        },
      })
        .then((res) => {
          if (res.data.success) toast.success("Meeting scheduled");
          getmeets();
          handleClose();
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            setSessionExpire(true);
          } else {
            toast.error(err.response.data.error);
          }
          handleClose();
        });
    }
  };

  const deletemeet = async (i) => {
    try {
      const response = await Axios.delete(`/api/incmeet/delete/${i}`);
      if (response.data.success) {
        toast.success("Meet Cancelled");
      }
      getmeets();
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      } else {
        toast.error(err.response.data.error);
      }
    }
  };

  const handleStart = (key, title, name, hostId) => {
    if (hostId === user.id) {
      navigate(
        `/jitsi-meet/${key}?title=${title}&host=${name}&programid=${programid}&d=incprogram`
      );
    } else {
      navigate(
        `/jitsi-meet/${key}?title=${title}&user=${user.name}&programid=${programid}&d=incprogram`
      );
    }
  };
  useEffect(() => {
    getmeets();
  }, []);

  return (
    <>
      <div className="meet-container row">
        <div className="col-12 col-md-8">
          <div className="row mb-4">
            <div
              onClick={() => createinstantmeet()}
              style={{ cursor: "pointer" }}
              className="col-6"
            >
              <div className="meet-head-box p-4">
                <div className="row">
                  <div className="col-3">
                    <img src={callimg} />
                  </div>
                  <div className="col-9">
                    <h5>Instant Meet</h5>
                    <p className="caption">Set up an instant meet</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={() => handleOpen()}
              style={{ cursor: "pointer" }}
              className="col-6"
            >
              <div className="meet-head-box p-4">
                <div className="row">
                  <div className="col-3">
                    <img src={schimg} />
                  </div>
                  <div className="col-9">
                    <h5>Schedule Meet</h5>
                    <p className="caption">Plan your meet</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {isLoading ? (
              <MeetCardSkeleton />
            ) : (
              <>
                {meets.map((v, i) => {
                  return (
                    <>
                      <div key={i} className="col-12 mb-4">
                        <div className="p-3 meet-head-box">
                          <div className="row">
                            <div className="col-9">
                              <p className="title mb-3">{v.title}</p>
                              <div className="meet-card-smalltext mb-4">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M6.99301 0.333008C3.31301 0.333008 0.333008 3.31967 0.333008 6.99967C0.333008 10.6797 3.31301 13.6663 6.99301 13.6663C10.6797 13.6663 13.6663 10.6797 13.6663 6.99967C13.6663 3.31967 10.6797 0.333008 6.99301 0.333008ZM9.19301 10.1397L6.33301 7.27301V3.66634H7.66634V6.72634L10.1397 9.19967L9.19301 10.1397Z"
                                    fill="#989898"
                                  />
                                </svg>
                                &nbsp;
                                {new Date(v.schedule).toLocaleString() + " "},
                                {get_isodiff(v.schedule) === "Live"
                                  ? "Live"
                                  : get_isodiff(v.schedule) === "Expired"
                                  ? "Expired"
                                  : "Starts in " + get_isodiff(v.schedule)}
                              </div>
                            </div>

                            <div className="col-2 text-right">
                              <div
                                className="secondaryFilled_activeCta p-0 "
                                style={{ fontSize: "10px", cursor: "pointer" }}
                                onClick={() => handleNotesOpen(v._id)}
                              >
                                Notes
                              </div>
                            </div>
                            <div className="col-1 text-right">
                              {user.id === v.hostid._id ? (
                                <>
                                  <DeleteIcon
                                    style={{
                                      cursor: "pointer",
                                      color: "#989898",
                                    }}
                                    onClick={() => deletemeet(v._id)}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>

                          {get_isodiff(v.schedule) === "Live" ? (
                            <>
                              <div className="btn_pos">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleStart(
                                      v.meet_id,
                                      v.title,
                                      v.hostid.name,
                                      v.hostid._id
                                    )
                                  }
                                  className="gradient_CTA"
                                >
                                  {user.id === v.hostid._id
                                    ? "START NOW"
                                    : "JOIN NOW"}
                                </button>
                              </div>
                            </>
                          ) : get_isodiff(v.schedule) === "Expired" ? (
                            <>
                              <>
                                <div className="btn_pos">
                                  <button
                                    type="button"
                                    disabled
                                    className="gradient_CTA"
                                  >
                                    Expired
                                  </button>
                                </div>
                              </>
                            </>
                          ) : (
                            <>
                              <div className="btn_pos">
                                <button
                                  disabled
                                  type="button"
                                  className="gradient_CTA"
                                >
                                  {get_isodiff(v.schedule)} left
                                </button>
                              </div>
                            </>
                          )}

                          <p>Hosted by {v.hostid.name}</p>
                          {/* <Stack direction="row" spacing={2}>
                      <Avatar
                        variant="circular"
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                      <Avatar
                        variant="circular"
                        alt="Travis Howard"
                        src="/static/images/avatar/2.jpg"
                      />
                      <Avatar
                        variant="circular"
                        alt="Cindy Baker"
                        src="/static/images/avatar/3.jpg"
                      />
                      <Avatar
                        variant="circular"
                        alt="Remy Sharp"
                        src="/static/images/avatar/1.jpg"
                      />
                    </Stack> */}
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={style}>
          <CreateMeet
            programid={programid}
            handleClose={handleClose}
            getmeets={getmeets}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={notesopen}
        onClose={handleNotesClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={style}>
          <CreateNotes meetid={currmeet} handleClose={handleNotesClose} />
        </Box>
      </Modal>
    </>
  );
}

export default meet;
