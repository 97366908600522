import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import Avatar from "@mui/material/Avatar";
import Loader from "../../../Components/Loader";
import { toast } from "react-toastify";

const Applicantcard = ({ setstep, spid, serviceid }) => {
  const [startup, setstartup] = useState(null);
  const [service, setservice] = useState(null);
  const [request, setrequest] = useState(null);

  const { setSessionExpire } = useAuth();

  const getDetail = async () => {
    await Axios({
      method: "get",
      url: `/api/sp/spbyid/${spid}`,
    })
      .then((res) => {
        setstartup(res.data.data);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const getservice = async () => {
    try {
      const response = await Axios.get(`/api/service/servicebyid/${serviceid}`);
      if (response.data.success) setservice(response.data.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const sendRequest = () => {
    Axios({
      method: "get",
      url: `/api/service/sendrequests/${spid}/${serviceid}`,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Service request sent");
          getrequest();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.err(err.response.data.error);
      });
  };
  const withDrawRequest = () => {
    Axios({
      method: "get",
      url: `/api/service/withdrawrequests/${request._id}`,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Service request withdrawn");
          getrequest();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.err(err.response.data.error);
      });
  };

  const getrequest = async () => {
    await Axios({
      method: "get",
      url: `/api/service/checkrequests/${spid}/${serviceid}`,
    })
      .then((res) => {
        setrequest(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    getDetail();
    getservice();
    getrequest();
  }, []);

  if (startup === null || service === null) return <Loader />;

  return (
    <>
      <div className="col-12 mb-5">
        <span
          className="back"
          onClick={() => {
            setstep(0);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left mx-1"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Back
        </span>
      </div>
      <div className="col-12 row">
        <div className="col-12 col-lg-8">
          <div className="connection_profile_root" id="connection_profile_root">
            <div className="row mt-2 justify-content-center connection_profile_head dashboard01_connection_profile_head">
              <div className="width_auto no-padding">
                <Avatar
                  src={startup.logo}
                  className=""
                  alt="Profile"
                  sx={{ width: 90, height: 90 }}
                />
              </div>
              <div className="col-10 d-flex flex-column justify-content-center">
                <p
                  className="p_spartan_20 subtitle"
                  style={{ color: "#262C5B" }}
                >
                  {startup.name}
                </p>
                <p className="bold">
                  Service Provider | {startup.location.country}
                </p>
              </div>
            </div>
            <div className="mt-4 mx-2">
              <p className="grey bold">{startup.description}</p>
            </div>

            <hr />

            <div className="mb-4">
              <p>Services Offered</p>
              <p className="p_grey_chip">{service.keyword}</p>
              <p>Pricing: {service.pricing}</p>
              <p> Customer Support: {service.customerSupport}</p>
              <div className="user_incubation_course_leftSide">
                <div className="user_course_desc pt-3">
                  <ul>
                    {service.services.map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>
              </div>
              {request === null ? (
                <button
                  onClick={() => sendRequest()}
                  className="gradient_CTA width_fit mr-3"
                >
                  REQUEST SERVICE
                </button>
              ) : !request.is_accepted ? (
                <button disabled className="gradient_CTA width_fit mr-3">
                  PENDING
                </button>
              ) : request.is_accepted ? (
                <button
                  onClick={() => {
                    withDrawRequest();
                  }}
                  className="twf_gradoutlinedbtn color-black width_fit mr-3"
                >
                  WITHDRAW SERVICE
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="connection_profile_root_highligts">
            {startup.id ? (
              <>
                <p className="p_spartan_20 mx-2 my-4 founder-title">
                  Founder Details
                </p>
                <div className="row mx-0 my-4 align-items-center col-8">
                  <div className="width_auto no-padding">
                    <img
                      src={startup.logo}
                      style={{
                        borderRadius: "50%",
                        width: "90px",
                        height: "90px",
                      }}
                      alt="Profile"
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="founder-title" style={{ fontSize: "16px" }}>
                      {startup.id.firstname} {startup.id.lastname}
                    </p>

                    <p className="bold">
                      <a
                        href={`${startup.linkedProfile}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-linkedin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                        </svg>
                      </a>
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicantcard;
