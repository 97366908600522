import React from "react";
import MyProgram from "./MyPrograms";
// import ProgramProfile from "./ProgramProfile";
import CreateProgram from "./createProgram";
import Analytics from "./Analytics";

const LandingPage = (props) => {
  const { eventActiveStep, setEventActiveStep } = props;
  return (
    <>
      {eventActiveStep === 1 ? (
        <MyProgram setEventActiveStep={setEventActiveStep} />
      ) : eventActiveStep === 2 ? (
        <CreateProgram setEventActiveStep={setEventActiveStep} />
      ) : eventActiveStep === 3 ? (
        <Analytics />
      ) : (
        <p>Coming Soon</p>
      )}
    </>
  );
};

export default LandingPage;
