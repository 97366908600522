import React , {  useEffect } from "react";  // useState,
// import { Link } from "react-router-dom";
// import Checkbox from '@mui/material/Checkbox';
// import profile_img from "../../Icons/profile.png"; 
// import CircularProgress from "@mui/material/CircularProgress";
// import OTPService from "../../../../Utils/OTP";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
// import { useAuth } from "../../../../Utils/Auth";
// import {toast} from "react-toastify"; 
// import Axios from "axios";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import Avatar from "@mui/material/Avatar";

import Multiselect from "multiselect-react-dropdown";

const Step3 = (props) => {
  const {
    options,
    investedmodel,
    setinvestedmodel,
    investedstage,
    setinvestedstage,
    investedIndustry,
    setinvestedIndustry,
    totalinvestment,
    settotalinvestment,
    loadinvestment,
    setloadinvestment,
    acquisitions,
    setacquisitions,
    exits,
    setexits,
    Fundingrounds,
    setFundingrounds,
    totalFunding,
    settotalFunding,

    initial,
    setinitial
  } = props;

  
  useEffect(()=>{
    if(investedIndustry.length===1)
    {
      setinitial(false);
      // console.log(initial)
    }
  },[investedIndustry])

  
  useEffect(()=>{
    setinitial(true);
    // console.log(initial)
  },[])

  const addInputEvent3 = (e) => {
    setinvestedIndustry(e.map((i) => i.label));
  };


  return (
    <form>

      <div className="register_page_form row">
        <div className="col-12" style={{ height: "100%" }}>
          <label className="auth_label">
            Interested Industry*
          </label>
          <Multiselect
            isMulti
            options={options}
            className=""
            displayValue="label"
            placeholder="Select Industries"
            name="industries"
            closeIcon="cancel"
            value={investedIndustry}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>
        
        <div className="col-12 " style={{ height: "100%" }}>
        <div className="d-flex flex-wrap">
          {initial && investedIndustry && investedIndustry.length>0 ? 
            investedIndustry.map((v,i)=>
                <>
                <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                  <p className="program_text">{v}</p>
                </span>
              </>
              )
          : null}
          </div>
        </div>
        <div className="col-12">
          <label className="auth_label">
            Invested Business Models*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={investedmodel}
            onChange={(e) => setinvestedmodel(e.target.value)}
          >
            <option className="color_black" value="">
              Select business model
            </option>
            <option className="color_black" value="Business to Consumer">
              Business to Consumer
            </option>
            <option className="color_black" value="Business to Business">
              Business to Business
            </option>
            <option className="color_black" value="Consumer to Business">
              Consumer to Business
            </option>
            <option className="color_black" value="Consumer to Consumer">
              Consumer to Consumer
            </option>
          </select>
        </div>
        <div className="col-12">
          <label className="auth_label">
            Invested Funding Stages*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={investedstage}
            onChange={(e) => setinvestedstage(e.target.value)}
          >
            <option className="color_black" value="">
              Select funding stages
            </option>
            <option className="color_black" value="Pre-Seed">
              Pre-Seed
            </option>
            <option className="color_black" value="Seed">
              Seed
            </option>
            <option className="color_black" value="Series A">
              Series A
            </option>
            <option className="color_black" value="Series B">
              Series B
            </option>
            <option className="color_black" value="All">
              All of the above
            </option>
            <option className="color_black" value="Not sure">
              Not sure
            </option>
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label>Total Investments*</label>
          <input
            type="text"
            placeholder="Enter number of investments"
            className="auth_input"
            value={totalinvestment}
            onChange={(e) => settotalinvestment(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Load Investments*</label>
          <input
            type="text"
            placeholder="Enter number of investments"
            className="auth_input"
            value={loadinvestment}
            onChange={(e) => setloadinvestment(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Acquisitions*</label>
          <input
            type="text"
            placeholder="Enter number of acquisitions"
            className="auth_input"
            value={acquisitions}
            onChange={(e) => setacquisitions(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Exits*</label>
          <input
            type="text"
            placeholder="Enter number of exits"
            className="auth_input"
            value={exits}
            onChange={(e) => setexits(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Funding Rounds*</label>
          <input
            type="text"
            placeholder="Enter number of funding rounds"
            className="auth_input"
            value={Fundingrounds}
            onChange={(e) => setFundingrounds(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Total Funding Amount*</label>
          <input
            type="text"
            placeholder="Enter total funding amount"
            className="auth_input"
            value={totalFunding}
            onChange={(e) => settotalFunding(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
};

export default Step3;
