import React, { useEffect } from 'react'; // useState,
import './valuation.css'
import LetStart from './LetStart';
import LandingPage from "./LandingPage"
import ValuationMain from './index';
// import Axios from "axios"
// import { toast } from 'react-toastify';
import { useLocation,  } from 'react-router-dom';
// import { useAuth } from "../../../Utils/Auth";
// import Toast from '../../../components/Toast';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const Valuation = ({valuationStep, setValuationStep,setActiveStep,ActiveStep}) => {
    // const [valuationStep, setValuationStep] = useState(1);
    // const [title ,setTitle] = useState("");
    // const [activeStep, setActiveStep] = useState(0);
    let query = useQuery();
    // const {user} = useAuth()
    // const navigate = useNavigate()
    const valuate = Number(query.get("valuate"));
    const tab = Number(query.get("valuationTab"));

    useEffect(() => {
        if (valuate) {
            setValuationStep(valuate);
        }
    }, [valuate]);
    useEffect(() => {
        if (tab) {
            setActiveStep(tab);
        }
        else if(tab === 0)
        {
            setActiveStep(tab);
        }
    }, [tab]);
    


    //     }).catch(()=>toast.error("try again"))
    // }

    const handleNext = () => {
        if (ActiveStep < 2) {
          setActiveStep(ActiveStep+1);
        }
      };

    return (
        <div className='NewValuation-root'>
            {
                valuationStep === 1 ? <LandingPage
                    setValuationStep={setValuationStep}
                    setActiveStep={setActiveStep}
                /> :  valuationStep === 3 ? <ValuationMain
                setValuationStep={setValuationStep}
                // title={title}
                setActiveStep={setActiveStep}
                ActiveStep={ActiveStep}
                handleNext={handleNext}
                /> : <LetStart
                setValuationStep={setValuationStep}
                // setTitle={setTitle}
                // title={title}
                // createReport={createReport}
                />
            }
            
        </div>
    )
}

export default Valuation