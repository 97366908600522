import React, { useState, useEffect } from "react";
import { useAuth } from "../../Utils/Auth";
import Axios from "axios";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import StartupProfile from './StartupProfile'
import moment from "moment-timezone";
import ShowProfile from "./ShowProfile";

const index = () => {

  const navigate = useNavigate();
  const getDiff = (date) => {
    // var dateTime = date + "T" + time;
    var scheduleTime = moment(date);
    var current = moment();
    // var ofset = moment().utcOffset() / 60;

    var dayDiff = scheduleTime.diff(current, "days");
    if (dayDiff < -1) {
      return Math.abs(dayDiff) + " days ago";
    } else if (dayDiff === -1) return Math.abs(dayDiff) + " day ago";
    else {
      var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
      //- parseFloat(timezone.split(" ", 3)[2]) +
      // ofset;
      if (hoursDiff <= -1) {
        let hoursDiffRound = Math.round(hoursDiff);
        return Math.abs(hoursDiffRound) == 1
          ? Math.abs(hoursDiffRound) + " hour ago"
          : Math.abs(hoursDiffRound) + " hours ago";
      } else {
        return Math.abs(Math.round(hoursDiff * 60)) + " mins ago";
      }
    }
  };

  const { setSessionExpire, user } = useAuth();
  const [invites, setinvites] = useState([]);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const [step, setstep] = useState(1)
  const query = useQuery()
  const steptab = Number(query.get("step"));

  useEffect(() => {
    if (steptab) {
      setstep(step)
    }
  }, [steptab])

  const fetchInvites = async () => {
    await Axios({
      url: "/api/mentor/received",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setinvites(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  return (
    <>

      {step === 1 ? (
        <>
          <div className="row">
            <div className="col-lg-8 col-xs-12">
              <h4 className="p_spartan_20 m-2">Active Mentorships</h4>
              <div className="row mx-0 my-4">
                {invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {v.is_accepted ? (
                          <>
                            <div key={i} className='col-lg-4 col-sm-6 col-xs-12' onClick={() => {
                              setstep(1);
                              navigate(
                                `/dashboard/${user.type}?tab=21&id=${v.connect_id}&${v.sender.type}=true`
                              );
                            }}>
                              <div
                                style={{ cursor: "pointer" }}
                                className={`dashboard_01_connections_list_item`}
                              >
                                <div className="dashboard_01_connections_logo_list_block">
                                  <Avatar
                                    src={v.sender.avatar}
                                    alt="connection"
                                    className="dashboard_01_connections_logo"
                                  />
                                </div>

                                <div className="dashboard_01_connections_list_item_desc">
                                  <p className="p_spartan_20 my-3">
                                    {v.sender.name.length > 12
                                      ? v.sender.name.slice(0, 12) + "..."
                                      : v.sender.name}
                                  </p>
                                  <p className="font12 bold grey my-3">
                                    {getDiff(v.updatedAt)}
                                  </p>

                                  <div className="row justify-content-center">

                                  </div>
                                </div>

                              </div>

                            </div>
                            {/* <div key={i}>
                        <p>{v.sender.name}</p>
                        <button
                          type="button"
                        >
                          Explore
                        </button>
                      </div> */}
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p className="px-2">No Mentorships</p>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="dashboard01_block h-fit">
                <h4 className="p_spartan_20 mb-4 mt-2">Requests</h4>
                {invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {!v.is_accepted ? (
                          <>
                            <div key={i}>
                              <div key={i} className='row mx-0 my-2 r_a' onClick={() => setstep(2)}>
                                <Avatar src={v.sender.avatar} />
                                <div className="col-9">
                                  <p className="bold my-0 caps">{v.sender.name}</p>
                                  <p className="font12 grey caps">{v.sender.type}</p>
                                </div>
                                <p
                                  className="font12 grey mx-auto back_CTA"
                                  onClick={() => {
                                    setstep(2)
                                    navigate(`/dashboard/${user.type}?tab=5&ms=1&${v.sender.type}=${v.sender._id}&connect=${v._id}`)
                                  }
                                  }
                                >
                                  View
                                </p>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p>No requests</p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : step === 2 ? (<>
        {/* <StartupProfile setstep={setstep} fetchInvites={fetchInvites} /> */}
        <ShowProfile setstep={setstep} fetchInvites={fetchInvites}/>
      </>) : null}
    </>
  );
};

export default index;
