import React, { useState } from "react";
import Step1 from "./Regsteps/Step1";
import Step2 from "./Regsteps/Step2";
import Step3 from "./Regsteps/Step3";
import Step4 from "./Regsteps/Step4";

// import Location from "../../../Utils/Location";
import industries from "../../Lists/Industries2.json";
// import Multiselect from "multiselect-react-dropdown";
import { useAuth } from "../../../Utils/Auth";
// import ClearIcon from "@mui/icons-material/Clear";
import Navbar from "../../../Layout/Navbar/Navbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NotFoundPage from "../../404Pages";
import axios from "axios";
import { Link } from "react-router-dom";
// import industriess from "../../Lists/Industries.json";
import Checkbox from "@mui/material/Checkbox";
// import OTPService from "./../../../Utils/OTP";
// import { Tooltip } from "@material-ui/core";
// import AddIcon from "@mui/icons-material/Add";
import "./index.css";

const Coporate_Register = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState(null);

  // STEP 1
  const [companyLogo, setCompanyLogoUrl] = useState(null);
  const [legalname, setlegalname] = useState("");
  const [nameOfCorporateType, setnameOfCorporateType] = useState("");
  const [typeOfCorporate, settypeOfCorporate] = useState("");
  const [bio, setbio] = useState("");
  const [companyWebsite, setcompanyWebsite] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [IncorporationDate, setIncorporationDate] = useState(null);
  const [industry, setindustry] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [services, setservices] = useState([]);
  const [description, setdescription] = useState("");

  // STEP2
  const [NumberOfEmployees, setNumberOfEmployees] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [FundingStatus, setFundingStatus] = useState("");
  const [otherFundingStatus, setOtherFundingStatus] = useState("");
  const [growth, setGrowth] = useState("");
  const [otherGrowth, setOtherGrowth] = useState("");
  const [CurrentEvaluation, setValuation] = useState(0);
  const [revenue, setrevenue] = useState("");
  const [DocumentsNeed, setCompanyDocuments] = useState([]);

  // STEP 3
  const [options] = useState(industries);
  const [investedstage, setinvestedstage] = useState("");
  const [investedmodel, setinvestedmodel] = useState("");
  const [investedIndustry, setinvestedIndustry] = useState([]);
  const [totalinvestment, settotalinvestment] = useState("");
  const [loadinvestment, setloadinvestment] = useState("");
  const [acquisitions, setacquisitions] = useState("");
  const [exits, setexits] = useState("");
  const [Fundingrounds, setFundingrounds] = useState("");
  const [totalFunding, settotalFunding] = useState("");

  // STEP 4
  const [interestedstage, setinterestedstage] = useState("");
  const [interestedmodel, setinterestedmodel] = useState("");
  const [interestedIndustry, setinterestedIndustry] = useState([]);
  const [chequsize, setchequsize] = useState("");
  const [phone, setPhone] = useState(null);
  const [phoneVerified, setphoneVerified] = useState(false);
  









  const navigate = useNavigate();
  // const [profilePic, setProfilePic] = useState(null);
  
  // REGISTRATION
  const [terms, setterms] = useState(true);
  const [isDisable, setisDisable] = useState(false);

  // const [companySize, setcompanySize] = useState("");
  // const [annualturnover, setannualturnover] = useState("");
  // const [interestedIndustry, setinterestedIndustry] = useState([]);
  // const [document, setdocument] = useState(null);

  // const [agreeTerms, setAgreeTerms] = useState(false);

  // const [stage, setstage] = useState("");
  // const [terms, setterms] = useState(true);

  // const [interestVal, setinterestVal] = useState("");
  // const [fieldOfInterest, setfieldOfInterest] = useState([]);

  // const addbenefit = () => {
  //   if (interestVal) {
  //     setfieldOfInterest((prev) => [...prev, interestVal]);
  //     setinterestVal("");
  //   }
  // };
  // const removebenefit = (id) => {
  //   const newArr = fieldOfInterest.filter((obj, i) => {
  //     return id !== i;
  //   });
  //   setfieldOfInterest(newArr);
  // };

  // const [currentPlan, setcurrentPlan] = useState(0);

  // const addInputEvent3 = (e) => {
  //   setinterestedIndustry(e.map((i) => i.label));
  // };

  // const handlePicUpload = (e) => {
  //   const image = e.target.files[0];
  //   if (image.size > 2048 * 1024) {
  //     toast.error("File size exceeded 2MB!", { position: "bottom-right" });
  //     return;
  //   }
  //   const formdata = new FormData();
  //   formdata.append("logo", image);
  //   Axios({
  //     method: "post",
  //     url: " /api/corporate/logo",
  //     data: formdata,
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       setProfilePic(data.location);
  //       toast.success("Logo uploaded", { position: "bottom-right" });
  //     })
  //     .catch(() => {
  //       toast.error("Error in upload", { position: "bottom-right" });
  //     });
  // };

  // const handleDocUpload = (e) => {
  //   const image = e.target.files[0];
  //   if (image.size > 2048 * 1024) {
  //     toast.error("File size exceeded 2MB!");
  //     return;
  //   }

  //   const formdata = new FormData();
  //   formdata.append("document", image);

  //   Axios({
  //     method: "post",
  //     url: "/api/upload/document",
  //     data: formdata,
  //   })
  //     .then((res) => {
  //       const { data } = res;

  //       if (data) setdocument(data.location);
  //       toast.success("Document uploaded");
  //       setdocument(data.location);
  //     })
  //     .catch(() => {
  //       toast.error("Error in upload");
  //       setdocument(null);
  //     });
  // };

  const handleRegister = async () => {
    try {
      // validation
      if (!terms) {
        toast.error("Please agree the Terms & Conditions", {
          position: "bottom-right",
        });
        return;
      }
      // if (bio.length === 0) return toast.warning("Bio is missing");

      
      if (
        legalname.length === 0 ||
        typeOfCorporate.length === 0 ||
        nameOfCorporateType.length === 0 ||
        linkedin.length === 0 ||
        industry.length === 0 ||
        (industry === "Other" && otherIndustry.length === 0) ||
        bio.length === 0 ||
        // companyWebsite.length === 0 ||
        IncorporationDate === null ||
        industry.length === 0 ||
        // services.length === 0 ||
        description.length === 0 ||
        companyLogo === null
      ) {
        return toast.warning("Some company details are missing");
      }

      
      if (
        NumberOfEmployees.length === 0 ||
        targetAudience.length === 0 ||
        CurrentEvaluation.length === 0 ||
        revenue.length === 0 ||
        DocumentsNeed.length === 0 ||
        FundingStatus.length === 0 ||
        (FundingStatus === "others" && otherFundingStatus.length === 0) ||
        growth.length === 0 ||
        (growth === "others" && otherGrowth.length === 0)
      ) {
        return toast.warning("Some domain details are missing");
      }
      if (
        investedIndustry.length === 0 ||
        investedstage.length === 0 ||
        investedmodel.length === 0 ||
        totalinvestment.length === 0 ||
        loadinvestment.length === 0 ||
        acquisitions.length === 0 ||
        exits.length === 0 ||
        Fundingrounds.length === 0 ||
        totalFunding.length === 0
      ) {
        return toast.warning("Some invested details are missing");
      }
      if (
        interestedIndustry.length === 0 ||
        interestedmodel.length === 0 ||
        interestedstage.length === 0 ||
        phone.length === 0 ||
        chequsize.length === 0 
      ) {
        return toast.warning("Some interested details are missing");
      }




      // if (profilePic === null) return toast.warning("Upload Corporate logo");
      // if (document === null) return toast.warning("Upload document");

      if (country.length === 0) return toast.warning("Enter company address");
      if (states && states.length > 0 && (!state || state.length === 0))
        return toast.warning("Select state ");
      else if (cities && cities.length > 0 && (!city || city.length === 0))
        return toast.warning("Select city ");

      if (phone === null) return toast.warning("Phone number field is empty");
      // console.log(phoneVerified)
      if (!phoneVerified) return toast.warning("Please verify phone number");
      // if (agreeTerms === false)
      //   return toast.warning(
      //     "Agree to our terms and conditions before submitting"
      //   );
      // send data to backend
      
      setisDisable(true);

      const response = await axios.post("/api/corporate/corporate-onboard", {
        legalname,
        typeOfCorporate,
        nameOfCorporateType,
        linkedin,
        bio,
        companyWebsite,
        IncorporationDate,
        industry: industry === "Other" ? otherIndustry : industry,
        services,
        description,
        logo: companyLogo,
        location: {
          country,
          state,
          city,
        },
        NumberOfEmployees,
        targetAudience,
        CurrentEvaluation,
        revenue,
        DocumentsNeed,
        FundingStatus: FundingStatus === "Other" ? otherFundingStatus : FundingStatus,
        growth: growth === "Other" ? otherGrowth : growth,
        investedIndustry,
        investedstage,
        investedmodel,
        totalinvestment,
        loadinvestment,
        acquisitions,
        exits,
        Fundingrounds,
        totalFunding,
        interestedIndustry,
        interestedmodel,
        interestedstage,
        phone,
        chequsize
      });

      if (response.status === 201) {
        setisDisable(false);
        toast.success("Corporate registered");
        navigate("/thankyou");
      } else if (response.status === 200) {
        setisDisable(false);
        return toast.error("Corporate already registered");
      }
    } catch (error) {
      setisDisable(false);
      toast.error(error.response.data.error);
    }
  };

  // const setCompanySize = (s) => {
  //   setcurrentPlan(s);
  //   setcompanySize(s);
  // };

  if (user)
    return (
      <>
        <div className="regis">
          <Navbar />
        <div className="register_page">
          
          {/* -------------STEP 1------------------ */}

          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-head w-100">
                    COMPANY DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                    About your company
                  </p>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead w-100">
                    DOMAIN DETAILS 
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                    INVESTMENTS
                  </a>
                  </div>
                  <div>
                  <a href="#part4 " className="register_page_sideborder-sidehead w-100">
                    INTERESTS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part1">
                  <Step1
                    nameOfCorporateType={nameOfCorporateType}
                    setnameOfCorporateType={setnameOfCorporateType}
                    typeOfCorporate={typeOfCorporate}
                    settypeOfCorporate={settypeOfCorporate}
                    bio={bio}
                    setbio={setbio}
                    companyWebsite={companyWebsite}
                    setcompanyWebsite={setcompanyWebsite}
                    linkedin={linkedin}
                    setlinkedin={setlinkedin}
                    setIncorporationDate={setIncorporationDate}
                    IncorporationDate={IncorporationDate}
                    industry={industry}
                    setIndustry={setindustry}
                    otherIndustry={otherIndustry}
                    setOtherIndustry={setOtherIndustry}
                    setservices={setservices}
                    services={services}
                    country={country}
                    setcountry={setCountry}
                    state={state}
                    setstate={setstate}
                    city={city}
                    setcity={setcity}
                    states={states}
                    cities={cities}
                    setstates={setstates}
                    setcities={setcities}
                    legalname={legalname}
                    setlegalname={setlegalname}
                    description={description}
                    setdescription={setdescription}
                    companyLogo={companyLogo}
                    setCompanyLogoUrl={setCompanyLogoUrl}
                    profile={profile}
                    setProfile={setProfile}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* -------------STEP 2------------------ */}

          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  
                  <div  className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead-active">
                    COMPANY DETAILS
                  </a>
                  </div>
                  <div  className="mb-16">
                  <a href="#part2" className="register_page_sideborder-head">
                    DOMAIN DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead m-0">
                    About your company domain
                  </p>
                  </div>
                  <div className="mb-16">
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                    INVESTMENTS
                  </a>
                  </div>
                  <div>
                  <a href="#part4 " className="register_page_sideborder-sidehead w-100">
                    INTERESTS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part2">
                  <Step2
                  NumberOfEmployees={NumberOfEmployees}
                  setNumberOfEmployees={setNumberOfEmployees}
                  targetAudience={targetAudience}
                  setTargetAudience={setTargetAudience}
                  FundingStatus={FundingStatus}
                  setFundingStatus={setFundingStatus}
                  otherFundingStatus={otherFundingStatus}
                  setOtherFundingStatus={setOtherFundingStatus}
                  growth={growth}
                  setGrowth={setGrowth}
                  otherGrowth={otherGrowth}
                  setOtherGrowth={setOtherGrowth}
                  CurrentEvaluation={CurrentEvaluation}
                  setValuation={setValuation}
                  revenue={revenue}
                  setrevenue={setrevenue}
                  DocumentsNeed={DocumentsNeed}
                  setCompanyDocuments={setCompanyDocuments}

                  />
                </div>
              </div>
            </div>
          </div>

          {/* -------------STEP 3------------------ */}

          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <div  className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead-active">
                    COMPANY DETAILS
                  </a>
                  </div>
                  <div  className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead">
                    DOMAIN DETAILS
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part3" className="register_page_sideborder-head w-100">
                    INVESTMENTS
                  </a>
                  <p className="register_page_sideborder-sidehead m-0">
                    About your investment history
                  </p>
                  </div>
                  <div>
                  <a href="#part4 " className="register_page_sideborder-sidehead w-100">
                    INTERESTS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part3">
                  <Step3
                    options={options}
                    investedIndustry={investedIndustry}
                    setinvestedIndustry={setinvestedIndustry}
                    investedstage={investedstage}
                    setinvestedstage={setinvestedstage}
                    investedmodel={investedmodel}
                    setinvestedmodel={setinvestedmodel}
                    totalinvestment={totalinvestment}
                    settotalinvestment={settotalinvestment}
                    loadinvestment={loadinvestment}
                    setloadinvestment={setloadinvestment}
                    acquisitions={acquisitions}
                    setacquisitions={setacquisitions}
                    exits={exits}
                    setexits={setexits}
                    Fundingrounds={Fundingrounds}
                    setFundingrounds={setFundingrounds}
                    totalFunding={totalFunding}
                    settotalFunding={settotalFunding}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* -------------STEP 4------------------ */}

          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <div  className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead-active">
                    COMPANY DETAILS
                  </a>
                  </div>
                  <div  className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead">
                    DOMAIN DETAILS
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                    INVESTMENTS
                  </a>
                  </div>
                  <div>
                  <a href="#part4 " className="register_page_sideborder-head w-100">
                    INTERESTS
                  </a>
                  <p className="register_page_sideborder-sidehead m-0">
                    About your future investment interests
                  </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part4">
                  <Step4
                    options={options}
                    interestedIndustry={interestedIndustry}
                    setinterestedIndustry={setinterestedIndustry}
                    interestedmodel={interestedmodel}
                    setinterestedmodel={setinterestedmodel}
                    interestedstage={interestedstage}
                    setinterestedstage={setinterestedstage}
                    phone={phone}
                    setPhone={setPhone}
                    setphoneVerified={setphoneVerified}
                    chequsize={chequsize}
                    setchequsize={setchequsize}
                  />
                </div>
              </div>
            </div>
          </div>
        
          {/* -------------REGISTER------------------ */}
          <div className="container mb-40">
            <div className="row">
              <div className="col-12 col-md-4">
              </div>
              <div className="col-12 col-md-8">
              <div className="d-flex mb-14" style={{ marginTop: "163px" }}>
                <div className="">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM9 5V7H11V5H9Z" fill="#989898"/>
                  </svg>
                </div>
                <div className="">
                  <p style={{marginLeft:"6px"}}>
                  All the documents submitted here will be treated as strictly confidential and will not be used/disclosed anywhere
                  </p>
                </div>

              </div>
                  <div
                    className="w-100"
                  >
                    <label
                      htmlFor="terms_condition"
                      className="text-dark text_align_start"
                    >
                      <Checkbox
                        defaultChecked
                        onClick={() => setterms(!terms)}
                        sx={{
                          color: `#262C5B`,
                          "&.Mui-checked": {
                            color: `#262C5B`,
                          },
                        }}
                        className="mr-8"
                      />
                      By signing up, you agree to Zefyron Incubation&apos;s{" "}
                      <Link
                        to="/terms-and-conditions"
                        target="_blank"
                        rel="nonreferrer"
                        className="text-dark"
                      >
                        Terms & Conditions{" "}
                      </Link>
                    </label>
                    <div className="w-100">
                    <button
                      className="gradient_CTA"
                      style={{ width: "50%" }}
                      disabled={isDisable}
                      onClick={() => handleRegister()}
                    >
                      Register
                    </button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

          {/* <div className="register_page">
            <div className="container pt-5">
              <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
                <div className="col-12 col-md-4">
                  <div className="register_page_sideborder">
                    <p className="register_page_sideborder-head">
                      COMPANY DETAILS
                    </p>
                    <p className="register_page_sideborder-sidehead-active">
                      About your company
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="register_page_form">
                    <div className="register_page_form_upload">
                      {profilePic ? (
                        <>
                          <img
                            alt="Profile Pic"
                            src={profilePic}
                            style={{
                              maxHeight: "90px",
                              maxWidth: "90px",
                              borderRadius: "10px",
                            }}
                          />
                          <p
                            className="p_small_black mt-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => setProfilePic(null)}
                          >
                            Delete
                          </p>
                        </>
                      ) : (
                        <>
                          <label htmlFor="clogo">
                            <div
                              className="register_page_form_com_logo"
                              onClick={{}}
                            >
                              Upload
                            </div>
                            <input
                              type="file"
                              accept="image/*"
                              name="profile_pic"
                              id="clogo"
                              onChange={(e) => handlePicUpload(e)}
                            />
                          </label>
                          <p className="register_page_form_upload-text mt-1">
                            Corporate Logo
                          </p>
                        </>
                      )}
                    </div>
                    <div className="register_page_form row">
                      <div className="col-12">
                        <label className="auth_label">Legal Name</label>
                        <input
                          placeholder="Legal Name"
                          required
                          type="text"
                          name="legalname"
                          className="auth_input"
                          value={legalname}
                          onChange={(e) => {
                            setlegalname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Corporate Profile</label>
                        <textarea
                          required
                          name="short_desc"
                          className="auth_input auth_textarea col-12"
                          placeholder="Write  a short description"
                          value={bio}
                          onChange={(e) => setbio(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Type of Corporate</label>
                        <select
                          className="auth_input"
                          name="typeOfCorporate"
                          value={typeOfCorporate}
                          onChange={(e) => settypeOfCorporate(e.target.value)}
                        >
                          <option className="" value="">
                            Type Of Company
                          </option>
                          <option className="" value="Corporate Company">
                            Corporate Company
                          </option>
                          <option className="" value="Corporate Business Unit">
                            Corporate Business Unit
                          </option>
                          <option className="" value="Corporate Venture">
                            Corporate Venture
                          </option>
                          <option className="" value="Corporate Incubator">
                            Corporate Incubator
                          </option>
                          <option className="" value="Other">
                            Others
                          </option>
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">
                          Name of Corporate type
                        </label>
                        <input
                          placeholder="Name of Corporate"
                          required
                          type="text"
                          className="auth_input"
                          name="nameOfCorporateType"
                          value={nameOfCorporateType}
                          onChange={(e) => {
                            setnameOfCorporateType(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label>Industry</label>
                        <select
                          className="auth_input auth_select"
                          name="industry"
                          value={industry}
                          onChange={(e) => setindustry(e.target.value)}
                        >
                          <option value="">Select Industry</option>
                          {industriess.map((item, i) => {
                            return (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Field of Interest</label>

                        <div className="goalsdiv">
                          <input
                            name="short_desc"
                            className="auth_input auth_textarea col-12"
                            type="text"
                            placeholder="Enter your field of interest"
                            id="objective"
                            value={interestVal}
                            onChange={(e) => setinterestVal(e.target.value)}
                          />
                          <Tooltip title="Add">
                            <button type="button" onClick={addbenefit}>
                              <AddIcon />
                            </button>
                          </Tooltip>
                        </div>

                        <div className="goalsscroll">
                          {fieldOfInterest
                            ? fieldOfInterest.map((obj, i) => {
                                return (
                                  <>
                                    <span
                                      className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3"
                                      key={i}
                                    >
                                      <p className="program_text">{obj}</p>
                                      <Tooltip title="Delete Row">
                                        <button
                                          type="button"
                                          title="Remove"
                                          onClick={() => removebenefit(i)}
                                          className="program_btn"
                                        >
                                          x
                                        </button>
                                      </Tooltip>
                                    </span>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">
                          Interested Investment Stage
                        </label>
                        <select
                          className="auth_input"
                          name="grossValue"
                          value={stage}
                          onChange={(e) => setstage(e.target.value)}
                        >
                          <option className="color_black" value="">
                            Select
                          </option>
                          <option className="color_black" value="Pre-Seed">
                            Pre-Seed
                          </option>
                          <option className="color_black" value="Seed">
                            Seed
                          </option>
                          <option className="color_black" value="Series A">
                            Series A
                          </option>
                          <option className="color_black" value="Series B">
                            Series B
                          </option>
                          <option className="color_black" value="All">
                            All of the above
                          </option>
                          <option className="color_black" value="Not sure">
                            Not sure
                          </option>
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Annual Turnover</label>
                        <select
                          className="auth_input"
                          name="typeOfCorporate"
                          value={annualturnover}
                          onChange={(e) => setannualturnover(e.target.value)}
                        >
                          <option className="" value="">
                            Enter Annual Turnover
                          </option>
                          <option
                            className=""
                            value="Companies with turnover of Euro 50 Millions"
                          >
                            Companies with turnover of Euro 50 Millions
                          </option>
                          <option
                            className=""
                            value="Companies with turnover of less than Euro 50 Millions"
                          >
                            Companies with turnover of less than Euro 50 Millions
                          </option>
                          <option
                            className=""
                            value="NGO’s/ Farmers Organizations/ Cooperatives"
                          >
                            NGO’s/ Farmers Organizations/ Cooperatives
                          </option>
                        </select>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">
                          Products and services
                        </label>
                        <input
                          placeholder="Enter Major Product/Business"
                          required
                          type="text"
                          name="legalname"
                          className="auth_input"
                          value={majorproduct}
                          onChange={(e) => {
                            setmajorproduct(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12" style={{ height: "100%" }}>
                        <label className="auth_label">
                          Interested Industries
                        </label>
                        <Multiselect
                          isMulti
                          options={options}
                          className=""
                          displayValue="label"
                          placeholder="Interested Industries"
                          name="industries"
                          closeIcon="cancel"
                          value={interestedIndustry}
                          onSelect={addInputEvent3}
                          onRemove={addInputEvent3}
                        />
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Location</label>

                        <div className="row no-margin justify-content-between">
                          <Location
                            country={country}
                            setcountry={setcountry}
                            state={state}
                            setstate={setstate}
                            city={city}
                            setcity={setcity}
                            states={states}
                            setstates={setstates}
                            cities={cities}
                            setcities={setcities}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">LinkedIn Profile</label>
                        <input
                          placeholder="Linkedin Profile URL"
                          required
                          type="url"
                          className="auth_input"
                          name="links"
                          value={linkedin}
                          onChange={(e) => setlinkedin(e.target.value)}
                        />
                      </div>
                      <div className="col-12">
                        <label className="c_plan_label p_small_black">
                          {" "}
                          Choose the type of account you want
                        </label>
                        <p className="p_small_black">{companySize}</p>
                        <div className="row c_plan_row mt-16">
                          <div className="col-12 col-md-4">
                            <div
                              style={{
                                cursor: "pointer",
                                border: `${
                                  currentPlan === 5
                                } ? 1px solid #262C5B : none`,
                                padding: "5px auto",
                              }}
                              onClick={() => setCompanySize(5)}
                            >
                              <div
                                className={
                                  currentPlan === 5
                                    ? "c_plan_col active"
                                    : "c_plan_col"
                                }
                              >
                                <h6 className="h5_medium_white">5 users</h6>
                                <p className="p_small_black">
                                  Recommended for early stage corporations
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div
                              style={{
                                cursor: "pointer",
                                border: `${
                                  currentPlan === 12
                                } ? 1px solid #262C5B : none`,
                                padding: "5px auto",
                              }}
                              onClick={() => setCompanySize(12)}
                            >
                              <div
                                className={
                                  currentPlan === 12
                                    ? "c_plan_col active"
                                    : "c_plan_col"
                                }
                              >
                                <h6 className="h5_medium_white">12 users </h6>
                                <p className="p_small_black">
                                  Recommended for medium to large stage
                                  corporations
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div
                              style={{
                                cursor: "pointer",
                                border: `${
                                  currentPlan === 100
                                } ? 1px solid #262C5B : none`,
                                padding: "5px auto",
                              }}
                              onClick={() => setCompanySize(100)}
                            >
                              <div
                                className={
                                  currentPlan === 100
                                    ? "c_plan_col active"
                                    : "c_plan_col"
                                }
                              >
                                <h6 className="h5_medium_white">Custom</h6>
                                <p className="p_small_black">
                                  Recommended for big corporations with large
                                  team size
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Document</label>
                        {document ? (
                          <>
                            <p
                              className="body1 w-fit"
                              style={{ color: "#636363" }}
                            >
                              <svg
                                className="mr-16"
                                width="23"
                                height="13"
                                viewBox="0 0 23 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z"
                                  fill="#6D7D8B"
                                />
                              </svg>
                              Document
                              <span
                                className="overline ml-16"
                                style={{ cursor: "pointer", color: "#F53939" }}
                                onClick={() => setdocument(null)}
                              >
                                Delete
                              </span>
                            </p>
                          </>
                        ) : (
                          <div className="authIdCard pb-2">
                            <div className="authIdCardFirst">
                              <p
                                className="p_small_black no-margin "
                                title="Click here to add verification of the credibility of our users helps us to ensure the quality of platform."
                              >
                                Please upload relevant documents to verify the
                                credibility of your company.
                              </p>
                            </div>

                            <br />
                            <div className="authIdCardSecond">
                              <label htmlFor="investor_id">
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="investor_logo"
                                  title="Click here to add verification of the credibility of our users helps us to ensure the quality of platform."
                                >
                                  <svg
                                    className=" ml-16 "
                                    width="23"
                                    height="13"
                                    viewBox="0 0 23 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z"
                                      fill="#6D7D8B"
                                    />
                                  </svg>
                                </div>
                                <input
                                  type="file"
                                  accept="application/pdf, image/*"
                                  name="investor_id"
                                  id="investor_id"
                                  onChange={handleDocUpload}
                                  title="Click here to add verification of the credibility of our users helps us to ensure the quality of platform."
                                />
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <label className="p_small_black text-left">
                          *All the documents submitted here will be treated as
                          strictly confidential and will not be used/disclosed
                          anywhere.
                        </label>
                      </div>

                      <div className="register_page_form row px-3 py-0 col-12">
                        <label>Phone Number</label>
                        <OTPService
                          phone={phone}
                          setPhone={setPhone}
                          setphoneVerified={setphoneVerified}
                          className="col-12"
                        />
                        <div
                          className="auth_news "
                          style={{ marginTop: "163px" }}
                        >
                          <label
                            htmlFor="terms_condition"
                            className="text-dark text_align_start"
                          >
                            <Checkbox
                              defaultChecked
                              onClick={() => setterms(!terms)}
                              sx={{
                                color: `#262C5B`,
                                "&.Mui-checked": {
                                  color: `#262C5B`,
                                },
                              }}
                              className="mr-8"
                            />
                            By signing up, you agree to Zefyron Incubation&apos;s{" "}
                            <Link
                              to="/terms-and-conditions"
                              target="_blank"
                              rel="nonreferrer"
                              className="text-dark"
                            >
                              Terms & Conditions{" "}
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="gradient_CTA"
                          style={{ width: "40%" }}
                          type="submit"
                          onClick={handleRegister}
                        >
                          REGISTER
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  else return <NotFoundPage />;
};

export default Coporate_Register;
