import React, { useEffect } from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AllPay from './AllPay/AllPay';
import Paid from "./Paid/Paid"
import Unpaid from "./UnPaid/Unpaid"
import InvoicingFrom from "./InvoicingForm"
import { useNavigate } from 'react-router-dom';
import Query from "../../../../Utils/Query/Query";
import ViewInvoicing from './ViewInvoicing';

const Invoicing = ({ setFinantialBool }) => {
  const [value, setValue] = React.useState("1");
  const [formState, setformState] = React.useState(0);
  const navigate = useNavigate()
  const query = Query()
  const formNo = Number(query.get("in"))

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (formNo) {
      setformState(formNo)
    }
  }, [formNo])

  return (
    <div className='incubator_invoicing_root'>
      {
        formState === 0 ?
          <div className="coworking_incubator_analytics_head">
            <div className="row">
              <div className="col-lg-12 col-md-7 col-6">
                <Box className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs">
                  <TabContext value={value}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <div className="row w_100" style={{ alignItems: 'flex-end' }}>
                        <div className="col-lg-9 col-md-7 col-6" style={{ borderBottom: "1px solid #e4e4e4" }}>
                          <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="All" value="1" />
                            <Tab label="Paid" value="2" />
                            <Tab label="Unpaid" value="3" />
                          </TabList>
                        </div>
                        <div className="col-lg-3 col-md-5 col-6">
                          <div className='d-flex justify-content-end '>
                            <button className='primaryFilled_activeCta w_100' onClick={() => {
                              navigate(`/dashboard/incubator?tab=8&ft=${2}&in=${1}`)
                              setformState(1)
                            }}>Create invoice</button>
                          </div>
                        </div>
                      </div>
                    </Box>
                    <TabPanel value="1">
                      <AllPay
                        setformState={setformState}
                      />
                    </TabPanel>
                    <TabPanel value="2">
                      <Paid
                        setformState={setformState}
                      />
                    </TabPanel>
                    <TabPanel value="3">
                      <Unpaid
                        setformState={setformState}
                      />
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>

            </div>
          </div> : formState === 1 ? <InvoicingFrom
            setformState={setformState}
            setFinantialBool={setFinantialBool}
          /> : <ViewInvoicing
            setformState={setformState}
            setFinantialBool={setFinantialBool}
          />
      }
    </div>
  )
}

export default Invoicing