import React from "react";
// import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import supportimage from "../../../Assets/images/events/ri_customer-service-fill.jpg";
import priceimage from "../../../Assets/images/events/cil_view-module.jpg";
const Card = (props) => {
  const { service, handleServiceid } = props;
  // const navigate = useNavigate();

  return (
    <div className="col-12 point mb-24">
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide"></div>
        <div className="user_incubation_course_rightMain w_100">
          <div
            onClick={() => handleServiceid(service.hostid._id, service._id)}
            className="row"
          >
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                <div className="user_incubation_course_rightHead">
                  <div className="user_incubation_course_rightTitle">
                    <h5>{service.servicename}</h5>
                    <p>{new Date(service.createdAt).toDateString()}</p>
                    <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2">
                        <img src={priceimage} />
                      </span>
                      <p className="body2">€ {service.pricing}</p>
                    </div>
                    <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2">
                        <img src={supportimage} />
                      </span>
                      <p className="body2"> {service.customerSupport}</p>
                    </div>
                  </div>
                </div>

                <div className="user_incubation_course_AvatarGroup_root">
                  <div className="user_incubation_course_AvatarGroups">
                    <Avatar src={service.hostid.avatar} />
                  </div>
                  <div className="user_incubation_course_startupEnrolled">
                    <p className="caption color_light_grey">
                      Hosted by {service.hostid.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_leftSide">
                <div className="user_course_desc pt-3">
                  <ul>
                    {service.services.map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
