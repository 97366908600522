import React, { useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Element from "../Elements";
import "./step2.css";
import { FormContext } from "../Context/FormContext";
import Axios from "axios";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Step2 = (props) => {
  const { Finantial, balanceSheet, handleNext } = props;

  
  var date = new Date();
  var curyear = date.getFullYear();

  let { fields } = Finantial ?? {};
  const context = useContext(FormContext);
  const { handleChangeFinancial } = context;

  const [value, setValue] = React.useState(0);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const submitCashFlow = async (e) => {
    //console.log(Finantial);
    e.preventDefault();
    await Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        fields: Finantial.fields,
        finFilled: true,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success("Saved Successfully");
          setValue(1);
        }
      })
      .catch(() => {
        toast.error("Could not save");
      });
  };
  // const navigate = useNavigate();

  const submitBalanceSheet = async (e) => {
    e.preventDefault();
    await Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        assets: balanceSheet.Assets,
        liabilities: balanceSheet.Liabilities,
        labFilled: true,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success("Saved Successfully");
          // navigate("/service/valuation?tab=2");
          handleNext();
        }
      })
      .catch(() => {
        toast.error("Could not save");
      });
  };

  // const saveFormProgress = () => {
  //   // console.log(questionnare);
  //   Axios({
  //     method: "post",
  //     url: "/api/valuation/questionnaire",
  //     data: {
  //       fields: Finantial.fields,
  //       assets: balanceSheet.Assets,
  //       liabilities: balanceSheet.Liabilities,
  //     },
  //   })
  //     .then((res) => {
  //       if (res.data) {
  //         toast.success("Saved Successfully");
  //       }
  //     })
  //     .catch(() => {
  //       toast.error("Could not save");
  //     });
  // };

  // const NextBtn = () => {
  //   return (
  //     <>
  //       <div className="savenextbtn">
  //         <button className="gradient_CTA" onClick={() => handleNext()}>
  //           SAVE AND NEXT
  //         </button>
  //       </div>
  //     </>
  //   );
  // };
  return (
    <>
      <div className="main-tab-content">
        <div className="valuationHeading">
          <h3>Financial</h3>
          <p>
            The qualitative aspects of a company are valuable as guarantee of
            future success, especially in early stage companies.
          </p>
        </div>
        <div className="tabs-content">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabs}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="CASH FLOW" {...a11yProps(0)} className="tabscont" />
            <Tab label="BALANCE SHEET" {...a11yProps(1)} className="tabscont" />
          </Tabs>
          <TabPanel
            value={value}
            index={0}
            className="tabpanelcontent tabpanelcontnet2"
          >
            <form onSubmit={submitCashFlow}>
              <table className="cashflowTable">
                <thead className="cashflowthead">
                  <tr>
                    <th></th>
                    <th>
                      Past Year
                      <p>{curyear - 1}-{curyear}</p>
                    </th>
                    <th>
                      Forecasts {">"}
                      <p>{curyear}-{curyear + 1}</p>
                    </th>
                    <th>
                      <p>{curyear+1}-{curyear + 2}</p>
                    </th>
                    <th>
                      <p>{curyear+2}-{curyear + 3}</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="cashflowthead">
                  {fields
                    ? fields.map((field, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <th>{field.row_label}(€)</th>
                              <td>
                                {field.past_year.type === "input" ? (
                                  <input
                                    className="financial_input"
                                    type="number"
                                    required
                                    value={field.past_year["value"]}
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.past_year.id,
                                        event
                                      )
                                    }
                                  />
                                ) : (
                                  <p>
                                    <b>{field.past_year.value}</b>
                                  </p>
                                )}
                              </td>
                              <td>
                                {field.forcast.type === "input" ? (
                                  <input
                                    className="financial_input"
                                    type="number"
                                    value={field.forcast["value"]}
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.forcast.id,
                                        event
                                      )
                                    }
                                  />
                                ) : (
                                  <p>
                                    <b>{field.forcast.value}</b>
                                  </p>
                                )}
                              </td>
                              <td>
                                {field.forcast1.type === "input" ? (
                                  <input
                                    className="financial_input"
                                    type="number"
                                    value={field.forcast1["value"]}
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.forcast1.id,
                                        event
                                      )
                                    }
                                  />
                                ) : (
                                  <p>
                                    <b>{field.forcast1.value}</b>
                                  </p>
                                )}
                              </td>
                              <td>
                                {field.forcast2.type === "input" ? (
                                  <input
                                    className="financial_input"
                                    type="number"
                                    value={field.forcast2["value"]}
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.forcast2.id,
                                        event
                                      )
                                    }
                                  />
                                ) : (
                                  <p>
                                    <b>{field.forcast2.value}</b>
                                  </p>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : null}
                </tbody>
              </table>

              <div className="savenextbtn">
                <button className="gradient_CTA" type="submit">
                  SAVE AND NEXT
                </button>
              </div>
            </form>
            {/* <NextBtn /> */}
          </TabPanel>
          <TabPanel value={value} index={1} className="tabpanelcontent">
            <div className="balance-tabpannel">
              {balanceSheet ? (
                <form onSubmit={submitBalanceSheet}>
                  <div className="balanceSheetmain">
                    <div className="assetsmain">
                      <h1>Assets(€)</h1>
                      <hr />
                      <div className="balanceSheet">
                        {balanceSheet.Assets
                          ? balanceSheet.Assets.map((field, i) => (
                              <Element key={i} field={field} />
                            ))
                          : null}
                      </div>
                    </div>
                    <div className="liabilitymain">
                      <h1>Liabilities(€)</h1>
                      <hr />
                      <div className="balanceSheet">
                        {balanceSheet.Liabilities
                          ? balanceSheet.Liabilities.map((field, i) => (
                              <Element key={i} field={field} />
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="savenextbtn">
                    <button className="gradient_CTA" type="submit">
                      SAVE AND NEXT
                    </button>
                  </div>
                </form>
              ) : (
                ""
              )}
            </div>
            {/* <NextBtn /> */}
          </TabPanel>
        </div>
      </div>
    </>
  );
};

Step2.propTypes = {
  Finantial: PropTypes.any,
  handleNext: PropTypes.any,
  balanceSheet: PropTypes.any,
  handleChangeFinancial: PropTypes.any,
};
export default Step2;
