import React from 'react'
import Skeleton from '@mui/material/Skeleton';
export const ApplicationSkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 6; index++) {
               list.push(
               <div className="col-sm-6 col-md-3 col-lg-3">
                    <div style={{ cursor: "pointer" }} className={`dashboard_01_connections_list_item`}>
                         <div className="dashboard_01_connections_logo_list_block" style={{ backgroundColor: "#D9D9D9" }}>

                              <Skeleton animation="wave" variant="rounded" style={{
                                   borderRadius: "50%",
                                   textAlign: "center",
                                   justifyContent: "center",
                                   margin: "0 auto",
                                   width: "64px",
                                   height: "64px",
                              }} />

                         </div>
                         <div className="d-flex flex-column align-items-center pb-3">

                                   <Skeleton animation="wave" width="60%" height={40} />

                                   <Skeleton animation="wave" width="60%" height={20} />
                         </div>
                    </div>
               </div>)
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}
export const WeeklySkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 4; index++) {
               list.push(
              
                    <div className="row">
                  <td key={index} className="col-2 d-flex align-items-center mr-2" >
                    
                    <Skeleton animation="wave" width="100%" height={70} />
                    
                  </td>
                  <div
                    className="col-10 row d-flex align-items-center"
                    
                  >
                    {[1,2,3,4].map((p) => {
                      return (
                        <>
                          <div className="col-3" style={{borderBottom:"0.25px solid grey",paddingBottom:"30px",paddingTop:"30px"}}>
                            
                              <Skeleton key={p}  className="row  d-flex align-items-center" animation="wave" width="100%" height={80} />
                         
                          
                          </div>
                        </>
                      );
                    })}
                  </div>
                  
                </div>

          )
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}
export const MemberSkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 4; index++) {
               list.push(
              
                    <div
                          className="col-12 no-padding mt-1 row dashboard_mentor_card"
                          key={index}
                         
                        >
                          <div className="col-12 col-md-2 d-flex align-items-center">
                            
                             <Skeleton style={{border:"10px"}} animation="wave" width={90} height={90} />
                          </div>
                          <div
                            className="col-12 col-md-5 d-flex flex-column justify-content-center"
                            
                          >
                            <h5 className="row" style={{ color: "#262C5B" }} 
                            
                            >
                           <Skeleton animation="wave" width={100} height={50} />
                            </h5>
                            <div className="row">
                            <Skeleton animation="wave" width="60%" height={20} />
                            <Skeleton animation="wave" width="80%" height={20} />
                            </div>
                           
                          </div>
                          <div className="col-12 col-md-5">
                          <Skeleton animation="wave" width="60%" height={30} />
                          <Skeleton animation="wave" width="70%" height={20} />
                          </div>
                        </div>
          )
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}
export const MeetCardSkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 3; index++) {
               list.push(

                    <div key={index} className="col-12 mb-4">
                    <div className="p-3 meet-head-box">
                      <div className="row">
                        <div className="col-10">
                          <p className="title mb-3"> <Skeleton animation="wave" width="50%" height={50} /></p>
                          <div className="meet-card-smalltext mb-4">
                            <Skeleton animation="wave" width={60} height={20} />
                          </div>
                        </div>
                       
                      </div>

                      <div className="btn_pos">
                      <Skeleton animation="wave" width={70} height={60} />
                          </div>

                      <p>   <Skeleton animation="wave" width="30%" height={20} /></p>
                   
                    </div>
                  </div>

                    )
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}
export const CourseCardSkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 3; index++) {
               list.push(
                    <div className="col-12 mb-24">
                    <div className="onboarding_cards incubation_courses_card_root2 d-flex">
                      <div className="user_incubation_course_slide">
                      </div>
                      <div className="user_incubation_course_rightMain w_100">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                              <div className="user_incubation_course_rightHead">
                                <div className="user_incubation_course_rightTitle">
                                  <h5> <Skeleton animation="wave" width="80%" height={50} /></h5>
                                </div>
                                <div className="user_incubation_course_rightInfo d-flex mt-3 mb-3">
                                  
                                 
                                    <Skeleton animation="wave" width="40%" height={30} />
                                 
                                </div>
                              </div>
                              <div className="user_incubation_course_AvatarGroup_root">
                                <div className="user_incubation_course_AvatarGroups">
                                 
                                </div>
                                <Skeleton animation="wave" width="30%" height={30} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-12">
                            <div className="user_incubation_course_leftSide">
                              <div className="user_course_tags d-flex align-items-center">
                                {
                                 
                                    [1,2,3]. map((i) =>   <Skeleton key={i} style={{marginRight:"6px"}} animation="wave" width="10%" height={30} />) 
                                }
                              </div>
                              <div className="user_course_desc pt-3">
                                <p className="caption">
                                <Skeleton animation="wave" width="40%" height={30} />
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    )
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}
export const ApplicationStatus = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 3; index++) {
               list.push(
                    <Skeleton animation="wave" width="100%" height={200} /> 
                    )
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>


     )
}