import React , { useState, useEffect } from "react"; 
import "./index.css";
import { Link,useNavigate } from "react-router-dom";
// import { Avatar } from "@mui/material";
import { useAuth } from "../../../Utils/Auth";
import Valuation from "../../../Assets/images/valuation.png"
import axios from "axios";
import Card from "./Card";

export default function DashboardValuation({setReportState,setValuationStep,setActiveStep}) {
  const { user,setSessionExpire } = useAuth();
  const navigate = useNavigate();
  

  const [program,setprogram]= useState([]);

  const getDetails = async ()=>{
    axios({
      url: "/api/dashboard/fetchMyReports",
      method:"get"
    })
    .then((res)=>{
      setprogram(res.data.reports);
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div className="mentor_startups_root" style={{margin: "0"}}>
    <div className="row justify-content-between mx-1 my-1">
      <h5 className="subtitle w_fit">Valuation</h5>
      <Link to={`/dashboard/${user.type}?tab=4`} onClick={()=>{setReportState(1)}}>
        <p className="w_fit bold" style={{marginTop: "5px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
    <div className="row mt-3 mb-2  justify-content-center ">
    <div className="col-12 col-md-6">
     {/* setReportState(1) */}
     <div className="valuation-mainsamplediv">
          <figure>
              <img src={Valuation} alt="valuation" />
          </figure>
          <div className="valation_newDownlaodSample" style={{cursor: "pointer"}} onClick={()=>{ navigate(`/dashboard/${user.type}?tab=4&valuate=2`); setReportState(1)}}>
              <p>Create Now &nbsp;</p>
          </div>
      </div>
    </div>
    {program && program.length>0 ? 
    <div className="col-12 col-md-6">
           { program.map((pro,i)=>
            <>
              {/* <span key={i} className="caption" style={{color:"#121843"}}>{pro._id}</span> */}
              <Card key={i} report={pro} setValuationStep={setValuationStep} setActiveStep={setActiveStep} />
          </>) }
    </div>
          : 
      null}
    </div>
    </div>
  );
}
