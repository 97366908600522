import React, { useState, useEffect } from 'react'
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import search from "./../../../../Assets/images/icons/search.png";
import Card from "./Card";
import './course.css'
import {useParams} from "react-router-dom";

const Courses = ({ setCourseState }) => {
  const { setSessionExpire } = useAuth();
  const [courses, setCourses] = useState([]);
  const { programid } = useParams();

  const fetchCourses = async () => {
    await Axios({
      url: `/api/mentorModules/courses/program/${programid}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setCourses(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
 

  useEffect(() => {
    fetchCourses()
  }, [])
  return <div className="incubation_program_root">
    <div className="incubation_prorgram_search_head">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-10">
          <div className="incubation_programm_searchBoxDiv">
            <input type="text" name="" id="" />
            <button className="p_Bold_secBlue14">
              <img src={search} alt="search" />
              Search
            </button>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-2">
          {/* <div className="incubation_program_filterDiv">filter......</div> */}
        </div>
      </div>
    </div>
    <div className="incubation_program_main mt-40">
      <div className="row">
        <div className="col-12">

        </div>
        {courses && courses.length > 0
          ? courses.map((course, i) => (
            <Card
              key={i}
              course={course}
              setCourseState={setCourseState}
            />
          ))
          : null}
      </div>
    </div>
  </div>
}

export default Courses