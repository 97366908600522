import React, { useEffect, useState } from "react";
import DeclinedCard from "./DeclinedCard";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import IncubatorExplore  from '../../../../../Components/SkeletonLoader/IncubationProgramSkeleton'
const Decline = ({ setIncubationState }) => {
  const [programs, setPrograms] = useState([]);
  const { setSessionExpire } = useAuth();
  const [isLoading,setIsLaoding] = useState(false)
  const fetchAllDecline = async () => {
    setIsLaoding(true)
    await Axios({
      url: `/api/incubatorProgram/declined`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setPrograms(res.data.data);
        setIsLaoding(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLaoding(false)
        }
      });
  };

  useEffect(() => {
    fetchAllDecline();
  }, []);
  return (
    <div className="incubation_profile_card_root">
      <div className="row">
        {isLoading ? <IncubatorExplore/> : (programs && programs.length > 0 ? (
          programs.map((program, i) => (
            <DeclinedCard
              key={i}
              setIncubationState={setIncubationState}
              program={program}
            />
          ))
        ) : (
          <p>No Rejections yet</p>
        )) }

      </div>
    </div>
  );
};

export default Decline;
