import React, { useEffect, useState } from 'react'
// import profile from "../../../../Assets/images/incubatorDash/profile_short.png"
import { LinkedIn } from "@mui/icons-material";
import Query from '../../../../Utils/Query/Query';
import Axios from "axios";
import { useAuth } from '../../../../Utils/Auth';
import { useNavigate } from "react-router-dom"
// import { toast } from 'react-toastify';

const ViewAsset = ({ setViewLeases }) => {
    const query = Query();
    const assetId = query.get('asset');
    const {user, setSessionExpire } = useAuth()
    const [data, setData] = useState(null)
    const navigate = useNavigate()

    const getRequesteData = async () => {
        await Axios({
            url: `/api/incubator-dashboard/byleases/${assetId}`,
            method: "GET",
        }).then((res) => {
            setData(res.data.data)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true)
            }
        })
    }

    useEffect(() => {
        if (assetId) {
            getRequesteData()
        }
    }, [])


    return (
        <div className='realEstate_showReq_root'>
            {
                data !== null ? <>
                    <div className="participants_profile_head">
                        <span
                            className="cta color_light_grey"
                            onClick={() => {
                                navigate(`/dashboard/${user.type}?tab=9&cw=1`)
                                setViewLeases(0);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left mx-1"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                />
                            </svg>
                            Back
                        </span>
                    </div>
                    <div className="realEstate_showReq_main">
                        <div className="row">
                            <div className="col-lg-8 col-md-7 col-12">
                                <div className="realEstate_showReq_profile_root pt-40 pb-40 pr-24 pl-24 onboarding_cards2">
                                    <div className="dashboard01_connection_profile_head_img">
                                        <img src={data.start_up?.logo} alt="logo" />
                                        <div className="ml-12">
                                            <p className="subtitle color_dark_blue">
                                                {data.start_up?.companyName}
                                            </p>
                                            <p className="caption color_dark_grey2">
                                                Startup | {data.start_up.location.country}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-40 pb-40 mb-24" style={{ borderBottom: "1px solid #E2E8F0" }}>
                                        <table>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Asset Id</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id?.asset_id}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Asset Name</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id?.asset_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Quantity</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.bought}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Brand</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id?.brand}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Category</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id?.category}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Condition</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id?.condition}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Status</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id?.status}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Remaining Stock</td>
                                                <td className="body2 color_dark_grey2 pb-16">{data.asset_id.stock - data.asset_id.stock_booked}</td>
                                            </tr>

                                            <tr>
                                                <td className="body2 pb-16 pr-40">Requested From</td>
                                                <td className="body2 color_dark_grey2 pb-16">{new Date(data.from).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <td className="body2 pb-16 pr-40">Requested Till</td>
                                                <td className="body2 color_dark_grey2 pb-16">{new Date(data.till).toLocaleDateString()}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div className='realEstate_showReq_profile_desc mb-40'>
                                        <p className='body2 color_dark_grey'>{data.asset_id.details}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-12">
                                <div className="row">
                                    <div className="col-12 mb-24">
                                        <div className="onboarding_cards2 pr-24 pl-24 pt-16 pb-16">
                                            <div className="participants_right_main_founder_head mb-16">
                                                <p className="title">Founder Details</p>
                                            </div>
                                            <div className="participants_right_main_founder_body">
                                                <img src={data.booked_by?.avatar} alt="profile" />
                                                <div className="participants_right_main_right ml-16">
                                                    <p className="subtitle">
                                                        {data.booked_by?.firstname} {data.booked_by?.lastname}
                                                    </p>
                                                    <p className="caption grey">Founder</p>
                                                    <p>
                                                        <a
                                                            href={`/startup.socialConnects.linkedin`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <LinkedIn style={{ fill: "#0077B5" }} />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="participants_profile_root_highligts_table_main onboarding_cards2 pb-24">
                                            <table className="participants_profile_root_highligts_table">
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Industry
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                                        {data.start_up?.industry}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Location
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                                        {data.start_up?.location.country}
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Audience
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                                        {data.start_up?.audience}
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Employees
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                                        {data.start_up?.employees}
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Funding Status
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                                        {data.start_up?.fundingSeries}
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Growth
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                                        {data.start_up?.growth}
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Incorporation Date
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                                        {new Date(data.start_up.incorporationDate).toLocaleDateString(
                                                            "nl",
                                                            { year: "numeric" }
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Website
                                                    </td>
                                                    <td
                                                        className="body2 color_dark_grey2 participants_profile_root_highligts_td"
                                                        style={{ wordBreak: "break-all" }}
                                                    >
                                                        <a
                                                            href={data.start_up?.website}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {data.start_up?.website}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr className="participants_profile_root_highligts_table_tr">
                                                    <td className="body2 participants_profile_root_highligts_ftd">
                                                        Contact
                                                    </td>
                                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                                        {data.start_up?.contact.phone}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>loading...</>
            }

        </div>
    )
}

export default ViewAsset