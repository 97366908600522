import React, { useEffect } from 'react';
import getDiff from "../../../Utils/diffleft/getDiff";

// import { MoreHoriz, FavoriteBorder, Favorite, Reply } from '@mui/icons-material';
// // import reply from "../../../Assets/Images/icons/reply.png"
// import ReplyComments from './ReplyComments';
// import ReplyInput from "./ReplyInput"
// import { toast } from "react-toastify"
// import Axios from "axios"

const Comment = (props) => {
  const { comment } = props;
  // const [commentInfo, setCommnetInfo] = useState(item);
  // const [commentView, setCommentView] = useState(false);
  // const [isCommentLike, setIsCommentLike] = useState(false);
  // const [CommentLikeLength, setIsCommentLikeLength] = useState(0);
  // const [createReplyComment, setCreateReplyComment] = useState(false);
  // const [replyComment, setReplyComment] = useState("")

  // useEffect(() => {
  //   setCommnetInfo(item)
  // }, [item])

  // const [replyComments, setReplyComments] = useState([]);

  // const getCommentLike = () => {
  //   try {
  //     Axios({
  //       method: "get",
  //       url: `/api/post/comment/getlikes/${item._id}`,
  //     })
  //       .then((res) => {
  //         setIsCommentLikeLength(res.data.data ? res.data.data.length : 0);
  //         if (res.data.data.some((e) => e.by_user._id === user.id)) {
  //           setIsCommentLike(true);
  //         }

  //       })
  //       .catch((err) => {
  //         if (err.response && err.response.status === 401) {
  //           setSessionExpire(true);
  //         }
  //       });
  //   } catch (error) {
  //     toast.error("Some Internal Error")
  //   }
  // };

  // const createCommentLike = () => {
  //   getCommentLike()
  //   setIsCommentLikeLength(CommentLikeLength + 1);
  //   try {
  //     Axios({
  //       method: "get",
  //       url: `/api/post/comment/like/${item._id}`,
  //     })
  //       .then(() => {
  //         setIsCommentLike(true);
  //         setIsCommentLikeLength(CommentLikeLength + 1);
  //       })
  //       .catch((err) => {
  //         setIsCommentLike(false);
  //         setIsCommentLikeLength(CommentLikeLength - 1);
  //         if (err.response.status === 401) {
  //           setSessionExpire(true)
  //         }
  //       });
  //   } catch (error) {
  //     toast.error("Some Internal Error")
  //   }
  // };

  // const createCommentUnlike = () => {
  //   setIsCommentLikeLength(CommentLikeLength - 1);
  //   try {
  //     Axios({
  //       method: "get",
  //       url: `/api/post/comment/like/${item._id}`,
  //     })
  //       .then(() => {
  //         setIsCommentLike(false);
  //       })
  //       .catch((err) => {
  //         setIsCommentLike(true);
  //         setIsCommentLikeLength(CommentLikeLength + 1);
  //         if (err.response.status === 401) {
  //           setSessionExpire(true)
  //         }
  //       });
  //   } catch (error) {
  //     toast.error("Some Internal Error")
  //   }
  // };

  // const createCommentReply = () => {
  //   try {
  //     Axios({
  //       method: "post",
  //       url: `/api/post/comment/reply/create/${item._id}`,
  //       data: {
  //         body: replyComment
  //       }
  //     })
  //       .then((res) => {
  //         setCreateReplyComment(false)
  //         setReplyComment("");
  //         // setIsCommentLikeLength(CommentLikeLength + 1);
  //         setReplyComments((prev) => [...prev, res.data.data])
  //       })
  //       .catch((err) => {
  //         // setIsCommentLike(false);
  //         setCreateReplyComment(false)
  //         // setIsCommentLikeLength(CommentLikeLength - 1);
  //         // if (err.response.status === 401) {
  //         //   setSessionExpire(true)
  //         // }
  //         if (err.response && err.response.status === 401) {
  //           setSessionExpire(true);
  //         }
  //       });
  //   } catch (error) {
  //     toast.error("Some Internal Error")
  //   }
  // };

  // const getCommentReply = () => {
  //   try {
  //     Axios({
  //       method: "get",
  //       url: `/api/post/comment/reply/${item._id}`
  //     })
  //       .then((res) => {
  //         setReplyComments(res.data.data)
  //       })
  //       .catch((err) => {
  //         if (err.response && err.response.status === 401) {
  //           setSessionExpire(true);
  //         }
  //       });
  //   } catch (error) {
  //     toast.error("Some Internal Error")
  //   }
  // };



  // useEffect(()=>{
  //   setReplyComments(newComments.replies)
  // },[newComments])

  useEffect(() => {
    // getCommentLike();
    // getCommentReply()
    // getReplyComments();
  }, [])


  return <>
    {
      comment && comment.byUser ?
        <div className="post_comments_body_root">
          <div className="post_comments_body_main">
            <div className="post_comments_body_img">
              <img src={comment.byUser.avatar} alt="commentImg" />
            </div>
            <div className="post_comments_body">
              <div className="post_comments_body_name">
                <p className="caption color_light_black">{comment.byUser.firstname} {comment.byUser.lastname} <span className='color_light_grey'> | {comment.byUser.type}</span></p>
              </div>
              <div className="post_comments_body_desc">
                <p className="overline color_light_black">{comment.body}</p>
              </div>
              <div className="post_comments_body_likes_root">
                <p className="overline"> {getDiff(comment.createdAt)}</p>
                {/* <p className="overline"> 2 replies</p>
                <p className="overline"> Reply</p> */}
              </div>
            </div>
          </div>
        </div> : null

    }
  </>
};

export default Comment;
