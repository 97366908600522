import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAuth } from "../../../../Utils/Auth";
// import Button from '@mui/material/Button';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";
import CreateEvent from "./createEvent";
import CreateNotes from "../Meet/createNotes";

import Axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment-timezone";
import get_isodiff from "../../../../Utils/ISOdiff/isodiff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

function calendar({ programid }) {
  const { user } = useAuth();
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [currmeet, setCurrmeet] = useState("");
  const [notesopen, setNotesOpen] = useState(false);
  const handleNotesOpen = (id) => {
    setNotesOpen(true);
    setCurrmeet(id);
  };
  const handleNotesClose = () => setNotesOpen(false);
  const [changedDate, setchangedDate] = useState(new Date());

  const [loading, setloading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setevents([]);
  };
  const handleClose = () => {
    setOpen(false);
    setevents([]);
  };

  const [events, setevents] = useState([]);
  const [allevents, setallevents] = useState([]);

  const geteventsbydate = async (e) => {
    setloading(true);
    const response = await Axios.get(
      `/api/incubatorProgram/eventsbydate/${e}/${programid}`
    );
    if (response.data.success) setevents(response.data.data);
    setloading(false);
  };
  const geteventsByMonth = async () => {
    setloading(true);
    const response = await Axios.get(
      `/api/incubatorProgram/eventsbymonth/${changedDate}/${programid}`
    );
    if (response.data.success) setallevents(response.data.data);
    setloading(false);
  };

  useEffect(() => {
    geteventsByMonth();
  }, [changedDate]);

  const tileContent = ({ date }) =>
    allevents.find(
      (x) =>
        moment(x.date).format("DD-MM-YYYY") ===
        moment(date).format("DD-MM-YYYY")
    )
      ? allevents.map((v, i) => {
          if (user.type != "incubator") {
            if (v.for_type) {
              if (v.for_type != "custom") {
                if (user.type != v.for_type) {
                  return;
                }
              } else {
                var found = false;

                for (var j = 0; j < v.for_user.length; j++) {
                  console.log(v.for_user[j].profile.id, user.id);
                  if (v.for_user[j].profile.id == user.id) {
                    found = true;
                    break;
                  }
                }
                if (found === false) return;
              }
            }
          }
          if (
            moment(v.date).format("DD-MM-YYYY") ===
            moment(date).format("DD-MM-YYYY")
          )
            return (
              <ul key={i} className="calendar-event-list">
                <li
                  className={`calendar-event-item row justify-content-between`}
                  style={{ borderLeft: `5px solid ${v.color}` }}
                >
                  {" "}
                  {v.title}
                  {""}
                  {v.type === "meet" ? (
                    <>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-calendar-check"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                      </svg>
                    </>
                  ) : null}
                </li>
              </ul>
            );
        })
      : null;

  const handleStart = (key, title, name) => {
    window.open(
      `/jitsi-meet/${key}?title=${title}&host=${name}&programid=${programid}&d=incprogram`
    );
  };

  return (
    <>
      <div className="calendar">
        <div className="calendar-container row">
          <Calendar
            className="col-12 mb-4"
            tileContent={tileContent}
            onChange={(e) => {
              setDate(e);
              console.log(e);
              handleOpen();
              geteventsbydate(e);
            }}
            onActiveStartDateChange={(e) => setchangedDate(e.activeStartDate)}
            value={date}
            defaultView="month"
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <h5 id="parent-modal-title" style={{ color: "#262C5B" }}>
            Events
          </h5>
          <p style={{ marginBottom: "10px" }}>{date.toDateString()}</p>
          {loading ? <CircularProgress /> : null}
          {events.map((v) => {
            if (user.type != "incubator") {
              if (v.for_type) {
                if (v.for_type != "custom") {
                  if (user.type != v.for_type) {
                    return;
                  }
                } else {
                  var found = false;

                  for (var j = 0; j < v.for_user.length; j++) {
                    console.log(v.for_user[j].profile.id, user.id);
                    if (v.for_user[j].profile.id == user.id) {
                      found = true;
                      break;
                    }
                  }
                  if (found === false) return;
                }
              }
            }
            return (
              <>
                {v.type === "meet" ? (
                  <>
                    <div className="row m-0 align-items-center justify-content-between">
                      <p
                        className="width_fit"
                        style={{
                          borderLeft: `3px solid ${v.color}`,
                          flexGrow: 1,
                        }}
                        id="parent-modal-description"
                      >
                        {v.title}
                      </p>
                      {v.meetid && (
                        <div
                          className="m-1"
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            color: "#262C5B",
                          }}
                          onClick={() => handleNotesOpen(v.meetid)}
                        >
                          Notes
                        </div>
                      )}
                      {get_isodiff(v.date) === "Live" ? (
                        <>
                          {/* <a href={} */}
                          <p
                            type="button"
                            onClick={() =>
                              handleStart(v.meet_id, v.title, v.host_id.name)
                            }
                            className="font12 blue"
                          >
                            Start
                          </p>
                        </>
                      ) : get_isodiff(v.date) === "Expired" ? (
                        <>
                          <p className="font12">Expired</p>
                        </>
                      ) : (
                        <>
                          <p disabled className="font12">
                            {get_isodiff(v.date)} left
                          </p>
                        </>
                      )}{" "}
                    </div>
                  </>
                ) : (
                  <p
                    style={{ borderLeft: `3px solid ${v.color}` }}
                    id="parent-modal-description"
                  >
                    {v.title}
                  </p>
                )}
              </>
            );
          })}

          <CreateEvent
            geteventsbydate={geteventsbydate}
            geteventsByMonth={geteventsByMonth}
            date={date}
            programid={programid}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={notesopen}
        onClose={handleNotesClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={style}>
          <CreateNotes meetid={currmeet} handleClose={handleNotesClose} />
        </Box>
      </Modal>
    </>
  );
}

export default calendar;
