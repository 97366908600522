import React from 'react'
import { Modal, Box } from "@mui/material";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'white',
    boxShadow: 24,
    p: 4,
};

const DeleteModal = ({deleteFunction, open, handleClose}) => {
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <p className="title">Please confirm to delete</p>
                <div className="d-flex justify-content-between mt-40">
                    <button className="primaryFilled_activeCta" onClick={(e) => deleteFunction(e)}>Confirm</button>
                    <button className="secondaryOutlined_activeCta" onClick={handleClose}>Decline</button>
                </div>

            </Box>
        </Modal>
    )
}

export default DeleteModal