import React, { useState } from "react"; //  , { useState }
// import { Link } from "react-router-dom";
// import Checkbox from '@mui/material/Checkbox';
import profile_img from "../../Icons/profile.png"; 
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../../../Utils/Auth";
import {toast} from "react-toastify"; 
import Axios from "axios";
import Avatar from "@mui/material/Avatar";

const Step3 = (props) => {
  const {
    jobTitle,
    setJobTitle,
    linkedProfile,
    setlinkedProfile,
    profilePic,
    setProfilePicUrl,
    // profile,
    // setProfile,
  } = props;

  
  const { user } = useAuth();
  const [ispicloading, setispicloading] = useState(false);

 
  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("profilepic", image);
    setispicloading(true);
    Axios({
      method: "post",
      url: "/api/startup/profilepic",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        data
          ? setProfilePicUrl(data.location)
          : toast.error("Error in upload", { position: "bottom-right" });
        // setProfile({
        //   ...profile,
        //   profilePic: data.location,
        // });
        setispicloading(false);
        toast.success("Profile Image upload", { position: "bottom-right" });
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };


  return (
    <form>
      <div className="register_page_form_upload">
        {ispicloading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            {profilePic ? (
              <>
                <div className="profile_pic_block">
                  <Avatar
                    alt="Profile Pic"
                    src={profilePic}
                    sx={{ width: 90, height: 90 }}
                    variant="rounded"
                  />
                </div>
                <p
                  className="text-dark mt-2"
                  onClick={() => setProfilePicUrl(null)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </p>
              </>
            ) : (
              <>
                <label htmlFor="clogo">
                  <div className="register_page_form_com_logo" onClick={{}}>
                    <img src={profile_img} />
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_pic"
                    id="clogo"
                    onChange={(e) => handlePicUpload(e)}
                  />
                </label>
                <p className="text-dark mt-1">Profile Picture</p>
              </>
            )}
          </>
        )}
      </div>

      <div className="register_page_form row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <label>First Name*</label>
                <input
                  placeholder="First Name"
                  required
                  type="text"
                  className="auth_input"
                  value={user.firstname}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <label>Last Name*</label>
                <input
                  placeholder="Last Name"
                  required
                  type="text"
                  className="auth_input"
                  value={user.lastname}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <label>Job Title*</label>
                <input
                  placeholder="Job Title"
                  required
                  type="text"
                  className="auth_input"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <label>LinkedIn Profile Url*</label>
                <input
                  placeholder="LinkedIn Profile Url"
                  required
                  type="text"
                  className="auth_input"
                  value={linkedProfile}
                  onChange={(e) => setlinkedProfile(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
       

        
       
      </div>
      {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
      
    </form>
  );
};

export default Step3;
