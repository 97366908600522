import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from "react-router-dom"
import { useAuth } from "../../../../Utils/Auth";
import Axios from 'axios';
import { toast } from 'react-toastify';
import Feedback from './Components/Feedback';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const AssessmentComp = ({ setModuleState, getEnrolledUser, setShowLockSessions }) => {
    const query = useQuery();
    const assessment_id = query.get("assessment");
    const isLast = query.get("isLast");

    const { setSessionExpire } = useAuth()
    const { courseid,programid } = useParams()

    const [assessment, setAssessment] = useState([]);
    const [assessmentMain, setAssessmentMain] = useState(null);

    // var assessmentMap = new Map();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [assessmentMap, setAssessmentMap] = useState(new Map())

    const getAssessment = async () => {
        await Axios({
            url: `/api/mentorModules/assessment/${courseid}/${assessment_id}`,
            method: "get",
        }).then((res) => {
            setAssessment(res.data.data.assessment);
            setAssessmentMain(res.data.data);
            if (res.data.data.is_submitted) {
                setModuleState(4)
            }
            getEnrolledUser()
            // setModules(res.data.mod);
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    const setAnswer = (e, id) => {
        // console.log(e.target.value)
        setAssessmentMap((prev) => prev.set(id, e.target.value))
        // console.log(assessmentMap)
    }

    const submitAssessment = async () => {
        setShowLockSessions(true)
        var newArr = [];
        assessmentMap.forEach((v, k) => newArr.push({
            question_id: k,
            answer: v,
        }))
        await Axios({
            url: `/api/mentorModules/assessment/submit/${courseid}/${assessmentMain._id}/${programid}`,
            method: "post",
            data: newArr
        }).then(() => {
            toast.success("Result Submitted Successfully");
            setModuleState(4)
            getEnrolledUser();
            setShowLockSessions(true)
            setShowLockSessions(false)
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    useEffect(() => {
        getAssessment()
    }, [assessment_id])

    return <div className="userCourses_tabPanel_content_main">
        <div className="userCourses_tabPanel_assessment_main">
            <div className="userCourses_tabPanel_assessment_head mb-4">
                <p className="title">{assessmentMain !== null ? assessmentMain.name : ""}</p>
            </div>
            <div className="userCourses_tabPanel_assess_ques_main">
                {
                    assessment && assessment.length > 0 ?
                        assessment.map((assess, i) => <div className="userCourses_assessment_qAroot" key={i}>
                            <div className="userCourses_tabPanel_assess_ques_head">
                                <p className="caption color_dark_blue">Question {i + 1}</p>
                            </div>
                            <hr />
                            <div className="userCourses_tabPanel_assess_quesAns">
                                <div className="userCourses_tabPanel_assess_question">
                                    <p className="body1 color_dark_grey2 mt-2">{assess.question} ?</p>
                                </div>
                                <div className="userCourses_tabPanel_assess_Ans_main mt-2">
                                    {
                                        assess.options.option_one !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                                            <div className="userCourse_radio_main">
                                                <input
                                                    type="radio"
                                                    name={`Ans${i + 1}`}
                                                    value={assess.options.option_one}
                                                    onChange={(e) => setAnswer(e, assess._id)}
                                                    className="userCourse_radio_input"
                                                    id={`Ans${i + 10}`}
                                                />

                                                <label
                                                    htmlFor={`Ans${i + 10}`}
                                                    className={`userCourse_radio`}></label>
                                            </div>

                                            <p className="body2">{assess.options.option_one}</p>
                                        </div> : null
                                    }
                                    {
                                        assess.options.option_two !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                                            <div className="userCourse_radio_main">
                                                <input
                                                    type="radio"
                                                    name={`Ans${i + 1}`}
                                                    value={assess.options.option_two}
                                                    onChange={(e) => setAnswer(e, assess._id)}
                                                    className="userCourse_radio_input"
                                                    id={`Ans${i + 20}`}
                                                />

                                                <label
                                                    htmlFor={`Ans${i + 20}`}
                                                    className={`userCourse_radio`}></label>
                                            </div>
                                            <p className="body2">{assess.options.option_two}</p>
                                        </div> : null
                                    }
                                    {
                                        assess.options.option_three !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                                            <div className="userCourse_radio_main">
                                                <input
                                                    type="radio"
                                                    name={`Ans${i + 1}`}
                                                    value={assess.options.option_three}
                                                    onChange={(e) => setAnswer(e, assess._id)}
                                                    className="userCourse_radio_input"
                                                    id={`Ans${i + 30}`}
                                                />

                                                <label
                                                    htmlFor={`Ans${i + 30}`}
                                                    className={`userCourse_radio`}></label>
                                            </div>
                                            <p className="body2">{assess.options.option_three}</p>
                                        </div> : null
                                    }
                                    {
                                        assess.options.option_four !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                                            <div className="userCourse_radio_main">
                                                <input
                                                    type="radio"
                                                    name={`Ans${i + 1}`}
                                                    value={assess.options.option_four}
                                                    onChange={(e) => setAnswer(e, assess._id)}
                                                    className="userCourse_radio_input"
                                                    id={`Ans${i + 40}`}
                                                />

                                                <label
                                                    htmlFor={`Ans${i + 40}`}
                                                    className={`userCourse_radio`}></label>
                                            </div>
                                            <p className="body2">{assess.options.option_four}</p>
                                        </div> : null
                                    }
                                    {
                                        assess.options.option_five !== "" ? <div className="userCourses_tabPanel_assess_Ans">
                                            <div className="userCourse_radio_main">
                                                <input
                                                    type="radio"
                                                    name={`Ans${i + 1}`}
                                                    value={assess.options.option_five}
                                                    onChange={(e) => setAnswer(e, assess._id)}
                                                    className="userCourse_radio_input"
                                                    id={`Ans${i + 50}`}
                                                />

                                                <label
                                                    htmlFor={`Ans${i + 50}`}
                                                    className={`userCourse_radio`}></label>
                                            </div>
                                            <p className="body2">{assess.options.option_five}</p>
                                        </div> : null
                                    }

                                </div>

                            </div>
                        </div>) : null
                }

                <div className="w_100 d-flex justify-content-center align-items-center">
                    <button className='primaryFilled_activeCta' onClick={isLast === "true" ? handleOpen: submitAssessment} >Submit</button>
                </div>


            </div>
        </div>
        <Feedback open={open} handleClose={handleClose} submit={submitAssessment} />
    </div>
}

export default AssessmentComp