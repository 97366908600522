import Skeleton from '@mui/material/Skeleton';

export default function IncubationProgramSkeleton() {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 8; index++) {
               list.push(
                    <div className="col-lg-3 col-md-6 col-12">
                         <div className="incubation_program_card_root" style={{ paddingBottom: 0, marginBottom: 0 }}>
                              <Skeleton animation="wave" width="100%" height={150} className="incubation_program_card_head" style={{ transform: "none", borderRadius: "none" }} />

                              <div className="incubation_program_card_main pl-3 pr-3">
                                   <div className="incubation_program_card_info d-flex justify-content-between">
                                        <span>
                                             <Skeleton animation="wave" width={100} height={40} />
                                        </span>
                                   </div>
                                   <div className="d-flex justify-content-between">
                                        <span>
                                             <Skeleton animation="wave" width={50} height={30} />
                                        </span>
                                        <span>  
                                             <Skeleton animation="wave" width={50} height={30} /> 
                                        </span>
                                   </div>
                              </div>
                              <Skeleton animation="wave" variant="rectangular" width="100%" height="100%"
                                   className="startup_assets_card_footer mt-16"
                                   style={{
                                        borderBottomLeftRadius: '12px',
                                        borderBottomRightRadius: '12px',
                                        padding: "10px"
                              }} />
                         </div>

                    </div>
               )
          }
          return list
     }
     return (
          <>
               {renderSkeleton()}
          </>
     )
}