import React from "react";
import * as xlsx from "xlsx";

const exceltojson = ({ handleexcelmails }) => {
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        handleexcelmails(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };
  return (
    <form>
      <label htmlFor="upload">Upload Excel File</label>
      <input 
        type="file" 
        name="upload" 
        id="upload" 
        onChange={readUploadFile} 
        accept=".csv, application/vnd.ms-excel, .xlsx,.xls"
      />
    </form>
  );
};

export default exceltojson;
