import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import logo from "../../../../Assets/images/logo/logoblack.png";
import "./index.css";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import embedLogo from "../../../../Assets/images/logo/TWF_emblem_black_400H.png";
import { useMediaQuery } from "react-responsive";

import DashboardNav from "../../../../Dashboard/Components/DashboardNav";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios"
import { KeyboardArrowLeft } from "@mui/icons-material"
import ModuleComp from "./ModuleComp";
import SessionComp from "./SessionComp";
import AssessmentComp from "./AssessmentComp";
import AssignmentComp from "./AssignmentComp";
import AssessmentResult from "./AssessmentResult";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function WatchCourseDash() {
    const navigate = useNavigate();
    // console.log(navigate)

    const { isLoading, setSessionExpire } = useAuth();
    const isTab = useMediaQuery({ query: "(max-width:768px)" });
    const query = useQuery();
    var currentStep = Number(query.get("tab"));
    var currentProStep = Number(query.get("ps"));
    const session_id = query.get("session");
    const assessment_id = query.get("assessment");
    const assignment_id = query.get("assignment");
    const { courseid, programid } = useParams()
    const [course, setCourse] = useState(null)
    const [modules, setModules] = useState([]);
    const [moduleState, setModuleState] = useState(1)
    const [progress, setProgress] = useState(0)
    const [enrolled, setEnrolled] = useState(null)
    // const [session, setSession] = useState(null);
    const [showLockSessions, setShowLockSessions] = useState(false);

    const getSessionByModule = async (m_id) => {
        await Axios({
            url: `/api/mentorModules/sessions/module/${m_id}`,
            method: "get",
        }).then((res) => {  
            var isLast = false;
            if(res.data.assessment === null && res.data.assignment === null && res.data.session.length < 2)
            {
                isLast = true;
            }
            navigate(`/service/userCourses/${courseid}/${programid}?session=${res.data.session[0]._id}&module=${m_id}&isLast=${isLast}`)
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });

    }

    const getCourseDetail = async () => {
        await Axios({
            url: `/api/mentorModules/course/${courseid}`,
            method: "get",
        })
            .then((res) => {
                setCourse(res.data.course);
                setModules(res.data.mod);
                if (session_id || assessment_id || assignment_id) {
                    // console.log('')
                } else {
                    if (res.data.mod.length > 0) {
                        getSessionByModule(res.data.mod[0]._id);
                    }
                }
            })
            .catch(() => {
                // if (err.response.status === 401) {
                    setSessionExpire(true);
                // }
            });
    }

    const getEnrolledUser = async () => {
        await Axios({
            url: `/api/mentorModules/enrolled/course/${courseid}`,
            method: "get",
        }).then((res) => {
            setProgress(res.data.data.progress)
            setEnrolled(res.data.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    useEffect(() => {
        getCourseDetail();
        getEnrolledUser()
    }, [courseid])

    useEffect(() => {
        if (currentStep) {
            // setValue(currentStep);
        }
    }, [currentStep]);

    useEffect(() => {
        if (currentProStep) {
            // setEventActiveStep(currentProStep)
            // setIncubationBool(true)
        }

    }, [currentProStep])

    if (isLoading) {
        return <div></div>;
    } else {
        return (
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: "background.paper",
                    display: "flex",
                    height: 224,
                }}
                className="dashboard01_main_box"
            >
                <div className="userCourses_tabs_root">
                    <div className="userCourses_head_root">
                        <div className="dashboard01_nav_head user_course_nav_head">
                            <Link to="/">
                                <img
                                    src={isTab ? embedLogo : logo}
                                    className="dashboard_left_nav_logo"
                                />
                            </Link>

                        </div>
                        <div className="userCourse_head_main">
                            <div className="userCourse_head_back">
                                <p className="cta color_light_grey d-flex align-items-center point" onClick={() => {
                                    navigate(`/service/userprogram/${programid}?tab=4&course=${courseid}`)

                                }}><span><KeyboardArrowLeft /></span> BACK</p>
                            </div>
                            <div className="userCourse_head_prgoress_root">
                                <p className="overline">{Math.floor(progress)}% completed</p>
                                <div className="userCourse_head_prgoress_main">
                                    <div className="userCourse_head_prgoress" style={{ width: `${progress}%` }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="userCourses_tabs_main">
                        {
                            modules && modules.length > 0 ?
                                modules.map((module, i) => <ModuleComp
                                    module={module}
                                    key={i}
                                    course={course}
                                    setModuleState={setModuleState}
                                    moduleState={moduleState}
                                    setShowLockSessions={setShowLockSessions}
                                    showLockSessions={showLockSessions}
                                />) : null
                        }

                    </div>

                </div>
                <DashboardNav />
                {
                    course === null ? null : <div className="userCourses_tabPanel_content_root">
                        <div className="userCourses_tabPanel_content_head">
                            <div className="userCourses_tabPanel_content_h5">
                                <h5>{course.title}</h5>
                            </div>
                            <div className="userCourses_tabPanel_content_head_detail">
                                <div>
                                    <p className="overline color_light_grey">Enrolled on</p>
                                    {
                                        enrolled !== null ?
                                            <p className="overline">{new Date(enrolled.createdAt).toLocaleDateString()}</p> : ""
                                    }
                                </div>
                                
                            </div>
                        </div>
                        {
                            moduleState === 1 ? <SessionComp
                                setModuleState={setModuleState}
                                getEnrolledUser={getEnrolledUser}
                                getCourseDetail={getCourseDetail}
                                setProgress={setProgress}
                                setShowLockSessions={setShowLockSessions}
                                showLockSessions={showLockSessions}
                                modules={modules}
                            /> : moduleState === 2 ? <AssessmentComp
                                setModuleState={setModuleState}
                                getEnrolledUser={getEnrolledUser}
                                getCourseDetail={getCourseDetail}
                                setProgress={setProgress}
                                setShowLockSessions={setShowLockSessions}
                                showLockSessions={showLockSessions}
                            /> : moduleState === 3 ? <AssignmentComp
                                getEnrolledUser={getEnrolledUser}
                                getCourseDetail={getCourseDetail}
                                setProgress={setProgress}
                                setModuleState={setModuleState}
                                setShowLockSessions={setShowLockSessions}
                                showLockSessions={showLockSessions}
                            /> : moduleState === 4 ? <AssessmentResult
                                getEnrolledUser={getEnrolledUser}
                                getCourseDetail={getCourseDetail}
                                setProgress={setProgress}
                                setModuleState={setModuleState}
                            /> : null
                        }

                    </div>
                }




            </Box>
        );
    }
}