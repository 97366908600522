import React from "react";
import { CalendarViewMonth, ArrowForward } from "@mui/icons-material";
// import { AvatarGroup, Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const Card = (props) => {
  const { course,setCourseState } = props;
  const {programid} = useParams();
  const navigate = useNavigate();
  return (
    <div
      className="col-12 point mb-24"
      onClick={()=>{
        setCourseState(1)
        navigate(`/service/userprogram/${programid}?tab=4&course=${course._id}`)
      }}
    >
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide">

        </div>
        <div className="user_incubation_course_rightMain w_100 pt-3 pb-3">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                <div className="user_incubation_course_rightHead">
                  <div className="user_incubation_course_rightTitle">
                    <h5>{course.title}</h5>
                  </div>
                  <div className="user_incubation_course_rightInfo d-flex mt-3 mb-3">
                    {/* <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2"><Videocam /></span>
                      <p className="body2">24.5 Hours</p>
                    </div> */}
                    <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2"><CalendarViewMonth /></span>
                      <p className="body2">{course.module_count} Modules</p>
                    </div>
                  </div>
                </div>
                <div className="user_incubation_course_rightFoot mt-16">
                  <p className="cta color_light_grey">View course details <ArrowForward /> </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_leftSide">
                <div className="user_course_tags d-flex align-items-center">
                  {
                    course.tag && course.tag.length > 0 ? 
                    course.tag.map((item,i)=><p className="incubation_chips" key={i}>{item}</p>):null
                  }
                  
                </div>
                <div className="user_course_desc pt-3">
                  <p className="caption">
                    {course.summary}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Card;