import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import "./index.css";

export default function Requests({setconnectionState}) {
  const [requests, setRequests] = useState([]);
  const { user } = useAuth();

  const getReceived = async () => {
    await axios({
      url: "/api/connection/received/false",
      method: "get",
    })
      .then((res) => {
        setRequests(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log();
        }
      });  
  };

  useEffect(() => {
    getReceived();
   
  }, []);

  return (
    <div className="mentor_tasks_root">
    <div className="row justify-content-between mx-1 my-1">
      <h5 className="subtitle w_fit">Connections</h5>
      <Link to={`/dashboard/${user.type}?tab=2`} onClick={()=>setconnectionState(1)}>
        <p className="w_fit bold" style={{marginTop: "5px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
      <div className="row">
        {requests.length!==0 ? (
           requests.slice(0,2).map((request, i) => ( 
            <div className="col-lg-12 my-1 mb-2" key={i}>
              <div className="connection-box">
                <div className="row">
                <div className="col-12 d-flex justify-content-between mb-2">
                  <p className="overline" style={{color: "#4D4B4B"}}>{request.sender.type}</p>
                  <p className="overline" style={{color: "#989898"}}>2 days ago</p>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                  <Avatar src={request.sender.avatar} className="mr-2" variant="rounded" />
                  <p className="body2" style={{color:"#262C5B"}}>{request.sender.name}</p>
                  </div>
                <Link to={`/dashboard/${user.type}?tab=1651&${request.sender.type}=${request.sender._id}`}>
                  <p className="overline" style={{color: "#4D4B4B"}}>
                    View Profile
                  </p>
                </Link>
                </div>
                </div>
              </div>
            </div>
           )) 
         ) : (
           <div className="col-12 d-flex align-items-center justify-content-center" style={{height:"240px"}}>
          <div className="spartan mx-auto">No Requests</div>
          </div>
        )}
      </div>
    </div>
  );
}
