import React, { useState } from "react";
import { toast } from "react-toastify";
import Step1 from "./Regsteps/Step1";
import Step2 from "./Regsteps/Step2";
import Step3 from "./Regsteps/Step3";
import Axios from "axios";
import LogoNavbar from "../../../Layout/Navbar/LogoNavbar";
import { useNavigate } from "react-router-dom";
import NotFoundPage from "./../../404Pages";
import { useAuth } from "../../../Utils/Auth";
import industries2 from "../../Lists/Industries2.json";
// import { Link } from "react-router-dom";
// import Checkbox from "@mui/material/Checkbox";

export default function VerticalTabs() {
  const { user, profile, getUser } = useAuth();



  // STEP 1
  const [IncubatorName, setIncubatorName] = useState("");
  const [IncorporationDate, setIncorporationDate] = useState(null);

  // const [industry, setIndustry] = useState("");
  // const [otherIndustry, setOtherIndustry] = useState("");
  const [interestedIndustries, setinterestedIndustries] = useState([]);
  const [options] = useState(industries2);

  const [services, setservices] = useState([]);
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [description, setdescription] = useState("");
  const [companyWebsite, setcompanyWebsite] = useState("");
  const [companyLogo, setCompanyLogoUrl] = useState(null);

  // STEP2
  const [NumberOfEmployees, setNumberOfEmployees] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [incubatorcount, setincubatorcount] = useState("");
  const [programcount, setprogramcount] = useState("");
  const [FundingStatus, setFundingStatus] = useState("");
  const [otherFundingStatus, setOtherFundingStatus] = useState("");
  const [growth, setGrowth] = useState("");
  const [otherGrowth, setOtherGrowth] = useState("");
  const [CurrentEvaluation, setValuation] = useState();
  const [revenue, setrevenue] = useState("");
  const [DocumentsNeed, setCompanyDocuments] = useState([]);

  // STEP 3
  const [jobTitle, setJobTitle] = useState("");
  const [photoId, setPhotoID] = useState("");
  // const [profile, setProfile] = useState(null);
  const [profilePic, setProfilePicUrl] = useState(null);
  const [linkedProfile, setlinkedProfile] = useState(null);
  const [phone, setPhone] = useState(null);


  const navigate = useNavigate();
  // const [terms, setterms] = useState(true);
  // const [IncubatorName, setIncubatorName] = useState("");
  // const [companyWebsite, setcompanyWebsite] = useState("");
  // const [country, setCountry] = useState("");
  // const [state, setstate] = useState("");
  // const [city, setcity] = useState("");
  // const [companyLogo, setCompanyLogoUrl] = useState("");
  // const [interest, setInterest] = useState([]);
  // // const [newsletter, Setnewsletter] = useState([]);
  // const [linkedProfile, setlinkedProfile] = useState(null);
  // // const [phone, setPhone] = useState(null);
  // const [states, setstates] = useState(null);
  // const [cities, setcities] = useState(null);
  // const [description, setdescription] = useState("");

  const [isDisable, setisDisable] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // if (!terms) {
    //   toast.error("Please agree the Terms & Conditions", {
    //     position: "bottom-right",
    //   });
    //   return;
    // }
   
    if (
      IncubatorName.length === 0 ||
      IncorporationDate === null ||
      // industry.length === 0 ||
      // (industry === "Other" && otherIndustry.length === 0) ||
      interestedIndustries.length === 0 ||
      // services.length === 0 ||
      companyWebsite.length === 0 ||
      companyLogo === null ||
      description.length === 0
    )
      return toast.warning("Incomplete Company Details");
    if (country.length === 0) return toast.warning("Enter company address");
    if (states && states.length > 0 && (!state || state.length === 0))
      return toast.warning("Select state ");
    else if (cities && cities.length > 0 && (!city || city.length === 0))
      return toast.warning("Select city ");

    

    if (
      jobTitle.length === 0 ||
      profilePic === null
      // linkedProfile.length === 0 ||
    )
      return toast.warning("Incomplete Personal Details");

    // if (interest.length === 0) return toast.warning("Select industries");
    setisDisable(true);
    await Axios({
      method: "put",
      url: "/api/incubator/edit-incubator",
      data: {
        name: IncubatorName,
        IncorporationDate: IncorporationDate,
        // industry: industry === "Other" ? otherIndustry : industry,
        interestedIndustries,
        services,
        companyWebsite,
        incDescription: description,
        logo: companyLogo,
        country: country,
        city: city,
        state: state,
        NumberOfEmployees,
        programcount,
        targetAudience,
        incubatorcount,
        FundingStatus,
        growth: growth === "others" ? otherGrowth : growth,
        CurrentEvaluation,
        revenue,

        jobTitle,

        profilePic,
        phone,
        linkedProfile,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Profile Successfully updated");
          getUser();
          navigate(`/dashboard/${user.type}?tab=10`);
        }
      })
      .catch((err) => {
        setisDisable(false);
        toast.error(err.response.data.error);
      });
  };

  
  const [initial,setinitial] = useState(true);

  React.useEffect(() => {
    // console.log(profile);
    if (profile) {
      // sethideid(true);
      if(profile.logo)
      {
        setCompanyLogoUrl(profile.logo);
      }
      if (profile.name) {
        setIncubatorName(profile.name);
      }
      if (profile.companyWebsite) {
        setcompanyWebsite(profile.companyWebsite);
      }
      if (profile.incDescription) {
        setdescription(profile.incDescription);
      }     
      if (profile.interestedIndustries) {
        setinterestedIndustries(profile.interestedIndustries);
      }
     if (profile.linkedProfile) {
        setlinkedProfile(profile.linkedProfile);
      }
      if (profile.location.country) {
        setCountry(profile.location.country);
      }
      if (profile.location.state) {
        setstate(profile.location.state);
      }
      if (profile.location.city) {
        setcity(profile.location.city);
      }


      
      if (profile.IncorporationDate) {
        setIncorporationDate(profile.IncorporationDate);
      }
      if (profile.services) {
        setservices(profile.services);
      }
      if (profile.incubatorcount) {
        setincubatorcount(profile.incubatorcount);
      }
      if (profile.programcount) {
        setprogramcount(profile.programcount);
      }
      if (profile.incubatorcount) {
        setincubatorcount(profile.incubatorcount);
      }
      if (profile.programcount) {
        setprogramcount(profile.programcount);
      }
      if (profile.FundingStatus) {
        setFundingStatus(profile.FundingStatus);
      }
      if (profile.CurrentEvaluation) {
        setValuation(profile.CurrentEvaluation);
      }
      if (profile.revenue) {
        setrevenue(profile.revenue);
      }
      if(profile.contact.phone)
      {
        setPhone(profile.contact.phone);
      }


      
      if (profile.profilePic) {
        setProfilePicUrl(profile.profilePic);
      }
      if (profile.jobTitle) {
        setJobTitle(profile.jobTitle);
      }
    }
  }, [profile]);

  if (!user) return <NotFoundPage />;

  return (
    <>
      <div className="regis">
        <LogoNavbar />

        <div className="register_page">
          
           {/* -------------STEP 1------------------ */}
           <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <div className="mb-16">
                    <a
                      href="#part1"
                      className="register_page_sideborder-head w-100"
                    >
                      COMPANY DETAILS
                    </a>
                    <p className="register_page_sideborder-sidehead-active m-0">
                      About your company
                    </p>
                  </div>
                  <div className="mb-16">
                    <a
                      href="#part2"
                      className="register_page_sideborder-sidehead w-100"
                    >
                      DOMAIN DETAILS
                    </a>
                  </div>
                  <div>
                    <a
                      href="#part3"
                      className="register_page_sideborder-sidehead w-100"
                    >
                      PERSONAL DETAILS
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part1">
                  <Step1
                    setIncorporationDate={setIncorporationDate}
                    IncorporationDate={IncorporationDate}

                    // industry={industry}
                    // setIndustry={setIndustry}
                    // otherIndustry={otherIndustry}
                    // setOtherIndustry={setOtherIndustry}
                    interestedIndustries={interestedIndustries}
                    setinterestedIndustries={setinterestedIndustries}
                    options={options}
          
                    initial={initial}
                    setinitial={setinitial}

                    setservices={setservices}
                    services={services}
                    country={country}
                    setcountry={setCountry}
                    state={state}
                    setstate={setstate}
                    city={city}
                    setcity={setcity}
                    states={states}
                    cities={cities}
                    setstates={setstates}
                    setcities={setcities}
                    name={IncubatorName}
                    setName={setIncubatorName}
                    description={description}
                    setdescription={setdescription}
                    companyLogo={companyLogo}
                    setCompanyLogoUrl={setCompanyLogoUrl}
                    companyWebsite={companyWebsite}
                    setcompanyWebsite={setcompanyWebsite}
                    // profile={profile}
                    // setProfile={setProfile}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* -------------STEP 2------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <div className="mb-16">
                    <a
                      href="#part1"
                      className="register_page_sideborder-sidehead-active"
                    >
                      COMPANY DETAILS
                    </a>
                  </div>
                  <div className="mb-16">
                    <a href="#part2" className="register_page_sideborder-head">
                      DOMAIN DETAILS
                    </a>
                    <p className="register_page_sideborder-sidehead m-0">
                      About your company domain
                    </p>
                  </div>
                  <div>
                    <a
                      href="#part3"
                      className="register_page_sideborder-sidehead"
                    >
                      PERSONAL DETAILS
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part2">
                  <Step2
                    NumberOfEmployees={NumberOfEmployees}
                    setNumberOfEmployees={setNumberOfEmployees}
                    targetAudience={targetAudience}
                    setTargetAudience={setTargetAudience}
                    incubatorcount={incubatorcount}
                    setincubatorcount={setincubatorcount}
                    programcount={programcount}
                    setprogramcount={setprogramcount}
                    FundingStatus={FundingStatus}
                    setFundingStatus={setFundingStatus}
                    otherFundingStatus={otherFundingStatus}
                    setOtherFundingStatus={setOtherFundingStatus}
                    growth={growth}
                    setGrowth={setGrowth}
                    otherGrowth={otherGrowth}
                    setOtherGrowth={setOtherGrowth}
                    CurrentEvaluation={CurrentEvaluation}
                    setValuation={setValuation}
                    revenue={revenue}
                    setrevenue={setrevenue}
                    DocumentsNeed={DocumentsNeed}
                    setCompanyDocuments={setCompanyDocuments}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* -------------STEP 3------------------ */}

          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <div className="mb-16">
                    <a
                      href="#part1"
                      className="register_page_sideborder-sidehead-active"
                    >
                      COMPANY DETAILS
                    </a>
                  </div>
                  <div className="mb-16">
                    <a
                      href="#part2"
                      className="register_page_sideborder-sidehead"
                    >
                      DOMAIN DETAILS
                    </a>
                  </div>
                  <div>
                    <a href="#part3" className="register_page_sideborder-head">
                      PERSONAL DETAILS
                    </a>
                    <p className="register_page_sideborder-sidehead m-0">
                      About yourself
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part3">
                  <Step3
                    jobTitle={jobTitle}
                    setJobTitle={setJobTitle}
                    linkedProfile={linkedProfile}
                    setlinkedProfile={setlinkedProfile}
                    photoId={photoId}
                    setPhotoID={setPhotoID}
                    phone={phone}
                    setPhone={setPhone}
                    profilePic={profilePic}
                    setProfilePicUrl={setProfilePicUrl}
                    // profile={profile}
                    // setProfile={setProfile}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* -------------REGISTER------------------ */}
        <div className="container mb-40">
          <div className="row">
            <div className="col-12 col-md-4">
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex mb-14" style={{ marginTop: "163px" }}>
                <div className="">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM9 5V7H11V5H9Z" fill="#989898"/>
                  </svg>
                </div>
                <div className="">
                  <p style={{marginLeft:"6px"}}>
                  All the documents submitted here will be treated as strictly confidential and will not be used/disclosed anywhere
                  </p>
                </div>

              </div>
                <div
                  className="w-100"
                >
                  {/* <label
                    htmlFor="terms_condition"
                    className="text-dark text_align_start"
                  >
                    <Checkbox
                      defaultChecked
                      onClick={() => setterms(!terms)}
                      sx={{
                        color: `#262C5B`,
                        "&.Mui-checked": {
                          color: `#262C5B`,
                        },
                      }}
                      className="mr-8"
                    />
                    By signing up, you agree to Zefyron Incubation&apos;s{" "}
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      rel="nonreferrer"
                      className="text-dark"
                    >
                      Terms & Conditions{" "}
                    </Link>
                  </label> */}
                  <div className="w-100">
                    <button
                      className="gradient_CTA"
                      style={{ width: "50%" }}
                      disabled={isDisable}
                      onClick={(e) => handleOnSubmit(e)}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>

  );
}
