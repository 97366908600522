import React, { useState } from "react";
import { PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer } from "recharts";
const COLORS = ["#FF6F1E", "#121843", "#580167", "#98B3D6", "#FBCF33"];
import './barchar.css'

const index = ({ data, totalcount }) => {
  const [activeIndex, setactiveIndex] = useState(0);
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    // const sx = cx + (outerRadius + 10) * cos;
    // const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    // console.log(sx,sy,ex,ey,textAnchor)
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} className="">
          {totalcount}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 4}
          outerRadius={outerRadius + 8}
          fill={fill}
        />
        {/* <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        /> */}
        {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 4}
          y={ey}         
          textAnchor={textAnchor}
          fill="#000"
          fontSize={12}
        >
          {payload.name}{" "}
        </text> */}
        <text
          x={ex + (cos >= 0 ? 1 : -1)}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          fontSize={10}
        >
          {payload.value}
        </text> 
      </g>
    );
  };
  const onPieEnter = (_, index) => {
    setactiveIndex(index);
  };
  return (
    <ResponsiveContainer width={`100%`} height={300}>
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={50}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        >
          {" "}
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend className="incubator_analytic_donut_legend" />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default index;
