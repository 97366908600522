import React, { useEffect, useState } from 'react';
import tAsset from "../../../../../Assets/images/financial/tAsset.png"
import tEstate from "../../../../../Assets/images/financial/tEstate.png"
import tEvents from "../../../../../Assets/images/financial/tEvents.png"
import tTools from "../../../../../Assets/images/financial/tTools.png"
import HeadSquare from './HeadSquare';
// import ReportCard from "./ReportCard";
import Axios from 'axios';
import { useAuth } from '../../../../../Utils/Auth';

const MonthlyReport = () => {
    // const [allReports, setAllReports] = useState([1, 2, 3, 4])
    const [totalAsset, setTotalAsset] = useState(0)
    const [totalRealEstate, setTotalRealEstate] = useState(0)
    const [totalEvents, setTotalEvents] = useState(0)
    const { setSessionExpire } = useAuth()

    const getTotal = async () => {
        await Axios({
            url: `/api/inc-finance/count/cowork`,
            method: "get"
        }).then((res) => {
            setTotalAsset(res.data.Assets)
            setTotalRealEstate(res.data.Real_Estate)
            setTotalEvents(res.data.Events)
        }).catch(() => {
            setSessionExpire(true)
        })
    }

    useEffect(() => {
        getTotal()
    }, [])
    return (
        <div className='monthlyReport_root'>
            <div className="coworking_incubator_analytics_head">
                <div className="row m-0 mb-40">
                    <HeadSquare
                        name={"Total Assets"}
                        value={totalAsset}
                        img={tAsset}
                    />
                    <HeadSquare
                        name={"Total Real Estate"}
                        value={totalRealEstate}
                        img={tEstate}
                    />
                    <HeadSquare
                        name={"Total Events"}
                        value={totalEvents}
                        img={tEvents}
                    />
                    <HeadSquare
                        name={"Total Tools"}
                        value={0}
                        img={tTools}
                    />

                </div>
            </div>
            {/* <div className="monthlyReport_main">
                <table className="incubator_assessment_table">
                    <thead className="incubator_assessment_thead">
                        <tr className="incubator_assessment_thead_tr">
                            <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                                <p className="body2 color_dark_grey2">Report Type</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                <p className="body2 color_dark_grey2">Description</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                                <p className="body2 color_dark_grey2">Start Date</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                                <p className="body2 color_dark_grey2">End Date</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_score">
                                <p className="body2 color_dark_grey2">Amount</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_score d-flex justify-content-center">
                                <p className="body2 color_dark_grey2">Download</p>
                            </td>

                        </tr>
                    </thead>
                    <tbody className="incubator_assessment_tbody">
                        {
                            allReports && allReports.length > 0 ?
                                allReports.map((data, i) => <ReportCard
                                    key={i}
                                    data={data}
                                    setAllReports={setAllReports}
                                />) : null
                        }
                    </tbody>
                </table>
            </div> */}
        </div>
    )
}

export default MonthlyReport