import React, { useEffect, useState } from "react";
import Step1 from "./Regsteps/Step1";
import Step2 from "./Regsteps/Step2";
import Step3 from "./Regsteps/Step3";
import Step4 from "./Regsteps/Step4";

// import Location from "../../../Utils/Location";
import industries from "../../Lists/Industries2.json";
// import Multiselect from "multiselect-react-dropdown";
import { useAuth } from "../../../Utils/Auth";
// import ClearIcon from "@mui/icons-material/Clear";
import Navbar from "../../../Layout/Navbar/Navbar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import NotFoundPage from "../../404Pages";
import axios from "axios";
// import { Link } from "react-router-dom";
// import Checkbox from "@mui/material/Checkbox";
import "./index.css";

const Coporate_Register = () => {
  const { user, profile, getUser } = useAuth();

  // STEP 1
  const [companyLogo, setCompanyLogoUrl] = useState(null);
  const [legalname, setlegalname] = useState("");
  const [nameOfCorporateType, setnameOfCorporateType] = useState("");
  const [typeOfCorporate, settypeOfCorporate] = useState("");
  const [bio, setbio] = useState("");
  const [companyWebsite, setcompanyWebsite] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [IncorporationDate, setIncorporationDate] = useState(null);
  const [industry, setindustry] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [services, setservices] = useState([]);
  const [description, setdescription] = useState("");

  // STEP2
  const [NumberOfEmployees, setNumberOfEmployees] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [FundingStatus, setFundingStatus] = useState("");
  const [otherFundingStatus, setOtherFundingStatus] = useState("");
  const [growth, setGrowth] = useState("");
  const [otherGrowth, setOtherGrowth] = useState("");
  const [CurrentEvaluation, setValuation] = useState(0);
  const [revenue, setrevenue] = useState("");

  // STEP 3
  const [options] = useState(industries);
  const [investedstage, setinvestedstage] = useState("");
  const [investedmodel, setinvestedmodel] = useState("");
  const [investedIndustry, setinvestedIndustry] = useState([]);
  const [totalinvestment, settotalinvestment] = useState("");
  const [loadinvestment, setloadinvestment] = useState("");
  const [acquisitions, setacquisitions] = useState("");
  const [exits, setexits] = useState("");
  const [Fundingrounds, setFundingrounds] = useState("");
  const [totalFunding, settotalFunding] = useState("");

  // STEP 4
  const [interestedstage, setinterestedstage] = useState("");
  const [interestedmodel, setinterestedmodel] = useState("");
  const [interestedIndustry, setinterestedIndustry] = useState([]);
  const [chequsize, setchequsize] = useState("");

  // console.log(profile)
  // console.log(user)
  
  const [initial,setinitial] = useState(true);
  const [initial2,setinitial2] = useState(true);

  useEffect(() => {
    if (profile) {
      if (profile.logo) {
        setCompanyLogoUrl(profile.logo)
      }
      if (profile.investedIndustry) {
        setinvestedIndustry(profile.investedIndustry)
      }
      if (profile.interestedIndustry) {
        setinterestedIndustry(profile.interestedIndustry)
      }
      if (profile.legalname) {
        setlegalname(profile.legalname)
      }
      if (profile.nameOfCorporateType) {
        setnameOfCorporateType(profile.nameOfCorporateType)
      }
      if (profile.typeOfCorporate) {
        settypeOfCorporate(profile.typeOfCorporate)
      }
      if (profile.bio) {
        setbio(profile.bio)
      }
      if (profile.companyWebsite) {
        setcompanyWebsite(profile.companyWebsite)
      }
      if (profile.linkedin) {
        setlinkedin(profile.linkedin)
      }
      if (profile.IncorporationDate) {
        setIncorporationDate(profile.IncorporationDate)
      }
      if (profile.industry) {
        setindustry(profile.industry)
      }
      if (profile.description) {
        setdescription(profile.description)
      }
      if (profile.location) {
        if (profile.location.country) {
          setCountry(profile.location.country)
        }
        if (profile.location.state) {
          setstate(profile.location.state)
        }
        if (profile.location.city) {
          setcity(profile.location.city)
        }
      }
      if (profile.services && profile.services.length > 0) {
        setservices(profile.services)
      }
      if (profile.NumberOfEmployees) {
        setNumberOfEmployees(profile.NumberOfEmployees)
      }
      if (profile.targetAudience) {
        setTargetAudience(profile.targetAudience)
      }
      if (profile.FundingStatus) {
        setFundingStatus(profile.FundingStatus)
      }
      if (profile.growth) {
        setGrowth(profile.growth)
      }
      if (profile.CurrentEvaluation) {
        setValuation(profile.CurrentEvaluation)
      }
      if (profile.revenue) {
        setrevenue(profile.revenue)
      }
      if (profile.interestedIndustry) {
        setinterestedIndustry(profile.interestedIndustry)
      }
      if (profile.investedmodel) {
        setinvestedmodel(profile.investedmodel)
      }
      if (profile.investedstage) {
        setinvestedstage(profile.investedstage)
      }
      if (profile.totalinvestment) {
        settotalinvestment(profile.totalinvestment)
      }
      if (profile.loadinvestment) {
        setloadinvestment(profile.loadinvestment)
      }
      if (profile.acquisitions) {
        setacquisitions(profile.acquisitions)
      }
      if (profile.exits) {
        setexits(profile.exits)
      }
      if (profile.Fundingrounds) {
        setFundingrounds(profile.Fundingrounds)
      }
      if (profile.totalFunding) {
        settotalFunding(profile.totalFunding)
      }
      if (profile.interestedmodel) {
        setinterestedmodel(profile.interestedmodel)
      }
      if (profile.interestedstage) {
        setinterestedstage(profile.interestedstage)
      }
      if (profile.chequsize) {
        setchequsize(profile.chequsize)
      }
    }
  }, [profile])

  const navigate = useNavigate();
  // const [profilePic, setProfilePic] = useState(null);

  // REGISTRATION
  // const [terms, setterms] = useState(true);
  const [isDisable, setisDisable] = useState(false);

  const handleEdit = async () => {
    try {
      // validation
      // if (!terms) {
      //   toast.error("Please agree the Terms & Conditions", {
      //     position: "bottom-right",
      //   });
      //   return;
      // }
      
      if (
        legalname.length === 0 ||
        typeOfCorporate.length === 0 ||
        nameOfCorporateType.length === 0 ||
        linkedin.length === 0 ||
        industry.length === 0 ||
        (industry === "Other" && otherIndustry.length === 0) ||
        bio.length === 0 ||
        companyWebsite.length === 0 ||
        IncorporationDate === null ||
        industry.length === 0 ||
        // services.length === 0 ||
        description.length === 0 ||
        companyLogo === null
      ) {
        return toast.warning("Some company details are missing");
      }


      if (
        NumberOfEmployees.length === 0 ||
        targetAudience.length === 0 ||
        CurrentEvaluation.length === 0 ||
        revenue.length === 0 ||
        FundingStatus.length === 0 ||
        (FundingStatus === "others" && otherFundingStatus.length === 0) ||
        growth.length === 0 ||
        (growth === "others" && otherGrowth.length === 0)
      ) {
        return toast.warning("Some domain details are missing");
      }
      if (
        investedIndustry.length === 0 ||
        investedstage.length === 0 ||
        investedmodel.length === 0 ||
        totalinvestment.length === 0 ||
        loadinvestment.length === 0 ||
        acquisitions.length === 0 ||
        exits.length === 0 ||
        Fundingrounds.length === 0 ||
        totalFunding.length === 0
      ) {
        return toast.warning("Some invested details are missing");
      }
      if (
        interestedIndustry.length === 0 ||
        interestedmodel.length === 0 ||
        interestedstage.length === 0 ||
        chequsize.length === 0
      ) {
        return toast.warning("Some interested details are missing");
      }




      // if (profilePic === null) return toast.warning("Upload Corporate logo");
      // if (document === null) return toast.warning("Upload document");

      if (country.length === 0) return toast.warning("Enter company address");
      if (states && states.length > 0 && (!state || state.length === 0))
        return toast.warning("Select state ");
      else if (cities && cities.length > 0 && (!city || city.length === 0))
        return toast.warning("Select city ");
      setisDisable(true);

      const response = await axios.put(`/api/corporate/edit-corporate/${profile._id}`, {
        legalname,
        typeOfCorporate,
        nameOfCorporateType,
        linkedin,
        bio,
        companyWebsite,
        IncorporationDate,
        industry: industry === "Other" ? otherIndustry : industry,
        services,
        description,
        logo: companyLogo,
        location: {
          country,
          state,
          city,
        },
        NumberOfEmployees,
        targetAudience,
        CurrentEvaluation,
        revenue,
        FundingStatus: FundingStatus === "Other" ? otherFundingStatus : FundingStatus,
        growth: growth === "Other" ? otherGrowth : growth,
        investedIndustry,
        investedstage,
        investedmodel,
        totalinvestment,
        loadinvestment,
        acquisitions,
        exits,
        Fundingrounds,
        totalFunding,
        interestedIndustry,
        interestedmodel,
        interestedstage,
        chequsize,
        phone: profile.phone
      });

      if (response.status === 201) {
        setisDisable(false);
        toast.success("Corporate Edited");
        getUser();
        navigate("/dashboard/corporate?tab=9");
      } else if (response.status === 200) {
        setisDisable(false);
        return toast.error("Corporate already registered");
      }
    } catch (error) {
      setisDisable(false);
      toast.error(error.response.data.error);
    }
  };

  if (user)
    return (
      <>
        <div className="regis">
          <Navbar />
          <div className="register_page">

            {/* -------------STEP 1------------------ */}

            <div className="container pt-5">
              <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
                <div className="col-12 col-md-4">
                  <div className="register_page_sideborder">
                    <div className="mb-16">
                      <a href="#part1" className="register_page_sideborder-head w-100">
                        COMPANY DETAILS
                      </a>
                      <p className="register_page_sideborder-sidehead-active m-0">
                        About your company
                      </p>
                    </div>
                    <div className="mb-16">
                      <a href="#part2" className="register_page_sideborder-sidehead w-100">
                        DOMAIN DETAILS
                      </a>
                    </div>
                    <div className="mb-16">
                      <a href="#part3" className="register_page_sideborder-sidehead w-100">
                        INVESTMENTS
                      </a>
                    </div>
                    <div>
                      <a href="#part4 " className="register_page_sideborder-sidehead w-100">
                        INTERESTS
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-md-1"></div> */}
                <div className="col-12 col-md-8">
                  <div className="register_page_form" id="part1">
                    <Step1
                      nameOfCorporateType={nameOfCorporateType}
                      setnameOfCorporateType={setnameOfCorporateType}
                      typeOfCorporate={typeOfCorporate}
                      settypeOfCorporate={settypeOfCorporate}
                      bio={bio}
                      setbio={setbio}
                      companyWebsite={companyWebsite}
                      setcompanyWebsite={setcompanyWebsite}
                      linkedin={linkedin}
                      setlinkedin={setlinkedin}
                      setIncorporationDate={setIncorporationDate}
                      IncorporationDate={IncorporationDate}
                      industry={industry}
                      setIndustry={setindustry}
                      otherIndustry={otherIndustry}
                      setOtherIndustry={setOtherIndustry}
                      setservices={setservices}
                      services={services}
                      country={country}
                      setcountry={setCountry}
                      state={state}
                      setstate={setstate}
                      city={city}
                      setcity={setcity}
                      states={states}
                      cities={cities}
                      setstates={setstates}
                      setcities={setcities}
                      legalname={legalname}
                      setlegalname={setlegalname}
                      description={description}
                      setdescription={setdescription}
                      companyLogo={companyLogo}
                      setCompanyLogoUrl={setCompanyLogoUrl}
                      profile={profile}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* -------------STEP 2------------------ */}

            <div className="container pt-5">
              <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
                <div className="col-12 col-md-4">
                  <div className="register_page_sideborder">

                    <div className="mb-16">
                      <a href="#part1" className="register_page_sideborder-sidehead-active">
                        COMPANY DETAILS
                      </a>
                    </div>
                    <div className="mb-16">
                      <a href="#part2" className="register_page_sideborder-head">
                        DOMAIN DETAILS
                      </a>
                      <p className="register_page_sideborder-sidehead m-0">
                        About your company domain
                      </p>
                    </div>
                    <div className="mb-16">
                      <a href="#part3" className="register_page_sideborder-sidehead w-100">
                        INVESTMENTS
                      </a>
                    </div>
                    <div>
                      <a href="#part4 " className="register_page_sideborder-sidehead w-100">
                        INTERESTS
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-md-1"></div> */}
                <div className="col-12 col-md-8">
                  <div className="register_page_form" id="part2">
                    <Step2
                      NumberOfEmployees={NumberOfEmployees}
                      setNumberOfEmployees={setNumberOfEmployees}
                      targetAudience={targetAudience}
                      setTargetAudience={setTargetAudience}
                      FundingStatus={FundingStatus}
                      setFundingStatus={setFundingStatus}
                      otherFundingStatus={otherFundingStatus}
                      setOtherFundingStatus={setOtherFundingStatus}
                      growth={growth}
                      setGrowth={setGrowth}
                      otherGrowth={otherGrowth}
                      setOtherGrowth={setOtherGrowth}
                      CurrentEvaluation={CurrentEvaluation}
                      setValuation={setValuation}
                      revenue={revenue}
                      setrevenue={setrevenue}

                    />
                  </div>
                </div>
              </div>
            </div>

            {/* -------------STEP 3------------------ */}

            <div className="container pt-5">
              <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
                <div className="col-12 col-md-4">
                  <div className="register_page_sideborder">
                    <div className="mb-16">
                      <a href="#part1" className="register_page_sideborder-sidehead-active">
                        COMPANY DETAILS
                      </a>
                    </div>
                    <div className="mb-16">
                      <a href="#part2" className="register_page_sideborder-sidehead">
                        DOMAIN DETAILS
                      </a>
                    </div>
                    <div className="mb-16">
                      <a href="#part3" className="register_page_sideborder-head w-100">
                        INVESTMENTS
                      </a>
                      <p className="register_page_sideborder-sidehead m-0">
                        About your investment history
                      </p>
                    </div>
                    <div>
                      <a href="#part4 " className="register_page_sideborder-sidehead w-100">
                        INTERESTS
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-md-1"></div> */}
                <div className="col-12 col-md-8">
                  <div className="register_page_form" id="part3">
                    <Step3
                      options={options}
                      investedIndustry={investedIndustry}
                      setinvestedIndustry={setinvestedIndustry}
                      investedstage={investedstage}
                      setinvestedstage={setinvestedstage}
                      investedmodel={investedmodel}
                      setinvestedmodel={setinvestedmodel}
                      totalinvestment={totalinvestment}
                      settotalinvestment={settotalinvestment}
                      loadinvestment={loadinvestment}
                      setloadinvestment={setloadinvestment}
                      acquisitions={acquisitions}
                      setacquisitions={setacquisitions}
                      exits={exits}
                      setexits={setexits}
                      Fundingrounds={Fundingrounds}
                      setFundingrounds={setFundingrounds}
                      totalFunding={totalFunding}
                      settotalFunding={settotalFunding}
                      initial={initial}
                      setinitial={setinitial}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* -------------STEP 4------------------ */}

            <div className="container pt-5">
              <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
                <div className="col-12 col-md-4">
                  <div className="register_page_sideborder">
                    <div className="mb-16">
                      <a href="#part1" className="register_page_sideborder-sidehead-active">
                        COMPANY DETAILS
                      </a>
                    </div>
                    <div className="mb-16">
                      <a href="#part2" className="register_page_sideborder-sidehead">
                        DOMAIN DETAILS
                      </a>
                    </div>
                    <div className="mb-16">
                      <a href="#part3" className="register_page_sideborder-sidehead w-100">
                        INVESTMENTS
                      </a>
                    </div>
                    <div>
                      <a href="#part4 " className="register_page_sideborder-head w-100">
                        INTERESTS
                      </a>
                      <p className="register_page_sideborder-sidehead m-0">
                        About your future investment interests
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-md-1"></div> */}
                <div className="col-12 col-md-8">
                  <div className="register_page_form" id="part4">
                    <Step4
                      options={options}
                      interestedIndustry={interestedIndustry}
                      setinterestedIndustry={setinterestedIndustry}
                      interestedmodel={interestedmodel}
                      setinterestedmodel={setinterestedmodel}
                      interestedstage={interestedstage}
                      setinterestedstage={setinterestedstage}
                      chequsize={chequsize}
                      setchequsize={setchequsize}
                      initial2={initial2}
                      setinitial2={setinitial2}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* -------------REGISTER------------------ */}
            <div className="container mb-40">
              <div className="row">
                <div className="col-12 col-md-4">
                </div>
                <div className="col-12 col-md-8">
                  <div
                    className="w-100"
                    style={{ marginTop: "163px" }}
                  >
                      <button
                        className="gradient_CTA"
                        style={{ width: "50%" }}
                        disabled={isDisable}
                        onClick={() => handleEdit()}
                      >
                        UPDATE
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </>
    );
  else return <NotFoundPage />;
};

export default Coporate_Register;
