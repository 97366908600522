import React from "react";
import { Link } from "react-router-dom";
// import misson from "../../../Assets/images/service-events/misson.png";
// import { AccountBalanceWallet, WatchLater, BusinessCenter } from "@mui/icons-material";

// import  from '@mui/material/CircularProgress';
// import moment from "moment-timezone";
import { useAuth } from "./../../../Utils/Auth";
import { 
  Avatar, AvatarGroup, 
  CircularProgress } from "@mui/material"


const Item = (props) => {
  const {
    title,
    // description,
    eventFees,
    date,
    time,
    thumbnail,
    // timezone,
    // name,
    isFree,
    keywords,
    evenyKey,
    participants
  } = props;

  const { user } = useAuth();



  const get_Date = (date) => {
    let d = new Date(date);
    return d.toLocaleDateString("en-US", {
      weekday: "short",
      month: "long",
      day: "numeric",
    });
  };
  const get_time = (date, time) => {
    let datetime = date + "T" + time;
    return new Date(datetime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // console.log(participants)

  if (!user)
    return <CircularProgress />;

  return (
    <>
     

      <div className="col-lg-3 col-md-6 col-12 mb-24"> 
      <Link
          to={`/dashboard/${user.type}?tab=6&event=${evenyKey}`}
        >
        {/* <div className="event_card_head_root">
          <div className="event_card_head_fDetails" style={{ backgroundImage: `url('${thumbnail}')` }}>
            <div className="event_card_head__fDetail" >
              {keywords
                ? keywords.map((key, i) => <p key={i}>{key}</p>)
                : null}
            </div>
            <div className="event_card_Card_mainInfo">
              <p className="p_black14">{title}</p>
              <div className="event_card_sdetails">
                <div className="event_card_dSetail">
                  <span>
                    <AccountBalanceWallet />
                  </span>
                  <span>
                    {get_Date(date)}
                  </span>
                </div>
                <div className="event_card_dSetail">
                  <span>
                    <WatchLater />
                  </span>
                  <span>
                    {get_time(date, time)}
                  </span>
                </div>
                <div className="event_card_dSetail">
                  <span>
                    <BusinessCenter />
                  </span>
                  <span>
                    {isFree ? "Free" : "$ " + eventFees}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="event__card_participatend_root pl-3">
           
          </div>
        </div> */}
        <div className="incubation_program_card_root mt-0 h-100">
        <div
          className="incubation_program_card_head"
          style={{ backgroundImage: `url('${thumbnail}')` }}
        >
          {/* <p className="p_normalBlack10">{program.type}</p> */}
          <div className="event_card_head__fDetail" >
              {keywords && keywords.length>0
                // ? keywords.slice(0,1).map((key, i) => <p className="mt-1" key={i}>{key}</p>)
                ? 
                <>
                <p className="mt-1">{keywords[0]}</p>
                  {keywords.length>1 ? <p className="mt-1">+ {keywords.length-1}</p> : null}
                </>
                : null}
            </div>
        </div>
        <div className="incubation_program_card_main pl-3 pr-3 d-flex flex-column justify-content-between" style={{minHeight: "215px"}}>
          <div>
          <div className="incubation_program_card_title mt-2">
            <h5 className="p_black20">{title}</h5>
          </div>
          <div className="incubation_program_card_info row d-flex justify-content-between mt-3 pb-2">
            <div className="col-7 incubation_program_card_small d-flex align-items-center">
              <span>
              <svg width="18" height="18" style={{width:"18px",height:"18px",marginRight:"4px"}} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.332031 11.5253C0.332031 12.6445 1.1987 13.5004 2.33203 13.5004H11.6654C12.7987 13.5004 13.6654 12.6445 13.6654 11.5253V6.25845H0.332031V11.5253ZM11.6654 1.64996H10.332V0.991608C10.332 0.596594 10.0654 0.333252 9.66537 0.333252C9.26537 0.333252 8.9987 0.596594 8.9987 0.991608V1.64996H4.9987V0.991608C4.9987 0.596594 4.73203 0.333252 4.33203 0.333252C3.93203 0.333252 3.66536 0.596594 3.66536 0.991608V1.64996H2.33203C1.1987 1.64996 0.332031 2.50583 0.332031 3.62503V4.94174H13.6654V3.62503C13.6654 2.50583 12.7987 1.64996 11.6654 1.64996Z" fill="#262C5B"/>
              </svg>
              </span>
              <span className="overline" style={{color: "#262C5B"}}> {get_Date(date)} </span>
            </div>
            <div className="col-5 incubation_program_card_small d-flex align-items-center p-0">
              <span>
              <svg width="14" height="14" style={{width:"16px",height:"16px",marginRight:"4px"}} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.14828 0.333252C3.46828 0.333252 0.488281 3.31992 0.488281 6.99992C0.488281 10.6799 3.46828 13.6666 7.14828 13.6666C10.8349 13.6666 13.8216 10.6799 13.8216 6.99992C13.8216 3.31992 10.8349 0.333252 7.14828 0.333252ZM9.82162 9.66659C9.75994 9.72839 9.68668 9.77742 9.60603 9.81087C9.52538 9.84433 9.43893 9.86155 9.35161 9.86155C9.2643 9.86155 9.17785 9.84433 9.0972 9.81087C9.01655 9.77742 8.94329 9.72839 8.88161 9.66659L6.68828 7.47325C6.62529 7.41151 6.57518 7.33788 6.54084 7.25663C6.50651 7.17538 6.48865 7.08812 6.48828 6.99992V4.33325C6.48828 3.96659 6.78828 3.66659 7.15495 3.66659C7.52162 3.66659 7.82161 3.96659 7.82161 4.33325V6.72659L9.82162 8.72659C10.0816 8.98659 10.0816 9.40659 9.82162 9.66659Z" fill="#262C5B"/>
              </svg>
              </span>
              <span className="overline" style={{color: "#262C5B"}}> {get_time(date, time)} </span>
            </div>
            <div className="col-7 incubation_program_card_small d-flex align-items-center ">
              <span>
              <svg width="16" height="12" style={{width:"16px",height:"16px",marginRight:"4px"}} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.667969 2.62243C0.667969 2.10378 0.874 1.60638 1.24074 1.23964C1.60748 0.872901 2.10488 0.66687 2.62352 0.66687H13.3791C13.8977 0.66687 14.3951 0.872901 14.7619 1.23964C15.1286 1.60638 15.3346 2.10378 15.3346 2.62243V4.08909H0.667969V2.62243Z" fill="#262C5B"/>
              <path d="M0.667969 5.06689H15.3346V9.46689C15.3346 9.98554 15.1286 10.4829 14.7619 10.8497C14.3951 11.2164 13.8977 11.4225 13.3791 11.4225H2.62352C2.10488 11.4225 1.60748 11.2164 1.24074 10.8497C0.874 10.4829 0.667969 9.98554 0.667969 9.46689V5.06689ZM11.4235 8.97801C11.2939 8.97801 11.1695 9.02951 11.0778 9.1212C10.9861 9.21288 10.9346 9.33723 10.9346 9.46689C10.9346 9.59656 10.9861 9.72091 11.0778 9.81259C11.1695 9.90428 11.2939 9.95578 11.4235 9.95578H13.3791C13.5087 9.95578 13.6331 9.90428 13.7248 9.81259C13.8165 9.72091 13.868 9.59656 13.868 9.46689C13.868 9.33723 13.8165 9.21288 13.7248 9.1212C13.6331 9.02951 13.5087 8.97801 13.3791 8.97801H11.4235Z" fill="#262C5B"/>
              </svg>

              </span>
              <span className="overline" style={{color: "#262C5B"}}> {isFree ? "Free" : "$ " + eventFees} </span>
            </div>
          </div>
          </div>
          <div>
          <div className="overline mt-10 mb-10" style={{color:"#580167"}}>Participants</div>
          <div className="pb-16 d-flex justify-content-between align-items-center">
                <div className="w-fit">

                <AvatarGroup total={participants && participants.length>0 ? participants.length : 0}>
                  {participants && participants.length>0 ? participants.map((data,i)=><Avatar key={i} src={data.avatar} alt={data.name} />) : <Avatar alt="" src="" /> }
                </AvatarGroup>
                </div>
                <div className="">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.20703 8.99996H12.377L7.49703 13.88C7.10703 14.27 7.10703 14.91 7.49703 15.3C7.88703 15.69 8.51703 15.69 8.90703 15.3L15.497 8.70996C15.887 8.31996 15.887 7.68996 15.497 7.29996L8.91703 0.699956C8.7302 0.512704 8.47655 0.407471 8.21203 0.407471C7.94751 0.407471 7.69386 0.512704 7.50703 0.699956C7.11703 1.08996 7.11703 1.71996 7.50703 2.10996L12.377 6.99996H1.20703C0.657031 6.99996 0.207031 7.44996 0.207031 7.99996C0.207031 8.54996 0.657031 8.99996 1.20703 8.99996Z" fill="#6D7D8B"/>
                  </svg>
                </div>
          </div>
          </div>
        </div>
      </div>
        </Link>
      </div>
    </>
  );
};

export default Item;
