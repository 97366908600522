import React from "react";
import search from "../../../../Assets/images/icons/search.png";
// import DeclinedCard from "./Decline/DeclinedCard";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Accept from "./Applications/Accept";
import Decline from "./Decline/Decline";
import ActivePrograms from "./MyActivePrograms";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyApplicatins = (props) => {
  const [value, setValue] = React.useState(0);
  const { setIncubationState } = props;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="incubation_program_root">
      <div className="incubation_prorgram_search_head">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-10">
            <div className="incubation_programm_searchBoxDiv">
              <input type="text" name="" id="" />
              <button className="p_Bold_secBlue14">
                <img src={search} alt="search" />
                Search
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-2">
            <div className="incubation_program_filterDiv">
              {/* filter...... */}
            </div>
          </div>
        </div>
      </div>
      <div className="incubation_profile_program_main">
        <div className="incubation_profile_program_TabsMain">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="incubation_profile_program_Tabs"
          >
            <Tab
              label="ACTIVE"
              {...a11yProps(0)}
              className="incubation_profile_program_Tab"
            />
            <span className="incubation_profile_program_span"></span>
            <Tab
              label="APPLICATIONS"
              {...a11yProps(2)}
              className="incubation_profile_program_Tab"
            />
            <span className="incubation_profile_program_span"></span>
            <Tab
              label="DECLINED"
              {...a11yProps(4)}
              className="incubation_profile_program_Tab"
            />
          </Tabs>
        </div>
        <div className="incubation_profile_program_TabPanelMain">
          <TabPanel
            value={value}
            index={0}
            className="incubation_profile_program_TabPanel"
          >
            <ActivePrograms setIncubationState={setIncubationState} />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            className="incubation_profile_program_TabPanel"
          >
            <Accept setIncubationState={setIncubationState} />
          </TabPanel>
          <TabPanel
            value={value}
            index={4}
            className="incubation_profile_program_TabPanel"
          >
            <Decline setIncubationState={setIncubationState} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default MyApplicatins;
