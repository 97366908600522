import React, { useState, useEffect } from "react";
import Axios from "axios";
import AssesmentForm from "./assessmentForm";
import { toast } from "react-toastify";
import { useLocation, } from "react-router-dom";
// import { useAuth } from "../../../../../../Utils/Auth";

const AddAssignment = ({ setAddSessionState }) => {

  // const navigate = useNavigate()
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  // const {user} = useAuth()
  // const {programid} = useParams()
  const [name, setName] = useState("");
  const [assessment, setassessment] = useState(null);
  // const [question, setQuestion] = useState("")
  const module_id = query.get('module')
  const course_id = query.get('course')

  const getAssessment = async () => {
    await Axios({
      url: `/api/mentorModules/assessment/module/${module_id}`,
      method: 'get'
    }).then(res => {
      if (res.data.data)
        setassessment(res.data.data)
    }).catch(() => {
      toast.error("error in fetching.")
    })
  }
  useEffect(() => {
    if (module_id)
      getAssessment()
  }, [module_id])

  const createAssesment = async (e) => {
    e.preventDefault();
    if (assessment) {
      await Axios({
        url: `/api/mentorModules/assessment/update/${module_id}`,
        method: "post",
        data: {
          name: name,
        },
      })
        .then((res) => {
          // setAddSessionState(3)
          setassessment(res.data.data);
          toast.success("Assessment Updated")
        })
        .catch(() => {
          toast.error('Try again')
        }
        );
    }
    else {
      await Axios({
        url: `/api/mentorModules/assessment/create/${course_id}/${module_id}`,
        method: "post",
        data: {
          name: name,
        },
      })
        .then((res) => {
          // setAddSessionState(3)
          setassessment(res.data.data);
        })
        .catch((err) => {
          toast.error('Try again')
          console.log(err)
        }
        );
    }

  };


  React.useEffect(() => {
    if (module_id)
      getAssessment()
  }, [module_id])

  React.useEffect(() => {
    if (assessment && assessment.name) {
      setName(assessment.name)
    }
  }, [assessment])

  React.useEffect(() => {
    scrollTo(0, 0)
  }, [])

  return (
    <div className="mt-3 w_100">
      <h4>Assessment Details </h4>
      <div className="create_assignment_form_main">
        <div className="create_course_form_maindiv">
          <label htmlFor="Module 1" className="caption color_dark_blue">
            Name of assessment
          </label>
          <input
            type="text"
            className="body1"
            placeholder="Enter the name of assesment"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="create_course_form_maindivBtn">
        <button
          className="primaryFilled_activeCta"
          onClick={createAssesment}
        >
          {!assessment ? "Save and Next" : "Update"}
        </button>
      </div>

      {assessment ? (
        <div className="my-5">
          <AssesmentForm
            assessment={assessment}
            getAssessment={getAssessment}
            setAddSessionState={setAddSessionState}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddAssignment;
