import React from 'react'
// import download from "../../../../../Assets/images/financial/download.png";
import { useNavigate } from 'react-router-dom';

const Card = ({ data, setformState }) => {
    const navigate = useNavigate();

    return (
        <div className="col-lg-3 colmd-4 col-12 mb-24">
            <div className="invoicing_allPay_card_root">
                <div className="invoicing_allPay_card_body">
                    <div className="invoicing_allPay_card_head d-flex align-items-center">
                        <img src={data.for_user.avatar} alt="zefyron finance" className='mr-16' style={{ width: 44, height: 44, borderRadius: "50%" }} />
                        <p className='subtitle'>{data.for_user.name.length > 10 ? `${data.for_user.name.slice(0, 10)}...` : data.for_user.name}</p>
                    </div>
                    <div className="invoicing_allPay_card_desc mt-24">
                        <p className='overLine'>{data.short_description.length > 90 ? `${data.short_description.slice(0, 90)}...` : data.short_description}</p>

                    </div>
                    <div className="invoicing_allPay_card_date mt-24 d-flex justify-content-between align-items-center">
                        <p className='body2 color_dark_blue'>{new Date(data.date).toLocaleDateString()}</p>
                        <p className='body2 color_dark_blue'>Euro {data.total_amount}</p>
                    </div>
                </div>
                <div className="invoicing_allPay_card_footer">
                    <p className="body2">Assets</p>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-fill point"
                        viewBox="0 0 16 16"
                        onClick={() => {
                            navigate(`/dashboard/incubator?tab=8&ft=2&in=0&invoice=${data._id}`)
                            setformState(2)
                        }}
                    >
                        <path d="M10.5 8a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"></path>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"></path>
                    </svg>
                    {/* <img src={download} alt="zefyron finance" className='point' onClick={() => {
                        navigate(`/dashboard/incubator?tab=9&ft=2&in=0&invoice=${data._id}`)
                        setformState(2)
                    }} /> */}
                </div>
            </div>
        </div>
    )
}

export default Card