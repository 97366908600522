import React, { useEffect, useState } from "react";
import Axios from "axios";
// import EditIcon from "@mui/icons-material/Edit";
// import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import "./Calendar.css";
import { WeeklySkeleton } from "../../../../Components/SkeletonLoader/ProgramDashboard";
import { useAuth } from "../../../../Utils/Auth";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const Weekly = ({ programid }) => {
  const { user, setSessionExpire } = useAuth();
  const [calendar, setcalendar] = useState([]);
  const [weektitle, setweektitle] = useState("");
  const [month, setmonth] = useState("");
  const [weeknum, setweeknum] = useState("");
  const [weekid, setweekid] = useState("");
  const [monthid, setmonthid] = useState("");
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  let color = 1;
  const handleOpen = (v, m, i, mi, vi) => {
    setOpen(true);
    setweektitle(v);
    setmonth(m);
    setweeknum(i);
    setweekid(vi);
    setmonthid(mi);
  };
  const handleClose = () => {
    setOpen(false);
    setweektitle("");
    setmonth("");
    setweeknum("");
    setweekid("");
    setmonthid("");
  };

  const getcalendar = async () => {
    setIsLoading(true);
    await Axios.get(`/api/incubatorProgram/calender/${programid}`)
      .then((res) => {
        if (res.data.success) {
          setcalendar(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.res.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const updatecalendar = async () => {
    const response = await Axios.post(
      `/api/incubatorProgram/calender/${monthid}/${weekid}`,
      {
        title: weektitle,
      }
    );
    if (response.data.success) toast.success("Title updated!");
    getcalendar();
    handleClose();
  };
  useEffect(() => {
    getcalendar();
  }, []);

  return (
    <>
      <table className="table">
        <thead>
          <tr className="row" style={{ marginBottom: "20px" }}>
            <div className="col-2"></div>
            <div className="col-10 row">
              <th className="subtitle col-3 d-flex justify-content-center">
                Week 1
              </th>
              <th className="subtitle col-3 d-flex justify-content-center">
                Week 2
              </th>
              <th className="subtitle col-3 d-flex justify-content-center">
                Week 3
              </th>
              <th className="subtitle col-3 d-flex justify-content-center">
                Week 4
              </th>
            </div>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <WeeklySkeleton />
          ) : calendar.length > 0 ? (
            <>
              {calendar.map((v, i) => {
                return (
                  <>
                    <div className="row">
                      <td key={i} className="col-2 d-flex align-items-center">
                        <h5 style={{ color: color ? "#580167" : "#F53939" }}>
                          {v.month}
                        </h5>
                      </td>
                      <div
                        className="col-10 row d-flex align-items-center"
                        style={{ borderBottom: "1px solid #989898" }}
                      >
                        {v.weeks.map((p, k) => {
                          return (
                            <>
                              <div className="col-3">
                                <div
                                  key={k}
                                  className="row curriculam-box d-flex align-items-center"
                                  style={{
                                    borderLeft: color
                                      ? "3px solid rgb(88, 1, 103,0.3)"
                                      : "3px solid rgb(245, 57, 57,0.3)",
                                  }}
                                >
                                  <div className="col-11 caption">
                                    {p.title}
                                  </div>

                                  {/* <div className="width_fit col-2"> */}
                                  {/* <IconButton>
                              <EditIcon
                                onClick={() =>
                                  handleOpen(
                                    p.title,
                                    v.month,
                                    p.i,
                                    v._id,
                                    p._id$
                                  )
                                }
                              />
                            </IconButton> */}
                                  {user.type === "incubator" ? (
                                    <>
                                      {" "}
                                      <div
                                        onClick={() =>
                                          handleOpen(
                                            p.title,
                                            v.month,
                                            p.i,
                                            v._id,
                                            p._id
                                          )
                                        }
                                        className="edit_btn"
                                        style={{
                                          background: color
                                            ? "rgb(88, 1, 103,0.3)"
                                            : "rgb(245, 57, 57,0.3)",
                                        }}
                                      >
                                        <svg
                                          width="10"
                                          height="10"
                                          viewBox="0 0 8 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M7.70219 1.64706L6.33217 0.272102C6.15337 0.103542 5.91906 0.0068244 5.67383 0.000347633C5.4286 -0.00612913 5.18955 0.0780869 5.00216 0.236976L0.502107 4.75327C0.340488 4.91685 0.239856 5.13124 0.217104 5.36047L0.0021015 7.45302C-0.0046341 7.52652 0.0048687 7.6006 0.0299324 7.66999C0.0549961 7.73939 0.0950036 7.80237 0.147103 7.85447C0.193824 7.90098 0.249232 7.93777 0.310152 7.96274C0.371071 7.98772 0.436303 8.00037 0.502107 7.99999H0.547108L2.63213 7.8093C2.86053 7.78647 3.07415 7.68547 3.23714 7.52327L7.73719 3.00697C7.91185 2.82179 8.00624 2.57467 7.99968 2.31976C7.99312 2.06485 7.88614 1.82295 7.70219 1.64706ZM6.00217 3.32311L4.66215 1.97826L5.63716 0.974638L7.00218 2.34458L6.00217 3.32311Z"
                                            fill="#565656"
                                          />
                                        </svg>
                                      </div>{" "}
                                    </>
                                  ) : null}
                                </div>
                                {/* </div> */}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {(color = !color)}
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <p>Applicants not found</p>
          )}
        </tbody>
      </table>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className="register_page_form row">
            <div className="col-12">
              <p className="text-dark mt-2">{month}</p>
              <p className="text-dark mt-2"> Week {weeknum} </p>
              <label>Title</label>
              <input
                id="week_name"
                value={weektitle}
                placeholder={weektitle}
                onChange={(e) => setweektitle(e.target.value)}
              />
              <div className="d-flex justify-content-center mt-5">
                <button
                  onClick={() => updatecalendar()}
                  type="button"
                  className="gradient_CTA"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Weekly;
