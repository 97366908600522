import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../Utils/Auth';

const AllReqCard = ({data, setViewEstate}) => {
    const navigate = useNavigate()
    const {user} = useAuth()

    return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_by">
            <img src={data.booked_by.avatar} alt="submitted By" />
            <p className="subtitle color_dark_black">{data.booked_by.name}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black">{data.estate_id.real_estate_manager}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">{data.quantity} {data.estate_id.real_estate_name}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
            <p className="caption color_dark_black">{new Date(data.createdAt).toLocaleDateString()}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_score">
            <p className="caption color_dark_black">{data.estate_id.status === "active"? "Active" : "Inactive"}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_view">
        <button className='incubator_assessment_viewBtn caption color_dark_grey'
            onClick={()=>{
                setViewEstate(1)
                navigate(`/dashboard/${user.type}?tab=8&cs=4&estate=${data._id}`)
            }}
        >View</button>            
        </td>
    </tr>
}

export default AllReqCard