import React from 'react'
import MyLeases from "./MyLeases/MyLeases"
import RealEstate from "./RealEstate/RealEstate"
import RealEstateReq from "./RealEstateReq/RealEstateReq"
import IncubatorsAssets from "./Asset/IncubatorsAssets"
import AssetReq from "./AssetReq/AssetReq";
import MyCart from "./MyCart/MyCart"

const Coworking = (props) => {
    const { coworkingStep, setCoWorkingStep } = props;

    return (
        <div className="incubator_coworking_root">
            {
                coworkingStep === 1 ? <MyLeases
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                /> : coworkingStep === 2 ? <RealEstate
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                /> : coworkingStep === 3 ? <RealEstateReq
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                /> : coworkingStep === 4 ? <IncubatorsAssets
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                /> : coworkingStep === 5 ? <AssetReq
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                />: coworkingStep === 6 ? <MyCart
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                /> :  null
            }
        </div>
    )
}

export default Coworking