import axios from "axios";
import React, { useState } from "react";
import "./stripeConnect.css";

// import Loader from '../../components/Loader'

import { toast } from "react-toastify";

export default function StripeConnect({ handleLocalUpload }) {
  const [loader, setLoader] = useState(false);
  const callConnect = () => {
    handleLocalUpload();
    setLoader(true);
    axios({
      method: "get",
      url: "/api/payment/user-stripe-connect",
      // headers: {
      //     "Content-Type": "application/json;charset=UTF-8",
      //   }
    })
      .then((res) => {
        window.open(res.data.accountUrl);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        toast.error("Could not connect");
      });
  };
  return (
    <div className="row align-items-center no-margin stripe_connect_root">
      <a
        onClick={() => callConnect()}
        className="stripe-connect"
        style={{
          cursor: "pointer",
          width: "52%",
          padding: "0 10px",
          background: "#121843",
          borderRadius: "15px",
        }}
      >
        <span>Connect with</span>
      </a>
      {loader ? (
        <>
          &nbsp;&nbsp;{" "}
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </>
      ) : null}
    </div>
  );
}
