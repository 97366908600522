import React from "react";
// import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";

const Card = (props) => {
  const { service, handleServiceid } = props;
  // const navigate = useNavigate();

  return (

    <>

      {/* <div
        className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
        onClick={() => handleServiceid(service.hostid._id, service._id)}
      >
          <div
            style={{ cursor: "pointer" }}
            className={`dashboard_01_connections_list_item`}
          >
            <div className="dashboard_01_connections_logo_list_block">
              <Avatar
                src={service.hostid.avatar}
                className="dashboard_01_connections_logo"
              />
            </div>

            <div className="dashboard_01_connections_list_item_desc">
              <p className="p_spartan_20 pb-2">
                {service.hostid.name.length > 12
                  ? service.hostid.name.slice(0, 12) + "..."
                  : service.hostid.name}
              </p>
              <p className=" no-margin font12 bold pb-2">
                {service.hosttype}{", "} {startup.location.country}
              </p>
              <div className="row justify-content-center">
                <p className=" font12 p_grey_chip">
                  {startup.industry}
                </p>
              </div>
            </div>

          </div>
      </div> */}

    {/* <div className="col-12 col-md-6 point mb-24">
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide"></div>
        <div className="user_incubation_course_rightMain w_100">
          <div
            onClick={() => handleServiceid(service.hostid._id, service._id)}
            className="row"
          >
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                <div className="user_incubation_course_rightHead">
                  <div className="user_incubation_course_rightTitle">
                    <h5>{service.keyword}</h5>
                    <p>{new Date(service.createdAt).toDateString()}</p>
                  </div>
                </div>

                
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_leftSide">
                <div className="user_course_desc pt-3">
                  <ul>
                    {service.services.map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <div className="col-12 col-md-6 point mb-24">
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide"></div>
        <div className=" w_100" style={{padding:"20px"}}>
          <div
            className="row"
          >
            <div className="col-md-6 col-12">
              <div className="">
                  <div className="user_incubation_course_AvatarGroup_root m-0 mb-16">
                    <div className="user_incubation_course_AvatarGroups">
                      <Avatar src={service.hostid.avatar} variant="rounded" />
                    </div>
                    <div className="user_incubation_course_startupEnrolled">
                      <p className="subtitle">
                        {service.hostid.name}
                      </p>
                    </div>
                  </div>
                  <div className="user_incubation_course_right_th mb-20">
                      <span className="caption" style={{ color: "#565656" }}>
                        {new Date(service.createdAt).toDateString()}
                      </span>
                    </div>
                <div className="user_course_tags d-flex align-items-center">
                  <p className="incubation_chips">{service.keyword}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12" style={{borderLeft: "2px solid #E6E6E6"}}>
                {/* <div className="user_course_tags d-flex align-items-center">
                  <p className="incubation_chips">{service.keyword}</p>
                </div> */}

                <div className="d-flex align-items-center justify-content-between mb-12">
                  <span className="caption" style={{color:"#262C5B"}}>Service keyword</span>

                  <svg style={{cursor:"pointer"}} onClick={() => handleServiceid(service.hostid._id, service._id)} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.47257 6.66668H8.91924L5.66591 9.92001C5.40591 10.18 5.40591 10.6067 5.66591 10.8667C5.92591 11.1267 6.34591 11.1267 6.60591 10.8667L10.9992 6.47334C11.2592 6.21334 11.2592 5.79334 10.9992 5.53334L6.61258 1.13334C6.48802 1.00851 6.31892 0.938354 6.14258 0.938354C5.96623 0.938354 5.79713 1.00851 5.67257 1.13334C5.41258 1.39334 5.41258 1.81334 5.67257 2.07334L8.91924 5.33334H1.47257C1.10591 5.33334 0.805908 5.63334 0.805908 6.00001C0.805908 6.36668 1.10591 6.66668 1.47257 6.66668Z" fill="#989898"/>
                  </svg>

                </div>

                <div className="user_course_desc">
                  <ul className="ml-16">
                    {service.services.slice(0,4).map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>


                  {/* <div className=" d-flex flex-column h-100">
                    <div className="user_incubation_course_right_th mb-20">
                      <span className="caption" style={{ color: "#565656" }}>
                        {moment(`${createdAt}`).format("DD/MM/YYYY")}
                      </span>
                    </div>
                    {service.pricing ?
                      null
                    : 
                    <div className="user_course_tags d-flex align-items-center">
                      <p className="incubation_chips">{service.keyword}</p>
                    </div>
                    }
                    {service.pricing ?
                    <div className="user_incubation_course_right_th d-flex align-items-center mb-20">
                      <span className="mr-2">
                        <img src={priceimage} />
                      </span>
                      <p className="caption">€ {service.pricing}</p>
                    </div>
                    : null}
                    {service.customerSupport ?
                    <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2">
                        <img src={supportimage} />
                      </span>
                      <p className="caption"> {service.customerSupport}</p>
                    </div>
                    : null }
                  </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

    </>
  );
};

export default Card;
