import React, { useState, useEffect } from "react";
import CourseDetails from "./CourseDetails";
import CourseCurriculam from "./CourseCurriculam";
import Axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddNewSession from "./AddNewSession";
import CreateAssessment from './Assesment/createAssesment'
import CreateAssignment from "./CreateAssignment";

const CreatCourse = ({setLearningState}) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  var courseId = query.get("course");

  const navigate = useNavigate();
  const [courseFormStep, setCourseFormStep] = useState(0);
  const [addSessionState, setAddSessionState] = useState(0);
  // course
  const [title, setTitle] = useState("");
  const [tagArr, setTagArr] = useState([]);
  const [summery, setSummery] = useState("");
  const [course, setCourse] = useState(null);

  const {programid} = useParams();

  const handleNext = () => {
    setCourseFormStep(courseFormStep + 1);
  };
  const handleBack = () => {
    if (courseFormStep > 0) {
      setCourseFormStep(courseFormStep - 1);
    }
  };
  

  const getModules = async (courseId) => {
    await Axios({
      url: `/api/mentorModules/course/${courseId}`,
      method: "get",
    })
      .then((res) => {
        setCourse(res.data.course);
        // toast.success("Module created");
        // setAddSessionState(1);
      })
      .catch(() => toast.error("Try Again"));
  };

  useEffect(() => {
    if (courseId) {
      getModules(courseId);
    }
  }, [courseId]);

  useEffect(() => {
    if (course) {
      if (course.title) {
        setTitle(course.title);
      }
      if (course.summary) {
        setSummery(course.summary);
      }
      if (course.tag) {
        setTagArr(course.tag);
      }
    }
  }, [course]);

  const saveCourseDetails = async (e) => {
    e.preventDefault();
    if (course) {
      await Axios({
        url: `/api/mentorModules/course/update/${courseId}`,
        method: "put",
        data: {
          title: title,
          summary: summery,
          tag: tagArr,
        },
      })
        .then((res) => {
          setCourse(res.data.data);
          toast.success("Course updated");
          // setTagArr([])
          handleNext();
          navigate(
            `/service/incprogram/${programid}?tab=6&course=${res.data.data._id}`
          );
        })
        .catch(() => toast.error("Try Again"));
    } else
      await Axios({
        url: `/api/mentorModules/course/create`,
        method: "post",
        data: {
          title: title,
          summary: summery,
          tag: tagArr,
        },
      })
        .then((res) => {
          setCourse(res.data.data);
          toast.success("Course created");
          // setTagArr([])
          handleNext();
          navigate(
            `/service/incprogram/${programid}?tab=6&course=${res.data.data._id}`
          );
        })
        .catch(() => toast.error("Try Again"));
  };

  React.useEffect(() => {
    scrollTo(0, 0)
  }, [])
  
  return (
    <div className="create_course_root">
      <div className="create_course_main w_100">
        <form className="create_course_form_root w_100">

          {addSessionState === 0 ? (
            <>
              <CourseDetails
                handleNext={handleNext}
                handleBack={handleBack}
                title={title}
                setTitle={setTitle}
                tagArr={tagArr}
                setTagArr={setTagArr}
                summery={summery}
                setSummery={setSummery}
                saveCourseDetails={saveCourseDetails}
              />

              {course ? (
                <>
                  <CourseCurriculam
                    handleNext={handleNext}
                    handleBack={handleBack}
                    courseId={course._id}
                    addSessionState={addSessionState}
                    setAddSessionState={setAddSessionState}
                    setLearningState={setLearningState}
                  />
                </>
              ) : null}
            </>
          ) : addSessionState === 1 ? (
            <>
              <AddNewSession
                //   module={module}
                courseId={courseId}
                setAddSessionState={setAddSessionState}
              />
            </>
          ) : addSessionState === 2 ? (
            <>
              <CreateAssessment setAddSessionState={setAddSessionState} />
            </>
          ) : addSessionState === 3 ? <CreateAssignment
            setAddSessionState={setAddSessionState}
          /> :
            null}
        </form>
      </div>
    </div>
  );
};

export default CreatCourse;
