import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import PreStartProfile from "../PreStartupProfile";
import rocketimg from "./../../../../../Assets/images/Rocket.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PreStartupInvite from "../PreStartupsInvite";
import ConnectionCard from "../../../../../Components/Cards/ConnectionCard";
import {
  ParticipantsSkeleton,
  ParticipantsCardSkeleton,
  ParticipantsEmailInvite,
  ParticipantsMentee,
} from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";
import MenteeProfile from "../MenteeProfile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const Students = ({ programid, menteesProfile }) => {
  const [participants, setparticipants] = useState([]);
  const { setSessionExpire, user } = useAuth();
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [userid, setUserid] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // getting data
  const fetchStudents = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/incubator-prestart/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setparticipants(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  return (
    <div className="student_root">
      {step === 1 ? (
        <>
          <PreStartProfile
            setstep={setstep}
            studentId={participantid}
            user_id={userid}
            // title={title}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-md-8 col-lg-8 col-sm-12">
              {user.type === "mentor" && (
                <div className="col-lg-12 col-sm-12 mx-3 mb-4">
                  <div className="dashboard01_block h-fit">
                    <p className="p_spartan_20 mb-4 mt-2">My mentee</p>
                    {isLoading ? (
                      <ParticipantsMentee />
                    ) : (
                      <div className="row">
                        <MenteeProfile
                          userType="prestartup"
                          menteesProfile={menteesProfile}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {user.type === "investor" && (
                <div className="col-lg-12 col-sm-12 mx-3 mb-4">
                  <div className="dashboard01_block h-fit">
                    <p className="p_spartan_20 mb-4 mt-2">My mentee</p>
                    {isLoading ? (
                      <ParticipantsMentee />
                    ) : (
                      <div className="row">
                        <MenteeProfile
                          userType="prestartup"
                          menteesProfile={menteesProfile}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="col-lg-12 col-sm-12">
                <div className="row m-0">
                  {isLoading ? (
                    <ParticipantsSkeleton />
                  ) : participants.length === 0 ? (
                    <p className="mx-4">No participants available</p>
                  ) : (
                    participants.map((v, i) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                        key={i}
                      >
                        <ConnectionCard
                          handleFunction={() => {
                            setstep(1);
                            setparticipantid(v.prestartup._id);
                            setUserid(v.prestartup.id);
                          }}
                          profilepic={v.prestartup.profile_pic}
                          name={v.prestartup.teamName}
                          state={v.prestartup.Location.state}
                          country={v.prestartup.Location.country}
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="d-flex justify-content-center ">
                {isLoading ? (
                  <ParticipantsEmailInvite />
                ) : (
                  <button
                    onClick={() => handleOpen()}
                    type="button"
                    className="gradient_CTA"
                  >
                    INVITE PARTICIPANTS BY EMAIL
                  </button>
                )}
              </div>
              {isLoading ? (
                <ParticipantsCardSkeleton />
              ) : (
                <div className="dashboard01_block h-fit">
                  <p className="p_spartan_20 mt-2">Total Participants</p>
                  <div className="row mx-0 my-2 r_a">
                    <div className="col-8">
                      <p className="bold my-0 h4" style={{ fontSize: "32px" }}>
                        {participants.length}
                      </p>
                    </div>
                    <div className="col-4">
                      <img src={rocketimg} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 500 }}>
              <PreStartupInvite handleClose={handleClose} />
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Students;
