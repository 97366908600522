import React, { useState } from "react";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
import { toast } from "react-toastify";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from "@mui/material/TextField";

const Vcmodal = ({ requestid, callbackfunc, setOpen }) => {
  const [time, setTime] = useState(new Date());
  const { setSessionExpire } = useAuth();

  const handlevcrequest = async (term) => {
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/vcrequest/${requestid}`,
      data: {
        vc_requested: term,
        vc_schedule: time,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setOpen(false)
          if (term) toast.success("Interview requested");
          else toast.success("Removed");
        }
        callbackfunc();
        // getrequest();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };

  return (
    <>
      <div className="auth_back_loginform no-border col-12" >
        <div className="h5 mb-4" style={{ color: "#262C5B" }}>
          Schedule Interview
        </div>
        <p className="overline pb-1">Link will be shared with the schedule</p>
        <LocalizationProvider dateAdapter={AdapterDateFns} >
          <DateTimePicker
            value={time}
            onChange={(v) => setTime(v)}
            renderInput={(params) => <TextField {...params} variant='standard' />}
          />
        </LocalizationProvider>
      </div>

      <button
        onClick={() => handlevcrequest(true)}
        className="gradient_CTA width_fit mt-3 color-black"
        type="button"
      >
        SCHEDULE
      </button>
    </>
  );
};

export default Vcmodal;
