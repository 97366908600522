import React  from "react";  // , { useState, useEffect }
import "./index.css";
import { useNavigate } from "react-router-dom";

export default function index() {
  
    const navigate = useNavigate();

  return (
    <div className="dashboard_adv">
        <div className="row h-100 m-0">
            <div className="col-12 p-0" style={{height:"100px"}}>
                <span className="ad_span">AD</span>
                <div className="dashboard_adv_top h-100">
                    <p className="body2">Grab the golden opportunity and promote your <span className="twf_backgradient">Event</span></p>
                </div>
            </div>
            <div className="col-12 ">
                <div className="dashboard_adv_bottom h-100">
                <div className="d-flex h-100 flex-column">
                <p className="overline" style={{color:"#565656"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div onClick={()=>{navigate('/contact')}} className="gradient_btn_main" style={{marginTop:"20px",cursor:"pointer"}}>
                    <div className="gradient_btn_light">
                         Contact Us
                    </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  );
}
