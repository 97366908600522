import React, { useEffect, useState } from "react";
import { useAuth } from "../../..//Utils/Auth";
import Axios from "axios";

import Avatar from "@mui/material/Avatar";

const AllAplicantCard = ({ serviceid, setstartupid, setapplicantstep }) => {
  const { setSessionExpire } = useAuth();
  const [applicants, setapplicants] = useState([]);

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/service/applications/${serviceid}/false`,
      method: "get",
    })
      .then((res) => {
        // console.log('here',res.data)
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {applicants.length > 0 ? (
        <>
          {applicants.map((v, i) => {
            return (
              <>
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding mb-4"
                onClick={() => {
                  setapplicantstep(1);
                  setstartupid(v.customerid._id);
                }}
                key={i}
                style={{ cursor: "pointer" }}
              >
                  <div
                    style={{ cursor: "pointer" }}
                    className={`dashboard_01_connections_list_item`}
                  >
                    <div className="dashboard_01_connections_logo_list_block">
                      <Avatar
                        src={v.customerid.avatar}
                        alt="Profile"
                        className=""
                      />
                    </div>

                    <div className="dashboard_01_connections_list_item_desc">
                      <p className="p_spartan_20 pb-2">
                        {v.customerid.name.length > 12
                          ? v.customerid.name.slice(0, 12) + "..."
                          : v.customerid.name}
                      </p>
                      <p className=" no-margin font12 bold pb-2">
                        {v.sender_type}
                      </p>
                      {/* <div className="row justify-content-center">
                        <p className=" font12 p_grey_chip">
                          {startup.industry}
                        </p>
                      </div> */}
                    </div>

                  </div>
              </div>
              </>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default AllAplicantCard;
