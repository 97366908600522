import React from 'react'
import { ArrowForward, WatchLater, Sell } from "@mui/icons-material"
import cabin from "../../../../../Assets/images/icons/cabin.png"
import profile_short from "../../../../../Assets/images/incubatorDash/profile_short.png";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../Utils/Auth';

const Card = ({ data,setViewLeases }) => {
  const {user} = useAuth()
  const navigate = useNavigate()
  return (
    <div className="col-lg-6 col-md-6 col-12 mb-24">
      <div className="leases_active_card_root">
        <div className="leases_active_card_left_black"></div>
        <div className="leases_active_card_main">
          <div className="row">
            <div className="col-6">
              <div className="leases_active_card_main_left pl-16">
                <div className="leases_active_card_left_chip">
                  <p className="overline tagchip"> <span style={{ color: "#580167" }}>
                    Asset :
                  </span>{data.asset_id.asset_id}</p>
                </div>
                <div className="leases_active_card_main_profile mt-16">
                  <img src={data.incubator_id.avatar ? data.incubator_id.avatar : profile_short} alt="profile" />
                  <p className="subtitle pl-8">{data.incubator_id.name ? data.incubator_id.name : `${data.incubator_id.firstname} ${data.incubator_id.lastname}`}</p>
                </div>
                <div className="leases_active_card_leased_from mt-12">
                  <div className="row">
                    <div className="col-6">
                      <div className="lease_active_leased_till">
                        <p className="overline color_dark_blue">Leased from</p>
                        <p className="caption mt-8">{new Date(data.from).toLocaleDateString('en-GB')}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="lease_activeleased_till">
                        <p className="overline color_dark_blue">Leased till</p>
                        <p className="caption mt-8">{new Date(data.till).toLocaleDateString('en-GB')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="leases_active_card_main_right pr-24">
                <div className="leases_active_card_right_back" onClick={()=>{
                  setViewLeases(2)
                  navigate(`/dashboard/${user.type}?tab=9&cw=1&asset=${data._id}`)
                }}>
                  <button className='transparent_cta color_dark_grey2'><ArrowForward /></button>
                </div>
                <div className="leases_active_card_right_cabin_main mt-12">
                  <div className="leases_active_card_right_cabin">
                    <div className="img_cabin">
                      <img src={cabin} alt="cabin" />
                    </div>
                    <p className="body1 pl-12">{data.asset_id.asset_name}</p>
                    <p className="overline" style={{ paddingLeft: 4 }}>
                      {data.bought ? `+${data.bought}` : ''}
                    </p>
                  </div>
                </div>
                <div className="leases_active_card_month_left mt-40">
                  <div className='leases_active_card_month mr-24'>
                    <WatchLater />
                    <p className="overline color_dark_blue pl-6">3 months</p>
                  </div>
                  <div className='leases_active_card_month'>
                    <Sell />
                    <p className="overline color_dark_blue pl-6">Euro {data.price}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card