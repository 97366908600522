import React, { useEffect, useState } from 'react';
import { KeyboardBackspace } from "@mui/icons-material"
import { useNavigate } from 'react-router-dom';
import Query from '../../../Utils/Query/Query'
import { toast } from 'react-toastify';
import PieChart from '../LeadCategory/PieChart';
import Axios from "axios";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TimelineIcon from '@mui/icons-material/Timeline';
import AddIcon from '@mui/icons-material/Add';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import './lead.css'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CreateLead from './createLeads';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import LeadDetails from './LeadDetails';
import { useAuth } from '../../../Utils/Auth';
const style = {
     position: "absolute",
     top: "50%",
     left: "50%",
     transform: "translate(-50%, -50%)",
     width: "50%",
     bgcolor: "background.paper",
     boxShadow: 24,
     p: 3,
     outline: 'none',
     margin: '2rem 0'
};
const styleDetails = {
     position: "absolute",
     top: "50%",
     left: "50%",
     transform: "translate(-50%, -50%)",
     width: "70%",
     bgcolor: "background.paper",
     boxShadow: 24,
     p: 3,
     outline: 'none',
     margin: '2rem 0'
};
const Leads = ({ setGenerationStep, target,tab }) => {
     const { user } = useAuth()
     const [category, setCategory] = useState({})
     const navigate = useNavigate();
     const query = Query();
     const targetId = query.get('target');
     const categoryId = query.get('category');
     const isLeadCreated = query.get('create');
     const [open, setOpen] = useState(true);
     const [leads, setLeads] = useState([])
     const [modalopen, setModalOpen] = useState(false);
     const [totalInteractionCount,setTotalInteractionCount] = useState(0)
     const handleOpen = () => {
          navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId}&category=${category._id}&create=pending`);
          setModalOpen(true)
     };
     const handleClose = () => {
          navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId}&category=${category._id}`);
          setModalOpen(false)
     };
     const [categoryStats,setCategoryStats]  = useState([])

     const [detailModal, setDetailModal] = useState(false)
     const [leadId,setLeadId] = useState('')
     const handleDetailModalOpen = (leadId) => {
          navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId}&category=${category._id}&lead=${leadId}`);
          setDetailModal(true)
          setLeadId(leadId)
     }

     const handleDetailModalClose = () => {
          if(isLeadCreated === 'pending'){
               navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId}&category=${category._id}`);
          }
          
          setDetailModal(false)
          setLeadId("")
     }

     const handleListItemOpen = () => {
          setOpen(!open);
     };

     const statusClass = (lead) => {
          return lead.lead_status === ("Introduction" || "Proposal sent") ? 'badge-primary' : lead.lead_status === "In progress" ? 'badge-warning' : lead.lead_status === "Approved" ? 'badge-success' : lead.lead_status === "Rejected" ? 'badge-danger' : 'badge-primary'
     }


     const fetchCategory = async () => {
          // setIsLoading(true)
          const { data } = await Axios({
               method: "get",
               url: `/api/leadGeneration/leadCategory/${targetId}/${categoryId}`,
          })
          if (data.success) {
               setCategory(data.data)
          } else {
               toast.error('Error while fetching category data')
          }

     }

     const fetchLeads = () => {
          Axios({
               method: "get",
               url: `/api/leadGeneration/leads/${targetId}/${categoryId}`,
          }).then((res) => {
               setLeads(res.data.data)
          })
               .catch(() => {
                    toast.error("Error while creating lead", { position: "top-right" });
               });
     }


     useEffect(() => {
          fetchCategory()
          fetchLeads()
     }, [leadId,isLeadCreated])



     const fetchStats = () => {
          Axios({
               mathod:'get',
               url:`/api/leadGeneration/analytics/leads/${categoryId}`
          }).then(res=>{
               let categoryStates = []
               let totalCount = 0;
               res.data.data.map(stats=>{
                    totalCount = totalCount + stats.count;
                    const data = {
                         name: stats.lead_status,
                         value:stats.count
                    }
                    categoryStates.push(data);
               })
               categoryStates.push({
                    name:"MOU",
                    value:res.data.mou
               })
               setTotalInteractionCount(totalCount)
               setCategoryStats(categoryStates)
          }).catch(()=>{
               toast.error("Error while creating lead", { position: "top-right" });
          })
     }

     useEffect(()=>{
          fetchStats()
     },[leadId,isLeadCreated])
     return (
          <div className='row'>
               <div className="viewReq_head col-12">
                    <button className='transparent_cta btncta' onClick={() => {
                         setGenerationStep(1)
                         navigate(-1)
                    }}><KeyboardBackspace /> Back</button>
               </div>

               <div className="target_card px-4 py-2 w-100 mx-1 mb-5 d-flex align-items-center">
                    <div>
                         <div>
                              <h5 className='text-uppercase'>{category.name}</h5>
                              <p>Total Leads : {category?.lead_nos ? category.lead_nos : 0}</p>
                         </div>

                    </div>
                    <div className='d-flex align-items-center mx-5'>
                         <div className='d-flex align-items-center my-3 mr-4'>
                              <p>Total Amount In : </p>
                              <h5 className='mx-2'>€ {category.category_amount} </h5>
                         </div>
                    </div>

               </div>
               <div style={{ width: '100%' }} className="mx-2">
                    <List
                         sx={{ width: 1, bgcolor: open ? '#F0F0F0' : '#fff', boxShadow: open ? '0px 3px 20px rgb(48 48 48 / 20%)' : '0px 3px 20px rgb(48 48 48 / 10%)' }}
                         component="nav"
                         aria-labelledby="nested-list-subheader">
                         <ListItemButton onClick={handleListItemOpen}>
                              <ListItemIcon>
                                   <TimelineIcon />
                              </ListItemIcon>
                              <ListItemText primary="Leads" />
                              {open ? <ExpandLess /> : <ExpandMore />}
                         </ListItemButton>
                         <Collapse in={open} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                   {
                                        leads.length > 0 && leads.map(lead => <ListItemButton sx={{ pl: 4, bgcolor: '#fff', m: 1 }} 
                                        onClick={() => handleDetailModalOpen(lead._id)} key={lead._id}>
                                             <AccountTreeIcon />
                                             <ListItemText primary={lead.title} className="mx-2 text-capitalize" />
                                             <p className='mx-2'>
                                                  {"€ " + lead.amount_raised}
                                             </p>
                                             <p className='mx-2'>
                                                  <AttachFileIcon />
                                                  <span className='mx-1'>{lead.attachments.length}</span>
                                             </p>
                                             <p className='mx-2'>
                                                  <FormatListNumberedRtlIcon />
                                                  <span className='mx-1'>{lead.todos.length}</span>
                                             </p>
                                             <p
                                                  className={
                                                       `ml-2 p-1 badge ${statusClass(lead)}`
                                                  }
                                             >
                                                  {lead.lead_status}
                                             </p>

                                        </ListItemButton>)
                                   }
                                   <ListItemButton sx={{ pl: 4, bgcolor: '#fff', m: 1 }} onClick={handleOpen} >
                                        <AddIcon />
                                        <ListItemText primary="Create Lead" className='mx-1' />
                                   </ListItemButton>
                              </List>
                         </Collapse>
                    </List>
               </div>

               <div className='col-12 mt-3 '>
                    <div className='col-6 target_card mb-4'>
                         <PieChart totalcount={totalInteractionCount} data={categoryStats} />
                    </div>
               </div>
               <Modal aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={modalopen}
                    onClose={handleClose}
                    closeAfterTransition
                    style={{ overflow: 'scroll', height: "100%" }}

               >
                    <Box sx={style}>
                         <CreateLead handleClose={handleClose} tab={tab} fetchLeads={fetchLeads} />
                    </Box>

               </Modal>

               <Modal aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={detailModal}
                    onClose={handleDetailModalClose}
                    closeAfterTransition
                    style={{ overflow: 'scroll', height: "100%" }}>
                    <Box sx={styleDetails}>

                         <LeadDetails handleDetailModalClose={handleDetailModalClose} category={category} target={target} />

                    </Box>
               </Modal>
          </div>
     )
}

export default Leads