import React, { useState } from "react";
// import "./../../authentication.css";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import LocationComponent from "../../../../Utils/Location/index";
import industries from "./../../../Lists/Industries.json";
import { useAuth } from "../../../../Utils/Auth";

const Step1 = (props) => {
  const { isUser } = useAuth();
  const {
    localprofile,
    setProfile,
    companyLogo,
    setCompanyLogoUrl,
    city,
    setcity,
    state,
    setstate,
    setcities,
    setstates,
    cities,
    states,
    country,
    setCountry,
    IncorporationDate,
    setIncorporationDate,
    companyWebsite,
    setcompanyWebsite,
    companylinkedin,
    setcompanylinkedin,
    name,
    setName,
    description,
    setDescription,
    setactiveTab,
    industry,
    setIndustry,
    otherIndustry,
    setOtherIndustry,
  } = props;

  const [islogoloading, setislogoloading] = useState(false);
  // const { user } = useAuth();

  const handleSubmit = (e) => {
    // setProfile({
    //     ...profile,
    //     name: name,
    //     description: description,
    //     companyWebsite: companyWebsite,
    //     IncorporationDate:IncorporationDate,
    //     country: country,
    //     CompanyAddress: CompanyAddress,
    //     postalCode: postalCode

    // });
    // localStorage.setItem("Profile", JSON.stringify(profile));
    e.preventDefault();
    if (companyLogo === null) return toast.error("Upload logo");
    if (
      industry.length === 0 ||
      name.length === 0 ||
      description.length === 0 ||
      IncorporationDate === null ||
      companyWebsite.length === 0
    )
      return toast.error("Empty fields");

    if (country.length === 0) return toast.error("Select Country");
    if (states && states.length > 0 && (!state || state.length === 0))
      return toast.error("Select state ");
    else if (cities && cities.length > 0 && (!city || city.length === 0))
      return toast.error("Select city ");
  };

  const handleLogoUpload = (e) => {
    const image = e.target.files[0];

    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("companyLogo", image);
    setislogoloading(true);
    Axios({
      method: "post",

      url: " /api/upload/companylogo",

      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          setCompanyLogoUrl(data.location);
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }
        setProfile({
          ...localprofile,
          companyLogo: data.location,
        });
        setislogoloading(false);
        toast.success("Company logo uploaded", { position: "bottom-right" });
        // setProgress(25);
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  if (!isUser) return <h1>Session Timed Out</h1>;

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="register_page_form_upload">
        {islogoloading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            {companyLogo ? (
              <>
                <img src={companyLogo} alt="logo" className="companyLogo" />
                <p
                  className="p_small_black mt-2"
                  onClick={() => setCompanyLogoUrl(null)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </p>
              </>
            ) : (
              <>
                <label htmlFor="clogo">
                  <div className="register_page_form_com_logo" onClick={{}}>
                    Upload
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_pic"
                    id="clogo"
                    value={companyLogo}
                    onChange={(e) => handleLogoUpload(e)}
                  />
                </label>
                <p className="register_page_form_upload-text mt-1">
                  Company Logo
                </p>
              </>
            )}
          </>
        )}
      </div>

      <div className="register_page_form row">
        <div className="col-12">
          <label>Company&#39;s Name*</label>
          <input
            id="scompanyname"
            placeholder="Enter Company's Name"
            required
            type="text"
            className="auth_input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-12">
          <label>Short Description*</label>
          <textarea
            required
     
            id="sdesc"
            name="short_desc"
            className="auth_input auth_textarea"
            placeholder=" Write a Short Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
          <label>Company Website*</label>
          <input
            placeholder="Enter Company's Website"
            required
            id="swebsite"
            type="text"
            className="auth_input"
            value={companyWebsite}
            onChange={(e) => setcompanyWebsite(e.target.value)}
          />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
          <label>Linkedin Profile Url*</label>
          <input
            placeholder="Enter Linkedin Profile Url"
            required
            type="text"
            className="auth_input"
            value={companylinkedin}
            onChange={(e) => setcompanylinkedin(e.target.value)}
          />
            </div>
          </div>
        </div>
        </div>

        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
              <label>Incorporation Date*</label>
              <input
                required
                id="sinc"
                type="text"
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                className="auth_input"
                placeholder="Enter Incorporation Date"
                value={IncorporationDate}
                onChange={(e) => setIncorporationDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <label>Industry*</label>
              <select
                className="auth_input auth_select"
                name="industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              >
                <option value="">Select Industry</option>
                {industries.map((item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        {industry === "Other" ? (
          <>
            <div className="col-12 col-md-6">
              <label>Industry</label>
              <input
                type="text"
                placeholder="Please specify your industry"
                className="auth_input"
                value={otherIndustry}
                onChange={(e) => setOtherIndustry(e.target.value)}
              />
            </div>
          </>
        ) : null}

        </div>
        <div className="col-12">
          <label>Location*</label>
        </div>
        <div className="col-12" style={{ display: "flex" }}>
          <LocationComponent
            country={country}
            setcountry={setCountry}
            state={state}
            setstate={setstate}
            city={city}
            setcity={setcity}
            states={states}
            cities={cities}
            setstates={setstates}
            setcities={setcities}
          />
        </div>
        <button
          type="button"
          onClick={() => setactiveTab(1)}
          className="forbtn"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default Step1;
