import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../Assets/images/logo/logoblack.png";
import "../dashboard.css";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";

import embedLogo from "../../Assets/images/logo/TWF_emblem_black_400H.png";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import Connections from "../Components/Connections";

import DashboardNav from "../Components/DashboardNav";
import { useAuth } from "../../Utils/Auth";

import EventsForm from "../../Services/Events/host";
import Myevents from "../../Services/Events/myevents";
import Event from "../../Services/Events";

import LandingPage from "../../Services/ServiceProviderTool/LandingPage";
import IncHome from "./Home";
import Valuation from "../Components/Valuation/Valuation";
import Messages from "../Components/Messages";
import Profile from "../Components/Profile/sp";
import ConnectionProfile from "../Components/Connections/ConnectionProfile";
import Requests from "../Components/Connections/Requests";
import MyConnections from "../Components/Connections/MyConnections";
import IncubationToolSP from "../Components/IncubationToolSP/IncubationTool";
import Mentorships from "../../Services/Mentorships";
import ActiveMentorships from "../../Services/Mentorships/Activementorships";
import Feed from "../Components/Feed/Feed";
import NotFoundPage from "../../Pages/404Pages";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function DashboardTabs() {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const [EventValue, setEventValue] = useState(0);
  // const [cur, setcur] = useState(1);
  // const [incubationValue, setIncubationValue] = useState(1);
  const [servicebool, setservicebool] = useState(0);
  const [EventBool, setEventBool] = useState(false);
  const [eventOption, seteventOption] = useState(false);
  const [eventActiveStep, setEventActiveStep] = useState(1);
  const [reportBool, setReportState] = useState(false);
  const [valuationStep, setValuationStep] = useState(1);
  const [connectionState, setconnectionState] = useState(0);
  const [ActiveStep, setActiveStep] = useState(0);
  const [incubationState, setIncubationState] = useState(0);
  const [mentorState, setmentorState] = useState(0);

  const { user, isVerified, isLoading, profile } = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isTab = useMediaQuery({ query: "(max-width:768px)" });
  const query = useQuery();
  var currentStep = Number(query.get("tab"));
  var currentProStep = Number(query.get("ps"));

  useEffect(() => {
    if (currentStep) {
      setValue(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentProStep) {
      setEventActiveStep(currentProStep);
    }
  }, [currentProStep]);

  useEffect(()=>{
    if(value==1920 || value==11)
    {
      setservicebool(0);
      setEventBool(0);
      setReportState(false);
      setconnectionState(0);
      setmentorState(0);
    }
  },[value]);

  useEffect(() => {
    if (!isLoading && !profile && !isVerified) return navigate("/welcome");
    if (!isLoading && !isVerified) return navigate("/thankyou");
  }, [isLoading]);
  if (isLoading) {
    return <div></div>;
  } 
  else if(user.type !== "serviceprovider")
  {
    return <NotFoundPage/>
  }
  else {
    return (
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: 224,
        }}
        className="dashboard01_main_box"
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="dashboard01_tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <div className="dashboard01_nav_head">
            <Link to="/">
              <img
                src={isTab ? embedLogo : logo}
                className="dashboard_left_nav_logo"
              />
            </Link>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-square-fill" viewBox="0 0 20 20">
  <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
</svg> */}
          </div>
          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=1`);
              setservicebool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
              setmentorState(0);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-house-door-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
                  </svg>
                </div>

                <p>Home</p>
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=2`);
              setservicebool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(1);
              setmentorState(0);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.3996 4.80039C10.3996 5.43691 10.1468 6.04736 9.69667 6.49745C9.24658 6.94753 8.63613 7.20039 7.99961 7.20039C7.36309 7.20039 6.75264 6.94753 6.30255 6.49745C5.85247 6.04736 5.59961 5.43691 5.59961 4.80039C5.59961 4.16387 5.85247 3.55342 6.30255 3.10333C6.75264 2.65325 7.36309 2.40039 7.99961 2.40039C8.63613 2.40039 9.24658 2.65325 9.69667 3.10333C10.1468 3.55342 10.3996 4.16387 10.3996 4.80039Z" />
                    <path d="M14.3997 6.40078C14.3997 6.82513 14.2311 7.23209 13.9311 7.53215C13.631 7.83221 13.2241 8.00078 12.7997 8.00078C12.3754 8.00078 11.9684 7.83221 11.6683 7.53215C11.3683 7.23209 11.1997 6.82513 11.1997 6.40078C11.1997 5.97643 11.3683 5.56947 11.6683 5.26941C11.9684 4.96935 12.3754 4.80078 12.7997 4.80078C13.2241 4.80078 13.631 4.96935 13.9311 5.26941C14.2311 5.56947 14.3997 5.97643 14.3997 6.40078Z" />
                    <path d="M4.79961 6.40078C4.79961 6.82513 4.63104 7.23209 4.33098 7.53215C4.03092 7.83221 3.62396 8.00078 3.19961 8.00078C2.77526 8.00078 2.3683 7.83221 2.06824 7.53215C1.76818 7.23209 1.59961 6.82513 1.59961 6.40078C1.59961 5.97643 1.76818 5.56947 2.06824 5.26941C2.3683 4.96935 2.77526 4.80078 3.19961 4.80078C3.62396 4.80078 4.03092 4.96935 4.33098 5.26941C4.63104 5.56947 4.79961 5.97643 4.79961 6.40078Z" />
                    <path d="M12.7997 14.4004V12.0004C12.8009 11.1871 12.5943 10.3869 12.1997 9.67564C12.5544 9.58487 12.9251 9.57631 13.2836 9.65061C13.6421 9.72491 13.9789 9.88011 14.2683 10.1044C14.5576 10.3286 14.792 10.616 14.9534 10.9446C15.1148 11.2732 15.1991 11.6343 15.1997 12.0004V14.4004H12.7997Z" />
                    <path d="M3.7998 9.67582C3.4052 10.3871 3.19868 11.1873 3.1998 12.0006V14.4006H0.799805V12.0006C0.799651 11.6343 0.883375 11.2727 1.04456 10.9437C1.20575 10.6147 1.44012 10.327 1.72971 10.1026C2.0193 9.87814 2.35643 9.723 2.71525 9.64903C3.07408 9.57506 3.44507 9.58423 3.7998 9.67582Z" />
                    <rect
                      x="4.7998"
                      y="8.80078"
                      width="6.4"
                      height="5.6"
                      rx="2.8"
                    />
                  </svg>
                </div>
                <p>Connections</p>
              </div>
            }
            {...a11yProps(2)}
          />
          {connectionState ? (
            <div className="dashboard01_tab_Valuate">
              <button
                onClick={() => setconnectionState(1)}
                className={`${connectionState === 1 ? "reportSelected" : ""}`}
              >
                Explore
              </button>
              <button
                onClick={() => setconnectionState(2)}
                className={`${connectionState === 2 ? "reportSelected" : ""}`}
              >
                My Network
              </button>
              <button
                onClick={() => setconnectionState(3)}
                className={`${connectionState === 3 ? "reportSelected" : ""}`}
              >
                Invitations{" "}
              </button>
            </div>
          ) : null}

          {/* <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=3`);
              setservicebool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
              setmentorState(0);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-chat-left-dots-fill"
                    viewBox="0 0 20 20"
                  >
                    <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                  </svg>
                </div>
                <p>Messages</p>
              </div>
            }
            {...a11yProps(3)}
          /> */}

          <p className="tab_head">SERVICE PAGES</p>
          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=4`);
              setservicebool(0);
              setEventBool(0);
              setReportState(true);
              setconnectionState(0);
              setmentorState(0);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M13.375 7.5H9C8.60217 7.5 8.22064 7.34196 7.93934 7.06066C7.65803 6.77935 7.5 6.39782 7.5 6V1.625C7.5 1.59185 7.48683 1.56005 7.46339 1.53661C7.43995 1.51317 7.40815 1.5 7.375 1.5H4.5C3.96957 1.5 3.46086 1.71071 3.08579 2.08579C2.71071 2.46086 2.5 2.96957 2.5 3.5V13.5C2.5 14.0304 2.71071 14.5391 3.08579 14.9142C3.46086 15.2893 3.96957 15.5 4.5 15.5H11.5C12.0304 15.5 12.5391 15.2893 12.9142 14.9142C13.2893 14.5391 13.5 14.0304 13.5 13.5V7.625C13.5 7.59185 13.4868 7.56005 13.4634 7.53661C13.4399 7.51317 13.4082 7.5 13.375 7.5Z" />
                    <path d="M13.1006 6.39456L8.60656 1.9005C8.59782 1.89181 8.58671 1.8859 8.57462 1.88351C8.56253 1.88112 8.55001 1.88236 8.53862 1.88707C8.52723 1.89178 8.51749 1.89975 8.51062 1.90998C8.50375 1.92021 8.50005 1.93224 8.5 1.94456V6.00113C8.5 6.13373 8.55268 6.26091 8.64645 6.35468C8.74021 6.44845 8.86739 6.50113 9 6.50113H13.0566C13.0689 6.50107 13.0809 6.49738 13.0911 6.49051C13.1014 6.48364 13.1093 6.4739 13.1141 6.46251C13.1188 6.45112 13.12 6.43859 13.1176 6.4265C13.1152 6.41442 13.1093 6.4033 13.1006 6.39456Z" />
                  </svg>
                </div>

                <p>Valuation tool</p>
              </div>
            }
            {...a11yProps(4)}
          />
          {reportBool ? (
            <div className="dashboard01_tab_Valuate">
              <button
                onClick={() => setValuationStep(1)}
                className={`${valuationStep === 1 ? "reportSelected" : ""}`}
              >
                {" "}
                View Reports{" "}
              </button>
              <button
                onClick={() => setValuationStep(2)}
                className={`${valuationStep === 2 || valuationStep === 3 ? "reportSelected" : ""}`}
              >
                {" "}
                Create Report{" "}
              </button>
            </div>
          ) : null}

          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=5`);
              setservicebool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
              setmentorState(1);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1.739 7.93729L8.37088 7.93789C8.67499 7.93795 8.96784 8.05291 9.19077 8.25974C9.41371 8.46658 9.55026 8.75001 9.57306 9.05326L9.57668 9.14369V10.6503C9.57608 13.1825 6.99206 13.9663 5.05494 13.9663C3.15641 13.9663 0.636299 13.2139 0.536218 10.7999L0.533203 10.6503V9.14309C0.533203 8.50763 1.02517 7.98673 1.64857 7.94091L1.739 7.93729ZM9.70932 7.93789L13.797 7.93729C14.4324 7.93729 14.9533 8.42926 14.9992 9.05265L15.0028 9.14309V10.3489C15.0022 12.6152 12.9348 13.3634 11.3854 13.3634C10.7475 13.3634 10.0234 13.2338 9.39039 12.9263C9.84377 12.3873 10.138 11.7012 10.1754 10.8378L10.1796 10.6503V9.14369C10.1796 8.72046 10.0295 8.33581 9.78528 8.02833L9.70932 7.93729H13.797L9.70932 7.93789ZM5.05494 0.0996094C5.49044 0.099649 5.92166 0.185465 6.32399 0.352159C6.72632 0.518852 7.09188 0.763158 7.39979 1.07113C7.70771 1.3791 7.95195 1.7447 8.11857 2.14706C8.28519 2.54942 8.37092 2.98066 8.37088 3.41615C8.37084 3.85165 8.28503 4.28287 8.11833 4.6852C7.95164 5.08753 7.70734 5.45309 7.39937 5.761C7.0914 6.06892 6.72579 6.31316 6.32343 6.47978C5.92107 6.6464 5.48983 6.73214 5.05434 6.7321C4.17482 6.73202 3.33135 6.38255 2.70949 5.76058C2.08763 5.13861 1.73832 4.29507 1.7384 3.41555C1.73848 2.53603 2.08794 1.69256 2.70992 1.0707C3.33189 0.448842 4.17542 0.0995295 5.05494 0.0996094ZM11.6868 1.30541C12.0431 1.30541 12.3959 1.37558 12.7251 1.51192C13.0542 1.64827 13.3533 1.84811 13.6052 2.10004C13.8572 2.35197 14.057 2.65105 14.1934 2.98021C14.3297 3.30937 14.3999 3.66217 14.3999 4.01845C14.3999 4.37473 14.3297 4.72753 14.1934 5.05669C14.057 5.38585 13.8572 5.68493 13.6052 5.93686C13.3533 6.18879 13.0542 6.38863 12.7251 6.52498C12.3959 6.66132 12.0431 6.73149 11.6868 6.73149C10.9673 6.73149 10.2772 6.44566 9.76841 5.93686C9.25962 5.42807 8.97378 4.73799 8.97378 4.01845C8.97378 3.29891 9.25962 2.60883 9.76841 2.10004C10.2772 1.59124 10.9673 1.30541 11.6868 1.30541Z" />
                  </svg>
                </div>
                <p>Mentorship tool</p>
              </div>
            }
            {...a11yProps(5)}
          />
          {mentorState ? (
            <div className="dashboard01_tab_Valuate">
              <button
                onClick={() => setmentorState(1)}
                className={`${mentorState === 1 ? "reportSelected" : ""}`}
              >
                {" "}
                Explore Mentors{" "}
              </button>
              <button
                onClick={() => setmentorState(2)}
                className={`${mentorState === 2 ? "reportSelected" : ""}`}
              >
                {" "}
                My Mentorships{" "}
              </button>
            </div>
          ) : null}

          <Tab
            onClick={() => {
              seteventOption(!eventOption);
              navigate(`/dashboard/${user.type}?tab=6`);
              setservicebool(0);
              setEventBool(!EventBool);
              setEventValue(1);
              setReportState(false);
              setconnectionState(0);
              setmentorState(0);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="20"
                    fill="currentColor"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 6.79976C10.4244 6.79976 10.8313 6.96832 11.1314 7.26836C11.4314 7.56841 11.6 7.97535 11.6 8.39968V11.5995C11.6 12.0238 11.4314 12.4308 11.1314 12.7308C10.8313 13.0309 10.4244 13.1994 10 13.1994H7.6C7.17566 13.1994 6.76869 13.0309 6.46863 12.7308C6.16858 12.4308 6.00001 12.0238 6.00001 11.5995V8.39968C6.00001 7.97535 6.16858 7.56841 6.46863 7.26836C6.76869 6.96832 7.17566 6.79976 7.6 6.79976H10ZM12.4 10.9292V9.07004L13.7872 7.75651C13.8724 7.67577 13.9793 7.62178 14.0949 7.60122C14.2104 7.58067 14.3295 7.59444 14.4372 7.64084C14.545 7.68724 14.6369 7.76423 14.7013 7.86228C14.7658 7.96033 14.8001 8.07514 14.8 8.19249V11.8067C14.8001 11.9241 14.7658 12.0389 14.7013 12.1369C14.6369 12.235 14.545 12.312 14.4372 12.3584C14.3295 12.4048 14.2104 12.4185 14.0949 12.398C13.9793 12.3774 13.8724 12.3234 13.7872 12.2427L12.4 10.9292V10.9292ZM18 9.9996C18 5.58142 14.4184 2 10 2C5.58161 2 2.00001 5.58142 2.00001 9.9996C1.99834 11.2779 2.30429 12.5378 2.89201 13.673L2.03761 16.7313C1.98995 16.902 1.98855 17.0824 2.03355 17.2539C2.07855 17.4253 2.16833 17.5818 2.29369 17.7071C2.41906 17.8325 2.5755 17.9223 2.74699 17.9673C2.91847 18.0123 3.09884 18.0109 3.26961 17.9632L6.33041 17.1096C7.46466 17.6959 8.7232 18.001 10 17.9992C14.4184 17.9992 18 14.4178 18 9.9996ZM3.20001 9.9996C3.20044 8.50745 3.69171 7.05687 4.59807 5.87151C5.50444 4.68614 6.77557 3.83181 8.21549 3.44023C9.65542 3.04865 11.1842 3.14156 12.5661 3.70464C13.948 4.26772 15.1063 5.2697 15.8624 6.55611C16.6185 7.84251 16.9305 9.34191 16.7502 10.8231C16.5699 12.3043 15.9073 13.6851 14.8647 14.7526C13.8221 15.8201 12.4572 16.515 10.9806 16.7302C9.50396 16.9453 7.99755 16.6688 6.69361 15.9433L6.47761 15.8233L3.28881 16.7121L4.17921 13.525L4.05841 13.309C3.49367 12.2976 3.1981 11.158 3.20001 9.9996V9.9996Z" />
                  </svg>
                </div>
                <p>Events & networking</p>
              </div>
            }
            {...a11yProps(6)}
          />
          {EventBool ? (
            <div className="event_dashboard_02">
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  EventValue === 1 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => setEventValue(1)}
              >
                My Events
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  EventValue === 2 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => setEventValue(2)}
              >
                Create Events
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  EventValue === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => setEventValue(3)}
              >
                Explore Events
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  EventValue === 4 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => setEventValue(4)}
              >
                My Invitations
              </button>
            </div>
          ) : null}

          <Tab
            onClick={() => {
              // seteventOption(!eventOption);
              navigate(`/dashboard/${user.type}?tab=7&ps=1`);
              setEventBool(0);
              setservicebool(!servicebool);
              setIncubationState(0);
              setEventActiveStep(1);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
            }}
            label={
              <div className="dashboard01_tab ">
                <div className="dashboard01_tab_svg">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 17.2503L3.75 17.2517C4.37402 17.2517 4.875 16.7503 4.875 16.128V8.28317H1.5V17.2503ZM3.1875 15.012C3.49512 15.012 3.75 15.2617 3.75 15.573C3.75 15.8797 3.49512 16.1339 3.1875 16.1339C2.87988 16.1339 2.625 15.8785 2.625 15.5733C2.625 15.2611 2.87988 15.012 3.1875 15.012ZM13.7625 6.02736C13.4833 6.02736 13.212 6.13152 13.0059 6.31953L9.54727 9.47597C9.54375 9.48299 9.54375 9.49001 9.53672 9.49001C8.95313 10.0373 8.96367 10.9108 9.46289 11.4546C9.91113 11.9142 10.8473 12.0728 11.4362 11.5511C11.4422 11.5458 11.4457 11.5458 11.4492 11.5423L14.2575 8.9778C14.486 8.77183 14.8464 8.78499 15.0527 9.01289C15.2637 9.24078 15.2461 9.59596 15.0176 9.80645L14.0993 10.6439L19.2188 14.7875C19.3198 14.875 19.4121 14.9627 19.4956 15.0591V8.24107C18.0549 6.80409 16.1037 6 14.0675 6L13.7625 6.02736ZM13.2633 11.409L12.2086 12.3734C11.1627 13.3245 9.56309 13.2326 8.62969 12.2201C7.6875 11.1915 7.76133 9.59525 8.78789 8.65504L11.6637 6.02736H11.4316C9.39961 6.02736 7.44492 6.83707 6.00703 8.26914L6 16.1241L6.6416 16.1254L9.82324 18.9959C10.79 19.7807 12.2051 19.6316 12.9873 18.6715L13.6243 19.2061C14.1826 19.6306 15.0088 19.5745 15.4658 19.0177L16.569 17.6635L16.758 17.8213C17.2414 18.2072 17.949 18.1371 18.34 17.6548L18.6753 17.2415C19.0662 16.7591 18.993 16.0564 18.5103 15.6652L13.2633 11.409ZM20.625 8.28668V16.1311C20.625 16.7493 21.126 17.2555 21.7184 17.2555L24 17.2538V8.27615L20.625 8.28668ZM22.3125 16.1311C22.0049 16.1311 21.75 15.8769 21.75 15.5702C21.75 15.2588 22.0049 15.0092 22.3125 15.0092C22.6201 15.0092 22.875 15.2611 22.875 15.5733C22.875 15.8785 22.6219 16.1311 22.3125 16.1311Z"
                      fill=""
                    />
                  </svg>
                </div>
                <p> Outreach </p>
              </div>
            }
            {...a11yProps(7)}
          />
          {servicebool ? (
            <div className="event_dashboard_02">
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 1 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(1);
                  navigate(`/dashboard/${user.type}?tab=7&ps=1`);
                }}
              >
                Active Outreach
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 2 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(2);
                  navigate(`/dashboard/${user.type}?tab=7&ps=2`);
                }}
              >
                My Services
              </button>
              <button
                className={`event_dashboard_btn_01 p_LightGrey14 ${
                  eventActiveStep === 3 ? "event_dashboard_btn_01_selected" : ""
                }`}
                onClick={() => {
                  setEventActiveStep(3);
                  navigate(`/dashboard/${user.type}?tab=7&ps=3`);
                }}
              >
                Explore Requests
              </button>
            </div>
          ) : null}
          <Tab
            onClick={() => {
              setEventBool(0);
              setIncubationState(1);
              setEventActiveStep(0);
              setconnectionState(0);
              setmentorState(0);
              setReportState(false);
              navigate(`/dashboard/${user.type}?tab=8`);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    fill="currentColor"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 9.08366C1 8.97315 1.0439 8.86717 1.12204 8.78903C1.20018 8.71089 1.30616 8.66699 1.41667 8.66699H2.66666C2.77717 8.66699 2.88315 8.71089 2.96129 8.78903C3.03943 8.86717 3.08333 8.97315 3.08333 9.08366V13.6653C3.08333 13.7758 3.03943 13.8818 2.96129 13.9599C2.88315 14.0381 2.77717 14.082 2.66666 14.082H1.41667C1.30616 14.082 1.20018 14.0381 1.12204 13.9599C1.0439 13.8818 1 13.7758 1 13.6653V9.08366ZM7.21457 8.74574C7.21457 8.74574 6.2654 8.56866 5.87707 8.74574C5.5879 8.87741 4.46874 9.27907 3.91666 9.47574V13.1674C4.23624 13.1491 4.87207 13.1153 5.14374 13.1261C6.70873 13.1891 7.50748 13.8486 9.06956 13.967C9.94331 14.0332 10.7087 14.1878 11.3129 13.967C11.9171 13.7457 15.3679 12.0645 15.7991 11.622C16.2308 11.1795 16.015 9.98449 14.6775 10.2057C13.34 10.427 11.5716 11.4891 10.8383 11.4449C10.105 11.4007 8.16331 11.0466 8.16331 11.0466L10.3925 11.0737C10.3925 11.0737 10.7075 11.0903 11.14 10.7812C11.5733 10.472 11.96 9.58657 11.3129 9.58657C10.6658 9.58657 9.97539 9.36491 9.97539 9.36491L7.21457 8.74574Z" />
                    <path d="M10.2 6.05556V5.66667H7.404L7.4 7.22222C7.4 7.65389 7.756 8 8.2 8H13.8C14.244 8 14.6 7.65389 14.6 7.22222V5.66667H11.8V6.05556H10.2ZM14.2 2.55556H12.596V1.77778L11.796 1H10.196L9.396 1.77778V2.55556H7.8C7.36 2.55556 7 2.90556 7 3.33333V4.5C7 4.93167 7.356 5.27778 7.8 5.27778H10.2V4.5H11.8V5.27778H14.2C14.64 5.27778 15 4.92778 15 4.5V3.33333C15 2.90556 14.64 2.55556 14.2 2.55556ZM11.8 2.55556H10.2V1.77778H11.8V2.55556Z" />
                  </svg>
                </div>
                <p>Incubation Tool</p>
              </div>
            }
            {...a11yProps(8)}
          />
          {incubationState ? (
            <div className="dashboard01_tab_Valuate">
              <button
                onClick={() => setIncubationState(1)}
                className={`${incubationState === 1 ? "reportSelected" : ""}`}
              >
                View Programs
              </button>
              <button
                onClick={() => setIncubationState(2)}
                className={`${incubationState === 2 ? "reportSelected" : ""}`}
              >
                My Programs
              </button>
              {/* <button onClick={() => setIncubationState(3)} className={`${incubationState === 3 ? "reportSelected" : ""}`} >View Programs </button> */}
              {/* <button onClick={() => setIncubationState(4)} className={`${incubationState === 4 ? "reportSelected" : ""}`} >Announcements </button> */}
            </div>
          ) : null}

          <p className="tab_head">ACCOUNT PAGES</p>
          <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=9`);
              setservicebool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                  </svg>
                </div>
                <p>Profile</p>
              </div>
            }
            {...a11yProps(9)}
          />
          {/* <Tab
            onClick={() => {
              navigate(`/dashboard/${user.type}?tab=10`);
              setservicebool(0);
              setEventBool(0);
              setReportState(false);
              setconnectionState(0);
              setmentorState(0);
            }}
            label={
              <div className="dashboard01_tab">
                <div className="dashboard01_tab_svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-gear-fill"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                  </svg>
                </div>
                <p>Settings</p>
              </div>
            }
            {...a11yProps(10)}
          /> */}
        </Tabs>
        <DashboardNav />
        <TabPanel value={value} className="dashboard01_tabpanel" index={1}>
          <IncHome
            servicebool={setservicebool}
            setconnectionState={setconnectionState}
            setValuationStep={setValuationStep}
            setActiveStep={setActiveStep}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={2}>
          {connectionState === 1 ? (
            <Connections />
          ) : connectionState === 2 ? (
            <MyConnections />
          ) : connectionState === 3 ? (
            <Requests />
          ) : null}
        </TabPanel>

        <TabPanel
          value={value}
          className="dashboard01_tabpanel"
          index={3}
        ></TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={3}>
          <Messages />
        </TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={4}>
          <Valuation
            valuationStep={valuationStep}
            setValuationStep={setValuationStep}
            setActiveStep={setActiveStep}
            ActiveStep={ActiveStep}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={5}>
          {mentorState === 1 ? (
            <Mentorships setmentorState={setmentorState} />
          ) : mentorState === 2 ? (
            <ActiveMentorships />
          ) : null}
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={6}>
          {EventValue === 1 ? (
            <Myevents seteventState={setEventValue} />
          ) : EventValue === 2 ? (
            <EventsForm setEventValue={setEventValue} />
          ) : EventValue === 3 ? (
            <Event />
          ) : null}
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={7}>
          <LandingPage
            setEventActiveStep={setEventActiveStep}
            eventActiveStep={eventActiveStep}
          />
        </TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={8}>
          <IncubationToolSP
            incubationState={incubationState}
            setIncubationState={setIncubationState}
          />
        </TabPanel>

        <TabPanel value={value} className="dashboard01_tabpanel" index={9}>
          <Profile />
        </TabPanel>
        <TabPanel
          value={value}
          className="dashboard01_tabpanel"
          index={10}
        ></TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
          <Messages />
        </TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={1651}>
          <ConnectionProfile setconnectionState={setconnectionState} />
        </TabPanel>
        <TabPanel value={value} className="dashboard01_tabpanel" index={1920}>
          <Feed />
        </TabPanel>
        {/* <TabPanel value={value} className="dashboard01_tabpanel" index={10}>
        <Myevents />
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
        <EventsForm />
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={12}>
        <Events />
      </TabPanel>
      <TabPanel
        value={value}
        className="dashboard01_tabpanel"
        index={13}
      ></TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={23}>
        <MyIncubatorPrograms />
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
         <CreateProgram  /> 
      </TabPanel>
      <TabPanel value={value} className="dashboard01_tabpanel" index={30}>
      </TabPanel> */}
      </Box>
    );
  }
}
