import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../../Utils/Auth";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Rating } from "@mui/material";
import Feedback from "./FeedBack";

const index = ({
  setstep,
  // id,
  mentorid,
  fetchInvites,
  fetchactivementors,
  // setmentorState,
  programid,
}) => {
  const [profile, setprofile] = useState(null);
  const [check, setcheck] = useState(null);
  const { setSessionExpire } = useAuth();
  const [ratingAvg, setRatingAvg] = useState(0);
  const [paticipantFeed, setParticipantFeed] = useState(null);
  const [length, setLength] = useState(0);

  const [open, setopen] = React.useState(false);
  const handleOpen = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  const fetchmentor = () => {
    Axios({
      url: `/api/sp/spbyid/${mentorid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setprofile(data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const checkrequest = async () => {
    const checkmentor = await Axios.post(`/api/incsp/checkmentor/${mentorid}`, {
      programid: programid,
    });

    setcheck(checkmentor.data.data);
  };
  const getFeedback = async () => {
    console.log('h1')
    await Axios({
      url: `/api/sp-rating/sp/rating/program/${programid}/${mentorid}`,
      method: "get",
    })
      .then((res) => {
        setParticipantFeed(res.data.data);
        const {data} = res.data;
        var quality_of_service = data.quality_of_service;
        var price_range = data.price_range;
        var overall_experience = data.overall_experience;
        var support = data.support;

        var quality_of_serviceSum =
          quality_of_service.one * 1 +
          quality_of_service.two * 2 +
          quality_of_service.three * 3 +
          quality_of_service.four * 4 +
          quality_of_service.five * 5;
        var price_rangeSum =
          price_range.one * 1 +
          price_range.two * 2 +
          price_range.three * 3 +
          price_range.four * 4 +
          price_range.five * 5;
        var overall_experienceSum =
          overall_experience.one * 1 +
          overall_experience.two * 2 +
          overall_experience.three * 3 +
          overall_experience.four * 4 +
          overall_experience.five * 5;
        var supportSum =
          support.one * 1 +
          support.two * 2 +
          support.three * 3 +
          support.four * 4 +
          support.five * 5;

        var quality_of_serviceAvg =
          quality_of_serviceSum /
          (quality_of_service.one +
            quality_of_service.two +
            quality_of_service.three +
            quality_of_service.four +
            quality_of_service.five);

        var price_rangeAvg =
          price_rangeSum /
          (price_range.one +
            price_range.two +
            price_range.three +
            price_range.four +
            price_range.five);

        var overall_experienceAvg =
          overall_experienceSum /
          (overall_experience.one +
            overall_experience.two +
            overall_experience.three +
            overall_experience.four +
            overall_experience.five);

        var supportAvg =
          supportSum /
          (support.one +
            support.two +
            support.three +
            support.four +
            support.five);
        setLength(
          support.one +
          support.two +
          support.three +
          support.four +
          support.five
        );
        var avg =
          (quality_of_serviceAvg +
            price_rangeAvg +
            overall_experienceAvg +
            supportAvg) /
          4;
        setRatingAvg(avg);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  // useEffect(() => {
  //   getFeedback()
  // }, []);

  useEffect(() => {
    getFeedback();
    fetchmentor();
    checkrequest();
  }, []);

  if (profile === null) return <p>loading...</p>;

  const sendRequest = async () => {
    await Axios({
      url: `/api/incsp/request/${mentorid}`,
      method: "post",
      data: {
        programid: programid,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Join request sent");
          checkrequest();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const acceptRequest = async () => {
    await Axios({
      url: `/api/incsp/accept/${check._id}`,
      method: "put",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Application accepted");
          checkrequest();
          fetchInvites();
          fetchactivementors();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  return (
    <>
      <div>
        <div
          className="back_CTA pointer"
          onClick={() => {
            setstep(0);
            // history(`/dashboard/${user.type}?tab=2`);
          }}
        >
          <p className="caption color_light_grey">
            <span>
              <KeyboardArrowLeft />
            </span>
            <span>BACK</span>
          </p>
        </div>
      </div>
      <hr />
      <>
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 mb-24 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.logo}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.companyName}</p>
                    <p className="bold">Service Provider | {profile.location.country}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right">
                  {ratingAvg > 0 ? (
                    <div className="participant_profile_right_rating_root">
                      <div className="participant_profile_right_rating_main">
                        <h5 className="color_dark_black2">{ratingAvg}</h5>
                        <div className="incubator_aly_rating__">
                          <Rating
                            name="read-only"
                            value={ratingAvg}
                            readOnly
                            className="incubator_aly_rating_read"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.description}</p>
              </div>

              <div className="row mx-2 my-3">
                <p className="p_grey_chip">{profile.industry} </p>
              </div>
              <hr />

              <div className="">
                {profile.founder ? (
                  <>
                    <p className="p_spartan_20 mx-2 my-4">Founder Details</p>
                    <div className="row mx-0 my-4 align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={profile.profilePic}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                        <p className="">
                          {profile.founder.firstname} {profile.founder.lastname}
                        </p>
                        <p className="">{profile.contact.jobTitle} </p>
                        <p className="bold">
                          <a href={`${profile.socialConnects.linkedin}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="partipant_profile_main_review_main mt-40">
                <div className="partipant_profile_main_review_head mb-24">
                  <p className="title">Ratings</p>
                  <button
                    className="primaryFilled_activeCta"
                    onClick={handleOpen}
                  >
                    REVIEW
                  </button>
                </div>
                <div className="incubator_analytics_rating_progress w_100">
                  <div className="incubator_analytics_rating_proQuality_main mb-16">
                    <p className="overline">Quality of Service</p>
                    <div className="incubator_analytics_rating_progress_main">
                      <div
                        className="incubator_analytics_rating_pr"
                        style={{
                          width: `${paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.quality_of_service
                            }%`,
                        }}
                      >
                        {paticipantFeed ===
                          null ? null : paticipantFeed.percent.quality_of_service >=
                            87 ? (
                          <p className="overline color_dark_grey2 pr-16 ml-8">
                            {length} members
                          </p>
                        ) : null}
                      </div>
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.quality_of_service < 87 ? (
                          <p className="overline color_dark_grey2 ml-8">
                            {length} members
                          </p>
                        ) : null}
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_proQuality_main mb-16">
                    <p className="overline">Price Range</p>
                    <div className="incubator_analytics_rating_progress_main">
                      <div
                        className="incubator_analytics_rating_pr"
                        style={{
                          width: `${paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.price_range
                            }%`,
                        }}
                      >
                        {paticipantFeed ===
                          null ? null : paticipantFeed.percent.price_range >=
                            87 ? (
                          <p className="overline color_dark_grey2 pr-16 ml-8">
                            {length} members
                          </p>
                        ) : null}
                      </div>
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.price_range <
                          87 ? (
                        <p className="overline color_dark_grey2 ml-8">
                          {length} members
                        </p>
                      ) : null}
                      {/* <p className="overline color_dark_grey2 ml-8">{length} members</p> */}
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_proQuality_main mb-16">
                    <p className="overline">support</p>
                    <div className="incubator_analytics_rating_progress_main">
                      <div
                        className="incubator_analytics_rating_pr"
                        style={{
                          width: `${paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.support
                            }%`,
                        }}
                      >
                        {paticipantFeed ===
                          null ? null : paticipantFeed.percent.support >=
                            87 ? (
                          <p className="overline color_dark_grey2 ml-8 pr-16">
                            {length} members
                          </p>
                        ) : null}
                      </div>
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.support <
                          87 ? (
                        <p className="overline color_dark_grey2 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="incubator_analytics_rating_proQuality_main">
                    <p className="overline">Overall Experience</p>
                    <div className="incubator_analytics_rating_progress_main">
                      <div
                        className="incubator_analytics_rating_pr"
                        style={{
                          width: `${paticipantFeed === null
                            ? 0
                            : paticipantFeed.percent.overall_experience
                            }%`,
                        }}
                      >
                        {paticipantFeed ===
                          null ? null : paticipantFeed.percent.overall_experience >=
                            87 ? (
                          <p className="overline color_light_grey ml-8 pr-16">
                            {length} members
                          </p>
                        ) : null}
                      </div>
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.overall_experience <
                          87 ? (
                        <p className="overline color_dark_grey2 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  {profile.valuation && (
                    <tr>
                      <td className="td_left">Valuation</td>
                      <td className="td_right">$ {profile.valuation}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="td_left">Audience</td>
                    <td className="td_right">{profile.audience}</td>
                  </tr>

                  <tr>
                    <td className="td_left">Employees</td>
                    <td className="td_right">{profile.employees}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Funding Series</td>
                    <td className="td_right">{profile.fundingSeries}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Incorporation</td>
                    <td className="td_right">
                      {new Date(profile.incorporationDate).getFullYear()}
                    </td>
                  </tr>

                  <tr>
                    <td className="td_left">Website</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`${profile.website}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile.website}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      <div>
        {check === null ? (
          <>
            <button
              type="button"
              onClick={() => sendRequest()}
              className="gradient_CTA width_fit"
            >
              REQUEST TO JOIN
            </button>
          </>
        ) : check !== null &&
          !check.is_accepted &&
          check.requested_from === "serviceprovider" ? (
          <>
            <button
              type="button"
              onClick={() => acceptRequest()}
              className="gradient_CTA width_fit"
            >
              ACCEPT REQUEST
            </button>
          </>
        ) : check !== null && check.is_accepted ? (
          <></>
        ) : (
          <>
            <button type="button" disabled className="gradient_CTA width_fit">
              PENDING
            </button>
          </>
        )}
      </div>
      <Feedback
        open={open}
        handleClose={handleClose}
        mentor={profile}
        getFeedback={getFeedback}
      />
    </>
  );
};

export default index;
