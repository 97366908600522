import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const createEvent = ({
  date,
  programid,
  geteventsbydate,
  geteventsByMonth,
}) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [EventTime, setEventTime] = useState();
  const [color, setColor] = useState("");
  const [active, setActive] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (EventTime === null) return toast.error("Enter valid input time");
    try {
      await Axios({
        method: "post",
        url: `/api/incubatorProgram/event/create/${programid}`,
        data: {
          title,
          description: message,
          time: EventTime,
          date,
          color,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Program created");
            // console.log(color);
            geteventsbydate(date);
            geteventsByMonth();
            handleClose();
          }
        })
        .catch((err) => {
          return toast.error(err.response.data.error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <AddCircleIcon
          onClick={handleOpen}
          fontSize="large"
          sx={{ color: "#F48361", cursor: "pointer" }}
        />
      </div>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <h5 id="child-modal-title" style={{ color: "#262C5B" }}>
            Add Event
          </h5>
          <CancelIcon
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="register_page_form row">
              <div className="col-12">
                <label>Title</label>
                <input
                  placeholder="Enter Title"
                  required
                  type="text"
                  className="auth_input"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label>Message (Optional)</label>
                <textarea
                  name="msg"
                  className="auth_input auth_textarea"
                  placeholder="Write a message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="col-12 col-md-6">
                <label>Date</label>
                <input
                  required
                  type="text"
                  className="auth_input"
                  disabled
                  value={date.toDateString()}
                />
              </div>
              <div className="col-12 col-md-6">
                <label style={{ marginBottom: "20px" }}>Time</label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    value={EventTime}
                    onChange={(v) => {
                      setEventTime(v);
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-12">
                <label>Colours</label>
                <div className="d-flex">
                  <span
                    className="color-tab"
                    onClick={() => {
                      setColor("#DADADA");
                      setActive(1);
                    }}
                    style={{
                      backgroundColor: "#DADADA",
                      border: active === 1 ? "1px solid black" : "none",
                    }}
                  ></span>
                  <span
                    className="color-tab"
                    onClick={() => {
                      setColor("#FFA6B3");
                      setActive(2);
                    }}
                    style={{
                      backgroundColor: "#FFA6B3",
                      border: active === 2 ? "1px solid black" : "none",
                    }}
                  ></span>
                  <span
                    className="color-tab"
                    onClick={() => {
                      setColor("#262C5B");
                      setActive(3);
                    }}
                    style={{
                      backgroundColor: "#262C5B",
                      border: active === 3 ? "1px solid yellow" : "none",
                    }}
                  ></span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5">
              <button type="submit" className="gradient_CTA">
                &nbsp; &nbsp; Save &nbsp; &nbsp;{" "}
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default createEvent;
