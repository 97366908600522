import React, { useState, useEffect } from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Allreq from "./AllReq/Allreq"
import Declined from "./Declined/Declined"
import ViewReq from './ViewReq';
import './realestate.css'
import Query from '../../../../Utils/Query/Query';

const RealEstateReq = ({ setCoWorkingBool }) => {
  const [value, setValue] = useState("1");
  const [viewEstate, setViewEstate] = useState(0)
  const query = Query()
  const estateId = query.get('estate')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (estateId) {
      setViewEstate(1)
    }
  }, [estateId])

  return (
    <>
      {
        viewEstate === 0 ?
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="All Requests" value="1" />
                  <Tab label="declined" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Allreq
                  setViewEstate={setViewEstate}
                  viewEstate={viewEstate}
                />
              </TabPanel>
              <TabPanel value="2">
                <Declined
                  setViewEstate={setViewEstate}
                  viewEstate={viewEstate}
                />
              </TabPanel>
            </TabContext>
          </Box>
          : <ViewReq
            viewEstate={viewEstate}
            setViewEstate={setViewEstate}
            setCoWorkingBool={setCoWorkingBool}
          />
      }
    </>
  )
}

export default RealEstateReq