import React, { useState, useEffect } from "react";
import "./feed.css";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
// import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { ChevronRight } from '@mui/icons-material';

import getDiff from "../../../Utils/diffleft/getDiff";
import Allpost from "./AllPost/Allpost";
import MyPost from "./Mypost/MyPost";

export default function Feed() {
    // const navigate = useNavigate();
    const [myposts, setMyPosts] = useState([]);
    

    // const [desc,setDesc ] = useState("");
    const { setSessionExpire} = useAuth();
    // const history = useHistory()
    const [myPostState, setMyPostState] = useState(1)
    

    const getMyPosts = async () => {
        await Axios({
            url: "/api/post/myPosts",
            method: "get",
        })
            .then((res) => {
                setMyPosts(res.data.data)
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                }
            });
    };


    

    useEffect(() => {
        // getPosts();
        getMyPosts()
    }, []);

    return (
        <div className="dashboard01_home">
            <div className="row no-margin">
                {
                    myPostState === 1 ? <Allpost
                    /> : <MyPost
                    setMyPostState={setMyPostState}
                    />
                }


                <div className="col-lg-4 post_left_side">
                    {/* <div className="post_advertisment_root">
                        <p className="overline">Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias in a fugiat eius voluptates officia recusandae. Praesentium quo dolorem laudantium.</p>
                        <div className="post_advertisment_tag">
                            <p>AD</p>
                        </div>
                    </div> */}
                    {/* <div className="post_recommandation_root mt-24">
                        <div className="post_recommandation_head">
                            <p className="subtitle">Recommended Connections</p>
                        </div>
                        <div className="post_recomandation_body">
                            {
                                [1, 2, 3, 4].map((data, i) => <div className="post_recomandations" key={i}>
                                    <div className="post_recomandations_left">
                                        <img src="https://picsum.photos/33" alt="post img" />
                                        <div className="post_recomandations_left_info ml-8">
                                            <p className="body2 color_dark_black">Company name</p>
                                            <p className="overline color_dark_grey2">Startup</p>
                                        </div>
                                    </div>
                                    <div className="post_recomandations_right">
                                        <p className="overline color_dark_grey point">See profile</p>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div> */}
                    {
                        myposts && myposts.length > 0 ?
                            myPostState === 1 ? <div className="post_recommandation_root">
                                <div className="post_recommandation_head d-flex justify-content-between align-items-center">
                                    <p className="subtitle">My posts</p>
                                    <span className="point"
                                        onClick={() => setMyPostState(2)}
                                    ><ChevronRight
                                            style={{
                                                fill: "#404654"
                                            }}
                                        /></span>
                                </div>
                                <div className="post_recomandation_body">
                                    {
                                        myposts.slice(0, 5).map((post, i) => {
                                            return <>
                                                {
                                                    post.byUser ? <div className="post_recomandations" key={i}>
                                                        <div className="post_myPost_left">
                                                            <img src={post.byUser.avatar} alt="post img" />
                                                            <div className="post_recomandations_left_info ml-8">
                                                                <p className="body2 color_dark_black">{post.byUser.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="post_recomandations_right">
                                                            <p className="overline color_dark_grey point">{getDiff(post.createdAt)}</p>
                                                        </div>
                                                    </div> : null
                                                }
                                            </>
                                        })
                                    }
                                </div>
                            </div> : null
                            : null
                    }
                </div>
            </div>


        </div>
    );
}
