import React, { useEffect } from "react";
import MyApplicatins from "./MyProgram/MyApplicatins";
import Explore from "./Explore/Explore";
import Query from "../../../Utils/Query/Query";
import ViewProgram from "./ViewProgram/ViewProgram";

const IncubationTool = (props) => {
  const query = Query();
  const Id = query.get("id");
  const myprogram = query.get("myprogram");
  const { incubationState, setIncubationState } = props;

useEffect(()=>{
  if(Id!=null){
    if(myprogram!=null && myprogram=="true"){
      setIncubationState(2)
    }else{
      setIncubationState(3)
    }
  }
})

  return (
    <div className="startUp_incubation_tool_root">
      {incubationState === 1 ? (
        <Explore setIncubationState={setIncubationState} />
      ) : incubationState === 2 ? (
        <MyApplicatins setIncubationState={setIncubationState} />
      ) : incubationState === 3 ? (
        <ViewProgram setIncubationState={setIncubationState} />
      ) : (
        <Explore setIncubationState={setIncubationState} />
      )}
    </div>
  );
};

export default IncubationTool;
