import React, { useState, useEffect } from "react";
import Myevent from "./myevent";
import { useLocation } from "react-router-dom";  //  useNavigate,
import host_img from "./../../../Assets/images/events/host.png";
import CircularProgress from '@mui/material/CircularProgress';
import Axios from "axios";
import Event from "./../event";
// import {useAuth} from "./../../../Utils/Auth";
import "./index.css"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Index = ({seteventState}) => {
  // const navigate = useNavigate();

  // const {user} = useAuth();

  const query = useQuery();
  var eventId = query.get("event");

  const [eDetails, seteDetails] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [isregistred, setisRegistered] = useState(false);
  const [isExpired, setisExpired] = useState(false);
  const [diff, setDiff] = useState("");
  const fetchEvent = async () => {
    setisLoading(true);
    await Axios({
      url: `/api/events/key/${eventId}`,
      method: "get",
    })
      .then((res) => {
        seteDetails(res.data.event);
        setisRegistered(res.data.isRegistered);
        setisExpired(res.data.isExpired);
        setDiff(res.data.diff);
        setisLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // setSessionExpire(true);
          setisLoading(false);
        }
      });
  };
  useEffect(() => {
    if (eventId) {
      fetchEvent();
      var flag=false;
      if(flag)
      console.log(isregistred, isExpired, diff );
    }
  }, [eventId]);



  return (
    <>
    {eventId ? (
          isLoading ? (
            <div className="col-lg-8 loader_root">
              <CircularProgress />
            </div>
          ) : eDetails ? (
            // <EventProfile
            //   event={eDetails}
            //   isregistred={isregistred}
            //   isExpired={isExpired}
            //   diff={diff}  
            //   fetchEvent={fetchEvent}
            // />
            <>
            <Event eventId={eventId} />
            </>
          ) : (
            <p>Event not found</p>
          )
        ) : (
      <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12 col-md-4 mb-24">
              <div className="h-100">
          <button className="host-btn event-host-btn" onClick={() =>{seteventState(2)}} type="button">
            <img src={host_img} alt="icon" />
            <br />
            Host Event
          </button>
          </div>
          </div>
          <Myevent />
          
          </div>
      </div>
        )}
    </>
  );
};

export default Index;
