import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";

import "./index.css";
import Card from "./Card";

const index = (id) => {
  const { setSessionExpire } = useAuth();
  // const navigate = useNavigate();
  const [programs, setprograms] = useState([]);
  // const [step, setstep] = useState(0);

  const fetchprograms = async () => {
    await Axios({
      url: `/api/incubatorProgram/programs/user/${id.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setprograms(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchprograms();
  }, []);
  return (
    <div className="incubation_program_root">
      <div className="incubation_prorgram_search_head"></div>
      <div className="incubation_program_main mt-4">
        <div className="row">
          {programs && programs.length > 0
            ? programs.map((program, i) => <Card key={i} program={program} />)
            : null}
        </div>
      </div>
    </div>
  );
};

export default index;
