import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../../../../Utils/Auth";
import Rating from "@mui/material/Rating";
import { BarChart, Bar, ResponsiveContainer, XAxis } from "recharts";

const StartupRightScreen = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const courseid = query.get("course");
  const moduleid = query.get("module");
  const { setSessionExpire } = useAuth();

  const [analytics, setAnalytics] = useState([]);
  const [average, setAverage] = useState(0);
  const [contendPercentage, setContentPercentage] = useState(0);
  const [instructorPercentage, setinstructorPercentage] = useState(0);
  const [experiencePercentage, setExperiencePercentage] = useState(0);
  const [length, setLength] = useState(0);
  // const demoUrl = 'https://codesandbox.io/s/tiny-bar-chart-35meb';

  const [fiverate, setFiveRate] = useState(0);
  const [fourrate, setfourRate] = useState(0);
  const [threerate, setthreeRate] = useState(0);
  const [tworate, setTwoRate] = useState(0);
  const [onerate, setoneRate] = useState(0);

  const [fiveUser, setfiveUser] = useState(0);
  const [fouruser, setfouruser] = useState(0);
  const [threeuser, setthreeuser] = useState(0);
  const [twouser, settwouser] = useState(0);
  const [oneuser, setoneuser] = useState(0);
  const [courseAvg, setCourseAvg] = useState(0);
  const [courseBarData, setCourseBarData] = useState([]);

  const [log, setLog] = useState(null);

  const getAnalytics = async () => {
    await Axios({
      url: `/api/mentorModules/analytics/${courseid}/${moduleid}`,
      method: "GET",
    })
      .then((res) => {
        setAnalytics(res.data.data);
        var contentRating = 0;
        var contentRatingSum = 0;
        var experience_rating = 0;
        var experience_ratingSum = 0;
        var instructRating = 0;
        var instructRatingSum = 0;
        var ContPer = 0;
        var InsPer = 0;
        var ExpPer = 0;
        setLength(res.data.data.length);
        for (let i = 0; i < res.data.data.length; i++) {
          const element = res.data.data[i];
          contentRatingSum += element.content_rating;
          experience_ratingSum += element.experience_rating;
          instructRatingSum += element.instructor_rating;
        }
        contentRating = contentRatingSum / res.data.data.length;
        experience_rating = experience_ratingSum / res.data.data.length;
        instructRating = instructRatingSum / res.data.data.length;
        setAverage(
          res.data.data.length > 0
            ? (
                (contentRating + experience_rating + instructRating) /
                3
              ).toFixed(1)
            : 0
        );
        ContPer = (contentRatingSum * 100) / (res.data.data.length * 5);
        InsPer = (instructRatingSum * 100) / (res.data.data.length * 5);
        ExpPer = (experience_ratingSum * 100) / (res.data.data.length * 5);
        setContentPercentage(res.data.data.length > 0 ? ContPer : 0);
        setinstructorPercentage(res.data.data.length > 0 ? InsPer : 0);
        setExperiencePercentage(res.data.data.length > 0 ? ExpPer : 0);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const getCourseFeedback = async () => {
    await Axios({
      url: `/api/mentorModules/modules/feedback/${courseid}`,
      method: "GET",
    })
      .then((res) => {
        const { data, log } = res.data;
        setCourseBarData(data);
        setLog(log);
        var five = 0;
        var four = 0;
        var three = 0;
        var two = 0;
        var one = 0;
        var total = 0;
        var totalAvgSum = 0;
        var totalAvg = 0;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          five +=
            element.feedback !== null
              ? element.feedback.content_rating.five +
                element.feedback.experience_rating.five +
                element.feedback.instructor_rating.five
              : 0;
          four +=
            element.feedback !== null
              ? element.feedback.content_rating.four +
                element.feedback.experience_rating.four +
                element.feedback.instructor_rating.four
              : 0;
          three +=
            element.feedback !== null
              ? element.feedback.content_rating.three +
                element.feedback.experience_rating.three +
                element.feedback.instructor_rating.three
              : 0;
          two +=
            element.feedback !== null
              ? element.feedback.content_rating.two +
                element.feedback.experience_rating.two +
                element.feedback.instructor_rating.two
              : 0;
          one +=
            element.feedback !== null
              ? element.feedback.content_rating.one +
                element.feedback.experience_rating.one +
                element.feedback.instructor_rating.one
              : 0;

          totalAvgSum +=
            (element.content_average +
              element.experience_average +
              element.instructor_average) /
            3;
        }
        total = one + two + three + four + five;
        totalAvg = (totalAvgSum / data.length).toFixed(1);
        setCourseAvg(totalAvg);

        setFiveRate(total === 0 ? 0 : (five * 100) / total);
        setfourRate(total === 0 ? 0 : (four * 100) / total);
        setthreeRate(total === 0 ? 0 : (three * 100) / total);
        setTwoRate(total === 0 ? 0 : (two * 100) / total);
        setoneRate(total === 0 ? 0 : (one * 100) / total);

        setfiveUser(five);
        setfouruser(four);
        setthreeuser(three);
        settwouser(two);
        setoneuser(one);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getCourseFeedback();
    // getEnrolledUser()
    if (moduleid) {
      getAnalytics();
    }
  }, [moduleid, courseid]);

  return (
    <div className="incubator_analytics_rating_info_root">
      {moduleid == 0 ? (
        <>
          <div className="incubator_analytic_course_root">
            <div className="row">
              <div className="col-lg-6">
                <div className="incubator_analytic_course_overview_root">
                  <div className="incubator_analytic_course_overview_head mb-24">
                    <p className="subtitle">Overview</p>
                  </div>
                  <div className="incubator_analytic_course_overview_body">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart width={100} height={100} data={courseBarData}>
                        <Bar dataKey={`average`} fill="#F2EFFF" barSize={22} />
                        {/* <Tooltip wrapperStyle={{ width: 50, backgroundColor: '#262C5B', boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)", borderRadius: 4 }} /> */}
                        {/* <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} /> */}
                        {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
                        <XAxis
                          dataKey="title"
                          stroke="#565656"
                          className="incubator_bar_xaxis"
                        />
                        {/* <YAxis /> */}

                        {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}
                        {/* <Bar dataKey="uv" fill="#8884d8" barSize={30} /> */}
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="incubator_analytic_course_star_root">
                  <div className="incubator_analytic_course_star_head">
                    <h2 className="myh2">{courseAvg}</h2>
                    <Rating
                      name="read-only"
                      value={courseAvg}
                      readOnly
                      className="incubator_analytic_course_stars"
                    />
                  </div>
                  <div className="w_100">
                    <div className="incubator_analytics_rating_proQuality_main mb-24">
                      <p className="body2">5 star</p>
                      <div className="incubator_analytic_course_star_progress">
                        <div
                          className="incubator_analytics_rating_pr"
                          style={{ width: `${fiverate}%` }}
                        >
                          {fiverate >= 75 ? (
                            <p className="overline color_dark_grey2 pr-8">
                              {fiveUser} members
                            </p>
                          ) : null}
                        </div>
                        {fiverate < 75 ? (
                          <p className="overline color_dark_grey2 ml-8">
                            {fiveUser} members
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_proQuality_main mb-24">
                      <p className="body2">4 star</p>
                      <div className="incubator_analytic_course_star_progress">
                        <div
                          className="incubator_analytics_rating_pr"
                          style={{ width: `${fourrate}%` }}
                        >
                          {fourrate >= 75 ? (
                            <p className="overline color_dark_grey2 pr-8">
                              {fouruser} members
                            </p>
                          ) : null}
                        </div>
                        {fourrate < 75 ? (
                          <p className="overline color_dark_grey2 ml-8">
                            {fouruser} members
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_proQuality_main mb-24">
                      <p className="body2">3 star</p>
                      <div className="incubator_analytic_course_star_progress">
                        <div
                          className="incubator_analytics_rating_pr"
                          style={{ width: `${threerate}%` }}
                        >
                          {threerate >= 75 ? (
                            <p className="overline color_dark_grey2 pr-8">
                              {threeuser} members
                            </p>
                          ) : null}
                        </div>
                        {threerate < 75 ? (
                          <p className="overline color_dark_grey2 ml-8">
                            {threeuser} members
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_proQuality_main mb-24">
                      <p className="body2">2 star</p>
                      <div className="incubator_analytic_course_star_progress">
                        <div
                          className="incubator_analytics_rating_pr"
                          style={{ width: `${tworate}%` }}
                        >
                          {tworate >= 75 ? (
                            <p className="overline color_dark_grey2 pr-8">
                              {twouser} members
                            </p>
                          ) : null}
                        </div>
                        {tworate < 75 ? (
                          <p className="overline color_dark_grey2 ml-8">
                            {twouser} members
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="incubator_analytics_rating_proQuality_main mb-24">
                      <p className="body2">1 star</p>
                      <div className="incubator_analytic_course_star_progress">
                        <div
                          className="incubator_analytics_rating_pr"
                          style={{ width: `${onerate}%` }}
                        >
                          {onerate >= 75 ? (
                            <p className="overline color_dark_grey2 pr-8">
                              {oneuser} members
                            </p>
                          ) : null}
                        </div>
                        {onerate < 75 ? (
                          <p className="overline color_dark_grey2 ml-8">
                            {oneuser} members
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {log !== null ? (
              <div className="row mt-24">
                <div className="col-lg-4">
                  <div className="incubator_analytic_course_start_assignmentRoot">
                    <p className="subtitle pb-8 color_dark_black2">
                      Assessment
                    </p>
                    <h4 className="pb-8" style={{ color: "#580167" }}>
                      {log.assessment_total > 9
                        ? log.assessment_total
                        : `0${log.assessment_total}`}
                    </h4>
                    <p className="body2 color_dark_black2">
                      {log.assessment_completed > 9
                        ? log.assessment_completed
                        : `0${log.assessment_completed}`}{" "}
                      startups completed
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="incubator_analytic_course_start_assignmentRoot">
                    <p className="subtitle pb-8 color_dark_black2">
                      Assignment
                    </p>
                    <h4 className="pb-8" style={{ color: "#FF6F1E" }}>
                      {log.assignment_total > 9
                        ? log.assignment_total
                        : `0${log.assignment_total}`}
                    </h4>
                    <p className="body2 color_dark_black2">
                      {" "}
                      {log.assignment_completed > 9
                        ? log.assignment_completed
                        : `0${log.assignment_completed}`}{" "}
                      startups completed
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="incubator_analytic_course_start_assignmentRoot">
                    <p className="subtitle pb-8 color_dark_black2">Sessions</p>
                    <h4 className="pb-8 color_dark_blue">
                      {log.session_total > 9
                        ? log.session_total
                        : `0${log.session_total}`}
                    </h4>
                    <p className="body2 color_dark_black2">
                      {" "}
                      {log.session_completed > 9
                        ? log.session_completed
                        : `0${log.session_completed}`}{" "}
                      startups completed
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className="incubator_analytics_rating_maininfo">
            <div className="incubator_analytics_rating_progress">
              <div className="incubator_analytics_rating_proQuality_main mb-16">
                <p className="overline">Quality of content</p>
                <div className="incubator_analytics_rating_progress_main">
                  <div
                    className="incubator_analytics_rating_pr"
                    style={{ width: `${contendPercentage}%` }}
                  ></div>
                  <p className="overline color_light_grey ml-8">
                    {length} members
                  </p>
                </div>
              </div>
              <div className="incubator_analytics_rating_proQuality_main mb-16">
                <p className="overline">Efficiency of instructor</p>
                <div className="incubator_analytics_rating_progress_main">
                  <div
                    className="incubator_analytics_rating_pr"
                    style={{ width: `${instructorPercentage}%` }}
                  ></div>
                  <p className="overline color_light_grey ml-8">
                    {length} members
                  </p>
                </div>
              </div>
              <div className="incubator_analytics_rating_proQuality_main">
                <p className="overline">Overall Experience</p>
                <div className="incubator_analytics_rating_progress_main">
                  <div
                    className="incubator_analytics_rating_pr"
                    style={{ width: `${experiencePercentage}%` }}
                  ></div>
                  <p className="overline color_light_grey ml-8">
                    {length} members
                  </p>
                </div>
              </div>
            </div>
            <div className="incubator_analytics_rating_overall">
              <div className="incubator_aly_rating_overall_main">
                <h4>{average}</h4>
                <div className="incubator_aly_rating__">
                  <Rating
                    name="read-only"
                    value={average}
                    readOnly
                    className="incubator_aly_rating_read"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="incubator_analytics_rating_info_main">
            <table className="incubator_analytics_rating_table">
              <thead className="incubator_analytics_rating_thead">
                <tr className="incubator_analytics_rating_thead_tr">
                  <th className="caption">Startups</th>
                  <th className="caption">Quality of content</th>
                  <th className="caption">Efficiency of instructor</th>
                  <th className="caption">Overall Experience</th>
                </tr>
              </thead>
              <tbody className="incubator_analytics_rating_tbody">
                {analytics.length > 0
                  ? analytics.map((info, i) => (
                      <tr
                        className="incubator_analytics_rating_tbody_tr"
                        key={i}
                      >
                        <td className="incubator_analytics_rating_tbody_td_img">
                          <img src={info.user.avatar} alt="" />
                          <p className="subtitle color_dark_blue">
                            {info.user.firstname} {info.user.lastname}
                          </p>
                        </td>
                        <td className="body2 color_dark_blue">
                          {info.content_rating}/5
                        </td>
                        <td>{info.instructor_rating}/5</td>
                        <td>{info.experience_rating}/5</td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default StartupRightScreen;
