import React, { useEffect, useState } from "react";
// import profile_inc from "../../../../Assets/images/incubatorDash/profile_inc.png"
import { Avatar } from "@mui/material";
import {
  CalendarToday,
  Money,
  AccessTime,
  KeyboardBackspace,
} from "@mui/icons-material";
import "./viewProgram.css";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader";

const ViewProgram = ({ setIncubationState }) => {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [program, setProgram] = useState(null);
  const { setSessionExpire } = useAuth();

  const [showLoader, setShowLoader] = useState(true);
  // const [onApply, setOnApply] = useState(false);

  const [monthDiff, setMonthDiff] = useState("");
  const [check, setcheck] = useState(null);
  // const [isexpired, setisexpired] = useState(null);

  const fetchprogram = () => {
    setShowLoader(true);
    Axios({
      url: `/api/incubatorProgram/program/${id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setProgram(res.data.data);

          setMonthDiff(res.data.monthDif);

          // setisexpired(res.data.isExpired);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const sendRequest = async () => {
    await Axios({
      url: `/api/incsp/request/${program.id._id}`,
      method: "post",
      data: {
        programid: id,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Successfully Applied");
          checkrequest();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const checkrequest = async () => {
    const checkmentor = await Axios.post(`/api/incsp/checkmentor/1`, {
      programid: id,
    });

    setcheck(checkmentor.data.data);
  };

  const acceptRequest = async () => {
    await Axios({
      url: `/api/incsp/accept/${check._id}`,
      method: "put",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Application accepted");
          checkrequest();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    fetchprogram();
    checkrequest();
  }, []);

  return (
    <>
      {program === null ? (
        showLoader ? (
          <Loader />
        ) : (
          <>
            <div className="backtoincubation d-flex align-content-center ">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIncubationState(1)}
              >
                <KeyboardBackspace />
              </span>
              <p className="body1 ml-4">Program not found</p>
            </div>
          </>
        )
      ) : (
        <div className="incubation_program_root">
          <div className="incubatrion_profile_program_head">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="incubation_profile__head_card1_root">
                  <div className="incubationprofile_card1_head">
                    <p className="p_grey_chip p_normalBlack10">
                      {program.sector}
                    </p>
                    {/* <button>edit</button> */}
                  </div>
                  <div className="incubationprofile_card1_title">
                    <h4>{program.title}</h4>
                  </div>

                  <div className="incubationprofile_card1_link">
                    <a href={program.website}>{program.website}</a>
                  </div>
                  <div className="incubationprofile_card1_hostedBy mt-4">
                    <p className="p_lightGrey10">Hosted By</p>
                    <div className="incubationprofile_card1_hostedPro">
                      <Avatar
                        src={program.id.avatar}
                        style={{ width: 40, height: 40 }}
                      />
                      <p className="p_white20 ml-3">{program.id.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12">
                <div className="incubation_profile__head_card2_root">
                  <img
                    src={program.coverImage}
                    alt="profile_inc"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="startup_incubation_viewProprgam_root mt-4">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12">
                <div className="startup_incubation_viewProprgam_main dahsboard_card">
                  <div className="startup_incubation_viewProprgam_head">
                    <p className="subtitle">{program.title} </p>
                  </div>
                  <div className="startup_incubation_viewProprgam_desc">
                    <p className="body2">{program.description} </p>
                  </div>
                  <div className="startup_incubation_viewProprgam_highlites">
                    <p className="body1 color_dark_blue">Highlights</p>
                    <div className="startup_incubation_viewProprgam_high_ul">
                      <ul>
                        {program.highlights && program.highlights.length > 0
                          ? program.highlights.map((data, i) => (
                              <li key={i} className="body2 pb-3">
                                {data}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                  <div className="startup_incubation_viewProprgam_benElig">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12 startup_incubation_viewProprgam_benefit">
                        <p className="body1 color_dark_blue">Benefits</p>
                        <ul>
                          {program.benefits && program.benefits.length > 0
                            ? program.benefits.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 startup_incubation_benefit startup_incubationeligibility">
                        <p className="body1 color_dark_blue">Eligibility</p>
                        <ul>
                          {program.eligibility && program.eligibility.length > 0
                            ? program.eligibility.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="startup_incubation_viewProprgam_documents">
                    <p className="body1 color_dark_blue">Documents</p>
                    <div className="startup_incubation_docs">
                      <ul>
                        {program.requiredDocs && program.requiredDocs.length > 0
                          ? program.requiredDocs.map((data, i) => (
                              <li key={i} className="body2 pb-3">
                                <a href={data}> {data}</a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-6 col">
                    <div className="startup_incubation_viewProprgam_infor dahsboard_card">
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center">
                          <span>
                            <CalendarToday />
                          </span>
                          {new Date(program.startDate).toDateString()}
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center">
                          <span>
                            <Money />
                          </span>
                          FREE
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center caps">
                          <span>
                            <AccessTime />
                          </span>
                          {monthDiff === "Live Left" || monthDiff === "Expired"
                            ? "Live"
                            : monthDiff}
                        </p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pb-3">
                        {check === null ? (
                          <>
                            <button
                              type="button"
                              onClick={() => sendRequest()}
                              className="gradient_CTA width_fit"
                            >
                              APPLY AS SERVICE PROVIDER
                            </button>
                          </>
                        ) : check !== null &&
                          !check.is_accepted &&
                          check.requested_from === "incubator" ? (
                          <>
                            <button
                              type="button"
                              onClick={() => acceptRequest()}
                              className="gradient_CTA width_fit"
                            >
                              ACCEPT REQUEST
                            </button>
                          </>
                        ) : check !== null && check.is_accepted ? (
                          <>
                            <button
                              type="button"
                              disabled
                              className="gradient_CTA width_fit"
                            >
                              ALREADY A MEMBER
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              disabled
                              className="gradient_CTA width_fit"
                            >
                              PENDING
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6 col-12 mt-lg-3 mt-md-0 mt-sm-3">
                    <div className="startup_incubation_app_info">
                      <div className="startup_incubation_sDae">
                        <div className="startup_incubation_startDate">
                          <p className="overLine">
                            {new Date(program.startDate).toDateString()}
                          </p>
                        </div>
                        <div className="startup_incubation_dText">
                          <p className="body2">Start Date</p>
                        </div>
                      </div>
                      <span className="startup_incubation_heigth"></span>
                      <div className="startup_incubation_sDae">
                        <div className="startup_incubation_startDate">
                          <p className="overLine">
                            {new Date(program.endDate).toDateString()}
                          </p>
                        </div>
                        <div className="startup_incubation_dText">
                          <p className="body2">End Date</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewProgram;
