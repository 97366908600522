import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "axios";
import { useNavigate } from 'react-router-dom'
import Query from "../../../../../Utils/Query/Query";
import Loader from "../../../../../Components/Loader";

const ViewStudentProfile = ({ setstep }) => {
    const [student, setstudent] = useState(null);
    const { user, setSessionExpire } = useAuth();
    const navigate = useNavigate()
    const query = Query()
    const studentId = query.get('student')
    const [isLoading,setIsLoading] = useState(false)
    const getDetail = async () => {
        setIsLoading(true)
        await Axios({
            method: "get",
            url: `/api/student/student-data/${studentId}`,
        })
            .then((res) => {
                setstudent(res.data.data);
                window.scrollTo(0, 0);
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                    setIsLoading(false)
                }
            });
    };

    useEffect(() => {
        if (studentId) {
            getDetail();
        }
    }, [studentId]);

    return (
        <>
            {
                isLoading ? <Loader /> : <>
                <div className="col-12 participants_profile_head">
                <span
                    className="cta color_light_grey"
                    onClick={() => {
                        setstep(0);
                        navigate(`/dashboard/${user.type}?tab=10&mm=1`)
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-left mx-1"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                    </svg>
                    Back
                </span>
            </div>
             <div className="col-12 row">
                    <div className="col-12">
                        <div
                            className="partipant_profile_main_left"
                            id="connection_profile_root"
                        >
                            <div className="partipant_profile_main_left_head">
                                <div className="dashboard01_connection_profile_head_img">
                                    <img src={student.profile_pic} alt="logo" />
                                    <div className="ml-12">
                                        <p className="subtitle color_dark_blue">
                                            {student.name}
                                        </p>
                                        <p className="caption color_dark_grey2">
                                            {student.college} | {student.course}
                                        </p>
                                        {/* <p className="caption color_dark_grey2">
                                        student | {student.Location.country}
                                    </p> */}
                                    </div>
                                </div>

                            </div>
                            <div className="mt-40">
                                <p className="body2 grey">{student.college}</p>
                            </div>

                            <div className="mt-40">
                                <p className="incubation_chips caption">{student.course} </p>
                            </div>

                        </div>
                    </div>

                </div> 
            
                </>
        }


        </>
    );
}

export default ViewStudentProfile