import axios from 'axios';
import React, { useState,useEffect } from 'react';
import Card from './Card';
import { useAuth } from '../../../../../Utils/Auth';
const Sent = () => {
    const {user, setSessionExpire} = useAuth();
    const [paid, setPaid] = useState([])

    const fetchPaidData = () => {
        axios.get(`/api/transaction/paid/${user.id}`).then(res=>{
            setPaid(res.data.data);
        }).catch(()=>{
            setSessionExpire(true)
        })
    }
    useEffect(()=>{
        fetchPaidData()
    },[])
    return <div className='invoicing_sent_root'>
        <div className="incubator_assessment_tableRoot">
            {
                paid.length > 0 ?
                    <table className="incubator_assessment_table">
                        <thead className="incubator_assessment_thead">
                        <tr className="incubator_assessment_thead_tr">
                                <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                                    <p className="body2 color_dark_grey2">Date</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                                    <p className="body2 color_dark_grey2">Payee</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                    <p className="body2 color_dark_grey2">Amount</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                                    <p className="body2 color_dark_grey2">Program Name</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                    <p className="body2 color_dark_grey2">Status</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                    <p className="body2 color_dark_grey2">Invoice</p>
                                </td>
                                
                            </tr>
                        </thead>
                        <tbody className="incubator_assessment_tbody">
                            {
                                paid && paid.length > 0 ?
                                    paid.map((data, i) => <Card
                                        key={i}
                                        data={data}
                                        setsent={setPaid}
                                    />) : null
                            }
                        </tbody>
                    </table> : <div className="incubator_empty_table mt-24">
                        <p>No Data Found</p>
                    </div>
            }
        </div>
    </div>
}

export default Sent