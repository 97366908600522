import React, { useEffect, useState } from 'react'
import { KeyboardBackspace } from "@mui/icons-material"
import { useAuth } from "../../../../Utils/Auth";
import './form.css'
import { AddBoxRounded } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import Query from "../../../../Utils/Query/Query";
import Axios from 'axios';
import { toast } from 'react-toastify';

const invingForm = ({ setformState, setFinantialBool }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [tools, setTools] = useState("");
    const [contact, setContact] = useState("");
    const [date, setDate] = useState(new Date().toLocaleDateString());
    const [desc, setDesc] = useState("");
    const [taxType, setTaxType] = useState("");
    const [taxValue, setTaxValue] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [discountType, setDiscountType] = useState("");
    const [discountValue, setDiscountValue] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [amount, setAmount] = useState(null)
    const [itemsArr, setItemsArr] = useState([])
    const [booked_byId, setBooked_byId] = useState("")
    const [itemDetails, setItemDetails] = useState({
        name: "",
        quantity: "",
        price: ""
    })
    const navigate = useNavigate()
    const query = Query();
    const isCI = Number(query.get("isCI"));
    const assetId = query.get("asset");
    const estateId = query.get("estate");
    const { setSessionExpire } = useAuth();

    const handleItemDetails = (e) => {
        const { name, value } = e.target;
        setItemDetails((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const getAssetData = async () => {
        await Axios({
            url: `/api/asset/common/cart/${assetId}`,
            method: "GET",
        }).then((res) => {
            const { data } = res.data;
            setName(data.booked_by.name)
            setBooked_byId(data.booked_by._id)
            setEmail(data.booked_by.email)
            setContact(data.start_up.contact.phone)
            setTools("Assets")
            setDesc(data.asset_id.details)
            setItemsArr((prev) => [...prev, {
                name: data.asset_id.asset_name,
                quantity: data.quantity,
                price: data.price
            }])
            if(Number(data.asset_id.discount) !== 0)
            {
                setDiscountType("percent")
                setDiscountValue(data.asset_id.discount)
                let dAmount = Number(data.quantity) * Number(data.price);
                let disVal = (dAmount*Number(data.asset_id.discount))/100;
                setAmount(dAmount-disVal);                
            }
            else{
                setAmount(Number(data.quantity) * Number(data.price))
            }
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true)
            }
        })
    }
    const getEstateData = async () => {
        await Axios({
            url: `/api/asset/common/cart/${estateId}`,
            method: "GET",
        }).then((res) => {
            const { data } = res.data;
            setName(data.booked_by.name)
            setBooked_byId(data.booked_by._id)
            setEmail(data.booked_by.email)
            setContact(data.start_up.contact.phone)
            setTools("Assets")
            setDesc(data.asset_id.details)
            setItemsArr((prev) => [...prev, {
                name: data.asset_id.asset_name,
                quantity: data.quantity,
                price: data.asset_id.price
            }])
            if(Number(data.asset_id.discount) !== 0)
            {
                setDiscountType("percent")
                setDiscountValue(data.asset_id.discount)
                let dAmount = Number(data.quantity) * Number(data.asset_id.price);
                let disVal = (dAmount*Number(data.asset_id.discount))/100;
                setAmount(dAmount-disVal);                
            }
            else{
                setAmount(Number(data.quantity) * Number(data.asset_id.price))
            }
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true)
            }
        })
    }
    const calculateTotalAmount = () => {
        let sum = 0;
        for (let i = 0; i < itemsArr.length; i++) {
            const element = itemsArr[i];
            sum += (element.quantity * element.price)
        }
        let tax = (sum * taxValue) / 100
        setTaxAmount(tax)
        sum = sum + tax;
        let discount = 0;
        if (discountType === "percent") {
            discount = (sum * discountValue) / 100; 
            setDiscountAmount(discount)           
        }
        else if (discountType === "value") {
            setDiscountAmount(discount)           
            discount = discountValue;
        }
        sum = sum - discount;

        setAmount(sum)
    }

    function removeItem(i) {
        const newArr = itemsArr.filter((newData, index) => index !== i)
        setItemsArr(newArr)
        itemsArr.forEach((val, index) => {
            if (i === index) {
                const total = amount - (val.price * val.quantity);
                setAmount(total)
            }
        })
    }
    useEffect(() => {
        calculateTotalAmount()
    }, [itemDetails.price, itemDetails.quantity, taxValue, discountValue, discountType])

    useEffect(() => {
        if (assetId) {
            getAssetData()
        }
    }, [assetId])

    useEffect(() => {
        if (estateId) {
            getEstateData()
        }
    }, [estateId])

    const createInvoice = async (e) => {
        e.preventDefault()
        try {
            await Axios({
                method: "post",
                url: `/api/inc-finance/invoice`,
                data: {
                    for_user: booked_byId,
                    cart_id : assetId ? assetId: estateId,
                    client_name: name,
                    email_id: email,
                    contact_no: contact,
                    tools: tools,
                    date: date,
                    short_description: desc,
                    item_details: itemsArr,
                    tax: taxType,
                    tax_amount: taxAmount,
                    tax_value: taxValue,
                    discount: discountType,
                    discount_amount: discountAmount,
                    discount_value: discountValue,
                    total_amount: amount,
                }
            }).then((res) => {
                if (res.data.success) {
                    if(assetId)
                    {
                        navigate(`/dashboard/incubator?tab=8&cs=6&asset=${assetId}`)
                    }
                    if(estateId)
                    {
                        navigate(`/dashboard/incubator?tab=8&cs=4&estate=${estateId}`)
                    }
                    setFinantialBool(false)
                    toast.success("Invoice is Created")
                }
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true)
                }
            })
        } catch (error) {
            toast.error(error.response.data.error);
            if (error.response.status === 401) {
                setSessionExpire(true);
            }
        }
    }



    return (
        <div className='coworking_addAssets_form_root'>
            <div className="coworking_addAssets_form_head">
                <button className='coworking_back_btn btncta' onClick={() => {
                    if (isCI === 1 && assetId) {
                        navigate(`/dashboard/incubator?tab=8&cs=6&asset=${assetId}`)
                        setFinantialBool(false)
                    }
                    else if (isCI === 2 && estateId) {
                        navigate(`/dashboard/incubator?tab=8&cs=4&estate=${estateId}`)
                        setFinantialBool(false)
                    }
                    else {
                        navigate(`/dashboard/incubator?tab=8&ft=2&in=0`)
                        setformState(0)
                    }

                }}>
                    <span><KeyboardBackspace /></span>
                    <span>BACK</span>
                </button>
            </div>
            <div className="coworking_addAssets_form_main w_100">
                <div className="coworking_addAssets_form_div">
                    <div className="coworking_addAssets_form_main2">
                        <h4>New Invoice</h4>
                    </div>

                    <form className='coworking_addAssets_form'>
                        <div className="row">
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="ClientName" className='caption color_dark_blue'>Client Name</label>
                                    <input
                                        type="text"
                                        placeholder='Enter Client Name'
                                        className='body1'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Email Id</label>
                                            <input
                                                type="text"
                                                placeholder='Enter Email Id'
                                                className='body1'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Tools" className='caption color_dark_blue'>Tools</label>
                                            <select name="Tools" id="Tools" value={tools} onChange={(e) => setTools(e.target.value)} className='body1'>
                                                <option value="" className='body1'>Select tools</option>
                                                <option value="Events" className='body1'>Events</option>
                                                <option value="Assets" className='body1'>Assets</option>
                                                <option value="Real Estate" className='body1'>Real Estate</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="ContactNo" className='caption color_dark_blue'>Contact No.</label>
                                            <input
                                                type="text"
                                                placeholder='Enter Contact no.'
                                                className='body1'
                                                value={contact}
                                                onChange={(e) => setContact(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="ShortDescription" className='caption color_dark_blue'>Date</label>
                                            <input
                                                type="date"
                                                className='body1'
                                                value={date}
                                                // value="2019-12-07"
                                                onChange={(e) => setDate(e.target.value)}
                                                defaultValue={new Date().toLocaleDateString()}
                                                // defaultValue={`${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getMonth()+1}`}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="ShortDescription" className='caption color_dark_blue'>Short Description</label>
                                    <input
                                        type="text"
                                        placeholder='Write a short description'
                                        className='body1'
                                        value={desc}
                                        onChange={(e) => setDesc(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="ShortDescription" className='caption color_dark_blue'>Item Details</label>
                                <div className="row">
                                    <div className="col-lg-8 col-md-6 col-6">
                                        <div className="invoice_item_main">
                                            <label htmlFor="ShortDescription" className='caption color_dark_grey'>Item</label>
                                            <input
                                                type="text"
                                                className='body1'
                                                name='name'
                                                value={itemDetails.name}
                                                onChange={handleItemDetails}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-3">
                                        <div className="invoice_item_main">
                                            <label htmlFor="ShortDescription" className='caption color_dark_grey'>Qty</label>
                                            <input
                                                type="number"
                                                className='body1'
                                                name='quantity'
                                                value={itemDetails.quantity}
                                                onChange={handleItemDetails}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-3">
                                        <div className="invoice_item_main">
                                            <label htmlFor="ShortDescription" className='caption color_dark_grey'>Price</label>
                                            <input
                                                type="number"
                                                className='body1'
                                                name='price'
                                                value={itemDetails.price}
                                                onChange={handleItemDetails}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-14">
                                <div className="invoicingAddbtn">
                                    <button className='transparent_cta'
                                        onClick={() => {
                                            if (itemDetails.name && itemDetails.quantity && itemDetails.price) {
                                                setItemsArr(prev => [...prev, itemDetails])
                                                setItemDetails({
                                                    name: "",
                                                    quantity: "",
                                                    price: ""
                                                })
                                            }
                                        }}
                                        type="button"
                                    >
                                        <AddBoxRounded /> <span className='body1 color_dark_blue'>Add item</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 mt-14 mb-16">
                                {
                                    itemsArr.map((data, i) => <div className="row mb-6" key={i}>
                                        <div className="col-3">
                                            <div className="invoice_item_text">
                                                <p className='body1 color_dark_blue'>{data.name}</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="invoice_item_text d-flex justify-content-center">
                                                <p className='body1 color_dark_blue'>{data.quantity}</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="invoice_item_text d-flex justify-content-center">
                                                <p className='body1 color_dark_blue'>{data.price}</p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="invoice_item_text d-flex justify-content-end">
                                                <button className='transparent_cta'
                                                    onClick={() => removeItem(i)}
                                                    type="button"
                                                >
                                                    <p className='removebtn'>Remove</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>)
                                }

                            </div>
                            <div className="col-12 mb-12">
                                <label htmlFor="ShortDescription" className='body1 color_dark_grey'>Tax</label>
                                <div className="row">
                                    <div className="col-lg-8 col-md-6 col-6">
                                        <div className="invoice_item_main">
                                            <input
                                                type="text"
                                                className='body1'
                                                placeholder='Enter Tax Type'
                                                name='taxtype'
                                                value={taxType}
                                                onChange={(e) => setTaxType(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-3">
                                        <div className="invoice_item_main">
                                            <input
                                                type="number"
                                                className='body1'
                                                name='taxvalue'
                                                value={taxValue}
                                                onChange={(e) => setTaxValue(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 mb-24">
                                <label htmlFor="ShortDescription" className='body1 color_dark_grey'>Discount</label>
                                <div className="row">
                                    <div className="col-lg-8 col-md-6 col-6">
                                        <div className="coworking_addAssets_input_main">
                                            <select name="discountType" value={discountType} onChange={(e) => setDiscountType(e.target.value)} className='body1'>
                                                <option value="" className='body1'>Select Discount Type</option>
                                                <option value="percent" className='body1'>Percent</option>
                                                <option value="value" className='body1'>Value</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3 col-3">
                                        <div className="invoice_item_main">
                                            <input
                                                type="number"
                                                className='body1'
                                                name='quantity'
                                                value={discountValue}
                                                onChange={(e) => setDiscountValue(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="Amount" className='caption color_dark_blue'>Amount</label>
                                    <input
                                        type="number"
                                        placeholder='Enter Amount'
                                        className='body1'
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        required
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="col-12" >
                                <div className="coworking_addAssets_btn_main">
                                    <button className='primaryFilled_activeCta' type='submit' onClick={createInvoice}>Create</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default invingForm 