import React, { useEffect, useState } from "react";
import "./index.css";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { useAuth } from "../../../Utils/Auth";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router";
import Notifications from "../../../Components/Notifications/whitebgNotyf";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "./../../../Assets/images/logo/logoblack.png";
import embedLogo from "./../../../Assets/images/logo/TWF_emblem_black_400H.png";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function DashNav() {
    const { signOut, user, notificationsCount, getUser } = useAuth();
    React.useEffect(() => {
        if (!user) getUser();
    }, []);
  const isTab = useMediaQuery({ query: "(max-width:768px)" });


    const gotodashbord = () => { };
    const [open, setOpen] = React.useState(false);
    const [openNotyf, setOpenNotyf] = React.useState(false);
    const [cStep, setCStep] = useState(0);
    const anchorRef = React.useRef(null);
    const anchorRefNotf = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleNotyfToggle = () => {
        setOpenNotyf((prevOpenNotyf) => !prevOpenNotyf);
    };
    const query = useQuery();
    var currentStep = Number(query.get("tab"));

    useEffect(() => {
        if (currentStep) {
            setCStep(currentStep);
        }
    }, [currentStep]);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleCloseNotyf = (event) => {
        if (anchorRefNotf.current && anchorRefNotf.current.contains(event.target)) {
            return;
        }
        setOpenNotyf(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    function handleListKeyDownNotyf(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpenNotyf(false);
        }
    }

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const prevOpenNotyf = React.useRef(openNotyf);

    React.useEffect(() => {
        if (prevOpenNotyf.current === true && openNotyf === false) {
            anchorRefNotf.current.focus();
        }
        prevOpenNotyf.current = openNotyf;
    }, [openNotyf]);

    if (!user) return <p></p>;

    return (
        <div className="dashboard01_navnew">
            <div className="dashboard01_new_logo">
              <Link to="/">
                <img
                  src={isTab ? embedLogo : logo}
                  className="dashboard_left_new_logo img-fluid"
                />
              </Link>
            </div>
            <div className="text-end">

                {
                    cStep === 1920 ?
                        <Link to={`/dashboard/${user.type}?tab=1920`} className='feed_nav_cta'>
                            FEED
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="#ff6f1e"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                            >
                                <path d="M3 3V21H21V3H3ZM18 18H6V17H18V18ZM18 16H6V15H18V16ZM18 12H6V6H18V12Z" fill="#FF6F1E" />
                            </svg>
                            {/* </p> */}

                        </Link> :
                        <Link
                            to={`/dashboard/${user.type}?tab=1920`}
                            className="feed_nav_cta"
                        >
                            FEED
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="#99B3D6"
                                xmlns="http://www.w3.org/2000/svg"
                                className=""
                            >
                                <path
                                    d="M3 3V21H21V3H3ZM18 18H6V17H18V18ZM18 16H6V15H18V16ZM18 12H6V6H18V12Z"
                                    fill="#99B3D6"
                                />
                            </svg>
                        </Link>
                }
                {
                    user.type === "student" ? null : cStep === 1158 ? (
                        <Link to={`/dashboard/${user.type}?tab=1158`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#ff6f1e"
                                className="bi bi-chat-left-text-fill mx-3"
                                viewBox="0 0 16 16"
                            >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                            </svg>
                        </Link>
                    ) : (
                        <Link to={`/dashboard/${user.type}?tab=1158`}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#99B3D6"
                                className="bi bi-chat-left-text-fill mx-3"
                                viewBox="0 0 16 16"
                            >
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                            </svg>
                        </Link>
                    )
                }

                <Button
                    ref={anchorRefNotf}
                    aria-controls={openNotyf ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleNotyfToggle}
                    style={{ margin: "0 px" }}
                >
                    {notificationsCount ? (
                        <Badge badgeContent={notificationsCount} color="primary">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#99B3D6"
                                className="bi bi-bell-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                            </svg>
                        </Badge>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#99B3D6"
                            className="bi bi-bell-fill"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                        </svg>
                    )}
                </Button>
                <Popper
                    open={openNotyf}
                    style={{ zIndex: "200" }}
                    anchorEl={anchorRefNotf.current}
                    role={undefined}
                    transition
                    className="dashNav_popper"
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper style={{ zIndex: "200" }}>
                                <ClickAwayListener onClickAway={handleCloseNotyf}>
                                    <MenuList
                                        autoFocusItem={openNotyf}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDownNotyf}
                                    >
                                        <Notifications />
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                &nbsp;
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className="dashNav_profile_heighted"
                >
                    {user ? (
                        <>
                            <Avatar src={user.avatar} />
                        </>
                    ) : (
                        <>
                            <Avatar>
                                <PersonIcon />
                            </Avatar>
                        </>
                    )}

                    {open ? <ExpandLess /> : <ExpandMore />}
                </Button>
                <Popper
                    open={open}
                    style={{ zIndex: "200", marginLeft: "10px" }}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                                marginLeft: "-5px",
                            }}
                        >
                            <Paper style={{ zIndex: "200" }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                gotodashbord();
                                                setOpen(false);
                                            }}
                                        >
                                            Dashboard
                                        </MenuItem>

                                        <MenuItem onClick={signOut}>Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}
DashNav.propTypes = {
    value: PropTypes.string.isRequired,
};
