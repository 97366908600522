import React from "react";
// import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
import { AvatarGroup, Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CalendarViewMonth, Delete } from "@mui/icons-material";
import { toast } from "react-toastify";
import Axios from "axios";
import DeleteModal from "../../../../../Utils/DeleteModal/DeleteModal";

const Card = (props) => {
  const { course, setLearningState, fetchCourses } = props;
  const navigate = useNavigate();
  const { programid } = useParams()
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const cloneCourse = async (e, id) => {
  //   e.preventDefault()
  //   await Axios({
  //     url: `/api/mentorModules/make-duplicate/course/${id}`,
  //     method: "get",
  //   })
  //     .then(() => {
  //       fetchCourses()
  //     })
  //     .catch((err) => {
  //       if (err.response.status === 401) {
  //         toast.error("Something went wrong. try again!")
  //       }
  //     });
  // }

  const deleteCourse = async (e) => {
    e.preventDefault()
    await Axios({
      url: `/api/mentorModules/course/delete/${course._id}`,
      method: "delete",
    })
      .then(() => {
        fetchCourses()
        toast.success("Course deleted")
        handleClose()
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  return (
    <div className="col-12 mb-24">
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide">

        </div>
        <div className="user_incubation_course_rightMain w_100">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                <div className="user_incubation_course_rightHead">
                  <div className="user_incubation_course_rightTitle">
                    <h5>{course.title}</h5>
                  </div>
                  <div className="user_incubation_course_rightInfo d-flex mt-3 mb-3">
                    {/* <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2"><Videocam /></span>
                      <p className="body2">24.5 Hours</p>
                    </div> */}
                    <div className="user_incubation_course_right_th d-flex align-items-center">
                      <span className="mr-2"><CalendarViewMonth /></span>
                      <p className="body2">{course.module_count.length > 0 ? course.module_count[0].module_count + ' Modules':0 + ' Module'}</p>
                    </div>
                  </div>
                </div>
                <div className="user_incubation_course_AvatarGroup_root">
                  <div className="user_incubation_course_AvatarGroups">
                    <AvatarGroup total={course.enrolled.length} className="user_incubation_course_AvatarGroup">
                      {
                        course.enrolled.slice(0, 4).map((data, i) => <Avatar key={i} src={data.user_id.avatar} />)
                      }
                    </AvatarGroup>
                  </div>
                  <div className="user_incubation_course_startupEnrolled">
                    <p className="caption color_light_grey">Startups enrolled</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_leftSide">
                <div className="user_course_tags d-flex align-items-center">
                  {
                    course.tag && course.tag.length > 0 ?
                      course.tag.map((item, i) => <p className="incubation_chips" key={i}>{item}</p>) : null
                  }
                </div>
                <div className="user_course_desc pt-3">
                  <p className="caption">
                    {course.summary}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user_incubation_course_btns">
          <button className="transparent_cta" onClick={handleOpen}><Delete /></button>
          {/* <button className="transparent_cta" onClick={(e) => cloneCourse(e, course._id)} ><FileCopy /></button> */}
          <button className="transparent_cta point" onClick={() => {
            setLearningState(2);
            navigate(`/service/incprogram/${programid}?tab=6&course=${course._id}`);
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              fill="#989898"
              className="bi bi-pencil-square"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"></path>
              <path
                fillRule="evenodd"
                d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="title">Please confirm to Delete</p>
          <div className="d-flex justify-content-between mt-40">
            <button className="primaryFilled_activeCta" onClick={(e)=>deleteCourse(e)}>Confirm</button>
            <button className="secondaryOutlined_activeCta">Decline</button>
          </div>

        </Box>
      </Modal> */}
      <DeleteModal
        open={open}
        handleClose={handleClose}
        deleteFunction={deleteCourse}
      />
    </div>
  );
};

export default Card;