import React from 'react';
import UserImg from "../../../../../Assets/images/user.png"

const Card = ({data}) => {
    return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td finance_received_from_td">
            <img src={data.for_user.avatar? data.for_user.avatar:UserImg} alt="twf finance" />
            <p className="subtitle color_dark_black">{data.for_user.name ? data.for_user.name : `${data.for_user.firstname} ${data.for_user.lastname}`}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black">{data.tools}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">{new Date(data.date).toLocaleDateString('en-GB')}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">{data.is_paid ? "Paid":"Unpaid"}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
            <p className="caption color_dark_black">Euro {data.total_amount}</p>
        </td>
    </tr>
}

export default Card