import React, { useEffect, useState } from "react";
import Card from "./AcceptedCard";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import IncubatorExplore  from '../../../../../Components/SkeletonLoader/IncubationProgramSkeleton'
const ViewCourses = (props) => {
  const { setIncubationState } = props;
  const [programs, setPrograms] = useState([]);
  const { setSessionExpire } = useAuth();
  const [isLoading,setIsLoading] = useState(false)
  const fetchAllPrograms = async () => {
    setIsLoading(true)
    try {
      await Axios({
        url: `/api/incubatorProgram/programsofstartup`,
        method: `get`,
      })
        .then((res) => {
          console.log(res.data)
          setPrograms(res.data.data);
          setIsLoading(false)
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setSessionExpire(true);
            setIsLoading(false)
          }
        });
    } catch (error) {
      setSessionExpire(true);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchAllPrograms();
  }, []);

  return (
    <div className="incubation_program_root">
      <div className="incubation_profile_program_main">
        <div className="incubation_program_main mt-4">
          <div className="row">
            {isLoading ? <IncubatorExplore/> : (programs && programs.length > 0 ? (
              programs.map((program, i) => (
                <Card
                  key={i}
                  program={program.program_id}
                  incubator={program.incubator_id}
                  setIncubationState={setIncubationState}
                />
              ))
            ) : (
              <p>No Active Programs </p>
            ))
            }
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCourses;
