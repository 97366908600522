import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "./../../../../../Utils/Auth";
import rocketimg from "./../../../../../Assets/images/Rocket.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IndividualInvite from "../IndividualInvite";
import { useParams } from "react-router-dom";
import ConnectionCard from "../../../../../Components/Cards/ConnectionCard";
import IndividualProfile from "../IndividualProfile/IndividualProfile";
import {
  ParticipantsSkeleton,
  ParticipantsCardSkeleton,
  ParticipantsEmailInvite,
  ParticipantsMentee,
} from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";
import MenteeProfile from "../MenteeProfile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const Individuals = ({ menteesProfile }) => {
  const [individuals, setIndividuals] = useState([]);
  const { setSessionExpire, user } = useAuth();
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [userid, setUserid] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { programid } = useParams();
  // getting data
  const fetchindividuals = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/incubator-individual/individuals/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setIndividuals(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (programid) {
      fetchindividuals();
    }
  }, [programid]);

  return (
    <div className="student_root">
      {step === 1 ? (
        <>
          <IndividualProfile
            setstep={setstep}
            studentId={participantid}
            user_id={userid}
            // title={title}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  {user.type === "mentor" && (
                    <div className="col-lg-12 col-sm-12 mx-3 mb-4">
                      <div className="dashboard01_block h-fit">
                        <p className="p_spartan_20 mb-4 mt-2">My mentee</p>
                        {isLoading ? (
                          <ParticipantsMentee />
                        ) : (
                          <div className="row">
                            <MenteeProfile
                              userType="individuals"
                              menteesProfile={menteesProfile}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {user.type === "investor" && (
                    <div className="col-lg-12 col-sm-12 mx-3 mb-4">
                      <div className="dashboard01_block h-fit">
                        <p className="p_spartan_20 mb-4 mt-2">My mentee</p>
                        {isLoading ? (
                          <ParticipantsMentee />
                        ) : (
                          <div className="row">
                            <MenteeProfile
                              userType="individuals"
                              menteesProfile={menteesProfile}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="row">
                    {isLoading ? (
                      <ParticipantsSkeleton />
                    ) : individuals.length === 0 ? (
                      <p className="mx-4">No individual available</p>
                    ) : (
                      individuals.map((v, i) => (
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                          key={i}
                        >
                          <ConnectionCard
                            handleFunction={() => {
                              setstep(1);
                              setparticipantid(v.individual._id);
                              setUserid(v.individual.id);
                            }}
                            profilepic={v.individual.profile_pic}
                            name={v.individual.name}
                            state={v.individual.location.state}
                            country={v.individual.location.country}
                            chip1={v.individual.latest_education}
                            chip2={v.individual.job_role}
                          />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="d-flex justify-content-center ">
                {isLoading ? (
                  <ParticipantsEmailInvite />
                ) : (
                  <button
                    onClick={() => handleOpen()}
                    type="button"
                    className="gradient_CTA"
                  >
                    INVITE INDIVIDUALS BY EMAIL
                  </button>
                )}
              </div>
              {isLoading ? (
                <ParticipantsCardSkeleton />
              ) : (
                <div className="dashboard01_block h-fit">
                  <p className="p_spartan_20 mt-2">Total Individuals</p>
                  <div className="row mx-0 my-2 r_a">
                    <div className="col-8">
                      <p className="bold my-0 h4" style={{ fontSize: "32px" }}>
                        {individuals.length}
                      </p>
                    </div>
                    <div className="col-4">
                      <img src={rocketimg} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 500 }}>
              <IndividualInvite handleClose={handleClose} />
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Individuals;
