// import { AddBox } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { useAuth } from "../../../../../Utils/Auth";
import { Delete, FileCopy } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import DeleteModal from "../../../../../Utils/DeleteModal/DeleteModal";
import SessionModal from "./SessionModal";
import AssignmentModal from "./AssignmentModal";
import AssessmentModal from "./AssessmentModal";
import { useEffect } from "react";

export default function ModuleComponent({
  setAddSessionState,
  data,
  courseId,
  index,
  getModules,
  len
}) {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const [isEdit, setisEdit] = useState(false);
  const [isScroll, setisScroll] = useState(false);
  const [moduleName, setModulelName] = useState("");
  const [moduleDesc, setModulelDesc] = useState("");
  const [sessions, setsessions] = useState([]);
  const [assesments, setassesments] = useState(null);
  const [assignment, setAssignment] = useState(null);
  const [total_sessions, setTotal_sessions] = useState(0)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { programid } = useParams()

  React.useEffect(() => {
    if (data) {
      if (data.title) {
        setModulelName(data.title);
      }
      if (data.description) {
        setModulelDesc(data.description);
      }
    }
  }, [data]);

  const updateModule = async (e) => {
    e.preventDefault();
    await axios({
      url: `/api/mentorModules/module/update/${data._id}`,
      method: "put",
      data: {
        title: moduleName,
        description: moduleDesc,
        course_id: courseId,
      },
    })
      .then(() => {
        toast.success("Module Updated");
        getModules();
        setisEdit(false);
      })
      .catch(() => {
        toast.error("Something went wrong, try again");
      });
  };

  const getSessions = async () => {
    await axios({
      url: `/api/mentorModules/sessions/module/${data._id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data) {
          setsessions(res.data.session);
          setassesments(res.data.assessment);
          setAssignment(res.data.assignment);
          let session_count = 0;
          for (let i = 0; i < res.data.session.length; i++) {
            const element = res.data.session[i];
            if (!element.is_deleted) {
              session_count = session_count + 1;
            }
          }
          setTotal_sessions(session_count)
        }
      })
      .catch(() => { });
  };

  const cloneModule = async (id) => {
    await axios({
      url: `/api/mentorModules/make-duplicate/module/${id}`,
      method: "get",
    })
      .then(() => {
        toast.success("Module Cloned");
        getModules();
      })
      .catch(() => {
        toast.error("Something went wrong, try again");
      });
  }

  const deleteModule = async () => {
    await axios({
      url: `/api/mentorModules/module/update/${data._id}`,
      method: "put",
      data: {
        is_deleted: true
      }
    })
      .then(() => {
        toast.success("Module Deleted");
        getModules();
      })
      .catch(() => {
        toast.error("Something went wrong, try again");
      });
  }


  React.useEffect(() => {
    getSessions();
  }, [data]);

  useEffect(()=>{
    if((index+1) === len)
    {
      setisEdit(true)
    }
  },[])

  return (
    <div className="incubator_learning_mdComp">
      <div className="module_main_div">
        <div className="row justify-content-between m-0">
          <div>
            <p className="subtitle">
              {data.title}
            </p>
            <p className="caption">
              {sessions && (total_sessions + (assignment === null ? 0 : 1) + (assesments === null ? 0 : 1))} {(total_sessions + (assignment === null ? 0 : 1) + (assesments === null ? 0 : 1)) > 1 ? "items" : "item"}
            </p>

          </div>
          <div className="d-flex align-items-center module_edit_btn">
            <Tooltip title="Delete">
              <p onClick={handleOpen} className="modulecomp_icons"><Delete /></p>
            </Tooltip>
            <Tooltip title="Clone">
              <p onClick={() => cloneModule(data._id)} className="modulecomp_icons"><FileCopy /></p>
            </Tooltip>
            <Tooltip title="Edit">
              <p
                className={`point caption modulecomp_icons`}
                onClick={() => {
                  setisScroll(!isScroll)
                  setisEdit(false)
                }}
                style={{ cursor: "pointer" }}

              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  fill="#989898"
                  className={`bi bi-pencil-square`}
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"></path>
                  <path
                    fillRule="evenodd"
                    d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"
                  ></path>
                </svg>
              </p>
            </Tooltip>
            <Tooltip title="scroll">
              <p
                className={`point caption modulecomp_icons pl-8`}
                onClick={() => {
                  setisEdit(!isEdit)
                  setisScroll(false)
                }}
                style={{ cursor: "pointer" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className={`bi bi-chevron-down mx-2 ${isEdit ? 'rotateAnsKey' : 'rotateAnsKey2'}`}
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </p>
            </Tooltip>
          </div>
        </div>
        {
          isScroll ? <div className="my-4">
            <div className="create_course_form_maindiv my-2">
              <label htmlFor="Module 1" className="caption color_dark_blue">
                Module {index + 1}
              </label>
              <input
                type="text"
                className="body1"
                placeholder="Enter Title"
                value={moduleName}
                onChange={(e) => setModulelName(e.target.value)}
              />
            </div>
            <div className="create_course_form_maindiv">
              <label htmlFor="course_desc" className="caption color_dark_blue">
                Description
              </label>
              <textarea
                type="text"
                className="body1"
                placeholder="Enter industry or keyword"
                value={moduleDesc}
                onChange={(e) => setModulelDesc(e.target.value)}
              />
            </div>
            <button
              className="primaryFilled_activeCta"
              onClick={(e) => updateModule(e)}
            >
              Update
            </button>
          </div> : null
        }
        {isEdit ? <>
          {sessions && sessions.length > 0 ? (
            <div className="mt-24">
              <h6 className="body1 bold color_dark_blue">Sessions</h6>
              {sessions.map((ses) => (
                <>
                  {
                    !ses.is_deleted ? <SessionModal
                      ses={ses}
                      setAddSessionState={setAddSessionState}
                      getSessions={getSessions}
                      key={ses._id}
                      courseId={courseId}
                      data={data}
                    /> : null
                  }
                </>
              ))}
            </div>
          ) : null}
          {assesments && !assesments.is_deleted ? (
            <>
              <div className="mt-12">
                <h6 className="body1 bold color_dark_blue">Assessment</h6>
                <AssessmentModal
                  assesments={assesments}
                  data={data}
                  setAddSessionState={setAddSessionState}
                  courseId={courseId}
                  getModules={getModules}
                  getSessions={getSessions}
                />
              </div>
            </>
          ) : null}
          {assignment && !assignment.is_deleted ? (
            <>
              <div className="mt-12">
                <h6 className="body1 bold color_dark_blue">Assignment</h6>
                <AssignmentModal
                  assignment={assignment}
                  data={data}
                  setAddSessionState={setAddSessionState}
                  courseId={courseId}
                  getModules={getModules}
                  getSessions={getSessions}
                />
              </div>
            </>
          ) : null}

          <div className="col-12 allbtns_module_main mt-3">
            <button
              className="cta color_dark_blue"
              onClick={(e) => {
                e.preventDefault();
                setAddSessionState(1);
                navigate(
                  `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}`
                );
              }}
            // title="New Session will be added of previous one until new module doesn't create"
            >
              Add Session
            </button>
            {assesments ? null : (
              <button
                className="cta color_dark_blue"
                onClick={(e) => {
                  e.preventDefault();
                  setAddSessionState(2);
                  navigate(
                    `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}`
                  );
                }}
              >
                Add Assesment
              </button>
            )}
            {assignment ? null : (
              <button
                className="cta color_dark_blue"
                onClick={(e) => {
                  e.preventDefault();
                  setAddSessionState(3);
                  navigate(
                    `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}`
                  );
                }}
              >
                Add Assignment
              </button>
            )}
          </div>
        </> : null}
      </div>

      <DeleteModal
        open={open}
        handleClose={handleClose}
        deleteFunction={deleteModule}
      />
    </div>
  );
}
