import React, { useEffect, useState } from "react";
import { useParams, 
  // useNavigate 
} from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import {  Tabs, Tab, Typography, Box } from "@mui/material";
// import profile_inc from "../../../../Assets/images/incubatorDash/profile_inc.png";
import PropTypes from "prop-types";
import "./index.css";
import AllAplicantCard from "./AllAplicantCard";
import ShortListedCard from "./ShortListedCard";
import DeclinedCard from "./DeclinedCard";
import AcceptedCard from "./AcceptedCard";
import Applicantcard from "./Applicantcard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const index = () => {
  // const navigate = useNavigate();

  let { programid } = useParams();
  const [program, setprogram] = useState(null);
  const { setSessionExpire } = useAuth();

  const [value, setValue] = React.useState(0);

  const [step, setstep] = useState(0);
  const [startupid, setstartupid] = useState("");
  const [requestid, setrequestid] = useState("");
  const [applicants, setapplicants] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchprogram = async () => {
    await Axios({
      url: `/api/incubatorProgram/program/${programid}`,
      method: "get",
    })
      .then((res) => {

        if (res.data.success) setprogram(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/incubatorProgram/requests/${programid}/false`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchprogram();
  }, []);
  if (program === null) return <h1></h1>;

  // console.log(program);
  return (
    <>
      {step === 1 ? (
        <>
          <Applicantcard
            setstep={setstep}
            startupid={startupid}
            requestid={requestid}
            callbackfunc={fetchapplicants}
            applicants={applicants}
            title={program.title}
          />
        </>
      ) : (
        <>
          {/* {console.log("program", program)} */}
          <div className="incubation_program_root">
           
            <div className="incubation_profile_program_main">
              <div className="incubation_profile_program_TabsMain">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="incubation_profile_program_Tabs"
                >
                  <Tab
                    label="All Applicants"
                    {...a11yProps(0)}
                    className="incubation_profile_program_Tab"
                  />
                  <span className="incubation_profile_program_span"></span>
                  <Tab
                    label="SHortlisted"
                    {...a11yProps(2)}
                    className="incubation_profile_program_Tab"
                  />
                  <span className="incubation_profile_program_span"></span>
                  <Tab
                    label="Accepted"
                    {...a11yProps(4)}
                    className="incubation_profile_program_Tab"
                  />
                  <span className="incubation_profile_program_span"></span>
                  <Tab
                    label="Declined"
                    {...a11yProps(6)}
                    className="incubation_profile_program_Tab"
                  />
                </Tabs>
              </div>
              <div className="incubation_profile_program_TabPanelMain">
                <TabPanel
                  value={value}
                  index={0}
                  className="incubation_profile_program_TabPanel"
                >
                  <div className="incubation_profile_card_root">
                    <div className="row">
                      <>
                        <AllAplicantCard
                          programid={programid}
                          step={step}
                          setstep={setstep}
                          startupid={startupid}
                          setstartupid={setstartupid}
                          requestid={requestid}
                          setrequestid={setrequestid}
                        />
                      </>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={2}
                  className="incubation_profile_program_TabPanel"
                >
                  <div className="incubation_profile_card_root">
                    <div className="row">
                      <ShortListedCard programid={programid} />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={4}
                  className="incubation_profile_program_TabPanel"
                >
                  <div className="incubation_profile_card_root">
                    <div className="row">
                      <AcceptedCard programid={programid} />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={6}
                  className="incubation_profile_program_TabPanel"
                >
                  <div className="incubation_profile_card_root">
                    <div className="row">
                      <DeclinedCard programid={programid} />
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default index;
