import React from "react";
import ViewCourse from "./ViewCourses/ViewCourse";
import CreatCourse from "./CreatCourses/CreatCourse";
import Assignment from "./Assignment/Assignment";
import Assessment from "./Assessment/Assessment";

const LandingPage = (props) => {
  const { setLearningState, learningState } = props;
  return (
    <>
      {
        learningState === 1 ? <ViewCourse
          setLearningState={setLearningState}
        /> : learningState === 2 ? <CreatCourse
          setLearningState={setLearningState}
        /> : learningState === 3 ? <Assignment
        setLearningState={setLearningState}
        /> : learningState === 4 ? <Assessment
        setLearningState={setLearningState}
        /> : null
      }
    </>
  );
};

export default LandingPage;