import React, { useState, useEffect } from 'react'
import Posts from "../Posts";
import { useAuth } from "../../../../Utils/Auth";
// import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { KeyboardBackspace } from '@mui/icons-material';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './mypost.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const MyPost = (props) => {
    const { setMyPostState } = props;
    // const navigate = useNavigate();
    const [myposts, setMyPosts] = useState([]);
    const [mostLiked, setMostLiked] = useState([]);
    const [mostCommented, setMostCommented] = useState([]);

    const { setSessionExpire } = useAuth();
    // const history = useHistory()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getMyPosts = async () => {
        await Axios({
            url: "/api/post/myPosts",
            method: "get",
        })
            .then((res) => {
                setMyPosts(res.data.data)
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                }
            });
    };

    const getMostLiked = async () => {
        await Axios({
            url: "/api/post/most-liked/user",
            method: "get",
        })
            .then((res) => {
                // const { data } = res;
                setMostLiked(res.data.data.liked);
                setMostCommented(res.data.data.commented);
                // setDesc((data.desc).toString().replaceAll('$newLine$','\n'));
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                }
            });
    };

    useEffect(() => {
        getMostLiked()
        getMyPosts()
    }, []);

    return <div className="col-lg-8 neWpost-root">
        <div className="row">
            <div className="col-12">
                <div className="myPost_root_head">
                    <div className="myPost_root_head_back">
                        <div
                            className="back_CTA myPost_root_back pointer col-12"
                            onClick={() => {
                                setMyPostState(1)
                                // navigate(-1);
                            }}
                        >
                            <p className="caption color_light_grey d-flex justify-content-center align-items-center"><span><KeyboardBackspace 
                                style={{height:16, width: 16}}
                            /></span>
                                <span style={{marginLeft:9}}>BACK</span></p>
                        </div>
                    </div>
                    <div className="myPost_root_head_heading">
                        <h6>My Activity</h6>
                    </div>
                </div>
                <div className="newPosts-main">
                    <div className="feedAllPost_tabs_root feedAllPost_tabs_root2 mt-16">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="feedAllPost_tabs">
                            <Tab className="feedAllPost_tab" label="LIKED" {...a11yProps(0)} />
                            <span className='feedAllPost_tab_span'></span>
                            <Tab className="feedAllPost_tab" label="COMMENTED" {...a11yProps(1)} />
                            <span className='feedAllPost_tab_span'></span>
                            <Tab className="feedAllPost_tab" label="MY POSTS" {...a11yProps(2)} />
                        </Tabs>
                    </div>
                    <div className="feedAllPost_tabpanel_root">
                        <TabPanel value={value} index={0}>
                            {
                                mostLiked.length > 0 ?
                                    mostLiked.map((post, i) => <Posts
                                        key={i}
                                        post={post}
                                        getPosts={getMyPosts}
                                    />) : ""
                            }
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {
                                mostCommented.length > 0 ?
                                mostCommented.map((post, i) => <Posts
                                        key={i}
                                        post={post}
                                        getPosts={getMyPosts}
                                    />) : ""
                            }
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            {
                                myposts.length > 0 ?
                                    myposts.map((post, i) => <Posts
                                        key={i}
                                        post={post}
                                        getPosts={getMyPosts}
                                    />) : ""
                            }
                        </TabPanel>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default MyPost