import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import IndividualProfile from "./IndividualsProfile/IndividualProfile";

import rocketimg from "./../../../../Assets/images/Rocket.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import StudentInvite from "../StudentInvite";
import {ParticipantsSkeleton,ParticipantsCardSkeleton} from '../../../../Components/SkeletonLoader/ParticipantsSkeleton'
import ConnectionCard from "../../../../Components/Cards/ConnectionCard";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    pt: 2,
    px: 4,
    pb: 3,
    padding: "40px",
};


const Individuals = ({ programid }) => {
    const [participants, setParticipants] = useState([]);
    const { setSessionExpire } = useAuth();
    const [step, setstep] = useState(0);
    const [participantid, setparticipantid] = useState("");
    const [userid, setUserid] = useState("");
    const [open, setOpen] = useState(false);
    const [isLoading,setIsLoading] = useState(false)
    const handleClose = () => {
        setOpen(false);
    };
    // getting data
    const fetchIndividuals = async () => {
        setIsLoading(true)
     await Axios({
          url: `/api/incubator-individual/individuals/${programid}`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            const { data } = res;
            setParticipants(data.data);
            setIsLoading(false)
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 401) {
              setSessionExpire(true);
            }
            setIsLoading(false)
          });
    };


    useEffect(() => {
        fetchIndividuals();
    }, []);

    return (
        <div className='student_root'>
            {step === 1 ? (
                <>
                    <IndividualProfile
                        setstep={setstep}
                        studentId={participantid}
                        user_id = {userid}
                    // title={title}
                    />
                </>
            ) : (
                <div className="p-2">
                    <div className="row">
                        <div className="col-lg-8 col-sm-12">
                            <div className="row m-0">
                                {
                                    isLoading ? <ParticipantsSkeleton/> : participants.length === 0 ? <p>No Individuals available</p> : participants.map((v, i) => <div
                                        className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                                        key={i}
                                    >
                                        <ConnectionCard
                                            handleFunction={() => {
                                                setstep(1);
                                                setparticipantid(v.individual._id);
                                                setUserid(v.individual.id);
                                            }}
                                            profilepic={v.individual.profile_pic}
                                            name={v.individual.name}
                                            state={v.individual.location.state}
                                            country={v.individual.location.country}
                                            chip1={v.individual.latest_education}
                                            chip2={v.individual.job_role}
                                        />
                                    </div>
                                    )}
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            {
                                isLoading ? <ParticipantsCardSkeleton/> :  <div className="dashboard01_block h-fit">
                                <p className="p_spartan_20 mt-2">Total Individuals</p>
                                <div className="row mx-0 my-2 r_a">
                                    <div className="col-8">
                                        <p className="bold my-0 h4" style={{ fontSize: "32px" }}>
                                            {participants.length}
                                        </p>
                                    </div>
                                    <div className="col-4">
                                        <img src={rocketimg} />
                                    </div>
                                </div>
                            </div>
                            }
                            
                        </div>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                    >
                        <Box sx={{ ...style, width: 500 }}>
                            {/* <StudentInvite handleClose={handleClose} /> */}
                        </Box>
                    </Modal>
                </div>
            )}
        </div>
    );




}

export default Individuals