import React, { useState, useEffect } from 'react';
import Card from './Card';
import { useAuth } from '../../../../../Utils/Auth';
import Axios from 'axios';

const Sent = () => {
    const [sent, setsent] = useState([])
    const [selected, setSelected] = useState("all")
    const { setSessionExpire } = useAuth();

    const getAllRequests = async () => {
        await Axios({
            url: `/api/inc-finance/invoices/incubator`,
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setsent(res.data.data)
        }).catch(() => {
            setSessionExpire(true)
        })
    }
    const getPaidRequests = async (term) => {
        await Axios({
            url: `/api/inc-finance/invoices/incubator/${term}`,
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setsent(res.data.data)
        }).catch(() => {
            setSessionExpire(true)
        })
    }
    

    useEffect(() => {
        if(selected === "all")
        {
            getAllRequests()
        }
        else if(selected === "paid")
        {
            getPaidRequests(true)
        }
        else if(selected === "unpaid")
        {
            getPaidRequests(false)
        }
    }, [selected])

    return <div className='invoicing_sent_root'>
        <div className="invoicing_selectOption d-flex justify-content-end align-items-center">
            <select name="days" className='incubator_insights_head_sct' onChange={(e)=>setSelected(e.target.value)}>
                <option value="all">All</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
            </select>
        </div>
        {
            sent.length > 0 ?
            <div className="incubator_assessment_tableRoot mt-24">
                <table className="incubator_assessment_table">
                    <thead className="incubator_assessment_thead">
                        <tr className="incubator_assessment_thead_tr">
                            <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                                <p className="body2 color_dark_grey2">Sent to</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                                <p className="body2 color_dark_grey2">Tools</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                <p className="body2 color_dark_grey2">Date</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                <p className="body2 color_dark_grey2">Status</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                                <p className="body2 color_dark_grey2">Amount</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody className="incubator_assessment_tbody">
                        {
                            sent && sent.length > 0 ?
                                sent.map((data) => <Card
                                    key={data._id}
                                    data={data}
                                    setsent={setsent}
                                />) : null
                        }
                    </tbody>
                </table>
            </div> : <div className="incubator_empty_table mt-24">
                <p>No Request</p>
            </div>
        }
    </div>
}

export default Sent