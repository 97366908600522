import React from "react";
import {
  AccessTime,
  AccountBalanceWallet,
  ArrowForward,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import get_isodiff from "../../Utils/ISOdiff/isodiff";
import { toast } from "react-toastify";

const ProgramCard = (props) => {
  const { program,incubator } = props;
  const navigate = useNavigate();
  return (
    <div
      className="col-lg-3 col-md-6 col-12 mb-4"
      onClick={() => {
        if (
          get_isodiff(program.startDate) === "Live" ||
          get_isodiff(program.startDate) === "Expired"
        )
          navigate(`/service/userprogram/${program.programid._id}?tab=0`);
        else {
          return toast.warning(
            `Program will start in ${get_isodiff(program.programid.startDate)}`
          );
        }
      }}
    >
      <div className="incubation_program_card_root">
        <div
          className="incubation_program_card_head"
          style={{ backgroundImage: `url('${program.programid.coverImage}')` }}
        >
          <p className="p_normalBlack10">{program.programid.sector}</p>
        </div>
        <div className="incubation_program_card_main pl-3 pr-3 pb-3">
          <div className="incubation_program_card_title mt-2">
            <h5 className="p_black20">
              {program.programid.title.substring(0, 15)}
              {program.programid.title.length > 15 ? "..." : ""}
            </h5>
          </div>
          <div className="incubation_program_card_info d-flex justify-content-between mt-3">
            <div className="incubation_program_card_small">
              <span>
                {" "}
                <AccountBalanceWallet />{" "}
              </span>
              <span className="p_secBlack10"> Free </span>
            </div>
            <div className="incubation_program_card_small">
              <span>
                {" "}
                <AccessTime />{" "}
              </span>
              <span className="p_secBlack10">
                {" "}
                {get_isodiff(program.programid.startDate) === "Live" ||
                  get_isodiff(program.programid.startDate) === "Expired"
                  ? "Live"
                  : get_isodiff(program.programid.startDate)}{" "}
              </span>
            </div>
          </div>
          <div className="incubator_program_card_applicants_root mt-3">
            <p className="p_secBlack10" style={{ color: "#580167" }}>
              Hosted By
            </p>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center mt-2">
                <Avatar
                  src={incubator.avatar}
                  style={{ width: 32, height: 32 }}
                  variant="circular"
                />
                <p className="body2 ml-2 color_dark_blue">{incubator.name}</p>
              </div>
              <div>
                <ArrowForward style={{ fill: "#6D7D8B", width: "0.78em" }} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="incubator_program_card_footer">
                <p className="p_secBlack10" style={{ color: "rgba(0,0,0,0.75)" }}>Pending</p>
            </div> */}
      </div>
    </div>
  );
};

export default ProgramCard;
