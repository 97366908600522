import React, { useEffect, useState } from 'react'
import Axios from "axios";
import { useAuth } from '../../../Utils/Auth';
// import Card from './Card';
import search from "./../../../Assets/images/icons/search.png";
import ProgramCard from '../../../Components/Cards/ProgramCard';

const StudentPrograms = () => {
    const { user } = useAuth()
    const [programs, setPrograms] = useState([])
    const getStudentPrograms = async () => {
        await Axios({
            url: `/api/incubator-student/program/${user.id}`,
            method: "GET"
        }).then((res) => {
            setPrograms(res.data.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getStudentPrograms()
    }, [])

    return (
        <div className="incubation_program_root">
            <div className="incubation_prorgram_search_head">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-10">
                        <div className="incubation_programm_searchBoxDiv">
                            <input type="text" name="" id="" />
                            <button className="p_Bold_secBlue14">
                                <img src={search} alt="search" />
                                Search
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-2">
                        {/* <div className="incubation_program_filterDiv">filter......</div> */}
                    </div>
                </div>
            </div>
            <div className="incubation_program_main mt-4">
                <div className="row">
                    {programs && programs.length > 0 ?
                        programs.map((program, i) => (
                            <ProgramCard
                                key={i}
                                program={program}
                                incubator = {program.incubator_user_id}
                            />
                        )) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default StudentPrograms