import React, { useState } from 'react'
import Courses from './Courses/Courses';
import ViewCourse from './ViewCourse/ViewCourse';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const LearningMaterial = () => {
    const [courseState, setCourseState] = useState(0);
    const search = useLocation().search;
    const course_id = new URLSearchParams(search).get("course");
    useEffect(()=>{
        if(course_id)
        {
            setCourseState(1)
        }
    },[])
    return <div className="course_program_root">
        {
            courseState === 0 ? <Courses
                setCourseState={setCourseState}
            /> : courseState === 1 ? <ViewCourse
                setCourseState={setCourseState}
            /> : null
        }
    </div>
}

export default LearningMaterial