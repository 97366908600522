import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Element from "../Elements";
import Axios from "axios";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

// import { FormContext } from "../Context/FormContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Step1 = (props) => {
  // const navigate = useNavigate();
  const { questionnare, handleNext } = props;

  let { Team, BusinessModel, ProductMarket, IPLegal } = questionnare ?? {};

  const [value, setValue] = useState(0);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0,0)
  }, [value])

  const submitTeam = (e) => {
    e.preventDefault();
    Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        Team: questionnare.Team,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success("Saved Successfully");
          // window.location.replace("/dashboard/incubator#attop?tab=4")
          // setValuationStep(2);
          setValue(1);
          // setValuationStep(3);
          // console.log(window.pageYOffset,"window");
        }
      })
      .catch(() => {
        toast.error("Could not save");
      });
  };
  const submitBusinessModel = (e) => {
    e.preventDefault();
    Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        BusinessModel: questionnare.BusinessModel,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success("Saved Successfully");
          setValue(2);
        }
      })
      .catch(() => {
        toast.error("Could not save");
      });
  };
  const submitProductMarket = (e) => {
    e.preventDefault();
    Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        ProductMarket: questionnare.ProductMarket,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success("Saved Successfully");
          setValue(3);
        }
      })
      .catch(() => {
        toast.error("Could not save");
      });
  };
  const submitIPLegal = (e) => {
    e.preventDefault();
    Axios({
      method: "post",
      url: `/api/valuation/questionnaire/${localStorage.getItem('valuation_id')}`,
      data: {
        IPLegal: questionnare.IPLegal,
        quesFilled: true,
      },
    })
      .then((res) => {
        if (res.data) {
          toast.success("Saved Successfully");
          // navigate("/dashboard/startup?tab=5&valuate=1");
          handleNext();
        }
      })
      .catch(() => {
        toast.error("Could not save");
      });
  };

  return (
    <>
      <div className="main-tab-content">
        <div className="valuationHeading">
          <h3>Questionnaire</h3>
          <p>
            The qualitative aspects of a company are valuable as guarantee of
            future success, especially in early stage companies.
          </p>
        </div>
        <div className="tabs-content">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabs}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="TEAM" {...a11yProps(0)} className="tabscont" />
            <Tab
              label="BUSINESS MODEL"
              {...a11yProps(1)}
              className="tabscont"
            />
            <Tab label="PRODUCT MODEL" {...a11yProps(2)} className="tabscont" />
            <Tab label="IP AND LEGAL" {...a11yProps(3)} className="tabscont" />
          </Tabs>
          <TabPanel value={value} index={0} className="tabpanelcontent">
            <form onSubmit={submitTeam} className="questionare_form_main" id="attop">
              {Team
                ? Team.map((field, i) => <Element key={i} field={field} />)
                : null}
              <div className="savenextbtn">
                <button className="gradient_CTA questionra-submitBtn" type="submit">
                  SAVE AND NEXT
                </button>
              </div>
            </form>
            {/* <NextBtn /> */}
          </TabPanel>
          <TabPanel value={value} index={1} className="tabpanelcontent">
            <form onSubmit={submitBusinessModel}>
              {BusinessModel
                ? BusinessModel.map((field, i) => (
                    <Element key={i} field={field} />
                  ))
                : null}
              <div className="savenextbtn">
                <button className="gradient_CTA questionra-submitBtn" type="submit">
                  SAVE AND NEXT
                </button>
              </div>
            </form>
          </TabPanel>
          <TabPanel value={value} index={2} className="tabpanelcontent">
            <form onSubmit={submitProductMarket}>
              {ProductMarket
                ? ProductMarket.map((field, i) => (
                    <Element key={i} field={field} />
                  ))
                : null}
              <div className="savenextbtn">
                <button className="gradient_CTA questionra-submitBtn" type="submit">
                  SAVE AND NEXT
                </button>
              </div>
            </form>
          </TabPanel>
          <TabPanel value={value} index={3} className="tabpanelcontent">
            <form onSubmit={submitIPLegal}>
              {IPLegal
                ? IPLegal.map((field, i) => <Element key={i} field={field} />)
                : null}
              <div className="savenextbtn">
                <button className="gradient_CTA questionra-submitBtn" type="submit">
                  SAVE AND NEXT
                </button>
              </div>
            </form>
            {/* <NextBtn /> */}
          </TabPanel>
        </div>
      </div>
    </>
  );
};
Step1.propTypes = {
  questionnare: PropTypes.any,
  handleNext: PropTypes.any,
};

export default Step1;
