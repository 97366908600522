import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import MentorRoom from "./MentorRoom";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import moment from "moment-timezone";
import { MentorExploreSkeleton } from "../../../Components/SkeletonLoader/MentorsSkeleton";

const Index = () => {
  const navigate = useNavigate();
  const { setSessionExpire, user } = useAuth();
  const [mentorships, setmentorships] = useState([]);
  const [step, setstep] = useState(0);
  const [isLoading,setIsLoading] = useState(false)
  const fetchActivementorship = () => {
    setIsLoading(true)
    Axios({
      url: "/api/mentor/mentorshipstatus",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setmentorships(data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };
  useEffect(() => {
    fetchActivementorship();
  }, []);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (id) {
      setstep(1)
    }
  }, [id])


  const getDiff = (date) => {
    // var dateTime = date + "T" + time;
    var scheduleTime = moment(date);
    var current = moment();
    // var ofset = moment().utcOffset() / 60;

    var dayDiff = scheduleTime.diff(current, "days");
    if (dayDiff < -1) {
      return Math.abs(dayDiff) + " days ago";
    } else if (dayDiff === -1) return Math.abs(dayDiff) + " day ago";
    else {
      var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
      //- parseFloat(timezone.split(" ", 3)[2]) +
      // ofset;
      if (hoursDiff <= -1) {
        let hoursDiffRound = Math.round(hoursDiff);
        return Math.abs(hoursDiffRound) == 1
          ? Math.abs(hoursDiffRound) + " hour ago"
          : Math.abs(hoursDiffRound) + " hours ago";
      } else {
        return Math.abs(Math.round(hoursDiff * 60)) + " mins ago";
      }
    }
  };
  return (
    <>
      {step === 1 ? (
        <MentorRoom setstep={setstep} />
      ) : (
        <>
        <div className="p-2">
          <h4 className="p_spartan_20 px-3 mb-4">Active Mentorships</h4>
          <div className="row m-0">
            {
              isLoading ? <MentorExploreSkeleton type="active_members"/> : mentorships && mentorships.length > 0 ? (
            mentorships.map((v, i) => {
              return (
                <>
                  {v.is_accepted ? (
                    <>
                    
                      <div key={i} className='col-lg-3 col-sm-4 col-xs-12'  onClick={() => {
                            setstep(1);
                            navigate(
                              `/dashboard/${user.type}?tab=5&id=${v.connect_id}&${v.receiver.type}=true`
                            );
                          }}>
                            <div
                              style={{ cursor: "pointer" }}
                              className={`dashboard_01_connections_list_item`}
                            >
                              <div className="dashboard_01_connections_logo_list_block">
                                <Avatar
                                  src={v.receiver.avatar}
                                  alt="connection"
                                  className="dashboard_01_connections_logo"
                                />
                              </div>

                              <div className="dashboard_01_connections_list_item_desc">
                                <p className="p_spartan_20 my-3">
                                  {v.receiver.name.length > 12
                                    ? v.receiver.name.slice(0, 12) + "..."
                                    : v.receiver.name}
                                </p>
                                <p className="font12 bold grey my-3">
                                  {getDiff(v.updatedAt)}
                                </p>

                                <div className="row justify-content-center">

                                </div>
                              </div>

                            </div>

                          </div>
                        </>
                      ) : null}
                    </>
                  );
                })
              ) : (
                <p className="px-3">No current mentorships
                  <br />Start connecting with mentors </p>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
