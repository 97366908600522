import React, { useEffect, useState } from 'react'
import { DescriptionOutlined, FileDownloadOutlined, DeleteOutline, CheckCircle, CheckCircleOutline } from "@mui/icons-material"
import { useAuth } from "../../../../Utils/Auth";
import Axios from 'axios';
import { useLocation, useParams } from "react-router-dom"
import { toast } from 'react-toastify';
import Feedback from './Components/Feedback';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const AssignmentComp = ({getEnrolledUser,setShowLockSessions}) => {
  const query = useQuery();
  const { courseid, programid } = useParams();
  const assignment_id = query.get("assignment");
  const isLast = query.get("isLast");
  const module_id = query.get("module");
  const { setSessionExpire } = useAuth()
  const [assignment, setAssignment] = useState(null)
  const [assignmentUrl, setAssignmentDocUrl] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
  };

  const getAssignment = async () => {
    await Axios({
      url: `/api/mentorModules/assignment/${courseid}/${assignment_id}`,
      method: "get",
    }).then((res) => {
      setAssignment(res.data.data);
      setIsSubmitted(res.data.data.is_submitted)
      // setAssessmentMain(res.data.data);
      // setModules(res.data.mod);
      getEnrolledUser()
    }).catch((err) => {
      if (err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  const handleDocUpload = async (e) => {
    const doc = e.target.files[0];
    if (doc.size > 2 * 1024 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("assignment", doc);
    await Axios({
      method: "post",
      url: `/api/mentorModules/assignment/upload`,
      data: formdata,
    }).then((res) => {
      const { data } = res;
      setAssignmentDocUrl(data.location);
      toast.success("File uploaded", { position: "bottom-right" });
    }).catch(() => {
      toast.error("Error in upload", { position: "bottom-right" });
    });
  }

  const submitAssignment = async () => {
    setShowLockSessions(true)
    if (assignmentUrl === "") {
      return toast.warn("Please upload your assignment")
    }
    await Axios({
      url: `/api/mentorModules/assignment/submit/${courseid}/${module_id}/${assignment_id}`,
      method: "post",
      data: {
        link: assignmentUrl,
        program_id : programid,
      }
    }).then(() => {
      toast.success("Assignment Submitted Successfully");
      getEnrolledUser()
      setIsSubmitted(true)
      setShowLockSessions(true)
      setShowLockSessions(false)
    }).catch((err) => {
      if (err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  useEffect(() => {
    getAssignment()
  }, [assignment_id])

  return <div className="userCourses_tabPanel_content_main">
    {
      assignment && assignment !== null ?
        <div className="userCourses_tabPanel_assessment_main">
          <div className="userCourses_tabPanel_assessment_head mb-4">
            <p className="title">{assignment.name}</p>
          </div>
          <div className="userCourses_tabPanel_assignment_title mb-5 pr-5">
            <p className="caption color_dark_blue mb-3">Instructions</p>
            <p className="body2 pr-4">{assignment.instructions}</p>
          </div>
          <div className="userCourses_tabpanel_downTabs_tabpCont">

            <div className="program_upload_imglater w_90 p-4 mt-3">
              <div className="d-flex align-items-center">
                <span className='mr-1'><DescriptionOutlined
                  style={{ fill: "#262C5B" }}
                /></span>
                <p className='caption color_dark_blue pt-1'>Sample Assignment</p>
              </div>
              <div className="program_upload_imgdlt">
                <a href={assignment.document} target="_blank" rel="noopener noreferrer">
                  <FileDownloadOutlined style={{ fill: "#98B3D6" }} />
                </a>
              </div>
            </div>

          </div>
          {
            isSubmitted ? <>
              <div className="userCourses_assignment_submit_assignment mt-5">
                <p className="body2 mt-2">Your assignment</p>
                <div className="program_upload_imglater w_90 p-3 mt-4">
                  <div className="d-flex align-items-center">
                    <span className='mr-1'><DescriptionOutlined
                      style={{ fill: "#262C5B" }}
                    /></span>
                    <p className='caption color_dark_blue pt-1'>
                      <a href={assignment.document} target="_blank" rel="noopener noreferrer">Your Assignment</a>
                    </p>
                  </div>
                  <div className="program_upload_imgdlt">
                    <span
                      // onClick={() => setAssignmentDocUrl("")} 
                      className="point program_deleteIcon">
                      <DeleteOutline />
                    </span>
                  </div>
                </div>
              </div>
              <div className="userCourses_assignment_evaluated_status_main mt-4">
                <p className="cta">
                  {
                    assignment.status === "Accepted" ?
                      <>
                        <span className='userCourses_evaluated_status'><CheckCircle
                          style={{ fill: "#2EB67D" }}
                        /></span>
                        <span>&nbsp;Evaluated</span>
                      </>
                      :
                      <>
                        <span className='userCourses_evaluated_status'><CheckCircleOutline
                        // style={{ fill: "#2EB67D" }}
                        /></span>
                        <span>&nbsp; Not Evaluated</span>
                      </>

                  }
                </p>
              </div>
              {
                assignment.remark !== null ?
                  <div className="userCourses_assignment_remark_main mt-4 mb-5">
                    <p className="caption color_dark_blue">Remarks</p>
                    <textarea className='userCourses_assignment_remark mt-2 body1 color_dark_grey2' disabled>
                      {assignment.remark}
                    </textarea>
                  </div> : null
              }
            </> : <>
              <div className="userCourses_assignment_submit_assignment mt-5">
                <p className="body2 mt-2">Upload your assignment</p>
                {
                  assignmentUrl && assignmentUrl !== "" ?
                    <div className="program_upload_imglater w_90 p-3">
                      <div className="d-flex align-items-center">
                        <span className='mr-1'><DescriptionOutlined
                          style={{ fill: "#262C5B" }}
                        /></span>
                        <p className='caption color_dark_blue pt-1'>Your Assignment</p>
                      </div>
                      <div className="program_upload_imgdlt">
                        <span onClick={() => setAssignmentDocUrl("")} className="point program_deleteIcon">
                          <DeleteOutline />
                        </span>
                      </div>
                    </div>
                    :
                    <div className="program_upload_img w_90 p-4">
                      <label htmlFor="submitAssignment" className='d-flex justify-content-center align-items-center m-0'>
                        <span className='mr-1'><DescriptionOutlined
                          style={{ fill: "#262C5B" }}
                        /></span>
                        <p className="caption color_dark_blue pt-1">Upload Document</p>
                      </label>
                      <input
                        type="file"
                        id="submitAssignment"
                        onChange={handleDocUpload}
                        accept="image/*, application/pdf,text/plain,application/msword,.doc, .docx"
                      />
                    </div>
                }
              </div>

              <div className="userCourses_assignment_submit_btn mt-5">
                <button className='primaryFilled_activeCta' onClick={isLast === "true" ? handleOpen : submitAssignment}>SUBMIT</button>
              </div>
            </>
          }

        </div> : null
    }
    <Feedback open={open} handleClose={handleClose} submit={submitAssignment} />
  </div>
}

export default AssignmentComp