import React, {  useEffect } from "react";  
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Multiselect from "multiselect-react-dropdown";

const Step2 = (props) => {
  const {
    options,
    interestedindustry,
    addInputEvent3,
    interestedmodel,
    setinterestedmodel,
    interestedstage,
    setinterestedstage,
    chequsize,
    setchequsize,
    investmentcriteria,
    handleMultiselect,
    support,
    handleMultiselectsupport,
    enablingtechnologies,
    handleMultiselecttech,
    showother,
    otherenabling,
    setotherenabling,
    initial2,
    setinitial2

    // motivation,
    // setmotivation,
    // othermotivation,
    // setothermotivation,
  } = props;

  
  useEffect(()=>{
    if(interestedindustry.length===1)
    {
      setinitial2(false);
    }
  },[interestedindustry])

  
  useEffect(()=>{
    setinitial2(true);
    // console.log(initial)
  },[])

  

  return (
    <form >
      <div className="register_page_form row">

        
        <div className="col-12">
          <label className="auth_label">
          Interested Industry*
          </label>
          <Multiselect
            isMulti
            options={options}
            // className="auth_input"
            displayValue="label"
            placeholder="Select industry"
            name="industries"
            closeIcon="cancel"
            value={interestedindustry}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>
        <div className="col-12 " style={{ height: "100%" }}>
        <div className="d-flex flex-wrap">
          {initial2 && interestedindustry && interestedindustry.length>0 ? 
            interestedindustry.map((v,i)=>
                <>
                <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                  <p className="program_text">{v}</p>
                </span>
              </>
              )
          : null}
          </div>
        </div>
        
        <div className="col-12">
          <label className="auth_label">
            Interested Business Models*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={interestedmodel}
            onChange={(e) => setinterestedmodel(e.target.value)}
          >
            <option className="color_black" value="">
              Select business model
            </option>
            <option className="color_black" value="Business to Consumer">
              Business to Consumer
            </option>
            <option className="color_black" value="Business to Business">
              Business to Business
            </option>
            <option className="color_black" value="Consumer to Business">
              Consumer to Business
            </option>
            <option className="color_black" value="Consumer to Consumer">
              Consumer to Consumer
            </option>
          </select>
        </div>

        <div className="col-12">
          <label className="auth_label">Interested Funding Stages*</label>
            <select
              className="auth_input"
              name="grossValue"
              value={interestedstage}
              onChange={(e) => setinterestedstage(e.target.value)}
            >
                <option className="color_black" value="">
                  Select funding stages
                </option>
                <option className="color_black" value="Pre-Seed">
                  Pre-Seed
                </option>
                <option className="color_black" value="Seed">
                  Seed
                </option>
                <option className="color_black" value="Series A">
                  Series A
                </option>
                <option className="color_black" value="Series B">
                  Series B
                </option>
                <option className="color_black" value="All">
                  All of the above
                </option>
                <option className="color_black" value="Not sure">
                  Not sure
                </option>
            </select>
        </div>

        <div className="col-12">
          <label className="auth_label">Average Investment Check Size*</label>
            <select
              className="auth_input"
              name="grossValue"
              value={chequsize}
              onChange={(e) => setchequsize(e.target.value)}
            >
                <option className="color_black" value="">
                  Select Investment Check Size
                </option>
                <option className="color_black" value="0 - 10 Thousands">
                  0 - 10 Thousands
                </option>
                <option className="color_black" value="10 Thousands - 50 Thousands">
                  10 Thousands - 50 Thousands
                </option>
                <option className="color_black" value="50 Thousands - 5 Millions">
                  50 Thousands - 5 Millions
                </option>
                <option className="color_black" value="5 Millions - 20 Millions">
                  5 Millions - 20 Millions
                </option>
                <option className="color_black" value="20 Millions - 100 Millions">
                  20 Millions - 100 Millions
                </option>
                <option className="color_black" value="More than 100 Millions">
                  More than 100 Millions
                </option>
            </select>
        </div>

{/*         
        <div className="col-12">
          <FormLabel id="demo-radio-buttons-group-label">
            What is your motivation for mentoring?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(e) => setmotivation(e.target.value)}
            // style={{ display: "flex", flexDirection: "row" }}
            className="row flex-row"
          >
            <FormControlLabel
              value="Stay in touch with the field"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Stay in touch with the field"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Recognition"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Recognition"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Giving back"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Giving back"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Professional satisfaction"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Professional satisfaction"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Access to ecosystem/Networking"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Access to ecosystem/Networking"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Other"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Other"
              className="col-12 col-md-6 mt-8"
            />
          </RadioGroup>
          {motivation === "Other" ? (
            <>
              <label className="auth_label">
                Please mention your motivation (if other) *
              </label>
              <input
                placeholder="Motivation"
                type="text"
                className="auth_input col-12"
                value={othermotivation}
                onChange={(e) =>
                  setothermotivation(e.target.value)
                }
              />
            </>
          ) : null}
        </div> */}

        
        <div className="col-12">
          <label className="auth_label">
            Investment Criteria*
          </label>
          <div className="row">
            {investmentcriteria && investmentcriteria.map((v, i) => (
              <>
                <FormControlLabel
                  key={i}
                  className="col-12"
                  control={
                    <Checkbox
                      sx={{
                        color: "#262C5B",
                        "&.Mui-checked": {
                          color: "#262C5B",
                        },
                        marginRight: "10px",
                      }}
                      checked={v.isSelected}
                      onChange={(e) =>
                        handleMultiselect(e, v.name, i)
                      }
                      name={" " + v.name}
                    />
                  }
                  label={v.name}
                />
              </>
            ))}
          </div>
        </div>

        
        <div className="col-12">
          <label className="auth_label">
            How do you typically support your portfolio companies apart from the funding*
          </label>
          <div className="row">
            {support.map((v, i) => (
              <>
                <FormControlLabel
                  key={i}
                  className="col-12"
                  control={
                    <Checkbox
                      sx={{
                        color: "#262C5B",
                        "&.Mui-checked": {
                          color: "#262C5B",
                        },
                        marginRight: "10px",
                      }}
                      checked={v.isSelected}
                      onChange={(e) =>
                        handleMultiselectsupport(e, v.name, i)
                      }
                      name={" " + v.name}
                    />
                  }
                  label={v.name}
                />
              </>
            ))}
          </div>
        </div>

        
        <div className="col-12">
          <label className="auth_label">Enabling Technologies*</label>
          {enablingtechnologies.map((v, i) => (
            <>
            <FormControlLabel
              key={i}
              className="col-12"
              control={
                <Checkbox
                  sx={{
                    color: "#262C5B",
                    "&.Mui-checked": {
                      color: "#262C5B",
                    },
                    marginRight: "10px",
                  }}
                  checked={v.isSelected}
                  onChange={(e) =>
                    handleMultiselecttech(e, v.name, i)
                  }
                  name={" " + v.name}
                />
              }
              label={v.name}
            />
          </>
          ))}

          {showother ? (
            <>
              <input
                type="text"
                placeholder="Please specify..."
                className="auth_input"
                value={otherenabling}
                onChange={(e) => setotherenabling(e.target.value)}
              />
            </>
          ) : null}
        </div>


      </div>
    </form>
  );
};

export default Step2;
