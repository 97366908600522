import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../Utils/Auth'
import './index.css';
import UpcommingEvents from '../../Components/UpcommingEvents'
import { useNavigate } from 'react-router-dom';
// import HomeConnections from '../../Components/HomeRequests';
// import add from "../../../Assets/images/Home/add.png"
// import addLogo from "../../../Assets/images/Home/addLogo.png"
import Advertisement from "./../../Components/Advertisement"
import Programs from '../../Components/Programs'
import Meetings from "./../../Components/Meetings"
import HomeConnections from '../../Components/HomeRequests'
import OnBoard from "./../../Components/OnBoard"
import Tasks from '../../Components/Tasks/Tasks';
import DashboardConversation from "./../../Components/DashboardConversation"
// import { Link } from "react-router-dom"; 
import Axios from "axios"
import Startups from '../../Components/Startups/Startups';
import Recommendations from '../../Components/Recommendations/Recommendations';

export default function Home({ seteventState, setconnectionState,
    // setmentorState
}) {
    const navigate = useNavigate()
    const { user, setSessionExpire } = useAuth()
    const [incubationBool] = useState(0);

    const [userData, setUserData] = useState([])

    const getTasks = async () => {
        await Axios({
            url: `/api/dashboard/mentorship/mentor`,
            method: "get"
        }).then((res) => {
            setUserData(res.data.data)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    useEffect(() => {
        getTasks()
    }, [])

    return (
        <div>
            <div className='row'>
                <div className='col-lg-8 col-sm-12 p-0'>
                    <div className='dashboardhome01_banner1'>
                        <h4 className='color_dark_blue'>Hi {user.firstname}</h4>
                        <h6 className='color_dark_blue'>Welcome to Zefyron Incubation!</h6>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mt-16'>
                    <Advertisement />
                </div>
            </div>
            <div className="row mt-24">
                <div className="col-12 col-lg-8">
                    <Programs seteventState={seteventState} incubationBool={incubationBool} />
                </div>
                <div className="col-12 col-lg-4">
                    <Meetings />
                </div>
            </div>
            <div className="row mt-24">
                {
                    userData && userData.length > 0 ? <>
                        <div className="col-12 col-lg-6">
                            <Tasks
                                userData={userData}
                            />
                        </div>

                        <div className="col-12 col-lg-3">
                            <HomeConnections setconnectionState={setconnectionState} />
                            {/* <Recommendations/> */}
                        </div>
                    </> : <>
                        <div className="col-12 col-lg-6">
                            <Startups/>
                        </div>

                        <div className="col-12 col-lg-3">
                            <Recommendations/>
                        </div>
                    </>
                }

                <div className="col-12 col-lg-3">
                    <OnBoard />
                </div>
            </div>

            <div className="row mt-24">
                <div className="col-lg-4 col-sm-12">
                    <UpcommingEvents seteventState={seteventState} />
                </div>
                <div className="col-lg-8 col-sm-12">

                    <div className='row m-0'>
                        <div className='col-lg-6 col-sm-12 p-0'>
                            <div className='dashboard01_block mt-0 py-3 mb-3 h-fit'>
                                <div className="d-flex mt-3">
                                    <svg className="mx-3" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.0026 9.82536C10.3519 9.82536 9.82927 10.348 9.82927 10.9987C9.82927 11.6494 10.3519 12.172 11.0026 12.172C11.6533 12.172 12.1759 11.6494 12.1759 10.9987C12.1759 10.348 11.6533 9.82536 11.0026 9.82536ZM11.0026 0.332031C5.1146 0.332031 0.335938 5.1107 0.335938 10.9987C0.335938 16.8867 5.1146 21.6654 11.0026 21.6654C16.8906 21.6654 21.6693 16.8867 21.6693 10.9987C21.6693 5.1107 16.8906 0.332031 11.0026 0.332031ZM13.3386 13.3347L4.6026 17.3987L8.6666 8.6627L17.4026 4.5987L13.3386 13.3347Z" fill="url(#paint0_linear_2808_27671)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_2808_27671" x1="20.5808" y1="0.332032" x2="-2.91135" y2="4.8833" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#262C5B" />
                                                <stop offset="1" stopColor="#580167" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="caption mr-2" style={{ color: "#262C5B" }}>Explore India’s first largest outreach service provider</span>
                                </div>
                                <p className='overline m-3' style={{ color: "#989898" }}>
                                    Scout for best deal and get your work done by service providers. ur work done by service prur work done by service prur work done by service prur work done by service pruone
                                </p>
                                <div className="row justify-content-end mx-1 my-1">
                                    <div onClick={()=>{
                                             setconnectionState(1)
                                             navigate(`/dashboard/${user.type}?tab=2`)
                                         }} 
                                        style={{cursor:"pointer"}} className="d-flex">
                                        <span className="mr-3 caption" style={{ color: "#565656" }}>Host now</span>
                                        <p className="w_fit bold">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='dashboardhome01_banner2'>
                                <p className='font18 bold'>
                                    Explore our network
                                    <br /> to
                                    find curated
                                    <br /> connections !
                                </p>
                                <p className='bold font12 mt-2' onClick={()=>{
                                        setconnectionState(1)
                                        navigate(`/dashboard/${user.type}?tab=2`)
                                    }}
                                    style={{cursor:"pointer"}}>
                                    Find Connections
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                    </svg>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-sm-12 ps-2'>
                            <DashboardConversation />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
