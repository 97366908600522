import React, { useState, useEffect } from 'react'
import { KeyboardBackspace } from "@mui/icons-material"
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from "../../../../Utils/Auth";
import { nanoid } from 'nanoid'
import { useLocation } from 'react-router-dom';

const AddAssetsForm = ({ setMyAssetState, getRealEstate }) => {
    const [realEstateName, setRealEstateName] = useState("");
    const [realEstateId, setRealEstateId] = useState(nanoid());
    const [realEstateManager, setRealEstateManager] = useState("");
    const [status, setStatus] = useState("");
    const [stock, setStock] = useState("");
    const [category, setCategory] = useState("");
    const [otherCategory, setOtherCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [areaSize, setAreaSize] = useState("");
    const [details, setDetails] = useState("");
    // const [detailsArr, setDetailsArr] = useState([]);
    const [availableFrom, setAvailableFrom] = useState("");
    const [availableTill, setAvailableTill] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [seats, setSeats] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false)

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const estateId = query.get("estate");

    const { setSessionExpire } = useAuth();

    const handleCreateEstate = async (e) => {
        e.preventDefault()
        if (realEstateName || realEstateId || realEstateManager || status || stock || category || subCategory || areaSize || availableFrom || availableTill || price || seats) {
            await Axios({
                url: `/api/realEstate/create`,
                method: 'post',
                data: {
                    real_estate_name: realEstateName,
                    real_estate_id: realEstateId,
                    real_estate_manager: realEstateManager,
                    status: status,
                    stock: stock,
                    category: category === "Other" ? otherCategory : category,
                    sub_category: subCategory,
                    area_size: areaSize,
                    details: details,
                    available_from: availableFrom,
                    available_till: availableTill,
                    price: price,
                    discount: discount,
                    seats: seats === null ? 0 : seats,
                }
            }).then(() => {
                setRealEstateName("")
                setRealEstateId("")
                setRealEstateManager("")
                setStatus("")
                setStock("")
                setCategory("")
                setSubCategory("")
                setAreaSize("")
                setDetails("")
                // setDetailsArr([])
                setAvailableFrom("")
                setAvailableTill("")
                setPrice("")
                setSeats(0)
                setDiscount("");
                setMyAssetState(0);
                toast.success("Real Estate Created")
                getRealEstate()
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true)
                }
            })
        }
        else {
            toast.warning("Fill All the fields");
        }
    }

    const getEstaeData = async () => {
        await Axios({
            url: `/api/realEstate/estate/${estateId}`,
            method: 'GET'
        }).then((res) => {
            const { data } = res.data;
            setRealEstateName(data.real_estate_name)
            if (data.real_estate_name) {
                setIsUpdate(true)
            }
            setRealEstateManager(data.real_estate_manager)
            setStatus(data.status)
            setRealEstateId(data.real_estate_id)
            setStock(data.stock)
            setCategory(data.category)
            setSubCategory(data.sub_category)
            setSeats(data.seats)
            setAreaSize(data.area_size)
            setDetails(data.details)
            setAvailableFrom(new Date(data.available_from).toLocaleDateString('fr-CA'))
            setAvailableTill(new Date(data.available_till).toLocaleDateString('fr-CA'))
            setPrice(data.price)
            setDiscount(data.discount)
        }).catch((err) => {
            console.log(err)
        })
    }

    const UpdateEstate = async (e) => {
        e.preventDefault()
        if (realEstateName || realEstateId || realEstateManager || status || stock || category || subCategory || areaSize || availableFrom || availableTill || price || seats) {
            await Axios({
                url: `/api/realEstate/update/${estateId}`,
                method: 'put',
                data: {
                    real_estate_name: realEstateName,
                    real_estate_id: realEstateId,
                    real_estate_manager: realEstateManager,
                    status: status,
                    stock: stock,
                    category: category === "Other" ? otherCategory : category,
                    sub_category: subCategory,
                    area_size: areaSize,
                    details: details,
                    available_from: availableFrom,
                    available_till: availableTill,
                    price: price,
                    discount: discount,
                    seats: seats === null ? 0 : seats,
                }
            }).then(() => {
                setRealEstateName("")
                setRealEstateId("")
                setRealEstateManager("")
                setStatus("")
                setStock("")
                setCategory("")
                setSubCategory("")
                setAreaSize("")
                setDetails("")
                // setDetailsArr([])
                setAvailableFrom("")
                setAvailableTill("")
                setPrice("")
                setSeats(0)
                setDiscount("");
                setMyAssetState(0);
                toast.success("Real Estate Created")
                getRealEstate()
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true)
                }
            })
        }
        else {
            toast.warning("Fill All the fields");
        }
    }

    useEffect(() => {
        if (estateId) {
            getEstaeData()
        }
    }, [estateId])

    return (
        <div className='coworking_addAssets_form_root'>
            <div className="coworking_addAssets_form_head">
                <button className='coworking_back_btn btncta' onClick={() => {
                    if(estateId)
                    {
                        setMyAssetState(0)
                    }
                    else{
                        setMyAssetState(0)
                        getRealEstate()
                    }
                }}>
                    <span><KeyboardBackspace /></span>
                    <span>BACK</span>
                </button>
            </div>
            <div className="coworking_addAssets_form_main w_100">
                <div className="coworking_addAssets_form_div">
                    <div className="coworking_addAssets_form_main2">
                        <h4>Add Real Estate</h4>
                    </div>

                    <form className='coworking_addAssets_form'>
                        <div className="row">
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="realEstateName" className='caption color_dark_blue'>Real Estate name</label>
                                    <input
                                        type="text"
                                        placeholder='Enter Real Estate name'
                                        className='body1'
                                        value={realEstateName}
                                        onChange={(e) => setRealEstateName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Real Estate ID</label>
                                            <input
                                                type="text"
                                                placeholder='Enter the Real Estate ID'
                                                className='body1'
                                                value={realEstateId}
                                                onChange={(e) => setRealEstateId(e.target.value)}
                                                required
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Real Estate manager</label>
                                            <input
                                                type="text"
                                                placeholder='Enter the Real Estate manager'
                                                className='body1'
                                                value={realEstateManager}
                                                onChange={(e) => setRealEstateManager(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Status</label>
                                            <select
                                                name="status"
                                                className='body1'
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                required
                                            >
                                                <option value="" className='body1'>Select status of Real Estate</option>
                                                <option value="active" className='body1'>Active</option>
                                                <option value="inactive" className='body1'>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Quantity</label>
                                            <input
                                                type="number"
                                                placeholder='Enter total available Real Estate'
                                                className='body1'
                                                value={stock}
                                                onChange={(e) => setStock(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Category</label>
                                            <select
                                                name="status"
                                                className='body1'
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                required
                                            >
                                                <option value="" className='body1'>Select the Real Estate category</option>
                                                <option value="Confrence Room" className='body1'>Confrence Room</option>
                                                <option value="Hall" className='body1'>Hall</option>
                                                <option value="Projector Room" className='body1'>Projector Room</option>
                                                <option value="Auditorium" className='body1'>Auditorium</option>
                                                <option value="cabin" className='body1'>Cabin</option>
                                                <option value="Other" className='body1'>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        category === "Other" ? <div className="col-lg-6 col-md-6 col-12">
                                            <div className="coworking_addAssets_input_main">
                                                <label htmlFor="assetName" className='caption color_dark_blue'>Other Category</label>
                                                <input
                                                    type="text"
                                                    placeholder='Enter Other category of Real Estate'
                                                    className='body1'
                                                    value={otherCategory}
                                                    onChange={(e) => setOtherCategory(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div> : null
                                    }
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Sub-category</label>
                                            <input
                                                type="text"
                                                placeholder='Enter sub-category of Real Estate'
                                                className='body1'
                                                value={subCategory}
                                                onChange={(e) => setSubCategory(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>No. of Available Seats</label>
                                            <input
                                                type="number"
                                                placeholder='Enter number of seats'
                                                className='body1'
                                                value={seats}
                                                onChange={(e) => setSeats(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Size of one seat (sqft.)</label>
                                            <input
                                                type="number"
                                                placeholder='Enter number of seats'
                                                className='body1'
                                                value={areaSize}
                                                onChange={(e) => setAreaSize(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="assetName" className='caption color_dark_blue'>Details</label>
                                    {/* <div className="coworking_addAssets_input_add"> */}
                                    <input
                                        type="text"
                                        placeholder='Write details of the Real Estate'
                                        className='body1'
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                    />
                                    {/* <button onClick={() => {
                                            setDetailsArr((prev) => [...prev, details])
                                            setDetails("")
                                        }}>+</button> */}

                                    {/* </div> */}
                                </div>
                            </div>
                            {/* {
                                detailsArr.length > 0 ?
                                    <div className="col-12 mb-12">
                                        {
                                            detailsArr.map((detail, i) => <div className="asset_details_arr mb-6" key={i}>
                                                <p className="caption">{detail}</p>
                                                <button onClick={() => {
                                                    const newArr = detailsArr.filter((data, id) => id !== i)
                                                    setDetailsArr(newArr)
                                                }} >X</button>
                                            </div>)
                                        }

                                    </div> : null

                            } */}
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Availablefrom" className='caption color_dark_blue'>Available from</label>
                                            <input
                                                type="date"
                                                id="Availablefrom"
                                                className='body1'
                                                value={availableFrom}
                                                onChange={(e) => setAvailableFrom(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Availabletill" className='caption color_dark_blue'>Available  till</label>
                                            <input
                                                type="date"
                                                id="Availabletill"
                                                className='body1'
                                                value={availableTill}
                                                onChange={(e) => setAvailableTill(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Pricing" className='caption color_dark_blue'>Pricing</label>
                                            <input
                                                type="number"
                                                id="Pricing"
                                                placeholder='€1,000'
                                                className='body1'
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Discount" className='caption color_dark_blue'>Discount % (optional)</label>
                                            <input
                                                type="text"
                                                id="Discount"
                                                placeholder='Enter only if applicable'
                                                className='body1'
                                                value={discount}
                                                onChange={(e) => setDiscount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" >
                                <div className="coworking_addAssets_btn_main">
                                    {
                                        isUpdate ?
                                            <button className='primaryFilled_activeCta' type='submit' onClick={UpdateEstate}>UPDATE REAL ESTATE</button> :
                                            <button className='primaryFilled_activeCta' type='submit' onClick={handleCreateEstate}>ADD REAL ESTATE</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddAssetsForm