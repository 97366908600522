import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/Auth";
import LogoNavbar from "../../../Layout/Navbar/LogoNavbar";
import NotFoundPage from "../../404Pages";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import LocationComponent from "../../../Utils/Location/index";
import { useNavigate } from "react-router-dom";
import cloud_upload from "../../../Assets/images/upload_doc.png";
import Delete from "../../../Assets/images/incubatorDash/delete.png"

const Edit_PreStart = () => {
    const { user, profile, getUser } = useAuth();
    const [country, setCountry] = useState("");
    const [state, setstate] = useState("");
    const [city, setcity] = useState("");
    const [teamName, setteamName] = useState();
    const [productDetails, setproductDetails] = useState("");
    const [productStage, setproductStage] = useState("");
    const [profilepic, setprofilepic] = useState(null);
    const [islogoloading, setislogoloading] = useState(false);
    const [cities, setcities] = useState(null);
    const [states, setstates] = useState(null);
    const [isdocloading, setisdocloading] = useState(false);
    const [docUrl, setDocUrl] = useState(null);

    const navigate = useNavigate();

    const handleUpdate = async () => {
        if (profilepic === null) return toast.warning("Upload profile image");
        if (
            profilepic ||
            teamName ||
            productDetails ||
            productStage ||
            cities ||
            state ||
            country
        ) {
            await Axios({
                method: "post",
                url: `/api/prestartup/update/${profile._id}`,
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    profile_pic: profilepic,
                    teamName: teamName,
                    Location: {
                        country: country,
                        state: state,
                        city: city,
                    },
                    pitchdeck: docUrl,
                    productDetails: productDetails,
                    productStage: productStage,
                },
            })
                .then((res) => {
                    if (res.data.success) {
                        navigate("/dashboard/prestartup?tab=2");
                        toast.success("Registration Complete", { position: "bottom-right" });
                        getUser()
                    }
                })
                .catch(() => {
                    toast.error("Error in upload", { position: "bottom-right" });
                });
        } else {
            toast.warning("All Fields are required");
        }
    };

    const handleLogoUpload = (e) => {
        const image = e.target.files[0];

        if (
            image.type !== "image/jpg" &&
            image.type !== "image/png" &&
            image.type !== "image/jpeg"
        ) {
            toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
            return;
        }
        if (image.size > 2048 * 1024) {
            toast.error("File size exceeded 2MB!", { position: "bottom-right" });
            return;
        }
        const formdata = new FormData();
        formdata.append("prestartup-profile", image);
        setislogoloading(true);
        Axios({
            method: "post",
            url: "/api/prestartup/profile",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                if (data) {
                    setprofilepic(data.location);
                } else {
                    toast.error("Error in upload", { position: "bottom-right" });
                }
                // setProfile({
                //     ...profile,
                //     profilepic: data.location,
                // });
                setislogoloading(false);
                toast.success("Profile Picture uploaded", { position: "bottom-right" });
                // setProgress(25);
            })
            .catch(() => {
                toast.error("Error in upload", { position: "bottom-right" });
            });
    };

    const handlePitchdeckUpload = (e) => {
        setisdocloading(true)
        const image = e.target.files[0];
        if (image.type !== "application/pdf" && image.type !== "application/vnd.openxmlformats-officedocument.presentationml.presentation" && image.type !== "application/vnd.ms-powerpoint") {
            toast.error("Upload PDF/PPT File!", { position: "bottom-right" });
            setisdocloading(false)
            return;
        }
        if (image.size > 1024 * 5 * 1024) {
            toast.error("File size exceeded 5MB!", { position: "bottom-right" });
            setisdocloading(false)
            return;
        }
        const formdata = new FormData();
        formdata.append("prestartup-pitchdeck", image);
        Axios({
            method: "post",
            url: "/api/prestartup/prestartup/pitchdeck",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                if (data) {
                    setDocUrl(data.location);
                    toast.success("Pitch Deck Uploaded", { position: "bottom-right" });
                    setisdocloading(false)
                }
            })
            .catch(() => {
                setisdocloading(false)
                toast.error("Error in upload", { position: "bottom-right" });
            });
    };

    useEffect(()=>{
        if(profile)
        {
            if(profile.teamName)
            {
                setteamName(profile.teamName)
            }
            if(profile.profile_pic)
            {
                setprofilepic(profile.profile_pic)
            }
            if(profile.productStage)
            {
                setproductStage(profile.productStage)
            }
            if(profile.productDetails)
            {
                setproductDetails(profile.productDetails)
            }
            if(profile.pitchdeck)
            {
                setDocUrl(profile.pitchdeck)
            }
            if(profile.Location)
            {
                if(profile.Location.country)
                {
                    setCountry(profile.Location.country)
                }
                if(profile.Location.state)
                {
                    setstate(profile.Location.state)
                }
                if(profile.Location.city)
                {
                    setcity(profile.Location.city)
                }
            }
        }
    },[user, profile])

    if (!user) return <NotFoundPage />;
    return (
        <div className="regis">
            <LogoNavbar />

            <div className="register_page">
                {/* -------------STEP 1------------------ */}
                <div className="container pt-5">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-4">
                            <div className="register_page_sideborder">
                                <div className="mb-16">
                                    <a
                                        href="#part1"
                                        className="register_page_sideborder-head w-100"
                                    >
                                        Pre-Startup Details
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-1"></div> */}
                        <div className="col-12 col-md-8">
                            <div className="register_page_form" id="part1">
                                <form>
                                    <div className="register_page_form_upload">
                                        {islogoloading ? (
                                            <CircularProgress style={{ color: "black" }} />
                                        ) : (
                                            <>
                                                {profilepic ? (
                                                    <>
                                                        <img
                                                            src={profilepic}
                                                            alt="profilepic"
                                                            className="companyLogo"
                                                        />
                                                        <p
                                                            className="p_small_black mt-2"
                                                            onClick={() => setprofilepic(null)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Delete
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label htmlFor="clogo">
                                                            <div
                                                                className="register_page_form_com_logo"
                                                                onClick={{}}
                                                            >
                                                                Upload
                                                            </div>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                name="profilepic_pic"
                                                                id="clogo"
                                                                value={profilepic}
                                                                onChange={handleLogoUpload}
                                                            />
                                                        </label>
                                                        <p className="register_page_form_upload-text mt-1">
                                                            Profile Image
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div className="register_page_form row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className=" col-12">
                                                    <label>Team Name*</label>
                                                    <input
                                                        id="scompanyname"
                                                        placeholder="Enter Team Name"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={teamName}
                                                        onChange={(e) => setteamName(e.target.value)}
                                                    // disabled
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label>Product Details*</label>
                                            <textarea
                                                id="scompanyname"
                                                placeholder="Enter Product Details"
                                                required
                                                type="text"
                                                className="auth_input"
                                                value={productDetails}
                                                onChange={(e) => setproductDetails(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label>Product Stage*</label>
                                                    <select
                                                        placeholder=""
                                                        required
                                                        id="swebsite"
                                                        type="text"
                                                        className="auth_input"
                                                        value={productStage}
                                                        onChange={(e) => setproductStage(e.target.value)}
                                                    >
                                                        <option value="">
                                                            Choose Your Current Product Stage
                                                        </option>
                                                        <option value="Developing Prototype">Developing Prototype</option>
                                                        <option value="Functional product with no customer">Functional product with no customer</option>
                                                        <option value="Functional product with customer">Functional product with customer</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label>Location*</label>
                                        </div>
                                        <div className="col-12">
                                            <LocationComponent
                                                country={country}
                                                setcountry={setCountry}
                                                state={state}
                                                setstate={setstate}
                                                city={city}
                                                setcity={setcity}
                                                states={states}
                                                cities={cities}
                                                setstates={setstates}
                                                setcities={setcities}
                                            />
                                        </div>
                                        {/* pitch dech */}
                                        <div className="col-12 mt-16 create_course_form_maindiv">
                                            {
                                                isdocloading ? <CircularProgress style={{ color: "black" }} /> : <>
                                                    {
                                                        docUrl ? <div className="program_upload_imglater">
                                                            <div className="d-flex align-items-center">
                                                                <div className="program_upload_imgImg">
                                                                    <img src={cloud_upload} alt="cloud_upload" style={{ padding: "20px" }} />
                                                                </div>
                                                                <div className="program_upload_imgName">
                                                                    <p className='caption color_dark_blue'>Pitch Deck </p>
                                                                </div>
                                                            </div>
                                                            <div className="program_upload_imgdlt">
                                                                <img src={Delete} alt="cloud_upload" style={{ padding: "20px", cursor: "pointer" }} onClick={() => setDocUrl(null)} />
                                                            </div>
                                                        </div> : <div className="program_upload_img">
                                                            {/* <label htmlFor="uplaodImg"> */}
                                                            <img src={cloud_upload} alt="cloud_upload" style={{ padding: "20px" }} />
                                                            <p className="caption color_dark_blue">Upload Pitch Deck</p>
                                                            {/* </label> */}
                                                            <input
                                                                type="file"
                                                                id="uplaodImg"
                                                                onChange={handlePitchdeckUpload}
                                                                accept="application/pdf,application/vnd.ms-powerpoint,.ppt, .pptx"
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }

                                        </div>
                                        
                                        <div className="col-12 my-5  d-flex justify-content-center align-items-center">
                                            <button
                                                type="button"
                                                className="primaryFilled_activeCta"
                                                onClick={handleUpdate}
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit_PreStart;
