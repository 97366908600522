import React from "react";  
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Multiselect from "multiselect-react-dropdown";

const Step2 = (props) => {
  const {
    experiencetime,
    setexperiencetime,
    experience,
    setExperience,
    preferredtime,
    setpreferredtime,
    motivation,
    setmotivation,
    othermotivation,
    setothermotivation,
    involvement,
    options,
    interest,
    addInputEvent3,
    

    handleMultiselect,

  } = props;

  return (
    <form >
      <div className="register_page_form row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <label className="auth_label">
                  Years of Experience
                </label>
                <input
                  placeholder="Enter your past experience "
                  required
                  type="text"
                  className="auth_input"
                  value={experiencetime}
                  onChange={(e) => setexperiencetime(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <label className="auth_label">
                  No. of Ventures Mentored
                </label>
                <input
                  placeholder="Enter your past experience "
                  required
                  type="text"
                  className="auth_input"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        
        <div className="col-12">
          <label className="auth_label">
          Area of Expertise
          </label>
          <Multiselect
            isMulti
            options={options}
            // className="auth_input"
            displayValue="label"
            placeholder="Select industry"
            name="industries"
            closeIcon="cancel"
            value={interest}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>

        <div className="col-12">
        <label className="auth_label">
        What kind of time commitment are you willing to make as a mentor?
        </label>
        <input
          placeholder="number of hours a week "
          type="text"
          className="auth_input col-12"
          value={preferredtime}
          onChange={(e) =>
            setpreferredtime(e.target.value)
          }
        />
        </div>

        
        <div className="col-12">
          <FormLabel id="demo-radio-buttons-group-label">
            What is your motivation for mentoring?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(e) => setmotivation(e.target.value)}
            // style={{ display: "flex", flexDirection: "row" }}
            className="row flex-row"
          >
            <FormControlLabel
              value="Stay in touch with the field"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Stay in touch with the field"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Recognition"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Recognition"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Giving back"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Giving back"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Professional satisfaction"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Professional satisfaction"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Access to ecosystem/Networking"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Access to ecosystem/Networking"
              className="col-12 col-md-6 mt-8"
            />
            <FormControlLabel
              value="Other"
              control={
                <Radio
                  sx={{
                    color: "#FF6F1E  !important",
                    "&.Mui-checked": {
                      color: "#FF6F1E",
                    },
                    marginRight: "8px",
                    marginTop: " 8px",
                  }}
                />
              }
              label="Other"
              className="col-12 col-md-6 mt-8"
            />
          </RadioGroup>
          {motivation === "Other" ? (
            <>
              <label className="auth_label">
                Please mention your motivation (if other)
              </label>
              <input
                placeholder="Motivation"
                type="text"
                className="auth_input col-12"
                value={othermotivation}
                onChange={(e) =>
                  setothermotivation(e.target.value)
                }
              />
            </>
          ) : null}
        </div>

        
        <div className="col-12">
          <label className="auth_label">
            How would you like to be involved in your mentees journey?
          </label>
          <div className="row">
            {involvement.map((v, i) => (
              <>
                <FormControlLabel
                  key={i}
                  className="col-12"
                  control={
                    <Checkbox
                      sx={{
                        color: "#262C5B",
                        "&.Mui-checked": {
                          color: "#262C5B",
                        },
                        marginRight: "10px",
                      }}
                      onChange={(e) =>
                        handleMultiselect(e, v.name, i)
                      }
                      name={" " + v.name}
                    />
                  }
                  label={v.name}
                />
              </>
            ))}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Step2;
