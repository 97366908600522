import React, { useState, useEffect } from "react";
import Axios from "axios";
import Avatar from "@mui/material/Avatar";
import "./connections.css";
import { Chip } from "@mui/material";
// import Toast from "../../../components/Toast";
import { KeyboardArrowLeft } from "@mui/icons-material";

import { useAuth } from "../../../Utils/Auth";
import { useNavigate, useLocation } from "react-router-dom";
// import moment from "moment-timezone";
import { toast } from "react-toastify";
import IncubatorPrograms from "./IncubatorPrograms";
import SPServices from "./SpServices";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ConnectionComponent({ setconnectionState }) {
  const { user } = useAuth();
  const [, setLastStep] = useState(1);
  // const [isPost, setIsPost] = useState(false);
  const history = useNavigate();
  const [sentRequest, setsentRequest] = useState(false);
  const handleConnect = async (id) => {
    await Axios({
      url: "/api/connection/send",
      method: "post",
      data: { id },
    })
      .then(() => {
        toast("Request Sent");
        setsentRequest(true);
      })
      .catch((err) => {
        toast(err.response.data.message);
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  // location.state ? setIsPost(true):setIsPost(false);

  const goToChats = () => {
    history(`/dashboard/${user.type}?tab=11`);
  };

  const query = useQuery();

  const startup = query.get("startup");
  const investor = query.get("investor");
  const corporate = query.get("corporate");
  const fundcompany = query.get("fundcompany");
  const incubator = query.get("incubator");
  const mentor = query.get("mentor");
  const sp = query.get("serviceprovider");

  const { setSessionExpire } = useAuth();
  const [connection, setConnection] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [investorDetails, setInvestorDetails] = useState(null);
  const [corporateDetails, setCorporateDetails] = useState(null);
  const [fundDetail, setFundDetails] = useState(null);
  const [fundmanagers, setfundmanagers] = useState([]);
  const [incubatorDetails, setincubatorDetails] = useState(null);
  const [mentorDetails, setmentorDetails] = useState(null);
  const [spDetails, setspDetails] = useState(null);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (startup) {
      const getDetail = async () => {
        setLastStep(step);
        await Axios({
          method: "get",
          url: `/api/startup/${startup}`,
        })
          .then((res) => {
            setUserDetails(res.data.startup);
            setConnection(res.data.connection);
            setStep(0);
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getDetail();
    }
  }, [startup]);

  useEffect(() => {
    if (investor) {
      window.scrollTo(0, 0);
      const getInvestorDetail = async () => {
        setLastStep(step);
        await Axios({
          method: "get",
          url: `/api/investor/${investor}`,
        })
          .then((res) => {
            setInvestorDetails(res.data.investor);
            setConnection(res.data.connection);
            setStep(0);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getInvestorDetail();
    }
  }, [investor]);

  useEffect(() => {
    if (corporate) {
      const getCorporatesDetails = async () => {
        setLastStep(step);
        // console.log(id);
        // console.log(curStep);
        await Axios({
          method: "get",
          url: `/api/corporate/corporate/${corporate}`,
        })
          .then((res) => {
            // console.log(res.data.data);
            setCorporateDetails(res.data.data);
            setConnection(res.data.connection);
            setStep(0);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getCorporatesDetails();
    }
  }, [corporate]);

  useEffect(() => {
    if (fundcompany) {
      const getfundcompanyDetails = async () => {
        setLastStep(step);
        await Axios({
          method: "get",
          url: `/api/fundcompany/by-user-id/${fundcompany}`,
        })
          .then((res) => {
            setFundDetails(res.data.fundCompany);
            setConnection(res.data.connection);
            setfundmanagers(res.data.funds);
            setStep(0);
          })
          .catch((err) => {
            if (err.response && err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getfundcompanyDetails();
    }
  }, [fundcompany]);

  useEffect(() => {
    if (incubator) {
      const getincubatorDetails = async () => {
        setLastStep(step);
        await Axios({
          method: "get",
          url: `/api/incubator/user-by-id/${incubator}`,
        })
          .then((res) => {
            setincubatorDetails(res.data.data);
            setConnection(res.data.connection);
          })
          .catch((err) => {
            if (err.response && err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getincubatorDetails();
    }
  }, [incubator]);

  useEffect(() => {
    if (mentor) {
      const getmentorDetails = async () => {
        setLastStep(step);
        await Axios({
          method: "get",
          url: `/api/mentor/user-by-id/${mentor}`,
        })
          .then((res) => {
            setmentorDetails(res.data.data);
            setConnection(res.data.connection);
          })
          .catch((err) => {
            if (err.response && err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getmentorDetails();
    }
  }, [mentor]);

  useEffect(() => {
    if (sp) {
      const getsp = async () => {
        setLastStep(step);
        await Axios({
          method: "get",
          url: `/api/sp/spbyid/${sp}`,
        })
          .then((res) => {
            setspDetails(res.data.data);
            setConnection(res.data.connection);
            setStep(0);
            window.scrollTo(0, 0);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              setSessionExpire(true);
            }
          });
      };
      getsp();
    }
  }, [sp]);

  const [isAccepted, setisAccepted] = useState(false);

  const handleAccept = async (id) => {
    console.log(id)
    await Axios({
      method: "get",
      url: `/api/connection/accept-connection/${id}`,
    })
      .then(() => {
        toast("Request Accepted");
        setisAccepted(true);
      })
      .catch((err) => {
        toast("Couldn't accept, try again");
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const RenderOneUser = (details) => {
    const profile = details[0];
    return (
      <>
        <div>
          <div
            className="back_CTA pointer"
            onClick={() => {
              setconnectionState(1);
              history(`/dashboard/${user.type}?tab=2`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.logo}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.companyName}</p>
                    <p className="bold">Startup | {profile.location.country}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.description}</p>
              </div>

              <div className="row mx-2 my-3">
                <p className="p_grey_chip">{profile.industry} </p>
              </div>
              <hr />

              <div className="">
                {profile.founder ? (
                  <>
                    <p className="p_spartan_20 mx-2 my-4">Founder Details</p>
                    <div className="row mx-0 my-4 align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={profile.profilePic}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                        <p className="">
                          {profile.founder.firstname} {profile.founder.lastname}
                        </p>
                        <p className="">{profile.contact.jobTitle} </p>
                        <p className="bold">
                          <a href={`${profile.socialConnects.linkedin}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}

                <div>
                  {user && user.id === profile.id ? null : (
                    <>
                    {!connection ? (
                      <button
                        className="gradient_CTA width_fit"
                        onClick={() => handleConnect(profile.id)}
                      >
                        {sentRequest ? "REQUESTED" : "CONNECT"}
                      </button>
                    ) : (
                      <>
                        {isAccepted ? (
                          <button
                            className="gradient_CTA width_fit"
                            onClick={() => goToChats(profile.id)}
                          >
                            CHAT NOW
                          </button>
                        ) : (
                          <>
                            {connection.sender === user.id ? (
                              <button
                                className="gradient_CTA width_fit"
                                disabled
                              >
                                REQUESTED
                              </button>
                            ) : (
                              <button
                                className="gradient_CTA width_fit"
                                onClick={() => {handleAccept(profile.id)}}
                              >
                                ACCEPT REQUEST
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  {profile.valuation && (
                    <tr>
                      <td className="td_left">Valuation</td>
                      <td className="td_right">$ {profile.valuation}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="td_left">Audience</td>
                    <td className="td_right">{profile.audience}</td>
                  </tr>

                  <tr>
                    <td className="td_left">Employees</td>
                    <td className="td_right">{profile.employees}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Funding Series</td>
                    <td className="td_right">{profile.fundingSeries}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Incorporation</td>
                    <td className="td_right">
                      {new Date(profile.incorporationDate).getFullYear()}
                    </td>
                  </tr>

                  <tr>
                    <td className="td_left">Website</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`//${profile.website}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile.website}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderInvestor = (details) => {
    const profile = details[0];
    // console.log(profile.location.country)
    return (
      <>
        <div>
          <div
            className="back_CTA pointer"
            onClick={() => {
              // setconnectionState(1)
              history(`/dashboard/${user.type}?tab=2&section=4`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.profilePic}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.name}</p>
                    <p className="bold">
                      Investor | {profile.location.country}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              {/* <div className="mt-4 mx-2">
                <p className="grey bold">{profile.description}</p>
              </div> */}

              <div className="row mx-2 my-3">
                {profile.interestedDomains &&
                profile.interestedDomains.length > 0
                  ? profile.interestedDomains.map((data, i) => (
                      <p className="p_grey_chip" key={i}>
                        {data}
                      </p>
                    ))
                  : null}
              </div>
              <hr />

              <div className="">
                {profile.founder ? (
                  <>
                    <p className="p_spartan_20 mx-2 my-4">Founder Details</p>
                    <div className="row mx-0 my-4 align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={profile.profilePic}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                        <p className="">
                          {profile.founder.firstname} {profile.founder.lastname}
                        </p>
                        <p className="">{profile.contact.jobTitle} </p>
                        <p className="bold">
                          <a href={`${profile.socialConnects.linkedin}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}

                <div>
                  {user && user.id === profile.id ? null : (
                    <>
                    {!connection ? (
                      <button
                        className="gradient_CTA width_fit"
                        onClick={() => handleConnect(profile.id)}
                      >
                        {sentRequest ? "REQUESTED" : "CONNECT"}
                      </button>
                    ) : (
                      <>
                        {isAccepted ? (
                          <button
                            className="gradient_CTA width_fit"
                            onClick={() => goToChats(profile.id)}
                          >
                            CHAT NOW
                          </button>
                        ) : (
                          <>
                            {connection.sender === user.id ? (
                              <button
                                className="gradient_CTA width_fit"
                                disabled
                              >
                                REQUESTED
                              </button>
                            ) : (
                              <button
                                className="gradient_CTA width_fit"
                                onClick={() => {handleAccept(profile.id)}}
                              >
                                ACCEPT REQUEST
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  <tr>
                    <td className="td_left">Invested Value</td>
                    <td className="td_right">{profile.investedValue}</td>
                  </tr>

                  {/* <tr>
                    <td className="td_left">Employees</td>
                    <td className="td_right">{profile.employees}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Funding Series</td>
                    <td className="td_right">{profile.fundingSeries}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Incorporation</td>
                    <td className="td_right">
                      {new Date(profile.incorporationDate).getFullYear()}
                    </td>
                  </tr> */}

                  <tr>
                    <td className="td_left">LinkedIn</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                    
                      <a
                        href={`${profile.socialConnects.linkedProfile}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ fontSize: 12 }}
                      >
                             <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderCorporate = (details) => {
    const profile = details[0];

    return (
      <>
        <div>
          <div
            className="back_CTA pointer"
            onClick={() => {
              setconnectionState(1);
              history(`/dashboard/${user.type}?tab=2&section=3`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>

        <hr />
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.logo}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.legalname}</p>
                    <p className="bold">
                      Corporate | {profile.location.country}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.fieldOfInterest}</p>
              </div>

              <div className="row mx-2 my-3">
                {profile.interestedIndustry &&
                profile.interestedIndustry.length > 0
                  ? profile.interestedIndustry.map((data, i) => (
                      <p className="p_grey_chip" key={i}>
                        {data}
                      </p>
                    ))
                  : null}
              </div>
              <hr />

              <div className="">
                {profile.founder ? (
                  <>
                    <p className="p_spartan_20 mx-2 my-4">Founder Details</p>
                    <div className="row mx-0 my-4 align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={profile.profilePic}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                        <p className="">
                          {profile.founder.firstname} {profile.founder.lastname}
                        </p>
                        <p className="">{profile.contact.jobTitle} </p>
                        <p className="bold">
                          <a href={`${profile.socialConnects.linkedin}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}

                <div>
                  {user && user.id === profile.id ? null : (
                    <>
                    {!connection ? (
                      <button
                        className="gradient_CTA width_fit"
                        onClick={() => handleConnect(profile.id)}
                      >
                        {sentRequest ? "REQUESTED" : "CONNECT"}
                      </button>
                    ) : (
                      <>
                        {isAccepted ? (
                          <button
                            className="gradient_CTA width_fit"
                            onClick={() => goToChats(profile.id)}
                          >
                            CHAT NOW
                          </button>
                        ) : (
                          <>
                            {connection.sender === user.id ? (
                              <button
                                className="gradient_CTA width_fit"
                                disabled
                              >
                                REQUESTED
                              </button>
                            ) : (
                              <button
                                className="gradient_CTA width_fit"
                                onClick={() => {handleAccept(profile.id)}}
                              >
                                ACCEPT REQUEST
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  {/* {profile.nameOfCorporateType && (
                    <tr>
                      <td className="td_left">Name of Corporate Type</td>
                      <td className="td_right"> {profile.nameOfCorporateType}</td>
                    </tr>
                  )} */}
                  <tr>
                    <td className="td_left">Type</td>
                    <td className="td_right">{profile.typeOfCorporate}</td>
                  </tr>

                  <tr>
                    <td className="td_left">LinkedIn</td>
                    <td className="td_right">
                      <a
                        href={`${profile.linkedin}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                     
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                  {/* <tr>
                    <td className="td_left">Funding Series</td>
                    <td className="td_right">{profile.fundingSeries}</td>
                  </tr> */}
                  {/* <tr>
                    <td className="td_left">Incorporation</td>
                    <td className="td_right">
                      {new Date(profile.incorporationDate).getFullYear()}
                    </td>
                  </tr> */}

                  {/* <tr>
                    <td className="td_left">Website</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`${profile.website}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile.website}
                      </a>
                    </td>
                  </tr> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderFundCompany = (details) => {
    const profile = details;

    return (
      <>
        <div>
          <div
            className="h5_medium_black font15 m-0"
            style={{
              color: "#000",
              cursor: "pointer",
              paddingLeft: "10px",
              width: "150px",
            }}
            onClick={() => {
              history(`/dashboard/${user.type}?tab=2&section=2`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>
        <div className="connection_profile_root" id="connection_profile_root">
          <div className="row mb-0 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
            <div className="row no-margin align-items-center col-8">
              <div className="width_auto no-padding">
                <Avatar
                  src={profile.profilePic}
                  className=""
                  alt="Profile"
                  sx={{ width: 90, height: 90 }}
                />
              </div>
              <div className="col-6 px-4">
                <p className="h5_medium_white bold width_auto no-margin">
                  {profile.companyName}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-md-6 text-right">
              {user && user.id === profile.id ? null : (
                <>
                {!connection ? (
                  <button
                    className="gradient_CTA width_fit"
                    onClick={() => handleConnect(profile.id)}
                  >
                    {sentRequest ? "REQUESTED" : "CONNECT"}
                  </button>
                ) : (
                  <>
                    {isAccepted ? (
                      <button
                        className="gradient_CTA width_fit"
                        onClick={() => goToChats(profile.id)}
                      >
                        CHAT NOW
                      </button>
                    ) : (
                      <>
                        {connection.sender === user.id ? (
                          <button
                            className="gradient_CTA width_fit"
                            disabled
                          >
                            REQUESTED
                          </button>
                        ) : (
                          <button
                            className="gradient_CTA width_fit"
                            onClick={() => {handleAccept(profile.id)}}
                          >
                            ACCEPT REQUEST
                          </button>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
              )}
            </div>
          </div>
          <div className="row no-margin p-4 white_bg">
            <div className="no-padding">
              <div className="connections_highlights_investor">
                <h5 className="h5_medium_black">Highlights</h5>
                <table>
                  <thead>
                    <tr>
                      <th className="table_title"></th>
                      <th className="table_description"></th>
                    </tr>
                  </thead>

                  <tr>
                    <td className="pe-3 left p_small_black_caption">
                      Location
                    </td>
                    <td className="right_td p_small_grey_bold">
                      {profile.location.country}
                    </td>
                  </tr>

                  <tr>
                    <td className="pe-3 left p_small_black_caption w_30 table_title">
                      Website
                    </td>
                    <td className="right_td p_small_grey_bold table_description">
                      {profile.website}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          {fundmanagers && fundmanagers.length > 0 ? (
            <div className="p-4 white_bg">
              <h5 className="h5_medium_black">Current Funds</h5>
              {fundmanagers.map((funds, i) => (
                <div key={i}>
                  <div className="row align-items-center justify-content-between connection_profile_head">
                    <div className="row no-margin align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={funds.fundlogo}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-6">
                        <p className="h5_medium_black width_auto no-margin">
                          {funds.fundName}
                        </p>
                      </div>
                    </div>
                    {/* <div className="col-3 text-right">
                    {user &&
                    (user.id === funds.id ||
                      user.id === funds.companyID) ? null : (
                      <>
                        {!connection ? (
                          <button
                            className="gradient_CTA width_fit"
                            onClick={() => handleConnect(funds.id)}
                          >
                            CONNECT
                          </button>
                        ) : (
                          <>
                            {connection.isAccepted ? (
                              <button
                                className="gradient_CTA width_fit"
                                onClick={() => goToChats(funds.id)}
                              >
                                CHAT NOW
                              </button>
                            ) : (
                              <>
                                {connection.sender === user.id ? (
                                  <button className="gradient_CTA width_fit" disabled>
                                    REQUESTED
                                  </button>
                                ) : (
                                  <button
                                    className="gradient_CTA width_fit"
                                    onClick={() => handleAccept(funds.id)}
                                  >
                                    ACCEPT REQUEST
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div> */}
                  </div>
                  <br />
                  <div className="pt-2">
                    <p className="bold">{funds.fundDescription}</p>
                  </div>

                  <div className="row no-margin pt-2">
                    <div className="no-padding">
                      <div className="connections_highlights_investor">
                        <table>
                          <thead>
                            <tr>
                              <th className="table_title"></th>
                              <th className="table_description"></th>
                            </tr>
                          </thead>

                          <tr>
                            <td className="pe-3 left p_small_black_caption">
                              Interested Industries
                            </td>
                            <td className="right_td p_small_grey_bold">
                              {funds.industries.map((ind, i) => (
                                <Chip key={i} label={ind} />
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <td className="pe-3 left p_small_black_caption">
                              Looking for Company Stage
                            </td>
                            <td className="right_td p_small_grey_bold">
                              {funds.currentStage.map((ind, i) => (
                                <Chip key={i} label={ind} />
                              ))}
                            </td>
                          </tr>

                          <tr>
                            <td className="pe-3 left p_small_black_caption">
                              Interested Locations
                            </td>
                            <td className="right_td p_small_grey_bold">
                              {funds.countries.map((ind, i) => (
                                <Chip key={i} label={ind} />
                              ))}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const RenderIncubator = (details) => {
    const profile = details;
    return (
      <>
        <div>
          <div
            className="back_CTA pointer"
            onClick={() => {
              setconnectionState(1);
              history(`/dashboard/${user.type}?tab=2`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.logo}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.name}</p>
                    <p className="bold">
                      Incubator | {profile.location.country}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.incDescription}</p>
              </div>

              <div className="row mx-2 my-3">
                {profile.interestedIndustries ? (
                  <>
                    {profile.interestedIndustries.map((industry, i) => (
                      <p className="p_grey_chip" key={i}>
                        {industry}{" "}
                      </p>
                    ))}
                  </>
                ) : null}
              </div>
              <hr />
              <div className="my-4">
                {user && user.id === profile.id ? null : (
                  <>
                  {!connection ? (
                    <button
                      className="gradient_CTA width_fit"
                      onClick={() => handleConnect(profile.id)}
                    >
                      {sentRequest ? "REQUESTED" : "CONNECT"}
                    </button>
                  ) : (
                    <>
                      {isAccepted ? (
                        <button
                          className="gradient_CTA width_fit"
                          onClick={() => goToChats(profile.id)}
                        >
                          CHAT NOW
                        </button>
                      ) : (
                        <>
                          {connection.sender === user.id ? (
                            <button
                              className="gradient_CTA width_fit"
                              disabled
                            >
                              REQUESTED
                            </button>
                          ) : (
                            <button
                              className="gradient_CTA width_fit"
                              onClick={() => {handleAccept(profile.id)}}
                            >
                              ACCEPT REQUEST
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
                )}
              </div>
              <div>
                <p className="p_spartan_20 mx-2">Current Programs</p>
                <IncubatorPrograms id={profile.id} />
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  <tr>
                    <td className="td_left">Website</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`${profile.companyWebsite}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile.companyWebsite}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="td_left">LinkedIn </td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`${profile.linkedProfile}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderMentor = (details) => {
    const profile = details;
    return (
      <>
        <div>
          <div
            className="back_CTA pointer"
            onClick={() => {
              setconnectionState(1);
              history(`/dashboard/${user.type}?tab=2`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.profilePic}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.name}</p>
                    <p className="bold">Mentor | {profile.location.country}</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.description}</p>
              </div>

              <div className="row mx-2 my-3">
                {profile.interestedDomains ? (
                  <>
                    {profile.interestedDomains.map((industry, i) => (
                      <p className="p_grey_chip" key={i}>
                        {industry}{" "}
                      </p>
                    ))}
                  </>
                ) : null}
              </div>
              <hr />

              <div className="my-4">
                {user && user.id === profile.id ? null : (
                  <>
                  {!connection ? (
                    <button
                      className="gradient_CTA width_fit"
                      onClick={() => handleConnect(profile.id)}
                    >
                      {sentRequest ? "REQUESTED" : "CONNECT"}
                    </button>
                  ) : (
                    <>
                      {isAccepted ? (
                        <button
                          className="gradient_CTA width_fit"
                          onClick={() => goToChats(profile.id)}
                        >
                          CHAT NOW
                        </button>
                      ) : (
                        <>
                          {connection.sender === user.id ? (
                            <button
                              className="gradient_CTA width_fit"
                              disabled
                            >
                              REQUESTED
                            </button>
                          ) : (
                            <button
                              className="gradient_CTA width_fit"
                              onClick={() => {handleAccept(profile.id)}}
                            >
                              ACCEPT REQUEST
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
                )}
              </div>

              <div className="row no-margin p-2 white_bg">
                <div className=" no-padding">
                  <div className="connections_highlights_investor">
                    {profile.involvement ? (
                      <>
                        <div>
                          <p className="p_spartan_20"> Prefered Involvement </p>
                          {profile.involvement.length > 0 ? (
                            <div className="mt-4">
                              {profile.involvement.map((inv, i) => (
                                <p className="" key={i}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-dot"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                  </svg>
                                  {inv.name}
                                </p>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  <tr>
                    <td className="td_left">Current Company</td>
                    <td className="td_right">{profile.currentCompany}</td>
                  </tr>

                  <tr>
                    <td className="td_left">Current Job Role</td>
                    <td className="td_right">{profile.currentJobRole}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Past Experience</td>
                    <td className="td_right">{profile.pastExperience}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Industry</td>
                    <td className="td_right">{profile.industry}</td>
                  </tr>

                  <tr>
                    <td className="td_left">Linkedin</td>
                    <td className="td_right"><a
                        href={`${profile.linkedProfile}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                          />
                          <path
                            fillRule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                          />
                        </svg>
                      </a></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const RenderSp = (details) => {
    const profile = details;
    return (
      <>
        <div>
          <div
            className="back_CTA pointer"
            onClick={() => {
              setconnectionState(1);
              history(`/dashboard/${user.type}?tab=2`);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.logo}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.companyName}</p>
                    <p className="bold">
                      Service Provider | {profile.location.country}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.description}</p>
              </div>

              <div className="row mx-2 my-3">
                <p className="p_grey_chip">{profile.industry} </p>
              </div>
              <hr />

              <div className="">
                {profile.founder ? (
                  <>
                    <p className="p_spartan_20 mx-2 my-4">Founder Details</p>
                    <div className="row mx-0 my-4 align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={profile.profilePic}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                        <p className="">
                          {profile.founder.firstname} {profile.founder.lastname}
                        </p>
                        <p className="">{profile.contact.jobTitle} </p>
                        <p className="bold">
                          <a href={`${profile.socialConnects.linkedin}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}

                <div>
                  {user && user.id === profile.id._id ? null : (
                    <>
                    {!connection ? (
                      <button
                        className="gradient_CTA width_fit"
                        onClick={() => handleConnect(profile.id)}
                      >
                        {sentRequest ? "REQUESTED" : "CONNECT"}
                      </button>
                    ) : (
                      <>
                        {isAccepted ? (
                          <button
                            className="gradient_CTA width_fit"
                            onClick={() => goToChats(profile.id)}
                          >
                            CHAT NOW
                          </button>
                        ) : (
                          <>
                            {connection.sender === user.id ? (
                              <button
                                className="gradient_CTA width_fit"
                                disabled
                              >
                                REQUESTED
                              </button>
                            ) : (
                              <button
                                className="gradient_CTA width_fit"
                                onClick={() => {handleAccept(profile.id._id)}}
                              >
                                ACCEPT REQUEST
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                  )}
                </div>
                <div>
                  <p className="p_spartan_20 mx-2">Current Services</p>
                  <SPServices id={profile.id} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  {profile.valuation && (
                    <tr>
                      <td className="td_left">Valuation</td>
                      <td className="td_right">$ {profile.valuation}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="td_left">Audience</td>
                    <td className="td_right">{profile.audience}</td>
                  </tr>

                  <tr>
                    <td className="td_left">Employees</td>
                    <td className="td_right">{profile.employees}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Funding Series</td>
                    <td className="td_right">{profile.fundingSeries}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Incorporation</td>
                    <td className="td_right">
                      {new Date(profile.incorporationDate).getFullYear()}
                    </td>
                  </tr>

                  <tr>
                    <td className="td_left">Website</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`${profile.website}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile.website}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  //   const [userId, setuserId] = useState(null);

  //   useEffect(() => {
  //     if (startup) setuserId(startup);
  //     else if (investor) setuserId(investor);
  //     else if (corporate) setuserId(corporate);
  //     else if (fundcompany) setuserId(fundcompany);

  //     if (userId) {
  //       getPosts(userId);
  //     }
  //   }, [userId]);

  //   const getDiff = (date) => {
  //     // var dateTime = date + "T" + time;
  //     var scheduleTime = moment(date);
  //     var current = moment();
  //     // var ofset = moment().utcOffset() / 60;

  //     var dayDiff = scheduleTime.diff(current, "days");
  //     if (dayDiff < -1) {
  //       return Math.abs(dayDiff) + " days ago";
  //     } else if (dayDiff === -1) return Math.abs(dayDiff) + " day ago";
  //     else {
  //       var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
  //       //- parseFloat(timezone.split(" ", 3)[2]) +
  //       // ofset;
  //       if (hoursDiff <= -1) {
  //         let hoursDiffRound = Math.round(hoursDiff);
  //         return Math.abs(hoursDiffRound) == 1
  //           ? Math.abs(hoursDiffRound) + " hour ago"
  //           : Math.abs(hoursDiffRound) + " hours ago";
  //       } else {
  //         return Math.abs(Math.round(hoursDiff * 60)) + " mins ago";
  //       }
  //     }
  //   };

  return (
    <>
      <div className="row no-margin">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="dashbaord_services dashbaord_connections">
            {userDetails ? RenderOneUser(userDetails) : null}
            {investorDetails ? RenderInvestor(investorDetails) : null}
            {corporateDetails ? RenderCorporate(corporateDetails) : null}
            {fundDetail ? RenderFundCompany(fundDetail) : null}
            {incubatorDetails ? RenderIncubator(incubatorDetails) : null}
            {mentorDetails ? RenderMentor(mentorDetails) : null}
            {spDetails ? RenderSp(spDetails) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default ConnectionComponent;
