function dateDiff(endingDate) {
    var startDate = new Date();
    if (!endingDate) {
        endingDate = new Date().toISOString().substring(0, 10); 
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
        var swap = startDate;
        startDate = endDate;
        endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
        if (monthDiff > 0) {
            monthDiff--;
        } else {
            yearDiff--;
            monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
    }
    
    return ((yearDiff > 0 ? (yearDiff + (yearDiff > 1 ? ' years ' : ' year ')) : '') + (monthDiff > 0 ? (monthDiff + (monthDiff > 1 ? ' months ': ' month ')) : '') + (dayDiff > 0 ? (dayDiff + (dayDiff > 1 ? ' days ': ' day')) : '')) + 'ago';

}

export default dateDiff;
