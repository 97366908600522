import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../Utils/Auth';
const Card = ({data,setTransactionStep}) => {
    const {user} = useAuth();
    const navigate = useNavigate();
    return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black px-2">{new Date(data.createdAt).toLocaleDateString('en-GB')}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black text-capitalize">{data.paid_to.name}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">€ {data.program_id?.participationAmmount}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black text-capitalize">{data.program_id?.title}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">Success</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on"  style={{cursor:'pointer'}}>
            <p className="caption color_dark_black text-capitalize" style={{cursor:'pointer'}}
            onClick={()=>{
                setTransactionStep(1)
                navigate(`/dashboard/${user.type}?tab=10&ft=4&transaction=${data._id}&inc=${data.paid_to._id}`)
            }}
            >Click here</p>
        </td>
    </tr>
}

export default Card