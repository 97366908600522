import React, { useEffect, useState } from 'react'
import { Add } from '@mui/icons-material';
// import profile_short from "../../../../Assets/images/incubatorDash/profile_short.png"
import RightScreen from './RightScreen';
import AddAssetsForm from './AddAssetsForm';
import Axios from 'axios';
import { useAuth } from "../../../../Utils/Auth";
import { useNavigate } from "react-router-dom"

const MyRealEstate = () => {
  const [myAssetState, setMyAssetState] = useState(0);
  const [realEstates, setRealEstates] = useState([])
  const navigate = useNavigate();

  const { user } = useAuth()
  const getRealEstate = async () => {
    await Axios({
      url: `/api/realEstate/incubator/${user.id}`,
      method: 'GET',
    }).then((res) => {
      setRealEstates(res.data.data)
      for (let i = 0; i < res.data.data.length; i++) {
        const e = res.data.data[i];
        if (!e.is_deleted) {
          navigate(`/dashboard/${user.type}?tab=5&cs=3&estate=${res.data.data[i]._id}`)
          break;
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    getRealEstate()
  }, [])


  return (
    <>
      {
        myAssetState === 0 ?
          <div className="incubator_coworking_myAssets_root">
            <div className="row">
              <div className="col-lg-3">
                <div className="incubator_coworking_myAssets_left_root">
                  <div className="coworking_myAssets_left_head">
                    <p className="caption">Real Estate</p>
                    <button className='addAssetFormbtn' onClick={() => {
                      navigate(`/dashboard/${user.type}?tab=5&cs=3`)
                      setMyAssetState(1)
                    }}><Add /></button>
                  </div>
                  <div className="coworking_myAssets_left_body">
                    {
                      realEstates.length > 0 ?
                        realEstates.map((estate, i) => <>
                          {
                            !estate.is_deleted ? <div className="coworking_myAssets_left_profile" key={i} onClick={() => {
                              navigate(`/dashboard/${user.type}?tab=5&cs=3&estate=${estate._id}`)
                            }} >
                              <div className="coworking_myAssets_left_profile_img">
                                <img src={estate.created_by.avatar} alt="profile" />
                              </div>
                              <div className="coworking_myAssets_left_profile_name ml-8">
                                <p className="body1">{estate.real_estate_name}</p>
                                <p className="overline">
                                  <span>ID : </span>
                                  <span>{(estate.real_estate_id).slice(-5)}</span>
                                </p>
                              </div>
                              {/* <div className="coworking_myAssets_left_active">
                                <p className="overline">Active</p>
                              </div> */}
                            </div> : null
                          }
                        </>) : null
                    }

                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <RightScreen
                  setMyAssetState={setMyAssetState}
                  getRealEstate={getRealEstate}
                />
              </div>
            </div>


          </div> :
          <AddAssetsForm
            setMyAssetState={setMyAssetState}
            getRealEstate={getRealEstate}
          />
      }
    </>

  )
}

export default MyRealEstate