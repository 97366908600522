import React, { useState, useEffect } from 'react';
import Query from '../../../Utils/Query/Query'
import { toast } from 'react-toastify';
import Axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import ClassIcon from '@mui/icons-material/Class';
import { MultilineEdit, InlineEditHead, InlineEdit } from './InlineEdit';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import NumberFormat from "react-number-format";
import ListHoverElements from './listHoverElements';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from "@material-ui/core";
import { format } from 'date-fns'
import CircularProgress from "@mui/material/CircularProgress";
const LeadDetails = ({ handleDetailModalClose, category, target }) => {
     const [lead, setLead] = useState(null)
     const query = Query();
     const leadId = query.get('lead');
     const [leadTitle, setLeadTitle] = useState('')
     const [leadDescription, setLeadDescription] = useState('')
     const [leadStatus, setLeadStatus] = useState('')
     const [amountRaised, setAmountRaised] = useState(null)
     const [formatedValue, setFormatedValue] = useState(amountRaised);
     const [leadEmail, setLeadEmail] = useState('')
     const [isNotify, setIsNotify] = useState(null)
     const [leadIssues, setLeadIssuses] = useState([]);
     const [issues, setIssuses] = useState("");
     const [leadCreated, setLeadCreated] = useState(null)
     const [leadComplete, setLeadComplete] = useState(null) //leadComplete
     const [isdocloading, setIsDocLoading] = useState(false)
     const [leadAttachments, setLeadAttachments] = useState([]);
     const handleRuppesConvert = (e) => {
          // let pastValue = amountRaised
          setFormatedValue(e.target.value);
          // if (e.target.value.trim() === "") {
          //      setFormatedValue(pastValue);
          // }
     }
     const handleOnBlur = () => {
          let s = formatedValue;
          if (s === amountRaised) {
               return;
          } else {
               if (s) {
                    var n = s.split("€", 2)[1];
                    setAmountRaised(Number(n.split(",").join("")));
                    updateLead('amount_raised', Number(n.split(",").join("")))
               }
          }

          // console.log(e.target.value)
     }
     const handleEmailNotification = (event) => {
          setIsNotify(current => !current)
          updateLead('email_notification', event.target.checked)
     }
     const handleStatusChange = (event) => {
          setLeadStatus(event.target.value)
          updateLead('lead_status', event.target.value)
     }

     const handleCreationDateChange = (e) => {
          setLeadCreated(e.target.value)
          updateLead('created_on', e.target.value)
     }
     const handleCompleteDateChange = (e) => {
          setLeadComplete(e.target.value)
          updateLead('completion_date', e.target.value)
     }
     const fetchLeadbyId = () => {

          Axios({
               method: "get",
               url: `/api/leadGeneration/lead/${leadId}`,
          }).then((res) => {
               console.log(res.data.data)
               setLead(res.data.data)
               const { title, description, lead_status, amount_raised, lead_email, email_notification, created_on, completion_date, attachments, todos } = res.data.data
               setLeadTitle(title)
               setLeadDescription(description)
               setLeadStatus(lead_status)
               setAmountRaised(amount_raised)
               setFormatedValue(amount_raised)
               setLeadEmail(lead_email)
               setIsNotify(email_notification)
               setLeadAttachments(attachments)
               setLeadIssuses(todos)
               setLeadCreated(() => format(new Date(created_on), 'yyyy-MM-dd'))
               if (completion_date === null) {
                    setLeadComplete(completion_date)
               } else {
                    setLeadComplete(() => format(new Date(completion_date), 'yyyy-MM-dd'))
               }

          }).catch(() => {
               toast.error("Error while fetching lead", { position: "top-right" });
          });
     }

     const addObjective = (arr, seto, v, setv) => {
          let data = {
               name: v
          }
          if (arr && v.length > 0) {
               seto((prev) => [...prev, data]);
               setv("");
               Axios({
                    method: "put",
                    url: `/api/leadGeneration/lead/update-todos/${leadId}`,
                    data: data,
               }).then(res => {
                    if (res.data.success === true) {
                         setIsDocLoading(false);
                         toast.success("Todo uploaded", { position: "top-right" });
                    } else {
                         toast.error("Error in upload", { position: "top-right" });
                    }
               })
          }
     };

     const handleUploadAttachment = (e) => {
          e.preventDefault();

          const file = e.target.files[0];
          const fileSize = file.size / 1024 / 1024; // in MiB
          if (fileSize > 25) {
               toast.error("File size exceeded 25MB!", { position: "bottom-right" });
               return;
          }
          const formdata = new FormData();
          formdata.append("leadAttachments", file);
          setIsDocLoading(true);
          Axios({
               method: "post",
               url: "/api/upload/lead-attachments",
               data: formdata,
          }).then((res) => {
               const { data } = res;
               if (data) {
                    const attchData = {
                         name: file.name,
                         link: data.location
                    }

                    setLeadAttachments((prevAttchments) => [...prevAttchments, attchData])
                    Axios({
                         method: "put",
                         url: `/api/leadGeneration/lead/update-attachment/${leadId}`,
                         data: attchData,
                    }).then(res => {
                         if (res.data.success === true) {
                              setIsDocLoading(false);
                              toast.success("Attchment uploaded", { position: "bottom-right" });
                         } else {
                              toast.error("Error in upload", { position: "top-right" });
                         }
                    })
               } else {
                    toast.error("Error in upload", { position: "top-right" });
               }

          })
               .catch(() => {
                    setIsDocLoading(false);
                    toast.error("Error in upload", { position: "bottom-right" });
               });
     }

     useEffect(() => {
          fetchLeadbyId()
     }, [])

     const updateLead = (query, value) => {
          let data;
          if (query === "email_notification") {
               data = { email_notification: value }
          } else if (query === "lead_status") {
               data = { lead_status: value }
          } else if (query === 'amount_raised') {
               data = { amount_raised: value }
          } else if (query === 'created_on') {
               data = { created_on: value }
          } else if (query === 'completion_date') {
               data = { completion_date: value }
          }
          Axios({
               method: "put",
               url: `/api/leadGeneration/lead/${leadId}`,
               data: data,
          })
               .then(() => {
                    toast.success(`Lead ${query} updated`, { position: "top-right" });
               })
               .catch(() => {
                    toast.error("Error while updating lead", { position: "top-right" });
               });
     }




     return (
          <>
               {
                    lead && <div>
                         <div className='d-flex align-items-center justify-content-between'>
                              <div className='d-flex align-items-center justify-content-start w-100'>
                                   <ClassIcon /> <p className="auth_label title text-capitalize">{target.name} / {category.name}</p>
                              </div>
                              <div>
                                   <button onClick={handleDetailModalClose} style={{ background: "transparent", border: "none", outline: "none" }}>
                                        <CancelIcon />
                                   </button>
                              </div>
                         </div>
                         <br />
                         <div className='row'>
                              <div className='col-md-6 col-lg-6 col-sm-12'>
                                   <div className='d-flex flex-column'>
                                        {
                                             leadTitle.length > 0 && leadDescription.length > 0 && <>
                                                  <InlineEditHead value={leadTitle} setValue={setLeadTitle} query="title" />
                                                  <MultilineEdit value={leadDescription} setValue={setLeadDescription} query="description" />
                                             </>
                                        }
                                   </div>
                                   <div className='my-3 d-flex flex-column'>
                                        <label className="auth_label p_secNaviBlue12 m-0 px-1">
                                             Todos
                                        </label>
                                        <div style={{ maxHeight: "8rem", overflowY: "scroll" }}>
                                        {leadIssues && leadIssues?.map((todo, i) => <div key={i} className="mx-3 p-1">
                                             <div className='d-flex align-items-center'>
                                                  <PlaylistAddCheckIcon /> <p className='mx-2'>{todo.name}</p>
                                             </div>
                                        </div>)}
                                        </div>
                                        <div className="goalsdiv mt-2">
                                             <input
                                                  type="text"
                                                  name="todos"
                                                  className="auth_input todo-input"
                                                  placeholder="Add todos"
                                                  id="todos"
                                                  value={issues}
                                                  onChange={(e) => setIssuses(e.target.value)}
                                             />
                                             <Tooltip title="Add Todos">
                                                  <button
                                                       type="button"
                                                       className='p-3'
                                                       onClick={(e) => {
                                                            e.preventDefault()
                                                            addObjective(
                                                                 leadIssues,
                                                                 setLeadIssuses,
                                                                 issues,
                                                                 setIssuses)
                                                       }
                                                       }
                                                  >
                                                       <AddIcon />
                                                  </button>
                                             </Tooltip>
                                        </div>
                                   </div>
                                   <div className='my-3 d-flex flex-column'>
                                        <label className="auth_label p_secNaviBlue12 m-0 px-1">
                                             Lead Email
                                        </label>
                                        {
                                             leadEmail && <InlineEdit value={leadEmail} setValue={setLeadEmail} query="lead_email" />
                                        }


                                        {
                                             isNotify !== null && <div className="col-12 m-0 p-0 mt-1 align-items-start">
                                                  <div className='d-flex '>
                                                       <input
                                                            placeholder="notification"
                                                            type="checkbox"
                                                            className="m-0 p-0"
                                                            value={isNotify}
                                                            name="notify"
                                                            id="notify"
                                                            onChange={handleEmailNotification}

                                                            defaultChecked={isNotify}
                                                       />
                                                       <label className="auth_label m-0 px-1" htmlFor='notify'>
                                                            Send Notifications
                                                       </label>

                                                  </div>
                                             </div>
                                        }


                                   </div>
                              </div>
                              <div className='col-md-6 col-lg-6 col-sm-12'>
                                   <div className='row'>
                                        <div className="col-12 col-md-6 col-sm-12">
                                             <label className="auth_label p_secNaviBlue12 mt-0">Lead status</label>
                                             <select
                                                  className="auth_input auth_select"
                                                  name="program_type"
                                                  value={leadStatus}
                                                  onChange={handleStatusChange}
                                             >
                                                  <option value="Introductin">
                                                       Introduction
                                                  </option>
                                                  <option value="Proposal sent">Proposal sent</option>
                                                  <option value="In progress">In progress</option>
                                                  <option value="Approved">Approved</option>
                                                  <option value="Rejected">
                                                       Rejected
                                                  </option>
                                             </select>
                                        </div>
                                        <div className='col-12 col-md-6 col-sm-12'>
                                             <label className="auth_label">
                                                  Amount Raised
                                             </label>
                                             <NumberFormat
                                                  placeholder="€ 100,000"
                                                  displayType={"number"}
                                                  thousandSeparator={true}
                                                  prefix={"€ "}
                                                  className="some auth_input"
                                                  inputMode="numeric"
                                                  value={formatedValue}
                                                  onChange={(e) => handleRuppesConvert(e)}
                                                  onBlur={handleOnBlur}
                                             />

                                        </div>
                                   </div>
                                   <div className='col-6 mx-0 px-0 my-2'>
                                        <label className="auth_label p_secNaviBlue12">
                                             Attachments
                                        </label>
                                        <div  style={{ maxHeight: "8rem", overflowY: "scroll" }}>
                                             {leadAttachments?.map((attach, i) => {

                                                  return (<div key={i} className="mx-3 d-flex align-items-center">
                                                       <ListHoverElements attach={attach} />
                                                  </div>)
                                             }
                                             )}
                                        </div>

                                        <div className="authIdCard d-flex align-items-center">
                                             <div>
                                                  <label
                                                       title="Attchments"
                                                       htmlFor='investor_id'
                                                       style={{ cursor: "pointer" }}
                                                  >
                                                       Add Attachments
                                                  </label>
                                             </div>
                                             {isdocloading ? (
                                                  <CircularProgress style={{ color: "black", margin: '0 5px' }} />
                                             ) : (
                                                  <div className="authIdCardSecond d-flex align-items-center" style={{ cursor: "pointer" }}>
                                                       <label htmlFor="investor_id">
                                                            <div
                                                                 className="p-2 mt-1"
                                                                 title="Attchments"
                                                            >
                                                                 <i
                                                                      style={{ color: "black" }}
                                                                      className="fas fa-paperclip"

                                                                 ></i>
                                                            </div>
                                                            <input
                                                                 type="file"
                                                                 accept="application/pdf,image/*,.doc,.docx,.ppt"
                                                                 name="investor_id"
                                                                 id="investor_id"
                                                                 onChange={handleUploadAttachment}
                                                                 title="Click here to add attchments"
                                                            />
                                                       </label>
                                                  </div>
                                             )}
                                        </div>

                                   </div>
                                   <div className='mt-3 mb-3'>
                                        {/* <button className="primaryOutlined_activeCta my-2">Create MOU</button>
                                        <button className="primaryFilled_activeCta my-2 ml-4">Create Invoice</button> */}

                                   </div>
                                   <div className='row'>
                                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                             <label className="auth_label p_secNaviBlue12">
                                                  Created on
                                             </label>

                                             <input
                                                  type="date"
                                                  onFocus={(e) => {
                                                       e.target.type = "date";
                                                  }}
                                                  className="inlineEditInput"
                                                  placeholder="Start Date"
                                                  // value={programStartDate}
                                                  defaultValue={leadCreated}
                                                  onChange={handleCreationDateChange}
                                             />

                                        </div>
                                        <div className='col-md-6 col-lg-6 col-sm-12'>
                                             <label className="auth_label p_secNaviBlue12">
                                                  Completion Date
                                             </label>
                                             <input
                                                  type="date"
                                                  onFocus={(e) => {
                                                       e.target.type = "date";
                                                  }}
                                                  className="inlineEditInput"
                                                  placeholder="Start Date"
                                                  // value={programStartDate}
                                                  defaultValue={leadComplete}
                                                  onChange={handleCompleteDateChange}
                                             />

                                        </div>
                                   </div>
                              </div>

                         </div>

                    </div>
               }
          </>
     )
}

export default LeadDetails