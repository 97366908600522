import React, {useEffect} from "react";
import MyServices from "./MyServices";
import Explore from "./Explore";
import ActiveServices from "./ActiveServices";

const LandingPage = (props) => {
  const { eventActiveStep, setEventActiveStep } = props;
  useEffect(()=>{
    var flag=false
    if(flag)
      console.log(setEventActiveStep);
  },[]);
  // console.log(setEventActiveStep);
  return (
    <>
      {eventActiveStep === 1 ? (
        <ActiveServices />
      ) : eventActiveStep === 2 ? (
        <MyServices />
      ) : eventActiveStep === 3 ? (
        <Explore />
      ) : (
        <Explore />
      )}
    </>
  );
};

export default LandingPage;
