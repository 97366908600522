import React from 'react'

const Card = () => {
   return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td finance_received_from_td">
            <img src={"https://picsum.photos/40"} alt="zefyron finance" />
            <p className="subtitle color_dark_black">Aman Gupta</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_courseName">
            <p className="caption color_dark_black">Events</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">08/07/2022</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
            <p className="caption color_dark_black">Euro 40000</p>
        </td>
    </tr>
}

export default Card