import React from 'react'
import card from "./../../../../../Assets/images/icons/card.png";
import { ShoppingCart, ArrowForward } from '@mui/icons-material';

const Card = (props) => {
    const {setAssetView} = props;
    return (
        <div className="col-lg-4 col-md-6 col-12 mb-24">
            <div className="startup_assets_card_root point" onClick={()=>setAssetView(1)}>
                <div className="startup_assets_card_main">
                    <div className="startup_assets_card_tag">
                        <p className='incubation_chips'>16svc</p>
                    </div>
                    <div className="startup_assets_card_profile mt-16">
                        <img src="https://picsum.photos/40/40" alt="card-profile" />
                        <div className="s_asset_card_profile_name ml-12">
                            <p className="body1">Vernier calliper</p>
                            <p className="overline color_dark_grey2 d-flex align-items-center">
                                <span>Building</span>
                                <span className='startup_assets_ellipse'></span>
                                <span>Cabin</span>
                            </p>
                        </div>
                    </div>
                    <div className="startup_assets_card_rs_info mt-16">
                        <div className="startup_assets_card_rs">
                            <img src={card} alt="card" style={{marginRight:4}} />
                            <p className="overline color_dark_blue">€ 5000</p>
                        </div>
                        <div className="startup_assets_card_rs">
                            <ShoppingCart />
                            <p className="overline color_dark_blue">50</p>
                        </div>
                    </div>
                    <div className="startup_assets_card_incubator_info mt-24">
                        <div className="startup_assets_card_incubator_profile">
                            <img src="https://picsum.photos/40/40" alt="profile" />
                            <p className="body2 ml-8">Aman Gupta</p>
                        </div>
                        <div className="startup_assets_card_inc_arrow">
                            <ArrowForward 
                                style={{color:"#6D7D8B"}}
                            />
                        </div>
                    </div>
                </div>
                <div className="startup_assets_card_footer mt-16">
                    <p className="overline" style={{ color: "rgba(0, 0, 0, 0.75)" }}>25 orders placed until now</p>
                </div>
            </div>
        </div>
    )
}

export default Card