import React from 'react'
import Query from '../../Utils/Query/Query';
import CorporateProfile from './CorporateProfile';
import IncubatorProfile from './IncubatorProfile';
import InvestorProfile from './investorProfile';
import SPprofile from './SPprofile';
import StartupProfile from './StartupProfile'

const ShowProfile = ({ setstep, fetchInvites }) => {
    const query = Query()
    const startup = query.get("startup");
    const incubator = query.get("incubator");
    // const mentor = query.get("mentor");
    const investor = query.get("investor");
    const corporate = query.get("corporate");
    const serviceprovider = query.get("serviceprovider");
    // const student = query.get("student");
    // const individual = query.get("individual");
    // const prestartup = query.get("prestartup");
    // const employee = query.get("employee");
    return (
        <div className='mentor_showProfile_root'>
            {
                startup ? <StartupProfile
                    setstep={setstep}
                    fetchInvites={fetchInvites}
                /> : incubator ? <IncubatorProfile
                    setstep={setstep}
                    fetchInvites={fetchInvites}
                /> : corporate ? <CorporateProfile
                    setstep={setstep}
                    fetchInvites={fetchInvites}
                /> : investor ? <InvestorProfile
                    setstep={setstep}
                    fetchInvites={fetchInvites}
                /> : serviceprovider ? <SPprofile
                setstep={setstep}
                    fetchInvites={fetchInvites}
                /> : null
            }

        </div>
    )
}

export default ShowProfile