import React, { useState } from "react";
import "./MyAssets.css";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useAuth } from "../../../../Utils/Auth";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const AssetsCard = ({ setMyAssetState, assets }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleAddAsset = (e) => {
    e.preventDefault();
    if (user.is_account === true) {
      setMyAssetState(1);
      navigate(`/dashboard/${user.type}?tab=5&cs=5`);
    } else {
      handleModalOpen();
    }
  };
  return (
    <div className="incubator_coworking_myAssets_left_root">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={{ ...style, width: 500 }}>
          <p className="mb-3">
            Please add account details first for accepting payments.
          </p>
          <div className="float-right">
            <button
              type="button"
              className="gradient_CTA"
              onClick={() => navigate(`/dashboard/${user.type}?tab=8&ft=1`)}
            >
              Add Account
            </button>
          </div>
        </Box>
      </Modal>
      <div className="coworking_myAssets_left_head">
        <p className="caption">Assets</p>
        <button className="addAssetFormbtn" onClick={handleAddAsset}>
          <Add />
        </button>
      </div>
      <div className="coworking_myAssets_left_body">
        {assets.length > 0
          ? assets.map((asset, i) => (
              <>
                {!asset.is_deleted ? (
                  <div
                    className="coworking_myAssets_left_profile"
                    key={i}
                    onClick={() => {
                      navigate(
                        `/dashboard/${user.type}?tab=5&cs=5&asset=${asset._id}`
                      );
                    }}
                  >
                    <div className="coworking_myAssets_left_profile_img">
                      <img src={asset.created_by.avatar} alt="profile" />
                    </div>
                    <div className="coworking_myAssets_left_profile_name ml-8">
                      <p className="body1">{asset.asset_name}</p>
                      <p className="overline">
                        <span>Asset ID : </span>
                        <span>{asset.asset_id.slice(-5)}</span>
                      </p>
                    </div>
                    {asset.status === "active" ? (
                      <div className="coworking_myAssets_left_active">
                        <p className="overline">Active</p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            ))
          : null}
      </div>
    </div>
  );
};

export default AssetsCard;
