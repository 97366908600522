import React, { useState, useEffect } from "react";
import "./index.css";
import { Link } from "react-router-dom";
// import { Avatar } from "@mui/material";
import { useAuth } from "../../../Utils/Auth";
import axios from "axios";
import { KeyboardArrowDown } from "@mui/icons-material"

export default function Requests() {
  const { user, setSessionExpire } = useAuth();
  const [programs, setprograms] = useState([]);
  const [selected, setselected] = useState("Select a program");
  // const [program, setprogram] = useState([]);
  // const [specificprogram, setspecificprogram] = useState([]);
  // const [program_id, setprogram_id] = useState(null);
  const [avatar_id, setavatar_id] = useState(null);
  const [applied, setapplied] = useState(0);
  const [shortlisted, setshortlisted] = useState(0);
  const [drop,setDrop]=useState(false);

  const getPrograms = async () => {
    await axios({
      url: "/api/dashboard/applications/programs",
      method: "get",
    })
      .then((res) => {
        setprograms(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      });  
  };
  const getdetails = async (id) => {
    await axios({
      url: `/api/dashboard/applications/program/${id}`,
      method: "get",
    })
      .then((res) => {
        // setprogram(res.data.data);
        setapplied(res.data.data.applied);
        setshortlisted(res.data.data.shortlisted);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      });  
  };
  

  useEffect(() => {
    getPrograms();
  },[]);
  
  useEffect(() => {
    if(programs && programs.length>0)
    {
      setselected(programs[0].title);
      getdetails(programs[0]._id);
      setavatar_id(programs[0].coverImage);
    }
  },[programs]);
  

  return (
    <div className="dashboard01_block mt-0">
    <div className="row justify-content-between mx-1 my-1">
      <h5 className="subtitle w_fit">Applications</h5>
      <Link to={`/dashboard/${user.type}?tab=2`}>
        <p className="w_fit bold" style={{marginTop: "5px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
        <div className="row mt-3 mb-2 mx-1">
          {/* <div className="col-12 application-box-one d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Avatar sx={{ width: 24, height: 24 }} src={avatar_id} className="mr-2" />
              <span className="caption" style={{color:"#121843"}}>{selected}</span>
            </div>
            <svg onClick={()=>{
                setDrop(!drop);
            }} style={{cursor: "pointer"}} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L5 5L1 1" stroke="#262C5B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div> */}


          <div className="mentor_tasks_select_root w-100">
                            <div className="mentor_tasks_bodyCmpName point" onClick={() => setDrop(!drop)}>
                              <div className="mentor_tasks_bodyCmpprf">
                                  {avatar_id ? <img src={avatar_id} alt="profile" /> : <span className="mr-8"></span> }
                                  <p className="caption color_dark_black2">{selected}</p>
                              </div>
                              <span><KeyboardArrowDown
                                  className={`mentorDownbtn ${drop ? 'rotateDown' : 'antirotateDown'}`}
                              /></span>
                            </div>
                            {
                                drop ?
                                    <div className={`mentor_tasks_options_root ${drop ? 'show' : 'hide'}`}>
                                        {
                                            programs && programs.length>0 ? 
                                            programs.map((data, i) => <div className="mentor_tasks_options point" key={i}
                                            onClick={()=>{
                                              setselected(data.title);
                                              getdetails(data._id);
                                              setavatar_id(data.coverImage);
                                              setDrop(!drop);
                                          }}
                                                >
                                                    <img src={data.coverImage} alt="profile" />
                                                    <p className="caption color_dark_black2">{data.title}</p>
                                                </div>) : null

                                        }
                                    </div> : null
                            }

                        </div>
          {/* {drop ? (
            <div className="col-12" style={{background:"#fff"}}>
            <div className="drop-design row">
            {programs && programs.length>0 ? 
            programs.map((program,i)=>
            <>
            <div key={i} onClick={()=>{
                setselected(program.title);
                getdetails(program._id);
                setavatar_id(program.coverImage);
                setDrop(!drop);
            }} className="col-12 application-box-one d-flex justify-content-between align-items-center" style={{height:"fit-content",borderRadius: "4px"}}>
            <div className="d-flex align-items-center">
              <span className="caption" style={{color:"#121843"}}>{program.title}</span>
            </div>
          </div>
          </>) 
          : 
          <div onClick={()=>{
            setDrop(!drop);
          }} className="col-12 application-box-one d-flex justify-content-between align-items-center" style={{height:"fit-content",borderRadius: "4px"}}>
            <div className="d-flex align-items-center">
              <span className="caption" style={{color:"#121843"}}>No Programs</span>
            </div>
          </div>
          }
          </div>
          </div>
          ) : null} */}
          
          <div className="col-12 mt-3 application-box-two d-flex justify-content-center align-items-center px-3 py-4">
            <div className="row w-100">
              <h5 className="col-2 text-right" style={{color: "#FF6F1E"}}>{applied}</h5>
              <span className="subtitle col-10 text-left" style={{color:"#121843"}}>Applied</span>
              </div>
          </div>
          <div className="col-12 mt-3 application-box-three d-flex justify-content-center align-items-center px-3 py-4">
            <div className="row w-100">
              <h5 className="col-2 text-right" style={{color: "#121843"}}>{shortlisted}</h5>
              <span className="subtitle col-10 text-left" style={{color:"#121843"}}>Shortlisted</span>
          </div>
          </div>
      </div>
    </div>
  );
}
