import React, { useState } from 'react'
import { CheckCircleOutline, RateReviewOutlined, ArrowForwardOutlined, Cancel, CheckCircle,RateReviewRounded } from '@mui/icons-material'
import Axios from 'axios';
import { useAuth } from "../../../../Utils/Auth";
import { Modal, Box } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: '#fff',
    // border: '2px solid #000',
    boxShadow: "0px 4px 20px rgba(48, 48, 48, 0.1)",
    // boxShadow: 24,
};

const AssignmentCard = (props) => {
    const { assignment,getAssignments } = props;
    const { setSessionExpire } = useAuth();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // console.log(user)
    const [remark, setRemark] = useState("")

    const markAsEvaluate = async () => {
        await Axios({
            url: `/api/mentorModules/assignment/status/${assignment.course_id._id}/${assignment.user_id._id}/${assignment.assignment_id}/Accepted`,
            method: "put",
        }).then(() => {
            // setAssignments(res.data.data)
            getAssignments()
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    const AddRemark = async () => {
        await Axios({
            url: `/api/mentorModules/assignment/remark/${assignment.course_id._id}/${assignment.user_id._id}/${assignment.assignment_id}`,
            method: "put",
            data: {
                remark: remark
            }
        }).then(() => {
            setRemark("");
            handleClose();
            getAssignments()
            // setAssignments(res.data.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    return <div className="col-lg-6 col-md-6 col-12 incubator_assignment_card_root">
        <div className="incubator_assignment_card_main">
            <div className="incubator_assignment_card">
                <div className="incubator_assignment_card_head">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <div className="incubator_assignment_card_headLeft d-flex">
                                <img src={assignment.user_id.avatar} />
                                <p className="subtitle">{assignment.user_id.firstname} {assignment.user_id.lastname}</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="incubator_assignment_card_headRight">
                                <p className="caption color_dark_grey2"> <span>{new Date(assignment.createdAt).toLocaleDateString()}</span> | <span>{new Date(assignment.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="incubator_assignment_card_mainInfo mt-3">
                    <div className="incubator_assignment_card_assignInfo">
                        <p className="caption">
                            <span className='color_dark_blue'>Course : </span>
                            <span className='color_dark_black'>{assignment.course_id.title}</span>
                        </p>
                        <p className="caption">
                            <span className='color_dark_blue'>Module : </span>
                            <span className='color_dark_black'>{assignment.module_id.title} </span>
                        </p>
                    </div>
                    <div className="incubator_assignment_card_checkInfo">
                        {
                            assignment.evaluated !== "Accepted" ?
                                <p className="caption point color_light_grey" onClick={markAsEvaluate}>
                                    <span className='incubator_assignment_notcheck'>
                                        <CheckCircleOutline />
                                    </span>
                                    <span className=''>Evaluation pending</span>
                                </p> :
                                <p className="caption point">
                                    <span className='incubator_assignment_notcheck'>
                                        <CheckCircle
                                            style={{ fill: "#2EB67D" }}
                                        />
                                    </span>
                                    <span className='color_dark_black'>Marked as evaluated</span>
                                </p>

                        }
                        {
                            assignment.remark ?
                                <p className="caption point color_light_grey">
                                    <span className='incubator_assignment_notcheck'>
                                        <RateReviewRounded 
                                            style={{fill:'#262C5B'}}
                                        />
                                    </span>
                                    <span className='color_dark_black'>Remark added</span>
                                </p> :
                                <p className="caption point color_light_grey" onClick={handleOpen}>
                                    <span className='incubator_assignment_notcheck'>
                                        <RateReviewOutlined />
                                    </span>
                                    <span className=''>To be remarked</span>
                                </p>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="incubator_assignment_card_footer">
            <p className="caption color_dark_grey2 point">
                <a href={assignment.link} target="_blank" rel="noopener noreferrer">
                    <span>View</span> <span><ArrowForwardOutlined 
                        style={{
                            height: 16,
                            width: 16
                        }}
                    /></span>
                </a>
            </p>
        </div>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="incubator_assignment_modal_root">
                <div className="incubator_assignment_modal_main">
                    <div className="incubator_assignment_modal_head">
                        <h4>Add remarks</h4>
                        <button onClick={handleClose}><Cancel /></button>
                    </div>
                    <div className="incubator_assignment_modal_input">
                        <label htmlFor="assignment_modal_input" className="caption color_dark_blue">Remark</label>
                        <input
                            type="text"
                            placeholder='Enter remark'
                            className='body1'
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                        />
                    </div>
                    <div className="incubator_assignment_modal_submit">
                        <button type="submit" className='primaryFilled_activeCta' onClick={AddRemark}>SEND</button>
                    </div>
                </div>
            </Box>
        </Modal>

    </div>
}

export default AssignmentCard