import moment from "moment-timezone";

const getDiff = (date) => {
  // var dateTime = date + "T" + time;
  var scheduleTime = moment(date);
  var current = moment();
  // var ofset = moment().utcOffset() / 60;

  var dayDiff = scheduleTime.diff(current, "days");
  if (dayDiff < -1) {
    return Math.abs(dayDiff) + " days ago";
  } else if (dayDiff === -1) return Math.abs(dayDiff) + " day ago";
  else {
    var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
    //- parseFloat(timezone.split(" ", 3)[2]) +
    // ofset;
    if (hoursDiff <= -1) {
      let hoursDiffRound = Math.round(hoursDiff);
      return Math.abs(hoursDiffRound) == 1
        ? Math.abs(hoursDiffRound) + " hour ago"
        : Math.abs(hoursDiffRound) + " hours ago";
    } else {
      return Math.abs(Math.round(hoursDiff * 60)) + " mins ago";
    }
  }
};

export default getDiff;
