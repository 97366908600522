import React from "react";
import "./index.css";
import impactimg from "./../../../Assets/images/dashboard_impact.png";
// import { Link } from "react-router-dom";

export default function OnBoard() {

  return (
    <div className="Dashboard01_events dashboard01_block mt-0 impact-box justify-content-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <img src={impactimg} />
        </div>
        <div className="body2 mt-3">Make your profile impactful</div>
        <div className="overline mt-2" style={{color: "#989898",textAlign: "center", fontSize: "12px"}}>Seems like you missed some data while onboarding </div>
      </div>
    </div>
  );
}