import React from "react";
import "./index.css";
import { useAuth } from "../../../Utils/Auth";
import coverimg from "./../../../Assets/images/back.png";
import Avatar from "@mui/material/Avatar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const { user, profile } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <div className="container">
        <div className="row">
          <img
            src={coverimg}
            className="profle-coverimg col-12"
            alt="coverimg"
          />
          <div className="col-12">
            <div className="profile-topbar p-3">
              <Avatar
                alt={user.name}
                src={user.avatar}
                className="profile-img"
              />
              <div className="row">
                <div className="col-1"></div>
                <div
                  className="col-9 subtitle pl-5 d-flex align-items-center"
                  style={{ color: "background: #262C5B;" }}
                >
                  {user.name}
                </div>
                <div className="col-2 d-flex justify-content-end align-items-center">
                  <div className="caption mr-4">Edit</div>
                  <div
                    className="profile-editbtn"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/edit/${user.type}`);
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.34369 12.8891L1.28813 17.4447C1.25172 17.6112 1.25297 17.7838 1.29179 17.9497C1.33061 18.1157 1.40601 18.2709 1.5125 18.4041C1.61899 18.5372 1.75387 18.6448 1.90729 18.7191C2.0607 18.7934 2.22878 18.8325 2.39924 18.8336C2.47867 18.8416 2.55871 18.8416 2.63813 18.8336L7.22147 17.778L16.0215 9.01133L11.1104 4.11133L2.34369 12.8891Z"
                        fill="url(#paint0_linear_2492_21155)"
                      />
                      <path
                        d="M18.7886 4.62231L15.5109 1.34454C15.2954 1.13013 15.0037 1.00977 14.6997 1.00977C14.3958 1.00977 14.1041 1.13013 13.8886 1.34454L12.0664 3.16676L16.972 8.07231L18.7942 6.25009C18.9008 6.14291 18.9853 6.01576 19.0427 5.87591C19.1002 5.73605 19.1295 5.58624 19.129 5.43505C19.1284 5.28386 19.0981 5.13425 19.0397 4.9948C18.9813 4.85534 18.896 4.72876 18.7886 4.62231Z"
                        fill="url(#paint1_linear_2492_21155)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2492_21155"
                          x1="15.2684"
                          y1="4.11133"
                          x2="-0.982444"
                          y2="7.26643"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_2492_21155"
                          x1="18.7686"
                          y1="1.00977"
                          x2="10.9914"
                          y2="2.5165"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F53939" />
                          <stop offset="0.203125" stopColor="#FF6F1E" />
                          <stop offset="1" stopColor="#FFC939" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-8">
            <div className="profile-detail p-4">
              <div className="row">
                <div className="col-12 profile-body2 ">
                  {profile.description}
                </div>
                <div
                  className="col-12 mt-3 pb-4"
                  style={{ borderBottom: "1px solid #E2E8F0" }}
                >
                  <span
                    className="badge rounded-pill mr-3"
                    style={{
                      background: "#E2E8F0",
                      color: "#565656",
                      marginTop: "10px",
                    }}
                  >
                    {profile.industry}
                  </span>
                  <span
                    className="badge rounded-pill mr-3"
                    style={{
                      background: "#E2E8F0",
                      color: "#565656",
                      marginTop: "10px",
                    }}
                  >
                    {profile.audience}
                  </span>
                </div>
                <div className="col-12 mt-4 mb-3">
                  <div className="profile-body2">{profile.companyName}</div>
                  <div className="profile-body2">
                    ({profile.contact.jobTitle})
                  </div>
                  <div className="profile-body2 mt-4">
                    <LinkedInIcon
                      onClick={() => {
                        window.open(
                          `${profile.socialConnects.linkedin}`,
                          "_blank"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                      color="primary"
                      className="mr-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-detail p-4 mt-4">
              <div className="d-flex justify-content-between align-items-center">
                <div className="profile-body2">
                  Currently on {user.plan} plan
                </div>
                <div>
                  {user.plan !== "Premium" ? (
                    <button
                      className="gradient_CTA"
                      onClick={() => {
                        navigate("/pricing");
                      }}
                    >
                      UPGRADE PLAN
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="profile-detail">
              <div className="subtitle p-4 profile-greybox">Highlights</div>
              <div className="row p-4">
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Location
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.location.country}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Audience
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.audience}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Employees
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.employees}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Funding
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.fundingSeries}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Growth
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.growth}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Industry
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.industry}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Revenue
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.revenue}
                </div>
                <div className="body2 mb-3 col-4" style={{ fontSize: "14px" }}>
                  Category
                </div>
                <div
                  className="body2 mb-3 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {user.type}
                </div>
                <div className="body2 col-4" style={{ fontSize: "14px" }}>
                  Contact
                </div>
                <div
                  className="body2 col-8"
                  style={{ fontSize: "14px", color: "#565656" }}
                >
                  {profile.contact.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
