import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "./../../../../../Utils/Auth";
import ParticipantProfile from "../ParticipantProfile";
import NormalProfile from "../InviteParticipant";
import { Avatar } from "@mui/material";
import rocketimg from "./../../../../../Assets/images/Rocket.png";
import MentorInvite from "../mentorinvite";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ConnectionCard from "../../../../../Components/Cards/ConnectionCard";
import {
  ParticipantsSkeleton,
  ParticipantsCardSkeleton,
  ParticipantsEmailInvite,
  ParticipantsInvite,
  ParticipantsMentee,
} from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";
import MenteeProfile from "../MenteeProfile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const index = ({ programid, menteesProfile }) => {
  const [participants, setparticipants] = useState([]);
  const [invites, setinvites] = useState([]);
  const { setSessionExpire, user } = useAuth();
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [title, settitle] = useState("");
  const [count, setcount] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // getting data
  const fetchparticipants = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/incubatorProgram/members/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setparticipants(data.data);
        settitle(data.program.title);
        setcount(data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        setIsLoading(false);
      });
  };
  const fetchallstartups = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/incubatorProgram/program/new-member/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setinvites(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchparticipants();
    fetchallstartups();
  }, []);

  return (
    <>
      {step === 1 ? (
        <>
          <ParticipantProfile
            setstep={setstep}
            setparticipantid={setparticipantid}
            startupid={participantid}
            title={title}
          />
        </>
      ) : step === 2 ? (
        <>
          <NormalProfile
            setstep={setstep}
            startupid={participantid}
            title={title}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              {user.type === "mentor" && (
                <div className="col-lg-12 col-sm-12 mx-3 mb-4">
                  <div className="dashboard01_block h-fit">
                    <p className="p_spartan_20 mb-4 mt-2">My mentee</p>
                    {isLoading ? (
                      <ParticipantsMentee />
                    ) : (
                      <div className="row">
                        <MenteeProfile
                          menteesProfile={menteesProfile}
                          handleFunction={(id) => {
                            setparticipantid(id);
                            setstep(1);
                          }}
                          userType="startup"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {user.type === "investor" && (
                <div className="col-lg-12 col-sm-12 mx-3 mb-4">
                  <div className="dashboard01_block h-fit">
                    <p className="p_spartan_20 mb-4 mt-2">
                      Assigned participants
                    </p>
                    {isLoading ? (
                      <ParticipantsMentee />
                    ) : (
                      <div className="row">
                        <MenteeProfile
                          menteesProfile={menteesProfile}
                          userType="startup"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="col-lg-12 col-sm-12">
                <div className="row m-0">
                  {isLoading ? (
                    <ParticipantsSkeleton />
                  ) : participants.length === 0 ? (
                    <p className="mx-4">No participants available</p>
                  ) : (
                    participants.map((v, i) => {
                      return (
                        <>
                          <div
                            className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                            key={i}
                          >
                            <ConnectionCard
                              handleFunction={() => {
                                setstep(1);
                                setparticipantid(v.profile._id);
                              }}
                              name={v.profile.companyName}
                              profilepic={v.profile.logo}
                              state={v.profile.location.state}
                              country={v.profile.location.country}
                              chip1={v.profile.industry}
                              chip2={v.profile.audience}
                            />
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="d-flex justify-content-center ">
                {isLoading ? (
                  <ParticipantsEmailInvite />
                ) : (
                  <button
                    onClick={() => handleOpen()}
                    type="button"
                    className="gradient_CTA"
                  >
                    INVITE STARTUPS BY EMAIL
                  </button>
                )}
              </div>
              {isLoading ? (
                <ParticipantsCardSkeleton />
              ) : (
                <div className="dashboard01_block h-fit">
                  <p className="p_spartan_20 mt-2">Total Startups</p>
                  <div className="row mx-0 my-2 r_a">
                    <div className="col-8">
                      <p className="bold my-0 h4" style={{ fontSize: "32px" }}>
                        {count}
                      </p>
                    </div>
                    <div className="col-4">
                      <img src={rocketimg} />
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="dashboard01_block">
              <p className="p_spartan_20 mt-2">Location</p>
                <div className='row mx-0 my-2 r_a'>
                  <div className="col-8">
                  <p className="bold my-0 h4" style={{fontSize: "32px"}}>10</p>
                  </div>
                </div>
            </div>
            <div className="dashboard01_block">
              <p className="p_spartan_20 mt-2">Industry</p>
                <div className='row mx-0 my-2 r_a'>
                  <div className="col-8">
                  <p className="bold my-0 h4" style={{fontSize: "32px"}}>5</p>
                  </div>
                </div>
            </div> */}
              <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mb-4 mt-2">Invite participants</p>
                {invites.length === 0 ? (
                  <ParticipantsInvite value={4} />
                ) : invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        <div key={i} className="row mx-0 my-2 r_a">
                          <Avatar src={v.logo} />
                          <div className="col-8">
                            <p className="bold my-0">{v.companyName}</p>
                            <p
                              onClick={() => {
                                setstep(2);
                                setparticipantid(v._id);
                              }}
                              className="font12 grey"
                              style={{ cursor: "pointer" }}
                            >
                              View Profile
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p>Start connecting with participants</p>
                )}
              </div>
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 500 }}>
              <MentorInvite handleClose={handleClose} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
};

export default index;
