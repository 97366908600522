import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import {ConnectionsSkeleton} from '../../../Components/SkeletonLoader/ConnectionSkeleton'
export default function Requests() {
  const [requests, setRequests] = useState([]);
  const { user } = useAuth();
  const [isLoading,setIsLoading] = useState(false)
  const getReceived = async () => {
    setIsLoading(true)
    await axios({
      url: "/api/connection/my-connections",
      method: "get",
    })
      .then((res) => {
        setRequests(res.data.connections);
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setIsLoading(false)
        }
      });
  };

  useEffect(() => {
    getReceived();
  }, []);

  return (
    <div className="">
      <p className="p_spartan_20 mb-4">My Network</p>
      <div className="row">
        {
          isLoading ? <ConnectionsSkeleton/> : requests.length > 0 ? (
            <>
            {
               requests.map((request, i) => (
                <div className="col-lg-6 col-sm-6 col-xs-12 my-2" key={i}>
                  <Link
                    to={
                      request.sender._id === user.id
                        ? `/dashboard/${user.type}?tab=1651&${request.receiver.type}=${request.receiver._id}`
                        : `/dashboard/${user.type}?tab=1651&${request.sender.type}=${request.sender._id}`
                    }
                  >
                    <div className="connection_request_item">
                      <div className="col-6 row">
                        <Avatar
                          src={
                            request.sender._id === user.id
                              ? request.receiver.avatar
                              : request.sender.avatar
                          }
                        />
                        <p className="px-3 spartan">
                          {request.sender._id === user.id
                            ? (request.receiver.name.length > 12 ? request.receiver.name.slice(0, 12) + "..." : request.receiver.name)
                            : (request.sender.name.length > 12 ? request.sender.name.slice(0, 12) + "..." : request.sender.name)}
                        </p>
                      </div>
                      <div className="col-6 row justify-content-between">
                        <p className="">
                          {request.sender._id === user.id
                            ? request.receiver.type
                            : request.sender.type}
                        </p>
                        <p className="font12 grey bold">
                          View{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                            />
                          </svg>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            }
            </>
          ):(
            <p className="spartan mx-auto"></p>
          )
        }
      </div>
    </div>
  );
}
