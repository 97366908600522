import React, { useState, useEffect } from "react";
import industries2 from "../../Lists/Industries2.json";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoNavbar from "../../../Layout/Navbar/LogoNavbar";

import { useAuth } from "../../../Utils/Auth";
import NotFoundPage from "./../../404Pages";

import Step1 from "./Regsteps/Step1";
import Step2 from "./Regsteps/Step2";
import Step3 from "./Regsteps/Step3";

const Edit_mentor = () => {

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step1
          bio={bio}
          setbio={setbio}
          linkedProfile={linkedProfile}
          setlinkedProfile={setlinkedProfile}
          category={category}
          setcategory={setcategory}
          curcompany={curcompany}
          setCurcompany={setCurcompany}
          jobrole={jobrole}
          setJobrole={setJobrole}
          country={country}
          setcountry={setcountry}
          state={state}
          setstate={setstate}
          city={city}
          setcity={setcity}
          states={states}
          setstates={setstates}
          cities={cities}
          setcities={setcities}
          profilePic={profilePic}
          setProfilePic={setProfilePic}
          othercategory={othercategory}
          setothercategory={setothercategory}
          handlePicUpload={handlePicUpload}
          islogoloading={islogoloading}
          setislogoloading={setislogoloading}
          />
        );

      case 1:
        return (
          <Step2
          initial={initial}
          setinitial={setinitial}
          experiencetime = {experiencetime}
          setexperiencetime = {setexperiencetime}
          experience = {experience}
          setExperience = {setExperience}
          preferredtime = {preferredtime}
          setpreferredtime = {setpreferredtime}
          motivation = {motivation}
          setmotivation = {setmotivation}
          othermotivation = {othermotivation}
          setothermotivation = {setothermotivation}
          involvement = {involvement}
          handleMultiselect={handleMultiselect}
          options={options}
          interest={interest}
          addInputEvent3={addInputEvent3}
          />
        );

      case 2:
        return (
          <Step3
          handleIDUpload={handleIDUpload}
          setPhotoID={setPhotoID}
          photoId = {photoId} 
          phone={phone}
          setPhone={setPhone}
          // setphoneVerified={setphoneVerified}
          idType ={idType}
          setIDType = {setIDType}
          isdocuploading ={isdocuploading}
          setisdocuploading = {setisdocuploading}
          />
        );
    }
  }

  const [initial,setinitial] = useState(true);
  // STEP 1
  const [curcompany, setCurcompany] = useState("");
  const [jobrole, setJobrole] = useState("");
  const [bio, setbio] = useState("");
  const [linkedProfile, setlinkedProfile] = useState("");
  const [category, setcategory] = useState("");
  const [othercategory, setothercategory] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [islogoloading, setislogoloading] = useState(false);
  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!");
      return;
    }
    const formdata = new FormData();
    formdata.append("profilepic", image);
    setislogoloading(true);
    Axios({
      method: "post",
      url: " /api/investor/profilepic",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setProfilePic(data.location);
        setislogoloading(false);
        toast.success("Profile Image upload");
      })
      .catch(() => {
        toast.error("Error in upload");
        setislogoloading(false);
      });
  };


  // STEP 2
  const [motivation, setmotivation] = useState("");
  const [othermotivation, setothermotivation] = useState("");
  const [interest, setInterest] = useState([]);
  const [experiencetime, setexperiencetime] = useState("");
  const [experience, setExperience] = useState("");
  const [preferredtime, setpreferredtime] = useState("");
  const [involvement, setinvolvement] = useState([
    { i: 0, name: "Sustained and active involvement", isSelected: false },
    { i: 1, name: "Reviewing documents from time to time", isSelected: false },
    {
      i: 2,
      name: "Offer feedback on product features/strategy",
      isSelected: false,
    },
    { i: 3, name: "Introduction to investors", isSelected: false },
    { i: 4, name: "Introduction to customers", isSelected: false },
    { i: 5, name: "Introduction to suppliers/partners", isSelected: false },
    { i: 6, name: "Advice and support on need basis", isSelected: false },
  ]);
  const [options] = useState(industries2);
  const addInputEvent3 = (e) => {
    setInterest(e.map((i) => i.label));
  };
  const handleMultiselect = (e, name, i) => {
    let newArray = [];
    involvement.map((v, index) => {
      if (index === i) {
        newArray.push({ i, name, isSelected: e.target.checked });
      } else newArray.push(v);
    });
    setinvolvement(newArray);
  };

  // STEP 3
  const [phone, setPhone] = useState(null);
  // const [phoneVerified, setphoneVerified] = useState(false);
  const [photoId, setPhotoID] = useState("");
  const [idType, setIDType] = useState("");
  const [isdocuploading, setisdocuploading] = useState(false);

  const handleIDUpload = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg" &&
      image.type !== "application/pdf"
    ) {
      toast.error("Only Image And PDF files are allowed!", {
        position: "bottom-right",
      });
      return;
    }
    const formdata = new FormData();
    formdata.append("photoID", image);
    setisdocuploading(true);
    Axios({
      method: "post",
      url: " /api/upload/photoID",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          const filename = {
            docType: idType,
            downloadUrl: data.location,
          };
          setPhotoID(filename);
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }
        setisdocuploading(false);
        toast.success("Photo ID Uploaded", { position: "bottom-right" });
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  


  const { user, profile, getUser } = useAuth();

  const navigate = useNavigate();



  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (profilePic === null) return toast("Add profile picture");
    if (
      interest.length === 0 ||
      linkedProfile.length === 0 ||
      linkedProfile.length === 0 ||
      experience.length === 0 ||
      motivation.length === 0 ||
      category.length === 0 ||
      preferredtime.length === 0 ||
      bio.length === 0
    )
      return toast.warning("Some fields are missing");

    if (country.length === 0) return toast.warning("Select Address");

    Axios({
      method: "put",

      url: "/api/mentor/edit-mentor",

      data: {
        profilePic,
        bio,
        interestedDomains: interest,
        linkedin: linkedProfile,
        location: {
          country,
          state,
          city,
        },
        pastExperience: experience,
        currentCompany: curcompany,
        currentJobRole: jobrole,
        motivation: motivation === "Other" ? othermotivation : motivation,
        category: category === "Other" ? othercategory : category,
        phone,
        preferredtime,
        involvement,
        experiencetime,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Mentor Updated");
          getUser();
          navigate("/dashboard/mentor?tab=9");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  useEffect(()=>{
    // console.log(profile);
    if(profile){
      if(profile.currentCompany)
        setCurcompany(profile.currentCompany);
        if(profile.currentJobRole)
        {setJobrole(profile.currentJobRole)}
        if(profile.bio)
        {setbio(profile.bio)}
        if(profile.linkedin)
        {setlinkedProfile(profile.linkedin)}
        if(profile.category)
        {setcategory(profile.category)}
        if(profile.location.city)
        {setcity(profile.location.city)}
        if(profile.location.state)
        {setstate(profile.location.state)}
        if(profile.location.country)
        {setcountry(profile.location.country)}
        if(profile.profilePic)
        {setProfilePic(profile.profilePic)}
        if(profile.motivation)
        {setmotivation(profile.motivation)}
        if(profile.interestedDomains)
        {setInterest(profile.interestedDomains)}
        if(profile.experiencetime)
        {setexperiencetime(profile.experiencetime)}
        if(profile.pastExperience)
        {setExperience(profile.pastExperience)}
        if(profile.preferredtime)
        {setpreferredtime(profile.preferredtime)}
        if(profile.involvement)
        {setinvolvement(profile.involvement)}

  
        if(profile.contact.phone)
        {setPhone(profile.contact.phone)}
    }
  },[profile]);

  if (!user) return <NotFoundPage />;

  return (
    <>
      <div className="regis">
        <LogoNavbar />

        <div className="register_page">
          
          {/* -------------STEP 1------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-head w-100">
                  PERSONAL DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                  About yourself
                  </p>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead w-100">
                  MENTORSHIP 
                  </a>
                  </div>
                  {/* <div>
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                  DOCUMENTS
                  </a>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part1">{getStepContent(0)}</div>
              </div>
            </div>
          </div>

          
          {/* -------------STEP 2------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead w-100">
                  PERSONAL DETAILS
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-head w-100">
                  MENTORSHIP 
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                  About yourself
                  </p>
                  </div>
                  {/* <div>
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                  DOCUMENTS
                  </a>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part2">{getStepContent(1)}</div>
              </div>
            </div>
          </div>

          
          {/* -------------STEP 3------------------ */}
          {/* <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead w-100">
                  PERSONAL DETAILS
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead w-100">
                  MENTORSHIP 
                  </a>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-head w-100">
                  DOCUMENTS
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                  About yourself
                  </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part3">{getStepContent(2)}</div>
              </div>
            </div>
          </div> */}

        {/* -------------REGISTER------------------ */}
        <div className="container mb-40">
          <div className="row">
            <div className="col-12 col-md-4">
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex mb-14" style={{ marginTop: "163px" }}>
                <div className="">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM9 5V7H11V5H9Z" fill="#989898"/>
                  </svg>
                </div>
                <div className="">
                  <p style={{marginLeft:"6px"}}>
                  All the documents submitted here will be treated as strictly confidential and will not be used/disclosed anywhere
                  </p>
                </div>

              </div>
                <div
                  className="w-100"
                >
                  <button
                    onClick={(e) => handleOnSubmit(e)}
                    className="gradient_CTA"
                    style={{ width: "50%" }}
                  >
                    {" "}
                    UPDATE{" "}
                  </button>
                </div>
            </div>
          </div>
        </div>




          {/* -------------REMAINS------------------ */}
          {/* <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-start">
              <div className="col-12 col-md-4">
                <div className="register_page_sideborder">
                  <p className="register_page_sideborder-head">
                    PLEASE ADD YOUR DETAILS
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="register_page_form">
                  <form onSubmit={handleOnSubmit}>

                    <div className="register_page_form row">

                      <div className="col-12">
                        <FormLabel id="demo-radio-buttons-group-label">
                          Gender
                        </FormLabel>
                        <br />
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(e) => setgender(e.target.value)}
                          // style={{ display: "flex", flexDirection: "row" }}
                          className="row flex-row"
                        >
                          <FormControlLabel
                            value="female"
                            control={
                              <Radio
                                sx={{
                                  color: "#FF6F1E  !important",
                                  "&.Mui-checked": {
                                    color: "#FF6F1E",
                                  },
                                  marginRight: "8px",
                                  marginTop: " 8px",
                                }}
                              />
                            }
                            label="Female"
                            className="col-12 col-md-4 mt-8"
                          />
                          <FormControlLabel
                            value="male"
                            control={
                              <Radio
                                sx={{
                                  color: "#FF6F1E  !important",
                                  "&.Mui-checked": {
                                    color: "#FF6F1E",
                                  },
                                  marginRight: "8px",
                                  marginTop: " 8px",
                                }}
                              />
                            }
                            label="Male"
                            className="col-12 col-md-4 mt-8"
                          />
                          <FormControlLabel
                            value="other"
                            control={
                              <Radio
                                sx={{
                                  color: "#FF6F1E  !important",
                                  "&.Mui-checked": {
                                    color: "#FF6F1E",
                                  },
                                  marginRight: "8px",
                                  marginTop: " 8px",
                                }}
                              />
                            }
                            label="Other"
                            className="col-12 col-md-4 mt-8"
                          />
                        </RadioGroup>
                      </div>

                      <div className="col-12">
                        <FormLabel id="demo-radio-buttons-group-label">
                          What is your preferred mode of contact between
                          mentor/mentee *
                        </FormLabel>
                        <br />

                        {preferredmode.map((v, i) => (
                          <>
                            <FormControlLabel
                              className="col-12 col-md-4 mt-8 pl-0"
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#262C5B",
                                    "&.Mui-checked": {
                                      color: "#262C5B",
                                    },
                                    marginRight: "10px",
                                  }}
                                  onChange={(e) =>
                                    handleMultimode(e, v.name, i)
                                  }
                                  name={" " + v.name}
                                />
                              }
                              label={v.name}
                            />
                          </>
                        ))}
                        
                      </div>

                      <div className="col-12">
                        <label className="auth_label">
                          Describe your brief professional profile
                        </label>
                        <textarea
                          required
                          multi
                          name="short_desc"
                          className="auth_input auth_textarea col-12"
                          placeholder="Profile Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">
                          Please state in 3 lines, why do you want to mentor
                          with us and how we can make this arrangement most
                          beneficial for all parties involved
                        </label>
                        <div className="goalsdiv">
                          <input
                            name="short_desc"
                            className="auth_input auth_textarea col-12"
                            type="text"
                            placeholder="Enter your Reasons"
                            id="objective"
                            value={reasonVal}
                            onChange={(e) => setreasonVal(e.target.value)}
                          />
                          <Tooltip title="Add">
                            <button type="button" onClick={addbenefit}>
                              <AddIcon />
                            </button>
                          </Tooltip>
                        </div>

                        <div className="goalsscroll">
                          {reason
                            ? reason.map((obj, i) => {
                                return (
                                  <>
                                    <span
                                      className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3"
                                      key={i}
                                    >
                                      <p className="program_text">{obj}</p>
                                      <Tooltip title="Delete Row">
                                        <button
                                          type="button"
                                          title="Remove"
                                          onClick={() => removebenefit(i)}
                                          className="program_btn"
                                        >
                                          x
                                        </button>
                                      </Tooltip>
                                    </span>
                                  </>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="auth_label">Industry</label>
                        <select
                          className="auth_input auth_select"
                          name="industry"
                          value={industry}
                          onChange={(e) => setIndustry(e.target.value)}
                        >
                          <option value="">Industry</option>
                          {industries.map((item, i) => {
                            return (
                              <option key={i} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {industry === "Other" ? (
                        <div className="col-12">
                          <input
                            type="text"
                            placeholder="Please specify your industry"
                            className="w_49 auth_input"
                            value={otherIndustry}
                            onChange={(e) => setOtherIndustry(e.target.value)}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      

                      
                      
                      

                      
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Edit_mentor;
