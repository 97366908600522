import React from "react";
import welcome_img from "../../Assets/images/welcome.png";
import logo from "./../../Assets/images/logo/logo.png";
// import "./../Registration/authentication.css";
// import "./../../Components/Css/common.css";
// import Navbar from "./../../Layout/Navbar/Navbar";
// import BackgroundImg from './../Home/backgroundImg';
import { NavLink } from "react-router-dom";
import { useAuth } from "../../Utils/Auth";

const Welcome = () => {
    const { name, role } = useAuth();
    const [imageLoaded, setImageLoaded] = React.useState(false);
  
    return (
        <>
            {/* <Navbar /> */}
            <div className="auth_back_up">
                <div className="container">
                <div className="row mt-5">
                    <a href="/"><img src={logo} alt="logo" /></a>
                </div>
                <div className="row mt-5">
                <div className="col-12 mt-5">
                    <h2 className="auth_back_head">Time to get going !</h2>
                </div>
                    {/* <div className="col-12 col-md-6">
                        <a href="/"><img src={logo} /></a>
                        <img
                            className={` img-fluid smooth-image auth_option_img image-${
                                imageLoaded ? "visible" : "hidden" 
                            } auth_image`}
                            onLoad={() => setImageLoaded(true)}
                            src={welcome_img}
                            alt="Welcome"
                        />
                        <div className="auth_back">
                        <i className="fa fa-arrow-left"></i>
                        Back
                        </div>
                    </div>
                    <div className="col-12 col-md-6">

                    </div> */}

                    <div className="col-12 mt-5 row">
                    <div className="col-12 col-md-5 mt-5">
                        <h1 className="auth_back_head mt-4" style={{color: "#000"}}>WELCOME {name ? name.toUpperCase() : ""}</h1>
                        <p className="auth_back_p">Welcome to Zefyron Incubation, now proceed with the registration process to join us.</p>
                        <NavLink to={`/register/${role}`} className="gradient_CTA ">
                            {" "}
                          Register Now{" "}
                        </NavLink>
                    </div>
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-5">
                            <img
                                className={` img-fluid smooth-image auth_option_img image-${
                                    imageLoaded ? "visible" : "hidden"
                                } shift_img`}
                                onLoad={() => setImageLoaded(true)}
                                src={welcome_img}
                                alt="Welcome"
                            />
                    </div>
                        {/* <div className="auth_img_cont">
                            <img
                                className={` img-fluid smooth-image auth_option_img image-${
                                    imageLoaded ? "visible" : "hidden"
                                }`}
                                onLoad={() => setImageLoaded(true)}
                                src={welcome_img}
                                alt="Welcome"
                            />
                        </div>
                        <div className="welcome_name mb-3">
                            <h2 className="h2_white_35">
                              WELCOME {name ? name.toUpperCase() : ""},
                            </h2>
                            <p className="p_small_grey_bold letter_spacing_1_2 py-1">
                              Please complete full registration to get started
                            </p>
                        </div>
                        <div className="register_btn">
                            <NavLink to={`/register/${role}`} className="w_33 gradient_btn ">
                                {" "}
                              Register{" "}
                            </NavLink>
                        </div> */}
                    </div>
                    </div>
                </div>
            </div>
            {/* <BackgroundImg /> */}
        </>
    );
};

export default Welcome;
