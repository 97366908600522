import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import sendicon from "../../../../Assets/images/icons/sendicon.png";
import { CameraAltOutlined } from '@mui/icons-material';
import Axios from "axios";
import Posts from "../Posts";
import { useAuth } from "../../../../Utils/Auth";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import './allpost.css';
import {FeedLoader} from '../../../../Components/SkeletonLoader/FeedSkeleton'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const Allpost = () => {

    const [postImage, setPostImage] = useState("");
    const [isLoading,setIsLoading] = useState(false) //isLoading
    
    // const [isPhotoUploading, setIsPhotoUploading] = useState(false);
    const [text, setText] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const [posts, setPosts] = useState([]);
    const [mostLiked, setMostLiked] = useState([]);
    const { setSessionExpire, user } = useAuth();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getPosts = async () => {
        setIsLoading(true);
        await Axios({
            url: "/api/post/all",
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                // const { data } = res;
               
                setPosts(res.data.data);
                setIsLoading(false);
                // setDesc((data.desc).toString().replaceAll('$newLine$','\n'));
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                }
                setIsLoading(false);
            });
    };

    const getMostLiked = async () => {
        await Axios({
            url: "/api/post/most-liked",
            method: "get",
        })
            .then((res) => {
                // const { data } = res;
                setMostLiked(res.data.data.liked);
                // setDesc((data.desc).toString().replaceAll('$newLine$','\n'));
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                }
            });
    };

    const handleUploadImage = (e) => {
        // setIsPhotoUploading(true)
        const image = e.target.files[0];
        if (image.size > 2048 * 1024) {
            toast.error("File size exceeded 2MB!");
            return;
        }
        const formdata = new FormData();
        formdata.append("post-photo", image);

        Axios({
            method: "post",
            url: "/api/post/upload-photo",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                setPostImage(data.location);
                toast.success("Image uploaded");
                setIsCreating(true)
                // setIsPhotoUploading(false)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setSessionExpire(true)
                }
                // setIsPhotoUploading(false)
            });
    };

    const createPost = async (e) => {
        e.preventDefault();
        if (text.length === 0 && !postImage)
            return toast.error("Enter post details!");
        await Axios({
            method: "POST",
            url: "/api/post/create",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                desc: text,
                photoUrl: postImage,
            },
        })
            .then(() => {
                toast.success("Post added!");
                setText("");
                setPostImage("");
                getPosts();
                setIsCreating(false)
            })
            .catch((err) => {
                // toast.error("Could not post");
                if (err.response.status === 401) {
                    setSessionExpire(true)
                }
            });
    };

    useEffect(() => {
        getPosts();
        getMostLiked()
    }, [])

    return <div className="col-lg-8 neWpost-root">
        <div className="row">
            <div className="col-12">
                <div className={`newPost-create-main ${isCreating ? 'addPostHeight' : 'removePostHeight'}`}>
                    <div className="nPost_img_main_text d-flex justify-content-between align-items-center w_100">
                        <div className="nPost_img_root_text" onClick={() => setIsCreating(!isCreating)}>
                            <img src={user.avatar} alt="profile" className="profile_img" />
                            <div className="ml-16">
                                <p className="subtitle color_dark_grey">{isCreating ? user.name : "Share your thoughts..."}</p>
                            </div>

                        </div>
                        <div className="nPost_img_root_btns">
                            <button className="transparent_cta postUploadImg">
                                <label htmlFor="uploadpost"><CameraAltOutlined /></label>
                                <input
                                    type="file"
                                    name="uploadpost"
                                    id="uploadpost"
                                    onChange={handleUploadImage}
                                    accept="video/*,image/*"
                                />
                            </button>
                            <button className="transparent_cta" onClick={createPost}><img src={sendicon} alt="send" /></button>
                        </div>
                    </div>
                    {
                        isCreating ? <div className="nPost_img_input_root mt-16 w_100">
                            <textarea
                                placeholder="Type here"
                                className="body2"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            ></textarea>
                            {
                                postImage !== "" ? <img src={postImage} alt="postImage" /> : null
                            }

                        </div> : null
                    }
                </div>

                <div className="newPosts-main">
                    <div className="feedAllPost_tabs_root mt-24">
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="feedAllPost_tabs">
                            <Tab className="feedAllPost_tab" label="Latest" {...a11yProps(0)} />
                            <span className='feedAllPost_tab_span'></span>
                            <Tab className="feedAllPost_tab" label="Most liked" {...a11yProps(1)} />
                        </Tabs>
                    </div>
                    <div className="feedAllPost_tabpanel_root">
                        <TabPanel value={value} index={0}>
                        <>
                            
                            {
                                isLoading ? <FeedLoader/> :
                                posts.length > 0 ?
                                    posts.map((post, i) => <Posts
                                        key={i}
                                        post={post}
                                        getPosts={getPosts}
                                    />) : null
                            }
                        </>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            {
                                isLoading ? <FeedLoader/> :
                                mostLiked.length > 0 ?
                                mostLiked.map((post, i) => <Posts
                                        key={i}
                                        post={post}
                                        getPosts={getPosts}
                                    />) : null
                            }
                        </TabPanel>
                    </div>
                </div>

                {/* <div className="newPosts-main">

                </div> */}
            </div>
        </div>
    </div>
}

export default Allpost