import React, { useState, useEffect } from "react";
import Axios from "axios";
import AssesmentForm from "./assessmentForm";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { useAuth } from "../../../../../../Utils/Auth";

const AddAssignment = ({ setAddSessionState }) => {

  const navigate = useNavigate()
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  // const {user} = useAuth()
  const {programid} = useParams()
  const [name, setName] = useState("");
  const [assessment, setassessment] = useState(null);
  // const [question, setQuestion] = useState("")
  const module_id = query.get('module')
  const course_id = query.get('course')

  const getAssessment = async () => {
    await Axios({
      url: `/api/mentorModules/assessment/${module_id}`,
      method: 'get'
    }).then(res => {
      if (res.data.data)
        setassessment(res.data.data)
    }).catch(() => {
      toast.error("error in fetching.")
    })
  }
  useEffect(() => {
    if (module_id)
      getAssessment()
  }, [module_id])

  const createAssesment = async (e) => {
    e.preventDefault();
    if (assessment) {
      await Axios({
        url: `/api/mentorModules/assessment/update/${module_id}`,
        method: "post",
        data: {
          name: name,
        },
      })
        .then((res) => {
          // setAddSessionState(3)
          setassessment(res.data.data);
          toast.success("Assessment Updated")
        })
        .catch(() => {
          toast.error('Try again')
        }
        );
    }
    else {
      await Axios({
        url: `/api/mentorModules/assessment/create/${course_id}/${module_id}`,
        method: "post",
        data: {
          name: name,
        },
      })
        .then((res) => {
          // setAddSessionState(3)
          setassessment(res.data.data);
        })
        .catch((err) => {
          toast.error('Try again')
          console.log(err)
        }
        );
    }

  };


  React.useEffect(() => {
    if (module_id)
      getAssessment()
  }, [module_id])

  React.useEffect(() => {
    if (assessment && assessment.name) {
      setName(assessment.name)
    }
  }, [assessment])

  React.useEffect(() => {
    scrollTo(0, 0)
  }, [])

  return (
    <div className="mt-3 w_100">
      <p className="body1 mb-3 point" onClick={() => {
        setAddSessionState(0);
        navigate(
          `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
        );
      }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-left mb-1 mx-2"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
          />
        </svg>
        Back
      </p>
      <h4>Assessment Details </h4>
      <div className="create_course_form_main">
        <div className="row">
          <div className="col-12 create_course_form_maindiv">
            <label htmlFor="Module 1" className="caption color_dark_blue">
              Name of assessment
            </label>
            <input
              type="text"
              className="body1"
              placeholder="Enter the name of assesment"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-12 create_course_form_maindivBtn">
            <button
              className="primaryFilled_activeCta"
              onClick={createAssesment}
            >
              {!assessment ? "Save and Next" : "Update"}
            </button>
          </div>
        </div>
      </div>
      {assessment ? (
        <div className="my-5">
          <AssesmentForm 
            assessment={assessment} 
            getAssignment={getAssessment} 
            setAddSessionState={setAddSessionState}  
          />
        </div>
      ) : null}
    </div>
  );
};

export default AddAssignment;
