import React from 'react'
// import {ArrowForwardOutlined } from '@mui/icons-material';
import profile_short from "../../../../../Assets/images/incubatorDash/profile_short.png"
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../../../Utils/Auth"

const AllReqCard = (props) => {
    const { setView, data } = props;
    const navigate = useNavigate()
    const {user} = useAuth()
    // function useQuery() {
    //     return new URLSearchParams(useLocation().search);
    // }
    // const query = useQuery();

    // var courseId = query.get("course");

    return <tr className="incubator_assessment_tbody_tr">
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_by">
            <img src={profile_short} alt="submitted By" />
            <p className="subtitle color_dark_black">{data.asset_id.asset_name}</p>
        </td>

        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_title">
            <p className="caption color_dark_black">{data.incubator_id.name ? data.incubator_id.name: `${data.incubator_id.firstname} ${data.incubator_id.lastname}` }</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_sub_on">
            <p className="caption color_dark_black">{new Date(data.createdAt).toLocaleDateString()}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_score">
            <p className="caption color_dark_black">€ {data.asset_id.price}</p>
        </td>
        <td className="incubator_assessment_tbody_td incubator_assessment_tbody_view">
            <button className='incubator_assessment_viewBtn caption color_dark_grey' onClick={()=>{
                setView(1)
                navigate(`/dashboard/${user.type}?tab=9&cw=5&asset=${data.asset_id._id}`)
            }}>View</button>
            {/* <p className="caption color_dark_grey" onClick={()=>{
                setAssessmentState(2);
                navigate(`/service/incprogram/${programid}?tab=6&course=${assessment.course_id._id}&assessment=${assessment.assessment_id._id}&user=${assessment.user_id._id}`)
            }}>View <ArrowForwardOutlined /></p> */}
        </td>
    </tr>
}

export default AllReqCard