import React from "react";
import "./../../authentication.css";
import CircularProgress from "@mui/material/CircularProgress";
import LocationComponent from "../../../../Utils/Location/index";
import { useAuth } from "../../../../Utils/Auth";

const Step1 = (props) => {
  const { isUser } = useAuth();
  const {
    bio,
    setbio,
    linkedProfile,
    setlinkedProfile,
    category,
    setcategory,
    curcompany,
    setCurcompany,
    jobrole,
    setJobrole,
    country,
    setcountry,
    state,
    setstate,
    city,
    setcity,
    states,
    setstates,
    cities,
    setcities,
    profilePic,
    setProfilePic,
    othercategory,
    setothercategory,
    handlePicUpload,
    islogoloading,
  } = props;

  const { user } = useAuth();

  if (!isUser) return <h1>Session Timed Out</h1>;

  return (
    <form>
    <div className="register_page_form_upload">
      {profilePic ? (
        <>
          <img
            src={profilePic}
            alt="logo"
            className="companyLogo"
          />
          <p
            className="p_small_black font12 mt-1"
            style={{ cursor: "pointer" }}
            onClick={() => setProfilePic(null)}
          >
            Delete
          </p>
        </>
      ) : (
        <>
          {islogoloading ? (
            <CircularProgress style={{ color: "black" }} />
          ) : (
            <>
              <label htmlFor="clogo">
                <div
                  className="register_page_form_com_logo"
                  onClick={{}}
                >
                  Upload
                </div>
                <input
                  type="file"
                  accept="image/*"
                  name="profile_pic"
                  id="clogo"
                  onChange={(e) => handlePicUpload(e)}
                />
              </label>
              <p className="register_page_form_upload-text mt-1">
                Profile Photo
              </p>
            </>
          )}
        </>
      )}
    </div>

      <div className="register_page_form row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <label>First Name*</label>
                <input
                  placeholder="First Name"
                  required
                  type="text"
                  className="auth_input"
                  value={user.firstname}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <label>Last Name*</label>
                <input
                  placeholder="Last Name"
                  required
                  type="text"
                  className="auth_input"
                  value={user.lastname}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <label className="auth_label">Mentor Profile*</label>
          <textarea
            required
            name="short_desc"
            className="auth_input auth_textarea col-12"
            placeholder="Write  a short description"
            value={bio}
            onChange={(e) => setbio(e.target.value)}
          ></textarea>
        </div>

        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">LinkedIn Profile</label>
            <input
              placeholder="LinkedIn Profile Url"
              required
              type="text"
              className="auth_input col-12 "
              value={linkedProfile}
              onChange={(e) => setlinkedProfile(e.target.value)}
            />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">Mentor Category*</label>
            <select
              className="auth_input"
              name="grossValue"
              value={category}
              onChange={(e) => setcategory(e.target.value)}
            >
              <option className="color_black" value="">
                Select
              </option>
              <option className="color_black" value="Experienced founder">
                Experienced founder
              </option>
              <option className="color_black" value="Investor">
                Investor
              </option>
              <option className="color_black" value="Subject matter expert">
                Subject matter expert
              </option>
              <option className="color_black" value="Academician / Researcher">
                Academician / Researcher
              </option>
              <option className="color_black" value="Other">
                Other
              </option>
            </select>
            </div>
          </div>
          <div className="col-12 col-md-6">
            
          {category === "Other" ? (
            <>
              <label className="auth_label">
                Please mention the category (if other)*
              </label>
              <input
                placeholder="Category"
                type="text"
                className="auth_input col-12"
                value={othercategory}
                onChange={(e) => setothercategory(e.target.value)}
              />
            </>
          ) : null}
          </div>
        </div>
        </div>

        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">Current Company*</label>
            <input
              placeholder="Current Company"
              type="text"
              className="auth_input"
              value={curcompany}
              onChange={(e) => setCurcompany(e.target.value)}
            />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">Current Job role*</label>
            <input
              placeholder="Current job role"
              type="text"
              className="auth_input"
              value={jobrole}
              onChange={(e) => setJobrole(e.target.value)}
            />
            </div>
          </div>
        </div>
        </div>
        <div className="col-12">
          <label>Location*</label>
        </div>
        <div className="col-12" style={{ display: "flex" }}>
          <LocationComponent
            country={country}
            setcountry={setcountry}
            state={state}
            setstate={setstate}
            city={city}
            setcity={setcity}
            states={states}
            cities={cities}
            setstates={setstates}
            setcities={setcities}
          />
        </div>
      </div>
    </form>
  );
};

export default Step1;
