import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Mentors from "./Mentors";
import Investors from "./Investors";
import Corporate from "./Corporates";
import ServiceProvider from "./ServiceProvider";

export default function LabTabs({ programid, setparticipantState }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: "divider" }}
      className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Mentors" value="1" />
            <Tab label="Investors" value="2" />
            <Tab label="Corporates" value="3" />
            <Tab label="Service Providers" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Mentors
            programid={programid}
            setparticipantState={setparticipantState}
          />{" "}
        </TabPanel>
        <TabPanel value="2">
          <Investors
            programid={programid}
            setparticipantState={setparticipantState}
          />
        </TabPanel>
        <TabPanel value="3">
          {" "}
          <Corporate
            programid={programid}
            setparticipantState={setparticipantState}
          />
        </TabPanel>
        <TabPanel value="4">
          {" "}
          <ServiceProvider
            programid={programid}
            setparticipantState={setparticipantState}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
