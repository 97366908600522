import React, { useEffect, useState } from 'react';
import iAsset from "../../../../Assets/images/financial/iAsset.png"
import footerLogo from "../../../../Assets/images/financial/footerLogo.png"
import Pdf from "react-to-pdf"
import { KeyboardBackspace } from "@mui/icons-material"
import { useNavigate } from 'react-router-dom';
import Query from '../../../../Utils/Query/Query';
import { useAuth } from '../../../../Utils/Auth';
import Axios from 'axios'

const ref = React.createRef();

const ViewInvoicing = ({ setformState }) => {
    const navigate = useNavigate()
    const query = Query()
    const { user, setSessionExpire } = useAuth()
    const invoiceId = query.get('invoice');
    const [data, setData] = useState(null)
    const [subTotal, setSubTotal] = useState(0)

    const getInvoice = async () => {
        await Axios({
            url: `/api/inc-finance/invoice/${invoiceId}`,
            method: "GET",
        }).then((res) => {
            setData(res.data.data)
            let stotal = 0;
            if(res.data.data.item_details)
            {
                for (let i = 0; i < res.data.data.item_details.length; i++) {
                    const element = res.data.data.item_details[i];
                    stotal += (element.price * element.quantity);
                }
                setSubTotal(stotal)
            }
            else{
                setSubTotal(0)
            }

        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true)
            }
        })
    }


    useEffect(() => {
        if (invoiceId) {
            getInvoice()
        }
    }, [invoiceId])

    return (
        <>
            {
                data ?
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <button className='coworking_back_btn btncta' onClick={() => {
                                setformState(0)
                                navigate(`/dashboard/${user.type}?tab=10&ft=2&in=0`)
                            }}>
                                <span><KeyboardBackspace /></span>
                                <span>BACK</span>
                            </button>
                            <Pdf targetRef={ref} filename="invoice-preview.pdf" x={0} y={0} scale={1}>
                                {({ toPdf }) => <button onClick={toPdf} className="primaryOutlined_activeCta">Generate Pdf</button>}
                            </Pdf>
                        </div>

                        <div ref={ref} className="incbator_invoice_pdf_root mt-40">
                            <div className="incbator_invoice_pdf_head">
                                <h5>INVOICE</h5>
                                <div>
                                    <p className="overline"> Date: {new Date(data.date).toLocaleDateString('en-GB')}</p>
                                    <p className="caption"> Invoice No: #2222</p>
                                </div>
                            </div>
                            <div className="incbator_invoice_pdf_main">
                                <div className="incbator_invoice_pdf_main_billto p-24">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="incbator_invoice_pdf_main_payto">
                                                <p className="body1">Bill to</p>
                                                <p className="overline mt-8">
                                                    {data.created_by.name}
                                                    {/* <br />
                                                    40 Red house ,
                                                    <br />
                                                    My Kingdom, UK */}
                                                </p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline">{data.created_by.email}</p>
                                                </div>
                                                {/* <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline"></p>
                                                </div> */}

                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="incbator_invoice_pdf_main_payto">
                                                <p className="body1">Pay to</p>
                                                <p className="overline mt-8">
                                                    {data.for_user.name}
                                                    {/* <br />
                                                    40 Red house ,
                                                    <br />
                                                    My Kingdom, UK */}
                                                </p>
                                                <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline">{data.for_user.email}</p>
                                                </div>
                                                {/* <div className='d-flex align-items-center'>
                                                    <span className='pdf-email-bg'></span>
                                                    <p className="overline">jhskdjfa@gmai.com</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="incbator_invoice_pdf_main_payto">
                                                <div className='d-flex align-items-center'>
                                                    <img src={iAsset} alt="twf-finance" />
                                                    <p className="body1 color_dark_blue ml-10 caps">{data.tools}</p>
                                                </div>
                                                <p className="overline mt-8">
                                                    {data.short_description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="incbator_invoice_pdf_table">
                                    <table className='w_100'>
                                        <thead>
                                            <tr>
                                                <td className='body2'>SL</td>
                                                <td className='body2'>Name</td>
                                                {/* <td className='body2'>Description</td> */}
                                                <td className='body2'>Rate</td>
                                                <td className='body2'>Qty.</td>
                                                <td className='body2'>Amount</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data.item_details && data.item_details.length > 0 ? data.item_details.map((asset, i)=><tr key={i}>
                                                <td className='overline'>{i+1}</td>
                                                <td className='overline'>{asset.name}</td>
                                                {/* <td className='overline'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</td> */}
                                                <td className='overline'>{asset.price}</td>
                                                <td className='overline'>{asset.quantity}</td>
                                                <td className='overline'>{Number(asset.price)*Number(asset.quantity)}</td>
                                            </tr>) : null
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="incbator_invoice_pdf_footer_main p-24">
                                    <div className="incbator_invoice_pdf_pay_info mt-40">
                                        <div className="row">
                                            <div className="col-6">
                                                {/* <p className="body2">Payment Info:</p>
                                                <div className="incbator_invoice_pdf_pay_table mt-16">
                                                    <table>
                                                        <tr>
                                                            <td className='caption'>Bank</td>
                                                            <td className='overline'>HDFC</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption'>Account No:</td>
                                                            <td className='overline'>1234567890</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption'>Ifsc code:</td>
                                                            <td className='overline'>FDG5202DS</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='caption'>Other Details:</td>
                                                            <td className='overline'>Sample Text</td>
                                                        </tr>
                                                    </table>
                                                </div> */}
                                            </div>
                                            <div className="col-6">
                                                <div className="incbator_invoice_pdf_pay_total">
                                                    <div className='incbator_invoice_pdf_pay_subtotal'>
                                                        <p className="body2">SubTotal</p>
                                                        <p className="overline">{subTotal}</p>
                                                    </div>
                                                    <div className='incbator_invoice_pdf_pay_subtotal'>
                                                        <p className="body2">Tax({data.tax ? data.tax : ""} {data.tax_value}%) </p>
                                                        <p className="overline">+{data.tax_amount}</p>
                                                    </div>
                                                    <div className='incbator_invoice_pdf_pay_subtotal incbator_invoice_pdf_pay_discount'>
                                                        <p className="body2">Discount{data.discount === "percent" ? ` (${data.discount_value}%)`:``}</p>
                                                        <p className="overline">-{data.discount_amount}</p>
                                                    </div>
                                                    <div className='incbator_invoice_pdf_pay'>
                                                        <p className="body1">Total</p>
                                                        <p className="body1">Euro {data.total_amount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="incbator_invoice_pdf_footer">
                                        <p className="overline">Powered by</p>
                                        <img src={footerLogo} alt="twf Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : <div>Loading...</div>
            }
        </>
    )
}

export default ViewInvoicing