import React, { useState, useEffect } from "react";
import AcceptCard from "./AcceptedCard";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";

const Accept = ({ setIncubationState }) => {
  const [applications, setapplications] = useState([]);
  const { setSessionExpire } = useAuth();

  const fetchapplications = async () => {
    await Axios({
      url: `/api/incinvestor/applications/mentor/false`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setapplications(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchapplications();
  }, []);
  return (
    <div className="incubation_program_main mt-4">
      <div className="row">
        {applications && applications.length > 0 ? (
          applications.map((program, i) => (
            <AcceptCard
              key={i}
              program={program}
              setIncubationState={setIncubationState}
            />
          ))
        ) : (
          <p>No Applications yet</p>
        )}
      </div>
    </div>
  );
};

export default Accept;
