import React, { useEffect, useState } from "react";
// import AddNewSession from "./AddNewSession";
import Axios from "axios";
import { toast } from "react-toastify";
import { AddBox } from "@mui/icons-material";
import ModuleComponent from "./ModuleComponent";

const CourseCurriculam = (props) => {
  const { setAddSessionState, courseId,setLearningState } = props;
  const [moduleName, setModulelName] = useState("");
  const [moduleDesc, setModulelDesc] = useState("");
  //   const [ setModule] = useState("");
  const [allModules, setAllModules] = useState([]);
  const [moduleOpen, setModuleOpen] = useState(true);

  const getModules = async () => {
    await Axios({
      url: `/api/mentorModules/course/${courseId}`,
      method: "get",
    })
      .then((res) => {
        setAllModules(res.data.mod);
        // toast.success("Module created");
        // setAddSessionState(1);
      })
      .catch(() => toast.error("Try Again"));
  };

  const createModule = async (e) => {
    e.preventDefault();
    if (!moduleName || moduleName === "") {
      toast.error("Title cannot be empty");
      return;
    }
    await Axios({
      url: `/api/mentorModules/module/create`,
      method: "post",
      data: {
        title: moduleName,
        description: moduleDesc,
        course_id: courseId,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // setModule(res.data.data);
          // sessionStorage.setItem("currentModule", JSON.stringify(res.data.data));
          toast.success("Module created");
          getModules();
          setModulelName("");
          setModulelDesc("");
          // setModuleOpen(false);
        }
      })
      .catch(() => toast.error("Try Again"));
  };

  useEffect(() => {
    getModules();
    // console.log(setAddSessionState)
  }, []);

  return (
    <div className="my-5">
      {/* {addSessionState === 0 ? ( */}
      <>
        <h4>Course Curriculum</h4>
        <div className="create_course_form_main">
          <div className="row">
            {allModules.length > 0
              ? allModules.map((data, i) => (
                <div className="col-12" key={i}>
                  <ModuleComponent
                    setAddSessionState={setAddSessionState}
                    data={data}
                    courseId={courseId}
                    allModules={allModules}
                    index={i}
                    getModules={getModules}
                  />
                </div>
              ))
              : ""}

            {moduleOpen ? (
              <>
                <div className="col-12 create_course_form_maindiv mt-3">
                  <label
                    htmlFor="Module 1"
                    className="caption color_dark_blue"
                  >
                    Module {allModules.length + 1}
                  </label>
                  <input
                    type="text"
                    className="body1"
                    placeholder="Enter Title"
                    value={moduleName}
                    onChange={(e) => setModulelName(e.target.value)}
                  />
                </div>
                <div className="col-12 create_course_form_maindiv">
                  <label
                    htmlFor="course_desc"
                    className="caption color_dark_blue"
                  >
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="body1"
                    placeholder="Enter industry or keyword"
                    value={moduleDesc}
                    onChange={(e) => setModulelDesc(e.target.value)}
                  />
                </div>
                <div className="col-12 create_course_form_maindivBtn">
                  <button
                    className="primaryFilled_activeCta"
                    onClick={createModule}
                  >
                    Save and Next
                  </button>
                </div>
                <div className="col-12 create_course_form_maindivBtn">
                  <button
                    className="primaryFilled_activeCta"
                    onClick={()=>setLearningState(1)}
                  >
                    Save Course
                  </button>
                </div>
              </>
            ) : (
              <button
                className="cta newModuleBtn color_dark_blue"
                onClick={(e) => {
                  e.preventDefault();
                  setModuleOpen(true);
                }}
              >
                <AddBox style={{ marginRight: 7 }} /> Add new Module
              </button>
            )}


          </div>
        </div>
      </>
      {/* ) : (
        <AddNewSession
          module={module}
          courseId={courseId}
          setAddSessionState={setAddSessionState}
        />
      )} */}
    </div>
  );
};

export default CourseCurriculam;
