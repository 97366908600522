import React, { useEffect, useState } from 'react'
import search from "./../../../../Assets/images/icons/search.png";
import bi_cart from "./../../../../Assets/images/icons/bi_cart.png";
import IncubatorCard from './IncubatorCard'
import './asset.css'
// import ViewAsset from "./ViewAsset/ViewAsset"
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { useNavigate } from 'react-router-dom';
import Assets from './Assets'
import { AssetsView } from '../../../../Components/SkeletonLoader/MyAssetsSkeleton';
const IncubatorsAssets = ({setCoWorkingStep}) => {
    const [assetView, setAssetView] = useState(0)
    const [incubators, setIncubators] = useState([])
    const { setSessionExpire, user } = useAuth();
    const [incubatorId,setIncubatorId] = useState('');
    const [isLoading,setIsLoading] = useState(false)
    const navigate = useNavigate()

    const getIncubators = async () => {
        setIsLoading(true)
        await Axios({
            url: `/api/asset/incubators`,
            method: "get",
        }).then((res) => {
            setIncubators(res.data.data)
            setIsLoading(false)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        getIncubators();
    }, [])

    return (
        <>
            {
                assetView === 0 ?
                    <div className='startup_assets_root'>
                        <div className="incubation_prorgram_search_head">
                            <div className="row">
                                <div className="col-lg-9 col-md-8 col-6">
                                    <div className="incubation_programm_searchBoxDiv w_100">
                                        <input type="text" name="" id="" placeholder='Type here to search' />
                                        <button className="p_Bold_secBlue14">
                                            <img src={search} alt="search" />
                                            Search
                                        </button>
                                    </div>

                                </div>
                                <div className="col-lg-3 col-md-4 col-6">
                                    <div className="startup_assets_head_addToCard" onClick={() => {
                                        setCoWorkingStep(6)
                                        navigate(`/dashboard/${user.type}?tab=9&cw=6&back=asset`)
                                    }}>
                                        <p className="body1 mr-8">My Cart</p>
                                        <img src={bi_cart} alt="bi_cart" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="startup_assets_main mt-24">
                            <div className="row">
                                {
                                    isLoading ? <AssetsView/> : incubators.length > 0 ?
                                        incubators.map((data, i) => <IncubatorCard
                                            key={i}
                                            setAssetView={setAssetView}
                                            assetView={assetView}
                                            data={data}
                                            setIncubatorId={setIncubatorId}
                                        />) : null
                                }
                            </div>
                        </div>

                    </div> : assetView === 1 ? <Assets
                        setAssetView={setAssetView}
                        incubatorId={incubatorId}
                        setCoWorkingStep={setCoWorkingStep}
                    /> : null
            }
        </>
    )
}

export default IncubatorsAssets