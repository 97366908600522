import React, { useState, useEffect } from "react";
import Allevents from "./allevents";
// useNavigate,
import { useLocation } from "react-router-dom";
import "./index.css";
import CircularProgress from '@mui/material/CircularProgress';
import Axios from "axios";
import Event from "./event";
// import host_img from "./../../Assets/images/events/host.png";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Index = () => {
  // const navigate = useNavigate();
    
  const query = useQuery();
  var eventId = query.get("event");

  const [eDetails, seteDetails] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [isregistred, setisRegistered] = useState(false);
  const [isExpired, setisExpired] = useState(false);
  const [diff, setDiff] = useState("");
  const fetchEvent = async () => {
    setisLoading(true);
    await Axios({
      url: `/api/events/key/${eventId}`,
      method: "get",
    })
      .then((res) => {
        seteDetails(res.data.event);
        setisRegistered(res.data.isRegistered);
        setisExpired(res.data.isExpired);
        setDiff(res.data.diff);
        setisLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // setSessionExpire(true);
          setisLoading(false);
        }
      });
  };
  useEffect(() => {
    if (eventId) {
      fetchEvent();
      var flag=false;
      if(flag)
      console.log( isregistred, isExpired, diff );
    }
  }, [eventId]);

  return (
    <>
    {eventId ? (
          isLoading ? (
            <div className="col-lg-8 loader_root">
              <CircularProgress />
            </div>
          ) : eDetails ? (
            <>
            <Event eventId={eventId} />
            </>
          ) : (
            <p>Event not found</p>
          )
        ) : <Allevents />
        }
    </>
  );
};

export default Index;
