import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar } from "@mui/material";
// import Checkbox from "@mui/material/Checkbox";

const index = ({ handleClose, meetid }) => {
  const { setSessionExpire, user } = useAuth();
  const [currnote, setCurrNotes] = useState("");
  const [notes, setNotes] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isContain, setIsContain] = useState(false);
  const [newNote, setnewNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const createnotes = async () => {
    console.log(currnote);
    if (currnote.length === 0) return toast.warning("Notes are missing");
    await Axios({
      method: "post",
      url: `/api/incmeet/create/notes/${meetid}`,
      data: { content: currnote },
    })
      .then((res) => {
        if (res.data.success) toast.success("Notes Posted");
        getnotes();
        setCurrNotes("");
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
        handleClose();
      });
  };
  const getnotes = async () => {
    console.log("hhey");
    try {
      setIsLoading(true);

      const response = await Axios.get(`/api/incmeet/notes/${meetid}`);
      console.log(response);
      if (response.data.success) {
        const getnotes = response.data.data;
        console.log(getnotes);
        setNotes(getnotes);
        for (let i in getnotes) {
          if (getnotes[i].author_id._id == user.id) {
            setIsContain(true);
          }
        }
        setIsLoading(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      } else {
        toast.error(err.response.data.error);
      }
      setIsLoading(false);
    }
  };
  const editnoteEnable = (content) => {
    setnewNote(content);
    setIsEdit(true);
  };
  const editnote = async (id) => {
    console.log(currnote);
    if (newNote.length === 0) return toast.warning("Notes are missing");
    await Axios({
      method: "put",
      url: `/api/incmeet/update/notes/${id}`,
      data: { content: newNote },
    })
      .then((res) => {
        if (res.data.success) toast.success("Notes Updated");
        setIsEdit(false);
        getnotes();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
        handleClose();
      });
  };
  useEffect(() => {
    getnotes();
  }, []);
  return (
    <>
      <div className="">
        <svg
          onClick={() => handleClose()}
          style={{ position: "absolute", right: "24px" }}
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 0C16.295 0 21 4.70505 21 10.5C21 16.295 16.295 21 10.5 21C4.70505 21 0 16.295 0 10.5C0 4.70505 4.70505 0 10.5 0Z"
            fill="#0D0D0D"
          />
          <path
            d="M13.641 8.35441L11.5588 10.4371L13.641 12.5198C13.9512 12.83 13.9512 13.3317 13.641 13.6415C13.4864 13.7961 13.2841 13.8739 13.0809 13.8739C12.8781 13.8739 12.6745 13.7966 12.5198 13.6415L10.4367 11.5588L8.35356 13.6415C8.1989 13.7961 7.99656 13.8739 7.79336 13.8739C7.59059 13.8739 7.38739 13.7966 7.23231 13.6415C6.92257 13.3317 6.92257 12.83 7.23231 12.5198L9.31543 10.4371L7.23231 8.35441C6.92257 8.04467 6.92257 7.54204 7.23231 7.2323C7.54205 6.92257 8.04382 6.92257 8.35356 7.2323L10.4367 9.31542L12.5198 7.2323C12.8295 6.92257 13.3313 6.92257 13.641 7.2323C13.9512 7.54204 13.9512 8.04467 13.641 8.35441Z"
            fill="#E2E2E2"
          />
        </svg>
        <div className="w-100 d-flex">
          <h5 className="w-fit" style={{ color: "#262C5B" }}>
            Notes
          </h5>
        </div>
        <br />
        <div style={{ overflowY: "scroll", height: "300px" }} className="w-100">
          {notes.length==0 && isLoading==false && <center><div>No notes Posted</div></center>}
          {isLoading ? (
           <center><CircularProgress/></center> 
          ) : (
            notes.map((v, i) => {
              var isusernote = v.author_id._id == user.id;
              return (
                <div
                  key={i}
                  style={{ marginBottom: "25px" }}
                  className="row no-margin align-items-center p-3  meet-head-box"
                >
                  <div
                    className="w-100 d-flex flex-row justify-content-center"
                    style={{ borderBottom: "1px solid #888" }}
                  >
                    <Avatar
                      src={v.author_id.avatar}
                      className="dashboard_block_item_img"
                      alt="avatar"
                    />

                    <div className="col no-padding d-flex flex-column">
                      <h5 className="p_spartan_20">{v.author_id.name}</h5>
                      <span
                        style={{ fontSize: "10px" }}
                        className="p_spartan_20"
                      >
                        {new Date(v.updatedAt).toLocaleString()}
                      </span>
                    </div>
                    {isContain && isEdit == false && isusernote && (
                      <EditIcon onClick={() => editnoteEnable(v.description)} />
                    )}
                    {isContain && isEdit && isusernote && (
                      <CheckIcon onClick={() => editnote(v._id)} />
                    )}
                    <br />
                    <br />
                  </div>
                  {!isEdit ? (
                    <pre>
                    {v.description}
                   </pre>
                  ) : isusernote ? (
                    <textarea
                      style={{ width: "100%", outline: "none", border: "0px" }}
                      value={newNote}
                      onChange={(e) => {
                        setnewNote(e.target.value);
                      }}
                    />
                  ) : (
                   <pre>
                    {v.description}
                   </pre>
                  )}
                </div>
              );
            })
          )}
        </div>
        {!isLoading && (
          <>
            {!isContain && (
              <div className="d-flex flex-column justify-content-left mt-5">
                <textarea
                  style={{ border: "1px solid black" ,resize: "none"}}
                  className="w-100 "
                  value={currnote}
                  onChange={(e) => setCurrNotes(e.target.value)}
                ></textarea>
                <button
                  onClick={() => createnotes()}
                  className="gradient_CTA width_fit mt-3 color-black"
                  type="button"
                >
                  POST
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default index;
