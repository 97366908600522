import React, { useState, useEffect, useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
// import Axios from "axios";
import PropTypes from "prop-types";
import axios from "axios";
import { useLocation } from "react-router-dom";
const authContext = createContext();
function useProvideAuth() {
    const location = useLocation();
    const [loadCurrent, setLoadCurrent] = useState(false);
    const [role, setRole] = useState("");
    const [user, setUser] = useState(null);
    const [isUser, setIsUser] = useState(false);
    const [profile, setProfile] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [name, setName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [notificationsCount, setNotificationsCount] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [miStep, setMiStep] = useState(0);
    const [reportName, setReportName] = useState("");
    const [sessionExpire,setSessionExpire] = useState(false);
    const [incubatorDetails,setIncubatorDetails] = useState(null)
    const [employeeFeatures,setEmployeeFeatures] = useState(null);
    const [redirectId,setRedirectId] = useState(null);
    const [redirect,setRedirect] = useState(null);
    const [redirectUpdateId,setRedirectUpdateId] = useState(null);
    const [redirectType,setRedirectType] = useState(null);
    const [meetRedirect,setMeetRedirect] = useState({
        status:false,
        link:""
    });
    const [pageredirect,setPageRedirect]=useState({
        status:false,
        link:""
    })

    const signOut = async () => {
        await axios
            .get(`/api/user/logout`)
            .then((res) => {
                if (res.data.success) {
                    if(location.pathname !== '/thankyou'){
                        navigate("/");
                    }
                    setIsUser(false);
                    setUser(null);
                    setProfile(null);
                    setIsVerified(null);
                    setRole(null);
                    setIncubatorDetails(null)
                    setEmployeeFeatures(null)
                    localStorage.clear();
                // cookies.remove('auth_tk');
                }
            })
            .catch(() => {
                navigate("/");
            });
    };
    async function getUser() {
        setIsLoading(true);
        await axios({
            method: "get",
            url: `/api/user/current`,
        })
            .then((res) => {  
                setUser({
                    email: res.data.email,
                    firstname: res.data.firstname,
                    lastname: res.data.lastname,
                    avatar: res.data.avatar,
                    name: res.data.name,
                    id: res.data._id,
                    type: res.data.type,
                    plan: res.data.plan,
                    stripeId: res.data.stripeId,
                    is_account:res.data?.is_account
                });
                setRole(res.data.type);
                setIsUser(true);
                setName(res.data.firstname.toUpperCase());
                if (res.data.profile.length > 0) {
                    setProfile(res.data.profile[0]);
                    if (res.data.profile[0].isVerified) {
                        res.data.profile[0].isVerified;
                        setIsVerified(true);
                    }
                }
                if(res.data?.incubator){
                    setIncubatorDetails(res.data.incubator[0])
                }
                if(res.data?.type === "employee"){
                    const {connections,valuation_tool,mentorship_tool,events_and_network,incubation_tool,co_working,finance,membership} = res.data;
                    setEmployeeFeatures({
                        connections,
                        valuation_tool,
                        mentorship_tool,
                        events_and_network,
                        incubation_tool,
                        co_working,
                        finance,
                        membership
                    })
                }
                if(res.data.redirect?.length > 0){
                    const {event_id,completed,_id,program_id} = res.data.redirect[0];
                    if(event_id === null){
                        setRedirectId(program_id)
                        setRedirectType('program')
                    }else{
                        setRedirectId(event_id)
                        setRedirectType('event')
                    }
                    setRedirect(completed)
                    setRedirectUpdateId(_id)
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsUser(false);
                setUser(null);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        getUser();
    },[role, loadCurrent])
 

    const getNotifications = async () => {
        const response = await axios(`/api/notification/stats`);
        setNotificationsCount(response.data);
    };


    useEffect(() => {
        if (user) {
            getNotifications();
        }
    }, [user]);
    return {
        role,
        setRole,
        isUser,
        user,
        setUser,
        profile,
        setProfile,
        isVerified,
        signOut,
        setName,
        name,
        isLoading,
        notificationsCount,
        setLoadCurrent,
        loadCurrent,
        activeStep,
        setActiveStep,
        miStep,
        setMiStep,
        reportName,
        setReportName,
        getNotifications,
        sessionExpire,
        setSessionExpire,
        getUser,
        incubatorDetails,
        employeeFeatures,
        redirectId,
        redirect,
        redirectUpdateId,
        setRedirect,
        setRedirectId,
        setRedirectUpdateId,
        redirectType,
        meetRedirect,
        setMeetRedirect,
        pageredirect,
        setPageRedirect

    };
}
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
ProvideAuth.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(authContext);
