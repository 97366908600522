import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import { MemberSkeleton } from "../../../../../Components/SkeletonLoader/ProgramDashboard";
import {
  ParticipantsInvite,
  ParticipantsEmailInvite,
} from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";
import { Skeleton } from "@mui/material";
import MentorProfile from "./MentorProfile";
// import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
// import { Link } from "react-router-dom";
import "./index.css";
import MentorInvite from "./mentorinvite";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

const index = ({ programid, setparticipantState }) => {
  const [participants, setparticipants] = useState([]);
  const [activementors, setactivementors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requests, setrequests] = useState([]);
  const { setSessionExpire } = useAuth(); // ,user
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [mentorid, setmentorid] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // getting data
  const fetchallmentors = async () => {
    await Axios({
      url: `/api/sp`,
      // url: `api/incubatorProgram/accepted/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setparticipants(data.resultModels);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const fetchInvites = async () => {
    setIsLoading(true);
    await Axios({
      url: `/api/incsp/applications/incubator/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setrequests(data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false);
        }
      });
  };

  // const cancelRequest = async (id) => {
  //   await Axios({
  //     url: `/api/mentor/delete/request/${id}`,
  //     method: "delete",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       if (data.success) {
  //         toast.success("Request cancelled");
  //         fetchInvites();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response.status === 401) {
  //         setSessionExpire(true);
  //       }
  //       toast.error(err.response.data.error);
  //     });
  // };

  const fetchactivementors = async () => {
    await Axios({
      url: `/api/incsp/activementors/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setactivementors(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchallmentors();
    fetchInvites();
    fetchactivementors();
  }, []);

  // console.log(participants);

  return (
    <>
      {step === 1 ? (
        <>
          <MentorProfile
            setstep={setstep}
            id={participantid}
            fetchInvites={fetchInvites}
            fetchactivementors={fetchactivementors}
            setparticipantState={setparticipantState}
            programid={programid}
            mentorid={mentorid}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row m-0">
                <p className="p_spartan_20 mb-4 mt-2">
                  Active Service Providers
                </p>
                {isLoading ? (
                  <MemberSkeleton />
                ) : activementors.length > 0 ? (
                  <>
                    {activementors &&
                      activementors.length != 0 &&
                      activementors.map((v, i) => {
                        return (
                          <>
                            <div
                              className="col-12 no-padding mt-1 row dashboard_mentor_card"
                              key={i}
                              onClick={() => {
                                setstep(1);
                                setparticipantid(v.profile._id);
                                setmentorid(v.profile.id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="col-12 col-md-2 d-flex align-items-center">
                                <Avatar
                                  src={v.profile.logo}
                                  alt="connection"
                                  sx={{ width: 90, height: 90 }}
                                />
                              </div>
                              <div
                                className="col-12 col-md-5 d-flex flex-column justify-content-center"
                                style={{ borderRight: "1px solid #262C5B" }}
                              >
                                <h5
                                  className="row"
                                  style={{ color: "#262C5B" }}
                                >
                                  {v.profile.companyName &&
                                  v.profile.companyName.length > 12
                                    ? v.profile.companyName.slice(0, 12) + "..."
                                    : v.profile.companyName}
                                </h5>
                                <div className="row">
                                  <p className=" no-margin font12 bold">
                                    Service Provider | &nbsp;
                                  </p>
                                  <p className=" no-margin font12 bold">
                                    {" "}
                                    {v.profile.location.city},{" "}
                                    {v.profile.location.country}
                                  </p>
                                </div>
                              </div>
                              <div className=" col-12 col-md-5">
                                <p className=" font12 p_grey_chip mt-3 mb-3">
                                  {v.profile.industry}
                                </p>

                                <p
                                  className="caption mb-3"
                                  style={{ color: "#303030" }}
                                >
                                  {v.profile.description}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                ) : (
                  <p>
                    <br />
                    <br />
                    No Active ServiceProviders
                  </p>
                )}
                {participants && participants.length === 0 ? <p></p> : null}
              </div>
            </div>

            <div className="col-lg-4 col-sm-4">
              <div className="d-flex justify-content-center ">
                {isLoading ? (
                  <ParticipantsEmailInvite />
                ) : (
                  <button
                    onClick={() => handleOpen()}
                    type="button"
                    className="gradient_CTA"
                  >
                    INVITE SERVICE PROVIDERS BY EMAIL
                  </button>
                )}
              </div>
              <div className="side_box">
                <div
                  className="subtitle"
                  style={{
                    padding: "8px 16px",
                    background: "rgba(226, 232, 240, 0.5)",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  {" "}
                  {isLoading ? (
                    <Skeleton animation="wave" width="60%" height={50} />
                  ) : (
                    "Service Provider Requests"
                  )}
                </div>
                {requests && requests.length > 0 ? (
                  <div className="row">
                    {isLoading ? (
                      <ParticipantsInvite value={4} />
                    ) : (
                      <>
                        {requests.map((v, i) => {
                          return (
                            <>
                              <>
                                <div key={i} className="col-12">
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ padding: "16px 24px" }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        src={v.profile.logo}
                                        sx={{ width: 40, height: 40 }}
                                        className="mr-3"
                                        variant="rounded"
                                      />
                                      <p
                                        className="body2"
                                        style={{ color: "#262C5B" }}
                                      >
                                        {v.profile.companyName}
                                      </p>
                                    </div>
                                    <div
                                      className="overline"
                                      onClick={() => {
                                        setstep(1);
                                        setparticipantid(v.profile._id);
                                        setmentorid(v.profile.id);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "#989898",
                                      }}
                                    >
                                      View Profile
                                    </div>
                                  </div>
                                </div>
                              </>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                ) : (
                  <p
                    className="body2"
                    style={{ padding: "16px 24px", color: "#989898" }}
                  >
                    {isLoading ? "" : "No Service Provider requests"}
                  </p>
                )}
              </div>
              <div className="side_box">
                <div
                  className="subtitle"
                  style={{
                    padding: "8px 16px",
                    background: "rgba(226, 232, 240, 0.5)",
                    borderRadius: "10px 10px 0 0",
                  }}
                >
                  {isLoading ? (
                    <Skeleton animation="wave" width="60%" height={50} />
                  ) : (
                    "Invite Service Provider"
                  )}
                </div>
                {isLoading ? (
                  <ParticipantsInvite value={4} />
                ) : (
                  <>
                    {participants.length > 0 ? (
                      <div className="row">
                        {participants.map((v, i) => {
                          return (
                            <>
                              <>
                                <div key={i} className="col-12">
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ padding: "16px 24px" }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        src={v.logo}
                                        sx={{ width: 40, height: 40 }}
                                        className="mr-3"
                                        variant="rounded"
                                      />
                                      <p
                                        className="body2"
                                        style={{ color: "#262C5B" }}
                                      >
                                        {v.companyName}
                                      </p>
                                    </div>
                                    <div
                                      className="overline"
                                      onClick={() => {
                                        setstep(1);
                                        setparticipantid(v._id);
                                        setmentorid(v.id);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "#989898",
                                      }}
                                    >
                                      View Profile
                                    </div>
                                  </div>
                                </div>
                              </>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <p
                        className="body2"
                        style={{ padding: "16px 24px", color: "#989898" }}
                      >
                        {isLoading
                          ? ""
                          : "Start connecting with Service Provider"}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <MentorInvite handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};

export default index;
