import React, { useState, useEffect } from 'react'
import AllReqCard from './AllReqCard'
import { toast } from 'react-toastify';
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth"

const Allreq = ({setViewEstate}) => {
  const [allReqs, setAllReqs] = useState([]);
  const { setSessionExpire } = useAuth();

  const getRequest = async () => {
    try {
      await Axios({
        method: "get",
        url: `/api/realEstate/estate/incubator/declined/${false}`,
      }).then((res) => {
        setAllReqs(res.data.data)
      }).catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      })
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  }

  useEffect(() => {
    getRequest()
  }, [])

  return <div className="incubator_assessment_root">
    {/* {
      assessmentState === 1 ? */}
    <div className="incubator_assessment_main">
      <div className="incubator_assessment_tableRoot">
        <table className="incubator_assessment_table">
          <thead className="incubator_assessment_thead">
            <tr className="incubator_assessment_thead_tr">
              <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                <p className="body2 color_dark_grey2">Requested by</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                <p className="body2 color_dark_grey2">Manager</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                <p className="body2 color_dark_grey2">Name</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                <p className="body2 color_dark_grey2">Requested on</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_score">
                <p className="body2 color_dark_grey2">Status</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_view text-end pr-24">
                <p className="body2 color_dark_grey2"></p>
              </td>
            </tr>
          </thead>
          <tbody className="incubator_assessment_tbody">
            {
              allReqs && allReqs.length > 0 ?
                allReqs.map((data, i) => <AllReqCard
                  key={i}
                  data={data}
                  setViewEstate={setViewEstate}
                  setAllReqs={setAllReqs}
                />) : null
            }
          </tbody>
        </table>
      </div>
    </div>
   
  </div>
}

export default Allreq