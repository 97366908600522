import React, { useEffect, useState } from 'react'
import { KeyboardBackspace } from "@mui/icons-material"
import Axios from 'axios';
import { useAuth } from "../../../../../Utils/Auth";
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Assessmentresult = ({ setAssessmentState }) => {

  const [results, setResult] = useState([]);
  const [details, setDetails] = useState([]);
  const [data, setData] = useState(null)
  const { setSessionExpire } = useAuth();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const navigate = useNavigate()
  const { programid } = useParams()

  // var courseId = query.get("course");
  var assessment_id = query.get("assessment");
  var user_id = query.get("user");


  const getResult = async () => {
    await Axios({
      url: `/api/mentorModules/assessment/incubator/${assessment_id}/${user_id}`,
      method: "get",
    }).then((res) => {
      setResult(res.data.data.results);
      setData(res.data.data);
      // setAssessmentMain(res.data.data);
      // setModules(res.data.mod);
    }).catch((err) => {
      if (err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  const getDetails = async () => {
    await Axios({
      url: `/api/startup/${user_id}`,
      method: "get",
    }).then((res) => {
      setDetails(res.data.startup);
      // setAssessmentMain(res.data.data);
      // setModules(res.data.mod);
    }).catch(() => {
      // if (err.response.status === 401) {
      setSessionExpire(true);
      // }
    });
  }

  useEffect(() => {
    getResult()
    getDetails()
  }, [])

  return <>
    <div className="Assessmentresult_root">
      <div className="Assessmentresult_main_head">
        <p className="cta color_light_grey point" onClick={() => {
          setAssessmentState(1)
          navigate(`/service/incprogram/${programid}?tab=6`)
        }}><KeyboardBackspace /> Back</p>
      </div>
      <div className="Assessmentresult_main mt-24">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12 Assessmentresult_leftmain">
            <div className="Assessmentresult_left">
              <div className="Assessmentresult_left_head">
                {
                  data !== null ? 
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12 Assessmentresult_div_main p-2">
                      <div className="Assessmentresult_div onboarding_cards">
                        <p className="subtitle">Correct</p>
                        <h4 style={{ color: "#580167" }}>{data.correct}/{data.total}</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 Assessmentresult_div_main p-2">
                      <div className="Assessmentresult_div onboarding_cards">
                        <p className="subtitle">Incorrect</p>
                        <h4 style={{ color: "#FF6F1E" }}>{data.total - data.correct}/{data.total}</h4>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12 Assessmentresult_div_main p-2">
                      <div className="Assessmentresult_div onboarding_cards">
                        <p className="subtitle">total</p>
                        <h4 style={{ color: "#121843" }}>{data.total}</h4>
                      </div>
                    </div>
                  </div> : null
                }
              </div>
              <div className="Assessmentresult_left_QBody_root onboarding_cards mt-24">
                {
                  results && results.length > 0 ?
                    results.map((result, i) => <div className="Assessmentresult_left_Ques" key={i}>
                      <div className="Assessmentresult_left_Question">
                        <p className="caption color_dark_black mb-8">Question {i + 1}</p>
                        <p className="body1 color_dark_grey">{result.question}</p>
                      </div>
                      <div className="Assessmentresult_leftR_correct">
                        {
                          result.is_correct ?
                            <>
                              <p className="caption color_dark_black mb-8">Correct Response</p>
                              <p className="body1">1/1</p>
                            </> : <>
                              <p className="caption color_dark_black mb-8">Incorrect Response</p>
                              <p className="body1">0/1</p>
                            </>
                        }

                      </div>
                    </div>) : null
                }

              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12 Assessmentresult_rightmain p-2">
            {
              details && details.length > 0 ?
                <div className="Assessmentresult_right onboarding_cards">
                  <div className="Assessmentresult_right_head">
                    <img src={details[0].profilePic} alt="startup" />
                    <div className="Assessmentresult_right_head_nam">
                      <p className="subtitle color_dark_blue">{details[0].founder.firstname} {details[0].founder.lastname}</p>
                      <p className="caption color_dark_grey2">Submitted on 20 March 22</p>
                    </div>
                  </div>
                  <div className="Assessmentresult_right_body">
                    <table className="Assessmentresult_right_table w_100">
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Industry</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{details[0].industry}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Location</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{details[0].location.country}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Audience</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{details[0].audience}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Employees</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{details[0].employees}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Funding Status</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{details[0].fundingSeries}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Growth</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{details[0].growth}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Incubation Date</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">{new Date(details[0].incorporationDate).toLocaleDateString()}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Website</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2" style={{ wordBreak: "break-all" }}>{details[0].website}</p>
                        </td>
                      </tr>
                      <tr className="Assessmentresult_right_tr">
                        <th className="Assessmentresult_right_th">
                          <p className="body2">Contact</p>
                        </th>
                        <td className="Assessmentresult_right_td">
                          <p className="body2 color_dark_grey2">
                            <a href={details[0].socialConnects.linkedin} target="_blank" rel="noopener noreferrer">Linkdedin</a>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div> : null

            }
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Assessmentresult