import React from 'react'

const HeaderComp = ({heading, subHead, money}) => {
    return <div className="col-lg-4 col-md-4 col-12">
        <div className="incubator_insight_head_money_root">
            <div className="incubator_insight_head_money_main p-24 d-flex align-items-center justify-content-between">
                <div className='incubator_insight_head_money_left'>
                    <p className="subtitle">{heading}</p>
                    <div className='incubator_insight_head_bd'>
                        <p className="overLine color_light_grey">{subHead}</p>
                    </div>
                </div>
                <div className='incubator_insight_head_money_right'>
                    <p><span className='incubator_insight_rupee'>€</span><span className='subtitle'>{money}</span></p>
                </div>
            </div>
            <div className="incubator_insight_head_money_foot">
                <p className="body2" style={{ color: "#370041" }}>Request Payment</p>
                <p className="body2 color_dark_blue">View Transaction</p>
            </div>
        </div>
    </div>
}

export default HeaderComp