import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
import Avatar from "@mui/material/Avatar";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Vcmodal from "./Vcmodal";
import { useParams } from "react-router-dom";
import Loader from "../../../../Components/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Applicantcard = ({
  setstep,
  startupid,
  requestid,
  callbackfunc,
  title,
}) => {
  const [startup, setstartup] = useState(null);
  const [request, setrequest] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isexpired, setisexpired] = useState(null);
  const [diff, setdiff] = useState(null);
  const { setSessionExpire, user } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let { programid } = useParams();

  const getDetail = async () => {
    await Axios({
      method: "get",
      url: `/api/startup/user-by-id/${startupid}`,
    })
      .then((res) => {
        setstartup(res.data.data);
        console.log(title);
        console.log(res.data.data);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getrequest = async () => {
    await Axios({
      method: "get",
      url: `/api/incubatorProgram/request/${requestid}`,
    })
      .then((res) => {
        setrequest(res.data.data);
        setdiff(res.data.diff);
        setisexpired(res.data.isExpired);
        setDocuments(res.data.data.documents);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const shortlist = async (term) => {
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/shortlist/${requestid}/${term}`,
      data: {
        title: title,
        user_email: startup.contact.email,
        incubator_name: user.name,
        user_name: startup.companyName,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (term) toast.success("Applicant shortlisted");
          else toast.success("Removed from shortlist");
          callbackfunc();
          getrequest();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };
  const handledecline = async (term) => {
    let data;
    if (!term) {
      data = {
        isDeclined: term,
        programid,
        title: title,
        user_email: startup.contact.email,
        incubator_name: user.name,
        user_name: startup.companyName,
      };
    } else {
      data = {
        isDeclined: term,
        is_accepted: !term,
        is_short_listed: !term,
        programid,
        title: title,
        user_email: startup.contact.email,
        incubator_name: user.name,
        user_name: startup.companyName,
      };
    }
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/update/request/${requestid}`,
      data,
    })
      .then((res) => {
        if (res.data.success) {
          if (term) toast.success("Applicant declined");
          else toast.success("Removed from declined");
        }
        callbackfunc();
        getrequest();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };
  const handleaccept = async (term) => {
    let data;
    if (term) {
      data = {
        is_accepted: term,
        isDeclined: !term,
        is_short_listed: !term,
        programid,
        title: title,
        user_email: startup.contact.email,
        incubator_name: user.name,
        user_name: startup.companyName,
      };
    } else {
      data = {
        is_accepted: term,
        is_removed: !term,
        programid,
        title: title,
        user_email: startup.contact.email,
        incubator_name: user.name,
        user_name: startup.companyName,
      };
    }
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/update/request/${requestid}`,
      data,
    })
      .then((res) => {
        if (res.data.success) {
          if (term) toast.success("Applicant accepted");
          else toast.success("Removed");
        }
        callbackfunc();
        getrequest();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };
  const cancelvcrequest = async (term) => {
    await Axios({
      method: "put",
      url: `/api/incubatorProgram/vcrequest/${requestid}`,
      data: {
        vc_requested: term,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Interview cancelled");
        }
        getrequest();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
      });
  };

  const handleStart = (key, title, name) => {
    window.open(
      `/jitsi-meet/${key}?title=${title}&host=${name}&programid=${programid}&d=incprogram`
    );
  };

  useEffect(() => {
    getDetail();
    getrequest();
  }, []);

  if (startup === null || request === null)
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "80vh" }}
      >
        <Loader />
      </div>
    );

  return (
    <>
      <div className="col-12 mb-5">
        <span
          className="back"
          onClick={() => {
            setstep(0);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left mx-1"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Back
        </span>
        <span className="backhead">{title}</span>
      </div>
      <div className="col-12 row">
        <div className="col-12 col-lg-8">
          <div className="connection_profile_root" id="connection_profile_root">
            <div className="row mt-2 justify-content-center connection_profile_head dashboard01_connection_profile_head">
              {/* <div className="row no-margin align-items-center"> */}
              <div className="width_auto no-padding">
                <Avatar
                  src={startup.logo}
                  className=""
                  alt="Profile"
                  sx={{ width: 90, height: 90 }}
                />
              </div>
              <div className="col-10 d-flex flex-column justify-content-center">
                <p
                  className="p_spartan_20 subtitle"
                  style={{ color: "#262C5B" }}
                >
                  {startup.companyName}
                </p>
                <p className="bold">Startup | {startup.location.country}</p>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div> */}
            </div>
            <div className="mt-4 mx-2">
              <p className="grey bold">{startup.description}</p>
            </div>

            <div className="row mx-2 my-3">
              <p className="p_grey_chip">{startup.industry} </p>
            </div>

            {documents.length > 0 ? (
              <>
                <hr />
                <div className="">
                  <p className="title">Documents</p>
                  <div className="d-flex align-items-center mt-16">
                    {documents.map((doc, i) => (
                      <p className="incubation_chips caption" key={i}>
                        <a
                          href={doc.doc_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {doc.doc_name}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            className="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.636 3.5a.5.5 0 00-.5-.5H1.5A1.5 1.5 0 000 4.5v10A1.5 1.5 0 001.5 16h10a1.5 1.5 0 001.5-1.5V7.864a.5.5 0 00-1 0V14.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h6.636a.5.5 0 00.5-.5z"
                            ></path>
                            <path
                              fillRule="evenodd"
                              d="M16 .5a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h3.793L6.146 9.146a.5.5 0 10.708.708L15 1.707V5.5a.5.5 0 001 0v-5z"
                            ></path>
                          </svg>
                        </a>
                      </p>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
            <hr />
            <div className="mb-4">
              {startup.id ? (
                <>
                  <p className="p_spartan_20 mx-2 my-4 founder-title">
                    Founder Details
                  </p>
                  <div className="row mx-0 my-4 align-items-center col-8">
                    <div className="width_auto no-padding">
                      {/* <Avatar
                        src={startup.profilePic}
                        className=""
                        alt="Profile"
                        sx={{ width: 90, height: 90 }}
                      /> */}
                      <img
                        src={startup.profilePic}
                        style={{
                          borderRadius: "50%",
                          width: "90px",
                          height: "90px",
                        }}
                        alt="Profile"
                      />
                    </div>
                    <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                      <p className="founder-title" style={{ fontSize: "16px" }}>
                        {startup.id.firstname} {startup.id.lastname}
                      </p>
                      <p className="founder-job">{startup.contact.jobTitle} </p>
                      <p className="bold">
                        <a href={`${startup.socialConnects.linkedin}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-linkedin"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                          </svg>
                        </a>
                      </p>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="row justify-content-end">
              <>
                {request !== null && request.isDeclined ? (
                  <>
                    <button
                      onClick={() => handledecline(false)}
                      className="color-black blueout_btn width_fit mr-3"
                    >
                      REMOVE FROM DECLINED
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => handledecline(true)}
                    className="color-black blueout_btn width_fit mr-3"
                  >
                    DECLINE
                  </button>
                )}
              </>

              <>
                {request !== null && request.is_short_listed ? (
                  <>
                    {/* <button
                      onClick={() => shortlist(false)}
                      className="twf_gradoutlinedbtn color-black width_fit mr-3"
                    >
                      REMOVE FROM SHORTLIST
                    </button> */}
                  </>
                ) : (
                  <div
                    onClick={() => shortlist(true)}
                    className="gradient_btn_main width_fit mr-3"
                    style={{ marginTop: "10px" }}
                  >
                    <div
                      className="gradient_btn_light meet-btn"
                      style={{ color: "#000000" }}
                    >
                      SHORTLIST
                    </div>
                  </div>
                )}
              </>
              {request.is_short_listed ? (
                <>
                  {request !== null && !request.vc_requested ? (
                    <>
                      <div
                        onClick={() => handleOpen()}
                        className="gradient_btn_main width_fit mr-3"
                        style={{ marginTop: "10px" }}
                      >
                        <div
                          className="gradient_btn_light meet-btn"
                          style={{ color: "#000000" }}
                        >
                          CALL FOR INTERVIEW
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              <>
                {request !== null && request.is_accepted ? (
                  <>
                    <button
                      onClick={() => handleaccept(false)}
                      className="gradient_CTA width_fit mr-3"
                    >
                      REMOVE
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => handleaccept(true)}
                    className="gradient_CTA width_fit mr-3"
                  >
                    ACCEPT
                  </button>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="connection_profile_root_highligts">
            <div className="connection_profile_root_highligts_head">
              <p className="p_spartan_20">Highlights</p>
            </div>
            <div className="connection_profile_root_highligts_desc">
              <table>
                {startup.valuation && (
                  <tr>
                    <td className="td_left">Valuation</td>
                    <td className="td_right">$ {startup.valuation}</td>
                  </tr>
                )}
                <tr>
                  <td className="td_left">Audience</td>
                  <td className="td_right">{startup.audience}</td>
                </tr>

                <tr>
                  <td className="td_left">Employees</td>
                  <td className="td_right">{startup.employees}</td>
                </tr>
                <tr>
                  <td className="td_left">Funding Series</td>
                  <td className="td_right">{startup.fundingSeries}</td>
                </tr>
                <tr>
                  <td className="td_left">Incorporation</td>
                  <td className="td_right">
                    {new Date(startup.incorporationDate).getFullYear()}
                  </td>
                </tr>

                <tr>
                  <td className="td_left">Website</td>
                  <td className="td_right" style={{ wordBreak: "break-all" }}>
                    <a
                      href={`${startup.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {startup.website}
                    </a>
                  </td>
                </tr>

                {request.documents
                  ? request.documents.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="td_left">{v.doc_name}</td>
                          <td
                            className="td_right"
                            style={{ wordBreak: "break-all" }}
                          >
                            <a
                              href={`${v.doc_link}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Document
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </table>
            </div>
          </div>
          {request.is_short_listed ? (
            <>
              {isexpired ? (
                <>
                  <div className="connection_profile_root_highligts meeting-box mt-24">
                    <div className="gradient_btn_main width_fit mx-auto">
                      <div
                        className="gradient_btn_light meet-btn"
                        style={{ color: "#989898" }}
                      >
                        Expired
                      </div>
                    </div>
                    <div className="mt-3">
                      <div
                        onClick={() => handleOpen()}
                        className="body2"
                        style={{ color: "#989898" }}
                      >
                        RESCHEDULE
                      </div>
                    </div>
                  </div>
                </>
              ) : request.vc_requested ? (
                <>
                  <div className="connection_profile_root_highligts meeting-box mt-24">
                    <div className="body1" style={{ color: "#262C5B" }}>
                      Interview Schedule
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <p className="meeting-box-greybox">
                        Interview Date: {request.vc_date}
                      </p>
                      <p
                        className="meeting-box-greybox mt-8"
                        style={{ marginBottom: "45px" }}
                      >
                        Interview Time: {request.vc_time}
                      </p>
                      {diff === "Live" ? (
                        <div
                          className="gradient_btn_main width_fit mx-auto"
                          onClick={() =>
                            handleStart(request.vc_id, "Interview", user.name)
                          }
                        >
                          <div
                            className="gradient_btn_light meet-btn"
                            style={{ color: "#303030" }}
                          >
                            START CALL
                          </div>
                        </div>
                      ) : diff === "Expired" ? (
                        <div
                          className="gradient_btn_main width_fit mx-auto"
                          disabled
                        >
                          <div
                            className="gradient_btn_light meet-btn"
                            style={{ color: "#989898" }}
                          >
                            EXPIRED
                          </div>
                        </div>
                      ) : (
                        <div
                          className="gradient_btn_main width_fit mx-auto"
                          disabled
                        >
                          <div
                            className="gradient_btn_light meet-btn"
                            style={{ color: "#989898" }}
                          >
                            Starts in {diff}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <div
                        onClick={() => handleOpen()}
                        className="body2"
                        style={{ color: "#989898" }}
                      >
                        RESCHEDULE
                      </div>
                      {diff !== "Expired" ? (
                        <div
                          onClick={() => cancelvcrequest(false)}
                          className="body2 mt-2"
                          style={{ color: "#989898" }}
                        >
                          CANCEL INTERVIEW
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={style}>
          <Vcmodal
            requestid={requestid}
            callbackfunc={getrequest}
            setOpen={setOpen}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Applicantcard;
