import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import MentorRoom from "./MentorRoom";
import { useNavigate } from "react-router-dom";

const index = () => {
  const navigate = useNavigate();
  const { setSessionExpire, user } = useAuth();
  const [mentorships, setmentorships] = useState([]);
  const [step, setstep] = useState(0);
  const fetchActivementorship = () => {
    Axios({
      url: "/api/mentor/received",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setmentorships(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchActivementorship();
  }, []);
  return (
    <>
      {step === 1 ? (
        <MentorRoom setstep={setstep} />
      ) : (
        <>
          <h4 style={{ fontWeight: "bold" }}>Active Mentorships</h4>
          {mentorships.length > 0 ? (
            mentorships.map((v, i) => {
              return (
                <>
                  {v.is_accepted ? (
                    <>
                      <div key={i}>
                        <p>{v.sender.name}</p>
                        <button
                          type="button"
                          onClick={() => {
                            setstep(1);
                            navigate(
                              `/dashboard/${user.type}?tab=20&id=${v.connect_id}`
                            );
                          }}
                        >
                          Explore
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              );
            })
          ) : (
            <p>No Mentorships</p>
          )}
        </>
      )}
    </>
  );
};

export default index;
