import React from 'react';
import bg1 from "./../Assets/images/Home/back1.png"
import bg2 from "./../Assets/images/Home/back2.png"
import bg3 from "./../Assets/images/Home/back3.png"

const backgroundImg = () => {
    return (
        <>
            <div className="home-footer-bgRoot">
                <img id='home-footer-bgRoot1' src={bg1} alt="Bg1" />
            </div>
            <div className="home-footer-bgRoot">
                <img id='home-footer-bgRoot2' src={bg2} alt="BG1" />
            </div>
            <div className="home-footer-bgRoot">
                <img id='home-footer-bgRoot3' src={bg3} alt="BG1" />
            </div>
        </>
    )
}

export default backgroundImg
