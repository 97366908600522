import React, { useState, useEffect } from 'react';
import chart from '../../../Assets/images/lead-generation.svg';
import './target.css';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import Axios from "axios";
import { toast } from 'react-toastify';
import { useAuth } from '../../../Utils/Auth'
import { format, parseISO } from 'date-fns'
import { useNavigate } from 'react-router-dom';
import NumberFormat from "react-number-format";
import {LeadTargetSkeleton} from '../../../Components/SkeletonLoader/LeadSkeleton'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 'none'
};
const LeadTarget = ({ setGenerationStep,tab }) => {
  const navigate = useNavigate();
  const [isEdit,setisEdit]=useState(false)
  const { user } = useAuth()
  const [modalopen, setModalOpen] = useState(false);
  const handleOpen = () => {

    setModalOpen(true)
  };
  const handleOpenEdit = (data) => {
  
    setisEdit(true)
    settoeditID(data._id)
    setTargetName(data.name)
    setStartDate(data.startDate)
    setEndDate(data.endDate)
    setAmount(data.amount)
    setModalOpen(true)
  };

  const handleClose = () =>{ 
   
    setisEdit(false)
    setTargetName('')
    setStartDate('')
    setEndDate('')
    setAmount('')
    
    setModalOpen(false)};

  const [targetName, setTargetName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [toeditID, settoeditID] = useState('');
  const [amount, setAmount] = useState(null);
 
  const [targets, setTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [formatedValue, setFormatedValue] = useState(amount);
  const fetchTargets = async () => {
    setIsLoading(true)
    const { data } = await Axios({
      method: "get",
      url: `/api/leadGeneration/leadTargets`,
    })
 
    if (data.success) {
      setTargets(data.data)
      setIsLoading(false)
    
    } else {
      setIsLoading(false)
      toast.error('Error while fetching targets')
    }

  }

  function handleRuppesConvert(e) {
    setFormatedValue(e.target.value);
    let s = e.target.value;
    if (s) {
         var n = s.split("€", 2)[1];
         setAmount(Number(n.split(",").join("")));
    }
}

  
  const handleTargetCreate = async () => {
    if (targetName.length <= 0 || startDate.length <= 0 || endDate.length <= 0 || amount <= 0) return toast.warning('Some fields are missings')

    const { data } = await Axios({
      method: "post",
      url: `/api/leadGeneration/create/leadTarget`,
      data: {
        name: targetName,
        startDate: startDate,
        endDate: endDate,
        amount: amount,
        user_id: user.id
      }
    })
    if (data.success) {
      handleClose();
      toast.success('Target created');
      setTargetName('')
      setStartDate('')
      setEndDate('')
      setAmount('')
      fetchTargets()
    } else {
      toast.success('Something went wrong');
    }
  }
  const handleTargetEdit = async () => {

   
   if (targetName.length <= 0 || startDate.length <= 0 || endDate.length <= 0 || amount <= 0) return toast.warning('Some fields are missings')

    const { data } = await Axios({
      method: "put",
      url: `/api/leadGeneration/update/leadTarget`,
      data: {
        name: targetName,
        startDate: startDate,
        endDate: endDate,
        amount: amount,
        _id: toeditID
      }
    })
    if (data.success) {
      handleClose();
      toast.success('Target Updated');
      setTargetName('')
      setStartDate('')
      setEndDate('')
      setAmount('')
      fetchTargets()
    } else {
      toast.success('Something went wrong');
    }

  }
  const handleTargetDelete = async (id) => {


     const { data } = await Axios({
       method: "delete",
       url: `/api/leadGeneration/delete/leadTarget/${id}`,
     })
     if (data.success) {
       handleClose();
       toast.success('Target Deleted');
       setTargetName('')
       setStartDate('')
       setEndDate('')
       setAmount('')
       fetchTargets()
     } else {
       toast.success('Something went wrong');
     }
 
   }
  const handleOpenLead = (targetId) => {
    setGenerationStep(1)
    navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId} `)
  }
  useEffect(() => {
    fetchTargets();
  }, [])

  return <div className="row">
    <div className="col-lg-3 col-md-6 col-12 point">
      <div className="target_card_create" onClick={handleOpen}>
        <img src={chart} alt="host_img" />
        <p className='py-3'>Create A Target</p>
      </div>
    </div>
    {
      isLoading ? <LeadTargetSkeleton/> : targets.map(target =>
       
        <div className='col-lg-3 col-md-6 col-12 point' key={target._id} >
          <div className="target_card p-3">
            <div className='d-flex justify-content-around'>

            <div className='d-flex align-items-center'>
              <img src={chart} alt="host_img" width="15%" height="15%" />
              <h5 className='py-3 p_black20 mx-2'>{target.name}</h5>
            </div>
            <div className='mr-3' onClick={()=>handleOpenEdit(target)}><svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 12 12"
              className="me-2"
            >
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.027 2.738l1.236 1.234-1.236-1.234zm.795-1.088L5.48 4.99c-.173.173-.29.392-.338.632l-.309 1.544 1.545-.309c.239-.048.458-.165.63-.338l3.342-3.34a1.08 1.08 0 10-1.53-1.53v0z"
              ></path>
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.084 7.75V9.5a1.167 1.167 0 01-1.167 1.166H2.501a1.167 1.167 0 01-1.167-1.167V3.083a1.167 1.167 0 011.167-1.167h1.75"
              ></path>
            </svg></div>
            <div onClick={()=>handleTargetDelete(target._id)}><svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 12 12"
              className="me-2"
            >
              <path
                fill="#000"
                d="M7.05 1.8a1.05 1.05 0 00-2.1 0h-.7a1.75 1.75 0 113.5 0h3.15a.35.35 0 110 .7h-.388l-.907 7.86a1.4 1.4 0 01-1.39 1.24h-4.43a1.4 1.4 0 01-1.39-1.24L1.487 2.5H1.1a.35.35 0 01-.344-.287L.75 2.15a.35.35 0 01.35-.35h5.95zm2.757.7H2.193l.897 7.78a.7.7 0 00.696.62h4.428a.7.7 0 00.695-.62l.898-7.78zM4.95 4.25c.171 0 .315.108.344.251l.006.055v4.288c0 .169-.157.306-.35.306-.171 0-.315-.109-.344-.252L4.6 8.844V4.557c0-.17.157-.307.35-.307zm2.1 0c.171 0 .315.108.344.251l.006.055v4.288c0 .169-.157.306-.35.306-.171 0-.315-.109-.344-.252L6.7 8.844V4.557c0-.17.157-.307.35-.307z"
              ></path>
            </svg></div>
            </div>
          
            <div onClick={() => handleOpenLead(target._id)}>
            <div className='d-flex align-items-center my-2'>
              <p>Duration : </p>
              <p className='mx-2'>{target.duration}</p>
            </div>
            <div className='d-flex align-items-center justify-content-between my-4'>
              <div>
                <p>Start Date</p>
                <p>{format(parseISO(target.startDate), "dd-MM-yyyy")}</p>
              </div>
              <div>
                <p>End Date</p>
                <p>{format(parseISO(target.endDate), "dd-MM-yyyy")}</p>
              </div>

            </div>
            </div>
          </div>
          
        </div>
        
 
      )
    }
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalopen}
      onClose={handleClose}
      closeAfterTransition
    >

      <Box sx={style}>
        <div className="close-modal-main">
          <button onClick={handleClose}>
            <CancelIcon />
          </button>
        </div>
        <div className="modal-main-text">
          <div className="incubator_create_program_form_root mt-3">
            <form className="incubator_create_program_form">
              <div className="register_page_form row">
                <div className="col-12">
                  <label className="auth_label p_secNaviBlue12 mt-0">
                    Target Name
                  </label>
                  <input
                    placeholder="Enter the target name"
                    type="text"
                    className="auth_input mt-0"
                    value={targetName}
                    onChange={(e) => setTargetName(e.target.value)}
                  />
                </div>
                <div className="col-12 col-md-6 col-12 my-2">
                  <label className="auth_label">Start Date</label>
                  <input
                    type="text"
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="w_100 auth_input"
                    placeholder="Start date"
                    // value={programStartDate}
                    defaultValue={startDate!=''?format(parseISO(startDate), "dd-MM-yyyy"):""}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className="col-12 col-md-6 col-12 my-2">
                  <label className="auth_label">End Date</label>
                  <input
                    type="text"
                    onFocus={(e) => {
                      e.target.type = "date";
                    }}
                    className="w_100 auth_input"
                    placeholder="End end"
                    // value={programStartDate}
                    defaultValue={endDate!=''?format(parseISO(endDate), "dd-MM-yyyy"):""}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div className="col-12 my-2">
                  <label className="auth_label p_secNaviBlue12 mt-0">
                    Target Amount *
                  </label>
                  <NumberFormat
                    placeholder="€ 100,000"
                    displayType={"number"}
                    thousandSeparator={true}
                    prefix={"€ "}
                    className="some auth_input"
                    inputmode="numeric"
                    value={formatedValue}
                    onChange={(e) => handleRuppesConvert(e)}
                  />
                </div>
              </div>
              {!isEdit && <button
                type="button"
                className="primaryFilled_activeCta float-right my-3"
                style={{ padding: "10px 20px", margin: ".5rem" }}
                onClick={handleTargetCreate}
              >
              Create
              </button>}
              {isEdit && <button
                type="button"
                className="primaryFilled_activeCta float-right my-3"
                style={{ padding: "10px 20px", margin: ".5rem" }}
                onClick={handleTargetEdit}
              >
                Edit
              </button>}
            </form>
          </div>
        </div>
      </Box>

    </Modal>
  </div>
}

export default LeadTarget;