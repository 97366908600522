import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Annoucement from "./Announcement";
import Chats from "../../IncubatorProgram/Chats";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ borderBottom: 1, borderColor: "divider" }}
      className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Announcements" value="1" />
            <Tab label="Chats" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Annoucement />
        </TabPanel>
        <TabPanel value="2">
          <Chats />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
