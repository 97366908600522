
import React from 'react'
import Skeleton from '@mui/material/Skeleton';
export const LeadTargetSkeleton = () => {

     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 7; index++) {
               list.push(
                    <div className="col-lg-3 col-md-6 col-12">
                         <div className='target_card p-3'>
                              <div className='d-flex align-items-center'>
                                   <Skeleton width={140} height={50}/>
                              </div>

                              <div className='d-flex align-items-center my-2'>
                              <Skeleton width={100} height={30}/>
                              </div>
                              <div className='d-flex align-items-center justify-content-between my-4'>
                                   <div>
                                   <Skeleton width={100} height={50}/>
                                   </div>
                                   <div>
                                   <Skeleton width={100} height={50}/>
                                   </div>
                                   
                              </div>
                         </div>

                    </div>
               )
          }
          return list
     }
     return (
          <>
               {renderSkeleton()}
          </>
     )
}