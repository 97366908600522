import React, { useState, useEffect } from 'react'
import './member.css'
import { Corporate, Employee, Individual, Investor, Mentor, PreStartup, ServiceProvider, Startup, Student } from './MemberDetails'
import { List,ListItemButton,ListSubheader,ListItemText,Collapse } from '@mui/material';
import search from "./../../../Assets/images/icons/search.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import InviteModal from './InviteModal';
import { useAuth } from '../../../Utils/Auth';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import MemberCard from './MemberCard';
import { useNavigate } from 'react-router-dom';
import { Members } from '../../../Components/SkeletonLoader/MembershipSkeleton';
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    pt: 2,
    px: 4,
    pb: 3,
    padding: "40px",
};

const Membership = () => {
    const [open, setOpen] = useState(false);
    const { user } = useAuth();
    const [openList, setOpenList] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [memebers, setMembers] = useState([])
    const [list, setList] = useState('')
    const navigate = useNavigate()
    
    const handleListOpen = (list) => {
        setList(list)
        setOpenList((prevList) => ({ ...!prevList, [list]: !prevList[list] }));
        
    };
    const fetchMembers = async (list) => {
        setIsLoading(true)
        await axios.get(`/api/membership/member/${list}`).then((res) => {
            setIsLoading(false)
            setMembers(res.data.data);
            // console.log(res.data.data)
            navigate(`/dashboard/${user.type}?tab=7&mm=1&${list}=${list==="serviceprovider" || list=== "employee"?res.data.data[0].user_id._id:res.data.data[0].userProfile._id}`)
        }).catch(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        if (list.length > 0) {
            fetchMembers(list)
        }

    }, [list])
    useEffect(()=>{
        handleListOpen('startup')
    },[])
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return <div className="incubator_analytics_root">
        <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-4 col-6">
                <div className="incubator_analytics_courses_root">
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Members
                            </ListSubheader>
                        }
                    >
                        <ListItemButton onClick={() => handleListOpen('startup')}>
                            <ListItemText primary="Startup" />
                            {openList['startup'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['startup']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list} />)
                            }

                        </Collapse>

                        <ListItemButton onClick={() => handleListOpen('serviceprovider')}>
                            <ListItemText primary="Service providers" />
                            {openList['serviceprovider'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['serviceprovider']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list}/>)
                            }
                        </Collapse>

                        <ListItemButton onClick={() => handleListOpen('mentor')}>
                            <ListItemText primary="Mentors" />
                            {openList['mentor'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['mentor']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list} />)
                            }
                        </Collapse>

                        <ListItemButton onClick={() => handleListOpen('investor')}>
                            <ListItemText primary="Investors" />
                            {openList['investor'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['investor']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list} />)
                            }
                        </Collapse>

                        <ListItemButton onClick={() => handleListOpen('corporate')}>
                            <ListItemText primary="Corporates" />
                            {openList['corporate'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['corporate']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list}/>)
                            }
                        </Collapse>

                        <ListItemButton onClick={() => handleListOpen('individual')}>
                            <ListItemText primary="Individuals" />
                            {openList['individual'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['individual']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list}/>)
                            }
                        </Collapse>

                        <ListItemButton onClick={() => handleListOpen('employee')}>
                            <ListItemText primary="Employees" />
                            {openList['employee'] ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openList['employee']} timeout="auto" unmountOnExit>
                            {
                                isLoading ? <Members value={3}/> : memebers?.map((member) => <MemberCard key={member._id} member={member} list={list}/>)
                            }
                        </Collapse>
                    </List>
                    {/* <div className="incubator_analytics_courses_head">
                        <p className="caption">Members</p>
                    </div>
                    <div className="incubator_analytics_courses_main">
                        <div className="incubator_analytics_courses mt-24">
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 0) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(0)
                                    }}>Startups</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 1) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(1)
                                    }}>Pre-Startups</p>

                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 2) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(2)
                                    }}>Investors</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 3) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(3)
                                    }}>Mentors</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 4) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(4)
                                    }}>Corporates</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 5) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(5)
                                    }}>Students</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 6) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(6)
                                    }}>Service Providers</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 7) ? 'showAnalyticsBg' : ''}`}>
                                <div className="member_border">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(7)
                                    }}>Employees</p>
                                </div>
                            </div>
                            <div className={`incubator_analytics_coursename mb-8 point ${(memberStep === 8) ? 'showAnalyticsBg' : ''}`}>
                                <div className="">
                                    <p className="subtitle color_dark_blue pt-8 pb-8" onClick={() => {
                                        setMemberStep(8)
                                    }}>Individuals</p>
                                </div>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-8 col-6">
                <div className="incubator_membership_main mb-24">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-10">
                            <div className="incubation_programm_searchBoxDiv">
                                <input type="text" name="" id="" />
                                <button className="p_Bold_secBlue14">
                                    <img src={search} alt="search" />
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-2">
                                <div className="incubator_member_invite_main">
                                    <button className='primaryFilled_activeCta w_100 text-uppercase' onClick={() => handleOpen()}>Invite via email</button>
                                </div>
                            </div>
                    </div>
                </div>
                {
                    list === 'startup' ? <Startup
                    /> : list === 'prestartup' ? <PreStartup
                    /> : list === 'investor' ? <Investor
                    /> : list === 'mentor' ? <Mentor
                    /> : list === 'corporate' ? <Corporate
                    /> : list === 'student' ? <Student
                    /> : list === 'serviceprovider' ? <ServiceProvider
                    /> : list === 'employee' ? <Employee
                    /> : list === 'individual' ? <Individual
                    /> : null
                }
            </div>
        </div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...style, width: 500 }}>
                <InviteModal
                    handleClose={handleClose}
                    list={list}
                />
            </Box>
        </Modal>
    </div>
}

export default Membership
