import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../Utils/Auth";
import './startup.css';
import { ArrowForward } from "@mui/icons-material"
import Axios from "axios";


const Startups = () => {
    const { user, setSessionExpire } = useAuth();
    const [startups, setStartups] = useState([])
    const navigate = useNavigate();
    const getStartups = async () => {
        await Axios({
            method: "get",
            url: `/api/startup`,
        }).then((res) => {
            setStartups(res.data.resultModels)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    };

    useEffect(() => {
        getStartups()
    }, [])

    return <div className="mentor_startups_root">
        <div className="mentor_startups_head">
            <p className="subtitle color_dark_black2">Explore start up looking for Mentors</p>
            <Link to={`/dashboard/${user.type}?tab=2`} >
                <p className="w_fit bold" style={{ marginTop: "5px" }}
                // onClick={() => setconnectionState(1)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </p>
            </Link>
        </div>

        <div className="mentor_startups_bodyMain">
            <div className="row">
                {
                    startups.length > 0 ?
                        startups.slice(0,3).map((startup, i) => <div className="col-lg-4" key={i}>
                            <div className="mentor_startups_body">
                                <div className="mentor_startups_body_head">
                                    <div className="mentor_startup_body_head_profile">
                                        <img src={startup.logo} alt="profile" />
                                    </div>
                                </div>
                                <div className="mentor_startups_name_body">
                                    <div className="mentor_startups_name pt-1 text-center">
                                        <p className="body1">{startup.companyName.slice(0,13)} {startup.companyName.length > 13? "...":""}</p>
                                    </div>
                                    <div className="mentor_startups_tags mt-8">

                                        <p className="p_grey_chip overline color_light_grey">{startup.industry}</p>
                                       
                                    </div>
                                    <div className="mentor_startups_view mt-24 mb-16">
                                        <p className="caption color_dark_black2 d-flex align-items-center point"
                                        onClick={()=>{
                                            navigate(`/dashboard/${user.type}?tab=1651&startup=${startup.id}`)
                                        }}
                                        >
                                            View <span style={{ marginLeft: 4 }}><ArrowForward
                                                style={{
                                                    fill: "#989898",
                                                    width: 16,
                                                    height: 16,
                                                }}
                                            /></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>) : null
                }

            </div>
        </div>

    </div>
}

export default Startups