import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import PropTypes from "prop-types";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import industryList from "../../../Pages/Lists/Industries.json";
import countryList from "../../../Pages/Lists/Countries.json";
import Pagination from "@mui/material/Pagination";
// import RecommendedInvestor from "../../Components/RecommendedInvestor";
// import RecommendedCorporates from '../../Components/RecommendedCorporates';
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import "./connections.css";
import {ConnectionExploreSkeleton} from '../../../Components/SkeletonLoader/ConnectionSkeleton'
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function ShowUsers() {
  //     {investorRecommendations,
  //   corpRecommendations,
  //   fundRecommendations
  // }
  const { user } = useAuth();
  const [allStartups, setallStartups] = useState([]);
  const [allIncubators, setAllIncubators] = useState([]);
  const [allCorporates, setAllCorportes] = useState([]);
  const [allserviceprovider, setAllserviceprovider] = useState([]);
  const [allMentors, setAllMentors] = useState([]);
  const [allNewInvestors, setAllNewInvestors] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  // const [serviceprovider, setAllserviceprovider] = useState(null);

  const [startupPage, setstartupPage] = useState(1);
  const [incubatorPage, setincubatorPage] = useState(1);
  const [corporatesPage, setcorporatesPage] = useState(1);
  const [mentorsPage, setmentorsPage] = useState(1);
  const [investorsPage, setinverstorsPage] = useState(1);
  const [spPage, setspPage] = useState(1);

  const [startupCount, setStartupCount] = useState(0);
  const [incubatorCount, setincubatorCount] = useState(0);
  const [corporatesCount, setcorporatesCount] = useState(0);
  const [mentorsCount, setmentorsCount] = useState(0);
  const [investorsCount, setinverstorsCount] = useState(0);
  const [spCount, setspCount] = useState(0);

  const handleStartupPageChange = (event, value) => {
    setstartupPage(value);
  };
  const handleIncubatorPageChange = (event, value) => {
    setincubatorPage(value);
  };
  const handleCorporatePageChange = (event, value) => {
    setcorporatesPage(value);
  };
  const handleMentorsPageChange = (event, value) => {
    setmentorsPage(value);
  };
  const handleInvestorsPageChange = (event, value) => {
    setinverstorsPage(value);
  };
  const handleSpPageChange = (event, value) => {
    setspPage(value);
  };


  const query = useQuery();
  var currentSection = Number(query.get("section"));

  const getStartups = async () => {
    setIsLoading(true)
    await Axios({
      method: "get",
      url: `/api/startup?page=${startupPage}&limit=20&company=${companyName}&industry=${industryQuery}&country=${locationQuery}`,
    })
      .then((res) => {
        if (res) {
          setallStartups(res.data.resultModels);
          setStartupCount(res.data.count);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setallStartups(null);
        if (err.response && err.response.status === 401) {
          //   setSessionExpire(true);
        }
        setIsLoading(false);
      });
  };

  const getIncubators = async () => {
    await Axios({
      method: "get",
      url: `/api/incubator?page=${incubatorPage}&limit=20&company=${companyName}&industry=${industryQuery}&country=${locationQuery}`,
    })
      .then((res) => {
        if (res) {
          setAllIncubators(res.data.resultModels);
          setincubatorCount(res.data.count)
        }
      })
      .catch((err) => {
        setAllIncubators(null);
        if (err.response && err.response.status === 401) {
          //   setSessionExpire(true);
        }
      });
  };

  // get corporates
  const getCorporates = async () => {
    await Axios({
      method: "get",
      url: `/api/corporate?page=${corporatesPage}&limit=20&company=${companyName}&industry=${industryQuery}&country=${locationQuery}`,
    })
      .then((res) => {
        if (res) {
          setAllCorportes(res.data.resultModels);
          setcorporatesCount(res.data.count)
        }
      })
      .catch((err) => {
        setAllIncubators(null);
        if (err.response && err.response.status === 401) {
          //   setSessionExpire(true);
        }
      });
  };

  const getMentors = async () => {
    await Axios({
      method: "get",
      url: `/api/mentor?page=${mentorsPage}&limit=20&company=${companyName}&industry=${industryQuery}&country=${locationQuery}`,
    })
      .then((res) => {
        if (res) {
          setAllMentors(res.data.resultModels);
          setmentorsCount(res.data.count)
        }
      })
      .catch((err) => {
        setAllIncubators(null);
        if (err.response && err.response.status === 401) {
          //   setSessionExpire(true);
        }
      });
  };

  const getInvestors = async () => {
    await Axios({
      method: "get",
      url: `/api/investor?page=${investorsPage}&limit=20&company=${companyName}&industry=${industryQuery}&country=${locationQuery}`,
    })
      .then((res) => {
        if (res) {
          setAllNewInvestors(res.data.resultModels);
          setinverstorsCount(res.data.count)
        }
      })
      .catch((err) => {
        setAllIncubators(null);
        if (err.response && err.response.status === 401) {
          //   setSessionExpire(true);
        }
      });
  };

  const getserviceprovider = async () => {
    await Axios({
      method: "get",
      url: `/api/sp?page=1&limit=${spPage}0&company=${companyName}&industry=${industryQuery}&country=${locationQuery}`,
    })
      .then((res) => {
        if (res) {
          setAllserviceprovider(res.data.resultModels);
          setspCount(res.data.count)
        }
      })
      .catch((err) => {
        // setserviceprovider(null);
        if (err.response && err.response.status === 401) {
          //   setSessionExpire(true);
        }
      });
  };

  const [companyName, setCompanyName] = useState("");
  const [industryQuery, setindustryQuery] = useState("");
  const [locationQuery, setlocationQuery] = useState("");

  useEffect(() => {
    getStartups();
  }, [companyName, industryQuery, locationQuery, startupPage]);
 
  useEffect(()=>{
    getIncubators();
  },[companyName, industryQuery, locationQuery,incubatorPage])
  useEffect(()=>{
    getCorporates();
  },[companyName, industryQuery, locationQuery,corporatesPage])
  useEffect(()=>{
    getMentors();
  },[companyName, industryQuery, locationQuery,mentorsPage])
  useEffect(()=>{
    getInvestors();
  },[companyName, industryQuery, locationQuery,investorsPage])
  useEffect(()=>{
    getserviceprovider();
  },[companyName, industryQuery, locationQuery,spPage])


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (currentSection) {
      setValue(currentSection);
    }
  }, [currentSection]);

  return (
    <div className="dashboard01_home">
      <div className="row no-margin">
        <div className="col-lg-12">
          <div className=" mt-3">
            <div>
              <div className="row no-margin justify-content-center">
                <div className="col-lg-8 no-padding">
                  <div className="connection_search">
                    <input
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      placeholder="Search"
                      className="connection_serach_input"
                    />
                    <div className="connection_search_button">
                      <button>
                        <SearchTwoToneIcon />
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 industry_filter_connections"
                  style={{ display: "none" }}
                >
                  <div className="connection_serach">
                    <select
                      value={industryQuery}
                      onChange={(e) => setindustryQuery(e.target.value)}
                      placeholder="Industry"
                      className="connection_serach_input"
                    >
                      <option value="">Industry</option>
                      {industryList.map((industry, i) => (
                        <option key={i} value={industry.label}>
                          {industry.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  className="col-lg-4 no-padding"
                  style={{ display: "none" }}
                >
                  <div className="connection_serach">
                    <select
                      value={locationQuery}
                      onChange={(e) => setlocationQuery(e.target.value)}
                      placeholder="Location"
                      className="connection_serach_input"
                    >
                      <option value="">Location</option>
                      {countryList.map((industry, i) => (
                        <option key={i} value={industry.label}>
                          {industry.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="service-tabs-main dashbaord_connections_inner_tabs"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="servicer-tabs"
                >
                  <Tab
                    // onClick={() =>
                    //   history(`/dashboard/${user.type}?tab=8&section=0`)
                    // }
                    label={
                      <div className="row align-items-center events-tabs-rows">
                        <p className="no-margin cta">STARTUPS</p>{" "}
                      </div>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    // onClick={() =>
                    //   history(`/dashboard/${user.type}?tab=8&section=1`)
                    // }
                    className=""
                    label={
                      <div className="row align-items-center events-tabs-rows">
                        <p className="no-margin cta">Incubators</p>{" "}
                      </div>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    // onClick={() =>
                    //   history(`/dashboard/${user.type}?tab=8&section=2`)
                    // }
                    className="tab_br_1"
                    label={
                      <div className="row align-items-center events-tabs-rows">
                        <p className="no-margin cta">MENTORS</p>{" "}
                      </div>
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    // onClick={() =>
                    //   history(`/dashboard/${user.type}?tab=8&section=2`)
                    // }
                    className="tab_br_1"
                    label={
                      <div className="row align-items-center events-tabs-rows">
                        <p className="no-margin cta">CORPORATES</p>{" "}
                      </div>
                    }
                    {...a11yProps(3)}
                  />
                  <Tab
                    // onClick={() =>
                    //   history(`/dashboard/${user.type}?tab=8&section=2`)
                    // }
                    className="tab_br_1"
                    label={
                      <div className="row align-items-center events-tabs-rows">
                        <p className="no-margin cta">INVESTORS</p>{" "}
                      </div>
                    }
                    {...a11yProps(4)}
                  />
                  <Tab
                    // onClick={() =>
                    //   history(`/dashboard/${user.type}?tab=8&section=2`)
                    // }
                    className="tab_br_1"
                    label={
                      <div className="row align-items-center events-tabs-rows">
                        <p className="no-margin cta">Service Providers</p>{" "}
                      </div>
                    }
                    {...a11yProps(5)}
                  />
                </Tabs>
              </Box>

              <TabPanel
                value={value}
                index={0}
                className="service-tabbpanel-main"
              >
                <div className="row no-margin ">
                  {
                    isLoading ? <ConnectionExploreSkeleton/> : allStartups?.length > 0 ? (
                      <>
                      {allStartups?.map((startup, i) => (
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
                          key={i}
                        >
                          <Link
                            to={`/dashboard/${user.type}?tab=1651&startup=${startup.id}`}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              className={`dashboard_01_connections_list_item`}
                            >
                              <div className="dashboard_01_connections_logo_list_block">
                                <Avatar
                                  src={startup.logo}
                                  alt="connection"
                                  className="dashboard_01_connections_logo"
                                />
                              </div>

                              <div className="dashboard_01_connections_list_item_desc">
                                <p className="p_spartan_20 pb-2 text-capitalize">
                                  {startup.companyName.length > 20
                                    ? startup.companyName.slice(0, 12) + "..."
                                    : startup.companyName}
                                </p>
                                <p className=" no-margin font12 bold pb-2">
                                  {startup.location.city}
                                  {", "} {startup.location.country}
                                </p>
                                <div className="row justify-content-center">
                                  <p className=" font12 p_grey_chip">
                                    {startup.industry}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                      <div className="gsd_pagination">
                        <Pagination
                          count={
                            startupCount ? Math.floor(startupCount / 20) + 1 : 0
                          }
                          page={startupPage}
                          onChange={handleStartupPageChange}
                        />
                      </div>
                      </>
                    ):(
                      ""
                    )}
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={1}
                className="service-tabbpanel-main"
              >
                <div className="row no-margin ">
                  {isLoading ? <ConnectionExploreSkeleton/> : allIncubators?.length > 0 ? (<>
                      {allIncubators?.map((incubator, i) => (
                        // <div className="col-sm-3 col-md-4 col-xs-6">
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
                          key={i}
                        >
                          <Link
                            to={`/dashboard/${user.type}?tab=1651&incubator=${incubator.id}`}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              className={`dashboard_01_connections_list_item`}
                            >
                              <div className="dashboard_01_connections_logo_list_block">
                                <Avatar
                                  src={incubator.logo}
                                  alt="connection"
                                  className="dashboard_01_connections_logo"
                                  sx={{ width: 64, height: 64 }}
                                />
                              </div>

                              <div className="dashboard_01_connections_list_item_desc">
                                <p className="p_spartan_20 pb-2 text-capitalize">
                                  {incubator.name.length > 20
                                    ? incubator.name.slice(0, 12) + "..."
                                    : incubator.name}
                                </p>
                                <p className=" no-margin font12 bold pb-2">
                                  {incubator.location.city},{" "}
                                  {incubator.location.country}
                                </p>
                                <div className="row justify-content-center mx-1">
                                  <p className=" font12 p_grey_chip">
                                    {incubator && incubator.interestedIndustries ? incubator.interestedIndustries[0] : null}
                                  </p>
                                  <p className=" font12 p_grey_chip">
                                    {incubator && incubator.interestedIndustries ? incubator.interestedIndustries[1] : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                      <div className="gsd_pagination">
                        <Pagination
                          count={
                            incubatorCount ? Math.floor(incubatorCount / 20) + 1 : 0
                          }
                          page={incubatorPage}
                          onChange={handleIncubatorPageChange}
                        />
                      </div>
                    </>):(
                    ""
                  )}
                 
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={2}
                className="service-tabbpanel-main"
              >
                <div className="row no-margin ">
                {isLoading ? <ConnectionExploreSkeleton/> : allMentors?.length > 0 ? (<>
                  {allMentors?.map((fund, i) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
                        key={i}
                      >
                        <Link
                          to={`/dashboard/${user.type}?tab=1651&mentor=${fund.id}`}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={`dashboard_01_connections_list_item`}
                          >
                            <div className="dashboard_01_connections_logo_list_block">
                              <Avatar
                                src={fund.profilePic}
                                alt="connection"
                                className="dashboard_01_connections_logo"
                              />
                            </div>

                            <div className="dashboard_01_connections_list_item_desc">
                              <p className="p_spartan_20 pb-2 text-capitalize">
                                {fund.name.length > 20
                                  ? fund.name.slice(0, 12) + "..."
                                  : fund.name}
                              </p>
                              <p className=" no-margin font12 bold pb-2">
                                {fund.location.city}, {fund.location.country}
                              </p>
                              <div className="row justify-content-center">
                                <p className=" font12 p_grey_chip">
                                  {fund.industry}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="gsd_pagination">
                        <Pagination
                          count={
                            mentorsCount ? Math.floor(mentorsCount / 20) + 1 : 0
                          }
                          page={mentorsPage}
                          onChange={handleMentorsPageChange}
                        />
                      </div>
                    </>):(
                    ""
                  )}
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={3}
                className="service-tabbpanel-main"
              >
                <div className="row no-margin ">
                {isLoading ? <ConnectionExploreSkeleton/> : allCorporates?.length > 0 ? (<>
                  { allCorporates.map((corporate, i) => (
                      // <div className="col-sm-3 col-md-4 col-xs-6">
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
                        key={i}
                      >
                        <Link
                          to={`/dashboard/${user.type}?tab=1651&corporate=${corporate.id}`}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={`dashboard_01_connections_list_item`}
                          >
                            <div className="dashboard_01_connections_logo_list_block">
                              <Avatar
                                src={corporate.logo}
                                alt="connection"
                                className="dashboard_01_connections_logo"
                              />
                            </div>

                            <div className="dashboard_01_connections_list_item_desc">
                              <p className="p_spartan_20 pb-2 text-capitalize">
                                {corporate.legalname.length > 20
                                  ? corporate.legalname.slice(0, 12) + "..."
                                  : corporate.legalname}
                              </p>
                              <p className=" no-margin font12 bold pb-2">
                                {corporate.location.city},{" "}
                                {corporate.location.country}
                              </p>
                              <div className="row justify-content-center">
                                <p className=" font12 p_grey_chip">
                                  {corporate.typeOfCorporate}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="gsd_pagination">
                        <Pagination
                          count={
                            corporatesCount ? Math.floor(corporatesCount / 20) + 1 : 0
                          }
                          page={corporatesPage}
                          onChange={handleCorporatePageChange}
                        />
                      </div>
                    </>):(
                    ""
                  )}
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={4}
                className="service-tabbpanel-main"
              >
                <div className="row no-margin ">
                {isLoading ? <ConnectionExploreSkeleton/> : allNewInvestors?.length > 0 ? (<>
                  { allNewInvestors.map((investor, i) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
                        key={i}
                      >
                        <Link
                          to={`/dashboard/${user.type}?tab=1651&investor=${investor.id}`}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={`dashboard_01_connections_list_item`}
                          >
                            <div className="dashboard_01_connections_logo_list_block">
                              <Avatar
                                src={investor.profilePic}
                                alt="connection"
                                className="dashboard_01_connections_logo"
                              />
                            </div>

                            <div className="dashboard_01_connections_list_item_desc">
                              <p className="p_spartan_20 pb-2 text-capitalize">
                                {investor.name.length > 20
                                  ? investor.name.slice(0, 12) + "..."
                                  : investor.name}
                              </p>
                              <p className=" no-margin font12 bold pb-2">
                                {investor.location.city},{" "}
                                {investor.location.country}
                              </p>
                              <div className="row justify-content-center mx-1">
                                <p className=" font12 p_grey_chip">
                                  {investor && investor.interestedDomains &&
                                  investor.interestedDomains.length > 1
                                    ? investor.interestedDomains[0]
                                    : null}
                                </p>
                                <p className=" font12 p_grey_chip">
                                  {investor && investor.interestedDomains &&
                                  investor.interestedDomains.length > 2
                                    ? investor.interestedDomains[1]
                                    : null}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="gsd_pagination">
                        <Pagination
                          count={
                            investorsCount ? Math.floor(investorsCount / 20) + 1 : 0
                          }
                          page={investorsPage}
                          onChange={handleInvestorsPageChange}
                        />
                      </div>
                    </>):(
                    ""
                  )}
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={5}
                className="service-tabbpanel-main"
              >
                <div className="row no-margin ">
                {isLoading ? <ConnectionExploreSkeleton/> : allserviceprovider?.length > 0 ? (<>
                  { allserviceprovider.map((startup, i) => (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding"
                        key={i}
                      >
                        <Link
                          to={`/dashboard/${user.type}?tab=1651&serviceprovider=${startup.id}`}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={`dashboard_01_connections_list_item`}
                          >
                            <div className="dashboard_01_connections_logo_list_block">
                              <Avatar
                                src={startup.logo}
                                alt="connection"
                                className="dashboard_01_connections_logo"
                              />
                            </div>

                            <div className="dashboard_01_connections_list_item_desc">
                              <p className="p_spartan_20 pb-2 text-capitalize">
                                {startup.companyName.length > 20
                                  ? startup.companyName.slice(0, 12) + "..."
                                  : startup.companyName}
                              </p>
                              <p className=" no-margin font12 bold pb-2">
                                {startup.location.city}
                                {", "} {startup.location.country}
                              </p>
                              <div className="row justify-content-center">
                                <p className=" font12 p_grey_chip">
                                  {startup.industry}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                    <div className="gsd_pagination">
                        <Pagination
                          count={
                            spCount ? Math.floor(spCount / 20) + 1 : 0
                          }
                          page={spPage}
                          onChange={handleSpPageChange}
                        />
                      </div>
                    </>):(
                    ""
                  )}
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
ShowUsers.propTypes = {
  handleAccept: PropTypes.func.isRequired,
};
{
  /*   <div className="row_between no-margin">
                            <div className="text-center cta" >
                            <p className="no-margin bold">{startup.employees}</p>
                            <p className="no-margin bold font12">Employees</p>
                            </div>
                            <div className="text-center cta" >
                            <p className="no-margin bold">{startup.audience}</p>
                            <p className="no-margin bold font12">Type</p>
                            </div>
                            </div> */
}
