import React, { useEffect, useState } from 'react'
import AssignmentCard from './AssignmentCard'
import './assignment.css';
import Axios from 'axios';
import { useAuth } from "../../../../../Utils/Auth";
import { useParams } from 'react-router-dom';

const Assignment = () => {

    const [assignments, setAssignments] = useState([]);
    const { setSessionExpire } = useAuth();
    const {programid} = useParams()

    const getAssignments = async () => {
        await Axios({
            url: `/api/mentorModules/assignment/incubator/course/${programid}`,
            method: "GET",
        }).then((res) => {
            setAssignments(res.data.data)
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    useEffect(() => {
        getAssignments();
    }, [])

    return <div className="incubator_assignment_root">
        <div className="incubator_assignment_main">
            <div className="row">
                {
                    assignments && assignments.length > 0 ?
                        assignments.map((assignment, i) => <AssignmentCard
                            key={i}
                            assignment={assignment}
                            getAssignments={getAssignments}
                        />) : null
                }

            </div>
        </div>
    </div>
}

export default Assignment