import React, {  useState } from 'react';

// import { useNavigate } from 'react-router-dom';
import Query from '../../../Utils/Query/Query'
import { toast } from 'react-toastify';

import Axios from "axios";

import CancelIcon from "@mui/icons-material/Cancel";

import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import NumberFormat from "react-number-format";
import './lead.css'
import {useAuth} from '../../../Utils/Auth'
import { useNavigate } from 'react-router-dom';
const CreateLead = ({handleClose,fetchLeads,tab}) => {
     const {user} = useAuth()
     const navigate = useNavigate()
     // const navigate = useNavigate();
     const query = Query();
     const targetId = query.get('target');
     const categoryId = query.get('category');
     const [leadTitle, setLeadTitle] = useState('')
     const [leadStatus, setLeadStatus] = useState('')
     const [leadEmail, setLeadEmail] = useState('')
     const [isNotify, setIsNotify] = useState(true);
     const [description, setDescription] = useState('')
     const [leadIssues, setLeadIssuses] = useState([]);
     const [issues, setIssuses] = useState("");
     const [isdocloading, setIsFocLoading] = useState(false)
     const [attachments, setAttachments] = useState([]);
     const [amountRaised, setAmountRaised] = useState(null)
     const [formatedValue, setFormatedValue] = useState(amountRaised);
     const [leadCreatedOn,setLeadCreatedOn] = useState(new Date().toISOString().substring(0, 10))
     const [leadCompletionDate,setLeadCompletionDate] = useState(null)
     const [isLoading,setIsLoading] = useState(false)
     const addObjective = (arr, seto, v, setv) => {
          let data = {
               name:v
          }
          if (arr && v.length > 0) {
               seto((prev) => [...prev, data]);
               setv("");
          }
     };

     const removeObjective = (arr, seto, id) => {
          const newArr = arr.filter((obj, i) => {
               return id !== i;
          });
          seto(newArr);
     };

     function handleRuppesConvert(e) {
          setFormatedValue(e.target.value);
          let s = e.target.value;
          if (s) {
               var n = s.split("€", 2)[1];
               setAmountRaised(Number(n.split(",").join("")));
          }
     }

     const handleUploadAttachment = (e) => {
          
          e.preventDefault();

          const file = e.target.files[0];
          const fileSize = file.size / 1024 / 1024; // in MiB
          if (fileSize > 25) {
               toast.error("File size exceeded 25MB!", { position: "bottom-right" });
               return;
          }
          const formdata = new FormData();
          formdata.append("leadAttachments", file);
          setIsFocLoading(true);
          Axios({
               method: "post",
               url: "/api/upload/lead-attachments",
               data: formdata,
          })
               .then((res) => {
                    const { data } = res;
                    if (data) {
                         const attchData = {
                              name: file.name,
                              link: data.location
                         }
                         setAttachments((prevAttchments) => [...prevAttchments, attchData])
                    } else {
                         toast.error("Error in upload", { position: "top-right" });
                    }

                    setIsFocLoading(false);
                    toast.success("Attchment uploaded", { position: "bottom-right" });
               })
               .catch(() => {
                    setIsFocLoading(false);
                    toast.error("Error in upload", { position: "bottom-right" });
               });
     }

     const handleLeadCreate = (e) => {
          setIsLoading(true)
          e.preventDefault();
          const data = {
               title:leadTitle,
               lead_email:leadEmail,
               email_notification:isNotify,
               description,
               user_id:user.id,
               todos:leadIssues,
               attachments:attachments,
               amount_raised:amountRaised,
               created_on:leadCreatedOn,
               completion_date:leadCompletionDate,
               target_id:targetId,
               category_id:categoryId,
               lead_status:leadStatus
          }
          if(leadTitle.length <= 0 || leadStatus.length <= 0 || leadEmail.length <= 0 || description.length <= 0){
               toast.warning('Please fill mendatory fields');
               setIsLoading(false)
               return;
          }else{
               Axios({
                    method: "post",
                    url: "/api/leadGeneration/create/lead",
                    data: data,
               })
               .then(() => {
                         toast.success("Lead created", { position: "top-right" });
                         navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId}&category=${categoryId}&create=success`)
                         setIsLoading(false)
                         setLeadTitle('')
                         setLeadEmail('')
                         setDescription('')
                         setLeadIssuses([])
                         setAttachments([])
                         setAmountRaised(null)
                         setLeadCompletionDate(null)
                         setLeadCreatedOn(new Date().toISOString().substring(0, 10))
                         handleClose()
                         fetchLeads()
                    })
                    .catch(() => {
                         toast.error("Error while creating lead", { position: "top-right" });
                         setIsLoading(false)
                    });
          }
     }
     return (
       

               <div>
                         <div className="close-modal-main">
                              <button onClick={handleClose}>
                                   <CancelIcon />
                              </button>
                         </div>
                         <div className="modal-main-text">
                              <div className="incubator_create_program_form_root mt-3">
                                   <form className="incubator_create_program_form">
                                        <div className="register_page_form row">
                                             <div className="col-12 col-sm-12 col-md-8">
                                                  <label className="auth_label p_secNaviBlue12 mt-0">
                                                       Title *
                                                  </label>
                                                  <input
                                                       placeholder="Enter the lead title"
                                                       type="text"
                                                       className="auth_input mt-0"
                                                       value={leadTitle}
                                                       onChange={(e) => setLeadTitle(e.target.value)}
                                                  />
                                             </div>
                                             <div className="col-12 col-md-4 col-sm-12">
                                                  <label className="auth_label p_secNaviBlue12 mt-0">Lead status *</label>
                                                  <select
                                                       className="w_100 auth_input auth_select"
                                                       name="program_type"
                                                       value={leadStatus}
                                                       onChange={(e) => setLeadStatus(e.target.value)}
                                                  >
                                                       <option value="">Select status</option>
                                                       <option value="Introduction">
                                                            Introduction
                                                       </option>
                                                       <option value="Proposal sent">Proposal sent</option>
                                                       <option value="In progress">In progress</option>
                                                       <option value="Approved">Approved</option>
                                                       <option value="Rejected">
                                                            Rejected
                                                       </option>
                                                  </select>
                                             </div>
                                             <div className='col-12 col-sm-12 col-md-8 pl-0'>
                                                  <div className="col-12">
                                                       <label className="auth_label">
                                                            Email of lead *
                                                       </label>
                                                       <input
                                                            placeholder="Enter the email of lead"
                                                            type="text"
                                                            className="auth_input"
                                                            value={leadEmail}
                                                            onChange={(e) => setLeadEmail(e.target.value)}
                                                       />
                                                  </div>
                                                  <div className="col-12 mt-1 align-items-start">
                                                       <div className='d-flex '>
                                                            <input
                                                                 placeholder="notification"
                                                                 type="checkbox"
                                                                 className="m-0 p-0 notify"
                                                                 value={isNotify}
                                                                 onChange={() =>setIsNotify(!isNotify)}

                                                                 defaultChecked={isNotify}
                                                            />
                                                            <label className="auth_label p_secNaviBlue12 m-0 px-1">
                                                                 Send Notifications
                                                            </label>

                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="col-12 col-md-4 col-sm-12 ">
                                                  <label className="auth_label">
                                                       Amount Raised *
                                                  </label>
                                                  <NumberFormat
                                                       placeholder="€ 100,000"
                                                       displayType={"number"}
                                                       thousandSeparator={true}
                                                       prefix={"€ "}
                                                       className="some auth_input"
                                                       inputmode="numeric"
                                                       value={formatedValue}
                                                       onChange={(e) => handleRuppesConvert(e)}
                                                  />
                                             </div>

                                             <div className="col-12">
                                                  <label className="auth_label">Description *</label>
                                                  <textarea
                                                       
                                                       name="short_desc"
                                                       className="auth_input auth_textarea"
                                                       style={{ height: "60px" }}
                                                       placeholder="Description"
                                                       value={description}
                                                       onChange={(e) => setDescription(e.target.value)}
                                                  ></textarea>
                                             </div>
                                             <div className="col-12">
                                                  <label className="auth_label">Todos </label>
                                                  <div className="goalsdiv">
                                                       <input
                                                            type="text"
                                                            name="todos"
                                                            className="auth_input"
                                                            placeholder="Add todos"
                                                            id="todos"
                                                            value={issues}
                                                            onChange={(e) => setIssuses(e.target.value)}
                                                       />
                                                       <Tooltip title="Add More Rows">
                                                            <button
                                                                 type="button"
                                                                 onClick={(e) => {
                                                                      e.preventDefault()
                                                                      addObjective(
                                                                           leadIssues,
                                                                           setLeadIssuses,
                                                                           issues,
                                                                           setIssuses)
                                                                 }
                                                                 }
                                                            >
                                                                 <AddIcon />
                                                            </button>
                                                       </Tooltip>
                                                  </div>
                                                  <div className="goalsscroll">
                                                       {leadIssues
                                                            ? leadIssues?.map((obj, i) => {
                                                                 return (
                                                                      <>
                                                                      <span
                                                                           className="badge badge-pill badge-dark goalsvalues  mr-2 mt-3"
                                                                           key={i}>
                                                                                <p className="program_text">{obj.name}</p>
                                                                                <Tooltip title="Delete Row">
                                                                                <button
                                                                                     type="button"
                                                                                     title="Remove"
                                                                                     onClick={() =>
                                                                                     removeObjective(
                                                                                     leadIssues,
                                                                                     setLeadIssuses,
                                                                                      i
                                                                                               )
                                                                                          }
                                                                                          className="program_btn"
                                                                                     >
                                                                                          x
                                                                                     </button>
                                                                                </Tooltip>
                                                                           </span>
                                                                      </>
                                                                 );
                                                            })
                                                            : null}
                                                  </div>
                                             </div>
                                             <div className='col-12 mt-2'>
                                                  <div className="authIdCard pb-2 row align-items-center">
                                                       <div className="authIdCardFirst col-12 col-md-11">
                                                            <label
                                                                 className="mt-2"
                                                                 title="Attchments"
                                                                 htmlFor='investor_id'
                                                                 style={{ cursor: "pointer" }}
                                                            >
                                                                 Add Attachments
                                                            </label>
                                                       </div>
                                                       {isdocloading ? (
                                                            <CircularProgress style={{ color: "black" }} />
                                                       ) : (
                                                            <div className="authIdCardSecond col-12 col-md-1" style={{ cursor: "pointer" }}>
                                                                 <label htmlFor="investor_id">
                                                                      <div
                                                                           className="investor_logo"
                                                                           title="Attchments"
                                                                      >
                                                                           <i
                                                                                style={{ color: "black" }}
                                                                                className="fas fa-paperclip"

                                                                           ></i>
                                                                      </div>
                                                                      <input
                                                                           type="file"
                                                                           accept="application/pdf,image/*,.doc,.docx,.ppt"
                                                                           name="investor_id"
                                                                           id="investor_id"
                                                                           onChange={handleUploadAttachment}
                                                                           title="Click here to add attchments"
                                                                      />
                                                                 </label>
                                                            </div>
                                                       )}
                                                  </div>
                                                  <div className='goalsscroll'>
                                                       {
                                                            attachments.length > 0 && attachments.map((attchment, i) => {
                                                                 return <>
                                                                      <span
                                                                           className="badge badge-pill badge-dark goalsvalues  mr-2 mt-1 d-flex justify-content-between"
                                                                           key={i}>
                                                                           <p className="program_text">{attchment.name.length > 25 ? attchment.name.substring(0, 25) + '...' : attchment.name}</p>
                                                                           <Tooltip title="Delete Row">
                                                                                <button
                                                                                     type="button"
                                                                                     title="Remove"
                                                                                     onClick={() =>
                                                                                          removeObjective(
                                                                                               attachments,
                                                                                               setAttachments,
                                                                                               i
                                                                                          )}
                                                                                     className="program_btn">x
                                                                                </button>
                                                                           </Tooltip>
                                                                      </span>
                                                                 </>
                                                            })
                                                       }
                                                  </div>
                                             </div>
                                             <div className="col-12 col-md-6 col-12">
                                                  <label className="auth_label">Created on *</label>
                                                  <input
                                                       type="date"
                                                       onFocus={(e) => {
                                                            e.target.type = "date";
                                                       }}
                                                       className="w_100 auth_input"
                                                       placeholder="Start Date"
                                                       // value={programStartDate}
                                                       defaultValue={leadCreatedOn}
                                                       onChange={(e) => setLeadCreatedOn(e.target.value)}
                                                  />
                                             </div>
                                             <div className="col-12 col-md-6 col-12">
                                                  <label className="auth_label">Created at</label>
                                                  <input
                                                       type="date"
                                                       onFocus={(e) => {
                                                            e.target.type = "date";
                                                       }}
                                                       className="w_100 auth_input"
                                                       placeholder="Start Date"
                                                       // value={programStartDate}
                                                       defaultValue={leadCompletionDate}
                                                       onChange={(e) => setLeadCompletionDate(e.target.value)}
                                                  />
                                             </div>

                                        </div>
                                        <div className='d-flex float-right align-items-center my-3 '>
                                        <button
                                             type="button"
                                             className="primaryFilled_activeCta float-right mx-2"
                                             style={{ padding: "10px 20px", margin: "1rem 0 1rem 1rem" }}
                                             onClick={handleLeadCreate}
                                        >
                                             Create
                                        </button>
                                        {isLoading && <CircularProgress/>}
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
         
     )
}

export default CreateLead