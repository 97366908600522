import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import Axios from "axios";
import { useParams } from "react-router-dom";

const index = () => {
  const [announcements, setannoucements] = useState([]);

  const { programid } = useParams();

  const getannoucements = async () => {
    const response = await Axios.get(`/api/announcement/${programid}`);
    if (response.data.success) setannoucements(response.data.data);
  };

  useEffect(() => {
    getannoucements();
  }, []);

  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            {announcements.length > 0
              ? announcements.map((v, i) => {
                  return (
                    <>
                      <div key={i} className=" meet-head-box mb-3">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-10">
                              <div className="pb-2 mt-0 user_incubation_course_AvatarGroup_root">
                                <div className="user_incubation_course_AvatarGroups">
                                  <Avatar src={v.host_id.avatar} />
                                </div>
                                <div className="user_incubation_course_startupEnrolled">
                                  <p className="caption color_light_grey">
                                    {v.host_id.name}
                                  </p>
                                </div>
                              </div>
                              <p className="overline">
                                {new Date(v.createdAt).toDateString() +
                                  " " +
                                  new Date(v.createdAt).toLocaleTimeString(
                                    navigator.language,
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                              </p>
                            </div>
                          </div>

                          <h5 className="p_Bold_secBlue18_spartan my-3 text-left">
                            {" "}
                            {v.title}{" "}
                          </h5>
                          <p className="caption">{v.description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
