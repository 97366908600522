import React from "react";
import image from "./../../Assets/images/404error.png";
// import Navbar from "./../../Layout/Navbar/Navbar";
// import BackgroundImg from './../Home/backgroundImg';
import logo from "./../../Assets/images/logo/logo.png";
import { NavLink } from "react-router-dom";
import "./404page.css";

export default function NotFoundPage() {

    const [imageLoaded, setImageLoaded] = React.useState(false);

    return (
        <>
            {/* <Navbar /> */}
            <div className="auth_back_up">
                <div className="container">
                <div className="row mt-5">
                    <a href="/"><img src={logo} alt="logo" /></a>
                </div>
                <div className="row mt-5">
                <div className="col-12 mt-5">
                    <h2 className="auth_back_head">Whoops !</h2>
                </div>
                    <div className="col-12 mt-5 row">
                    <div className="col-12 col-md-5 mt-5">
                        <h1 className="auth_back_head" style={{color: "#000"}}>Error found</h1>
                        <p className="auth_back_p">It looks like this page can’t be found. Please check the URL and try again later, we are trying our best.</p>
                        <NavLink to={`/`} className="gradient_CTA ">
                            {" "}
                            GO TO HOME{" "}
                        </NavLink>
                    </div>
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-5">
                            <img
                                className={` img-fluid smooth-image auth_option_img image-${
                                    imageLoaded ? "visible" : "hidden"
                                } shift_img`}
                                onLoad={() => setImageLoaded(true)}
                                src={image}
                                alt="image"
                            />
                    </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* <BackgroundImg /> */}
        </>
    );
}
