import React, { useEffect, useState } from 'react'
import './index.css'
import Axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useAuth } from '../../../../../../Utils/Auth';
import StartupRightScreen from './StartupRightScreen';

const Startup = () => {
    const { programid } = useParams();
    const { setSessionExpire } = useAuth();
    const navigate = useNavigate()
    const [courses, setCourses] = useState([])
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const moduleid = query.get("module");
    const courseid = query.get("course");



    const getCourses = async () => {
        await Axios({
            url: `/api/mentorModules/courses/program/${programid}`,
            method: "GET",
        }).then((res) => {
            setCourses(res.data.data);
            navigate(`/service/incprogram/${programid}?tab=8&course=${res.data.data[0]._id}&module=${0}`)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    useEffect(() => {
        getCourses();
    }, [])

    return <div className="incubator_analytics_root">
        <div className="row">
            <div className="col-lg-3">
                <div className="incubator_analytics_courses_root">
                    <div className="incubator_analytics_courses_head">
                        <p className="caption">Courses & Modules</p>
                    </div>
                    <div className="incubator_analytics_courses_main">
                        {
                            courses && courses.length > 0 ?
                                courses.map((course, i) => <div className="incubator_analytics_courses mt-24" key={i}>
                                    <div className={`incubator_analytics_coursename mb-8 point ${(course._id === courseid && moduleid == 0) ? 'showAnalyticsBg' : ''}`}>
                                        <p className="subtitle color_dark_blue pt-8 pb-8"
                                            onClick={() => {
                                                navigate(`/service/incprogram/${programid}?tab=8&course=${course._id}&module=${0}`)
                                            }}
                                        >{course.title}</p>
                                    </div>
                                    <div className="incubator_analytics_courseModules">
                                        {
                                            course.modules && course.modules.length > 0 ?
                                                course.modules.map((module, j) => <div className={`incubator_analytics_courseModule point ${module._id === moduleid ? 'showAnalyticsBg' : ''}`} key={`module${j}`}
                                                    onClick={() => {
                                                        navigate(`/service/incprogram/${programid}?tab=8&course=${course._id}&module=${module._id}`)
                                                    }}

                                                >
                                                    <p className="body2">{module.title}</p>
                                                </div>) : null
                                        }

                                    </div>
                                </div>) : null
                        }

                    </div>
                </div>
            </div>
            <div className="col-lg-9">
                <StartupRightScreen />
            </div>
        </div>
    </div>
}

export default Startup