import React, { useEffect, useState } from 'react'
// import { Avatar } from '@mui/material';
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Axios from "axios";
// import { toast } from 'react-toastify';
import { useAuth } from "../../../Utils/Auth";
import ReportCard from "./ReportCard"
import { useNavigate } from "react-router-dom"
// import BusinessValuation from "../../../Assets/Images/NewDash/Business valuation.png"
// import { AddBoxRounded } from '@mui/icons-material';
import Valuation from "../../../Assets/images/valuation.png"
import Valuation2 from "../../../Assets/images/valuation2.png"
import download from "../../../Assets/images/download (2).png"
import addNewReport from "../../../Assets/images/addNewReport.png"


const LandingPage = (props) => {
    const { setValuationStep, setActiveStep } = props;
    const navigate = useNavigate()
    // const [valuationReports, setValuationReports] = useState()
    const [myReports, setMyreports] = useState([])
    // const [sampleReports,] = useState([1, 2, 3, 4]);
    const { user, setSessionExpire } = useAuth();

    const fetchReports = async () => {
        await Axios({
            url: "/api/valuation/fetchMyReports",
            method: "get",
        })
            .then((res) => {
                setMyreports(res.data.reports);
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    setSessionExpire(true)
                }
            });
    };


    useEffect(() => {
        fetchReports();
    }, [])

    return (
        <div className="newValuation-main">
            {/* <div className="newValuationhead">
                <h6>Business Valuation</h6>
                <button className='newValuation-btn' onClick={() => {
                    setValuationStep(2)
                    navigate(`/dashboard/${user.type}?tab=5&valuate=2`)
                }} >Valuate now</button>
            </div> */}
            <div className="newValuation-mainContainer mt-3">
                <div className="valuation-mainDefCont">
                    <div className="row px-3">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="valuation-mainDefdiv">
                                <div className="row Height_100">
                                    <div className="col-7 Height_100">
                                        <div className="report_impactful_root1">
                                            <div className="title">Make your Valuation Impactful</div>
                                            <p className="mt-8 overline mb-40">Presentation is the key! Make sure you customize the report with logo, business description and contact details.</p>
                                            <div className="gradient_btn_main" onClick={() => {
                                            setValuationStep(2)
                                            navigate(`/dashboard/${user.type}?tab=4&valuate=2`)
                                        }}>
                                            <div className="gradient_btn_light">Start Valuation </div></div>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="addInforMation_root Height_100">
                                            <img src={Valuation2} alt="valuation2" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="valuation-mainsamplediv">
                                <figure>
                                    <img src={Valuation} alt="valuation" />
                                </figure>
                                <div className="valation_newDownlaodSample">
                                    <p>Download Sample Report &nbsp;</p>
                                    <a href="https://www.equidam.com/resources/Equidam-Valuation-Report-Sample.pdf" target="_blank" rel="noopener noreferrer">
                                        <img src={download} alt="dicon" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="valuation-Ongoing-main">
                        {/* <div className="valuation-ongoing-mainHead mb-2">
                            <p>Ongoing valuation / Recently created reports</p>
                        </div> */}
                        <div className="valuation-ongoingVal">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12 p-2">
                                    <div className={`valuation-ongoingMain`}>
                                        <div className="valuation_newgenerateReport" onClick={() => {
                                            setValuationStep(2)
                                            navigate(`/dashboard/${user.type}?tab=4&valuate=2`)
                                        }} >
                                            <img src={addNewReport} alt="addNewReport" className='img-fluid' />
                                            <p>Generate Report</p>
                                        </div>
                                    </div>
                                </div>
                                {myReports && myReports.map((report, i) => <ReportCard
                                    key={i}
                                    report={report}
                                    i={i}
                                    setValuationStep={setValuationStep}
                                    setActiveStep={setActiveStep}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage