import React, { useEffect, useState } from 'react'
import { KeyboardArrowDown } from "@mui/icons-material"
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import './task.css';
// import Axios from 'axios'
// import getDiff from '../../../Utils/diffleft/getDiff';
import moment from "moment-timezone";



const Tasks = ({ userData }) => {

    const { user } = useAuth();

    const [progress, setProgress] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [userTask, setUser] = useState(null)
    const [showUserTask, setShowUser] = useState(false)

    const selectTaskData = async (data) => {
        setProgress(data.progress);
        setTasks(data.tasks);
        setUser(data.user);
        setShowUser(false)
    }

    useEffect(() => {
        setProgress(userData[0].progress);
        setTasks(userData[0].tasks);
        setUser(userData[0].user);
    }, []);

    const get_isodiff = (date) => {
        var dateTime = date;
        var scheduleTime = moment(dateTime);
        var current = moment();

        var dayDiff = scheduleTime.diff(current, "days");
        if (dayDiff > 1) {
            return dayDiff + " days";
        } else if (dayDiff === 1) return dayDiff + " day";
        else {
            var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
            if (hoursDiff >= 1) {
                let hoursDiffRound = Math.round(hoursDiff);
                return hoursDiffRound == 1
                    ? hoursDiffRound + " hour"
                    : hoursDiffRound + " hours";
            } else if (hoursDiff <= 0 && hoursDiff >= -2) {
                return "Live";
            } else if (hoursDiff < -2) {
                return "Expired";
            } else {
                return Math.round(hoursDiff * 60) + " min";
            }
        }
    };

    return <div className="mentor_tasks_root">
        <div className="mentor_tasks_head">
            <p className="subtitle color_dark_black2">Tasks</p>
            <Link to={`/dashboard/${user.type}?tab=2`} >
                <p className="w_fit bold" style={{ marginTop: "5px" }}
                // onClick={() => setconnectionState(1)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </p>
            </Link>
        </div>
        <div className="mentor_tasks_bodyMain mt-16">
            <div className="row">
                <div className="col-lg-5">
                    <div className="mentor_tasks_bodyleft">
                        <div className="mentor_tasks_select_root">
                            <div className="mentor_tasks_bodyCmpName point" onClick={() => setShowUser(!showUserTask)}>
                                {
                                    userTask !== null && userData !== null ?
                                        <>
                                            <div className="mentor_tasks_bodyCmpprf">
                                                <img src={userTask.avatar} alt="profile" />
                                                <p className="caption color_dark_black2">{userTask.firstname} {userTask.lastname}</p>
                                            </div>
                                            <span><KeyboardArrowDown
                                                className={`mentorDownbtn ${showUserTask ? 'rotateDown' : 'antirotateDown'}`}
                                            /></span>
                                        </> : null
                                }
                            </div>
                            {
                                showUserTask ?
                                    <div className={`mentor_tasks_options_root ${showUserTask ? 'show' : 'hide'}`}>
                                        {
                                            userData !== null ?
                                                userData.map((data, i) => <div className="mentor_tasks_options point" key={i}
                                                    onClick={() => selectTaskData(data)}
                                                >
                                                    <img src={data.user.avatar} alt="profile" />
                                                    <p className="caption color_dark_black2">{data.user.firstname} {data.user.lastname}</p>
                                                </div>) : null

                                        }
                                    </div> : null
                            }

                        </div>
                        {
                            progress !== null ? <>
                                <div className="mentor_tasks_bodyProgress">
                                    <h5 style={{ color: "#FF6F1E" }}>{progress.in_progress}</h5>
                                    <p className='subtitle color_dark_black2'>In progress</p>
                                </div>
                                <div className="mentor_tasks_bodyProgress  mt-16">
                                    <h5 style={{ color: "#121843" }}>{progress.completed}</h5>
                                    <p className='subtitle color_dark_black2'>Completed</p>
                                </div>
                            </> : null
                        }

                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="mentor_tasks_bodyright">
                        {
                            tasks && tasks.length > 0 ?
                                tasks.map((task, i) => <div key={i} className="mentor_tasks_mainTask">
                                    <div className="mentor_tasks_maintask_name">
                                        <p className="body2">{task.title}</p>
                                        <p className="caption color_light_grey">{task.sub_task} {task.sub_task > 1 ? "Subtasks" : "Subtask"}</p>
                                    </div>
                                    <div className="mentor_tasks_maintask_due">
                                        <p className="overline">
                                            <span className='color_light_grey'>Due date </span>
                                            <span style={{ color: "#9d2f40" }}>{new Date(task.due_date).toDateString("en-US", {
                                                year: "numeric",
                                                month: "short",
                                            })}</span>
                                        </p>
                                        {/* <p className="overline">{getDiff(task.due_date)}</p> */}
                                        <p className="overline">
                                            {
                                                get_isodiff(task.due_date) ==='Expired'?'Expired' : `${get_isodiff(task.due_date)} left`
                                            }
                                        </p>
                                    </div>
                                </div>) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Tasks