import React, { useState, useEffect } from "react";  
import axios from "axios";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import "./index.css";
import gifannouce from "./../../../Assets/images/announcement.gif";
import moment from "moment-timezone";

export default function Requests() {
  const [announcament, setannouncament] = useState([]);
  const { user, setSessionExpire } = useAuth();

  const getDiffago = (date) => {
    // var dateTime = date + "T" + time;
    var scheduleTime = moment(date);
    var current = moment();
    // var ofset = moment().utcOffset() / 60;

    var dayDiff = scheduleTime.diff(current, "days");
    if (dayDiff < -1) {
      return Math.abs(dayDiff) + " days ago";
    } else if (dayDiff === -1) return Math.abs(dayDiff) + " day ago";
    else {
      var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
      //- parseFloat(timezone.split(" ", 3)[2]) +
      // ofset;
      if (hoursDiff <= -1) {
        let hoursDiffRound = Math.round(hoursDiff);
        return Math.abs(hoursDiffRound) == 1
          ? Math.abs(hoursDiffRound) + " hour ago"
          : Math.abs(hoursDiffRound) + " hours ago";
      } else {
        return Math.abs(Math.round(hoursDiff * 60)) + " mins ago";
      }
    }
  };

  const getAnnouncament = async () => {
    await axios({
      url: "/api/announcement/myannouncements",
      method: "get",
    })
      .then((res) => {
        setannouncament(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      });  
  };

  useEffect(() => {
    getAnnouncament();
  }, []);
  

  return (
    <div className="dashboard01_block mt-0">
    <div className="row justify-content-between mx-1 my-1">
      <h5 className="subtitle w_fit">Announcements</h5>
      <Link to={`/dashboard/${user.type}?tab=7&ps=1`}>
        <p className="w_fit bold" style={{marginTop: "5px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
    <div style={{minHeight:"240px"}}>
      <div className="row">
        {announcament.length!==0 ? (
           announcament.slice(0,3).map((data, i) => ( 
            <Link to={`/service/incprogram/${data.program_id}?tab=7`} key={i}>
            <div className="col-lg-12 my-1 mb-2">
              <div className="announcement-box">
                <div className="row">
                <div className="col-2 d-flex align-items-center" style={{padding:"0" , margin :"0px 10px"}}>
                  <Avatar sx={{width:40,height:40}} src={user.avatar} style={{background: "rgba(0,0,0,0.1"}} className="mr-2" />
                </div>
                <div className="col-8 p-0">
                <div className="row">
                  <div className="d-flex col-12">
                  <p className="overline pr-1" style={{color: "#989898",borderRight: "1px solid #989898"}}>{data.mentor && data.startup ? "Mentor, Startup" : (data.mentor ? "Mentor" : "Startup")}</p>
                  <p className="overline pl-1" style={{color: "#989898"}}>{getDiffago(data.updatedAt)}</p>
                  </div>

                  <p className="caption col-12" >{data.title}</p>
                </div>
                </div>
                </div>
              </div>
            </div>
            </Link>
            )) 
         ) : (
           <div className="col-12 d-flex flex-column align-items-center justify-content-center" style={{height:"240px"}}>
          <img src={gifannouce} style={{witdh:"90px",height:"90px",paddingBottom:"19px"}} />
          <div className="body1 mx-auto" style={{color:"#262C5B"}}>Make an announcement</div>
          </div> 
         )} 
      </div>
    </div>
    </div>
  );
}
