import React, { useState, useEffect } from "react";
import AcceptCard from "./AcceptedCard";
import Axios from "axios";
// import { useAuth } from "../../../../../Utils/Auth";
// import IncubatorExplore  from '../../../../../Components/SkeletonLoader/IncubationProgramSkeleton'
import IncubationProgramSkeleton from '../../../../../Components/SkeletonLoader/ProgramSkeleton'
const Accept = ({ setIncubationState }) => {
  const [programs, setPrograms] = useState([]);
  // const { setSessionExpire } = useAuth();
const [isLoading,setIsLoading] = useState(false);
  const fetchAllAccept = async () => {
    setIsLoading(true)
    await Axios({
      url: `/api/incubatorProgram/pending`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setPrograms(res.data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        //     setSessionExpire(true);
        // }
        console.log(err);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    fetchAllAccept();
  }, []);
  return (
    <div className="incubation_program_main mt-4">
      <div className="row">
        {isLoading ? <IncubationProgramSkeleton/> : (programs && programs.length > 0 ? (
          programs.map((program, i) => (
            <AcceptCard
              key={i}
              program={program}
              setIncubationState={setIncubationState}
            />
          ))
        ) : (
          <p>No Applications yet</p>
        ))}
      </div>
    </div>
  );
};

export default Accept;
