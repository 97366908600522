import React from "react";
// import profile_short from "../../../../../Assets/images/incubatorDash/profile_short.png"
import { ArrowForward } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import get_isodiff from "../../../../../Utils/ISOdiff/isodiff";

const DeclinedCard = ({ program, setIncubationState }) => {
  const navigate = useNavigate();

  return (
    <div
      className="col-12 incunation_shortlisted_main mb-4"
      onClick={() => {
        setIncubationState(3);
        navigate(`/dashboard/startup?tab=7&id=${program.to._id}`);
      }}
    >
      <div className="row">
        <div className="col-lg-4 col-md-3 col-4 d-flex align-items-center">
          <Avatar src={program.to.coverImage} variant="rounded" />
          <p className="p_Bold_secBlue18_spartan ml-2">{program.to.title}</p>
        </div>

        <div className="col-lg-2 col-md-3 col-3 d-flex align-items-center justify-content-end">
          <div className="incubation_chips">{program.to.sector}</div>
        </div>
        <div className="col-lg-2 col-md-2 col-2 d-flex justify-content-center align-items-center">
          <p className="p_LightGrey12" style={{ color: "#565656" }}>
            {get_isodiff(program.updatedAt) === "Live"
              ? "0 mins ago"
              : get_isodiff(program.updatedAt)}
          </p>
        </div>
        <div className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center">
          <p className="transparntChip mr-2">NOT SELECTED</p>
        </div>
        <div
          className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
          style={{ cursor: "pointer" }}
        >
          <p
            className="p_LightGrey12 d-flex align-items-center"
            style={{ color: "#565656" }}
          >
            See Profile <ArrowForward />
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeclinedCard;
