import React from 'react';
import { CancelSharp } from "@mui/icons-material"

const ConfirmModal = ({ handleClose, RequestToIncubator, cartData, subtotal }) => {
    return (
        <div className='startup_mycart_confirm_modal_root p-40'>
            <div className="startup_mycart_confirm_modal_head">
                <h5 className='color_dark_blue'>Order Details</h5>
                <button className='transparent_cta mycart_startup_close' onClick={handleClose}><CancelSharp /></button>
            </div>
            <div className="startup_mycart_confirm_modal_main">
                <div className="startup_mycart_confirm_modal_profile mt-24">
                    {
                        cartData.length > 0 ?
                        cartData.map((data, i) => <div className="startup_mycart_confirm_modal d-flex justify-content-between align-items-center mb-16" key={i}>
                            <div className="startup_mycart_confirm_name">
                                <p className="title">{data.asset_id.asset_name}</p>
                                <p className="overline color_dark_grey2">Incubator name</p>
                            </div>
                            <div className="startup_mycart_confirm_qty_main d-flex justify-content-between align-items-center">
                                <p className='body2'>
                                    <span className='color_dark_grey2'>Qty : </span>
                                    <span className='color_dark_grey2'>{data.quantity}</span>
                                </p>
                                <p className="body1 ml-40">€ {data.asset_id.price * data.quantity}</p>
                            </div>
                        </div>) : null
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center mt-16">
                    <p className="body1">Subtotal({cartData.length} items):</p>
                    <p className="subtitle">€ {subtotal}</p>
                </div>
                <div className="d-flex justify-content-end align-items-end mt-40">
                    <button className='primaryFilled_activeCta' onClick={RequestToIncubator}>CONFIRM</button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmModal