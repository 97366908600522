import React from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Programs from "./TabPanels/Program/Startup";
import Startup from "./TabPanels/Statups/Startup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Analytics = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="incubator_analytics_root">
      <div className="newPosts-main">
        <div className="feedAllPost_tabs_root">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="feedAllPost_tabs"
          >
            <Tab
              className="feedAllPost_tab"
              label="PROGRAM"
              {...a11yProps(0)}
            />
            <span className="feedAllPost_tab_span"></span>
            <Tab
              className="feedAllPost_tab"
              label="STARTUPS"
              {...a11yProps(1)}
            />
            {/* <span className="feedAllPost_tab_span"></span>
            <Tab
              className="feedAllPost_tab"
              label="MENTORS"
              {...a11yProps(2)}
            /> */}
          </Tabs>
        </div>
        <div className="feedAllPost_tabpanel_root">
          <TabPanel value={value} index={0}>
            <Programs />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Startup />
          </TabPanel>
          {/* <TabPanel value={value} index={4}>
            <h3>h3</h3>
          </TabPanel> */}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
