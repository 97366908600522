import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";

const Recommendations = () => {
    const [recommended, setRecommended] = useState([]);
    const { user } = useAuth();

    const getReceived = async () => {
        await axios({
            url: "/api/dashboard/recommandation/mentor",
            method: "get",
        })
            .then((res) => {
                setRecommended(res.data.data.recommand);
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    console.log();
                }
            });
    };

    useEffect(() => {
        getReceived();

    }, []);
    return <div className="mentor_startups_root">
        <div className="row justify-content-between mx-1 my-1">
            <p className="subtitle w_fit">Recomendations</p>
            <Link to={`/dashboard/${user.type}?tab=2`} >
                <p className="w_fit bold" style={{ marginTop: "5px" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </p>
            </Link>
        </div>
        <div className="row">
            {recommended.length > 0 ? (
                recommended.slice(0, 2).map((recommend, i) => (
                    <div className="col-lg-12 my-1 mb-2" key={i}>
                        <div className="connection-box">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-between mb-2">
                                    <p className="overline" style={{ color: "#4D4B4B" }}>Startup</p>
                                    {/* <p className="overline" style={{ color: "#989898" }}>2 days ago</p> */}
                                </div>
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Avatar src={recommend.logo} className="mr-2" variant="rounded" />
                                        <p className="body2" style={{ color: "#262C5B" }}>{recommend.companyName}</p>
                                    </div>
                                    <Link to={`/dashboard/${user.type}?tab=1651&sender=${recommend.id}`}>
                                        <p className="overline" style={{ color: "#4D4B4B" }}>
                                            View Profile
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="col-12 d-flex align-items-center justify-content-center" style={{ height: "240px" }}>
                    <div className="spartan mx-auto">No recommends</div>
                </div>
            )}
        </div>
    </div>
}

export default Recommendations