import React from "react";
import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
// import { AvatarGroup, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Utils/Auth";

const Card = (props) => {
  const { program } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  return (
    <div
      className="col-md-4 col-12"
      onClick={() => {
        navigate(`/dashboard/${user.type}?tab=7`);
      }}
    >
      <div className="incubation_program_card_root">
        <div
          className="incubation_program_card_head"
          style={{ backgroundImage: `url('${program.coverImage}')` }}
        >
          <p className="p_normalBlack10">{program.type}</p>
        </div>
        <div className="incubation_program_card_main pl-3 pr-3">
          <div className="incubation_program_card_title mt-2">
            <h5 className="p_black20">
              {program.title
                ? program.title.length > 20
                  ? program.title.substring(0, 20) + "..."
                  : program.title
                : ""}
            </h5>
          </div>
          <div className="incubation_program_card_info d-flex justify-content-between mt-3">
            <div className="incubation_program_card_small">
              <span>
                {" "}
                <AccountBalanceWallet />{" "}
              </span>
              <span className="p_secBlack10"> Free </span>
            </div>
            <div className="incubation_program_card_small">
              <span>
                {" "}
                <AccessTime />{" "}
              </span>
              <span className="p_secBlack10"> 2 months </span>
            </div>
          </div>
          {/* <div className="incubator_program_card_applicants_root mt-3">
            <p className="p_secBlack10" style={{ color: "#580167" }}>
              Applicants
            </p>
            <div className="incubator_program_card_applicants">
              <AvatarGroup total={20}>
                <Avatar src="" style={{ width: 33, height: 33 }} />
                <Avatar src="" style={{ width: 33, height: 33 }} />
                <Avatar src="" style={{ width: 33, height: 33 }} />
                <Avatar src="" style={{ width: 33, height: 33 }} />
              </AvatarGroup>
            </div>
          </div> */}
        </div>
        <div className="incubator_program_card_footer">
          <p className="p_secBlack10" style={{ color: "rgba(0,0,0,0.75)" }}>
            Application Open Now
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
