import React , { useState, useEffect } from "react"; 
import "./index.css";
import { Link } from "react-router-dom"; // ,useNavigate
import { useAuth } from "../../../Utils/Auth";
import videoimg from "./../../../Assets/images/dashboard/lesson.svg";
import assesmentsimg from "./../../../Assets/images/dashboard/Assesments.svg";
import assignmentimg from "./../../../Assets/images/dashboard/assignment.svg";
import axios from "axios";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from "moment-timezone";

// const GradientSVG =({ startColor, endColor, idCSS, rotation })=> {

//     let gradientTransform = `rotate(${rotation})`;

//     return (
//       <svg style={{height: "0px"}}>
//         <defs>
//           <linearGradient id={idCSS} gradientTransform={gradientTransform}>
//             <stop offset="0%" stopColor={startColor} />
//             <stop offset="100%" stopColor={endColor} />
//           </linearGradient>
//         </defs>
//       </svg>
//     );
// }




export default function DashboardPrograms({setIncubationState}) {
  const { user, setSessionExpire } = useAuth(); 
  // const navigate = useNavigate();
  const [program,setprogram]= useState([]);
  const [lessoncount,setlessoncount]= useState(0);
  const [assignmentcount,setassignmentcount]= useState(0);
  const [Assessmentcount,setAssessmentcount]= useState(0);

  const returnProgress = (val) => {
    return val ? 1 : 0;
}

const getReportProgress = () => {
    let getProgress = 0;
    program && program.session_logs && program.session_logs.length>0 ? 
    program.session_logs.map((data)=>
    getProgress = getProgress + returnProgress(data.is_completed)):null

    setlessoncount(getProgress);
}
const getAssignmentProgress = () => {
  let getProgress = 0;
  program && program.assignment_logs && program.assignment_logs.length>0 ? 
  program.assignment_logs.map((data)=>
  getProgress = getProgress + returnProgress(data.is_completed)):null

  setassignmentcount(getProgress);
}
const getAssessmentProgress = () => {
  let getProgress = 0;
  program && program.assessment_logs && program.assessment_logs.length>0 ? 
  program.assessment_logs.map((data)=>
  getProgress = getProgress + returnProgress(data.is_completed)):null

  setAssessmentcount(getProgress);
}
  

  const getDetails = async ()=>{
    axios({
      url: "/api/dashboard/course/user",
      method:"get"
    })
    .then((res)=>{
      setprogram(res.data.data);
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    });
  }

  useEffect(() => {
    getDetails()
  }, []);
  
  useEffect(() => {
    if(program)
    {
    getReportProgress()  
    getAssignmentProgress()
    getAssessmentProgress()
    }
  }, [program]);

  const getDiff = (date) => {
    // var dateTime = date + "T" + time;
    var scheduleTime = moment(date);
    var current = moment();
    // var ofset = moment().utcOffset() / 60;

    var dayDiff = scheduleTime.diff(current, "days");
    if (dayDiff > 1) {
      return Math.abs(dayDiff) + " days";
    } else if (dayDiff === 1) return Math.abs(dayDiff) + " day";
    else {
      var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
      //- parseFloat(timezone.split(" ", 3)[2]) +
      // ofset;
      if (hoursDiff >= 1) {
        let hoursDiffRound = Math.round(hoursDiff);
        return Math.abs(hoursDiffRound) == 1
          ? Math.abs(hoursDiffRound) + " hour"
          : Math.abs(hoursDiffRound) + " hours";
      } else if (hoursDiff < 0 && hoursDiff > 0) {
        return Math.abs(Math.round(hoursDiff * 60)) + " mins";
      } else return "expired";
    }
  };

  // useEffect(() => {
  //   console.log(program,"program");
  // });

  return (
    <div className="dashboard_program_box">
    <div className="d-flex justify-content-between align-items-center dashboard_program_boxhead">
      <h5 className="subtitle w_fit">{program && program.program_id?program.program_id.title:"No Program"}</h5>
      <Link to={`/dashboard/${user.type}?tab=7`} onClick={()=>{setIncubationState(2)}}>
        <p className="w_fit caption d-flex align-items-center" style={{color: "#262C5B"}}>
          Continue
          <svg style={{marginLeft:"12px",marginBottom:"0px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
    <div className="mb-2 dashboard_program_boxcontent">
      <p className="body2" style={{color:"#262C5B"}}>{program && program.course_id?program.course_id.title:"No Course Enrolled"}</p>
    <div className="row d-flex align-items-center" style={{marginTop: "21px",marginBottom: "38px"}}>
    <div className="col-12 col-md-2">
        <div className="d-flex flex-column align-items-center">
          <img style={{width:"15px",height:"15px",marginBottom:"12px"}} src={videoimg} />
          <span className="overline" style={{color: '#303030'}}>Lessons</span>
          <div className="body1" style={{color: '#989898'}}>
            <span style={{color: '#262C5B'}}>{lessoncount}</span>/{program && program.session_logs && program.session_logs.length>0 ? program.session_logs.length:0}
          </div>
        </div>
    </div>
    <div className="col-12 col-md-2">
      <div className="d-flex flex-column align-items-center">
          <img style={{width:"15px",height:"15px",marginBottom:"12px"}} src={assesmentsimg} />
          <span className="overline" style={{color: '#303030'}}>Assesments</span>
          <div className="body1" style={{color: '#989898'}}>
            <span style={{color: '#262C5B'}}>{Assessmentcount}</span>/{program && program.assessment_logs && program.assessment_logs.length>0 ? program.assessment_logs.length:0}
          </div>
        </div>
    </div>
    <div className="col-12 col-md-2">
      <div className="d-flex flex-column align-items-center">
          <img style={{width:"15px",height:"15px",marginBottom:"12px"}} src={assignmentimg} />
          <span className="overline" style={{color: '#303030'}}>Assigments</span>
          <div className="body1" style={{color: '#989898'}}>
            <span style={{color: '#262C5B'}}>{assignmentcount}</span>/{program && program.assignment_logs && program.assignment_logs && program.assignment_logs.length>0 ? program.assignment_logs.length:0}
          </div>
        </div>
    </div>
    <div className="col-12 col-md-3">
      <div className="d-flex flex-column align-items-center">
      <div style={{ width: 50, height: 50 }}>
        <CircularProgressbar 
        value={program && program.progress ? Math.floor(program.progress) : 0}   
        text={`${program && program.progress ? Math.floor(program.progress) : ''}%`}   
        // startColor="#F53939"
        // endColor="#FFC939"
        styles={buildStyles({
          // Text size
          textSize: '16px',
      
          // Colors
          pathColor: `#ff6f1e`,
          textColor: '#000',
          trailColor: '#d6d6d6',
          backgroundColor: '#3e98c7',
        })}
        />
        {/* <GradientSVG
        startColor="#F53939"
        endColor="#FFC939"
        idCSS="grad"
        rotation="60%"
        /> */}
      </div>
      <span className="overline" style={{color:"#989898"}}>Overall Progress</span>
      </div>
    </div>
    <div className="col-12 col-md-3">
     <div className="body1 d-flex flex-column align-items-center" style={{color: "#989898"}}>
          <span className="w-fit" style={{color: "#000"}}>{program && program.program_id && program.program_id.endDate ?getDiff(program.program_id.endDate):null}</span> 
        <span>{program && program.program_id && program.program_id.endDate && getDiff(program.program_id.endDate)!=="expired"?'Left':''}</span>
     </div>
    </div>
    </div>
    </div>
    </div>
  );
}
