import React from 'react'
import card from "./../../../../Assets/images/icons/card.png";
import { ShoppingCart, ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Card = (props) => {
    const { setAssetView, data } = props;
    const navigate = useNavigate();

    return (
        <div className="col-lg-3 col-md-4 col-12 mb-24">
            <div className="startup_assets_card_root point" onClick={() => {
                setAssetView(1);
                navigate(`/dashboard/startup?tab=9&cw=2&estate=${data._id}`)
            }}>
                <div className="startup_assets_card_main">
                    <div className="startup_assets_card_tag">
                        <p className='incubation_chips'>{data.real_estate_id}</p>
                    </div>
                    <div className="startup_assets_card_profile mt-16">
                        <img src="https://picsum.photos/40/40" alt="card-profile" />
                        <div className="s_asset_card_profile_name ml-12">
                            <p className="body1">{data.real_estate_name}</p>
                            <p className="overline color_dark_grey2 d-flex align-items-center">
                                <span>{data.category}</span>
                                <span className='startup_assets_ellipse'></span>
                                <span>{data.sub_category}</span>
                            </p>
                        </div>
                    </div>
                    <div className="startup_assets_card_rs_info mt-16">
                        <div className="startup_assets_card_rs">
                            <img src={card} alt="card" style={{ marginRight: 4 }} />
                            <p className="overline color_dark_blue">€ {data.price}</p>
                        </div>
                        <div className="startup_assets_card_rs">
                            <ShoppingCart />
                            <p className="overline color_dark_blue">{data.stock}</p>
                        </div>
                    </div>
                    <div className="startup_assets_card_incubator_info mt-24">
                        <div className="startup_assets_card_incubator_profile">
                            {
                                data.incubator_profile ? <>
                                    <img src={data.incubator_profile.logo} alt="profile" />
                                    <p className="body2 ml-8">{data.incubator_profile.name}</p>
                                </> : <>
                                    {
                                        data.created_by.avatar ?
                                            <img src={data.created_by.avatar} alt="profile" /> : <img src={`https://picsum.photos/40/40`} alt="profile" />
                                    }
                                    <p className="body2 ml-8">{data.created_by.firstname} {data.created_by.lastname}</p>
                                </>
                            }

                        </div>
                        <div className="startup_assets_card_inc_arrow">
                            <ArrowForward
                                style={{ color: "#6D7D8B" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="startup_assets_card_footer mt-16">
                    <p className="overline" style={{ color: "rgba(0, 0, 0, 0.75)" }}>{data.stock_booked} area booked until now</p>
                </div>
            </div>
        </div>
    )
}

export default Card