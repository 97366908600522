import React, { useEffect, useState } from "react";
import Card from "./eventscard/card";
import Axios from "axios";
import { useAuth } from "../../Utils/Auth";
import { EventPreviewSkeleton } from "../../Components/SkeletonLoader/EventSkeleton";

const Explore = () => {
  const [events, setEvents] = useState([]);
  const { setSessionExpire } = useAuth();
  const [isLoading,setIsLoading] = useState(false)
  // getting data
  useEffect(() => {
    setIsLoading(true)
    Axios({
      url: "/api/events/public",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setEvents(data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  }, []);

  return <div className="container mt-5">
    <div className="row">
      {
        isLoading ? <EventPreviewSkeleton/> : events.map((event, i) => {
        return (
          <Card
            key={i}
            thumbnail={event.thumbnail}
            name={event.hostCompany}
            title={event.title}
            description={event.description}
            eventFees={event.eventFees}
            date={event.eventTime.date}
            time={event.eventTime.time}
            timezone={event.eventTime.timezone}
            isFree={event.isFree}
            keywords={event.keywords}
            evenyKey={event.key}
            host={event.hostId}
            participants={event.participants}
          />
        );
      })}
    </div>
  </div>
};

export default Explore;
