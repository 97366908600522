import React,{useState,useEffect,useRef} from 'react';
import './InlineEdit.css';
import Axios  from 'axios';
import { toast } from 'react-toastify';
import Query from '../../../Utils/Query/Query';

export const InlineEditHead = ({ value, setValue,query }) => {
  const queryParams = Query();
const leadId = queryParams.get('lead');
    const [editingValue, setEditingValue] = useState(value);
    let pastValue = value;
     const onChange = (event) => setEditingValue(event.target.value);

     const onKeyDown = (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.target.blur();
      
      }
    }

    const onBlur = (event) => {
      if (event.target.value.trim() === "") {
        setEditingValue(value);
      } else {
        setValue(event.target.value)
        if(editingValue === pastValue){
          return;
        }else{
          if(query === 'title'){
            const data = {title:editingValue}
            Axios({
              method: "put",
              url: `/api/leadGeneration/lead/${leadId}`,
              data: data,
         })
              .then(() => {
                   toast.success(`Lead ${query} updated`, { position: "top-right" });
              })
              .catch(() => {
                   toast.error("Error while updating lead", { position: "top-right" });
              });
          }
          }
          
      }
    }

     return (
       <input
        className='inlineEditInputHead'
         type="text"
         aria-label="Field name"
         value={editingValue}
         onChange={onChange}
         onKeyDown={onKeyDown}
         onBlur={onBlur}
       />
     )
}


export const InlineEdit = ({ value, setValue,query }) => {
    let pastValue = value;
    const queryParams = Query();
    const leadId = queryParams.get('lead');
    const [editingValue, setEditingValue] = useState(value);
     const onChange = (event) => setEditingValue(event.target.value);

     const onKeyDown = (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.target.blur();
      }
    }

    const onBlur = (event) => {
      if (event.target.value.trim() === "") {
        setEditingValue(value);
      } else {
        setValue(event.target.value)
        if(editingValue === pastValue){
          return;
        }else{
          if(query === 'lead_email'){
            const data = {lead_email:editingValue}
            Axios({
              method: "put",
              url: `/api/leadGeneration/lead/${leadId}`,
              data: data,
         })
              .then(() => {
                   toast.success(`Lead ${query} updated`, { position: "top-right" });
              })
              .catch(() => {
                   toast.error("Error while updating lead", { position: "top-right" });
              });
          }
          }
      }
    }

     return (
       <input
        className='inlineEditInput'
         type="text"
         aria-label="Field name"
         value={editingValue}
         onChange={onChange}
         onKeyDown={onKeyDown}
         onBlur={onBlur}
       />
     )
}



export const MultilineEdit = ({value,setValue,query}) => {
  const queryParams = Query();
const leadId = queryParams.get('lead');
  const [editingValue, setEditingValue] = useState(value);
  let pastValue = value;
  const onChange = (event) => setEditingValue(event.target.value);

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value);
      if(editingValue === pastValue){
        return;
      }else{
        if(query === 'description'){
          const data = {description:editingValue}
          Axios({
            method: "put",
            url: `/api/leadGeneration/lead/${leadId}`,
            data: data,
       })
            .then(() => {
                 toast.success(`Lead ${query} updated`, { position: "top-right" });
            })
            .catch(() => {
                 toast.error("Error while updating lead", { position: "top-right" });
            });
        }
        }
    }
  };

  const onInput = (target) => {
    if (target.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight - 16 + "px";
    }
  };

  const textareaRef = useRef();

  useEffect(() => {
    onInput(textareaRef.current);
  }, [onInput, textareaRef]);

  return (
    <textarea
      rows={1}
      aria-label="Field name"
      value={editingValue}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onInput={(event) => onInput(event.target)}
      ref={textareaRef}
      className="inlineEditTextarea"
    />
  );
}