import React from 'react'
import HeaderComp from './HeaderComp'
import {ChevronRight} from "@mui/icons-material"
// import App_center from "../../../../../Assets/images/financial/App_center.png"
// import checkout from "../../../../../Assets/images/financial/checkout.png"
import Invoic_img from "../../../../../Assets/images/financial/Invoic_img.png"
import Req_pay from "../../../../../Assets/images/financial/Req_pay.png"
// import Send_pay from "../../../../../Assets/images/financial/Send_pay.png"
import transaction from "../../../../../Assets/images/financial/transaction.png"

const Analytics = () => {
    return <div className="incubator_insights_root">
        <div className="incubator_insights_head">
            <div className="d-flex justify-content-between align-items-center">
                <p className="title">Insights</p>
                <select name="days" className='incubator_insights_head_sct'>
                    <option value="past 7 days">Past 7 Days</option>
                    <option value="past 15 days">Past 15 Days</option>
                    <option value="past 30 days">Past 30 Days</option>
                </select>
            </div>
            <div className="incubator_insights_head_main mt-24 mb-40">
                <div className="row">
                    <HeaderComp 
                        heading="Money In" 
                        subHead="Total recieved"
                        money={5000}
                    />
                    <HeaderComp 
                        heading="Money Out"
                        subHead="Total sent"
                        money={0}
                    />
                    <HeaderComp 
                        heading="Money requested" 
                        subHead="Pending payments"
                        money={1000}
                    />
                </div>
            </div>
        </div>
        <div className="incubator_insights_main mt-40">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="incubator_insights_glance_root">
                        <p className="total">At a glance</p>
                        <div className="incubator_insights_glance_main mt-24 onboarding_cards3 p-24">
                            <div className="incubator_insights_glance_head">
                                <div className="row align-items-center">
                                    <div className="col-10 d-flex align-items-center justify-content-between">
                                        <p className="subtitle">Invoices and estimates</p>
                                        <select name="glance" className='glance_select incubator_insights_head_sct  color_dark_grey2'>
                                            <option value="Last 6 months">Last 6 months</option>
                                            <option value="Last 1 year">Last 1 year</option>
                                            <option value="Last 2 years">Last 2 years</option>
                                        </select>
                                    </div>
                                    <div className="col-2 p-0">
                                        <p className="caption">More <ChevronRight
                                            style={{
                                                height:16,
                                                width:16
                                            }}
                                        /></p>
                                    </div>
                                </div>
                            </div>
                            <div className="incubator_insights_glance_body mt-24">
                                <div className="incubator_insights_unpaid_invoice mb-24">
                                    <div className="incubator_insights_unpaid_">
                                        <select name="invoice" className='incuba_unpaid_invoice body2'>
                                            <option value="Unpaid Invoices">Unpaid Invoices (4)</option>
                                            <option value="Paid Invoices">Paid Invoices (4)</option>
                                        </select>
                                    </div>
                                    <div className="incubator_insights_c_inv mt-12 d-flex align-items-center">
                                        <p className="body2 color_slaty_dark pl-24">Create Invoice</p>
                                        <p className="body2 color_slaty_dark pl-40">Manage Invoice</p>
                                    </div>
                                </div>
                                <div className="incubator_insights_estimate_root">
                                    <div className="incubator_insights_estimate_main">
                                        <p className="body2">Send estimates to grow your business</p>
                                    </div>
                                    <div className="incubator_insights_estimate_ pl-24 mt-12">
                                        <p className="body2 color_slaty_dark">Create an Estimate</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="incubator_insights_glance_qLinks">
                        <p className="total">Quick links</p>
                        <div className="incubator_insights_quick_links mt-24">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="incubator_insights_appCard_root onboarding_cards3">
                                        <img src={Invoic_img} alt="Invoicing" />
                                        <p className="body1 mt-20">Invoicing</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="incubator_insights_appCard_root onboarding_cards3">
                                        <img src={Req_pay} alt="Request Payment" />
                                        <p className="body1 mt-20">Request Payment</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="incubator_insights_appCard_root onboarding_cards3">
                                        <img src={transaction} alt="Transactions" />
                                        <p className="body1 mt-20">Transactions</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Analytics