import React, { useState } from "react";
import Axios from "axios";
import "./index.css";
import { KeyboardArrowLeft } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import { useAuth } from "../../../Utils/Auth";

const ServiceDetails = ({ setstep, fetchservices }) => {
  const { setSessionExpire } = useAuth();
  const [servicename, setservicename] = useState("");
  const [keyword, setkeyword] = useState("");
  const [pricing, setpricing] = useState(0);
  const [customerSupport, setcustomerSupport] = useState("");
  const [benefits, setbenefits] = useState([]);
  const [benefit, setbenefit] = useState("");

  const addObjective = (arr, seto, v, setv) => {
    if (arr && v.length > 0) {
      seto((prev) => [...prev, v]);
      setv("");
    }
  };
  const removeObjective = (arr, seto, id) => {
    const newArr = arr.filter((obj, i) => {
      return id !== i;
    });
    seto(newArr);
  };
  const [formatedValue, setFormatedValue] = useState(pricing);

  function handleValuation(e) {
    setFormatedValue(e.target.value);
    let s = e.target.value;
    if (s) {
      var n = s.split("€", 2)[1];
      setpricing(Number(n.split(",").join("")));
    }
  }

  const hostservice = async () => {
    if (
      servicename.length === 0 ||
      keyword.length === 0 ||
      benefits.length === 0
    ) {
      return toast.error("Some fields are missing", {
        position: "bottom-right",
      });
    }

    const data = {
      servicename,
      keyword,
      benefits,
      pricing,
      customerSupport,
    };
    await Axios.post("/api/service/create", data)
      .then((res) => {
        if (res.data.success)
          toast.success("Service hosted successfully", {
            position: "bottom-right",
          });
        fetchservices();
        setstep(0);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.error(err.response.data.error, { position: "bottom-right" });
      });
  };

  return (
    <>
      <div
        className="back_CTA pointer"
        onClick={() => {
          setstep(0);
        }}
      >
        <p className="caption color_light_grey">
          <span>
            <KeyboardArrowLeft />
          </span>
          <span>BACK</span>
        </p>
      </div>
      <div className="create_course_root">
        <div className="create_course_form_main">
          <div className="mt-4">
            <h4>Basic Information</h4>
            <div className="create_course_form_main">
              <div className="row">
                <div className="col-12 create_course_form_maindiv">
                  <label
                    htmlFor="course_title"
                    className="caption color_dark_blue"
                  >
                    Keyword of service
                  </label>
                  <input
                    type="text"
                    className="body1"
                    placeholder="Enter keyword of service"
                    onChange={(e) => setkeyword(e.target.value)}
                    value={keyword}
                  />
                </div>
                <div className="col-12 create_course_form_maindiv">
                  <label
                    htmlFor="course_tag"
                    className="caption color_dark_blue"
                  >
                    Service Name
                  </label>
                  <input
                    type="text"
                    className="body1"
                    placeholder="Enter the name of your service"
                    onChange={(e) => setservicename(e.target.value)}
                    value={servicename}
                  />
                </div>
                <div className="col-12 create_course_form_maindiv">
                  <label
                    htmlFor="course_tag"
                    className="caption color_dark_blue"
                  >
                    Service Benefits
                  </label>
                  <div className="goalsdiv">
                    <input
                      type="text"
                      name="objective"
                      className="auth_input"
                      placeholder="List down the benefits or perks of your service"
                      id="objective"
                      value={benefit}
                      onChange={(e) => setbenefit(e.target.value)}
                    />
                    <Tooltip title="Add More Rows">
                      <button
                        type="button"
                        onClick={() =>
                          addObjective(
                            benefits,
                            setbenefits,
                            benefit,
                            setbenefit
                          )
                        }
                      >
                        <AddIcon />
                      </button>
                    </Tooltip>
                  </div>

                  <div className="newgoals">
                    {benefits
                      ? benefits.map((obj, i) => {
                          return (
                            <>
                              <span
                                className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3"
                                key={i}
                              >
                                <p className="program_text">{obj}</p>
                                <Tooltip title="Delete Row">
                                  <button
                                    type="button"
                                    title="Remove"
                                    onClick={() =>
                                      removeObjective(benefits, setbenefits, i)
                                    }
                                    className="program_btn"
                                  >
                                    x
                                  </button>
                                </Tooltip>
                              </span>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className="col-12 create_course_form_maindiv">
                  <label
                    htmlFor="course_tag"
                    className="caption color_dark_blue"
                  >
                    Pricing
                  </label>
                  <NumberFormat
                    placeholder="100"
                    displayType={"number"}
                    thousandSeparator={true}
                    prefix={"€ "}
                    className="some auth_input"
                    inputmode="numeric"
                    value={formatedValue}
                    onChange={(e) => handleValuation(e)}
                  />
                </div>
                <div className="col-12 create_course_form_maindiv">
                  <label
                    htmlFor="course_tag"
                    className="caption color_dark_blue"
                  >
                    Customer Support (optional)
                  </label>
                  <input
                    type="text"
                    className="body1"
                    placeholder="24/7 quick support"
                    onChange={(e) => setcustomerSupport(e.target.value)}
                    value={customerSupport}
                  />
                </div>
                <div className="col-12 create_course_form_maindivBtn">
                  <button
                    type="button"
                    onClick={() => hostservice()}
                    className="primaryFilled_activeCta"
                  >
                    HOST SERVICE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetails;
