import React from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Received from './Received/Received';
import Sent from './Sent/Sent';

const PayRequest = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return <Box className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs">
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="received" value="1" />
          <Tab label="sent" value="2" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <Received/>
      </TabPanel>
      <TabPanel value="2">
        <Sent/>
      </TabPanel>
    </TabContext>
  </Box>
}

export default PayRequest