/** @format */

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React, { useContext } from "react";
import { FormContext } from "../Context/FormContext";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import "./select.css";

const Select = ({ key, field_id, field_label, field_options, isDropDown }) => {
  const { handleChange } = useContext(FormContext);
  return (
    <>
      {isDropDown ? (
        <>
          <div className="mb-3 selectElement">
            <label>
              {key} {field_label}
            </label>
            <Form.Control
              as="select"
              custom
              onChange={(event) => handleChange(field_id, event)}
              className="selectFormControl_questionnare"
            >
              {field_options.map((option, index) => {
                return (
                  <option
                    key={index}
                    required={index === 0 ? true : ""}
                    className="MuiFormControlLabel-label optionFormControl_questionnare"
                    value={option.option_label}
                  >
                    {option.option_label}
                  </option>
                );
              })}
            </Form.Control>
          </div>
        </>
      ) : (
        <>
          <div className="mb-3 valuation_overlay">
            <FormControl component="fieldset">
              <label
                htmlFor={field_id}
                className="form-label valuation_input_label"
              >
                {field_label}
              </label>
              <RadioGroup
                name={field_id}
                onChange={(event) => handleChange(field_id, event)}
              >
                {field_options.length > 0 &&
                  field_options.map((option, i) => (
                    <FormControlLabel
                      value={option.option_label}
                      name={field_id}
                      key={i}
                      checked={option.checked}
                      control={<Radio 
                        sx={{
                          padding : "0px 5px",
                          color: "#FF6F1E",
                          '&.Mui-checked': {
                            color: "#FF6F1E",
                          },
                        }}
                        required={i === 0 ? "required" : ""} />}
                      label={option.option_label}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
        </>
      )}
    </>
  );
};

Select.propTypes = {
  field_id: PropTypes.number.isRequired,
  field_label: PropTypes.string.isRequired,
  field_placeholder: PropTypes.string.isRequired,
  field_value: PropTypes.any.isRequired,
  field_type: PropTypes.string.isRequired,
  key: PropTypes.number.isRequired,
  field_options: PropTypes.any.isRequired,
  isDropDown: PropTypes.bool.isRequired,
};

export default Select;
