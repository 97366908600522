import React from 'react'
import { Avatar } from "@mui/material";

const ConnectionCard = ({ profilepic, name, state, country, chip1, chip2, handleFunction }) => {
    return <div
        style={{ cursor: "pointer" }}
        className={`dashboard_01_connections_list_item`}
        onClick={handleFunction}
    >
        <div className="dashboard_01_connections_logo_list_block">
            <Avatar
                src={profilepic}
                alt="connection"
                className="dashboard_01_connections_logo"
            />
        </div>

        <div className="dashboard_01_connections_list_item_desc">
            {
                name ? <p className="p_spartan_20">
                    {name.length > 12
                        ? name.slice(0, 12) + "..."
                        : name}
                </p> : null
            }
            <p className=" no-margin font12 bold">
                {state},{" "}
                {country}
            </p>
            <div className="row justify-content-center">
                {
                    chip1 ? <p className="font12 p_grey_chip">
                        {chip1}
                    </p> : null
                }
                {
                    chip2 ? <p className="font12 p_grey_chip">
                        {chip2}
                    </p> : null
                }
            </div>
        </div>
    </div>
}

export default ConnectionCard