/** @format */

import { React, useContext } from "react";
import { FormContext } from "../Context/FormContext";
import PropTypes from "prop-types";
import "./input.css";

const Input = ({
  field_id,
  field_label,
  field_placeholder,
  field_value,
  field_type,
  key,
}) => {
  const { handleChange } = useContext(FormContext);
  //  handleChange()
  return (
    <div className="mb-3">
      <label htmlFor={field_id} className="form-label valuation_input_label">
        {key} {field_label}
      </label>
      <input
        type={field_type}
        className="valuation_input"
        id={field_id}
        required
        aria-describedby="emailHelp"
        placeholder={field_placeholder ? field_placeholder : ""}
        value={field_value}
        onChange={(event) => handleChange(field_id, event)}
      />
    </div>
  );
};

Input.propTypes = {
  field_id: PropTypes.number.isRequired,
  field_label: PropTypes.string.isRequired,
  field_placeholder: PropTypes.string.isRequired,
  field_value: PropTypes.any.isRequired,
  field_type: PropTypes.string.isRequired,
  key: PropTypes.number.isRequired,
};

export default Input;
