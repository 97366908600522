
import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export const  ConnectionExploreSkeleton = ()  =>{
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 8; index++){
               list.push( <div className="col-12 col-sm-6 col-md-4 col-lg-3 no-padding">
               <div style={{ cursor: "pointer" }} className={`dashboard_01_connections_list_item`}>
                    <div className="dashboard_01_connections_logo_list_block" style={{backgroundColor:"#D9D9D9"}}>
                    
                    <Skeleton animation="wave" variant="rounded" style={{
                         borderRadius: "20%",
                         textAlign: "center",
                         justifyContent: "center",
                         margin: "0 auto",
                         width: "64px",
                         height: "64px",
                    }}/>
                    
                    </div>

                    <div className="dashboard_01_connections_list_item_desc">
                         
                         <div className="d-flex flex-column align-items-center">
                         
                              <Skeleton animation="wave"  width="60%" height={40} />

                              <Skeleton animation="wave"  width="60%" height={20} />
                       
                              <Skeleton animation="wave"  width="60%" height={20} />
                              
                         </div>
                    </div>
                         
               </div>
          </div>)
          }
          return list;
     }
  return (
    
         <>
         {renderSkeleton()}
         </>
   
  )
}



export const ConnectionsSkeleton = () =>{
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 8; index++){
               list.push( <div className="col-lg-6 col-sm-6 col-xs-12 mb-3">
                  <div className="connection_request_item">
                        
                              <div className='col-1'>
                              <Skeleton variant="circular" width={40} height={40} />
                    
                              </div>
                              <div className='col-5'>
                              <Skeleton animation="wave"  width={150} height={40} />
                    
                              </div>
                              <div className='col-3'>
                              <Skeleton animation="wave"  width={60} height={40} />
                    
                              </div>
                              <div className='col-2'>
                              <Skeleton animation="wave"  width={40} height={40}  />
                    
                              </div>

                </div>
          </div>)
          }
          return list;
     }
  return (
    
         <>
         {renderSkeleton()}
         </>
   
  )
}