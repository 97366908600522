import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function JitsiMeetComponent() {
  let query = useQuery();
  const title = query.get("title");
  const host = query.get("host");

  const navigate = useNavigate();
  const { key } = useParams();
  const [loading, setLoading] = useState(false);
  const containerStyle = {
    // width: '800px',
    // height: '400px',
  };

  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
  };

  function startConference() {
    try {
      const domain = "meet.jit.si";
      const options = {
        roomName: title,
        height: 750,
        with: "100%",
        userInfo: {
          email: " ",
          displayName: "Display Name",
        },
        parentNode: document.getElementById("jitsi-container"),
        interfaceConfigOverwrite: {
          filmStripOnly: false,
          SHOW_JITSI_WATERMARK: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          HIDE_INVITE_MORE_HEADER: true,
          disableInviteFunctions: true,
        },
        configOverwrite: {
          disableSimulcast: false,
          enableClosePage: false,
          disableInviteFunctions: true,
        },
      };

      const api = new window.JitsiMeetExternalAPI(domain, options);
      api.addEventListener("videoConferenceJoined", () => {
        // console.log('Local User Joined');
        setLoading(false);
      });
      api.addEventListener("videoConferenceLeft", () => {
        navigate(`/service/events/${key}`);
      });
      if (host) {
        api.addEventListener("participantRoleChanged", function (event) {
          if (event.role === "moderator") {
            api.executeCommand("password", "länhkeitnt");
          }
        });
      } else {
        //      join a protected channel
        api.on("passwordRequired", function () {
          api.executeCommand("password", "länhkeitnt");
        });
      }
    } catch (error) {
      console.error("Failed to load Jitsi API", error);
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert("Jitsi Meet API script not loaded");
  }, []);

  return (
    <div style={containerStyle}>
      {loading && <CircularProgress />}
      <div id="jitsi-container" style={jitsiContainerStyle} />
    </div>
  );
}

export default JitsiMeetComponent;
