import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "axios";
// import { LinkedIn } from "@mui/icons-material";
import Feedback from "./FeedBack";
// import { useParams } from "react-router-dom";
// import { Rating } from "@mui/material";
import { ParticipantsDetails } from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";
const IndividualProfile = ({ setstep, studentId, user_id }) => {
  const [student, setstudent] = useState(null);
  // const [length, setLength] = useState(0);
  // const [ratingAvg, setRatingAvg] = useState(0)
  // const [paticipantFeed, setParticipantFeed] = useState(null);
  const { setSessionExpire } = useAuth();

  // const { programid } = useParams();

  // const getFeedback = async (userid) => {
  //   await Axios({
  //     method: "get",
  //     url: `/api/incubator-individual/ratings/${programid}/${userid}`,
  //   })
  //     .then((res) => {
  //       setParticipantFeed(res.data.data);
  //       const { data } = res.data;
  //       var professionalism = data.professionalism;
  //       setLength(
  //         professionalism.one +
  //         professionalism.two +
  //         professionalism.three +
  //         professionalism.four +
  //         professionalism.five
  //       );

  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.status === 401) {
  //         setSessionExpire(true);
  //       }
  //     });
  // };

  const getDetail = async () => {
    await Axios({
      method: "get",
      url: `/api/individual/individual-data/${studentId}`,
    })
      .then((res) => {
        setstudent(res.data.data);
        window.scrollTo(0, 0);
        // getFeedback(res.data.data.id);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  const [open, setopen] = React.useState(false);
  // const handleOpen = () => {
  //   setopen(true);
  // };
  const handleClose = () => {
    setopen(false);
  };

  if (student === null) return <ParticipantsDetails/>;

  return (
    <>
      <Feedback open={open} handleClose={handleClose} />
      <div className="col-12 participants_profile_head">
        <span
          className="cta color_light_grey"
          onClick={() => {
            setstep(0);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left mx-1"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Back
        </span>
      </div>
      <div className="col-12 row">
        <div className="col-12 col-lg-8">
          <div
            className="partipant_profile_main_left"
            id="connection_profile_root"
          >
            <div className="partipant_profile_main_left_head">
              <div className="dashboard01_connection_profile_head_img">
                <img src={student.profile_pic} alt="logo" />
                <div className="ml-12">
                  <p className="subtitle color_dark_blue">
                    {student?.name}
                  </p>
                  <p className="caption color_dark_grey2">
                    {student?.college_name} | {student?.latest_education}
                  </p>
                  {/* <p className="caption color_dark_grey2">
                                        student | {student.Location.country}
                                    </p> */}
                </div>
              </div>
              {/* {paticipantFeed && paticipantFeed.result && paticipantFeed.result.percent > 0 ? (
                <div className="participant_profile_right_rating_root">
                  <div className="participant_profile_right_rating_main">
                    <h5 className="color_dark_black2">{(paticipantFeed.result.percent).toFixed(2)}</h5>
                    <div className="incubator_aly_rating__">
                      <Rating
                        name="read-only"
                        value={(paticipantFeed.result.percent).toFixed(2)}
                        readOnly
                        className="incubator_aly_rating_read"
                      />
                    </div>
                  </div>
                </div>
              ) : null} */}
            </div>
            <div className="mt-40">
              <p className="body2 grey">{student?.company_name}</p>
            </div>

            <div className="mt-40">
              <p className="incubation_chips caption">{student?.job_role} </p>
            </div>
            <hr />
            {/* <div className="partipant_profile_main_review_main mt-40">
              <div className="partipant_profile_main_review_head mb-24">
                <p className="title">Ratings</p>
                <button
                  className="primaryFilled_activeCta"
                  onClick={handleOpen}
                >
                  REVIEW
                </button>
              </div>
              <div className="incubator_analytics_rating_progress w_100">
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Accessibility</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.percent.accessibility
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.accessibility >=
                          87 ? (
                        <p className="overline color_dark_grey2 pr-16 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.percent.accessibility < 87 ? (
                        <p className="overline color_dark_grey2 ml-8">
                          {length} members
                        </p>
                      ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Active participation</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.percent.active_participation
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.active_participation >=
                          87 ? (
                        <p className="overline color_dark_grey2 pr-16 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.percent.active_participation <
                        87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Professionalism</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.percent.professionalism
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.professionalism >=
                          87 ? (
                        <p className="overline color_dark_grey2 ml-8 pr-16">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.percent.professionalism <
                        87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main">
                  <p className="overline">Overall Experience</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.percent.overall_experience
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.percent.overall_experience >=
                          87 ? (
                        <p className="overline color_light_grey ml-8 pr-16">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.percent.overall_experience <
                        87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div
                            className="partipant_profile_main_fund"
                            style={{ marginTop: 130 }}
                        >
                            <p className="title">Funding to Raise</p>
                            <div className="partipant_profile_main_fund_INR d-flex">
                                <p className="INR">INR</p>
                                <p className="INR INR50" style={{ marginLeft: 26 }}>
                                    {student.fundraise}
                                </p>
                            </div>
                        </div> */}
          </div>
        </div>
        {/* <div className="col-12 col-lg-4">
                    {student.id ? (
                        <div className="participants_right_main_founder_root mb-24">
                            <div className="participants_right_main_founder_head mb-16">
                                <p className="title">Founder Details</p>
                            </div>
                            <div className="participants_right_main_founder_body">
                                <img src={student.profilePic} alt="profile" />
                                <div className="participants_right_main_right ml-16">
                                    <p className="subtitle">
                                        {student.id.firstname} {student.id.lastname}
                                    </p>
                                    <p className="caption grey">{student.contact.jobTitle}</p>
                                    <p>
                                        <a
                                            href={student.socialConnects.linkedin}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <LinkedIn style={{ fill: "#0077B5" }} />
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="connection_profile_root_highligts">
                        <div className="participants_profile_root_highligts_head">
                            <p className="subtitle">Highlights</p>
                        </div>
                        <div className="participants_profile_root_highligts_table_main">
                            <table className="participants_profile_root_highligts_table">
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Industry
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                        {student.industry}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Location
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {student.location.country}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Audience
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {student.audience}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Employees
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {student.employees}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Funding Status
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {student.fundingSeries}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Growth
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {student.growth}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Incorporation Date
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                        {new Date(student.incorporationDate).toLocaleDateString(
                                            "nl",
                                            { year: "numeric" }
                                        )}
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Website
                                    </td>
                                    <td
                                        className="body2 color_dark_grey2 participants_profile_root_highligts_td"
                                        style={{ wordBreak: "break-all" }}
                                    >
                                        <a
                                            href={`${student.website}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {student.website}
                                        </a>
                                    </td>
                                </tr>
                                <tr className="participants_profile_root_highligts_table_tr">
                                    <td className="body2 participants_profile_root_highligts_ftd">
                                        Contact
                                    </td>
                                    <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                        {student.contact.phone}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div> */}
      </div>
      <Feedback
        open={open}
        handleClose={handleClose}
        student={student}
        getDetail={getDetail}
        studentId={studentId}
        user_id={user_id}
      />
    </>
  );
}

export default IndividualProfile