import React from "react";
// import { AccessTime, AccountBalanceWallet } from "@mui/icons-material";
// import { AvatarGroup, Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";

const Card = (props) => {
  const { service, handleserviceid } = props;
  // const navigate = useNavigate();

  return (
    <div className="col-12 point mb-24">
      <div className="onboarding_cards incubation_courses_card_root2 d-flex">
        <div className="user_incubation_course_slide"></div>
        <div className="user_incubation_course_rightMain w_100">
          <div onClick={() => handleserviceid(service._id)} className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                <div className="user_incubation_course_rightHead">
                  <div className="user_incubation_course_rightTitle">
                    <h5>{service.keyword}</h5>
                  </div>
                </div>
                <div className="user_incubation_course_AvatarGroup_root">
                  <div className="user_incubation_course_AvatarGroups">
                    {/* <AvatarGroup
                      total={course.enrolled.length}
                      className="user_incubation_course_AvatarGroup"
                    >
                      {course.enrolled.slice(0, 4).map((data, i) => (
                        <Avatar key={i} src={data.user_id.avatar} />
                      ))}
                    </AvatarGroup> */}
                  </div>
                  <div className="user_incubation_course_startupEnrolled">
                    <p className="caption color_light_grey">
                      {new Date(service.createdAt).toDateString()}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="user_incubation_course_leftSide">
                <div className="user_course_desc pt-3">
                  <ul>
                    {service.services.map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
