import * as React from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { styled } from '@mui/material/styles';
import Axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useParams } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
};

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: 'linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)',
    },
    '& .MuiRating-iconHover': {
        color: 'linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)',
    },
});

let labels = ['Poor', 'Bad', 'Good', 'Best', 'Excellent'];
let mglabel = ['-5px', '4px', '5px', '2px', '4px'];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Feedback({ open, handleClose, submit }) {
    // const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(null);
    const [value1, setValue1] = React.useState(null);
    const [value2, setValue2] = React.useState(null);
    const query = useQuery();
    const module_id = query.get("module");
    const { courseid } = useParams()
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const handleOnSubmit = (e) => {
        e.preventDefault();
        Axios({
            method: "post",
            url: `/api/mentorModules/feedback/create/${courseid}/${module_id}`,
            data: {
                experience_rating: value2,
                instructor_rating: value1,
                content_rating: value
            }
        }).then(() => {
            toast.success("Feedback Submitted");
            handleClose();
            submit();
        })
            .catch((err) => {
                toast.error(err.response.data.error);
                handleClose()

            })
    };

    // 622d88e97629f6e367d8fe41

    return (
        <div>
            {/* <Button className="gradient_CTA" style={{borderRadius:" 30px"}} onClick={handleOpen}>Give Feedback</Button> */}
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="participant_feedback_head">
                        <div className="caption color_light_grey">How would you rate your experience on scale of 1 to 5</div>
                    </div>
                    <div className="participant_feadback_body">
                        <div className="row mb-40">
                            <div className="col-6 body1 color_light_black">Quality of content</div>
                            <StyledRating
                                name="customized-color"
                                value={value}
                                className="col-6"
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                                icon={<StarIcon fontSize="inherit" />}
                                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
                            />
                            <div className="col-6"></div>
                            <div className="col-6 d-flex">
                                {labels.map((val, index) => {
                                    return <span className="feedback-starlabel" key={index} style={{ marginLeft: `${mglabel[index]}` }}>{val}</span>
                                })}
                            </div>
                        </div>
                        <div className="row mb-40">
                            <div className="col-6 body1 color_light_black">Efficiency of instructor</div>
                            <StyledRating
                                name="customized-color"
                                value={value1}
                                className="col-6"
                                onChange={(event, newValue) => {
                                    setValue1(newValue);
                                }}
                                icon={<StarIcon fontSize="inherit" />}
                                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
                            />
                            <div className="col-6"></div>
                            <div className="col-6 d-flex">
                                {labels.map((val, index) => {
                                    return <span className="feedback-starlabel" key={index} style={{ marginLeft: `${mglabel[index]}` }}>{val}</span>
                                })}
                            </div>
                        </div>
                        <div className="row mb-40">
                            <div className="col-6 body1 color_light_black">Overall experience</div>
                            <StyledRating
                                name="customized-color"
                                value={value2}
                                className="col-6"
                                onChange={(event, newValue) => {
                                    setValue2(newValue);
                                }}
                                icon={<StarIcon fontSize="inherit" />}
                                emptyIcon={<StarOutlineIcon fontSize="inherit" />}
                            />
                            <div className="col-6"></div>
                            <div className="col-6 d-flex">
                                {labels.map((val, index) => {
                                    return <span className="feedback-starlabel" key={index} style={{ marginLeft: `${mglabel[index]}` }}>{val}</span>
                                })}
                            </div>
                        </div>                        <div className="d-flex justify-content-end">
                            <button onClick={(e) => handleOnSubmit(e)} className='primaryFilled_activeCta'>SUBMIT</button>
                        </div>
                    </div>
                    

                </Box>
            </Modal>
        </div>
    );
}

