import React, { useEffect, useState } from "react";

import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
import getDiff from "../../../Utils/diffleft/getDiff";
import { toast } from "react-toastify";

const AcceptedCard = ({ serviceid, setstartupid, setapplicantstep }) => {
  const { setSessionExpire } = useAuth();
  const [applicants, setapplicants] = useState([]);

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/service/applications/${serviceid}/true`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const removeaccept = (requestid) => {
    Axios({
      method: "put",
      url: `/api/service/removeaccept/${requestid}`,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Service request removed");
          fetchapplicants();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.err(err.response.data.error);
      });
  };
  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {applicants.map((v, i) => {
        return (
          <div className="col-12 incunation_shortlisted_main mb-4" key={i}>
            <div className="row">
              <div className="col-lg-3 col-md-3 col-4 d-flex align-items-center">
                <img
                  src={v.spid.avatar}
                  alt="profile_short"
                  className="mr-2"
                  height={"100px"}
                  width={"100px"}
                />
                <p className="p_Bold_secBlue18_spartan">{v.spid.name}</p>
              </div>
              <div className="col-lg-1 col-md-1 col-2 d-flex justify-content-center align-items-center">
                <p className="p_LightGrey12" style={{ color: "#565656" }}>
                  Service Provider
                </p>
              </div>

              <div className="col-lg-1 col-md-2 col-2 d-flex justify-content-center align-items-center">
                <p className="p_LightGrey12" style={{ color: "#565656" }}>
                  {getDiff(v.updatedAt)}
                </p>
              </div>
              <div
                onClick={() => {
                  removeaccept(v._id);
                }}
                className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <p className="transparntChip mr-2">REMOVE MEMBER</p>
              </div>
              <div
                onClick={() => {
                  setapplicantstep(1);
                  setstartupid(v.spid._id);
                }}
                style={{ cursor: "pointer" }}
                className="col-lg-2 col-md-2 col-3 d-flex justify-content-center align-items-center"
              >
                <p className="p_LightGrey12" style={{ color: "#565656" }}>
                  See Profile
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AcceptedCard;
