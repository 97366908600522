import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import logo from "../../Assets/images/logo/logoblack.png";
import "../dashboard.css";
import {  useNavigate } from "react-router-dom";
import Profile from "../Components/Profile/startup";
import embedLogo from "../../Assets/images/logo/TWF_emblem_black_400H.png";
import { useMediaQuery } from "react-responsive";
import { useLocation} from "react-router-dom";
import Connections from "../Components/Connections";
import DashboardNav from "../Components/DashboardNav";
import { useAuth } from "../../Utils/Auth";
import LeadGeneration from "../../Services/LeadGeneration";
import Events from "./../../Services/Events";
import EventsForm from "./../../Services/Events/host";
import Myevents from "./../../Services/Events/myevents";

import ConnectionProfile from "../Components/Connections/ConnectionProfile";
import Requests from "../Components/Connections/Requests";
import MyConnections from "../Components/Connections/MyConnections";
import Messages from "../Components/Messages";

import Valuation from "../Components/Valuation/Valuation";
import StarupHome from "./Home";
import Mentorships from "../../Services/Mentorships";
import ActiveMentorships from "../../Services/Mentorships/Activementorships";
import IncubationTool from "../Components/IncubationTool/IncubationTool";
import Feed from "../Components/Feed/Feed";
import ServiceProviderTool from "../../Services/StartupSPTool/LandingPage";
import Coworking from "../../Services/StartupProgram/StartupCoworking/Coworking";
import FInanceMain from "../../Services/StartupProgram/FinanceTool/FInanceMain";
import NotFoundPage from "../../Pages/404Pages";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function DashboardTabs() {
  const [reportBool, setReportState] = useState(false);
  const [connectionState, setconnectionState] = useState(0);
  const [eventState, seteventState] = useState(0);
  const [mentorState, setmentorState] = useState(0);
  const [incubationState, setIncubationState] = useState(0);
  const [valuationStep, setValuationStep] = useState(1);
  const [servicebool, setservicebool] = useState(0);
  const [ActiveStep, setActiveStep] = useState(0);
  const [coworkingBool, setCoWorkingBool] = useState(false);
  const [coworkingStep, setCoWorkingStep] = useState(1);
  const [finantialBool, setFinantialBool] = useState(false);
  const [finantialStep, setFinantialStep] = useState(1);
  // setActiveStep 
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  // const [eventOption,seteventOption] = useState(false);
  const [connectOption, setconnectOption] = useState(false);
  const { user, profile, isLoading, isVerified,setPageRedirect } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isTab = useMediaQuery({ query: "(max-width:768px)" });
  const query = useQuery();
  var currentStep = Number(query.get("tab"));
  var state = Number(query.get("event-state"));
  // var currentStep = Number(query.get("st"));
  var ms = Number(query.get("ms"));
  var vs = Number(query.get("vs"));
  var cs = Number(query.get("cs"));
  var es = Number(query.get("es"));
  var currentCWStep = Number(query.get("cw"));
  var currentFTStep = Number(query.get("ft"));
  var programState = Number(query.get("program-state"));

  useEffect(() => {
    if (programState) {
      setIncubationState(programState);
    }
  }, []);
  // var ps = Number(query.get("ps"));
  useState(() => {
    if (state) {
      seteventState(1);
    }
  }, []);
  useEffect(() => {
    if (currentStep) {
      setValue(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (vs) {
      setValuationStep(vs);
    }
  }, [vs]);
  useEffect(() => {
    if (ms) {
      setmentorState(ms);
    }
  }, [ms]);

  useEffect(() => {
    if (es) {
      seteventState(es);
    }
  }, [es]);
  useEffect(() => {
    if (cs) {
      setconnectionState(cs);
    }
  }, [cs]);

  useEffect(() => {
    if (currentStep) {
      setValue(currentStep);
    }
  }, [currentStep]);

  useEffect(() => {
    if (value == 1920 || value == 11) {
      setReportState(false);
      setconnectionState(0);
      seteventState(0);
      setmentorState(0);
      setIncubationState(0);
      setservicebool(0);
    }
  }, [value]);

  useEffect(() => {
    if (currentCWStep) {
      setCoWorkingStep(currentCWStep);
      setCoWorkingBool(true);
    }
  }, [currentCWStep]);

  useEffect(() => {
    if (currentFTStep) {
      setFinantialStep(currentFTStep);
      setFinantialBool(true);
    }
  }, [currentFTStep]);

  useEffect(() => {
    // redirection code
    console.log(isLoading ,profile ,isVerified,user)
    if (isLoading==false && user==null){
      const pid=window.location.href.split("&id=")[1]
      console.log(`/dashboard/startup?tab=7&id=${pid}`)
      setPageRedirect({status:true,link:`/dashboard/startup?tab=7&id=${pid}`})
      return navigate("/login")
    }
        // redirection code
    if (!isLoading && !profile && !isVerified) return navigate("/welcome");
    if (!isLoading && !isVerified) return navigate("/thankyou");
  }, [isLoading]);
  if (isLoading) {
    return <div></div>;
  } else if (user != null && user.type !== "startup") {
    return <NotFoundPage />;
  } else {
    return (
      <>
        {profile ? (
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: 224,
            }}
            className="dashboard01_main_box"
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className="dashboard01_tabs"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <div className="dashboard01_nav_head">
                {/* <Link to="/"> */}
                  <img
                    src={isTab ? embedLogo : logo}
                    className="dashboard_left_nav_logo"
                  />
                {/* </Link> */}
              </div>
              <Tab
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=1`);
                  setReportState(false);
                  setconnectionState(0);
                  seteventState(0);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-house-door-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
                      </svg>
                    </div>

                    <p>Home</p>
                  </div>
                }
                {...a11yProps(1)}
              />
              <Tab
              className="hidden"
                onClick={() => {
                  setconnectOption(!connectOption);
                  setconnectionState(1);
                  seteventState(0);
                  setReportState(false);
                  setmentorState(0);
                  navigate(`/dashboard/${user.type}?tab=2`);
                  setIncubationState(0);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                  setservicebool(0);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-people-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fillRule="evenodd"
                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                        />
                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                      </svg>
                    </div>
                    <p>Connections</p>
                  </div>
                }
                {...a11yProps(2)}
              />
              {connectionState ? (
                <div className="dashboard01_tab_Valuate">
                  <button
                    onClick={() => setconnectionState(1)}
                    className={`${
                      connectionState === 1 ? "reportSelected" : ""
                    }`}
                  >
                    Explore
                  </button>
                  <button
                    onClick={() => setconnectionState(2)}
                    className={`${
                      connectionState === 2 ? "reportSelected" : ""
                    }`}
                  >
                    My Network
                  </button>
                  <button
                    onClick={() => setconnectionState(3)}
                    className={`${
                      connectionState === 3 ? "reportSelected" : ""
                    }`}
                  >
                    Invitations{" "}
                  </button>
                </div>
              ) : null}

              <p className="tab_head">SERVICE PAGES</p>
              <Tab
              className="hidden"
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=4`);
                  setReportState(true);
                  setconnectionState(0);
                  seteventState(0);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                }}
                label={
                  <>
                    <div className="dashboard01_tab">
                      <div className="dashboard01_tab_svg">
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M13.375 7.5H9C8.60217 7.5 8.22064 7.34196 7.93934 7.06066C7.65803 6.77935 7.5 6.39782 7.5 6V1.625C7.5 1.59185 7.48683 1.56005 7.46339 1.53661C7.43995 1.51317 7.40815 1.5 7.375 1.5H4.5C3.96957 1.5 3.46086 1.71071 3.08579 2.08579C2.71071 2.46086 2.5 2.96957 2.5 3.5V13.5C2.5 14.0304 2.71071 14.5391 3.08579 14.9142C3.46086 15.2893 3.96957 15.5 4.5 15.5H11.5C12.0304 15.5 12.5391 15.2893 12.9142 14.9142C13.2893 14.5391 13.5 14.0304 13.5 13.5V7.625C13.5 7.59185 13.4868 7.56005 13.4634 7.53661C13.4399 7.51317 13.4082 7.5 13.375 7.5Z" />
                          <path d="M13.1006 6.39456L8.60656 1.9005C8.59782 1.89181 8.58671 1.8859 8.57462 1.88351C8.56253 1.88112 8.55001 1.88236 8.53862 1.88707C8.52723 1.89178 8.51749 1.89975 8.51062 1.90998C8.50375 1.92021 8.50005 1.93224 8.5 1.94456V6.00113C8.5 6.13373 8.55268 6.26091 8.64645 6.35468C8.74021 6.44845 8.86739 6.50113 9 6.50113H13.0566C13.0689 6.50107 13.0809 6.49738 13.0911 6.49051C13.1014 6.48364 13.1093 6.4739 13.1141 6.46251C13.1188 6.45112 13.12 6.43859 13.1176 6.4265C13.1152 6.41442 13.1093 6.4033 13.1006 6.39456Z" />
                        </svg>
                      </div>
                      <p>Valuation</p>
                    </div>
                  </>
                }
                {...a11yProps(4)}
              />
              {reportBool ? (
                <div className="dashboard01_tab_Valuate">
                  <button
                    onClick={() => setValuationStep(1)}
                    className={`${valuationStep === 1 ? "reportSelected" : ""}`}
                  >
                    {" "}
                    View Reports{" "}
                  </button>
                  <button
                    onClick={() => setValuationStep(2)}
                    className={`${
                      valuationStep === 2 || valuationStep === 3
                        ? "reportSelected"
                        : ""
                    }`}
                  >
                    {" "}
                    Create Report{" "}
                  </button>
                </div>
              ) : null}

              <Tab
                onClick={() => {
                  setconnectionState(0);
                  seteventState(0);
                  setmentorState(1);
                  setReportState(false);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                  navigate(`/dashboard/${user.type}?tab=5`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1.739 7.93778L8.37088 7.93838C8.67499 7.93844 8.96784 8.0534 9.19077 8.26023C9.41371 8.46706 9.55026 8.7505 9.57306 9.05374L9.57668 9.14418V10.6508C9.57608 13.183 6.99206 13.9668 5.05494 13.9668C3.15641 13.9668 0.636299 13.2143 0.536218 10.8003L0.533203 10.6508V9.14358C0.533203 8.50812 1.02517 7.98722 1.64857 7.9414L1.739 7.93778ZM9.70932 7.93838L13.797 7.93778C14.4324 7.93778 14.9533 8.42974 14.9992 9.05314L15.0028 9.14358V10.3494C15.0022 12.6157 12.9348 13.3639 11.3854 13.3639C10.7475 13.3639 10.0234 13.2342 9.39039 12.9268C9.84377 12.3878 10.138 11.7017 10.1754 10.8383L10.1796 10.6508V9.14418C10.1796 8.72094 10.0295 8.33629 9.78528 8.02882L9.70932 7.93778H13.797L9.70932 7.93838ZM5.05494 0.100098C5.49044 0.100137 5.92166 0.185954 6.32399 0.352647C6.72632 0.51934 7.09188 0.763646 7.39979 1.07162C7.70771 1.37959 7.95195 1.74519 8.11857 2.14755C8.28519 2.54991 8.37092 2.98115 8.37088 3.41664C8.37084 3.85214 8.28503 4.28336 8.11833 4.68569C7.95164 5.08802 7.70734 5.45358 7.39937 5.76149C7.0914 6.06941 6.72579 6.31365 6.32343 6.48027C5.92107 6.64689 5.48983 6.73262 5.05434 6.73258C4.17482 6.7325 3.33135 6.38304 2.70949 5.76107C2.08763 5.13909 1.73832 4.29556 1.7384 3.41604C1.73848 2.53652 2.08794 1.69305 2.70992 1.07119C3.33189 0.44933 4.17542 0.100018 5.05494 0.100098ZM11.6868 1.30589C12.0431 1.30589 12.3959 1.37607 12.7251 1.51241C13.0542 1.64876 13.3533 1.8486 13.6052 2.10053C13.8572 2.35246 14.057 2.65154 14.1934 2.9807C14.3297 3.30986 14.3999 3.66266 14.3999 4.01894C14.3999 4.37522 14.3297 4.72801 14.1934 5.05718C14.057 5.38634 13.8572 5.68542 13.6052 5.93735C13.3533 6.18928 13.0542 6.38912 12.7251 6.52546C12.3959 6.66181 12.0431 6.73198 11.6868 6.73198C10.9673 6.73198 10.2772 6.44614 9.76841 5.93735C9.25962 5.42856 8.97378 4.73848 8.97378 4.01894C8.97378 3.29939 9.25962 2.60932 9.76841 2.10053C10.2772 1.59173 10.9673 1.30589 11.6868 1.30589Z" />
                      </svg>
                    </div>
                    <p>Mentorships</p>
                  </div>
                }
                {...a11yProps(5)}
              />
              {mentorState ? (
                <div className="dashboard01_tab_Valuate">
                  <button
                    onClick={() => setmentorState(1)}
                    className={`${mentorState === 1 ? "reportSelected" : ""}`}
                  >
                    {" "}
                    Explore Mentors{" "}
                  </button>
                  <button
                    onClick={() => setmentorState(2)}
                    className={`${mentorState === 2 ? "reportSelected" : ""}`}
                  >
                    {" "}
                    My Mentorships{" "}
                  </button>
                </div>
              ) : null}
              <Tab
              className="hidden"
                onClick={() => {
                  seteventState(1);
                  setconnectionState(0);
                  setReportState(false);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                  navigate(`/dashboard/${user.type}?tab=6`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        width="16"
                        fill="currentColor"
                        height="16"
                        viewBox="0 0 18 18"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 6.79976C10.4244 6.79976 10.8313 6.96832 11.1314 7.26836C11.4314 7.56841 11.6 7.97535 11.6 8.39968V11.5995C11.6 12.0238 11.4314 12.4308 11.1314 12.7308C10.8313 13.0309 10.4244 13.1994 10 13.1994H7.6C7.17566 13.1994 6.76869 13.0309 6.46863 12.7308C6.16858 12.4308 6.00001 12.0238 6.00001 11.5995V8.39968C6.00001 7.97535 6.16858 7.56841 6.46863 7.26836C6.76869 6.96832 7.17566 6.79976 7.6 6.79976H10ZM12.4 10.9292V9.07004L13.7872 7.75651C13.8724 7.67577 13.9793 7.62178 14.0949 7.60122C14.2104 7.58067 14.3295 7.59444 14.4372 7.64084C14.545 7.68724 14.6369 7.76423 14.7013 7.86228C14.7658 7.96033 14.8001 8.07514 14.8 8.19249V11.8067C14.8001 11.9241 14.7658 12.0389 14.7013 12.1369C14.6369 12.235 14.545 12.312 14.4372 12.3584C14.3295 12.4048 14.2104 12.4185 14.0949 12.398C13.9793 12.3774 13.8724 12.3234 13.7872 12.2427L12.4 10.9292V10.9292ZM18 9.9996C18 5.58142 14.4184 2 10 2C5.58161 2 2.00001 5.58142 2.00001 9.9996C1.99834 11.2779 2.30429 12.5378 2.89201 13.673L2.03761 16.7313C1.98995 16.902 1.98855 17.0824 2.03355 17.2539C2.07855 17.4253 2.16833 17.5818 2.29369 17.7071C2.41906 17.8325 2.5755 17.9223 2.74699 17.9673C2.91847 18.0123 3.09884 18.0109 3.26961 17.9632L6.33041 17.1096C7.46466 17.6959 8.7232 18.001 10 17.9992C14.4184 17.9992 18 14.4178 18 9.9996ZM3.20001 9.9996C3.20044 8.50745 3.69171 7.05687 4.59807 5.87151C5.50444 4.68614 6.77557 3.83181 8.21549 3.44023C9.65542 3.04865 11.1842 3.14156 12.5661 3.70464C13.948 4.26772 15.1063 5.2697 15.8624 6.55611C16.6185 7.84251 16.9305 9.34191 16.7502 10.8231C16.5699 12.3043 15.9073 13.6851 14.8647 14.7526C13.8221 15.8201 12.4572 16.515 10.9806 16.7302C9.50396 16.9453 7.99755 16.6688 6.69361 15.9433L6.47761 15.8233L3.28881 16.7121L4.17921 13.525L4.05841 13.309C3.49367 12.2976 3.1981 11.158 3.20001 9.9996V9.9996Z" />
                      </svg>
                    </div>
                    <p>Events</p>
                  </div>
                }
                {...a11yProps(6)}
              />
              {eventState ? (
                <div className="dashboard01_tab_Valuate">
                  <button
                    onClick={() => seteventState(1)}
                    className={`${eventState === 1 ? "reportSelected" : ""}`}
                  >
                    Explore
                  </button>
                  <button
                    onClick={() => seteventState(2)}
                    className={`${eventState === 2 ? "reportSelected" : ""}`}
                  >
                    My Events
                  </button>
                  <button
                    onClick={() => seteventState(3)}
                    className={`${eventState === 3 ? "reportSelected" : ""}`}
                  >
                    Create Event{" "}
                  </button>
                </div>
              ) : null}
              <Tab
                onClick={() => {
                  seteventState(0);
                  setconnectionState(0);
                  setReportState(false);
                  setmentorState(0);
                  setservicebool(0);
                  setIncubationState(1);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                  navigate(`/dashboard/${user.type}?tab=7`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 11.7782C0 11.6309 0.0585315 11.4896 0.162718 11.3854C0.266905 11.2812 0.408212 11.2227 0.555554 11.2227H2.22222C2.36956 11.2227 2.51087 11.2812 2.61505 11.3854C2.71924 11.4896 2.77777 11.6309 2.77777 11.7782V17.8871C2.77777 18.0344 2.71924 18.1757 2.61505 18.2799C2.51087 18.3841 2.36956 18.4426 2.22222 18.4426H0.555554C0.408212 18.4426 0.266905 18.3841 0.162718 18.2799C0.0585315 18.1757 0 18.0344 0 17.8871V11.7782ZM8.28609 11.3277C8.28609 11.3277 7.02054 11.0915 6.50276 11.3277C6.11721 11.5032 4.62499 12.0388 3.88888 12.301V17.2232C4.31499 17.1987 5.16276 17.1538 5.52499 17.1682C7.61165 17.2521 8.67664 18.1315 10.7594 18.2893C11.9244 18.3776 12.945 18.5837 13.7505 18.2893C14.5561 17.9943 19.1572 15.7526 19.7322 15.1626C20.3077 14.5726 20.0199 12.9793 18.2366 13.2743C16.4533 13.5693 14.0955 14.9854 13.1177 14.9265C12.14 14.8676 9.55109 14.3954 9.55109 14.3954L12.5233 14.4315C12.5233 14.4315 12.9433 14.4538 13.52 14.0415C14.0977 13.6293 14.6133 12.4488 13.7505 12.4488C12.8877 12.4488 11.9672 12.1532 11.9672 12.1532L8.28609 11.3277Z"
                          fill=""
                        />
                        <path
                          d="M12.2667 7.74074V7.22222H8.53867L8.53333 9.2963C8.53333 9.87185 9.008 10.3333 9.6 10.3333H17.0667C17.6587 10.3333 18.1333 9.87185 18.1333 9.2963V7.22222H14.4V7.74074H12.2667ZM17.6 3.07407H15.4613V2.03704L14.3947 1H12.2613L11.1947 2.03704V3.07407H9.06667C8.48 3.07407 8 3.54074 8 4.11111V5.66667C8 6.24222 8.47467 6.7037 9.06667 6.7037H12.2667V5.66667H14.4V6.7037H17.6C18.1867 6.7037 18.6667 6.23704 18.6667 5.66667V4.11111C18.6667 3.54074 18.1867 3.07407 17.6 3.07407ZM14.4 3.07407H12.2667V2.03704H14.4V3.07407Z"
                          fill=""
                        />
                      </svg>
                    </div>
                    <p>Incubator Programs</p>
                  </div>
                }
                {...a11yProps(7)}
              />
              {incubationState ? (
                <div className="dashboard01_tab_Valuate">
                  <button
                    onClick={() => {
                      setIncubationState(1);
                      navigate(`/dashboard/${user.type}?tab=7`);
                    }}
                    className={`${
                      incubationState === 1 ? "reportSelected" : ""
                    }`}
                  >
                    View Programs
                  </button>
                  <button
                    onClick={() => {
                      setIncubationState(2)
                      navigate(`/dashboard/${user.type}?tab=7`);
                    }
                    
                    }
                    className={`${
                      incubationState === 2 ? "reportSelected" : ""
                    }`}
                  >
                    My Programs
                  </button>
                </div>
              ) : null}
              <Tab
              className="hidden"
                onClick={() => {
                  seteventState(0);
                  setconnectionState(0);
                  setReportState(false);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(1);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                  navigate(`/dashboard/${user.type}?tab=8`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5 17.2503L3.75 17.2517C4.37402 17.2517 4.875 16.7503 4.875 16.128V8.28317H1.5V17.2503ZM3.1875 15.012C3.49512 15.012 3.75 15.2617 3.75 15.573C3.75 15.8797 3.49512 16.1339 3.1875 16.1339C2.87988 16.1339 2.625 15.8785 2.625 15.5733C2.625 15.2611 2.87988 15.012 3.1875 15.012ZM13.7625 6.02736C13.4833 6.02736 13.212 6.13152 13.0059 6.31953L9.54727 9.47597C9.54375 9.48299 9.54375 9.49001 9.53672 9.49001C8.95313 10.0373 8.96367 10.9108 9.46289 11.4546C9.91113 11.9142 10.8473 12.0728 11.4362 11.5511C11.4422 11.5458 11.4457 11.5458 11.4492 11.5423L14.2575 8.9778C14.486 8.77183 14.8464 8.78499 15.0527 9.01289C15.2637 9.24078 15.2461 9.59596 15.0176 9.80645L14.0993 10.6439L19.2188 14.7875C19.3198 14.875 19.4121 14.9627 19.4956 15.0591V8.24107C18.0549 6.80409 16.1037 6 14.0675 6L13.7625 6.02736ZM13.2633 11.409L12.2086 12.3734C11.1627 13.3245 9.56309 13.2326 8.62969 12.2201C7.6875 11.1915 7.76133 9.59525 8.78789 8.65504L11.6637 6.02736H11.4316C9.39961 6.02736 7.44492 6.83707 6.00703 8.26914L6 16.1241L6.6416 16.1254L9.82324 18.9959C10.79 19.7807 12.2051 19.6316 12.9873 18.6715L13.6243 19.2061C14.1826 19.6306 15.0088 19.5745 15.4658 19.0177L16.569 17.6635L16.758 17.8213C17.2414 18.2072 17.949 18.1371 18.34 17.6548L18.6753 17.2415C19.0662 16.7591 18.993 16.0564 18.5103 15.6652L13.2633 11.409ZM20.625 8.28668V16.1311C20.625 16.7493 21.126 17.2555 21.7184 17.2555L24 17.2538V8.27615L20.625 8.28668ZM22.3125 16.1311C22.0049 16.1311 21.75 15.8769 21.75 15.5702C21.75 15.2588 22.0049 15.0092 22.3125 15.0092C22.6201 15.0092 22.875 15.2611 22.875 15.5733C22.875 15.8785 22.6219 16.1311 22.3125 16.1311Z"
                          fill=""
                        />
                      </svg>
                    </div>
                    <p>Outreach</p>
                  </div>
                }
                {...a11yProps(8)}
              />
              {servicebool ? (
                <div className="dashboard01_tab_Valuate">
                  <button
                    onClick={() => setservicebool(1)}
                    className={`${servicebool === 1 ? "reportSelected" : ""}`}
                  >
                    Active Outreach
                  </button>
                  <button
                    onClick={() => setservicebool(2)}
                    className={`${servicebool === 2 ? "reportSelected" : ""}`}
                  >
                    My Services Requests
                  </button>
                  <button
                    onClick={() => setservicebool(3)}
                    className={`${servicebool === 3 ? "reportSelected" : ""}`}
                  >
                    Explore Providers
                  </button>

                  {/* <button onClick={() => setIncubationState(3)} className={`${incubationState === 3 ? "reportSelected" : ""}`} >View Programs </button> */}
                  {/* <button onClick={() => setIncubationState(4)} className={`${incubationState === 4 ? "reportSelected" : ""}`} >Announcements </button> */}
                </div>
              ) : null}

              <Tab
                onClick={() => {
                  seteventState(0);
                  setconnectionState(0);
                  setReportState(false);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(!coworkingBool);
                  setCoWorkingStep(1);
                  setFinantialBool(false);
                  navigate(`/dashboard/${user.type}?tab=9&cw=1`);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill=""
                          d="M16.764 16.086c.55 0 .957-.428.957-.957a.943.943 0 00-.957-.957.97.97 0 00-.957.957c0 .529.45.957.957.957zM16.551 16.469c-.39 0-.763.107-1.085.301-.276.097-.845.835-.804.874l-.868 1.615c-.15.28-.067.627.232.777.28.151.627.046.777-.232l.622-1.194v.921c0 .424.363.766.765.766h1.148a.751.751 0 00.765-.766v-.92l.665 1.193a.574.574 0 001.009-.545l-.904-1.682a2.109 2.109 0 00-1.853-1.108h-.469z"
                        ></path>
                        <path
                          fill=""
                          d="M10.313 13.134h-2.25a.563.563 0 01-.563-.563v-2.252a.563.563 0 01.563-.563h2.25a.562.562 0 01.562.563v1.306c.39-.453.95-.743 1.575-.743h4.814l.234-.265a.582.582 0 00.127-.334.574.574 0 00-.167-.373l-2.083-1.843V4.689a.563.563 0 00-.563-.563h-1.124a.562.562 0 00-.563.563v1.383l-3.111-2.758A1.433 1.433 0 009.189 3c-.26 0-.63.14-.825.314L.918 9.91a.568.568 0 00-.168.373c.006.12.05.236.125.33l.66.75c.1.1.234.16.375.168a.58.58 0 00.329-.123L3 10.737v5.774a1.127 1.127 0 001.125 1.126h6.188v-4.503zm12.562 5.63h-1.313v-5.63c0-.622-.453-1.126-1.012-1.126h-8.1c-.56 0-1.012.504-1.012 1.126v5.63h-1.313a.375.375 0 00-.375.375v.375a1.507 1.507 0 001.498 1.501h10.504a1.505 1.505 0 001.498-1.501v-.375a.375.375 0 00-.375-.376zm-3 0h-6.75v-5.067h6.75v5.066z"
                        ></path>
                      </svg>
                    </div>
                    <p>Co-working</p>
                  </div>
                }
                {...a11yProps(9)}
              />
              {coworkingBool ? (
                <div className="event_dashboard_02">
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      coworkingStep === 1
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setCoWorkingStep(1);
                      navigate(`/dashboard/${user.type}?tab=9&cw=1`);
                    }}
                  >
                    My Leases
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      coworkingStep === 2
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setCoWorkingStep(2);
                      navigate(`/dashboard/${user.type}?tab=9&cw=2`);
                    }}
                  >
                    View Real Estate
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      coworkingStep === 3
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setCoWorkingStep(3);
                      navigate(`/dashboard/${user.type}?tab=9&cw=3`);
                    }}
                  >
                    Real Estate Requests
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      coworkingStep === 4
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setCoWorkingStep(4);
                      navigate(`/dashboard/${user.type}?tab=9&cw=4`);
                    }}
                  >
                    View Assets
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      coworkingStep === 5
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setCoWorkingStep(5);
                      navigate(`/dashboard/${user.type}?tab=9&cw=5`);
                    }}
                  >
                    Asset Requests
                  </button>
                </div>
              ) : null}

              <Tab
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=10&ft=1`);
                  seteventState(0);
                  setconnectionState(0);
                  setReportState(false);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(false);
                  setFinantialBool(!finantialBool);
                  setFinantialStep(1);
                }}
                label={
                  <div className="dashboard01_tab ">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.375 2.176c.235-.235.615-.235.828 0l1.6 1.6A.54.54 0 0116 4.2a.47.47 0 01-.197.402l-1.6 1.6c-.213.255-.593.255-.828 0-.232-.212-.232-.592 0-.827l.578-.598H9.6c-.332 0-.6-.247-.6-.577a.6.6 0 01.6-.6l4.353-.022-.578-.554a.603.603 0 010-.848z"></path>
                        <path d="M2.603 11.4l-.554.598 4.329-.023c.354 0 .6.29.6.6 0 .353-.245.6-.6.6l-4.33.023.554.575c.256.235.256.615 0 .827-.212.255-.592.255-.826 0L.176 13C.063 12.91 0 12.758 0 12.575c0-.137.063-.29.176-.402l1.6-1.6c.234-.233.614-.233.827 0 .254.235.254.615 0 .827z"></path>
                        <path
                          d="M8.447 3.602h-6.07c-.86 0-1.6.716-1.6 1.6v5.362l.504-.482a1.301 1.301 0 011.984.172c.362.235.622.61.707 1.048l2.405-.023c.74 0 1.3.605 1.3 1.3 0 .24-.03.443-.125.623H13.6c.882 0 1.6-.718 1.6-1.6V6.239l-.48.483a1.302 1.302 0 01-1.985-.173 1.6 1.6 0 01-.708-1.07H9.6c-.718 0-1.3-.56-1.3-1.3 0-.194.052-.398.147-.577zm-4.47 1.6c0 .882-.695 1.6-1.6 1.6v-1.6h1.6zm9.623 4.8v1.6H12c0-.883.717-1.6 1.6-1.6zm-3.2-1.6c0 1.325-1.075 2.4-2.423 2.4a2.4 2.4 0 01-2.4-2.4c0-1.348 1.075-2.4 2.4-2.4 1.348 0 2.423 1.052 2.423 2.4z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <p> Finance </p>
                  </div>
                }
                {...a11yProps(10)}
              />
              {finantialBool ? (
                <div className="event_dashboard_02">
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      finantialStep === 1
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setFinantialStep(1);
                      navigate(`/dashboard/${user.type}?tab=10&ft=1`);
                    }}
                  >
                    Insights
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      finantialStep === 2
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setFinantialStep(2);
                      navigate(`/dashboard/${user.type}?tab=10&ft=2&in=0`);
                    }}
                  >
                    Invoicing
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      finantialStep === 3
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setFinantialStep(3);
                      navigate(`/dashboard/${user.type}?tab=10&ft=3`);
                    }}
                  >
                    Payment Requests
                  </button>
                  <button
                    className={`event_dashboard_btn_01 p_LightGrey14 ${
                      finantialStep === 4
                        ? "event_dashboard_btn_01_selected"
                        : ""
                    }`}
                    onClick={() => {
                      setFinantialStep(4);
                      navigate(`/dashboard/${user.type}?tab=10&ft=4`);
                    }}
                  >
                    Transactions
                  </button>
                </div>
              ) : null}
              <Tab
              className="hidden"
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=11`);
                  seteventState(0);
                  setconnectionState(0);
                  setReportState(false);
                  setmentorState(0);
                  setIncubationState(0);
                  setservicebool(0);
                  setCoWorkingBool(0);
                  setCoWorkingStep(0);
                  setFinantialBool(false);
                }}
                label={
                  <div className="dashboard01_tab ">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="14"
                        fill="none"
                        viewBox="0 0 11 14"
                      >
                        <path d="M3.929 1.5c0-.828.527-1.5 1.178-1.5h.786c.65 0 1.178.672 1.178 1.5v11c0 .828-.527 1.5-1.178 1.5h-.786c-.65 0-1.178-.672-1.178-1.5v-11zM0 7.5C0 6.672.528 6 1.179 6h.785c.651 0 1.179.672 1.179 1.5v5c0 .828-.528 1.5-1.179 1.5H1.18C.528 14 0 13.328 0 12.5v-5zM9.036 2h.785C10.472 2 11 2.672 11 3.5v9c0 .828-.528 1.5-1.179 1.5h-.785c-.651 0-1.179-.672-1.179-1.5v-9c0-.828.528-1.5 1.179-1.5z"></path>
                      </svg>
                    </div>
                    <p> Lead Generation</p>
                  </div>
                }
                {...a11yProps(11)}
              />
              <p className="tab_head">ACCOUNT PAGES</p>
              <Tab
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=13`);
                  setReportState(false);
                  setconnectionState(0);
                  setIncubationState(0);
                  setCoWorkingBool(false);
                  setFinantialBool(false);
                }}
                label={
                  <div className="dashboard01_tab">
                    <div className="dashboard01_tab_svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-person-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </div>
                    <p>Profile</p>
                  </div>
                }
                {...a11yProps(13)}
              />
            </Tabs>
            <DashboardNav />
            <TabPanel value={value} className="dashboard01_tabpanel" index={1}>
              <StarupHome
                seteventState={seteventState}
                setReportState={setReportState}
                setconnectionState={setconnectionState}
                setmentorState={setmentorState}
                setValuationStep={setValuationStep}
                setActiveStep={setActiveStep}
                setIncubationState={setIncubationState}
              />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={2}>
              {connectionState === 1 ? (
                <Connections />
              ) : connectionState === 2 ? (
                <MyConnections />
              ) : connectionState === 3 ? (
                <Requests />
              ) : null}
            </TabPanel>

            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={3}
            ></TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={4}>
              <Valuation
                valuationStep={valuationStep}
                setValuationStep={setValuationStep}
                ActiveStep={ActiveStep}
                setActiveStep={setActiveStep}
              />
              {/* {reportState === 1 ? (
                <Valuation />
              ) : reportState === 2 ? (
                <LetStart />
              ) : null} */}
            </TabPanel>

            <TabPanel value={value} className="dashboard01_tabpanel" index={5}>
              {mentorState === 1 ? (
                <Mentorships setmentorState={setmentorState} />
              ) : mentorState === 2 ? (
                <ActiveMentorships />
              ) : null}
            </TabPanel>

            <TabPanel value={value} className="dashboard01_tabpanel" index={6}>
              {/* <Events /> */}
              {eventState === 1 ? (
                <Events />
              ) : eventState === 2 ? (
                <Myevents seteventState={seteventState} />
              ) : eventState === 3 ? (
                <EventsForm />
              ) : null}
            </TabPanel>

            <TabPanel value={value} className="dashboard01_tabpanel" index={7}>
              <IncubationTool
                incubationState={incubationState}
                setIncubationState={setIncubationState}
              />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={8}>
              <ServiceProviderTool
                servicebool={servicebool}
                setservicebool={setservicebool}
              />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={9}>
              <Coworking
                coworkingStep={coworkingStep}
                setCoWorkingStep={setCoWorkingStep}
              />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={10}>
              <FInanceMain
                finantialStep={finantialStep}
                setFinantialStep={setFinantialStep}
              />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={11}>
              <LeadGeneration />
            </TabPanel>
            <TabPanel value={value} className="dashboard01_tabpanel" index={13}>
              <Profile />
            </TabPanel>

            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1158}
            >
              <Messages />
            </TabPanel>

            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1651}
            >
              <ConnectionProfile setconnectionState={setconnectionState} />
            </TabPanel>

            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1561}
            ></TabPanel>
            <TabPanel
              value={value}
              className="dashboard01_tabpanel"
              index={1920}
            >
              <Feed />
            </TabPanel>
          </Box>
        ) : (
          <div
            style={{
              height: "100vh",
              position: "relative",
            }}
          >
            <div
              className="text-center"
              style={{
                margin: "30vh auto",
              }}
            >
              <h5 className="h2_white_40">SESSION EXPIRED</h5>
              <button
                className="gradient_CTA"
                onClick={() => navigate(`/login`)}
              >
                LOGIN AGAIN
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
