import React, { useState } from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MonthlyReport from './MonthlyReport/MonthlyReport';
import Analytics from './Analytics/Analytics';

const Insights = () => {
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div>
            <Box className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs">
                <TabContext value={value}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <div className="row w_100" style={{ alignItems: 'flex-end' }}>
                            <div className="col-lg-9 col-md-7 col-6" style={{ borderBottom: "1px solid #e4e4e4" }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Analytics" value="1" />
                                    <Tab label="Monthly reports" value="2" />
                                </TabList>
                            </div>
                            <div className="col-lg-3 col-md-5 col-6">
                                <div className='d-flex justify-content-end '>
                                    <button className='primaryFilled_activeCta w_100 d-flex justify-content-center align-items-center'>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                fill="#000"
                                                d="M16.438 20c.397 0 .777-.169 1.057-.469.28-.3.438-.707.438-1.131v-4l-3.736 3.2v-2.4H8.966v-1.6h5.23v-2.4l3.737 3.2V8.8L13.449 4H7.471c-.396 0-.776.169-1.057.469-.28.3-.437.707-.437 1.131v12.8c0 .424.157.831.437 1.131.28.3.66.469 1.057.469h8.967zM12.702 5.6l3.736 4h-3.736v-4z"
                                            ></path>
                                        </svg><span>EXPORT DATA</span></button>
                                </div>
                            </div>
                        </div>
                    </Box>
                    <TabPanel value="1">
                        <Analytics />
                    </TabPanel>
                    <TabPanel value="2">
                        <MonthlyReport />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    )
}
// sx={{ borderBottom: 1, borderColor: "divider" }}
export default Insights