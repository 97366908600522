import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "axios";
import Query from "../../../../../Utils/Query/Query";
import Loader from "../../../../../Components/Loader";
// import { useNavigate } from "react-router-dom";

const ViewPreStartupProfile = () => {
    const [prestartup, setPrestartup] = useState(null);
    const { setSessionExpire } = useAuth();
    const query = Query()
    const preStartupId = query.get('prestartup')
    const [isLoading,setIsLoading] = useState(false)
    // const navigate = useNavigate()

    const getDetail = async () => {
        setIsLoading(true)
        await Axios({
            method: "get",
            url: `/api/prestartup/data/${preStartupId}`,
        })
            .then((res) => {
                setPrestartup(res.data.data);
                window.scrollTo(0, 0);
                setIsLoading(false)
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true);
                    setIsLoading(false)
                }
            });
    };

    useEffect(() => {
        if (preStartupId) {
            getDetail();
        }
    }, [preStartupId]);
    return (
        <>
            {
                isLoading ? <Loader/> : <>
                    {/* <div className="col-12 participants_profile_head">
                <span
                    className="cta color_light_grey"
                    onClick={() => {
                        setstep(0);
                        navigate(`/dashboard/${user.type}?tab=10&mm=1`)
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-left mx-1"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                        />
                    </svg>
                    Back
                </span>
            </div> */}
            {
                prestartup && <div className="col-12 row">
                    <div className="col-12 col-lg-7">
                        <div
                            className="partipant_profile_main_left"
                            id="connection_profile_root"
                        >
                            <div className="partipant_profile_main_left_head">
                                <div className="dashboard01_connection_profile_head_img">
                                    <img src={prestartup.profile_pic} alt="logo" />
                                    <div className="ml-12">
                                        <p className="subtitle color_dark_blue">
                                            {prestartup.teamName}
                                        </p>
                                        <p className="caption color_dark_grey2">
                                            {prestartup.Location.state}, {prestartup.Location.country}
                                        </p>
                                    </div>

                                </div>

                            </div>
                            <div className="mt-40">
                                <p className="incubation_chips caption">{prestartup.productStage} </p>
                            </div>
                            <div className="mt-40">
                                <p className="body2 grey">    {prestartup.productDetails}</p>
                            </div>
                            <hr />
                            <div className="">
                                <p className="incubation_chips caption mt-24">
                                    <a href={prestartup.pitchdeck} target="_blank" rel="noopener noreferrer">Pitch Deck <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        fill="currentColor"
                                        className="bi bi-box-arrow-up-right"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8.636 3.5a.5.5 0 00-.5-.5H1.5A1.5 1.5 0 000 4.5v10A1.5 1.5 0 001.5 16h10a1.5 1.5 0 001.5-1.5V7.864a.5.5 0 00-1 0V14.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h6.636a.5.5 0 00.5-.5z"
                                        ></path>
                                        <path
                                            fillRule="evenodd"
                                            d="M16 .5a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h3.793L6.146 9.146a.5.5 0 10.708.708L15 1.707V5.5a.5.5 0 001 0v-5z"
                                        ></path>
                                    </svg></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-5">
                        <div className="connection_profile_root_highligts">
                            <div className="participants_profile_root_highligts_head">
                                <p className="subtitle">Highlights</p>
                            </div>
                            <div className="participants_profile_root_highligts_table_main">
                                <table className="participants_profile_root_highligts_table">
                                    <tr className="participants_profile_root_highligts_table_tr">
                                        <td className="body2 participants_profile_root_highligts_ftd">
                                            Product Stage
                                        </td>
                                        <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                            {prestartup.productStage}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="body2 participants_profile_root_highligts_ftd">
                                            Country
                                        </td>
                                        <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                            {prestartup.Location.country}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="body2 participants_profile_root_highligts_ftd">
                                            State
                                        </td>
                                        <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                            {prestartup.Location.state}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="body2 participants_profile_root_highligts_ftd">
                                            City
                                        </td>
                                        <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                                            {prestartup.Location.city}
                                        </td>
                                    </tr>

                                    <tr className="participants_profile_root_highligts_table_tr">
                                        <td className="body2 participants_profile_root_highligts_ftd">
                                            Contact
                                        </td>
                                        <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                                            {prestartup.phoneNo}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
                </>
            }
        </>
    );
}

export default ViewPreStartupProfile