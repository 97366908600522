import React , { useState, useEffect } from "react";  
import axios from "axios";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import Stack from '@mui/material/Stack';
import "./index.css";
import gifchat from "./../../../Assets/images/video-chat.gif";

export default function Requests() {
  const [conversations, setconversations] = useState([]);
  const { user, setSessionExpire } = useAuth();

  const getConversations = async () => {
    await axios({
      url: "/api/dashboard/conversations",
      method: "get",
    })
      .then((res) => {
        setconversations(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      });  
  };

  useEffect(() => {
    getConversations();
  }, []);
  // useEffect(() => {
  //   console.log(conversations);
  // });

  return (
    <div className="dashboard01_block mt-0">
    <div className="row justify-content-between mx-1 my-1">

      <div className="w_fit d-flex">
        <h5 className="subtitle w_fit mr-3">Conversations</h5>
        {conversations && conversations.latest_conv ? 
        <span className="px-3 py-1" style={{background: "#E2E8F0",borderRadius: "24px"}}>{conversations.latest_conv.length}</span> 
        : null}

      </div>
      <Link to={`/dashboard/${user.type}?tab=1158`}>
        <p className="w_fit bold" style={{marginTop: "5px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
    {conversations && conversations.latest_conv && conversations.latest_conv.length>0 ?
    <>
    <div className="my-3">
      <div className="caption mb-2">
        Open frequent/recent chats
      </div>
        <Stack direction="row" spacing={2}>
         {conversations.latest_conv.slice(0,5).map((convo,i)=> <Avatar  src={convo.user.avatar}  key={i}  style={{background: "rgba(0,0,0,0.1)"}} />) }
          
        </Stack>
    </div>
    <div className="mb-3 d-flex">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.486 0 0 3.589 0 8C0 10.908 1.898 13.515 5 14.934V20L10.34 15.995C15.697 15.852 20 12.32 20 8C20 3.589 15.514 0 10 0Z" fill="url(#paint0_linear_2808_27248)"/>
        <defs>
        <linearGradient id="paint0_linear_2808_27248" x1="18.9796" y1="6.24106e-07" x2="-3.04433" y2="4.26681" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F53939"/>
        <stop offset="0.203125" stopColor="#FF6F1E"/>
        <stop offset="1" stopColor="#FFC939"/>
        </linearGradient>
        </defs>
        </svg>
      <div className="body1 ml-2" style={{color: "#262C5B"}}>
        Recent messages
      </div>
    </div>
    <div style={{minHeight:"0px"}}>
      <div className="row">

      {conversations.latest_conv.slice(0,3).map((convo,i)=>
            <div className="col-lg-12 my-1 mb-2" key={i}>
              <div className="convo-box">
                <div className="row">
                <div className="col-2 d-flex align-items-center pr-0">
                  <Avatar sx={{width:40,height:40}} src={convo.user.avatar} className="mr-2" style={{marginRight: "16px"}} />
                </div>
                <div className="col-6 " style={{paddingLeft:"12px"}}>
                  <p className="caption" >Name</p>
                  <p className="overline pr-1" style={{color: "#989898"}}>2 new messages</p>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-end">
                  <p className="overline pr-1" style={{color: "#989898"}}>2 days ago</p>
                </div>
                </div>
              </div>
            </div>
      )}

        </div>
      </div>
      </>
      : 
      <div className="col-12 d-flex flex-column align-items-center justify-content-center" style={{height:"305px"}}>
          <img src={gifchat} style={{witdh:"131px",height:"131px",paddingBottom:"4px"}} />
          <div className="caption mx-auto" style={{color:"#000000", maxWidth: "175px", textAlign: "center"}}>Connect with mentors , incubators etc and start a conversation</div>
      </div> 
      }
      </div>
  );
}
