import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import ParticipantProfile from "./ParticipantProfile";


import rocketimg from "../../../../Assets/images/Rocket.png";
import {ParticipantsSkeleton,ParticipantsCardSkeleton} from '../../../../Components/SkeletonLoader/ParticipantsSkeleton'
import ConnectionCard from "../../../../Components/Cards/ConnectionCard";
const index = ({ programid }) => {
  const [participants, setparticipants] = useState([]);

  const { setSessionExpire } = useAuth();
  const [step, setstep] = useState(0);
  const [participantid, setparticipantid] = useState("");
  const [title, settitle] = useState("");
  const [count, setcount] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  // getting data
  const fetchparticipants = async () => {
    setIsLoading(true)
    await Axios({
      url: `/api/incubatorProgram/members/${programid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setparticipants(data.data);
        settitle(data.program.title);
        setcount(data.count);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        setIsLoading(false)
      });
  };

  useEffect(() => {
    fetchparticipants();
  }, []);


  return (
    <>
      {step === 1 ? (
        <>
          <ParticipantProfile
            setstep={setstep}
            startupid={participantid}
            title={title}
          />
        </>
      ) : (
        <div className="p-2">
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row m-0">
                {isLoading ? <ParticipantsSkeleton/> : participants.length === 0 ? <p>No Participants available</p> : participants.map((v, i) => {
                    return (
                      <>
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                          key={i}
                        >
                          <ConnectionCard
                            handleFunction={() => {
                              setstep(1);
                              setparticipantid(v.profile._id);
                            }}
                            name={v.profile.companyName}
                            profilepic={v.profile.logo}
                            state={v.profile.location.state}
                            country={v.profile.location.country}
                            chip1={v.profile.industry}
                            chip2={v.profile.audience}
                          />
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              {
                isLoading? <ParticipantsCardSkeleton/> :  <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mt-2">Total Startups</p>
                <div className="row mx-0 my-2 r_a">
                  <div className="col-8">
                    <p className="bold my-0 h4" style={{ fontSize: "32px" }}>
                      {count}
                    </p>
                  </div>
                  <div className="col-4">
                    <img src={rocketimg} />
                  </div>
                </div>
              </div>
              }
              
              {/* <div className="dashboard01_block">
              <p className="p_spartan_20 mt-2">Location</p>
                <div className='row mx-0 my-2 r_a'>
                  <div className="col-8">
                  <p className="bold my-0 h4" style={{fontSize: "32px"}}>10</p>
                  </div>
                </div>
            </div>
            <div className="dashboard01_block">
              <p className="p_spartan_20 mt-2">Industry</p>
                <div className='row mx-0 my-2 r_a'>
                  <div className="col-8">
                  <p className="bold my-0 h4" style={{fontSize: "32px"}}>5</p>
                  </div>
                </div>
            </div> */}
              {/* <div className="dashboard01_block">
                <p className="p_spartan_20 mb-4 mt-2">Invite participants</p>
                {invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {!v.is_accepted ? (
                          <>
                            <div key={i} className="row mx-0 my-2 r_a">
                              <Avatar src={v.receiver.avatar} />
                              <div className="col-8">
                                <p className="bold my-0">{v.receiver.name}</p>
                                <p className="font12 grey">Pending</p>
                              </div>
                              <p
                                className="font12 grey mx-auto back_CTA"
                                onClick={() => cancelRequest(v._id)}
                              >
                                Cancel
                              </p>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p>Start connecting with participants</p>
                )}
              </div>
              <div className="dashboard01_block">
                <p className="p_spartan_20 mb-4 mt-2">Active participants</p>
                {invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {v.is_accepted ? (
                          <>
                            <div key={i} className="row mx-0 my-2 r_a">
                              <Avatar src={v.receiver.avatar} />
                              <div className="col-8">
                                <p className="bold my-0">{v.receiver.name}</p>
                              </div>
                              <Link
                                to={`/dashboard/${user.type}?tab=5&id=${v.connect_id}`}
                              >
                                <p
                                  className="font12 grey mx-auto back_CTA"
                                  onClick={() => setparticipantState(2)}
                                >
                                  View
                                </p>
                              </Link>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p>No participants</p>
                )}
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default index;
