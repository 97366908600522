import React, { useState } from "react";
import logo from "./../../Assets/images/logo/logo.png";
import login_img from "./../../Assets/images/signup.png";
// import Navbar from '../../Layout/Navbar/Navbar'
// import BackgroundImg from '../Home/backgroundImg'
// import GoogleLogo from "../../Assets/images/Signup/googleLogo.png"
// import LinkdinLogo from "../../Assets/images/Signup/linkdinLogo.png"
import "./login.css";
import { toast } from "react-toastify";
import Axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
import { useAuth } from "./../../Utils/Auth";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const { setLoadCurrent, loadCurrent, setRole } = useAuth();
  const [disable, setdisable] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  
  // const [firstName, setFirstname]=useState("");
  // const [lastName, setLastname]=useState("");
  // const [role, setRole]=useState("");
  const [agreed, setAgreed] = useState(0);

/*=============================================
=            Handling signup url            =
=============================================*/

const search = useLocation().search;
const event_id = new URLSearchParams(search).get('event_id')
const request_id = new URLSearchParams(search).get('request_id')
let SIGN_UP = '/signup';
if(event_id !== null){
  SIGN_UP = `/signup?event_id=${event_id}`
}else if(request_id !==null){
  SIGN_UP = `/signup?request_id=${request_id}`
}else{
  SIGN_UP = '/signup'
}



  const handleOnSubmit = async (e) => {
    let data;
    e.preventDefault();
    setdisable(true);

    if(event_id !== null){
      data = {
        email,
        password,
        event_id
      };
    }else if(request_id !== null){
      data = {
        email,
        password,
        request_id
      };
    }else{
      data = {
        email,
        password,
      };
    }
    
    await Axios.post("/api/user/login", data)
      .then((res) => {
        setLoadCurrent(!loadCurrent);
        toast.success("Login successfull", { position: "bottom-right" });
        setRole(res.data.type);

        navigate(`/user-check/${res.data.type}`);
      })
      .catch((res) => {
        toast.error(res.response.data.message, { position: "bottom-right" });
        setdisable(false);
      });
  };




  return (
    <>
      {/* <Navbar /> */}

      <div className="auth_back_part">
        <div className="twf_signup_left">
          <div className="twf_signup_left_imgs">
            <div className="twf_signup_left_logo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </div>
            <div className="twf_singup_left_bg">
              <img
                className={` img-fluid smooth-image auth_option_img image-${imageLoaded ? "visible" : "hidden"
                  } auth_image`}
                onLoad={() => setImageLoaded(true)}
                src={login_img}
                alt="twf signup"
              />
            </div>

          </div>
          <div
            className="auth_back back_CTA"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </div>

        </div>
        <div className="twf_signup_right_main">
          <div className="auth_back_loginform-head">
            <h3>Welcome back</h3>
          </div>
          <form className="row" onSubmit={handleOnSubmit}>
            <div className="auth_back_loginform col-12">
              <label>Email Address</label>
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email ID*"
                value={email}
              />
            </div>
            <div className="auth_back_loginform col-12">
              <div className="signup-form-frgetPas">
                <label>Enter Password</label>
                <input
                  type={!showPassword ? "password" : "text"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Password*"
                  value={password}
                />
              </div>
              <button
                className="auth_input_pas_btn"
                type="button"
                onClick={() => setshowPassword(!showPassword)()}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </button>
            </div>
            <div
              className="signup-checkbox col-12"
              style={{ paddingLeft: "15px" }}
            >
              <div className="signup-check">
                <input
                  type="checkbox"
                  onChange={() => {
                    setAgreed(!agreed);
                  }}
                  name=""
                  id=""
                />
              </div>
              <p className="auth_back_loginform-text">Remember me</p>
              <Link
                to="/forget-password"
                style={{ position: "absolute", right: "0" }}
              >
                {" "}
                <p className="auth_back_loginform-text">
                  Forgot your password?
                </p>
              </Link>
            </div>

            <div className="signup-btn col-12">
              <button
                type="submit"
                className="gradient_CTA"
                disabled={disable}
              >
                LOGIN
              </button>
            </div>
            <div className="col-12">
              <p
                className="auth_back_loginform-text"
                style={{ textAlign: "center" }}
              >
                New member?{" "}
                <Link to={SIGN_UP} style={{ color: "#000" }}>
                  SIGNUP
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;

