import React, { useState, useEffect } from 'react'
import DeclinedCard from "./DeclinedCard"
import { toast } from 'react-toastify';
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth"

const Allreq = ({setViewAsset}) => {
  const [declined, setDeclined] = useState([]);
  const { setSessionExpire } = useAuth();

  const getRequest = async () => {
    try {
      await Axios({
        method: "get",
        url: `/api/asset/asset/incubator/declined/${true}`,
      }).then((res) => {
        setDeclined(res.data.data)
      }).catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true)
        }
      })
    } catch (error) {
      toast.error(error.response.data.error);
      if (error.response.status === 401) {
        setSessionExpire(true);
      }
    }
  }

  useEffect(() => {
    getRequest()
  }, [])

  return <div className="incubator_assessment_root">
    <div className="incubator_assessment_main">
      <div className="incubator_assessment_tableRoot">
        <table className="incubator_assessment_table">
          <thead className="incubator_assessment_thead">
            <tr className="incubator_assessment_thead_tr">
              <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                <p className="body2 color_dark_grey2">Requested by</p>
              </td>

              <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                <p className="body2 color_dark_grey2">Asset name</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                <p className="body2 color_dark_grey2">Brand</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                <p className="body2 color_dark_grey2">Usage Type</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_score">
                <p className="body2 color_dark_grey2">Status</p>
              </td>
              <td className="incubator_assessment_thead_td incubator_assessment_td_view text-end pr-24">
                {/* <p className="body2 color_dark_grey2">Details</p> */}
              </td>
            </tr>
          </thead>
          <tbody className="incubator_assessment_tbody">
            {
              declined && declined.length > 0 ?
                declined.map((data, i) => <DeclinedCard
                  key={i}
                  data={data}
                  setViewAsset={setViewAsset}
                />) : null
            }
          </tbody>
        </table>
      </div>
    </div>

  </div>
}

export default Allreq