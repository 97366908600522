import React, { useState } from 'react'
// import Axios from "axios"
// import {toast} from "react-toastify"

const CourseDetails = (props) => {
    const { title, setTitle, tagArr, setTagArr, summery, setSummery, saveCourseDetails } = props;
    const [tag, setTag] = useState("")


    return <div className='mt-4'>
        <h4>Course Details</h4>
        <div className="create_course_form_main">
            <div className="row">
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="course_title" className='caption color_dark_blue'>Course Title</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Enter Title'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="course_tag" className='caption color_dark_blue'>Course Tag</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Enter industry or keyword'
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                    />
                    <button type='submit'
                        style={{ opacity: 0 }}
                        onClick={(e) => {
                            e.preventDefault();
                            setTagArr((prev) => [...prev, tag])
                            setTag("")
                        }}></button>
                </div>
                <div className='tagchips px-3'>
                    {
                        tagArr.map((data, i) => <p className='body2 incubation_chips' key={i}><span>{data}</span> <button onClick={(e) => {
                            e.preventDefault();
                            const newArr = tagArr.filter((item, index) => index !== i);
                            setTagArr(newArr)
                        }}>X</button></p>)
                    }
                </div>
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="course_summary" className='caption color_dark_blue'>Course Summary</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Write a short description and the objectives'
                        value={summery}
                        onChange={(e) => setSummery(e.target.value)}
                    />
                </div>
                <div className="col-12 create_course_form_maindivBtn">
                    <button className='primaryFilled_activeCta' onClick={saveCourseDetails}>Save and Next</button>
                </div>
            </div>
        </div>
    </div>
}

export default CourseDetails