import Skeleton from '@mui/material/Skeleton';
export const Members = ({value}) => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < value; index++) {
               list.push(<div className="row mx-3 my-2 r_a">
                    <Skeleton variant="circular" width={30} height={30} />
                    <div className="col-8">
                         <Skeleton animation="wave" width="100%" height={30} />
                    </div>
               </div>)
          }
          return list;
     }
     return (
          <>
               {renderSkeleton()}
          </>
     )
}
