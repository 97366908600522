import Axios from "axios";
import React, { useEffect, useState } from "react"; 
import "./index.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import Component from "./Component";
import servicegif from "./../../../Assets/images/servicereq.gif";

export default function ServiceRequests() {
  const { user } = useAuth();

  const [services, setservices] = useState([]);
  const { setSessionExpire } = useAuth();
  const fetchservices = async () => {
    await Axios({
      url: `/api/service/latestrequests`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setservices(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };


  useEffect(() => {
    fetchservices();
  }, []);
  
  // useEffect(() => {
  //   console.log(services);
  // });

  return (
    <div className="Dashboard01_events dashboard01_block mt-0">
      <div className="row justify-content-between align-items-center mx-1 my-1">
        <h5 className="w_fit subtitle">Service Requests</h5>
        <Link to={`/dashboard/${user.type}?tab=7&ps=2`}>
          <p className="w_fit bold" style={{color: "#262C5B"}}>
          {services && services.length>0 ?
          
            <svg style={{margin:"4px 0px 0px 0"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            :
            <>
            <svg className="mr-8" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3.61111C0 2.65338 0.380455 1.73488 1.05767 1.05767C1.73488 0.380455 2.65338 0 3.61111 0H16.3889C17.3466 0 18.2651 0.380455 18.9423 1.05767C19.6195 1.73488 20 2.65338 20 3.61111V16.3889C20 17.3466 19.6195 18.2651 18.9423 18.9423C18.2651 19.6195 17.3466 20 16.3889 20H3.61111C2.65338 20 1.73488 19.6195 1.05767 18.9423C0.380455 18.2651 0 17.3466 0 16.3889V3.61111Z" fill="#262C5B"/>
            <path d="M10.0001 4.44434C10.2211 4.44434 10.4331 4.53213 10.5894 4.68841C10.7457 4.84469 10.8335 5.05666 10.8335 5.27767V9.16656H14.7224C14.9434 9.16656 15.1553 9.25436 15.3116 9.41064C15.4679 9.56692 15.5557 9.77888 15.5557 9.99989C15.5557 10.2209 15.4679 10.4329 15.3116 10.5891C15.1553 10.7454 14.9434 10.8332 14.7224 10.8332H10.8335V14.7221C10.8335 14.9431 10.7457 15.1551 10.5894 15.3114C10.4331 15.4677 10.2211 15.5554 10.0001 15.5554C9.77912 15.5554 9.56716 15.4677 9.41088 15.3114C9.2546 15.1551 9.1668 14.9431 9.1668 14.7221V10.8332H5.27791C5.0569 10.8332 4.84494 10.7454 4.68866 10.5891C4.53238 10.4329 4.44458 10.2209 4.44458 9.99989C4.44458 9.77888 4.53238 9.56692 4.68866 9.41064C4.84494 9.25436 5.0569 9.16656 5.27791 9.16656H9.1668V5.27767C9.1668 5.05666 9.2546 4.84469 9.41088 4.68841C9.56716 4.53213 9.77912 4.44434 10.0001 4.44434Z" fill="white"/>
            </svg>
            <span className="cta" style={{color: "#262C5B"}}>Add service</span>
            </>
          }
            </p>
        </Link>
      </div>
      <div className="mt-3 mb-3">
      <div className="row mx-0 d-flex justify-content-center align-items-center">

        {
          services && services.length>0 ? 
            services.map((service,i)=><Component key={i} service={service} />)
            :
            <>
              <img src={servicegif} className="noreq-img" />
              <div className="body1 mt-24" style={{color: "#262C5B"}}>Add more services for better reach</div>
            </>  
        }    
      </div>
      </div>
    </div>
  );
}