import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useAuth } from "../../Utils/Auth";
import MentorProfile from "./MentorProfile";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { MentorExploreSkeleton } from "../../Components/SkeletonLoader/MentorsSkeleton";
import { ParticipantsInvite } from "../../Components/SkeletonLoader/ParticipantsSkeleton";
const index = ({ setmentorState }) => {
  const [mentors, setmentors] = useState([]);
  const [invites, setinvites] = useState([]);
  const { setSessionExpire, user } = useAuth();
  const [step, setstep] = useState(0);
  const [mentorid, setmentorid] = useState("");
  const [isLoading,setIsLoading] = useState(false)
  // getting data
  const fetchmentors = async () => {
    setIsLoading(true)
    await Axios({
      url: "/api/mentor",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setmentors(data.resultModels);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };

  const fetchInvites = async () => {
    setIsLoading(true)
    await Axios({
      url: "/api/mentor/mentorshipstatus",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setinvites(data.data);
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };

  const cancelRequest = async (id) => {
    await Axios({
      url: `/api/mentor/delete/request/${id}`,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          toast.success("Request cancelled");
          fetchInvites();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.error(err.response.data.error);
      });
  };

  useEffect(() => {
    fetchmentors();
    fetchInvites();
  }, []);

  // if (mentors.length === 0) return <p>No Mentors available</p>;
  return (
    <>
      {step === 1 ? (
        <>
    
          <MentorProfile
            setstep={setstep}
            id={mentorid}
            fetchInvites={fetchInvites}
            setmentorState={setmentorState}
          />
        </>
      ) : (
        <div className="p-2">
          <p className="p_spartan_20 px-3">Mentors</p>
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <div className="row m-0">
                {
                  isLoading ? <MentorExploreSkeleton/> : mentors &&
                  mentors.map((v, i) => {
                    return (
                      <>
                        <div
                          className="col-12 col-sm-6 col-md-4 col-lg-4 no-padding mt-4"
                          key={i}
                          onClick={() => {
                            setstep(1);
                            setmentorid(v.id);
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            className={`dashboard_01_connections_list_item`}
                          >
                            <div className="dashboard_01_connections_logo_list_block">
                              <Avatar
                                src={v.profilePic}
                                alt="connection"
                                className="dashboard_01_connections_logo"
                              />
                            </div>

                            <div className="dashboard_01_connections_list_item_desc">
                              <p className="p_spartan_20">
                                {v.name.length > 12
                                  ? v.name.slice(0, 12) + "..."
                                  : v.name}
                              </p>
                              <p className=" no-margin font12 bold">
                                {v.location.city}, {v.location.country}
                              </p>
                              <div className="row justify-content-center">
                                <p className=" font12 p_grey_chip">
                                  {v.industry}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="card" key={i} style={{ width: "18rem" }}>
                      <img
                        className="card-img-top"
                        src={v.profilePic}
                        alt="Card image cap"
                        style={{ height: "100px", width: "100px" }}
                      />
                      <div className="card-body">
                        <h5 className="card-title">{v.name}</h5>
                        <p className="card-text">Industry: {v.industry}</p>
                        <Button
                          onClick={() => {
                            setstep(1);
                            setmentorid(v.id);
                          }}
                          className="btn btn-primary"
                          style={{ width: "100%" }}
                        >
                          View Mentor
                        </Button>
                      </div>
                    </div> */}
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mb-4 mt-2"> Sent Invites</p>
                {
                  isLoading ? <ParticipantsInvite value={3}/> : invites.length > 0 ? (
                  invites.map((v, i) => {
                    return (
                      <>
                        {!v.is_accepted ? (
                          <>
                            <div key={i} className="row mx-0 my-2 r_a">
                              <Avatar src={v.receiver.avatar} />
                              <div className="col-8">
                                <p className="bold my-0">{v.receiver.name}</p>
                                <p className="font12 grey">Pending</p>
                              </div>
                              <p
                                className="font12 grey mx-auto back_CTA"
                                onClick={() => cancelRequest(v._id)}
                              >
                                Cancel
                              </p>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <p>Start connecting with mentors</p>
                )}
              </div>
              <div className="dashboard01_block h-fit">
                <p className="p_spartan_20 mb-4 mt-2">Active Mentorships</p>
                {
                  isLoading ? <ParticipantsInvite value={6}/> : invites.map((v, i) => {
                    return (
                      <>
                        {v.is_accepted ? (
                          <>
                            <div key={i} className="row mx-0 my-2 r_a">
                              <Avatar src={v.receiver.avatar} />
                              <div className="col-8">
                                <p className="bold my-0">{v.receiver.name}</p>
                              </div>
                              <Link
                                to={`/dashboard/${user.type}?tab=5&id=${v.connect_id}`}
                              >
                                <p
                                  className="font12 grey mx-auto back_CTA"
                                  onClick={() => setmentorState(2)}
                                >
                                  View
                                </p>
                              </Link>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default index;
