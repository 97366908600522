import React, { useEffect, useState } from "react";
// import profile_inc from "../../../../Assets/images/incubatorDash/profile_inc.png"
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  CalendarToday,
  Money,
  AccessTime,
  KeyboardBackspace,
} from "@mui/icons-material";
import "./viewProgram.css";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
// import ClearIcon from "@mui/icons-material/Clear";
// import CircularProgress from "@mui/material/CircularProgress";

import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader";
import ShowDoc from "./ShowDoc";
import UploadDocComp from "./UploadDocComp";
// import UploadDocComp from "./UploadDocComp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ViewProgram = ({ setIncubationState }) => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const id = new URLSearchParams(search).get("id");
  const isProgramRedirect = new URLSearchParams(search).get("program-state");
  const { setSessionExpire, user } = useAuth();
  const [program, setProgram] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [onApply, setOnApply] = useState(false);
  const [request, setRequest] = useState(null);
  const [monthDiff, setMonthDiff] = useState("");
  const [open, setOpen] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const handleOpenDocumentModal = () => setOpenDocumentModal(true);
  const handleCloseDocumentModal = () => setOpenDocumentModal(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/dashboard/startup?tab=7&id=6374e958e6fe9da2bbd44ba0");
  };
  const [diff, setdiff] = useState(null);
  // const [isdocloading, setisDocUploading] = useState(false);

  const [documentsRequired, setdocumentsRequired] = useState([]);

  const fetchprogram = async () => {
    await Axios({
      url: `/api/incubatorProgram/program/${id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setProgram(res.data.data);
          setRequest(res.data.request);
          if (res.data.request?.documents.length <= 0) {
            handleOpenDocumentModal();
          }

          setMonthDiff(res.data.monthDif);
          setdiff(res.data.diff);
          let documents = res.data.data.requiredDocs;
          let docs = [];
          for (let i = 0; i < documents.length; i++) {
            docs.push({ doc_name: documents[i], doc_link: null });
          }
          setdocumentsRequired(docs);
        }
      })
      .catch((err) => {
        if (err) {
          setSessionExpire(true);
          setShowLoader(false);
        }
      });
  };

  const sendRequest = async () => {
    for (let index = 0; index < documentsRequired.length; index++) {
      const element = documentsRequired[index];
      if (element.doc_link === null)
        return toast.error(`Upload ${element.doc_name}`);
    }

    await Axios({
      url: `/api/incubatorProgram/send/${id}`,
      method: "post",
      data: { documents: documentsRequired },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Successfully Applied");
          setOnApply(true);
          handleClose();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchprogram();
  }, []);

  useEffect(() => {
    if (isProgramRedirect & !onApply) {
      handleOpen();
    }
  }, [isProgramRedirect]);

  const handleStart = (key, title, name) => {
    window.open(
      `/jitsi-meet/${key}?title=${title}&user=${name}&programid=${id}&d=incprogram`
    );
  };
  const handleremovedoc = (doc_name) => {
    let array2 = documentsRequired.map((a) => {
      return { ...a };
    });
    array2.find((a) => a.doc_name == doc_name).doc_link = null;
    setdocumentsRequired(array2);
  };

  return (
    <>
      {program === null ? (
        showLoader ? (
          <Loader />
        ) : (
          <>
            <div className="backtoincubation d-flex align-content-center ">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIncubationState(1)}
              >
                <KeyboardBackspace />
              </span>
              <p className="body1 ml-4">Program not found</p>
            </div>
          </>
        )
      ) : (
        <div className="incubation_program_root">
          <div className="incubatrion_profile_program_head">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="incubation_profile__head_card1_root">
                  <div className="incubationprofile_card1_head">
                    <p className="p_grey_chip p_normalBlack10">
                      {program.sector && program.sector.length > 0
                        ? program.sector[0]
                        : program.type}
                    </p>
                  </div>
                  <div className="incubationprofile_card1_title">
                    <h4>{program.title} </h4>
                  </div>

                  <div className="incubationprofile_card1_link">
                    <a href={program.website}>{program.website}</a>
                  </div>
                  <div className="incubationprofile_card1_hostedBy mt-4">
                    <p className="p_lightGrey10">Hosted By</p>
                    <div className="incubationprofile_card1_hostedPro">
                      <Avatar
                        src={program.id.avatar}
                        style={{ width: 40, height: 40 }}
                      />
                      <p className="p_white20 ml-3">{program.id.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12">
                <div className="incubation_profile__head_card2_root">
                  <img
                    src={program.coverImage}
                    alt="profile_inc"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="startup_incubation_viewProprgam_root mt-40">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12">
                <div className="startup_incubation_viewProprgam_main dahsboard_card">
                  <div className="startup_incubation_viewProprgam_head">
                    <p className="subtitle">{program.title} </p>
                  </div>
                  <div className="startup_incubation_viewProprgam_desc">
                    <p className="body2">{program.description} </p>
                  </div>
                  <div className="startup_incubation_viewProprgam_highlites">
                    <p className="body1 color_dark_blue">Highlights</p>
                    <div className="startup_incubation_viewProprgam_high_ul">
                      <ul>
                        {program.highlights && program.highlights.length > 0
                          ? program.highlights.map((data, i) => (
                              <li key={i} className="body2 pb-3">
                                {data}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                  <div className="startup_incubation_viewProprgam_benElig">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12 startup_incubation_viewProprgam_benefit">
                        <p className="body1 color_dark_blue">Benefits</p>
                        <ul>
                          {program.benefits && program.benefits.length > 0
                            ? program.benefits.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 startup_incubation_benefit startup_incubationeligibility">
                        <p className="body1 color_dark_blue">Eligibility</p>
                        <ul>
                          {program.eligibility && program.eligibility.length > 0
                            ? program.eligibility.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="startup_incubation_viewProprgam_documents">
                    <p className="body1 color_dark_blue">Documents</p>
                    <div className="startup_incubation_docs">
                      <ul>
                        {program.requiredDocs && program.requiredDocs.length > 0
                          ? program.requiredDocs.map((data, i) => (
                              <li key={i} className="body2 pb-3">
                                <a href={data}> {data}</a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-6 col">
                    <div className="startup_incubation_viewProprgam_infor dahsboard_card">
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center">
                          <span className="mr-2">
                            <CalendarToday />
                          </span>
                          {new Date(program.startDate).toDateString()}
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center text-uppercase">
                          <span className="mr-2">
                            <Money />
                          </span>
                          {program?.programPaymentType === "paid" ? (
                            <span>€{program?.participationAmmount}</span>
                          ) : (
                            <span>FREE</span>
                          )}
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center caps">
                          <span className="mr-2">
                            <AccessTime />
                          </span>
                          {monthDiff === "Live" || monthDiff === "Expired"
                            ? "Live"
                            : monthDiff}
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_btn d-flex justify-content-center align-items-center pb-3">
                        {request?.documents.length <= 0 &&
                        !request.is_accepted &&
                        !request.isDeclined ? (
                          <button className="cta" onClick={handleOpen}>
                            Upload documents
                          </button>
                        ) : request === null ? (
                          <button className="cta" onClick={handleOpen}>
                            {onApply ? "APPLIED" : "APPLY"}
                          </button>
                        ) : request.is_accepted &&
                          !request.isDeclined &&
                          monthDiff === "Live" ? (
                          <button className="cta">Go to Program</button>
                        ) : request.is_accepted &&
                          !request.isDeclined &&
                          monthDiff === "Expired" ? (
                          <button className="cta">Live</button>
                        ) : request.is_accepted &&
                          !request.isDeclined &&
                          monthDiff !== "Live" ? (
                          <button className="cta">Starts in {monthDiff}</button>
                        ) : !request.is_accepted && !request.isDeclined ? (
                          <button className="cta" disabled>
                            PENDING
                          </button>
                        ) : !request.is_accepted && request.isDeclined ? (
                          <button className="cta" disabled>
                            Rejected
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {request !== null && request.vc_requested ? (
                    <>
                      <div className="col-lg-12 col-md-6 col mt-3">
                        <div className="startup_incubation_viewProprgam_infor dahsboard_card">
                          <p>Interview Details</p>
                          <div className="startup_incubation_viewProprgam_date">
                            <p className="body1 d-flex align-content-center">
                              <span>
                                <CalendarToday />
                              </span>
                              {request.vc_date}
                            </p>
                          </div>

                          <div className="startup_incubation_viewProprgam_date">
                            <p className="body1 d-flex align-content-center caps">
                              <span>
                                <AccessTime />
                              </span>
                              {request.vc_time}
                            </p>
                          </div>
                          <div className="startup_incubation_viewProprgam_btn d-flex justify-content-center align-items-center pb-3">
                            {diff === "Live" ? (
                              <button
                                className="gradient_CTA width_fit mr-3"
                                type="button"
                                onClick={() =>
                                  handleStart(
                                    request.vc_id,
                                    "Interview",
                                    user.name
                                  )
                                }
                              >
                                JOIN NOW
                              </button>
                            ) : diff === "Expired" ? (
                              <button
                                className="gradient_CTA width_fit mr-3"
                                type="button"
                                disabled
                              >
                                EXPIRED
                              </button>
                            ) : (
                              <button
                                className="gradient_CTA width_fit mr-3"
                                type="button"
                                disabled
                              >
                                {diff} left
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-lg-12 col-md-6 col-12 mt-lg-3 mt-md-0 mt-sm-3">
                    <div className="startup_incubation_app_info">
                      <div className="startup_incubation_sDae">
                        <div className="startup_incubation_startDate">
                          <p className="overLine">
                            {new Date(program.startDate).toDateString()}
                          </p>
                        </div>
                        <div className="startup_incubation_dText">
                          <p className="body2">Start Date</p>
                        </div>
                      </div>
                      <span className="startup_incubation_heigth"></span>
                      <div className="startup_incubation_sDae">
                        <div className="startup_incubation_startDate">
                          <p className="overLine">
                            {new Date(program.endDate).toDateString()}
                          </p>
                        </div>
                        <div className="startup_incubation_dText">
                          <p className="body2">End Date</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className="register_page_form row">
            <div className="col-12">
              {documentsRequired.length > 0 ? (
                documentsRequired.map((v, i) => {
                  return (
                    <div key={10 * Math.random() + v.doc_name}>
                      {v.doc_link ? (
                        <ShowDoc v={v} handleremovedoc={handleremovedoc} />
                      ) : (
                        <UploadDocComp
                          i={i}
                          v={v}
                          docname={v.doc_name}
                          documentsRequired={documentsRequired}
                          setdocumentsRequired={setdocumentsRequired}
                          handleOpen={handleOpen}
                          handleClose={handleClose}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <p>No Documents required</p>
              )}

              <div className="d-flex justify-content-center mt-5">
                <button
                  onClick={() => sendRequest()}
                  type="button"
                  className="gradient_CTA"
                >
                  APPLY
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDocumentModal}
        onClose={handleCloseDocumentModal}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className="register_page_form row mb-3">
            Please upload your documents and complete your apply process.
          </div>
          <button
            className="gradient_CTA float-right mt-4"
            onClick={() => {
              handleCloseDocumentModal();
              handleOpen();
            }}
          >
            Upload Documents
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default ViewProgram;
