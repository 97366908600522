import Skeleton from '@mui/material/Skeleton';
export const EventPreviewSkeleton = () => {
     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 8; index++){

               list.push( 
                    <div className="col-lg-3 col-md-6 col-12 mb-24"> 
               <div className="incubation_program_card_root mt-0 h-100">

               <Skeleton animation="wave" variant="rounded" widht="100%" height={160} style={{
                         borderTopLeftRadius: "0.8rem",
                         borderTopRightRadius: "0.8rem",
               }}/>
              
               <div className="incubation_program_card_main pl-3 pr-3 d-flex flex-column justify-content-between" style={{minHeight: "215px"}}>
                 <div>
                 <div className="incubation_program_card_title mt-2">
                   <h5 className="p_black20"><Skeleton animation="wave" variant="wave" width="100%" height={45} /></h5>
                 </div>
                 <div className="incubation_program_card_info row d-flex justify-content-between mt-3 pb-2">
                   <div className="col-7 incubation_program_card_small d-flex align-items-center">
                   <Skeleton animation="wave" variant="wave" width={60} height={20} />
                   </div>
                   <div className="col-5 incubation_program_card_small d-flex align-items-center p-0">
                   <Skeleton animation="wave" variant="wave" width={50} height={20} />
                   </div>
                 </div>
                 </div>
                 <div>
                 <div className="overline mt-10 mb-10" style={{color:"#580167"}}>
                 <Skeleton animation="wave" variant="wave" width={70} height={20} /></div>
                 <div className="pb-16 d-flex justify-content-between align-items-center">
                       <div className="w-fit">
                       <Skeleton animation="wave" variant="wave" width={120} height={40} />
                       </div>
                       <div className="">
                       <Skeleton animation="wave" variant="wave" width={20} height={20} />
                       </div>
                 </div>
                 </div>
               </div>
             </div>
             </div>
             )
          }
          return list;
     }
  return (
    
     <>
     {renderSkeleton()}
     </>
         
  
   
  )
}