import React, { useState } from "react";
// import { toast } from "react-toastify";
import Axios from "axios";
// import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";

const invite = ({ handleClose }) => {
  const [mail, setmail] = useState("");
  const [mentoremails, setmentoremails] = useState([]);
  const { programid } = useParams();
  const addObjective = (arr, seto, v, setv) => {
    if (arr) {
      seto((prev) => [...prev, v]);
      setv("");
    }
  };
  const removeObjective = (arr, seto, id) => {
    const newArr = arr.filter((obj, i) => {
      return id !== i;
    });
    seto(newArr);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const sendInvite = async () => {
    if (mentoremails.length === 0) {
      return toast.error("Enter atleast one email");
    }
    const data = {
      mentoremails,
      type: "mentor",
    };
    await Axios.post(`/api/incmentor/email-invite/${programid}`, data)
      .then((res) => {
        if (res.data.success)
          toast.success("Invite sent successfully", {
            position: "bottom-right",
          });
        handleClose();
      })
      .catch((res) => {
        toast.error(res.response.data.message, { position: "bottom-right" });
      });
  };

  return (
    <>
      <form>
        <div className="register_page_form row">
          <h5 style={{ color: " #262C5B" }}>Invite via email</h5>
          <div className="col-12">
            <label className="auth_label">Add emails</label>
            <div className="goalsdiv">
              <input
                type="text"
                name="objective"
                className="auth_input"
                placeholder="mentor@mail.com"
                id="objective"
                value={mail}
                onChange={(e) => setmail(e.target.value)}
              />
              <Tooltip title="Add More Rows">
                <button
                  type="button"
                  onClick={() => {
                    if (!validateEmail(mail))
                      return toast.warning("Enter valid email");
                    if (mentoremails.length > 4) {
                      return toast.warning("Invite is limited to 5 mentors");
                    }
                    addObjective(mentoremails, setmentoremails, mail, setmail);
                  }}
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </div>

            <div>
              {mentoremails
                ? mentoremails.map((obj, i) => {
                    return (
                      <>
                        <span
                          className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3"
                          key={i}
                        >
                          <p className="program_text">{obj}</p>

                          <Tooltip title="Delete Row">
                            <button
                              type="button"
                              title="Remove"
                              onClick={() =>
                                removeObjective(
                                  mentoremails,
                                  setmentoremails,
                                  i
                                )
                              }
                              className="program_btn"
                            >
                              x
                            </button>
                          </Tooltip>
                        </span>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              onClick={() => sendInvite()}
              type="button"
              className="gradient_CTA"
            >
              &nbsp; &nbsp; SEND INVITE&nbsp; &nbsp;{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default invite;
