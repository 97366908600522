import Skeleton from '@mui/material/Skeleton';

export const  MentorExploreSkeleton = ({type})  =>{
     const renderSkeleton = () => {
          const list = [];
          const size = type==='active_members' ? 8 : 6
          for (let index = 0; index < size; index++){
               list.push( <div className={`col-12 col-sm-6 col-md-4 no-padding mt-4 ${type==='active_members' ? 'col-lg-3':'col-lg-4'}`}>
               <div style={{ cursor: "pointer" }} className={`dashboard_01_connections_list_item`}>
                    <div className="dashboard_01_connections_logo_list_block" style={{backgroundColor:"#D9D9D9"}}>
                    
                    <Skeleton animation="wave" variant="rounded" style={{
                         borderRadius: "20%",
                         textAlign: "center",
                         justifyContent: "center",
                         margin: "0 auto",
                         width: "64px",
                         height: "64px",
                    }}/>
                    
                    </div>

                    <div className="dashboard_01_connections_list_item_desc">
                         
                         <div className="d-flex flex-column align-items-center">
                         
                              <Skeleton animation="wave"  width="60%" height={40} />

                              {
                                   type==='active_members' ?  null : <Skeleton animation="wave"  width="60%" height={20} />
                              }
                              
                       
                              <Skeleton animation="wave"  width="60%" height={20} />
                              
                         </div>
                    </div>
                         
               </div>
          </div>)
          }
          return list;
     }
  return (
    
         <>
         {renderSkeleton()}
         </>
   
  )
}