import React, { useState, useEffect } from "react";
import Axios from "axios";
import Avatar from "@mui/material/Avatar";
import { useAuth } from "../../../../Utils/Auth";
import { toast } from "react-toastify";

const Applicantcard = ({ setstep, startupid, title }) => {
  const [startup, setstartup] = useState(null);

  const { setSessionExpire } = useAuth();

  const getDetail = async () => {
    await Axios({
      method: "get",
      url: `/api/startup/user-by-id/${startupid}`,
    })
      .then((res) => {
        setstartup(res.data.data);

        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const sendinvite = async () => {
    await Axios({
      method: "get",
      url: `/api/incubatorProgram/invite/user/${startupid}/${title}`,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Program invite sent");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getDetail();
  }, []);

  if (startup === null) return <p>loading...</p>;

  return (
    <>
      <div className="col-12 mb-5">
        <span
          className="back"
          onClick={() => {
            setstep(0);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left mx-1"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Back
        </span>
        <span className="backhead">{title}</span>
      </div>
      <div className="col-12 row">
        <div className="col-12 col-lg-8">
          <div className="connection_profile_root" id="connection_profile_root">
            <div className="row mt-2 justify-content-center connection_profile_head dashboard01_connection_profile_head">
              {/* <div className="row no-margin align-items-center"> */}
              <div className="width_auto no-padding">
                <Avatar
                  src={startup.logo}
                  className=""
                  alt="Profile"
                  sx={{ width: 90, height: 90 }}
                />
              </div>
              <div className="col-10 d-flex flex-column justify-content-center">
                <p
                  className="p_spartan_20 subtitle"
                  style={{ color: "#262C5B" }}
                >
                  {startup.companyName}
                </p>
                <p className="bold">Startup | {startup.location.country}</p>
              </div>
              {/* </div> */}
              {/* <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div> */}
            </div>
            <div className="mt-4 mx-2">
              <p className="grey bold">{startup.description}</p>
            </div>

            <div className="row mx-2 my-3">
              <p className="p_grey_chip">{startup.industry} </p>
            </div>
            <hr />

            <div className="mb-4">
              {startup.id ? (
                <>
                  <p className="p_spartan_20 mx-2 my-4 founder-title">
                    Founder Details
                  </p>
                  <div className="row mx-0 my-4 align-items-center col-8">
                    <div className="width_auto no-padding">
                      <img
                        src={startup.profilePic}
                        style={{
                          borderRadius: "50%",
                          width: "90px",
                          height: "90px",
                        }}
                        alt="Profile"
                      />
                    </div>
                    <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                      <p className="founder-title" style={{ fontSize: "16px" }}>
                        {startup.name}
                      </p>
                      <p className="founder-job">{startup.contact.jobTitle} </p>
                      <p className="bold">
                        <a href={`${startup.socialConnects.linkedin}`}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-linkedin"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                          </svg>
                        </a>
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => sendinvite()}
                    className="gradient_CTA width_fit"
                  >
                    {" "}
                    INVITE TO PROGRAM
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="connection_profile_root_highligts">
            <div className="connection_profile_root_highligts_head">
              <p className="p_spartan_20">Highlights</p>
            </div>
            <div className="connection_profile_root_highligts_desc">
              <table>
                {startup.valuation && (
                  <tr>
                    <td className="td_left">Valuation</td>
                    <td className="td_right">$ {startup.valuation}</td>
                  </tr>
                )}
                <tr>
                  <td className="td_left">Audience</td>
                  <td className="td_right">{startup.audience}</td>
                </tr>

                <tr>
                  <td className="td_left">Employees</td>
                  <td className="td_right">{startup.employees}</td>
                </tr>
                <tr>
                  <td className="td_left">Funding Series</td>
                  <td className="td_right">{startup.fundingSeries}</td>
                </tr>
                <tr>
                  <td className="td_left">Incorporation</td>
                  <td className="td_right">
                    {new Date(startup.incorporationDate).getFullYear()}
                  </td>
                </tr>

                <tr>
                  <td className="td_left">Website</td>
                  <td className="td_right" style={{ wordBreak: "break-all" }}>
                    <a
                      href={`${startup.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {startup.website}
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicantcard;
