import React, { useEffect, useState } from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Allreq from "./AllReq/Allreq"
import Declined from "./Declined/Declined"
import ViewReq from './ViewReq';
import Query from '../../../../Utils/Query/Query';

const AssestReq = ({setCoWorkingBool}) => {
  const [value, setValue] = React.useState("1");
  const [viewAsset, setViewAsset] = useState(0)
  const [isDeclined, setIsDeclined] = useState(false)
  const query = Query()
  const assetId = query.get('asset')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(()=>{
    if(assetId){
      setViewAsset(1)
    }
  },[assetId])

  return (
    <>
      {
        viewAsset === 0 ?
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="All Requests" value="1" onClick={() => setIsDeclined(false)} />
                  <Tab label="declined" value="2" onClick={() => setIsDeclined(true)} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Allreq 
                  setViewAsset={setViewAsset}
                />
              </TabPanel>
              <TabPanel value="2">
                <Declined 
                  setViewAsset={setViewAsset}
                />
              </TabPanel>
            </TabContext>
          </Box> : <ViewReq
            viewAsset={viewAsset}
            setViewAsset={setViewAsset}
            isDeclined={isDeclined}
            setCoWorkingBool={setCoWorkingBool}
          />
      }

    </>
  )
}

export default AssestReq