import React, { useState, useRef } from "react";

import MuiPhoneNumber from "material-ui-phone-number";
import Axios from "axios";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
const OTPService = (props) => {
  const [sendingotp, setsendingotp] = useState(false);
  const [verifyingotp, setverifyingotp] = useState(false);
  const RenderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setTimeout(() => {
        setOneLastRerender((val) => val + 1);
      }, 20);
      setcalltimer(false);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          <p>{remainingTime}</p>
        </div>
      </div>
    );
  };

  const { setphoneVerified, phone, setPhone } = props;

  const [calltimer, setcalltimer] = useState(false);
  const [code, setCode] = useState(null);
  const [otpStatus, setotpStatus] = useState(null);

  const sendOtp = async () => {
    if (!phone) {
      toast.error("Empty phone number!", { position: "bottom-right" });
    } else if (phone.length < 10) {
      toast.error("Invalid phone number!", { position: "bottom-right" });
    } else if (calltimer) {
      toast.error("Please wait for sometime before requesting another OTP", {
        position: "bottom-right",
      });
    } else {
      setsendingotp(true);
      Axios({
        method: "POST",
        url: `/api/otp/request`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: { phone },
      }).then((response) => {
        if (response.data.success) {
          toast.success("OTP Sent Successfully", { position: "bottom-right" });
          setotpStatus("sent");
          setcalltimer(true);
          setsendingotp(false);
        } else {
          toast.error("Retry after some time", {
            position: "bottom-right",
          });
          setsendingotp(false);
        }
      });
    }
  };

  const verifyOtp = async () => {
    if (!code) {
      toast.error("Please Enter Valid OTP", { position: "bottom-right" });
    } else {
      setverifyingotp(true);
      Axios({
        method: "POST",
        url: `/api/otp/verify`,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: { code, phone },
      }).then((response) => {
        if (response.data.status === "approved") {
          toast.success("OTP Verified Successfully", {
            position: "bottom-right",
          });
          setotpStatus("verified");
          setphoneVerified(true);
          setCode(null);
          setverifyingotp(false);
        } else if (response.data.status === "pending") {
          toast.error("Invalid OTP", { position: "bottom-right" });
          setotpStatus("invalid");
          setCode(null);
          setverifyingotp(false);
        } else {
          toast.error("Something went Wrong", { position: "bottom-right" });
          setverifyingotp(false);
        }
      });
    }
  };

  const handlePhone = (value) => {
    setPhone(value);
  };

  return (
    <>
    <div className="d-flex align-items-end w-100">
      <div className="flex-fill">
      <MuiPhoneNumber
        name="phone"
        // variant="outlined"
        fullWidth
        data-cy="user-phone"
        defaultCountry={"in"}
        value={phone}
        required
        onChange={handlePhone}
        style={{ color: "black" }}
      />
      </div>
      {sendingotp ? <CircularProgress style={{ color: "black",margin:'0px 10px' }} /> : null}
      {verifyingotp ? <CircularProgress style={{ color: "black",margin:'0px 10px' }} /> : null}
      
        {!otpStatus ? (
          <>
          <div className="ml-12">
            <div className="no-padding" style={{ textAlign: "left" }}>
              <button
                type="button"
                className="gradient_CTA text-white"
                onClick={sendOtp}
              >
                Send OTP
              </button>
            </div>
            </div>
          </>
        ) : 
          null}
          </div>
          {otpStatus && otpStatus === "sent" ? (
              <>
                <div className="row m-0 align-items-end mt-12">
                  <div className="col-12 col-md-6 p-0">
                  <input
                    type="number"
                    className="auth_input"
                    placeholder="OTP"
                    onChange={(e) => setCode(e.target.value)}
                  />
                  </div>
                  <div className="col-12 col-md-5">
                  <button
                    type="button"
                    onClick={verifyOtp}
                    className="secondaryOutlined_inactiveCta text-dark"
                  >
                    Verify OTP
                  </button>
                  </div>
                  <div className="col-12 mt-12 justify-content-start p-0">
                  <p className="width_auto text-darkGray no-margin ">
                    Didn&apos;t get the OTP?
                  </p>
                  <p
                    className="w-fit text-dark p_button no-padding secondaryOutlined_inactiveCta"
                    style={{cursor: "pointer"}}
                    onClick={() => sendOtp()}
                  >
                    Resend
                  </p>
                  </div>
                </div>
              </>
            ) : null}

        {calltimer ? (
          <div className="timer-wrapper" style={{ display: "none" }}>
            <CountdownCircleTimer
              isPlaying
              duration={60}
              size={50}
              strokeWidth={5}
              colors={[["#870015", 0.33], ["#222", 0.33], ["#123769"]]}
            >
              {RenderTime}
            </CountdownCircleTimer>
          </div>
        ) : null}
      

      {otpStatus === "verified" ? (
        <p className="text-dark my-4 mx-0">OTP Verified Successfully</p>
      ) : null}

      {otpStatus === "invalid" ? (
        <>
          <div className="row m-0 align-items-end mt-12">
                <p className="text-dark text-left col-12 p-0">Invalid OTP</p>
                  <div className="col-12 col-md-6 p-0">
                  <input
                    type="number"
                    className="auth_input"
                    placeholder="OTP"
                    onChange={(e) => setCode(e.target.value)}
                  />
                  </div>
                  <div className="col-12 col-md-5">
                  <button
                    type="button"
                    onClick={verifyOtp}
                    className="secondaryOutlined_inactiveCta text-dark"
                  >
                    Verify OTP
                  </button>
                  </div>
                  <div className="col-12 mt-12 justify-content-start p-0">
                  <p className="width_auto text-darkGray no-margin ">
                    Didn&apos;t get the OTP?
                  </p>
                  <p
                    className="w-fit text-dark p_button no-padding secondaryOutlined_inactiveCta"
                    style={{cursor: "pointer"}}
                    onClick={() => sendOtp()}
                  >
                    Resend
                  </p>
                  </div>
                </div>
        </>
      ) : null}
    </>
  );
};

export default OTPService;
