import React, { useEffect, useState } from 'react'
import { KeyboardBackspace } from "@mui/icons-material"
import './form.css';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from "../../../../Utils/Auth";
import { nanoid } from 'nanoid'
import { useLocation } from 'react-router-dom';

const AddAssetsForm = ({ setMyAssetState, getAssets,assetsType }) => {
    const [assetName, setAssetName] = useState("");
    const [asset_nano_id, setAsset_nano_id] = useState(nanoid());
    const [assetUseType, setAssetUseType] = useState("");
    const [status, setStatus] = useState("");
    const [stock, setStock] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState("");
    const [condition, setCondition] = useState("");
    const [brand, setBrand] = useState("");
    const [details, setDetails] = useState("");
    // const [detailsArr, setDetailsArr] = useState([]);
    const [availableFrom, setAvailableFrom] = useState("");
    const [availableTill, setAvailableTill] = useState("");
    const [price, setPrice] = useState("");
    const [discount, setDiscount] = useState("");
    const [isUpdate, setIsUpdate] = useState(false)
    const { setSessionExpire } = useAuth();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const asset_Id = query.get("asset");

    const getAssetData = async () => {
        await Axios({
            url: `/api/asset/asset/${asset_Id}`,
            method: 'GET'
        }).then((res) => {
            const { data } = res.data
            setAssetName(data.asset_name)
            if (data) {
                setIsUpdate(true)
            }
            setAsset_nano_id(data.asset_id)
            setAssetUseType(data.asset_usage_type)
            setStatus(data.status)
            setStock(data.stock)
            setCategory(data.category)
            setSubCategory(data.sub_category)
            setCondition(data.condition)
            setBrand(data.brand)
            setDetails(data.details)
            setAvailableFrom(new Date(data.available_from).toLocaleDateString('fr-CA'))
            setAvailableTill(new Date(data.available_till).toLocaleDateString('fr-CA'))
            setPrice(data.price)
            setDiscount(data.discount)
        }).catch(() => {
            setSessionExpire(true)
        })
    }

    useEffect(() => {
        if (asset_Id) {
            getAssetData()
        }
    }, [asset_Id])

    const handleCreateAsset = async (e) => {
        e.preventDefault()
        if (assetName || asset_nano_id || assetUseType || status || stock || category || subCategory || condition || brand || availableFrom || availableTill || price) {
            await Axios({
                url: `/api/asset/create`,
                method: 'post',
                data: {
                    asset_name: assetName,
                    asset_id: asset_nano_id,
                    asset_usage_type: assetUseType,
                    status: status,
                    stock: stock,
                    category: category,
                    sub_category: subCategory,
                    condition: condition,
                    brand: brand,
                    details: details,
                    available_from: availableFrom,
                    available_till: availableTill,
                    price: price,
                    discount: discount,
                    asset_type:assetsType
                }
            }).then(() => {
                setAssetName("")
                setAsset_nano_id("")
                setAssetUseType("")
                setStatus("")
                setStock("")
                setCategory("")
                setSubCategory("")
                setCondition("")
                setBrand("")
                setDetails("")
                // setDetailsArr([])
                setAvailableFrom("")
                setAvailableTill("")
                setPrice("")
                setDiscount("");
                setMyAssetState(0);
                getAssets()
                toast.success("Asset Created")
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true)
                }
            })
        }
        else {
            toast.warning("Fill All the fields");
        }
    }

    const UpdateAsset = async (e) => {
        e.preventDefault()
        if (assetName || asset_nano_id || assetUseType || status || stock || category || subCategory || condition || brand || availableFrom || availableTill || price) {
            await Axios({
                url: `/api/asset/update/${asset_Id}`,
                method: 'put',
                data: {
                    asset_name: assetName,
                    asset_id: asset_nano_id,
                    asset_usage_type: assetUseType,
                    status: status,
                    stock: stock,
                    category: category,
                    sub_category: subCategory,
                    condition: condition,
                    brand: brand,
                    details: details,
                    available_from: availableFrom,
                    available_till: availableTill,
                    price: price,
                    discount: discount,
                    asset_type:assetsType
                }
            }).then(() => {
                setAssetName("")
                setAsset_nano_id("")
                setAssetUseType("")
                setStatus("")
                setStock("")
                setCategory("")
                setSubCategory("")
                setCondition("")
                setBrand("")
                setDetails("")
                setAvailableFrom("")
                setAvailableTill("")
                setPrice("")
                setDiscount("");
                setMyAssetState(0);
                getAssets()
                toast.success("Asset Updated")
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    setSessionExpire(true)
                }
            })
        }
        else {
            toast.warning("Fill All the fields");
        }
    }

    return (
        <div className='coworking_addAssets_form_root'>
            <div className="coworking_addAssets_form_head">
                <button className='coworking_back_btn btncta' onClick={() => {
                    if (asset_Id) {
                        setMyAssetState(0)
                    }
                    else {
                        setMyAssetState(0)
                        getAssets()
                    }
                }}>
                    <span><KeyboardBackspace /></span>
                    <span>BACK</span>
                </button>
            </div>
            <div className="coworking_addAssets_form_main w_100">
                <div className="coworking_addAssets_form_div">
                    <div className="coworking_addAssets_form_main2">
                        <h4>Add {assetsType} Asset</h4>
                    </div>
                    
                    <form className='coworking_addAssets_form'>
                        <div className="row">
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="assetName" className='caption color_dark_blue'>Asset name</label>
                                    <input
                                        type="text"
                                        placeholder='Enter asset name'
                                        className='body1'
                                        value={assetName}
                                        onChange={(e) => setAssetName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Asset ID</label>
                                            <input
                                                type="text"
                                                placeholder='Enter the asset ID'
                                                className='body1'
                                                value={asset_nano_id}
                                                onChange={(e) => setAsset_nano_id(e.target.value)}
                                                required
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Asset usage type</label>
                                            <input
                                                type="text"
                                                placeholder='Enter the asset usage type'
                                                className='body1'
                                                value={assetUseType}
                                                onChange={(e) => setAssetUseType(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Status</label>
                                            <select
                                                name="status"
                                                className='body1'
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                required
                                            >
                                                <option value="" className='body1'>Select status of asset</option>
                                                <option value="active" className='body1'>Active</option>
                                                <option value="inactive" className='body1'>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Stock</label>
                                            <input
                                                type="number"
                                                placeholder='Enter total available asset'
                                                className='body1'
                                                value={stock}
                                                onChange={(e) => setStock(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    {/* <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="assetName" className='caption color_dark_blue'>Category</label>
                                            <select
                                                name="status"
                                                className='body1'
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                required
                                            >
                                                <option value="" className='body1'>Select the asset category</option>
                                                <option value="active" className='body1'>Active</option>
                                                <option value="deactive" className='body1'>Deactive</option>
                                            </select>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Category</label>
                                            <input
                                                type="text"
                                                placeholder='Enter Category of asset'
                                                className='body1'
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Sub-category</label>
                                            <input
                                                type="text"
                                                placeholder='Enter sub-category of asset'
                                                className='body1'
                                                value={subCategory}
                                                onChange={(e) => setSubCategory(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Condition" className='caption color_dark_blue'>Condition</label>
                                            <select
                                                name="status"
                                                id='Condition'
                                                className='body1'
                                                value={condition}
                                                onChange={(e) => setCondition(e.target.value)}
                                                required
                                            >
                                                <option value="" className='body1'>Select the condition of asset </option>
                                                <option value="New" className='body1'>New</option>
                                                <option value="Used" className='body1'>Used</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Stock" className='caption color_dark_blue'>Brand</label>
                                            <input
                                                type="text"
                                                placeholder='Enter brand of asset'
                                                className='body1'
                                                value={brand}
                                                onChange={(e) => setBrand(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="coworking_addAssets_input_main">
                                    <label htmlFor="assetName" className='caption color_dark_blue'>Details</label>
                                    {/* <div className="coworking_addAssets_input_add"> */}
                                    <input
                                        type="text"
                                        placeholder='Write details of the asset'
                                        className='body1'
                                        value={details}
                                        onChange={(e) => setDetails(e.target.value)}
                                    />
                                    {/* <button onClick={() => {
                                            setDetailsArr((prev) => [...prev, details])
                                            setDetails("")
                                        }}>+</button>

                                    </div> */}
                                </div>
                            </div>
                            {/* {
                                detailsArr.length > 0 ?
                                    <div className="col-12 mb-12">
                                        {
                                            detailsArr.map((detail, i) => <div className="asset_details_arr mb-6" key={i}>
                                                <p className="caption">{detail}</p>
                                                <button onClick={() => {
                                                    const newArr = detailsArr.filter((data, id) => id !== i)
                                                    setDetailsArr(newArr)
                                                }} >X</button>
                                            </div>)
                                        }

                                    </div> : null
                            } */}
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Availablefrom" className='caption color_dark_blue'>Available from</label>
                                            <input
                                                type="date"
                                                id="Availablefrom"
                                                className='body1'
                                                value={availableFrom}
                                                onChange={(e) => setAvailableFrom(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Availabletill" className='caption color_dark_blue'>Available  till</label>
                                            <input
                                                type="date"
                                                id="Availabletill"
                                                className='body1'
                                                value={availableTill}
                                                onChange={(e) => setAvailableTill(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                assetsType === 'External' && (
                                    <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Pricing" className='caption color_dark_blue'>Pricing</label>
                                            <input
                                                type="number"
                                                id="Pricing"
                                                placeholder='€1,000'
                                                className='body1'
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="coworking_addAssets_input_main">
                                            <label htmlFor="Discount" className='caption color_dark_blue'>Discount % (optional)</label>
                                            <input
                                                type="text"
                                                id="Discount"
                                                placeholder='Enter only if applicable'
                                                className='body1'
                                                value={discount}
                                                onChange={(e) => setDiscount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                )
                            }
                            <div className="col-12" >
                                <div className="coworking_addAssets_btn_main mb-40">
                                    {
                                        isUpdate ?
                                            <button className='primaryFilled_activeCta' type='submit' onClick={UpdateAsset}>UPDATE ASSET</button> :
                                            <button className='primaryFilled_activeCta' type='submit' onClick={handleCreateAsset}>ADD ASSET</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddAssetsForm