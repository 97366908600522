import React, { useState, useEffect } from 'react'
import { KeyboardBackspace, ArrowDropUp, ArrowDropDown } from "@mui/icons-material"
import './viewAsset.css';
import alertImg from "../../../../../Assets/images/icons/alert.png"
import Card from './Card';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../../Utils/Auth';
import Axios from "axios";
import { toast } from "react-toastify"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './stateCalender.css';

const ViewAsset = ({ setAssetView }) => {
    const [quantity, setQuantity] = useState(0)
    const [estate, setEstate] = useState(null)
    const [fromDate, setFromDate] = useState(null);
    const [tillDate, setTillDate] = useState(null);
    const { setSessionExpire } = useAuth();
    const [date, setDate] = useState(new Date());
    const [availableStock, setAvailableStock] = useState(0);
    const [availables, setAvailabels] = useState([])

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const estateId = query.get("estate");

    const getEstate = async () => {
        await Axios({
            url: `/api/realEstate/estate/${estateId}`,
            method: "get",
        }).then((res) => {
            setEstate(res.data.data);
            setDate(new Date() > new Date(res.data.data.available_from) ? new Date() : new Date(res.data.data.available_from))
            setAvailabels(res.data.data.availables)
            setAvailableStock(Number(res.data.data.stock) - Number(res.data.data.stock_booked))
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        })
    }

    const addToCart = async () => {
        if (fromDate || tillDate) {
            if (fromDate !== tillDate) {
                await Axios({
                    url: `/api/realEstate/cart/create`,
                    method: "post",
                    data: {
                        id: estateId,
                        quantity: Number(quantity),
                        from: fromDate,
                        till: tillDate,
                    }
                }).then((res) => {
                    if (res.data.success) {
                        toast.success('Added to Cart')
                    }
                }).catch((err) => {
                    if (err.response && err.response.status === 401) {
                        setSessionExpire(true);
                    }
                })
            }
            else {
                toast.error("Date must not be same")
            }
        }
        else {
            toast.warning("Please Select Date")
        }
    }

    const returnTileContent = (e) => {
        // console.log(new Date(new Date(e.date).toLocaleDateString()))
        if ((new Date(new Date(e.date).toLocaleDateString()).getTime()) < (new Date(new Date(date).toLocaleDateString()).getTime())) {
            return ""
        }
        else {
            if (availables.length > 0) {
                const found = availables.find((data) => new Date(new Date(data.date).toLocaleDateString()).getTime() === new Date(new Date(e.date).toLocaleDateString()).getTime())
                if (found) {
                    return <p className='coworking_startup_availableStock overline'><span className='coworking_startup_availablespan mr-6'></span>Available {found.available}</p>;
                }
                else {
                    return <p className='coworking_startup_availableStock overline'><span className='coworking_startup_availablespan mr-6'></span>Available {availableStock}</p>
                }
                // const newArr = availables.some((data)=>new Date(new Date(data.date).toLocaleDateString()).getTime() === new Date(new Date(e.date).toLocaleDateString()).getTime())
                // console.log(newArr)
                // return availables.find((data, i) => new Date(new Date(data.date).toLocaleDateString()).getTime() === new Date(new Date(e.date).toLocaleDateString()).getTime()) ? <p className='coworking_startup_availableStock overline'><span className='coworking_startup_availablespan mr-6'></span>Available {availables[i].available}</p>  : <p className='coworking_startup_availableStock overline'><span className='coworking_startup_availablespan mr-6'></span>Available {availableStock}</p>
            }
            else {
                return <p className='coworking_startup_availableStock overline'><span className='coworking_startup_availablespan mr-6'></span>Available {availableStock}</p>
            }
        }
    }

    useEffect(() => {
        getEstate()
    }, [])

    return (
        <div className='viewReq_root'>
            <div className="viewReq_head">
                <button className='transparent_cta btncta' onClick={() => {
                    setAssetView(0)
                }}><KeyboardBackspace /> Back</button>
            </div>
            <div className="viewReq_main mt-24">
                <div className="viewReq_main_header">
                    <div className="row">
                        <div className="col-lg-8 col-md-7 col-12">
                            {
                                estate !== null ?
                                    <div className="viewReq_main_header_cardleft">
                                        <div className="viewReq_m_header_lcard_head">
                                            <div className='d-flex align-items-center'>
                                                <img src={alertImg} alt="alert" />
                                                <p className='overline color_dark_black_sec'>{estate.stock_booked} orders placed until now</p>
                                            </div>
                                            <div>
                                                <p className="caption color_dark_grey2">estate id : {estate.real_estate_id}</p>
                                            </div>
                                        </div>
                                        <div className='pt-16 pl-24 pr-24 pb-40'>
                                            <div className="viewReq_m_header_lcard_profile d-flex align-items-center">
                                                <img src="https://picsum.photos/64" alt="profile" />
                                                <div className="s_estate_card_profile_name ml-12">
                                                    <p className="body1">{estate.real_estate_name}</p>
                                                    <p className="overline color_dark_grey2 d-flex align-items-center">
                                                        <span>{estate.category}</span>
                                                        <span className='startup_assets_ellipse'></span>
                                                        <span>{estate.sub_category}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="viewReq_m_header_lcard_desc mt-16">
                                                <p className="body2 color_dark_grey2">
                                                    {estate.details}
                                                </p>
                                            </div>
                                            <div className="viewReq_m_header_lcard_table_main mt-24">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <table className="viewReq_m_header_lcard_table w_100">
                                                            {/* <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Order ID </td>
                                                                <td className='body1 color_dark_blue pb-16'>OD16VC20052022</td>
                                                            </tr> */}
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Amount</td>
                                                                <td className='body1 color_dark_blue pb-16'>{estate.price}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Status</td>
                                                                <td className='body1 color_dark_blue pb-16'>{estate.status === "active" ? "Active" : "Inactive"}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1'>Stock Available</td>
                                                                <td className='body1 color_dark_blue'>{estate.stock}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-12">
                                                        <table className="viewReq_m_header_lcard_table w_100">
                                                            {/* <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Booked from</td>
                                                                <td className='body1 color_dark_blue pb-16'>{new Date(estate.available_from).toLocaleDateString()}</td>
                                                            </tr> */}
                                                            {/* <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Booked till</td>
                                                                <td className='body1 color_dark_blue pb-16'>{new Date(estate.available_till).toLocaleDateString()}</td>
                                                            </tr> */}
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Type</td>
                                                                <td className='body1 color_dark_blue pb-16'>{estate.category}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>No. of Seats Availale</td>
                                                                <td className='body1 color_dark_blue pb-16'>{estate.seats}</td>
                                                            </tr>
                                                            <tr className='viewReq_m_header_lcard_tr'>
                                                                <td className='body1 pb-16'>Area per Seat</td>
                                                                <td className='body1 color_dark_blue pb-16'>{estate.area_size} sqft.</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> : null
                            }
                            {/* calender */}

                            {
                                estate !== null ?
                                    <div className="startup_state_calender_root mt-24">
                                        <div className="startup_state_calender_head p-16 d-flex justify-content-between align-items-center">
                                            <div className="startup_calender_head_date">
                                                <p className="body2 color_dark_grey2">Book From : &nbsp;&nbsp;</p>
                                                <input
                                                    type="text"
                                                    className='body1'
                                                    placeholder='mm/dd/yyyy'
                                                    value={fromDate}
                                                    disabled
                                                />
                                            </div>
                                            <div className="startup_calender_head_date">
                                                <p className="body2 color_dark_grey2">Book Till : &nbsp; &nbsp;</p>
                                                <input
                                                    type="text"
                                                    className='body1'
                                                    placeholder='mm/dd/yyyy'
                                                    value={tillDate}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="startup_state_calender_main">
                                            <Calendar
                                                tileContent={returnTileContent}
                                                onChange={(e) => {
                                                    setDate(e)
                                                    setFromDate(new Date(e[0]).toLocaleDateString())
                                                    setTillDate(new Date(e[1]).toLocaleDateString())
                                                    if (new Date(e[0]).toLocaleDateString() === new Date(e[1]).toLocaleDateString()) {
                                                        toast.warn("Date should not be same")
                                                    }
                                                }}
                                                value={date}
                                                selectRange={true}
                                                minDate={new Date() > new Date(estate.available_from) ? new Date() : new Date(estate.available_from)}
                                                maxDate={new Date(estate.available_till)}
                                            />
                                        </div>
                                        <div className="startup_state_calender_footwer d-flex justify-content-end align-items-center pb-24 pr-24">
                                            <div className="viewAsset_card_quantity d-flex align-items-center mr-24">
                                                <p className="body2 color_dark_grey2 mr-8">Quantity : </p>
                                                <div className="viewAsset_card_quan_input_main">
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        value={quantity}
                                                        onChange={(e) => setQuantity(e.target.value)}
                                                        className="subtitle"
                                                    />
                                                    <div className="viewAsset_card_quan_btn_main">
                                                        <button onClick={() => setQuantity(Number(quantity) + 1)}><ArrowDropUp /></button>
                                                        <button onClick={() => {
                                                            if (quantity > 0)
                                                                setQuantity(quantity - 1)
                                                        }}><ArrowDropDown /></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <button className='primaryFilled_activeCta' onClick={addToCart}>Add to cart</button>
                                            </div>
                                        </div>
                                    </div> : null

                            }

                            <div className="viewAsset_similar_product_root mt-40">
                                <p className='subtitle'>Similar Products</p>
                                <div className="viewAsset_similar_product_main mt-24">
                                    <div className="row">
                                        {
                                            [1, 2, 3, 4].map((data, i) => <Card
                                                data={data}
                                                key={i}
                                            />)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            estate !== null && estate.incubator_profile ?
                                <div className="col-lg-4 col-md-5 col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="viewReq_main_header_cardRight">
                                                <div className="viewReq_main_header_cardRight_head">
                                                </div>
                                                <div className="viewReq_head_cardRight_profile_img">
                                                    <img src={estate.incubator_profile.logo} alt="profile" />
                                                    <div className="viewReq_head_cardRight_profile_name">
                                                        <p className="title">{estate.incubator_profile.name}</p>
                                                        <p className="caption color_dark_grey2">Service Provider  |  {estate.incubator_profile.location.state}</p>
                                                    </div>
                                                </div>
                                                <div className="viewReq_m_header_Rcard_table_main p-24">
                                                    <table className="viewReq_m_header_rcard_table w_100">
                                                        <tr className='viewReq_m_header_rcard_tr'>
                                                            <td className='body2 pb-20'>Industry</td>
                                                            <td className='body2 color_dark_grey2 pb-20'>{estate.incubator_profile.interestedIndustries[0]}</td>
                                                        </tr>

                                                        {/* <tr className='viewReq_m_header_rcard_tr'>
                                                            <td className='body2 pb-20'>Funding Status</td>
                                                            <td className='body2 color_dark_grey2 pb-20'>Pre-seed</td>
                                                        </tr> */}

                                                        {/* <tr className='viewReq_m_header_rcard_tr'>
                                                            <td className='body2 pb-20'>Incubation Date</td>
                                                            <td className='body2 color_dark_grey2 pb-20'>2020</td>
                                                        </tr> */}

                                                        <tr className='viewReq_m_header_rcard_tr'>
                                                            <td className='body2 pb-20'>Website</td>
                                                            <td className='body2 color_dark_grey2 pb-20'>{estate.incubator_profile.companyWebsite}</td>
                                                        </tr>

                                                        <tr className='viewReq_m_header_rcard_tr'>
                                                            <td className='body2'>Contact</td>
                                                            <td className='body2 color_dark_grey2'>{estate.incubator_profile.contact.email}</td>
                                                        </tr>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-24">
                                            <div className="viewAsset_other_products_root">
                                                <div className="viewAsset_other_products_head">
                                                    <p className="subtitle">Other products</p>
                                                </div>
                                                <div className="viewAsset_other_products_body p-16">
                                                    {
                                                        [1, 2, 3, 4].map((data, i) => <div className="viewAsset_other_products mb-12" key={i}>
                                                            <div className="viewAsset_other_prod_main d-flex align-items-center">
                                                                <img src="https://picsum.photos/40" alt="view asset" />
                                                                <div className="viewAsset_other_prod_profile ml-8">
                                                                    <p className="body2">Vernier caliper</p>
                                                                    <p className="overline color_dark_grey2 d-flex align-items-center">
                                                                        <span>Building</span>
                                                                        <span className='startup_assets_ellipse'></span>
                                                                        <span>Cabin</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="viewAsset_other_prod_view">
                                                                <p className="overline color_dark_grey2">view</p>
                                                            </div>
                                                        </div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAsset