import React, { useState, useEffect } from "react";
import Axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAuth } from "./../../../Utils/Auth";
import { Avatar } from "@mui/material";
import { toast } from "react-toastify";
import "./index.css";

const Sharemodal = ({ handleShareClose }) => {
  const [startups, setstartups] = useState([]);
  const [assigned, setassigned] = useState([]);
  const [SearchText, setSearchText] = useState("");
  const [drop, setdrop] = useState(false);
  const { setSessionExpire } = useAuth();

  // get startups
  const fetchallstartups = async () => {
    await Axios({
      url: `/api/incubatorProgram/members`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setstartups(res.data.data);

      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const handleSubmit = async () => {
    var assignedsub = assigned.map((e) => e.id);
    const data = {
      assigned_user: assignedsub,
    };
    await Axios.put(`/api/incmentor/assign/user`, data)
      .then((res) => {
        if (res.data.success) {
          toast.success("Mentor Assigned successfully", {
            position: "bottom-right",
          });

          handleShareClose();
        }
      })
      .catch((res) => {
        toast.error(res.response.data.message, { position: "bottom-right" });
      });
  };

  //  assiged startup
  const assign = (companyName, logo, id) => {
    const user_id = { companyName, logo, id };
    setassigned((prev) => [...prev, user_id]);
    setstartups(startups.filter((user) => user.profile.id !== id));
  };
  const removeassign = (companyName, logo, id) => {
    const user_id = { profile: { companyName, logo, id } };
    setstartups((prev) => [...prev, user_id]);
    setassigned(assigned.filter((user) => user.id !== id));
    // console.log(companyName,logo);
  };

  useEffect(() => {
    fetchallstartups();
    // setassigned(already);
  }, []);

  useEffect(() => {
    // console.log("SearchText",startups)
    if (SearchText !== "") {
      setdrop(true);
    } else {
      setdrop(false);
    }
  }, [SearchText]);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ marginBottom: "40px" }}
      >
        <h5 className="w-fit" style={{ color: "#262C5B" }}>
          Share To
        </h5>
        <CancelIcon style={{ cursor: "pointer" }} onClick={handleShareClose} />
      </div>

      <div>
        <div className="register_page_form row">
          <div className="col-12">
            <label className="auth_label">Search Connections</label>
            <input
              placeholder="Search Connections"
              required
              type="text"
              className="auth_input col-12 "
              onClick={() => setdrop(!drop)}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={SearchText}
            />
          </div>
          {drop ? (
            <div className="col-12">
              <div
                className={`mentor_tasks_options_root row ${
                  drop ? "show" : "hide"
                }`}
              >
                {startups && startups.length > 0
                  ? startups
                      .filter((ele) => {
                        if (SearchText === "") return ele;
                        else if (
                          ele.profile.companyName
                            .toLowerCase()
                            .includes(SearchText.toLocaleLowerCase())
                        ) {
                          return ele;
                        }
                      })
                      .map((data, i) => (
                        <div
                          className="mentor_tasks_options point w-100"
                          key={i}
                        >
                          <div className="d-flex justify-content-between w-100 mx-2">
                            <div className="d-flex align-items-center">
                              <img src={data.profile.logo} alt="profile" />
                              <p className="caption color_dark_black2">
                                {data.profile.companyName}
                              </p>
                            </div>
                            <div
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                assign(
                                  data.profile.companyName,
                                  data.profile.logo,
                                  data.profile.id
                                );
                                setdrop(!drop);
                              }}
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0 3.25C0 2.38805 0.34241 1.5614 0.951903 0.951903C1.5614 0.34241 2.38805 0 3.25 0H14.75C15.612 0 16.4386 0.34241 17.0481 0.951903C17.6576 1.5614 18 2.38805 18 3.25V14.75C18 15.612 17.6576 16.4386 17.0481 17.0481C16.4386 17.6576 15.612 18 14.75 18H3.25C2.38805 18 1.5614 17.6576 0.951903 17.0481C0.34241 16.4386 0 15.612 0 14.75V3.25Z"
                                  fill="#262C5B"
                                />
                                <path
                                  d="M9 4C9.19891 4 9.38968 4.07902 9.53033 4.21967C9.67098 4.36032 9.75 4.55109 9.75 4.75V8.25H13.25C13.4489 8.25 13.6397 8.32902 13.7803 8.46967C13.921 8.61032 14 8.80109 14 9C14 9.19891 13.921 9.38968 13.7803 9.53033C13.6397 9.67098 13.4489 9.75 13.25 9.75H9.75V13.25C9.75 13.4489 9.67098 13.6397 9.53033 13.7803C9.38968 13.921 9.19891 14 9 14C8.80109 14 8.61032 13.921 8.46967 13.7803C8.32902 13.6397 8.25 13.4489 8.25 13.25V9.75H4.75C4.55109 9.75 4.36032 9.67098 4.21967 9.53033C4.07902 9.38968 4 9.19891 4 9C4 8.80109 4.07902 8.61032 4.21967 8.46967C4.36032 8.32902 4.55109 8.25 4.75 8.25H8.25V4.75C8.25 4.55109 8.32902 4.36032 8.46967 4.21967C8.61032 4.07902 8.80109 4 9 4Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ))
                  : null}
              </div>
            </div>
          ) : null}

          <div className="col-12 d-flex flex-wrap mt-24">
            {assigned && assigned.length > 0
              ? assigned.map((ele, i) => (
                  <div
                    className="w-fit mt-8 assigned-items d-flex mr-8"
                    key={i}
                  >
                    <Avatar
                      src={ele.logo}
                      sx={{ width: 24, height: 24 }}
                      style={{ marginRight: "4px" }}
                    />
                    <div className="ele_hover" style={{ paddingLeft: "40px" }}>
                      <div className=" d-flex align-items-center">
                        <p
                          className="caption mr-12"
                          style={{ color: "#262C5B" }}
                        >
                          {ele.companyName}
                        </p>

                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            removeassign(ele.companyName, ele.logo, ele.id);
                          }}
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 5C0 2.235 2.235 0 5 0C7.765 0 10 2.235 10 5C10 7.765 7.765 10 5 10C2.235 10 0 7.765 0 5ZM6.445 7.15C6.64 7.345 6.955 7.345 7.15 7.15C7.34 6.955 7.34 6.635 7.15 6.445L5.705 5L7.15 3.555C7.345 3.36 7.345 3.045 7.15 2.85C6.955 2.655 6.64 2.655 6.445 2.85L5 4.295L3.555 2.85C3.36 2.655 3.045 2.655 2.85 2.85C2.75637 2.94341 2.70376 3.07024 2.70376 3.2025C2.70376 3.33476 2.75637 3.46158 2.85 3.555L4.295 5L2.85 6.445C2.75637 6.53842 2.70376 6.66524 2.70376 6.7975C2.70376 6.92976 2.75637 7.05658 2.85 7.15C3.045 7.345 3.36 7.345 3.555 7.15L5 5.705L6.445 7.15Z"
                            fill="#262C5B"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
          <div
            className="col-4 gradient_CTA"
            style={{ marginTop: "40px", cursor: "pointer" }}
            onClick={handleSubmit}
          >
            Share
          </div>
        </div>
      </div>
    </>
  );
};

export default Sharemodal;
