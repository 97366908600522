import React, { useEffect, useState } from "react";
import { useAuth } from "../../../..//Utils/Auth";
import Axios from "axios";
import Applicantcard from "./Applicantcard";
import Avatar from "@mui/material/Avatar";
import {ApplicationSkeleton} from '../../../../Components/SkeletonLoader/ProgramDashboard';
const AllAplicantCard = ({
  programid,
  step,
  setstep,
  startupid,
  setstartupid,
  requestid,
  setrequestid,
}) => {
  const { setSessionExpire } = useAuth();
  const [applicants, setapplicants] = useState([]);
  const [isLoading,setIsLoading] = useState(false); //
  // const [step, setstep] = useState(0);
  // const [startupid, setstartupid] = useState("");
  // const [requestid, setrequestid] = useState("");

  const fetchapplicants = async () => {
    setIsLoading(true)
    await Axios({
      url: `/api/incubatorProgram/requests/${programid}/false`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setapplicants(res.data.data)
          setIsLoading(false)
        }
      })
      .catch((err) => {
        setIsLoading(false)
        if (err.response.status === 401) {
          setSessionExpire(true);
          
        }
      });
  };

  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {step === 1 ? (
        <Applicantcard
          setstep={setstep}
          startupid={startupid}
          requestid={requestid}
          callbackfunc={fetchapplicants}
          programid={programid}
        />
      ) : (
        <>
        {
          isLoading ? <ApplicationSkeleton/> : applicants.length > 0 ? (
            <>
              {applicants.map((v, i) => {
                return (
                  <div
                    key={i}
                    className="col-lg-3 col-md-6 col-12 mb-4"
                    onClick={() => {
                      setstep(1);
                      setstartupid(v.profile._id);
                      setrequestid(v._id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                   
                    <div className="incubation_profile_card_main">
                      <div className="incubation_profile_card_head">
                        <div className="incubation_profile_card_profile">
                          <Avatar
                            src={v.profile.logo}
                            className=""
                            alt="Profile"
                            sx={{ width: 60, height: 60 }}
                          />
                        </div>
                      </div>
                      <div className="incubation_profile_card_title">
                        <p>{v.profile.companyName}</p>
                        <div className="incubation_profile_card_subtitle">
                          <p className="p_LightGrey12 mr-2">Startup</p>{" "}
                          <span></span>{" "}
                          <p className="p_LightGrey12 ml-2">
                            {v.profile.location.state.length === 0
                              ? v.profile.location.country
                              : v.profile.location.state}
                          </p>
                        </div>
                      </div>
                      <div className="incubation_profile_card_chip">
                        <div className="incubation_chips">
                          {v.profile.industry}
                        </div>
                        <div className="incubation_chips">
                          {v.profile.audience}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : <p>Applicants not found</p>}
        </>
      )}
    </>
  );
};

export default AllAplicantCard;
