import React, { useState } from "react";
import NumberFormat from "react-number-format";
import industries from "./../../../Lists/Industries.json";
// import ClearIcon from "@mui/icons-material/Clear";
// import CircularProgress from "@mui/material/CircularProgress";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
// import Axios from "axios";

const Step2 = (props) => {
  const {
    CurrentEvaluation,
    fundraise,
    profile,
    setfundraise,
    setValuation,
    setProfile,
    otherIndustry,
    setOtherIndustry,
    setOtherGrowth,
    otherGrowth,
    otherFundingStatus,
    setOtherFundingStatus,
    targetAudience,
    setTargetAudience,
    DocumentsNeed,
    // setCompanyDocuments,
    industry,
    setIndustry,
    growth,
    setGrowth,
    FundingStatus,
    NumberOfEmployees,
    setNumberOfEmployees,
    setFundingStatus,
  } = props;

  // const [docType, setDocType] = useState("");
  const [, setisdocloading] = useState(false);
  const [formatedValue, setFormatedValue] = useState(CurrentEvaluation);
  const [formatedValuef, setFormatedValuef] = useState(fundraise);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      industry.length === 0 ||
      targetAudience.length === 0 ||
      growth.length === 0 ||
      NumberOfEmployees.length === 0 ||
      FundingStatus.length === 0
    )
      return toast.error("Empty fields");
    if (DocumentsNeed.length < 1) {
      toast.error("Please upload documents", { position: "bottom-right" });
      return;
    } else {
      setProfile({
        ...profile,
        DocumentsNeed,
      });
      localStorage.setItem("Profile", JSON.stringify(profile));
      setisdocloading(false);
    }
  };

  // const handleDocumentsChange = (e) => {
  //   const file = e.target.files[0];
  //   if (
  //     file.type !== "image/jpg" &&
  //     file.type !== "image/png" &&
  //     file.type !== "image/jpeg" &&
  //     file.type !== "application/pdf"
  //   ) {
  //     toast.error("Only Image And PDF files are allowed!");
  //     return;
  //   }
  //   if (file.size > 2048 * 1024) {
  //     toast.error("File size exceeded 2MB!");
  //     return;
  //   }

  //   const formdata = new FormData();
  //   formdata.append("document", file);
  //   setisdocloading(true);
  //   Axios({
  //     method: "post",

  //     url: " /api/upload/document",

  //     data: formdata,
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       const filename = {
  //         docType: docType,
  //         docName: e.target.files[0].name,
  //         docExt: e.target.files[0].type,
  //         downloadUrl: data.location,
  //       };
  //       setCompanyDocuments((docs) => [...docs, filename]);
  //       setisdocloading(false);
  //       toast.success("Document uploaded", { position: "bottom-right" });

  //       setDocType(null);
  //     })
  //     .catch(() => {
  //       toast.error("Error in upload", { position: "bottom-right" });
  //     });
  // };

  function handleValuation(e) {
    setFormatedValue(e.target.value);
    let s = e.target.value;
    if (s) {
      var n = s.split("$", 2)[1];
      setValuation(Number(n.split(",").join("")));
    }
  }
  function handleFund(e) {
    setFormatedValuef(e.target.value);
    let s = e.target.value;
    if (s) {
      var n = s.split("$", 2)[1];
      setfundraise(Number(n.split(",").join("")));
    }
  }

  // const deleteDocument = (e, i) => {
  //   e.preventDefault();
  //   setCompanyDocuments((oldItems) => {
  //     return oldItems.filter((arrelement, index) => {
  //       return index != i;
  //     });
  //   });
  // };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      {/* <div className="auth_head">
        <p className="p_small_whiteGray">Please fill company domain details</p>
        <hr style={{ background: "rgb(159, 159, 159)" }} />
      </div> */}
      <div
        className=" register_page_form row"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        <div className="col-12 col-md-6">
          <label>Number of Employees*</label>
          <select
            className="auth_input auth_select"
            name="no_of_emp"
            value={NumberOfEmployees}
            onChange={(e) => setNumberOfEmployees(e.target.value)}
          >
            <option value="">Select Number of Employees</option>
            <option value="0-5">0-5</option>
            <option value="5-15">5-15</option>
            <option value="15-30">15-30</option>
            <option value="30-50">30-50</option>
            <option value="More than 50">More than 50</option>
          </select>
          </div>
          <div className="col-12 col-md-6">
            <label>Target Audience*</label>
          <select
            className="auth_input auth_select"
            name="target_audience"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
          >
            <option value="">Select Target Audience</option>
            <option value="Business To Business">Business To Business</option>
            <option value="Business To Customers">Business To Customers</option>
            <option value="Customers To Customers">
              Customers To Customers
            </option>
            <option value="Business To Government">
              Business To Government
            </option>
            <option value="Government To Customers">
              Government To Customers
            </option>
            <option value="Government To Business">
              Government To Business
            </option>
          </select>
          </div>
          <div className="col-12 col-md-6">
            <label>Funding Status*</label>
          <select
            className="auth_input auth_select"
            name="funding_status"
            value={FundingStatus}
            onChange={(e) => setFundingStatus(e.target.value)}
          >
            <option value="">Select Funding Status</option>
            <option value="Bootstrap">Bootstrap</option>
            <option value="Pre-Seed">Pre-Seed</option>
            <option value="Seed">Seed</option>
            <option value="Series A">Series A</option>
            <option value="Series B">Series B</option>
            <option value="Series C">Series C</option>
            <option value="None">None</option>
            <option value="others">Others</option>
          </select>
          </div>

          {FundingStatus === "others" ? (
            <>
            <div className="col-12 col-md-6">
              <label>Funding Status</label>
              <input
                type="text"
                placeholder="Please specify your funding status"
                className="auth_input"
                value={otherFundingStatus}
                onChange={(e) => setOtherFundingStatus(e.target.value)}
              />
              </div>
            </>
          ) : null}
          <div className="col-12 col-md-6">
            <label>Current Growth*</label>
          <select
            className="auth_input auth_select"
            name="current_growth"
            value={growth}
            onChange={(e) => setGrowth(e.target.value)}
          >
            <option value="">Select Current Growth</option>
            <option value="Pre Startup/R&D">Pre-Startup/R&amp;D</option>
            <option value="MVP/Prototype">MVP/Prototype</option>
            <option value="Early Customers and Iteration">
              Early Customers &amp; Iteration
            </option>
            <option value="Scaling and Expansion">
              Scaling &amp; Expansion
            </option>
            <option value="Product Market Fit and Monestisation">
              Product Market Fit &amp; Monestisation
            </option>
            <option value="others">Others</option>
          </select>
          </div>
          

          {growth === "others" ? (
            <>
            <div className="col-12 col-md-6">
              <label>Current Growth</label>
              <input
                type="text"
                placeholder="Please specify your current growth"
                className="auth_input"
                value={otherGrowth}
                onChange={(e) => setOtherGrowth(e.target.value)}
              />
              </div>
            </>
          ) : null}

          <div className="col-12 col-md-6">
            <label>Industry*</label>
          <select
            className="auth_input auth_select"
            name="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          >
            <option value="">Select Industry</option>
            {industries.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
          </div>

          {industry === "Other" ? (
            <>
            <div className="col-12 col-md-6">
            <label>Industry</label>
              <input
                type="text"
                placeholder="Please specify your industry"
                className="auth_input"
                value={otherIndustry}
                onChange={(e) => setOtherIndustry(e.target.value)}
              />
            </div>
            </>
          ) : null}
          
          <div className="col-12 col-md-6">
              <label>Current Valuation</label>
          <NumberFormat
            placeholder="Current Valuation"
            displayType={"number"}
            thousandSeparator={true}
            prefix={"$ "}
            className="auth_input some"
            inputmode="numeric"
            value={formatedValue}
            onChange={(e) => handleValuation(e)}
          />
          </div>
          <div className="col-12 col-md-6">
            <label>Amout you want to raise</label>
          <NumberFormat
            placeholder="Amount you want to Raise"
            displayType={"number"}
            thousandSeparator={true}
            prefix={"$ "}
            className="auth_input some"
            inputmode="numeric"
            value={formatedValuef}
            onChange={(e) => handleFund(e)}
          />
          </div>
        </div>
      

      {/* <div className="loginbtns">
        {activeStep === 0 ? null : (
          <button
            type="submit"
            className="backbtn"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            <ArrowBackIosNewIcon />
          </button>
        )}

        <button
          type="submit"
          className="forbtn"
          onClick={(e) => handleSubmit(e)}
        >
          <ArrowForwardIosIcon />
        </button>
      </div> */}
      {/* <button type="submit" onClick={handleSubmit}>Submit</button> */}
    </form>
  );
};

export default Step2;
