import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../Utils/Auth";
// import logo from "./../../../Assets/images/events/logo.png";
import calendar from "./../../../Assets/images/events/calendar.png";
import clock from "./../../../Assets/images/events/clock.png";
// import amount from "./../../../Assets/images/events/amount.png";
// import Avatar from '@mui/material/Avatar';
// import AvatarGroup from '@mui/material/AvatarGroup';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import { loadStripe } from "@stripe/stripe-js";
import PlanModal from "../../../Components/Modal/PlanUgrade";
// import {useNavigate} from 'react-router-dom'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const index = () => {
  const { programid } = useParams();
  // const { eventId } = props;
  // const key=programid;
  const [event, setevent] = useState(null);
  const { setSessionExpire } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [openP, setPOpen] = useState(false);
  // const handlePOpen = () => setPOpen(true);
  const handlePClose = () => setPOpen(false);
  const [disable, setDisable] = useState(false);

  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const history = useNavigate()

  const fetchEvent = async () => {
    await axios({
      method: "get",
      url: `/api/incubatorProgram/program/${programid}`,
    })
      .then((res) => {
        if (res.data.success) setevent(res.data.data);
        // (res.data);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        if (error.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  // const handleStart = (key) => {
  //   window.open(
  //     `/jitsi-event/${key}?title=${event.event.title}&host=${event.event.hostCompany}`
  //   );
  // };

  const get_Date = (date) => {
    let d = new Date(date);
    return d.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  // const get_time = (date, time, timezone) => {
  //   let datetime = date + "T" + time;
  //   var t = new Date(datetime).toLocaleTimeString("en-US", {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //   });
  //   return t + " " + timezone.split(" ", 3)[0];
  // };

  // const handleFreeRegister = async (id) => {
  //   if (user.plan === "Free") {
  //     handlePOpen();
  //     return;
  //   }
  //   await axios({
  //     url: `/api/events/participate/${id}`,
  //     method: "post",
  //   })
  //     .then((res) => {
  //       if (res.status === 201) {
  //         handleClose();
  //         toast.success("Registered", { position: "bottom-right" });
  //         fetchEvent();
  //       }
  //     })
  //     .catch(() => {
  //       toast.error("Could not register, try later", {
  //         position: "bottom-right",
  //       });
  //     });
  // };

  // const handleRegister = async (id) => {
  //   if (user.plan === "Free") {
  //     handlePOpen();
  //     return;
  //   }
  //   setDisable(true);
  //   if (stripe) {
  //     await axios({
  //       url: `/api/payment/event-checkout/${id}`,
  //       method: "post",
  //     })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           stripe.redirectToCheckout({ sessionId: res.data.id });
  //           setLoader(false);
  //           handleClose();
  //         }
  //       })
  //       .catch(() => {
  //         toast.error("Could not register, try later", {
  //           position: "bottom-right",
  //         });
  //         setDisable(false);
  //       });
  //   }
  // };

  useEffect(() => {
    async function fetchConfig() {
      const stripepromise = await loadStripe(
        "pk_live_51JqTdkGcUuatUK7QHwIvQjp4PpJ4P7lYX1wutnVX2GysCqBWQd7m7nHTACDrPS9YkJHqN9OtL0Bcl90I1mWxX3x800Z9bUj5KC"
      );
      setStripe(stripepromise);
    }
    fetchConfig();
  }, []);

  useEffect(() => {
    fetchEvent();
  }, [programid]);

  useEffect(() => {
    setDisable(false);
    setLoader(false);
    var flag=false;
    if(flag)
    console.log(stripe);
  });

  if (event === null) return <p>Loading...</p>;

  return (
    <>
      <div className="container ">
        <PlanModal open={openP} handleClose={handlePClose} />
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="event-banner">
              <img src={event.coverImage} alt="banner" />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="event-page-box p-5">
              <div className="event-box-pill">
                {/* {event.event.keywords.map((obj,ind)=>{
                        return (<span key={ind} style={{marginRight: "5px"}} className="badge badge-pill badge-light">{obj}</span>);
                      })} */}
                <span className="badge badge-pill badge-light">
                  {event.type}
                </span>
              </div>
              <h4 className="event-box-head mt-3 mb-3">{event.title}</h4>
              <div className="event-box-organise">Organised by</div>
              <div className="event-box-image mt-3">
                <img src={event.id.avatar} alt="logo" className="mr-3" />
                {event.id.name}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3 mb-5">
          <div className="col-12 col-md-8 w-100 mt-5">
            <div className="event-main p-3">
              <p className="event-main-head">About Program</p>
              <div className="event-main-line"></div>
              <p className="event-main-text">{event.description}</p>
              <p className="event-main-sidehead">Highlights </p>
              <div className="ml-4">
                <ol>
                  {event.highlights.map((obj, ind) => {
                    return (
                      <li key={ind} className="mb-3">
                        {obj}
                      </li>
                    );
                  })}
                </ol>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 pl-4">
                  <p className="event-main-sidehead">Benefits</p>
                  <ul>
                    {event.benefits.map((obj, ind) => {
                      return (
                        <li key={ind} className="mb-3">
                          {obj}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <p className="event-main-sidehead">Eligibility</p>
                  <ol>
                    {event.eligibility.map((obj, ind) => {
                      return (
                        <li key={ind} className="mb-3">
                          {obj}
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
              <p className="event-main-sidehead">Documents</p>
              <ol>
                {event.requiredDocs.map((obj, ind) => {
                  return (
                    <li key={ind} className="mb-3">
                      {obj}
                    </li>
                  );
                })}
              </ol>
              <div></div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-5">
            <div className="event-page-detail p-5">
              <div className="event-page-detail-section">
                <img src={calendar} alt="calendar" />
                <span>{get_Date(event.startDate)}</span>
              </div>
              <div className="event-page-detail-section">
                <img src={clock} alt="clock" />
                <span>{event.duration}</span>
                {/* <span>{get_time(event.event.eventTime.date,event.event.eventTime.time,event.event.eventTime.timezone)}</span> */}
              </div>
              {/* <div className="event-page-detail-section">
                <img src={amount} alt="amount" />
                <span>30</span>
                <span>{event.event.isFree ? "Free" : "EUR " + event.event.eventFees}</span>
              </div> */}

              {/* <div className=" d-flex justify-content-center">
              {user && user.id === event.id._id ? (
                  <>
                    {event ? (
                    <div
                      className="gradient_btn_main text-dark"
                      onClick={() => handleStart(event.event.key)}
                    >
                      <div className="gradient_btn_light">
                      <p>START</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {event.monthDif === "Expired" ? (
                      <div className="gradient_btn_main text-dark" disable>
                      <div className="gradient_btn_light">
                        EXPIRED
                        </div>
                      </div>
                    ) : (
                    <div
                      className="gradient_btn_main text-dark"
                      onClick={() => handleOpen()}
                    >
                      <div className="gradient_btn_light">
                      REGISTER NOW
                      </div>
                    </div>
                    )}
                  </>
                )}
              </div> */}
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="close-modal-main">
                <button onClick={handleClose}>
                  <CancelIcon />
                </button>
              </div>
              <div className="modal-main-text">
                <div className="modalEvent-title">
                  <h6>EVENT TITLE: {event.title}</h6>
                </div>
                <div className="modalEvent-date-details">
                  <div>
                    <BusinessCenterIcon />{" "}
                    {/* <p>{get_Date(event.event.eventTime.date)}</p> */}
                    <p>30</p>
                  </div>
                  <div>
                    <WatchLaterIcon />{" "}
                    {/* <p>
                        {get_time(
                          event.event.eventTime.date,
                          event.event.eventTime.time,
                          event.event.eventTime.timezone
                        )}
                      </p> */}
                    <p>30</p>
                  </div>

                  <div>
                    <HourglassFullIcon />
                    <p>30</p>
                    {/* <p>{event.diff}</p> */}
                  </div>
                </div>
                <div className="modalEvent-paybleAmount">
                  <p>Total Amount Payable</p>
                  {/* <h5> {event.event.isFree ? "Free" : "EUR " + event.event.eventFees} </h5> */}
                  <span>30</span>
                </div>
                {loader ? <></> : null}
                <div className="modalPay-options-main">
                  <div className="modalpay-btn-main">
                    {/* {event.event.isFree ? ( */}
                    {event ? (
                      <button
                        className="gradient_CTA"
                        // onClick={() => handleFreeRegister(event._id)}
                      >
                        REGISTER
                      </button>
                    ) : (
                      <>
                        {loader ? (
                          <></>
                        ) : (
                          <button
                            className="gradient_CTA"
                            disabled={disable}
                            // onClick={() => handleRegister(event._id)}
                          >
                            PAY
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default index;
