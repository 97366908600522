import React, { useState } from "react";
import Axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@material-ui/core";
import { toast } from "react-toastify";
import Exceltojson from "../../../Utils/ExcelToJson/exceltojson";

const InviteModal = ({ handleClose, list }) => {
  const [mail, setmail] = useState("");
  const [inviteMails, setInviteMails] = useState([]);

  const addObjective = (arr, seto, v, setv) => {
    if (arr) {
      seto((prev) => [...prev, v]);
      setv("");
    }
  };

  const removeObjective = (arr, seto, id) => {
    const newArr = arr.filter((obj, i) => {
      return id !== i;
    });
    seto(newArr);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendInvite = async () => {
    if (inviteMails.length === 0) {
      return toast.error("Enter atleast one email");
    }
    const data = {
      emails: inviteMails,
      type: list,
    };
    await Axios.post(`/api/incubatorProgram/email-invite-member/`, data)
      .then((res) => {
        if (res.data.success)
          toast.success("Invite sent successfully", {
            position: "bottom-right",
          });
        handleClose();
      })
      .catch((res) => {
        toast.error(res.response.data.message, { position: "bottom-right" });
      });
  };
  const handleexcelmails = async (emails) => {
    if (emails.length === 0) {
      return toast.error("Empty Excel sheet");
    }
    if (!emails[0].Emails) {
      return toast.error("Excel should have a column named 'Emails'");
    }
    for (let i = 0; i < emails.length; i++) {
      if (validateEmail(emails[i].Emails))
        setInviteMails((prev) => [...prev, emails[i].Emails]);
    }
  };

  return (
    <>
      <form>
        <div className="register_page_form row">
          <h5 style={{ color: " #262C5B" }}>Invite via email</h5>
          <div className="col-12">
            <label className="auth_label">Add emails</label>
            <div className="goalsdiv">
              <input
                type="text"
                name="objective"
                className="auth_input"
                placeholder="xyz@mail.com"
                id="objective"
                value={mail}
                onChange={(e) => setmail(e.target.value)}
              />
              <Tooltip title="Add More Rows">
                <button
                  type="button"
                  onClick={() => {
                    if (!validateEmail(mail))
                      return toast.warning("Enter valid email");

                    addObjective(inviteMails, setInviteMails, mail, setmail);
                  }}
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </div>
            <Exceltojson handleexcelmails={handleexcelmails} />
            <p style={{ fontSize: "12px" }}>
              Excel should have column heading as &ldquo;Emails&ldquo;,{" "}
              <a
                href="https://twfprogram.s3.eu-central-1.amazonaws.com/The+Wise+Folks.xlsx"
                rel="noreferrer"
                target="_blank"
                style={{ textDecoration: "underline" }}
              >
                download sample excel
              </a>
            </p>
            <div className="excelmails">
              {inviteMails
                ? inviteMails.map((obj, i) => {
                    return (
                      <>
                        <span
                          className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3"
                          key={i}
                        >
                          <p className="program_text">{obj}</p>

                          <Tooltip title="Delete Row">
                            <button
                              type="button"
                              title="Remove"
                              onClick={() =>
                                removeObjective(inviteMails, setInviteMails, i)
                              }
                              className="program_btn"
                            >
                              x
                            </button>
                          </Tooltip>
                        </span>
                      </>
                    );
                  })
                : null}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              onClick={() => sendInvite()}
              type="button"
              className="gradient_CTA"
            >
              &nbsp; &nbsp; SEND INVITE&nbsp; &nbsp;{" "}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default InviteModal;
