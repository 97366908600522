import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import {
  CalendarToday,
  Money,
  AccessTime,
  KeyboardBackspace,
} from "@mui/icons-material";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ShowDoc from "../../../Dashboard/Components/IncubationTool/ViewProgram/ShowDoc";
import UploadDocComp from "../../../Dashboard/Components/IncubationTool/ViewProgram/UploadDocComp";
import "../../../Components/Css/common.css";
import Query from "../../../Utils/Query/Query";
// import PaymentSuccess from '../../../../../Assets/images/financial/payment_success.svg'
import PaymentSuccess from "../../../Assets/images/financial/payment_success.svg";
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

const ProgramDetails = ({
  id,
  setIncubationState,
  setFetchBool,
  fetchBool,
  isPaid,
}) => {
  const query = Query();
  const navigate = useNavigate();
  const paymentSuccess = Boolean(query.get("success"));
  const [program, setProgram] = useState(null);
  const { setSessionExpire, user } = useAuth();
  const [showLoader, setShowLoader] = useState(true);
  const [request, setRequest] = useState(null);
  const [monthDiff, setMonthDiff] = useState("");
  const [, setisexpired] = useState(null);
  const [, setdiff] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [documentsRequired, setdocumentsRequired] = useState([]);
  const [isNeedUpload, setIsNeedUpload] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    navigate(`/service/userprogram/${id}?tab=0`);
  };

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [gst, setGst] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [billingModalOpen, setBillingModalOpen] = useState(false);

  const handleOpenBillingModal = () => setBillingModalOpen(true);

  const handleCloseBillingModal = () => {
    setBillingModalOpen(false);
  };

  const handleBilling = (e) => {
    e.preventDefault();
    if (
      address.length <= 0 ||
      city.length <= 0 ||
      state.length <= 0 ||
      pinCode.length <= 0 ||
      phoneNumber.length <= 0 ||
      email.length <= 0
    ) {
      toast.warning("Please fill mandatory fields");
      return;
    } else {
      Axios({
        url: `/api/paidProgram/info/user/${user.id}`,
        method: "put",
        data: {
          address,
          city,
          state,
          pinCode,
          gstNumber: gst,
          email,
          phoneNumber,
        },
      }).then((res) => {
        if (res.data.success) {
          displayRazorpay();
          handleCloseBillingModal();
        }
      }).catch = () => {
        toast.error("Something went wrong");
      };
    }
  };
  const fetchBillingAdd = () => {
    Axios({
      url: `/api/paidProgram/info/user/${user.id}`,
      method: "get",
    }).then((res) => {
      if (res.data.success) {
        if (res.data.data) {
          const { billing_add } = res.data.data;
          setAddress(billing_add.address);
          setCity(billing_add.city);
          setState(billing_add.state);
          setPinCode(billing_add.pinCode);
          setGst(billing_add.gstNumber);
          setPhoneNumber(billing_add.phoneNumber);
          setEmail(billing_add.email);
        }
      }
    });
  };

  useEffect(() => {
    fetchBillingAdd();
  }, []);

  //payment
  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      return toast("Payment failed to load, try again later");
    }
    await Axios({
      url: `/api/paidProgram/razorpay/${program._id}/${user.id}`,
      method: "post",
    })
      .then((res) => {
        const { data, item } = res.data;
        console.log(data);
        var options = {
          // key: "rzp_test_0ajbWdUbbV6vTl",
          key: "rzp_test_iVFxChcWvaQPTJ",
          amount: data.amount,
          currency: data.currency,
          name: "Zefyron Incubation",
          description: "Program Payment",
          image:
            "https://twf-attachment.s3.ap-south-1.amazonaws.com/user-registration-uploads/620fc4214bbfd96516f22edb/event-cover/AMbQG_5ZKVOf5q8SG_uJN.png",
          order_id: data.id,
          callback_url: `/api/paidProgram/check-success/${data.id}/${item.incubator_id}`,
          prefill: {
            name: item.client_name,
            email: data.notes.email,
            contact: data.notes.phoneNumber,
          },
          notes: {
            item_local_invoice_id: item._id,
            name: item.client_name,
            email: item.email_id,
            contact: item.contact_no,
            cart_id: item.cart_id,
            created_by: item.created_by,
            for_user: item.for_user,
            tools: item.tools,
            item_details: item.item_details,
            program_id: item.program_id._id,
            program_name: item.program_id.title,
            billingAddress: data.notes,
          },
          theme: {
            color: "#ff6f1e",
          },
        };
        const PaymentObject = new window.Razorpay(options);
        PaymentObject.open();
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
        script.onerror = () => {
          resolve(false);
        };
      };
      document.body.appendChild(script);
    });
  }

  const fetchprogram = async () => {
    await Axios({
      url: `/api/incubatorProgram/program/${id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          setProgram(res.data.data);
          setRequest(res.data.request);
          setMonthDiff(res.data.monthDif);
          setdiff(res.data.diff);
          setisexpired(res.data.isExpired);
          let documents = res.data.data.requiredDocs;
          let docs = [];
          for (let i = 0; i < documents.length; i++) {
            docs.push({ doc_name: documents[i], doc_link: null });
          }
          setdocumentsRequired(docs);

          if (res.data.request) {
            if (res.data.data.requiredDocs.length > 0) {
              if (res.data.request.documents.length > 0) {
                setIsNeedUpload(false);
              } else {
                setIsNeedUpload(true);
              }
            } else {
              setIsNeedUpload(false);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // if (err.response.status === 500) {
        setSessionExpire(true);
        setShowLoader(false);
        // }
      });
  };

  const sendRequest = async () => {
    for (let index = 0; index < documentsRequired.length; index++) {
      const element = documentsRequired[index];
      if (element.doc_link === null)
        return toast.error(`Upload ${element.doc_name}`);
    }
    await Axios({
      url: `/api/incubatorProgram/send/${id}`,
      method: "post",
      data: {
        documents: documentsRequired,
        is_accepted: true,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setFetchBool(!fetchBool);
          toast.success("Successfully Uploaded");
          fetchprogram();
          handleClose();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const handleremovedoc = (doc_name) => {
    let array2 = documentsRequired.map((a) => {
      return { ...a };
    });
    array2.find((a) => a.doc_name == doc_name).doc_link = null;
    setdocumentsRequired(array2);
  };

  useEffect(() => {
    fetchprogram();
  }, []);
  useEffect(() => {
    if (paymentSuccess === true) {
      handleModalOpen();
    }
  }, []);
  return (
    <>
      {program === null ? (
        showLoader ? (
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ height: "100%" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="backtoincubation d-flex align-content-center ">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIncubationState(1)}
              >
                <KeyboardBackspace />
              </span>
              <p className="body1 ml-4">Program not found</p>
            </div>
          </>
        )
      ) : (
        <div className="incubation_program_root">
          <div className="incubatrion_profile_program_head">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="incubation_profile__head_card1_root">
                  <div className="incubationprofile_card1_head">
                    <p className="p_grey_chip p_normalBlack10">
                      {program.sector}
                    </p>
                    {/* <button>edit</button> */}
                  </div>
                  <div className="incubationprofile_card1_title">
                    <h4>{program.title}</h4>
                  </div>

                  <div className="incubationprofile_card1_link">
                    <a href={program.website}>{program.website}</a>
                  </div>
                  <div className="incubationprofile_card1_hostedBy mt-4">
                    <p className="p_lightGrey10">Hosted By</p>
                    <div className="incubationprofile_card1_hostedPro">
                      <Avatar
                        src={program.id.avatar}
                        style={{ width: 40, height: 40 }}
                      />
                      <p className="p_white20 ml-3">{program.id.name}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-12">
                <div className="incubation_profile__head_card2_root">
                  <img
                    src={program.coverImage}
                    alt="profile_inc"
                    className="img-fluid"
                    style={{ width: "100%", borderRadius: "12px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="startup_incubation_viewProprgam_root mt-40">
            <div className="row">
              <div className="col-lg-8 col-md-12 col-12">
                <div className="startup_incubation_viewProprgam_main dahsboard_card">
                  <div className="startup_incubation_viewProprgam_head">
                    <p className="subtitle">{program.title} </p>
                  </div>
                  <div className="startup_incubation_viewProprgam_desc">
                    <p className="body2">{program.description} </p>
                  </div>
                  <div className="startup_incubation_viewProprgam_highlites">
                    <p className="body1 color_dark_blue">Highlights</p>
                    <div className="startup_incubation_viewProprgam_high_ul">
                      <ul>
                        {program.highlights && program.highlights.length > 0
                          ? program.highlights.map((data, i) => (
                              <li key={i} className="body2 pb-3">
                                {data}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                  <div className="startup_incubation_viewProprgam_benElig">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12 startup_incubation_viewProprgam_benefit">
                        <p className="body1 color_dark_blue">Benefits</p>
                        <ul>
                          {program.benefits && program.benefits.length > 0
                            ? program.benefits.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 startup_incubation_benefit startup_incubationeligibility">
                        <p className="body1 color_dark_blue">Eligibility</p>
                        <ul>
                          {program.eligibility && program.eligibility.length > 0
                            ? program.eligibility.map((data, i) => (
                                <li key={i} className="body2 pb-3">
                                  {data}
                                </li>
                              ))
                            : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="startup_incubation_viewProprgam_documents">
                    <p className="body1 color_dark_blue">Documents</p>
                    <div className="startup_incubation_docs">
                      <ul>
                        {program.requiredDocs && program.requiredDocs.length > 0
                          ? program.requiredDocs.map((data, i) => (
                              <li key={i} className="body2 pb-3">
                                <a href={data}> {data}</a>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12 col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-6 col">
                    <div className="startup_incubation_viewProprgam_infor dahsboard_card">
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center">
                          <span>
                            <CalendarToday />
                          </span>
                          {new Date(program.startDate).toDateString()}
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center text-uppercase">
                          <span>
                            <Money />
                          </span>
                          {program?.programPaymentType === "paid" ? (
                            <span>€{program?.participationAmmount}</span>
                          ) : (
                            <span>FREE</span>
                          )}
                        </p>
                      </div>
                      {/* <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center text-uppercase">
                          <span>
                            <CurrencyRupeeIcon />
                          </span>
                          Pending
                        </p>
                      </div> */}
                      <div className="startup_incubation_viewProprgam_date">
                        <p className="body1 d-flex align-content-center caps">
                          <span>
                            <AccessTime />
                          </span>
                          {monthDiff === "Live" || monthDiff === "Expired"
                            ? "Live"
                            : monthDiff}
                        </p>
                      </div>
                      <div className="startup_incubation_viewProprgam_btn d-flex justify-content-center align-items-center pb-3">
                        {!isPaid && program?.participationAmmount > 0 && (
                          <button
                            className="cta"
                            onClick={handleOpenBillingModal}
                            style={{
                              background:
                                "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)",
                              border: "none",
                            }}
                          >
                            Pay
                          </button>
                        )}
                        {(request === null || isNeedUpload) && isPaid ? (
                          <button
                            className="cta"
                            onClick={handleOpen}
                            style={{
                              background:
                                "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)",
                              border: "none",
                            }}
                          >
                            Upload Documents
                          </button>
                        ) : null}
                        {request === null ? null : request.is_accepted &&
                          !request.isDeclined &&
                          monthDiff === "Live" &&
                          isPaid ? (
                          <button className="cta">Go to Program</button>
                        ) : request.is_accepted &&
                          !request.isDeclined &&
                          monthDiff !== "Live" &&
                          isPaid ? (
                          <button className="cta">
                            {monthDiff === "Expired"
                              ? "LIVE"
                              : "Starts in " + monthDiff}
                          </button>
                        ) : !request.is_accepted && !request.isDeclined ? (
                          <button className="cta" disabled>
                            PENDING
                          </button>
                        ) : !request.is_accepted && request.isDeclined ? (
                          <button className="cta" disabled>
                            Rejected
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <img
              src={PaymentSuccess}
              alt="payment success"
              width="16%"
              className="my-3"
            />
            <h5 className="mt-3">Payment Success</h5>
            {(request === null || isNeedUpload) && (
              <p className="text-center my-3">
                As a next step, please upload your documents for acceessing the
                program
              </p>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className="register_page_form row">
            <div className="col-12">
              {documentsRequired.length > 0 ? (
                documentsRequired.map((v, i) => {
                  return (
                    <div key={10 * Math.random() + v.doc_name}>
                      {v.doc_link ? (
                        <ShowDoc v={v} handleremovedoc={handleremovedoc} />
                      ) : (
                        <UploadDocComp
                          i={i}
                          v={v}
                          docname={v.doc_name}
                          documentsRequired={documentsRequired}
                          setdocumentsRequired={setdocumentsRequired}
                          handleOpen={handleOpen}
                          handleClose={handleClose}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <p>No Documents required</p>
              )}

              <div className="d-flex justify-content-center mt-5">
                <button
                  onClick={() => sendRequest()}
                  type="button"
                  className="gradient_CTA"
                >
                  Upload Documents
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={billingModalOpen}
        onClose={handleCloseBillingModal}
        closeAfterTransition
        BackdropProps={{
          timeout: 300,
        }}
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className="register_page_form row">
            <div className="col-12">
              <label className="auth_label p_secNaviBlue12 mt-0">
                Billing Address *
              </label>
              <input
                placeholder="Enter the billing address"
                type="text"
                className="auth_input mt-0"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              <label className="auth_label p_secNaviBlue12 mt-0">City *</label>
              <input
                placeholder="Enter the your city"
                type="text"
                className="auth_input mt-0"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              <label className="auth_label p_secNaviBlue12 mt-0">State *</label>
              <input
                placeholder="Enter the your state"
                type="text"
                className="auth_input mt-0"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label className="auth_label p_secNaviBlue12 mt-0">
                Pin Code *
              </label>
              <input
                placeholder="Enter the your pin code"
                type="text"
                className="auth_input mt-0"
                value={pinCode}
                onChange={(e) => setPinCode(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              <label className="auth_label p_secNaviBlue12 mt-0">
                Phone Number *
              </label>
              <input
                placeholder="Enter the your number"
                type="number"
                className="auth_input mt-0"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12">
              <label className="auth_label p_secNaviBlue12 mt-0">Email *</label>
              <input
                placeholder="Enter the your email"
                type="text"
                className="auth_input mt-0"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-12">
              <label className="auth_label p_secNaviBlue12 mt-0">
                GST Number
              </label>
              <input
                placeholder="Enter the GST number"
                type="text"
                className="auth_input mt-0"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-end mt-1 float-right w-100">
              <button
                onClick={handleBilling}
                type="button"
                className="gradient_CTA float-right"
              >
                Proceed to pay
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProgramDetails;
