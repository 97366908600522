import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import Avatar from "@mui/material/Avatar";
import Loader from "../../../Components/Loader";
import { toast } from "react-toastify";
import { KeyboardArrowLeft } from "@mui/icons-material";

const Applicantcard = ({ setstep, startupid, serviceid }) => {
  const [startup, setstartup] = useState(null);
  const [service, setservice] = useState(null);
  const [request, setrequest] = useState(null);
  const { setSessionExpire } = useAuth();

  const getDetail = async () => {
    await Axios({
      method: "get",
      url: `/api/startup/user-by-id/${startupid}`,
    })
      .then((res) => {
        setstartup(res.data.data);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const getservice = async () => {
    try {
      const response = await Axios.get(`/api/service/servicebyid/${serviceid}`);
      if (response.data.success) setservice(response.data.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };
  const getrequest = async () => {
    await Axios({
      method: "get",
      url: `/api/service/checkrequestsp/${startupid}/${serviceid}`,
    })
      .then((res) => {
        setrequest(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const sendRequest = () => {
    Axios({
      method: "get",
      url: `/api/service/sendrequestsp/${startupid}/${serviceid}`,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Service request sent");
          getrequest();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.err(err.response.data.error);
      });
  };
  const withDrawRequest = () => {
    Axios({
      method: "get",
      url: `/api/service/withdrawrequests/${request._id}`,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Service request withdrawn");
          getrequest();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.err(err.response.data.error);
      });
  };

  useEffect(() => {
    getDetail();
    getservice();
    getrequest();
  }, []);

  if (startup === null || service === null) return <Loader />;

  return (
    <>
      <div className="col-12 mb-24">
        <span
          className="back w-fit"
          onClick={() => {
            setstep(0);
          }}
        >
          <KeyboardArrowLeft />
          Back
        </span>
      </div>
      <hr />
      <div className="col-12 row mt-24">
        <div className="col-12 col-lg-8">
          <div className="connection_profile_root" id="connection_profile_root">
            <div className="row mt-2 justify-content-center connection_profile_head dashboard01_connection_profile_head">
              <div className="width_auto no-padding">
                <Avatar
                  src={startup.logo}
                  className=""
                  alt="Profile"
                  sx={{ width: 64, height: 64 }}
                  variant="rounded"
                />
              </div>
              <div className="col-10 d-flex flex-column justify-content-center">
                <p
                  className="p_spartan_20 subtitle"
                  style={{ color: "#262C5B" }}
                >
                  {startup.id.name}
                </p>
                <p className="caption mt-4" style={{ color: "#565656" }}>
                  {service.hosttype} | {startup.location.country}
                </p>
              </div>
            </div>
            <div style={{ margin: "40px 0" }}>
              <p className="grey bold">{startup.description}</p>
            </div>

            <div className="row mx-2 my-3">
              <p className="p_grey_chip">{startup.industry} </p>
            </div>
            <hr style={{ margin: "40px 0px" }} />

            <div className="mb-4">
              <p>Services needed</p>
              {/* <p className="p_grey_chip">{service.keyword}</p> */}
              <div className="">
                <div className="user_course_desc pt-3 ml-16">
                  <ul>
                    {service.services.map((v, i) => (
                      <li key={i}>{v}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div style={{ margin: "80px 0" }}>
                {request === null ? (
                  <div
                    onClick={() => sendRequest()}
                    className="gradient_CTA width_fit mr-3"
                  >
                    OFFER SERVICE
                  </div>
                ) : !request.is_accepted ? (
                  <div disabled className="gradient_CTA width_fit mr-3">
                    PENDING
                  </div>
                ) : request.is_accepted ? (
                  <div
                    onClick={() => {
                      withDrawRequest();
                    }}
                    className="gradient_btn_main width_fit mr-3"
                  >
                    <div className="gradient_btn_light">
                      WITHDRAW SERVICE OFFER
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="connection_profile_root_highligts">
            {startup.id ? (
              <>
                <div className="" style={{ padding: "16px 24px" }}>
                  <p className="title w-fit">Founder Details</p>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ padding: "16px 24px" }}
                >
                  <div className="width_auto no-padding ">
                    <Avatar
                      src={startup.profilePic}
                      className="mr-16"
                      sx={{ width: 80, height: 80 }}
                    />
                  </div>

                  <div className="">
                    <p className="founder-title" style={{ fontSize: "16px" }}>
                      {startup.id.firstname} {startup.id.lastname}
                    </p>
                    <p className="founder-job">{startup.contact.jobTitle} </p>
                    <p className="bold">
                      <a href={`${startup.socialConnects.linkedin}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#0077B5"
                          className="bi bi-linkedin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                        </svg>
                      </a>
                    </p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div className="connection_profile_root_highligts mt-24">
            {/* <div className="connection_profile_root_highligts_head">
              <p className="p_spartan_20">Highlights</p>
            </div> */}
            <div className="connection_profile_root_highligts_desc">
              <table>
                {startup.valuation && (
                  <tr>
                    <td className="td_left">Valuation</td>
                    <td className="td_right">$ {startup.valuation}</td>
                  </tr>
                )}
                <tr>
                  <td className="td_left">Audience</td>
                  <td className="td_right">{startup.audience}</td>
                </tr>

                <tr>
                  <td className="td_left">Employees</td>
                  <td className="td_right">{startup.employees}</td>
                </tr>
                <tr>
                  <td className="td_left">Funding Series</td>
                  <td className="td_right">{startup.fundingSeries}</td>
                </tr>
                <tr>
                  <td className="td_left">Incorporation</td>
                  <td className="td_right">
                    {new Date(startup.incorporationDate).getFullYear()}
                  </td>
                </tr>

                <tr>
                  <td className="td_left">Website</td>
                  <td className="td_right" style={{ wordBreak: "break-all" }}>
                    <a
                      href={`${startup.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {startup.website}
                    </a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Applicantcard;
