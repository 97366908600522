import React from "react";
import "./step1.css";
import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
import { Tooltip } from "@material-ui/core";
// import upload from "../../../../Assets/images/events/upload.png";
// import PropTypes from "prop-types";
// import { toast } from "react-toastify";
import cloud_upload from "../../../../Assets/images/upload_doc.png"


const Step1 = (props) => {
  const {
    title,
    setTitle,
    description,
    setDescription,
    objectiveVal,
    setObjectiveVal,
    addObjective,
    removeObjective,
    objectives,
    criteriaVal,
    setCriteriaVal,
    criteria,
    addCriteria,
    removeCriteria,
    handleCoverImg,
    setCoverImg,
    coverImg,
    // setActiveStep,
    // activeStep,
    // eventDetails,
    // seteventDetails,
  } = props;


  return (
    <>
      <form className="incubator_create_program_form">
        <div className="register_page_form row">
          <div className="auth_back_loginform col-12">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              name="title"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter Title"
            />
          </div>
          <div className="auth_back_loginform col-12">
            <label htmlFor="description">Short Description</label>
            <textarea
              name="description"
              id="description"
              rows="3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Write a Short Description"
            ></textarea>
          </div>
          <div className="auth_back_loginform col-12">
            <label className="auth_label p_secNaviBlue12 mt-0">Cover Image <span className="p_LightGrey12">(PNG and JPG files are allowed, below 10MB file size)</span> </label>
            {coverImg === null || coverImg === "" ? (
              <>
                <div className="program_upload_img" style={{cursor:"pointer"}}>
                  <img
                    src={cloud_upload}
                    alt="cloud_upload"
                    style={{ padding: "20px" }}
                  />
                  <label htmlFor="uplaodImg" style={{margin:"12px 0px"}}>
                    Upload Cover image
                  </label>
                  <input
                    type="file"
                    id="uplaodImg"
                    accept="image/*"
                    onChange={(e) => handleCoverImg(e)} />
                </div>
              </>
            ) : (
              <>
              <div className="del-box">
                <figure id="coverUploadfigure">
                  <img
                    className="img-fluid event-cover-img"
                    src={coverImg}
                    alt="upload"
                  />
                </figure>
                <p className="p_button del-btn" onClick={() => setCoverImg(null)}>
                  Delete
                </p>
                </div>
              </>
            )}
          </div>
          <div className="auth_back_loginform col-12">
            <label className="auth_label">Objectives</label>
            <div className="goalsdiv">
              <input
                type="text"
                name="objective"
                className="auth_input"
                placeholder="Enter your objective"
                id="objective"
                value={objectiveVal}
                onChange={(e) => setObjectiveVal(e.target.value)}
              />
              <Tooltip title="Add">
                <button
                  type="button"
                  onClick={
                    addObjective
                  }
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </div>

            <div className="goalsscroll">
              {objectives
                ? objectives.map((obj, i) => {
                  return (
                    <>
                      <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                        <p className="program_text">{obj}</p>
                        <Tooltip title="Delete Row">
                          <button
                            type="button"
                            title="Remove"
                            onClick={() => removeObjective(i)}
                            className="program_btn"
                          >
                            x
                          </button>
                        </Tooltip>
                      </span>
                    </>
                  );
                })
                : null}
            </div>
          </div>

          <div className="auth_back_loginform col-12">
            <label className="auth_label">Criteria</label>
            <div className="goalsdiv">
              <input
                type="text"
                name="criteria"
                id="criteria"
                value={criteriaVal}
                onChange={(e) => setCriteriaVal(e.target.value)}
                placeholder="Enter your criteria"
              />
              <Tooltip title="Add">
                <button
                  type="button"
                  onClick={addCriteria}
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </div>

            <div className="goalsscroll">
              {criteria
                ? criteria.map((obj, i) => {
                  return (
                    <>
                      <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                        <p className="program_text">{obj}</p>
                        <Tooltip title="Delete Row">
                          <button
                            type="button"
                            title="Remove"
                            onClick={() => removeCriteria(i)}
                            className="program_btn"
                          >
                            x
                          </button>
                        </Tooltip>
                      </span>
                    </>
                  );
                })
                : null}
            </div>
          </div>

        </div>
      </form>
      {/* <div className="savenextbtn">
        <button className="gradient_CTA" type="submit" onClick={handleNext}>
          SAVE & NEXT
        </button>
      </div> */}
    </>
  );
};

// Step1.propTypes = {
//   //   questionnare: PropTypes.any,
//   handleNext: PropTypes.any,
// };

export default Step1;
