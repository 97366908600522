import React from 'react';
import DeleteModal from '../../../../Utils/DeleteModal/DeleteModal';
import { Delete } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const AssessmentModal = (props) => {
    const { assesments, courseId, data, setAddSessionState, getModules, getSessions } = props;
    const {programid} = useParams();
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const deleteAssessment = async (e) => {
        e.preventDefault()
        await axios({
            url: `/api/mentorModules/assessment/update/${assesments._id}`,
            method: "put",
            data: {
                is_deleted: true
            }
        })
            .then(() => {
                toast.success("Assessment Deleted");
                getModules();
                getSessions();
            })
            .catch(() => {
                toast.error("Something went wrong, try again");
            });
    }

    return <>
        <div className="row justify-content-between mx-0 my-0 session_inc_main">
            <p className="body2">{assesments.name}</p>
            <div className="d-flex align-items-center module_edit_btn">
                <Tooltip title="Delete">
                    <p style={{ color: "#989898" }} onClick={handleOpen} className="modulecomp_Sessicons"><Delete /></p>
                </Tooltip>
                <Tooltip title="Edit">
                    <p className="caption point modulecomp_Sessicons" onClick={() => {
                        navigate(
                            `/service/mentorprogram/${programid}?tab=5&course=${courseId}&module=${data._id}&assesment=${assesments._id}`
                        );
                        setAddSessionState(2)

                    }} >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            fill="#989898"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                        >
                            <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"></path>
                            <path
                                fillRule="evenodd"
                                d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"
                            ></path>
                        </svg>
                    </p>
                </Tooltip>
            </div>
        </div>
        <DeleteModal
        open={open}
        handleClose={handleClose}
        deleteFunction={deleteAssessment}
      />
    </>
}

export default AssessmentModal