import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const SimpleLineChart = ({data, isEstate}) => {
    return (
        <ResponsiveContainer width="100%" height={315}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={isEstate === 0 ? "Asset": isEstate ===1 ?"Real_estate" : "User"} stroke={(isEstate === 0 || isEstate === 1)?"#6200EE":"#121843"} />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default SimpleLineChart;