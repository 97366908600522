import React, { useState, useEffect } from 'react'
import Card from './Card'
import Axios from "axios";
import { useAuth } from '../../../../../Utils/Auth';
import './allpay.css'

const AllPay = ({setformState}) => {
    const [allPays, setAllPays] = useState([])
    const { setSessionExpire } = useAuth()

    const getInvoices = async () => {
        await Axios({
            url: `/api/inc-finance/all/incubator`,
            method: "GET",
        }).then((res) => {
            setAllPays(res.data.data)
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true)
            }
        })
    }


    useEffect(() => {
        getInvoices()
    }, [])

    return (
        <div className='incubation_allPay_root'>
            <div className="row">
                {
                    allPays.map((data) => <Card
                        data={data}
                        key={data._id}
                        setformState={setformState}
                    />)
                }
            </div>
        </div>
    )
}

export default AllPay