import React, { useState, useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import LogoNavbar from "../../../Layout/Navbar/LogoNavbar";
import NotFoundPage from "../../404Pages";
import { toast } from "react-toastify";
import Axios from "axios";
import LocationComponent from "../../../Utils/Location/index";
import { CircularProgress } from "@mui/material";
import OTPService from "../../../Utils/OTP";

const Register_Employee = () => {
    const { user } = useAuth();
    const [country, setCountry] = useState("");
    const [state, setstate] = useState("");
    const [city, setcity] = useState("");
    const [name, setName] = useState(``);
    const [jobTitle, setJobTitle] = useState("")
    const [joiningDate, setJoiningDate] = useState("")
    const [designation, setDesignation] = useState("")
    const [department, setDepartment] = useState("")
    const [profilepic, setprofilepic] = useState(null);
    const [islogoloading, setislogoloading] = useState(false);
    const [cities, setcities] = useState(null);
    const [states, setstates] = useState(null);
    const [phone, setPhone] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [phoneVerified, setphoneVerified] = useState(false)



    useEffect(() => {
        if (user) {
            setName(`${user.firstname} ${user.lastname}`);
        }
    }, [user]);

    const handleSubmit = async () => {
        if (profilepic === null) return toast.warning("Upload profile image");
        if (
            name ||
            profilepic ||
            jobTitle ||
            city ||
            state ||
            country ||
            phoneVerified ||
            phone ||
            designation||
            department||
            linkedin ||
            joiningDate
        ) {
            await Axios({
                method: "post",
                url: "/api/employee/create",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                },
                data: {
                    name: name,
                    designation: designation,
                    linkedin: linkedin,
                    department: department,
                    profile_pic: profilepic,
                    joining_date: joiningDate,
                    location: {
                        country: country,
                        state: state,
                        city: city,
                    },
                    contact: {
                        phone: phone,
                        jobTitle: jobTitle,
                    },
                },
            })
                .then((res) => {
                    if (res.data.success) {
                        toast.success("Employee registered", { position: "bottom-right" });
                        location.href = "/dashboard/employee?tab=1920"
                    }
                })
                .catch(() => {
                    toast.error("Internal Server Error", { position: "bottom-right" });
                });
        } else {
            toast.warning("All Fields are required");
        }
    };

    const handleLogoUpload = (e) => {
        const image = e.target.files[0];

        if (
            image.type !== "image/jpg" &&
            image.type !== "image/png" &&
            image.type !== "image/jpeg"
        ) {
            toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
            return;
        }
        if (image.size > 2048 * 1024) {
            toast.error("File size exceeded 2MB!", { position: "bottom-right" });
            return;
        }
        const formdata = new FormData();
        formdata.append("employee-profile", image);
        setislogoloading(true);
        Axios({
            method: "post",
            url: "/api/employee/profile",
            data: formdata,
        })
            .then((res) => {
                const { data } = res;
                if (data) {
                    setprofilepic(data.location);
                } else {
                    toast.error("Error in upload", { position: "bottom-right" });
                }
                // setProfile({
                //     ...profile,
                //     profilepic: data.location,
                // });
                setislogoloading(false);
                toast.success("Profile Picture uploaded", { position: "bottom-right" });
                // setProgress(25);
            })
            .catch(() => {
                setislogoloading(false);
                toast.error("Error in upload", { position: "bottom-right" });
            });
    };


    if (!user) return <NotFoundPage />;
    return (
        <div className="regis">
            <LogoNavbar />

            <div className="register_page">
                {/* -------------STEP 1------------------ */}
                <div className="container pt-5">
                    <div className="row d-flex align-items-center">
                        <div className="col-12 col-md-4">
                            <div className="register_page_sideborder">
                                <div className="mb-16">
                                    <a
                                        href="#part1"
                                        className="register_page_sideborder-head w-100"
                                    >
                                        Employee Details
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-1"></div> */}
                        <div className="col-12 col-md-8">
                            <div className="register_page_form" id="part1">
                                <form>
                                    <div className="register_page_form_upload">
                                        {islogoloading ? (
                                            <CircularProgress style={{ color: "black" }} />
                                        ) : (
                                            <>
                                                {profilepic ? (
                                                    <>
                                                        <img
                                                            src={profilepic}
                                                            alt="profilepic"
                                                            className="companyLogo"
                                                        />
                                                        <p
                                                            className="p_small_black mt-2"
                                                            onClick={() => setprofilepic(null)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Delete
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label htmlFor="clogo">
                                                            <div
                                                                className="register_page_form_com_logo"
                                                                onClick={{}}
                                                            >
                                                                Upload
                                                            </div>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                name="profilepic_pic"
                                                                id="clogo"
                                                                value={profilepic}
                                                                onChange={handleLogoUpload}
                                                            />
                                                        </label>
                                                        <p className="register_page_form_upload-text mt-1">
                                                            Profile Image
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>

                                    <div className="register_page_form row">
                                        <div className="col-12">
                                            <label>Employee Name</label>
                                            <input
                                                id="name"
                                                placeholder="Enter Name"
                                                required
                                                type="text"
                                                className="auth_input"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Job Title</label>
                                                    <input
                                                        id="jobtitle"
                                                        placeholder="Job Title"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={jobTitle}
                                                        onChange={(e) => setJobTitle(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Joining Date</label>
                                                    <input
                                                        id="joiningDate"
                                                        placeholder="Joining Date"
                                                        required
                                                        type="date"
                                                        className="auth_input"
                                                        value={joiningDate}
                                                        onChange={(e) => setJoiningDate(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Designation</label>
                                                    <input
                                                        id="designation"
                                                        placeholder="Designation"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={designation}
                                                        onChange={(e) => setDesignation(e.target.value)}
                                                    />
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    <label>Department</label>
                                                    <input
                                                        id="department"
                                                        placeholder="Department"
                                                        required
                                                        type="text"
                                                        className="auth_input"
                                                        value={department}
                                                        onChange={(e) => setDepartment(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label>Linkedin</label>
                                            <input
                                                id="designation"
                                                placeholder="LinkedIn"
                                                required
                                                type="url"
                                                className="auth_input"
                                                value={linkedin}
                                                onChange={(e) => setLinkedin(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <label>Phone Number*</label>
                                            <OTPService
                                                phone={phone}
                                                setPhone={setPhone}
                                                setphoneVerified={setphoneVerified}
                                                className="col-12"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <label>Location</label>
                                        </div>
                                        <div className="col-12">
                                            <LocationComponent
                                                country={country}
                                                setcountry={setCountry}
                                                state={state}
                                                setstate={setstate}
                                                city={city}
                                                setcity={setcity}
                                                states={states}
                                                cities={cities}
                                                setstates={setstates}
                                                setcities={setcities}
                                            />
                                        </div>
                                        <div className="col-12 mt-24 mb-40 d-flex justify-content-center align-items-center">
                                            <button
                                                type="button"
                                                className="primaryFilled_activeCta"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register_Employee