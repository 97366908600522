import React, { useEffect, useState } from 'react'
import Axios from "axios"
// import { useAuth } from "../../../../../../Utils/Auth";
import { toast } from 'react-toastify';
import QuestionComp from "./QuestionComp";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AssessmentEditModule from "./AssesmentEditModule"


const AddAssignmentForm = ({ setAddSessionState, assessment, getAssignment }) => {

    const navigate = useNavigate()
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const module_id = query.get('module')
    const course_id = query.get('course')
    const {programid} = useParams();
    // const {user} = useAuth()
    const [assignment, setAssignment] = useState({})
    const [mainData, setMainData] = useState([])
    const [question, setQuestion] = useState("")
    const [question1, setQuestion1] = useState("")
    const [question2, setQuestion2] = useState("")
    const [question3, setQuestion3] = useState("")
    const [question4, setQuestion4] = useState("")
    const [question5, setQuestion5] = useState("")
    const [showAnsKey, setAnsKey] = useState(false);
    const [answer, setAnswer] = useState("");
    const [count, setCount] = useState([1])
    const [showAddQ, setShowAddQ] = useState(false)
    // const navigate = useNavigate()

    // currentModule



    const addQuestion = async (e) => {
        e.preventDefault();
        if (answer === "") {
            return toast.warning("Please select answer key")
        }
        if (count.length < 2) {
            return toast.warning("At least 2 Question should be there")
        }
        await Axios({
            url: `/api/mentorModules/assessment/add/${assessment._id}`,
            method: "put",
            data: {
                question: question,
                option_type: "MCQ",
                options: {
                    option_one: question1,
                    option_two: question2,
                    option_three: question3,
                    option_four: question4,
                    option_five: question5,
                },
                answer: answer
            }
        }).then(() => {
            getAssignment();
            setQuestion("")
            setQuestion1("")
            setQuestion2("")
            setQuestion3("")
            setQuestion4("")
            setQuestion5("")
            setAnswer("")
            setCount([1])
            setAnsKey(false)
            setShowAddQ(false)
            // setAnsKey()
        }).catch((err) => {
            console.log(err)
        })
    }

    const setAnswerKeyVal = (e) => {
        const val = e.target.value;
        if (val === "1") {
            setAnswer(question1)
        }
        else if (val === "2") {
            setAnswer(question2)
        }
        else if (val === "3") {
            setAnswer(question3)
        }
        else if (val === "4") {
            setAnswer(question4)
        }
        else if (val === "5") {
            setAnswer(question5)
        }
    }

    useEffect(() => {
        if (assessment) {
            setAssignment(assessment)
            setMainData(assessment.assessment)
        }
    }, [assessment]);

    return <>
        {
            assignment ? <>
                <h4>{assignment.name} </h4>
                <div className="create_course_form_main">
                    <div className="row">
                        <div className="col-12">
                            <div className="showAllQuesAns_assignForm">
                                {
                                    mainData.length > 0 ? mainData.map((data, i) => <AssessmentEditModule
                                        key={i}
                                        data={data}
                                        i={i}
                                        setAnswerKeyVal={setAnswerKeyVal}
                                        addQuestion={addQuestion}
                                        setAnsKey={setAnsKey}
                                        showAnsKey={showAnsKey}
                                        mainData={mainData}
                                        assessment={assessment}
                                        answer={answer}
                                        setAnswer={setAnswer}
                                        getAssignment={getAssignment}
                                        setShowAddQ={setShowAddQ}
                                    />) : null
                                }

                            </div>
                        </div>

                        {
                            showAddQ ? <QuestionComp
                                question={question}
                                question1={question1}
                                question2={question2}
                                question3={question3}
                                question4={question4}
                                question5={question5}
                                setQuestion={setQuestion}
                                setQuestion1={setQuestion1}
                                setQuestion2={setQuestion2}
                                setQuestion3={setQuestion3}
                                setQuestion4={setQuestion4}
                                setQuestion5={setQuestion5}
                                count={count}
                                setCount={setCount}
                                setAnsKey={setAnsKey}
                                showAnsKey={showAnsKey}
                                addQuestion={addQuestion}
                                setAnswerKeyVal={setAnswerKeyVal}
                                pos={mainData.length}
                            // updateQ={updateQ}
                            /> : null
                        }

                        {
                            showAddQ ? null : <div className="col-12 create_course_form_maindivBtn w_80">
                                <button className='cta newModuleBtn color_dark_blue w_80' onClick={() => setShowAddQ(true)} style={{ background: "transparent" }}>+ Add Question </button>
                            </div>
                        }

                        <div className="col-12 create_course_form_maindivBtn">
                            <button className='primaryFilled_activeCta' onClick={(e) => {
                                e.preventDefault();
                                setAddSessionState(0)
                                navigate(
                                    `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
                                );
                            }}>Save</button>
                        </div>
                    </div>
                </div>
            </> : ""
        }

    </>
}

export default AddAssignmentForm