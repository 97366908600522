import React, { useEffect, useState } from 'react'
import { CalendarToday, FileDownloadOutlined, DescriptionOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Axios from 'axios';
import { useLocation, useParams } from "react-router-dom"
import { useAuth } from "../../../../Utils/Auth";
import { toast } from 'react-toastify';
import Feedback from './Components/Feedback';
import ReactPlayer from 'react-player'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SessionComp = (props) => {
    const {getCourseDetail,setProgress,setShowLockSessions, modules} = props;
    const [value, setValue] = useState(0);
    const [isLast, setisLast] = useState(false);
    const { setSessionExpire } = useAuth()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const query = useQuery();
    const Last = query.get("isLast");
    const session_id = query.get("session");
    const moduleid = query.get("module");
    const { courseid } = useParams()
    const [session, setSession] = useState(null)
    const [markAsCompleteBool, setMarkAsCompleteBool] = useState(false)
    const [has_access, setHas_access] = useState(false);
    const [moduleDesc, setModuleDesc] = useState("");
    useEffect(()=>{
        if(Last)
        {
            setisLast(Last)
        }
    },[Last])
    useEffect(()=>{
        if(Last)
        {
            setisLast(Last)
        }
    },[])

    const getSeession = async () => {
        await Axios({
            url: `/api/mentorModules/session/${courseid}/${session_id}`,
            method: "get",
        }).then((res) => {

            setSession(res.data.data);
            setHas_access(res.data.has_access)
            // setModules(res.data.mod);
        }).catch(() => {
            // if (err.response.status === 401) {
                setSessionExpire(true);
            // }
        });
    }

    const markAsComplete = async () => {
        setShowLockSessions(true)
        await Axios({
            url: `/api/mentorModules/enrolled/session/complete/${courseid}/${session._id}`,
            method: "put",

        }).then(() => {
            setMarkAsCompleteBool(true)
            getEnrolledUser()
            getCourseDetail()
            getSeession()
            toast.success("Completed")
            setShowLockSessions(true)
            setShowLockSessions(false)

        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    const getEnrolledUser = async () => {
        await Axios({
            url: `/api/mentorModules/enrolled/course/${courseid}`,
            method: "get",
        }).then((res) => {
            const { data } = res.data;
            setProgress(res.data.data.progress)
            for (let i = 0; i < data.session_logs.length; i++) {
                if (data.session_logs[i].session_id === session_id && data.session_logs[i].is_completed) {
                    setMarkAsCompleteBool(true);
                    break;
                }
                else {
                    setMarkAsCompleteBool(false);

                }


            }
        }).catch((err) => {
            if (err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    }

    const completeModule = ()=>{
        if(isLast === "true")
        {
            handleOpen()
        }
        else {
            markAsComplete()
        }
    }

    useEffect(() => {
        if (session_id) {
            getSeession();
            getEnrolledUser();
        }
        for (let i = 0; i < modules.length; i++) {
            const element = modules[i];
            if(element._id === moduleid)
            {
                setModuleDesc(element.description);
                break;
            }
        }

    }, [session_id])

    return <>
        {
            has_access ? <div className="userCourses_tabPanel_content_main">
            {
                session === null ? null : <div className="userCourses_tabPanel_contents">
                    <div className="userCourses_tabPanel_content_video_main">
                    <ReactPlayer 
                        url={session.video_link && session.video_link.length>1 ? session.video_link : session.external_video_url} 
                        controls={true}
                        width={`100%`}
                        style={{borderRadius:8}}
                        className="reactPlayer_userProgram"
                    />
                        {/* <video width="100%" height={470} controls controlsList='nodownload'>
                            <source src={session.video_link && session.video_link.length>1 ? session.video_link : session.external_video_url} type="video/*" />
                            <source src={session.video_link && session.video_link.length>1 ? session.video_link : session.external_video_url} type="video/ogg" />

                        </video> */}
                        {/* <iframe width="420" height="345" src={session.video_link ? session.video_link : session.external_video_url}>
                    </iframe> */}
                    </div>
                    <div className="userCourses_tabpanel_downContent_root">
                        <div className="userCourses_tabpanel_downContent_head">
                            <p className="title">{session.session_name} </p>
                            <div className="usercourse_tabPanel_downSchedule d-flex align-items-center">
                                <span className='mr-1'><CalendarToday /></span>
                                <p className="caption pt-1 color_light_grey">SCHEDULE LEARNING</p>
                            </div>
                        </div>
                        <div className="userCourses_tabpanel_downTabs_root mt-3">
                            <div className="userCourses_tabpanel_downTabs_tabs_main">
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='userCourses_tabpanel_downTabs_tabs'>
                                    <Tab label="OVERVIEW" {...a11yProps(0)} className="userCourses_tabpanel_downTabs_tab" />
                                    <Tab label="RESOURCES" {...a11yProps(1)} className="userCourses_tabpanel_downTabs_tab" />
                                </Tabs>
                            </div>
                            <div className="userCourses_tabpanel_downTabs_tabpanel_main">
                                <TabPanel value={value} index={0} className="userCourses_tabpanel_downTabs_tabpanel">
                                    <div className="userCourses_tabpanel_downTabs_tabpCont">
                                        <p className="subtitle color_dark_blue">Module Description</p>
                                        <p className="body2">
                                            {moduleDesc}
                                        </p>


                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1} className="userCourses_tabpanel_downTabs_tabpanel">
                                    <div className="userCourses_tabpanel_downTabs_tabpCont">
                                        <p className="subtitle color_dark_blue">Material &  Resources </p>
                                        <div className="program_upload_imglater w_90 p-4 mt-3">
                                            <div className="d-flex align-items-center">
                                                <span className='mr-1'><DescriptionOutlined
                                                    style={{ fill: "#262C5B" }}
                                                /></span>
                                                <p className='caption color_dark_blue'>Resource</p>
                                            </div>
                                            <div className="program_upload_imgdlt">
                                                <a href={session.resource_link} target="_blank" rel="noopener noreferrer">
                                                    <FileDownloadOutlined style={{ fill: "#98B3D6" }} />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="program_upload_imglater w_90 p-4 mt-3">
                                            <div className="d-flex align-items-center">
                                                <span className='mr-1'><DescriptionOutlined
                                                    style={{ fill: "#262C5B" }}
                                                /></span>
                                                <div>
                                                    <p className='caption color_dark_blue'>{session.session_name}</p>
                                                    <a href={session.resource_website} target="_blank" rel="noopener noreferrer" className="overline color_dark_grey">{session.resource_website}</a>

                                                </div>
                                            </div>
                                            <div className="program_upload_imgdlt">
                                                <p className="p_grey_chip overline color_light_grey" style={{ background: "#E4E4E4" }}>copy url</p>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <div className="userCourses_tabpanel_downTabs_tabpnBtn ml-3">
                                    {
                                        markAsCompleteBool ? <button className='secondaryOutlined_inactiveCta mt-3'>COMPLETED</button> : <button className='primaryFilled_inactiveCta mt-3 color_dark_black' onClick={completeModule}>MARK AS COMPLETE</button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div> : <h1></h1>
        }
        <Feedback open={open} handleClose={handleClose} submit={markAsComplete} />
    </>
}

export default SessionComp