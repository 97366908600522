import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";

import { Avatar } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";

const index = ({ setstep, mentorid }) => {
  const [profile, setprofile] = useState(null);

  const { setSessionExpire } = useAuth();

  const fetchmentor = () => {
    Axios({
      url: `/api/sp/spbyid/${mentorid}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setprofile(data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchmentor();
  }, []);

  if (profile === null) return <p>loading...</p>;

  return (
    <>
      <div>
        <div
          className="back_CTA pointer"
          onClick={() => {
            setstep(0);
            // history(`/dashboard/${user.type}?tab=2`);
          }}
        >
          <p className="caption color_light_grey">
            <span>
              <KeyboardArrowLeft />
            </span>
            <span>BACK</span>
          </p>
        </div>
      </div>
      <hr />
      <>
        <div className="row">
          <div className="col-lg-8">
            <div
              className="connection_profile_root"
              id="connection_profile_root"
            >
              <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                <div className="row no-margin align-items-center col-8">
                  <div className="width_auto no-padding">
                    <Avatar
                      src={profile.logo}
                      className=""
                      alt="Profile"
                      sx={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                    <p className="p_spartan_20">{profile.companyName}</p>
                    <p className="bold">
                      Service Provider | {profile.location.country}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
              </div>
              <div className="mt-4 mx-2">
                <p className="grey bold">{profile.description}</p>
              </div>

              <div className="row mx-2 my-3">
                <p className="p_grey_chip">{profile.industry} </p>
              </div>
              <hr />

              <div className="">
                {profile.founder ? (
                  <>
                    <p className="p_spartan_20 mx-2 my-4">Founder Details</p>
                    <div className="row mx-0 my-4 align-items-center col-8">
                      <div className="width_auto no-padding">
                        <Avatar
                          src={profile.profilePic}
                          className=""
                          alt="Profile"
                          sx={{ width: 90, height: 90 }}
                        />
                      </div>
                      <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                        <p className="">
                          {profile.founder.firstname} {profile.founder.lastname}
                        </p>
                        <p className="">{profile.contact.jobTitle} </p>
                        <p className="bold">
                          <a href={`${profile.socialConnects.linkedin}`}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-linkedin"
                              viewBox="0 0 16 16"
                            >
                              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                            </svg>
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="connection_profile_root_highligts">
              <div className="connection_profile_root_highligts_head">
                <p className="p_spartan_20">Highlights</p>
              </div>
              <div className="connection_profile_root_highligts_desc">
                <table>
                  {profile.valuation && (
                    <tr>
                      <td className="td_left">Valuation</td>
                      <td className="td_right">$ {profile.valuation}</td>
                    </tr>
                  )}
                  <tr>
                    <td className="td_left">Audience</td>
                    <td className="td_right">{profile.audience}</td>
                  </tr>

                  <tr>
                    <td className="td_left">Employees</td>
                    <td className="td_right">{profile.employees}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Funding Series</td>
                    <td className="td_right">{profile.fundingSeries}</td>
                  </tr>
                  <tr>
                    <td className="td_left">Incorporation</td>
                    <td className="td_right">
                      {new Date(profile.incorporationDate).getFullYear()}
                    </td>
                  </tr>

                  <tr>
                    <td className="td_left">Website</td>
                    <td className="td_right" style={{ wordBreak: "break-all" }}>
                      <a
                        href={`${profile.website}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {profile.website}
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default index;
