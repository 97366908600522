import React, { useState } from "react";
import './../index.css';
// import AddIcon from "@mui/icons-material/Add";
import NumberFormat from "react-number-format";

const Step2 = (props) => {
  const {
    // NumberOfEmployees,
    // setNumberOfEmployees,
    // targetAudience,
    // setTargetAudience,
    incubatorcount,
    setincubatorcount,
    programcount,
    setprogramcount,
    FundingStatus,
    setFundingStatus,
    otherFundingStatus,
    setOtherFundingStatus,
    // growth,
    // setGrowth,
    // otherGrowth,
    // setOtherGrowth,
    CurrentEvaluation, 
    setValuation,
    revenue,
    setrevenue,
  } = props;

  const [formatedValue, setFormatedValue] = useState(CurrentEvaluation);
 
  function handleValuation(e) {
    setFormatedValue(e.target.value);
    let s = e.target.value;
    if (s) {
      var n = s.split("€", 2)[1];
      setValuation(Number(n.split(",").join("")));
    }
  }



  return (
    <form>
      <div
        className=" register_page_form row"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {/* <div className="col-12 col-md-6">
          <label>Number of Employees</label>
          <select
            className="auth_input auth_select"
            name="no_of_emp"
            value={NumberOfEmployees}
            onChange={(e) => setNumberOfEmployees(e.target.value)}
          >
            <option value="">Select Number of Employees</option>
            <option value="0-5">0-5</option>
            <option value="5-15">5-15</option>
            <option value="15-30">15-30</option>
            <option value="30-50">30-50</option>
            <option value="More than 50">More than 50</option>
          </select>
        </div>
        <div className="col-12 col-md-6">
          <label>Target Audience*</label>
          <select
            className="auth_input auth_select"
            name="target_audience"
            value={targetAudience}
            onChange={(e) => setTargetAudience(e.target.value)}
          >
            <option value="">Select Target Audience</option>
            <option value="Business To Business">Business To Business</option>
            <option value="Business To Customers">Business To Customers</option>
            <option value="Customers To Customers">
              Customers To Customers
            </option>
            <option value="Business To Government">
              Business To Government
            </option>
            <option value="Government To Customers">
              Government To Customers
            </option>
            <option value="Government To Business">
              Government To Business
            </option>
          </select>
        </div> */}
        <div className="col-12 col-md-6">
          <label>Total No. of Incubatees Impacted*</label>
          <input
            type="text"
            placeholder="Enter number of startups/students"
            className="auth_input"
            value={incubatorcount}
            onChange={(e) => setincubatorcount(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Total No. of Programs*</label>
          <input
            type="text"
            placeholder="Enter number of programs hosted"
            className="auth_input"
            value={programcount}
            onChange={(e) => setprogramcount(e.target.value)}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Funding Status*</label>
          <select
            className="auth_input auth_select"
            name="funding_status"
            value={FundingStatus}
            onChange={(e) => setFundingStatus(e.target.value)}
          >
            <option value="">Select Funding Status</option>
            <option value="Bootstrap">Bootstrap</option>
            <option value="Pre-Seed">Pre-Seed</option>
            <option value="Seed">Seed</option>
            <option value="Series A">Series A</option>
            <option value="Series B">Series B</option>
            <option value="Series C">Series C</option>
            <option value="None">None</option>
            <option value="others">Others</option>
          </select>
        </div>

        {FundingStatus === "others" ? (
          <>
            <div className="col-12 col-md-6">
              <label>Funding Status</label>
              <input
                type="text"
                placeholder="Please specify your funding status"
                className="auth_input"
                value={otherFundingStatus}
                onChange={(e) => setOtherFundingStatus(e.target.value)}
              />
            </div>
          </>
        ) : null}
        {/* <div className="col-12 col-md-6">
          <label>Current Growth</label>
          <select
            className="auth_input auth_select"
            name="current_growth"
            value={growth}
            onChange={(e) => setGrowth(e.target.value)}
          >
            <option value="">Select Current Growth</option>
            <option value="Pre Startup/R&D">Pre-Startup/R&amp;D</option>
            <option value="MVP/Prototype">MVP/Prototype</option>
            <option value="Early Customers and Iteration">
              Early Customers &amp; Iteration
            </option>
            <option value="Scaling and Expansion">
              Scaling &amp; Expansion
            </option>
            <option value="Product Market Fit and Monestisation">
              Product Market Fit &amp; Monestisation
            </option>
            <option value="others">Others</option>
          </select>
        </div> */}

        {/* {growth === "others" ? (
          <>
            <div className="col-12 col-md-6">
              <label>Current Growth</label>
              <input
                type="text"
                placeholder="Please specify your current growth"
                className="auth_input"
                value={otherGrowth}
                onChange={(e) => setOtherGrowth(e.target.value)}
              />
            </div>
          </>
        ) : null} */}


        <div className="col-12 col-md-6">
          <label>Current Valuation</label>
          <NumberFormat
            placeholder="Current Valuation"
            displayType={"number"}
            thousandSeparator={true}
            prefix={"€ "}
            className="auth_input some"
            inputmode="numeric"
            value={formatedValue}
            onChange={(e) => handleValuation(e)}
          />
        </div>
        {/* <div className="col-12 col-md-6">
          <label>Amout you want to raise</label>
          <NumberFormat
            placeholder="Amount you want to Raise"
            displayType={"number"}
            thousandSeparator={true}
            prefix={"€ "}
            className="auth_input some"
            inputmode="numeric"
            value={formatedValuef}
            onChange={(e) => handleFund(e)}
          />
        </div> */}
        <div className="col-12 col-md-6">
          <label>Estimated Revenue range*</label>
          <select
            className="auth_input auth_select"
            name="target_audience"
            value={revenue}
            onChange={(e) => setrevenue(e.target.value)}
          >
            <option value="">Select Revenue range</option>
            <option value="0 to 10 Thousands">0 to 10 Thousands</option>
            <option value="10 Thousands to 40 Thousands">10 Thousands to 40 Thousands</option>
            <option value="40 Thousands to 1 Crore">40 Thousands to 1 Millions</option>
            <option value="1 Crore to 10 Millions">1 Crore to 10 Millions</option>
            <option value="More than 10 Millions">More than 10 Millions</option>
          </select>
        </div>
      </div>
      
    </form>
  );
};

export default Step2;
