import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// import { useAuth } from "../../../../../Utils/Auth";
// import Axios from "axios"
import { Tooltip } from '@mui/material';
import { Assignment,Lock } from "@mui/icons-material"

const Assessments = (props) => {
    const { assessment, module, setModuleState, isLast } = props;
    const { courseid, programid } = useParams()
    const navigate = useNavigate();
   

    return <>
        {
            assessment.has_access ? <div className="userCourses_tabs_sessions pl-4" onClick={() => {
                setModuleState(2)
                navigate(`/service/userCourses/${courseid}/${programid}?assessment=${assessment._id}&module=${module._id}&isLast=${isLast}`)
            }}>
                <div className="userCourseSession_title_">
                    <p className='body2'>
                        <span className='mr-1'><Assignment /></span>
                        <span>Assessment</span>
                    </p>
                </div>

            </div> : <div className="userCourses_tabs_sessions pl-4 userCourses_tabs_lock_session">
                <Tooltip title="Complete all the sessions in previous module to unlock this module." placement="bottom">
                    <div className="userCourseSession_title_">
                        <p className='body2'>
                            <span className='mr-1'><Lock /></span>
                            <span>Assessment</span>
                        </p>
                    </div>
                </Tooltip>
            </div>
        }
    </>
}

export default Assessments