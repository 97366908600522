import React, { useState } from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../../../Utils/Auth";
import { Add } from "@mui/icons-material";
import CheckLists from "./checklists";

const EditTask = (props) => {
  const { handleEditTaskClose, editData, gettasks } = props;
  const [title, setTitle] = useState(editData.title);
  const [description, setDescription] = useState(editData.description);
  const [dueDate, setDueDate] = useState(editData.dueDate);
  const { setSessionExpire } = useAuth();

  const [problem, setproblem] = useState("");

  const [lists, setLists] = useState([]);

  const getCheckLists = async (id) => {
    await axios({
      method: "get",
      url: `/api/mentor/tasks-checklist/${id}`,
    })
      .then((res) => {
        setLists(res.data.list);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const updateTask = async () => {
    if (title === "") {
      toast.error("Please provide a title");
      return;
    }
    await axios({
      url: `/api/mentor/task-edit/${editData._id}`,
      method: "post",
      data: { title, description, dueDate },
    })
      .then((res) => {
        if (res.data.success) toast.success("Task updated");
        gettasks();
        handleEditTaskClose();
        setTitle("");
        setDescription("");
        setDueDate(new Date());
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
        toast.error("Could not create, try again");
      });
  };

  const addlist = async () => {
    await axios({
      url: `/api/mentor/create-list/${editData.mentorship_id}`,
      method: "post",
      data: {
        description: problem,
        task_id: editData._id,
      },
    })
      .then(() => {
        getCheckLists(editData._id);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  return (
    <div className="create_task_form">
      <div className="row no-margin justify-content-between">
        <h4 className="p_spartan_20 bold">Edit Task</h4>
        <div
          className="width_fit back_CTA no-padding"
          onClick={() => handleEditTaskClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="#9f9f9f"
            className="bi bi-x-circle-fill "
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        </div>
      </div>
      <div className="partner_addtask_form">
        <div>
          <label className="p_just_midsmall_black14">Task title</label>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            required
          />
        </div>
        <div>
          <label className="p_just_midsmall_black14">Task description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            type="text"
            required
          />
        </div>
        <label className="p_just_midsmall_black14">Sub tasks</label>
        <div className="addProblem-mai">
          <input
            value={problem}
            onChange={(e) => setproblem(e.target.value)}
            type="text"
            required
          />
          <button
            onClick={() => {
              if (problem.length > 0) {
                addlist();
                setproblem("");
              }
            }}
          >
            <Add />
          </button>
        </div>

        <div className="newTask-addFile-main task-dash-root mt-2">
          <CheckLists
            taskid={editData._id}
            lists={lists}
            getCheckLists={getCheckLists}
          />
        </div>
        <div>
          <label
            className="p_just_small_darkgrey12"
            style={{ marginBottom: 10 }}
          >
            Due date
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={dueDate}
              onChange={(newValue) => {
                setDueDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} variant='standard' />}
            />
          </LocalizationProvider>
        </div>

        <div className="text-right">
          <button onClick={() => updateTask()} className="gradient_CTA">
            UPDATE
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTask;
