import React, { useState, useEffect } from "react";
import "./index.css";
import { Link } from "react-router-dom";
// import { Avatar } from "@mui/material";
import { useAuth } from "../../../Utils/Auth";
import axios from "axios";
import moment from "moment-timezone";
import { KeyboardArrowDown } from "@mui/icons-material"

export default function Mentorship() {
  const { user } = useAuth();
  const [programs, setprograms] = useState([]);
  const [selected, setselected] = useState("Select a mentor");
  // const [program, setprogram] = useState([]);
  // const [specificprogram, setspecificprogram] = useState([]);
  // const [program_id, setprogram_id] = useState(null);
  const [avatar_id, setavatar_id] = useState(null);
  const [inprogress, setinprogress] = useState(0);
  const [completed, setcompleted] = useState(0);
  const [tasks, settasks] = useState([]);
  const [drop,setDrop]=useState(false);

  const getPrograms = async () => {
    await axios({
      url: "/api/dashboard/mentorship/user",
      method: "get",
    })
      .then((res) => {
        setprograms(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          console.log(err);
        }
      });  
  };
  
  useEffect(() => {
    getPrograms();
  },[]);
  useEffect(() => {
    // console.log(programs)
    if(programs && programs.length>0)
    {
      setselected(programs[0].user.firstname + " " + programs[0].user.lastname);
      setavatar_id(programs[0].user.avatar);
      setinprogress(programs[0].progress.in_progress);
      setcompleted(programs[0].progress.completed);
      settasks(programs[0].tasks);
    }
  },[programs]);

  function getDate(str)
  {
    var mydate = new Date(str);
    // console.log(mydate.toDateString().slice(4,15));
    return mydate.toDateString().slice(4,15);
  }
  const getDiff = (date) => {
    // var dateTime = date + "T" + time;
    var scheduleTime = moment(date);
    var current = moment();
    // var ofset = moment().utcOffset() / 60;

    var dayDiff = scheduleTime.diff(current, "days");
    if (dayDiff > 1) {
      return Math.abs(dayDiff) + " days";
    } else if (dayDiff === 1) return Math.abs(dayDiff) + " day";
    else {
      var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
      //- parseFloat(timezone.split(" ", 3)[2]) +
      // ofset;
      if (hoursDiff >= 1) {
        let hoursDiffRound = Math.round(hoursDiff);
        return Math.abs(hoursDiffRound) == 1
          ? Math.abs(hoursDiffRound) + " hour"
          : Math.abs(hoursDiffRound) + " hours";
      } else if (hoursDiff < 0 && hoursDiff > 0) {
        return Math.abs(Math.round(hoursDiff * 60)) + " mins";
      } else return "expired";
    }
  };

  return (
    <div className="dashboard01_block mt-0">
    <div className="row justify-content-between mx-1 my-1">
      <h5 className="subtitle w_fit">Mentorship</h5>
      <Link to={`/dashboard/${user.type}?tab=2`}>
        <p className="w_fit bold" style={{marginTop: "5px"}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </p>
      </Link>
    </div>
    <div className="row mt-3 mb-2 ">
    <div className="col-12 col-md-5" style={{borderRight: "1px solid #E4E4E4"}}>
        <div className="row mx-1">
          {/* <div className="col-12 application-box-one d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Avatar sx={{ width: 24, height: 24 }} src={avatar_id} className="mr-2" />
              <span className="caption" style={{color:"#121843"}}>{selected}</span>
            </div>
            <svg onClick={()=>{
                setDrop(!drop);
            }} style={{cursor: "pointer"}} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L5 5L1 1" stroke="#262C5B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div> */}
          
          <div className="mentor_tasks_select_root w-100">
                            <div className="mentor_tasks_bodyCmpName point" onClick={() => setDrop(!drop)}>
                              <div className="mentor_tasks_bodyCmpprf">
                                  {avatar_id?<img src={avatar_id} />:<span className="mr-8"></span>}
                                  <p className="caption color_dark_black2">{selected}</p>
                              </div>
                              <span><KeyboardArrowDown
                                  className={`mentorDownbtn ${drop ? 'rotateDown' : 'antirotateDown'}`}
                              /></span>
                            </div>
                            {
                                drop ?
                                    <div className={`mentor_tasks_options_root ${drop ? 'show' : 'hide'}`}>
                                        {
                                            programs && programs.length>0 ? 
                                            programs.map((program, i) => <div className="mentor_tasks_options point" key={i}
                                            onClick={()=>{
                                              setselected(program.user.firstname + " " + program.user.lastname);
                                              setavatar_id(program.user.avatar);
                                              setinprogress(program.progress.in_progress);
                                              setcompleted(program.progress.completed);
                                              settasks(program.tasks);
                                              setDrop(!drop);
                                          }}
                                                >
                                                    <img src={program.user.avatar} alt={program.user.firstname} />
                                                    <p className="caption color_dark_black2">{program.user.firstname} {program.user.lastname}</p>
                                                </div>) : null

                                        }
                                    </div> : null
                            }

                        </div>


          {/* {drop ? (
            <div className="col-12" style={{background:"#fff"}}>
            <div className="drop-design row" style={{width: "193px"}}>
            {programs && programs.length>0 ? 
            programs.map((program,i)=>
            <>
            <div key={i} onClick={()=>{
                setselected(program.user.firstname + " " + program.user.lastname);
                setavatar_id(program.user.avatar);
                setinprogress(program.progress.in_progress);
                setcompleted(program.progress.completed);
                settasks(program.tasks);
                setDrop(!drop);
            }} className="col-12 application-box-one d-flex justify-content-between align-items-center" style={{height:"fit-content",borderRadius: "4px"}}>
            <div className="d-flex align-items-center">
              <Avatar sx={{ width: 24, height: 24 }} src={program.user.avatar} className="mr-2" />
              <span className="caption" style={{color:"#121843"}}>{program.user.firstname} {program.user.lastname}</span>
            </div>
          </div>


          </>) 
          : 
          <div onClick={()=>{
                setDrop(!drop);
            }} className="col-12 application-box-one d-flex justify-content-between align-items-center" style={{height:"fit-content",borderRadius: "4px"}}>
            <div className="d-flex align-items-center">
              <span className="caption" style={{color:"#121843"}}>No Mentor</span>
            </div>
          </div>
          }
          </div>
          </div>
          ) : null} */}
          
          <div className="col-12 mt-3 application-box-two d-flex justify-content-center align-items-center px-3 py-4">
            <div className="row w-100">
              <h5 className="col-2 text-right p-0 pr-3" style={{color: "#FF6F1E"}}>{inprogress}</h5>
              <span className="subtitle col-10 p-0 text-left" style={{color:"#121843"}}>In progress</span>
          </div>
          </div>
          <div className="col-12 mt-3 application-box-three d-flex justify-content-center align-items-center px-3 py-4">
            <div className="row w-100">
              <h5 className="col-2 text-right p-0 pr-3" style={{color: "#121843"}}>{completed}</h5>
              <span className="subtitle col-10 text-left p-0" style={{color:"#121843"}}>Completed</span>
          </div>
          </div>
      </div>
    </div>
    <div className="col-12 col-md-7">

    {tasks && tasks.length>0 ? 
            tasks.map((task,i)=>
          (<div key={i} className="mentor-task-box">
            <div className="d-flex align-items-center justify-content-between">
              <span className="body2" style={{color: "#262C5B"}}>{task.title}</span>
              <span className="caption" style={{color: "#989898"}}>{task.sub_task} subtasks</span>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="overline" style={{color: "#989898"}}>Due date <span style={{color: "#F53939"}}>{getDate(task.due_date)}</span></div>
              <span className="overline" style={{color: "#000000"}}>{getDiff(getDate(task.due_date))}</span>
            </div>
          </div>))
    : 
    <div>No Tasks</div>}
    </div>
    </div>
    </div>
  );
}
