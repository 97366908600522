import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PlayCircleFilled, Lock } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

import { Tooltip } from '@mui/material';

const Sessions = (props) => {
    const { session, module, setModuleState,getSessionByModule, isLast, i , length } = props;
    const { courseid, programid } = useParams()
    const navigate = useNavigate();

    useEffect(() => {
        getSessionByModule();
    }, [])

    return <>
        {
            session.has_access ? <>
                <div className="userCourses_tabs_sessions pl-4" onClick={() => {
                    setModuleState(1)
                    navigate(`/service/userCourses/${courseid}/${programid}?session=${session._id}&module=${module._id}&isLast=${(isLast) && (i+1 === length) ? true : false}`);
                }}>
                    <div className="userCourseSession_title_">
                        <p className='body2'>
                            <span className='mr-1'><PlayCircleFilled /></span>
                            <span>{session.session_name}</span>
                        </p>
                    </div>
                    {/* <div className="userCoursesSession_duration">
            <p className="overline color_light_grey">4 min</p>
        </div> */}
                </div>
            </> : <>
                <div className="userCourses_tabs_sessions pl-4 userCourses_tabs_lock_session">
                    <Tooltip title="Complete all the sessions in previous module to unlock this module." placement="bottom">
                        <div className="userCourseSession_title_">
                            <p className='body2'>
                                <span className='mr-1'><Lock /></span>
                                <span>{session.session_name}</span>
                            </p>
                        </div>
                    </Tooltip>
                    {/* <div className="userCoursesSession_duration">
            <p className="overline color_light_grey">4 min</p>
        </div> */}
                </div>
            </>
        }
    </>
}

export default Sessions