/** @format */
var moment = require("moment-timezone");

const get_isodiff = (date) => {
  var dateTime = date;
  var scheduleTime = moment(dateTime);
  var current = moment();

  var dayDiff = scheduleTime.diff(current, "days");
  if (dayDiff > 1) {
    return dayDiff + " days";
  } else if (dayDiff === 1) return dayDiff + " day";
  else {
    var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
    
    if (hoursDiff >= 1) {
      let hoursDiffRound = Math.round(hoursDiff);
      return hoursDiffRound == 1
        ? hoursDiffRound + " hour"
        : hoursDiffRound + " hours";
    } else if (hoursDiff <= 0 && hoursDiff >= -2) {
      return "Live";
    } else if (hoursDiff < -2) {
      return "Expired";
    } 
    else if(scheduleTime.diff(current, "minutes")<=5){
      return "Live";
    }
    else {
      return Math.round(hoursDiff * 60) + " min";
    }
  }
};

module.exports = get_isodiff;
