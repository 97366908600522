import React, { useState, useEffect } from 'react'
import Card from './Card'
import { useAuth } from '../../../../../Utils/Auth'
import Axios from "axios"
import Query from '../../../../../Utils/Query/Query'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PaymentSuccess from '../../../../../Assets/images/financial/payment_success.svg'
import {useNavigate} from 'react-router-dom'
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    outline:'none'
    
};
const Received = () => {
    const query = Query()
    const navigate =  useNavigate()
    const paymentSuccess = Boolean(query.get('success'))
    const [received, setReceived] = useState([])
    const [selected, setSelected] = useState("all")
    const { setSessionExpire,user } = useAuth();
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalOpen = () => setModalOpen(true)
    const handleModalClose = () => {
        setModalOpen(false)
        navigate(`/dashboard/${user.type}?tab=10&ft=3`)
    }
   
    const getAllRequests = async () => {
        await Axios({
            url: `/api/inc-finance/request/user`,
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setReceived(res.data.data)
        }).catch(() => {
            setSessionExpire(true)
        })
    }
    const getPaidRequests = async (term) => {
        await Axios({
            url: `/api/inc-finance/request/user/${term}`,
            method: "get",
            headers: {
                "Content-Type": "application/json"
            }
        }).then((res) => {
            setReceived(res.data.data)
        }).catch(() => {
            setSessionExpire(true)
        })
    }

    useEffect(()=>{
        if (paymentSuccess === true) {
            handleModalOpen()
        }
    },[])

    useEffect(() => {
        if (selected === "all") {
            getAllRequests()
        }
        else if (selected === "paid") {
            getPaidRequests(true)
        }
        else if (selected === "unpaid") {
            getPaidRequests(false)
        }
    }, [selected])
    return (
        <div className='invoicing_received_root'>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={modalOpen}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropProps={{
                    timeout: 300,
                }}
            >
                <Box sx={{ ...style, width: 500 }}>
                
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <img src={PaymentSuccess} alt="payment success" width='16%' className='my-3'/>
                        <h5 className='mt-3'>Payment Success</h5>
                    </div>
                </Box>
            </Modal>
            <div className="invoicing_selectOption d-flex justify-content-end align-items-center">
                <select name="days" className='incubator_insights_head_sct' onChange={(e) => setSelected(e.target.value)}>
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                </select>
            </div>
            <div className="incubator_assessment_tableRoot">
                {
                    received.length > 0 ?
                        <table className="incubator_assessment_table">
                            <thead className="incubator_assessment_thead">
                                <tr className="incubator_assessment_thead_tr">
                                    <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                                        <p className="body2 color_dark_grey2">Recieved from</p>
                                    </td>
                                    <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                                        <p className="body2 color_dark_grey2">Tools</p>
                                    </td>
                                    <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                        <p className="body2 color_dark_grey2">Date</p>
                                    </td>
                                    <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                        <p className="body2 color_dark_grey2">Status</p>
                                    </td>
                                    <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                                        <p className="body2 color_dark_grey2">Amount</p>
                                    </td>
                                    <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">

                                    </td>
                                </tr>
                            </thead>
                            <tbody className="incubator_assessment_tbody">
                                {
                                    received && received.length > 0 ?
                                        received.map((data, i) => <Card
                                            key={i}
                                            data={data}
                                        />) : null
                                }
                            </tbody>
                        </table> : <div className="incubator_empty_table mt-24">
                            <p>No Request</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default Received