import React, { useEffect } from "react";
import Startup from "./Startup";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import Students from "./Students/Students";
import PreStartups from "./PreStartups";
import Individuals from "./Individuals/Individuals";
import { useAuth } from "../../../../Utils/Auth";
import Axios from "axios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const index = ({ programid }) => {
  const [value, setValue] = React.useState(0);
  const [menteesProfile, setMenteesProfile] = React.useState([]);
  const { user } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchMenteeProfile = async () => {
    const { data: menteeProfile } = await Axios({
      url: `/api/incmentor/assigned/users/${programid}/${user.id}`,
      method: "get",
    });
    console.log(menteeProfile.data.assigned_user);
    setMenteesProfile(menteeProfile.data.assigned_user);
  };

  const fetchInvestorsAssignedUsers = async () => {
    const { data: UsersProfile } = await Axios({
      url: `/api/incinvestor/assigned/users/${programid}/${user.id}`,
      method: "get",
    });
    setMenteesProfile(UsersProfile.data.assigned_user);
  };
  useEffect(() => {
    if (user.type === "mentor") {
      fetchMenteeProfile();
    }
    if (user.type === "investor") {
      fetchInvestorsAssignedUsers();
    }
  }, []);
  return (
    <div className="participants_root">
      <div className="incubation_paticipation_program_TabsMain">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="incubation_profile_program_Tabs dashbaord_connections_inner_tabs"
        >
          <Tab
            label="Startups"
            {...a11yProps(0)}
            className="incubation_profile_program_Tab"
          />
          {/* <Tab
            label="Pre-Startups"
            {...a11yProps(1)}
            className="incubation_profile_program_Tab"
          /> */}
          <Tab
            label="Students"
            {...a11yProps(2)}
            className="incubation_profile_program_Tab"
          />
          {/* <Tab
            label="Individuals"
            {...a11yProps(3)}
            className="incubation_profile_program_Tab"
          /> */}
        </Tabs>
      </div>
      <div className="incubation_profile_program_TabPanelMain">
        <TabPanel
          value={value}
          index={0}
          className="incubation_profile_program_TabPanel"
        >
          <Startup programid={programid} menteesProfile={menteesProfile} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          className="incubation_profile_program_TabPanel"
        >
          <PreStartups programid={programid} menteesProfile={menteesProfile} />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          className="incubation_profile_program_TabPanel"
        >
          <Students programid={programid} menteesProfile={menteesProfile} />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          className="incubation_profile_program_TabPanel"
        >
          <Individuals menteesProfile={menteesProfile} />
        </TabPanel>
      </div>
    </div>
  );
};

export default index;
