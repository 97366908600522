import React, { useState } from 'react';
import Card from './Card';

const Sent = () => {
    const [sent, setsent] = useState([])
    return <div className='invoicing_sent_root'>
        <div className="incubator_assessment_tableRoot">
            {
                sent.length > 0 ?
                    <table className="incubator_assessment_table">
                        <thead className="incubator_assessment_thead">
                            <tr className="incubator_assessment_thead_tr">
                                <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                                    <p className="body2 color_dark_grey2">Sent to</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                                    <p className="body2 color_dark_grey2">Tools</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                    <p className="body2 color_dark_grey2">Date</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                    <p className="body2 color_dark_grey2">Status</p>
                                </td>
                                <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                                    <p className="body2 color_dark_grey2">Amount</p>
                                </td>
                            </tr>
                        </thead>
                        <tbody className="incubator_assessment_tbody">
                            {
                                sent && sent.length > 0 ?
                                    sent.map((data, i) => <Card
                                        key={i}
                                        data={data}
                                        setsent={setsent}
                                    />) : null
                            }
                        </tbody>
                    </table> : <div className="incubator_empty_table mt-24">
                        <p>No Request</p>
                    </div>
            }
        </div>
    </div>
}

export default Sent