import React from "react";
import {
  AccessTime,
  AccountBalanceWallet,
  ArrowForward,
} from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect } from "react";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Card = (props) => {
  const { program, setIncubationState } = props;
  const navigate = useNavigate();
  const query = useQuery();
  var programState = Number(query.get("program-state"));
 
  useEffect(() => {
    if (programState) {
      setIncubationState(3);
    }
  }, []);
  return (
    <div
      className="col-lg-3 col-md-6 col-12 mb-4"
      onClick={() => {
        setIncubationState(3);
        navigate(`/dashboard/startup?tab=7&id=${program._id}`);
        // navigate(`/service/userprogram/${program._id}?tab=1`);
      }}
    >
      <div className="incubation_program_card_root">
        <div
          className="incubation_program_card_head"
          style={{ backgroundImage: `url('${program.coverImage}')` }}
        >
      <p className="p_normalBlack10">{program.sector && program.sector.length>0? program.sector[0]:program.type}</p>
        </div>
        <div className="incubation_program_card_main pl-3 pr-3 pb-3">
          <div className="incubation_program_card_title mt-2">
            <h5 className="p_black20">
              {program.title.substring(0, 15)}
              {program.title.length > 15 ? "..." : ""}
            </h5>
          </div>
          <div className="incubation_program_card_info d-flex justify-content-between mt-3">
            <div className="incubation_program_card_small">
              <span>
                {" "}
                <AccountBalanceWallet />{" "}
              </span>
              <span className="p_secBlack10 text-capitalize"> {!program?.programPaymentType || program?.programPaymentType === 'FREE' ? 'FREE' : "€ " + program?.participationAmmount } </span>
            </div>
            <div className="incubation_program_card_small">
              <span>
                {" "}
                <AccessTime />{" "}
              </span>
              <span className="p_secBlack10"> {program.duration} </span>
            </div>
          </div>
          <div className="incubator_program_card_applicants_root mt-3">
            <p className="p_secBlack10" style={{ color: "#580167" }}>
              Hosted By
            </p>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center mt-2">
                <Avatar
                  src={program.id.avatar}
                  style={{ width: 32, height: 32 }}
                  variant="circular"
                />
                <p className="body2 ml-2 color_dark_blue">{program.id.name}</p>
              </div>
              <div>
                <ArrowForward style={{ fill: "#6D7D8B", width: "0.78em" }} />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="incubator_program_card_footer">
                <p className="p_secBlack10" style={{ color: "rgba(0,0,0,0.75)" }}>Pending</p>
            </div> */}
      </div>
    </div>
  );
};

export default Card;
