import React from "react";
import "./../../authentication.css";
import CircularProgress from "@mui/material/CircularProgress";
import LocationComponent from "../../../../Utils/Location/index";
import { useAuth } from "../../../../Utils/Auth";
import Multiselect from "multiselect-react-dropdown";

const Step1 = (props) => {
  const { isUser } = useAuth();
  const {
    profilePic,
    setProfilePic,
    handlePicUpload,
    islogoloading,
    linkedProfile,
    setlinkedProfile,
    category,
    setcategory,
    fund,
    setfund,
    jobrole,
    setJobrole,
    bio,
    setbio,
    country,
    setcountry,
    state,
    setstate,
    city,
    setcity,
    states,
    setstates,
    cities,
    setcities,
    options,
    investedIndustry,
    setinvestedIndustry,
    investedmodel,
    setinvestedmodel,
    investedstage,
    setinvestedstage,
    amount,
    setamount
  } = props;

  const { user } = useAuth();

  const addInputEvent3 = (e) => {
    setinvestedIndustry(e.map((i) => i.label));
  };

  if (!isUser) return <h1>Session Timed Out</h1>;

  return (
    <form>
    <div className="register_page_form_upload">
      {profilePic ? (
        <>
          <img
            src={profilePic}
            alt="logo"
            className="companyLogo"
          />
          <p
            className="p_small_black font12 mt-1"
            style={{ cursor: "pointer" }}
            onClick={() => setProfilePic(null)}
          >
            Delete
          </p>
        </>
      ) : (
        <>
          {islogoloading ? (
            <CircularProgress style={{ color: "black" }} />
          ) : (
            <>
              <label htmlFor="clogo">
                <div
                  className="register_page_form_com_logo"
                  onClick={{}}
                >
                  Upload
                </div>
                <input
                  type="file"
                  accept="image/*"
                  name="profile_pic"
                  id="clogo"
                  onChange={(e) => handlePicUpload(e)}
                />
              </label>
              <p className="register_page_form_upload-text mt-1">
                Profile Photo
              </p>
            </>
          )}
        </>
      )}
    </div>

      <div className="register_page_form row">
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <label>First Name*</label>
                <input
                  placeholder="First Name"
                  required
                  type="text"
                  className="auth_input"
                  value={user.firstname}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div>
                <label>Last Name*</label>
                <input
                  placeholder="Last Name"
                  required
                  type="text"
                  className="auth_input"
                  value={user.lastname}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">LinkedIn Profile</label>
            <input
              placeholder="Enter your Profile Url"
              required
              type="text"
              className="auth_input col-12 "
              value={linkedProfile}
              onChange={(e) => setlinkedProfile(e.target.value)}
            />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">Investor Type*</label>
            <select
              className="auth_input"
              name="grossValue"
              value={category}
              onChange={(e) => setcategory(e.target.value)}
            >
              <option className="color_black" value="">
                Select investor type
              </option>
              <option value="VC">VC</option>
              <option value="Corporate VC">Corporate VC</option>
              <option value="Angel Group">Angel Group</option>
              <option value="Family Office">Family Office</option>
              <option value="Accelerator">Accelerator</option>
              <option value="Business Angel">Business Angel</option>
              <option value="First-time Angel">First-time Angel</option>
              <option value="Foundation, Philanthropist or Impact Fund/Angel">
                Foundation, Philanthropist or Impact Fund/Angel
              </option>
              <option value="Impact Investor">Impact Investor</option>
            </select>
            </div>
          </div>
        </div>
        </div>

        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">Name of Fund/Network/Entity*</label>
            <input
              placeholder="Enter name"
              type="text"
              className="auth_input"
              value={fund}
              onChange={(e) => setfund(e.target.value)}
            />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
            <label className="auth_label">Job role*</label>
            <input
              placeholder="Enter your role"
              type="text"
              className="auth_input"
              value={jobrole}
              onChange={(e) => setJobrole(e.target.value)}
            />
            </div>
          </div>
        </div>
        </div>

            
        <div className="col-12">
          <label className="auth_label">Investor Profile*</label>
          <textarea
            required
            name="short_desc"
            className="auth_input auth_textarea col-12"
            placeholder="Write  a short description"
            value={bio}
            onChange={(e) => setbio(e.target.value)}
          ></textarea>
        </div>

        <div className="col-12">
          <label>Location*</label>
        </div>
        <div className="col-12" style={{ display: "flex" }}>
          <LocationComponent
            country={country}
            setcountry={setcountry}
            state={state}
            setstate={setstate}
            city={city}
            setcity={setcity}
            states={states}
            cities={cities}
            setstates={setstates}
            setcities={setcities}
          />
        </div>

        
        <div className="col-12" style={{ height: "100%" }}>
          <label className="auth_label">
            Invested Industry*
          </label>
          <Multiselect
            isMulti
            options={options}
            className=""
            displayValue="label"
            placeholder="Select Industries"
            name="industries"
            closeIcon="cancel"
            value={investedIndustry}
            onSelect={addInputEvent3}
            onRemove={addInputEvent3}
          />
        </div>

        <div className="col-12">
          <label className="auth_label">
            Invested Business Models*
          </label>
          <select
            className="auth_input"
            name="grossValue"
            value={investedmodel}
            onChange={(e) => setinvestedmodel(e.target.value)}
          >
            <option className="color_black" value="">
              Select business model
            </option>
            <option className="color_black" value="Business to Consumer">
              Business to Consumer
            </option>
            <option className="color_black" value="Business to Business">
              Business to Business
            </option>
            <option className="color_black" value="Consumer to Business">
              Consumer to Business
            </option>
            <option className="color_black" value="Consumer to Consumer">
              Consumer to Consumer
            </option>
          </select>
        </div>
        <div className="col-12">
          <label className="auth_label">Invested Funding Stages*</label>
            <select
              className="auth_input"
              name="grossValue"
              value={investedstage}
              onChange={(e) => setinvestedstage(e.target.value)}
            >
                <option className="color_black" value="">
                  Select funding stages
                </option>
                <option className="color_black" value="Pre-Seed">
                  Pre-Seed
                </option>
                <option className="color_black" value="Seed">
                  Seed
                </option>
                <option className="color_black" value="Series A">
                  Series A
                </option>
                <option className="color_black" value="Series B">
                  Series B
                </option>
                <option className="color_black" value="All">
                  All of the above
                </option>
                <option className="color_black" value="Not sure">
                  Not sure
                </option>
            </select>
        </div>
        <div className="col-12">
            <label className="auth_label">Invested Amount*</label>
            <input
              placeholder="Type Amount here"
              type="text"
              className="auth_input"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
            />
        </div>
      </div>
    </form>
  );
};

export default Step1;
