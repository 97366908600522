/** @format */

import { Avatar } from "@material-ui/core";
import React from "react";
import { useAuth } from "../../Utils/Auth";
// import { useHistory } from "react-router-dom";
import "./notification.css";
import axios from "axios";

const NotificationCard = ({ props, getNotificationsNew,isBgWhite }) => {
  // const history = useHistory();
  const { getNotifications } = useAuth();

  const readNotifications = async (id) => {
    await axios({
      method: "post",
      url: `/api/notification/seen`,
      headers: {
        "Content-Type": "application/json",
      },
      data:{
        id:id
      }
    }).then(()=>{
      getNotificationsNew()
    })
  };

  const handleClick = () => {
    readNotifications(props._id);
    getNotifications()
  };

  return (
    <div className={`notificationCard__root ${!props.seen ? "grey" : ""} ${(isBgWhite && !props.seen)?'new_notyf_card':""}`}>
      <div
        onClick={() => handleClick()}
        className="row no-margin align-items-center"
      >
        <div className="col-2">
          <Avatar src={props.image} />
        </div>
        <div className="no-padding col-9 text-left">
          <p className="width_auto">{props.description}</p>
        </div>
      </div>
    </div>
  );
};
export default NotificationCard;
