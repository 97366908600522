import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import Button from '@mui/material/Button';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CreateEvent from "./createEvent";
import Axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment-timezone";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

function calendar({ programid }) {
  const [date, setDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [changedDate, setchangedDate] = useState(new Date());

  const [loading, setloading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setevents([]);
  };
  const handleClose = () => {
    setOpen(false);
    setevents([]);
  };

  const [events, setevents] = useState([]);
  const [allevents, setallevents] = useState([]);

  const geteventsbydate = async (e) => {
    setloading(true);
    const response = await Axios.get(
      `/api/incubatorProgram/eventsbydate/${e}/${programid}`
    );
    if (response.data.success) setevents(response.data.data);
    setloading(false);
  };
  const geteventsByMonth = async () => {
    setloading(true);
    const response = await Axios.get(
      `/api/incubatorProgram/eventsbymonth/${changedDate}/${programid}`
    );
    if (response.data.success) setallevents(response.data.data);
    setloading(false);
  };

  useEffect(() => {
    geteventsByMonth();
  }, [changedDate]);

  const tileContent = ({ date }) =>
    allevents.find(
      (x) =>
        moment(x.date).format("DD-MM-YYYY") ===
        moment(date).format("DD-MM-YYYY")
    )
      ? allevents.map((v, i) => {
          if (
            moment(v.date).format("DD-MM-YYYY") ===
            moment(date).format("DD-MM-YYYY")
          )
            return (
              <ul key={i} className='calendar-event-list'>
              <li className={`calendar-event-item`} style={{borderLeft:`5px solid ${v.color}`}}> {v.title} </li>
            </ul>
            );
        })
      : null;

  return (
    <>
      <div className="calendar">
        <div className="calendar-container row">
          <Calendar
            className="col-12 mb-4"
            tileContent={tileContent}
            onChange={(e) => {
              setDate(e);
              console.log(e);
              handleOpen();
              geteventsbydate(e);
            }}
            onActiveStartDateChange={(e) => setchangedDate(e.activeStartDate)}
            value={date}
            defaultView="month"
          />
        </div>
        <p className="text-center">
          <span className="bold">Selected Date:</span> {date.toDateString()}
        </p>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <h5 id="parent-modal-title" style={{ color: "#262C5B" }}>
            Events
          </h5>
          <p style={{ marginBottom: "10px" }}>{date.toDateString()}</p>
          {loading ? <CircularProgress /> : null}
          {events.map((v) => {
            return (
              <>
                <p
                  style={{ borderLeft: `3px solid ${v.color}` }}
                  id="parent-modal-description"
                >
                  {v.title}
                </p>
              </>
            );
          })}

          <CreateEvent
            geteventsbydate={geteventsbydate}
            geteventsByMonth={geteventsByMonth}
            date={date}
            programid={programid}
          />
        </Box>
      </Modal>
    </>
  );
}

export default calendar;
