import React, { useEffect } from "react";
import { useAuth } from "../../../../Utils/Auth";
import axios from "axios";
import { Checkbox } from "@mui/material";
import "./checklist.css";

const CheckLists = ({ taskid, lists, getCheckLists }) => {
  const { setSessionExpire } = useAuth();

  const updateList = async (id) => {
    await axios({
      url: `/api/mentor/edit-list/${id}`,
      method: "put",
    })
      .then(() => {
        getCheckLists(taskid);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const deletelist = async (id) => {
    await axios({
      url: `/api/mentor/edit-list/${id}`,
      method: "delete",
    })
      .then(() => {
        getCheckLists(taskid);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    getCheckLists(taskid);
  }, []);
  return (
    <div className="newTask-Lists-root">
      {lists && lists.length > 0
        ? lists.map((list, i) => (
            <div
              key={i}
              className="newTask-list d-flex justify-content-between align-items-center"
            >

              {list.isComplete ? (
                <p className="p_just_small_darkgrey12">
              <span className="checklist_index">{String(i+1).padStart(2, '0')}</span>    <strike>{list.description}</strike>
                </p>
              ) : (
                <p className="p_just_small_black12"> <span className="checklist_index">{String(i+1).padStart(2, '0')}</span> {list.description}</p>
              )}
              <div className="row r_a">
              <Checkbox
                checked={list.isComplete}
                onChange={() => updateList(list._id)}
                className="newTask-checkbox"
              />
              <p className="back_CTA" onClick={() => deletelist(list._id)} type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle mx-2" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg>
              </p>
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};

export default CheckLists;
