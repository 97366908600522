import React, { useEffect, useState } from "react";
// import {
//   useParams,
//   // useNavigate
// } from "react-router-dom";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
import { Tabs, Tab, Typography, Box } from "@mui/material";
// import profile_inc from "../../../../Assets/images/incubatorDash/profile_inc.png";
import PropTypes from "prop-types";
import "./index.css";
import AllAplicantCard from "./AllAplicantCard";
// import ShortListedCard from "./ShortListedCard";
// import DeclinedCard from "./DeclinedCard";
import AcceptedCard from "./AcceptedCard";
import Applicantcard from "./Applicantcard";
import { KeyboardArrowLeft } from "@mui/icons-material";
import supportimage from "../../../Assets/images/events/ri_customer-service-fill.jpg";
import priceimage from "../../../Assets/images/events/cil_view-module.jpg";
import Loader from "../../../Components/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const index = ({ setstep, serviceid }) => {
  // const navigate = useNavigate();

  //   let { programid } = useParams();
  const [service, setservice] = useState(null);
  const [startupid, setstartupid] = useState(null);

  const [applicantstep, setapplicantstep] = useState(0);

  const { setSessionExpire } = useAuth();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getservice = async () => {
    try {
      const response = await Axios.get(`/api/service/servicebyid/${serviceid}`);
      if (response.data.success) setservice(response.data.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setSessionExpire(true);
      }
    }
  };

  useEffect(() => {
    if (serviceid) getservice();
  }, []);
  if (service === null) return <Loader />;
  return (
    <>
      {applicantstep === 1 ? (
        <>
          <Applicantcard
            setapplicantstep={setapplicantstep}
            startupid={startupid}
            serviceid={serviceid}
          />
        </>
      ) : (
        <>
          <div
            className="back_CTA pointer mt-2 mb-3"
            onClick={() => {
              setstep(0);
            }}
          >
            <p className="caption color_light_grey">
              <span>
                <KeyboardArrowLeft />
              </span>
              <span>BACK</span>
            </p>
          </div>
          <div className="incubation_program_root">
            {/* <div className="col-12 point mb-24">
              <div className="onboarding_cards incubation_courses_card_root2 d-flex">
                <div className="user_incubation_course_slide"></div>
                <div className="user_incubation_course_rightMain w_100">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="user_incubation_course_leftSide">
                        <div className="user_course_tags d-flex align-items-center">
                          <p className="incubation_chips">{service.keyword}</p>
                        </div>
                        <div className="user_course_desc pt-3">
                          <ul>
                            {service.services.map((v, i) => (
                              <li key={i}>{v}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="user_incubation_course_rightside pl-3 d-flex flex-column justify-content-between">
                        <div className="user_incubation_course_rightHead">
                          <div className="user_incubation_course_rightTitle">
                            <h5>{service.servicename}</h5>
                          </div>
                          <div className="user_incubation_course_rightInfo d-flex mt-3 mb-3">
                            <div className="user_incubation_course_right_th d-flex align-items-center">
                              <span className="mr-2">
                                <img src={priceimage} />
                              </span>
                              <p className="body2">€ {service.pricing}</p>
                            </div>
                            <div className="user_incubation_course_right_th d-flex align-items-center">
                              <span className="mr-2">
                                <img src={supportimage} />
                              </span>
                              <p className="body2">
                                {" "}
                                {service.customerSupport}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="user_incubation_course_AvatarGroup_root">
                          <div className="user_incubation_course_AvatarGroups">
                          </div>
                           <div className="user_incubation_course_startupEnrolled">
                            <p className="caption color_light_grey">
                              Startups enrolled
                            </p>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="container"> */}
              <div className="row m-0">
               <div className="col-12 col-md-8">
                    <div className="outreach_service_box p-24 h-100">
                        <h5 className="mb-20">{service.servicename}</h5>
                        <div className="user_course_desc">
                          <ul className="ml-24">
                            {service.services.map((v, i) => (
                              <li key={i} className="body1">{v}</li>
                            ))}
                          </ul>
                        </div>
                    </div>
               </div>
               <div className="col-12 col-md-4">
                    <div className="outreach_service_box p-24 h-100">
                        <div className="user_course_tags d-flex align-items-center">
                          <p className="caption grey-pill" style={{ color:"#565656"}}>{service.keyword}</p>
                        </div>
                        <div className="user_incubation_course_rightInfo d-flex flex-column mt-26">
                            <div className="user_incubation_course_right_th d-flex align-items-center">
                              <span className="mr-2">
                                <img src={priceimage} />
                              </span>
                              <p className="body2">€ {service.pricing}</p>
                            </div>
                            <div className="user_incubation_course_right_th d-flex align-items-center mt-26">
                              <span className="mr-2">
                                <img src={supportimage} />
                              </span>
                              <p className="body2">
                                {" "}
                                {service.customerSupport}
                              </p>
                            </div>
                          </div>
                      </div>
                </div>
            </div>
            {/* </div> */}

            <div className="incubation_profile_program_main">
              <div className="incubation_profile_program_TabsMain">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="incubation_profile_program_Tabs"
                >
                  <Tab
                    label="Requests Received"
                    {...a11yProps(0)}
                    className="incubation_profile_program_Tab"
                  />
                  <span className="incubation_profile_program_span"></span>
                  <Tab
                    label="Accepted"
                    {...a11yProps(2)}
                    className="incubation_profile_program_Tab"
                  />
                </Tabs>
              </div>
              <div className="incubation_profile_program_TabPanelMain">
                <TabPanel
                  value={value}
                  index={0}
                  className="incubation_profile_program_TabPanel"
                >
                  <div className="incubation_profile_card_root">
                    <div className="row">
                      <>
                        <AllAplicantCard
                          serviceid={serviceid}
                          setstartupid={setstartupid}
                          setapplicantstep={setapplicantstep}
                        />
                      </>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel
                  value={value}
                  index={2}
                  className="incubation_profile_program_TabPanel"
                >
                  <div className="incubation_profile_card_root">
                    <div className="row">
                      <AcceptedCard
                        serviceid={serviceid}
                        setstartupid={setstartupid}
                        setapplicantstep={setapplicantstep}
                      />
                    </div>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default index;
