import React, { useState } from "react";
import logo from "./../../../Assets/images/logo/logo.png";
import "./forget.css";
import reset_password_img from "./../../../Assets/images/reset_password.png";
import { toast } from "react-toastify";
// , toast
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
// import { useAuth } from "./../../../Utils/Auth";

const ResetPassword = () => {
  // const { setLoadCurrent, loadCurrent } = useAuth();
  const [disable, setdisable] = useState(false);
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const tk = useQuery().get("tk");
  const [validPassword, setValidPassword] = useState(true);
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!validPassword) {
      toast.warning(
        "Invalid password, password should include atleast an uppercase letter, a lowercase letter,a number and a special character like @,!,etc. "
      );
      return;
    }
    if (password !== confirmpassword) {
      toast.warning("Passwords do not match!");
      return;
    } else {
      await axios({
        url: "/api/user/reset-password",
        method: "post",
        data: {
          token: tk.toString(),
          password: password,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Password changed successfully");
            navigate("/login");
            setdisable(false);
          }
        })
        .catch((err) => {
          toast({ msg: err.response.data.message, code: 0 });
        });
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const txt = e.target.value;
    if (
      // txt.length >= 8 &&
      // isNumberRegx.test(txt) &&
      regex.test(txt)
    ) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };
  return (
    <>
      <div className="auth_back_part pt-5">
        <div className="container mt-5">
          <div className="row mt-5">
            <div className="col-12 col-md-6">
              <a href="/">
                <img src={logo} />
              </a>
              <img
                className={` img-fluid smooth-image auth_option_img image-${
                  imageLoaded ? "visible" : "hidden"
                } auth_image`}
                onLoad={() => setImageLoaded(true)}
                src={reset_password_img}
                alt="reset_password_img"
              />
              <div
                className="auth_back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <i className="fa fa-arrow-left"></i>
                Back
              </div>
            </div>
            <div className="col-12 col-md-6 pl-md-5 mt-5 mt-md-0 d-flex flex-column justify-content-center">
              <div className="auth_back_loginform-head">
                <h3>Reset your password</h3>
              </div>
              <p className="auth_back_loginform-para">
                What would you like your new password to be ?
              </p>
              <form className="row" onSubmit={handleOnSubmit}>
                <div className="auth_back_loginform col-12">
                  <label>New password</label>
                  <input
                    type="password"
                    onChange={(e) => {
                      handlePasswordChange(e);
                    }}
                    placeholder="Enter new password"
                    value={password}
                  />
                  <div style={{ textAlign: "left" }}>
                    <p
                      className="p_small_black no-margin"
                      style={{ display: validPassword ? "none" : "block" }}
                    >
                      Password should include atleast an uppercase letter, a
                      lowercase letter,a number and a special character like
                      @,!,etc.
                    </p>
                  </div>
                </div>
                <div className="auth_back_loginform col-12">
                  <label>Confirm new password</label>
                  <input
                    type="password"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    placeholder="Enter new password"
                    value={confirmpassword}
                  />
                </div>

                <div className="signup-btn col-12">
                  <button
                    type="submit"
                    className="gradient_CTA"
                    disabled={disable}
                  >
                    SAVE
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
