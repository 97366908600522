import React from "react";
import { Avatar } from "@mui/material";
// import moment from "moment-timezone";

const Component = ({ service }) => {
  // const getDur = (startdate,enddate) => {
  //     // var dateTime = date + "T" + time;
  //     var scheduleTime = moment(enddate);
  //     var current = moment(startdate);
  //     // var ofset = moment().utcOffset() / 60;

  //     var dayDiff = scheduleTime.diff(current, "days");
  //     if (dayDiff > -1) {
  //       return Math.abs(dayDiff) + " days";
  //     } else if (dayDiff === -1) return Math.abs(dayDiff) + " day";
  //     else {
  //       var hoursDiff = parseFloat(scheduleTime.diff(current, "minutes") / 60);
  //       //- parseFloat(timezone.split(" ", 3)[2]) +
  //       // ofset;
  //       if (hoursDiff <= -1) {
  //         let hoursDiffRound = Math.round(hoursDiff);
  //         return Math.abs(hoursDiffRound) == 1
  //           ? Math.abs(hoursDiffRound) + " hour"
  //           : Math.abs(hoursDiffRound) + " hours";
  //       } else {
  //         return Math.abs(Math.round(hoursDiff * 60)) + " mins";
  //       }
  //     }
  //   };

  function getDate(str) {
    var mydate = new Date(str);
    // console.log(mydate.toDateString().slice(4,15));
    return mydate.toDateString().slice(4, 15);
  }

  return (
    <div className="mt-16 w-100">
      <div className="servicereq-box w-100 d-flex flex-column">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-md-6 d-flex align-items-center">
            <Avatar
              alt={service.customerid.name}
              src={service.customerid.avatar}
              sx={{ width: 28, height: 28 }}
              className="mr-4"
            />
            <span className="body1">
              {service && service.customerid.name.length <= 12 ? (
                service.customerid.name
              ) : (
                <>{service.customerid.name.slice(0, 12)}...</>
              )}
            </span>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-end caption">
            {service.serviceid.servicename}
          </div>
        </div>
        <div className="mt-16 mb-12 d-flex justify-content-between">
          <div className="d-flex">
            <div className="w-fit caption mr-32" style={{ color: "#262C5B" }}>
              € {service.serviceid.pricing}
            </div>
            <div className="w-fit caption mr-32" style={{ color: "#FF6F1E" }}>
              {service.sender_type}
            </div>
            <div className="w-fit caption mr-32" style={{ color: "#989898" }}>
              {getDate(service.createdAt)}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span
              className="caption"
              style={{ color: "#989898", marginRight: "6px" }}
            >
              View
            </span>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.47257 6.6668H8.91924L5.66591 9.92013C5.40591 10.1801 5.40591 10.6068 5.66591 10.8668C5.92591 11.1268 6.34591 11.1268 6.60591 10.8668L10.9992 6.47347C11.2592 6.21347 11.2592 5.79347 10.9992 5.53347L6.61258 1.13347C6.48802 1.00863 6.31892 0.938477 6.14258 0.938477C5.96623 0.938477 5.79713 1.00863 5.67257 1.13347C5.41258 1.39347 5.41258 1.81347 5.67257 2.07347L8.91924 5.33347H1.47257C1.10591 5.33347 0.805908 5.63347 0.805908 6.00013C0.805908 6.3668 1.10591 6.6668 1.47257 6.6668Z"
                fill="#989898"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
