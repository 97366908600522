import React from 'react';
import './category.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Utils/Auth';
const CategoryCard = ({category,setGenerationStep,targetId,tab}) => {
     const {user} = useAuth();
     const navigate = useNavigate()
     const handleCategoryOpen = () => {
          setGenerationStep(2);
          navigate(`/dashboard/${user.type}?tab=${tab}&target=${targetId}&category=${category._id}`)
     }
     return (
          <div className="category_card mx-2 p-2 d-flex flex-column justify-content-center" onClick={handleCategoryOpen}>
                    <div className='d-flex flex-column align-items-center justify-content-center mt-2'>
                         <img src={require(`../../../Assets/images/leads/${category.name}.svg`)} alt="host_img" />
                         <h5 className='p_black20 mt-3'>{category.name}</h5>
                    </div>
                    
                    <div className='d-flex my-3'>
                         <p className='text-success mx-3'>€ {category.category_amount?category.category_amount:0} In</p>
                         {/* <p className='text-danger'>€ {totalCategoryAmount} total</p> */}
                         <p className='mx-3'>{category.lead_nos} Leads</p>
                    </div>
          </div>
     )
}

export default CategoryCard;