
import React from 'react'
import Skeleton from '@mui/material/Skeleton';

export default function IncubatorExplore() {
     

     const renderSkeleton = () => {
          const list = [];
          for (let index = 0; index < 8; index++) {
               list.push(
                    <div className="col-lg-3 col-md-6 col-12">
                         <div className="incubation_program_card_root">
                              <Skeleton animation="wave" width="100%" height={150} className="incubation_program_card_head" style={{ transform: "none", borderRadius: "none" }} />

                              <div className="incubation_program_card_main pl-3 pr-3 pb-3">
                                   <div className="incubation_program_card_title">
                                        <h5 className="p_black20">
                                             <Skeleton animation="wave" width="80%" height={55} />
                                        </h5>
                                   </div>
                                   <div className="incubation_program_card_info d-flex justify-content-between">
                                        <div className="incubation_program_card_small">
                                             <span>
                                                  <Skeleton animation="wave" width={60} height={30} />
                                             </span>
                                             <span>  <Skeleton animation="wave" /> </span>
                                        </div>
                                        <span>
                                             <Skeleton animation="wave" width={40} height={30} />
                                        </span>

                                   </div>
                                   <div>
                                        <div className="d-flex justify-content-between align-items-center">
                                             <div className="d-flex align-items-center">
                                                  <Skeleton animation="wave" width={90} height={30} />
                                             </div>
                                             <div className="d-flex align-items-center">
                                                  <Skeleton animation="wave" width={40} height={30} />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               )
          }
          return list
     }
     return (
          <>
          {renderSkeleton()}
          </>
     )
}