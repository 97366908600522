import React, { useEffect, useState } from 'react'
// import { LinearProgress } from '@mui/material';
import { ArrowRightAlt, Autorenew, Download } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import rect1 from "../../../Assets/images/rect1.png"
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow:
    "0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  pt: 2,
  px: 4,
  pb: 3,
  padding: "40px",
};

import ShareModal from "./ShareModal";


const ReportCard = ({ report, setValuationStep, setActiveStep }) => {
    const [progress, setProgress] = useState(100);
    
    const [Shareopen, setShareOpen] = React.useState(false);
    const handleShareOpen = () => setShareOpen(true);
    const handleShareClose = () => setShareOpen(false);

    const returnProgress = (val) => {
        return val ? 1 : 0;
    }

    const getReportProgress = () => {
        const getProgress = returnProgress(report.finFilled && report.labFilled) + returnProgress(report.quesFilled) + returnProgress(report.transFilled);
        setProgress(getProgress * (100 / 3));
    }

    useEffect(() => {
        getReportProgress()
        // console.log(report)
    }, [])

    return (
        <>
            
    <div className="col-lg-3 col-md-6 col-12 p-2">
        <div className={`valuation-ongoingMain`}>
            <div className="valuation-OngoingTitleDiv" style={{ backgroundImage: `url('${rect1}')`,backgroundRepeat:'no-repeat',backgroundPosition:"top" }}>
                <p className="Valuation_report_title px-2">
                    {report.reportTitle}
                </p>
            </div>
            <div className='p-3'>
                <div className="newValuation_statuMain">
                    <p className='smallP_lightGrey'>Date</p>
                    <p className='smallP_Black ml-8'>{new Date(report.reportDate).toDateString()}</p>
                </div>
                <div className='newValuation_status'>
                    <p className='smallP_lightGrey'>Status</p>
                    <div className="newValution_DivQ ml-8">
                        {
                            progress === 100 && report.reportGenerated ?
                                <>
                                    <p className='smallP_Black'>
                                        <a href={report.reportDownloadLink}>
                                            Download 
                                        </a>
                                    </p>
                                    <p className='smallP_lightGrey'>
                                        <span className="w-fit" style={{cursor: "pointer"}} onClick={()=>{handleShareOpen();}}>
                                            <ShareIcon style={{ width: "18px" }} />
                                        </span>
                                        <a href={report.reportDownloadLink}>
                                            <Download style={{ width: "18px" }} />
                                        </a>
                                    </p>
                                </>
                                :
                                progress === 100 && !report.requestFullFilled ?
                                    <>
                                        <p className='smallP_Black'>
                                            Pending 
                                        </p>
                                        <p className='smallP_lightGrey'>
                                            <Autorenew style={{ width: "18px", cursor: "no-drop"}}/>
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p onClick={() => {
                                            localStorage.setItem('valuation_id', report._id);
                                            localStorage.setItem('valuation_title', report.reportTitle)
                                            localStorage.setItem('valuation_cretedDate', report.reportDate)
                                            setValuationStep(3)
                                            Math.floor(progress) === 0 ? setActiveStep(0) : Math.floor(progress) === 33 ? setActiveStep(1) : setActiveStep(2)
                                        }} className='smallP_Black'>
                                            Continue
                                        </p>
                                        <p className='smallP_lightGrey' onClick={() => {
                                            localStorage.setItem('valuation_id', report._id);
                                            localStorage.setItem('valuation_title', report.reportTitle)
                                            localStorage.setItem('valuation_cretedDate', report.reportDate)
                                            setValuationStep(3)
                                            Math.floor(progress) === 0 ? setActiveStep(0) : Math.floor(progress) === 33 ? setActiveStep(1) : setActiveStep(2)}}>
                                            <ArrowRightAlt style={{ width: "18px", cursor: "pointer" }}
                                            />
                                        </p>

                                    </>
                        }
                    </div>
                    
                </div>                
            </div>
            <div className="newValuation_progress_main mt-1">
                <div className="newValuation_progress-shown" style={{ width: `${progress}%`, borderRadius: `${progress === 100 ? "0px 0px 10px 10px" : "0px 0px 0 10px"}` }}>

                </div>
                <span className='newValuation_progress_span'>{Math.floor(progress)} % Completed</span>
            </div>
            
        </div>
    </div>
    
    <Modal
        open={Shareopen}
        onClose={handleShareClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 620 }}>
          <ShareModal handleShareClose={handleShareClose} />
        </Box>
      </Modal>
        </>
    );
}

export default ReportCard