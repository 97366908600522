import React, { useState } from "react";
import "./step1.css";
import "./step2.css";
import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
import { Tooltip } from "@material-ui/core";
// import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Chip from "@mui/material/Chip" ;
// import stripe from "../../../Assets/Images/events/stripe.png";
// import paypal from "../../../Assets/Images/events/paypal.png";
// import klarna from "../../../Assets/Images/events/klarna.png";
import TimeZoneCode from "../TimeZoneCode.json";
import { toast } from "react-toastify";
// import CloseIcon from "@mui/icons-material/Close";
import NumberFormat from "react-number-format";
import StripeConnect from "../StripeConnect/StripeConnect";
import { useAuth } from "../../../../Utils/Auth";

// import { KeyboardAlt } from "@mui/icons-material";

const Step2 = (props) => {
  const { user } = useAuth();

  const {
    activeStep,
    // setActiveStep,
    // handleBack,
    date,
    setDate,
    time,
    setTime,
    duration,
    setDuration,
    isFree,
    setIsFree,
    isPublicEvent,
    setIsPublicEvent,
    isPrivateEvent,
    setIsPrivateEvent,
    eventFees,
    setEventFees,
    // setHostAccountType,
    // hostAccountType,
    keywordTag,
    setKeywordTag,
    keyword,
    addKeyword,
    removeKeyword,
    documentUrl,
    setDocumentUrl,
    handleDocUpload,
    timezone,
    setTimezone,
    documentName,
    eventDetails,
    seteventDetails,
    handleSubmit,
  } = props;

  const handleNext = (e) => {
    if (activeStep < 2) {
      if (!date) {
        return toast.error("Date can't be empty", {
          position: "bottom-right",
        });
      }
      if (!time) {
        return toast.error("Time can't be empty", {
          position: "bottom-right",
        });
      }
      if (!timezone) {
        return toast.error("Timezone can't be empty", {
          position: "bottom-right",
        });
      }
      if (!duration) {
        return toast.error("Duration can't be empty", {
          position: "bottom-right",
        });
      }
      if (isPublicEvent === null || isPrivateEvent === null) {
        return toast.error("Choose Event Type", {
          position: "bottom-right",
        });
      }
      if (isFree === null) {
        return toast.error("Choose payment type", {
          position: "bottom-right",
        });
      }
      if (keyword.length === 0) {
        return toast.error("Keyword can't be empty", {
          position: "bottom-right",
        });
      }
      if (!isFree) {
        if (!user.stripeId || user.stripeId === null) {
          return toast.error(
            "Please connect with Stripe to host a paid event",
            {
              position: "bottom-right",
            }
          );
        }
      }
      handleSubmit(e);
      seteventDetails({
        ...eventDetails,
        date,
        time,
        timezone,
        duration,
        documentUrl,
        documentName,
        eventFees,
        keyword,
        isFree,
      });
    }
  };

  const handleDocUrl = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDocumentUrl((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [formatedValue, setFormatedValue] = useState(eventFees);

  function handleValuation(e) {
    setFormatedValue(e.target.value);
    let s = e.target.value;
    if (s) {
      var n = s.split("$", 2)[1];
      setEventFees(Number(n.split(",").join("")));
    }
  }

  const handleLocalUpload = () => {
    seteventDetails({
      ...eventDetails,
      date,
      time,
      timezone,
      duration,
      documentUrl,
      documentName,
      eventFees,
      keyword,
      isFree,
    });
  };

  return (
    <>
      <div className="main-tab-content mt-5">
      <div className="incubator_create_program_head">
          <h4>Specific Information</h4>
        </div>
        <div className="main-event-form">
          <form className="row">
              <div className="auth_back_loginform col-12 col-md-6">
                <label htmlFor="date">Date</label>
                <input
                  type="date"
                  name="date"
                  className="event_date_input"
                  id="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="auth_back_loginform col-12 col-md-6">
                <label htmlFor="time">Time</label>
                <input
                  type="time"
                  name="time"
                  className="event_date_input"
                  id="time"
                  defaultValue="19:00"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>
              <div className="auth_back_loginform col-12 col-md-6">
                <label htmlFor="timezon">TimeZone</label>
                <select
                  name="timezon"
                  id="timezon"
                  value={timezone}
                  onChange={(e) => setTimezone(e.target.value)}
                >
                  {TimeZoneCode.map((val, i) => {
                    return (
                      <option
                        className="event-inputs-option"
                        key={i}
                        value={
                          val.abbr +
                          " " +
                          val.utcOffset +
                          " " +
                          val.offset +
                          " " +
                          val.utc[0]
                        }
                      >{`${val.value} ${val.text}`}</option>
                    );
                  })}
                </select>
              </div>

              <div className="auth_back_loginform col-12 col-md-6">
                <label htmlFor="duration">Duration</label>
                <select
                  name="duration"
                  id="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  {/* <option value=""></option> */}
                  <option className="" value="below 15 min">
                    Below 15 mins
                  </option>
                  <option value="30 min">30 mins</option>
                  <option value="45 min">45 mins</option>
                  <option value="01:00 hr">01:00 hrs</option>
                  <option value="01:30 hr">01:30 hrs</option>
                  <option value="2 hr">2 hrs</option>
                  <option value="Above 02 hr">Above 02 hrs</option>
                </select>
              </div>
            <div className="auth_back_loginform col-12 col-md-6">
              <label htmlFor="payment">Payment</label>
              <RadioGroup
                row
                aria-label="payment"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="free"
                  control={<Radio 
                  sx={{
                    color: "#FF6F1E",
                    '&.Mui-checked': {
                      color: "#FF6F1E",
                    },
                  }}  
                  checked={isFree} />}
                  label="Free"
                  name="payment"
                  style={{marginTop: "0"}}
                  onChange={() => {
                    setIsFree(true);
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                  value="paid"
                  control={<Radio 
                  sx={{
                    color: "#FF6F1E",
                    '&.Mui-checked': {
                      color: "#FF6F1E",
                    },
                  }}  
                  checked={!isFree} />}
                  label="Paid"
                  name="payment"
                  style={{marginTop: "0"}}
                  onChange={() => {
                    setIsFree(false);
                  }}
                />
              </RadioGroup>
              
            </div>
            <div className="auth_back_loginform col-12 col-md-6">
              <label htmlFor="eventType">Event Type</label>
              <RadioGroup
                row
                aria-label="eventType"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="public"
                  control={<Radio
                  sx={{
                    color: "#FF6F1E",
                    '&.Mui-checked': {
                      color: "#FF6F1E",
                    },
                  }}  
                   />}
                  label="Public"
                  style={{marginTop: "0"}}
                  onChange={() => {
                    setIsPublicEvent(true);
                    setIsPrivateEvent(false);
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <FormControlLabel
                  value="private"
                  control={<Radio
                  sx={{
                    color: "#FF6F1E",
                    '&.Mui-checked': {
                      color: "#FF6F1E",
                    },
                  }}  
                   />}
                  label="Private"
                  style={{marginTop: "0"}}
                  onChange={() => {
                    setIsPublicEvent(false);
                    setIsPrivateEvent(true);
                  }}
                />
              </RadioGroup>
            </div>

                {!isFree ? (
                  <>
                    <div className="auth_back_loginform col-12 col-md-6 paidPrice">
                      <NumberFormat
                        placeholder="100"
                        displayType={"number"}
                        thousandSeparator={true}
                        prefix={"€ "}
                        className="some auth_input"
                        inputmode="numeric"
                        value={formatedValue}
                        onChange={(e) => handleValuation(e)}
                      />
                    </div>
                    {/* <p>Pay With</p>
                    <div className="paymentMethod">
                      <div className="paymethodDiv">
                        <label htmlFor="pay1">
                          <input
                            type="radio"
                            name="paymethod"
                            id="pay1"
                            value="stripe"
                            onChange={(e) => setHostAccountType(e.target.value)}
                          />
                          <figure>
                            <img src={stripe} alt="stripe" />
                          </figure>
                        </label>
                      </div>
                      <div className="paymethodDiv">
                        <label htmlFor="pay2">
                          <input
                            type="radio"
                            name="paymethod"
                            id="pay2"
                            value="paypal"
                            onChange={(e) => setHostAccountType(e.target.value)}
                          />
                          <figure>
                            <img src={paypal} alt="paypal" />
                          </figure>
                        </label>
                      </div>
                      <div className="paymethodDiv">
                        <label htmlFor="pay3">
                          <input
                            type="radio"
                            name="paymethod"
                            id="pay3"
                            value="klarna"
                            onChange={(e) => setHostAccountType(e.target.value)}
                          />
                          <figure>
                            <img src={klarna} alt="klarna" />
                          </figure>
                        </label>
                      </div>
                    </div> */}
                  </>
                ) : null}

            {!isFree ? (
              <>
              <div className="auth_back_loginform col-12 col-md-7">
                <label style={{marginBottom: "10px"}}>
                  Connect with Stripe to accept payments for your event.
                </label>
                {user.stripeId != null ? (
                  <>
                    <p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check-lg"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                      </svg>{" "}
                      Stripe already connected
                    </p>
                  </>
                ) : (
                  <>
                    {/* <br /> */}
                    <StripeConnect handleLocalUpload={handleLocalUpload} />
                  </>
                )}
                </div>
              </>
            ) : null}

            <div className="auth_back_loginform col-12">
              <label htmlFor="documents">Documents(Optional)</label>
              <div className="documentdiv">
                <input
                  type="text"
                  name="docName"
                  placeholder="Name of document"
                  value={documentUrl.docName}
                  onChange={handleDocUrl}
                />
                {/* <input
                  type="text"
                  name="docUrl"
                  placeholder="Link (must include http/https) "
                  value={documentUrl.docUrl}
                  onChange={handleDocUrl}
                /> */}
              </div>
              <div className="documentupload">
                {/* <label>or</label> */}
                <div>
                  <label>Upload File</label>
                  <span className="eventdoc"  style={{cursor: "pointer"}}>
                    
                  <input
                    type="file"
                    name="eventDocFile"
                    accept="application/pdf, application/doc, application/docx, application/ppt, application/txt"
                    onChange={handleDocUpload}
                    style={{position:"relative",zIndex: "3"}}
                  />
                    <span style={{position:"relative",left:"-55px",zIndex: "0",cursor: "pointer"}}>
                    <i className="fa-solid fa-paperclip" style={{color: "#262C5B",cursor: "pointer",zIndex: "3"}}></i>
                    </span>
                  </span>
                </div>
                {documentName === null ? (
                  null
                ) : (
                  <>
                    <div id="uploaddocid">
                      <label style={{marginTop: "8px"}}>{documentName}</label>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="auth_back_loginform col-12">
              <label htmlFor="keywords">Keywords</label>
              <div className="goalsdiv">
                <input
                  type="text"
                  name="keywords"
                  id="keywords"
                  placeholder="Add new tags"
                  value={keywordTag}
                  onChange={(e) => setKeywordTag(e.target.value)}
                />
                <Tooltip title="Add">
                  <button
                    type="button"
                    className="addrowsbtn"
                    onClick={addKeyword}
                  >
                    <AddIcon />
                  </button>
                </Tooltip>
              </div>
              <div className="goalsscroll">
                {keyword
                  ? keyword.map((key, i) => {
                      return (
                        <>
                          <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                            <p className="program_text">{key}</p>
                            <Tooltip title="Delete Row">
                              <button
                                type="button"
                                title="Remove"
                                onClick={() => removeKeyword(i)}
                                className="program_btn"
                              >
                                x 
                              </button>
                            </Tooltip>
                          </span>
                        </>
                      );
                    })
                  : null}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="savenextbtn">
        {/* <button className="gradient_CTA" type="submit" onClick={handleBack}>
          BACK
        </button> */}
        <button className="gradient_CTA" type="submit" onClick={handleNext}>
          SUBMIT
        </button>
      </div>
    </>
  );
};
// Step2.propTypes = {
//   //   questionnare: PropTypes.any,
//   handleNext: PropTypes.any,
//   handleBack: PropTypes.any,
// };
export default Step2;
