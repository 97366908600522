import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useAuth } from "../../../Utils/Auth";
import { toast } from "react-toastify";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";

const index = ({ setstep, id, fetchInvites, setmentorState }) => {
  const [mentor, setmentor] = useState(null);
  const [checkconnect, setcheckconnect] = useState(null);
  // const [checkvc, setcheckvc] = useState(null);
  const navigate = useNavigate();
  const { setSessionExpire, user } = useAuth();

  const fetchmentor = () => {
    Axios({
      url: `/api/mentor/mentor/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setmentor(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const fetchConnection = () => {
    Axios({
      url: `/api/mentor/checkconnect/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        setcheckconnect(data.success);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  // const fetchVCConnection = () => {
  //   Axios({
  //     url: `/api/mentor/checkvc/${id}`,
  //     method: "get",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => {
  //       const { data } = res;
  //       setcheckvc(data.success);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.response.status === 401) {
  //         setSessionExpire(true);
  //       }
  //     });
  // };

  useEffect(() => {
    fetchmentor();
    fetchConnection();
    // fetchVCConnection();
  }, []);

  if (mentor === null) return <p>loading...</p>;

  const sendRequest = () => {
    Axios({
      url: `/api/mentor/send/request/${id}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          toast.success("Mentorship request sent");
          fetchInvites();
          fetchConnection();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  return (
    <>
      <div>
        <div
          className="back_CTA pointer"
          onClick={() => {
            setstep(0);
            // history(`/dashboard/${user.type}?tab=2`);
          }}
        >
          <p className="caption color_light_grey">
            <span>
              <KeyboardArrowLeft />
            </span>
            <span>BACK</span>
          </p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-8">
          <div className="connection_profile_root" id="connection_profile_root">
            <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
              <div className="row no-margin align-items-center col-8">
                <div className="width_auto no-padding">
                  <Avatar
                    src={mentor.profilePic}
                    className=""
                    alt="Profile"
                    sx={{ width: 90, height: 90 }}
                  />
                </div>
                <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                  <p className="p_spartan_20">{mentor.name}</p>
                  <p className="bold">Mentor | {mentor.location.country}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
            </div>
            <div className="mt-4 mx-2">
              <p className="grey bold">{mentor.description}</p>
            </div>

            <div className="row mx-2 my-3">
              {mentor.interestedDomains ? (
                <>
                  {mentor.interestedDomains.map((industry, i) => (
                    <p className="p_grey_chip" key={i}>
                      {industry}{" "}
                    </p>
                  ))}
                </>
              ) : null}
            </div>
            <hr />

            <div className="row no-margin p-2 white_bg">
              <div className=" no-padding">
                <div className="connections_highlights_investor">
                  {mentor.involvement ? (
                    <>
                      <div>
                        <p className="p_spartan_20"> Prefered Involvement </p>
                        {mentor.involvement.length > 0 ? (
                          <div className="mt-4">
                            {mentor.involvement.map((inv, i) => (
                              <p className="" key={i}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-dot"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                </svg>
                                {inv.name}
                              </p>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {checkconnect === "null" ? (
              <>
                <button
                  type="button"
                  className="gradient_CTA mx-2"
                  onClick={() => sendRequest()}
                >
                  Request Mentorship
                </button>
              </>
            ) : checkconnect === false ? (
              <button type="button" className="gradient_CTA mx-2" disabled>
                Pending
              </button>
            ) : (
              <button
                type="button"
                className="gradient_CTA mx-2"
                onClick={() => {
                  navigate(`/dashboard/${user.type}?tab=5`);
                  setmentorState(2);
                }}
              >
                GO TO MENTOR-SPACE
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="connection_profile_root_highligts">
            <div className="connection_profile_root_highligts_head">
              <p className="p_spartan_20">Highlights</p>
            </div>
            <div className="connection_profile_root_highligts_desc">
              <table>
                <tr>
                  <td className="td_left">Current Company</td>
                  <td className="td_right">{mentor.currentCompany}</td>
                </tr>

                <tr>
                  <td className="td_left">Current Job Role</td>
                  <td className="td_right">{mentor.currentJobRole}</td>
                </tr>
                <tr>
                  <td className="td_left">Past Experience</td>
                  <td className="td_right">{mentor.pastExperience}</td>
                </tr>
                <tr>
                  <td className="td_left">Industry</td>
                  <td className="td_right">{mentor.industry}</td>
                </tr>

                <tr>
                  <td className="td_left">Linkedin</td>
                  <td className="td_right">{mentor.linkedin}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
