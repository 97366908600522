import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";

import Avatar from "@mui/material/Avatar";

const AllAplicantCard = ({ serviceid, setstartupid, setapplicantstep }) => {
  const { setSessionExpire } = useAuth();
  const [applicants, setapplicants] = useState([]);

  const fetchapplicants = async () => {
    await Axios({
      url: `/api/service/applications/${serviceid}/false`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setapplicants(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    fetchapplicants();
  }, []);

  return (
    <>
      {applicants.length > 0 ? (
        <>
          {applicants.map((v, i) => {
            return (
              <div
                key={i}
                className="col-lg-3 col-md-6 col-12 mb-4"
                onClick={() => {
                  setapplicantstep(1);
                  setstartupid(v.spid._id);
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="incubation_profile_card_main">
                  <div className="incubation_profile_card_head">
                    <div className="incubation_profile_card_profile">
                      <Avatar
                        src={v.spid.avatar}
                        className=""
                        alt="Profile"
                        sx={{ width: 60, height: 60 }}
                      />
                    </div>
                  </div>
                  <div className="incubation_profile_card_title">
                    <p>{v.spid.name}</p>
                    <div className="incubation_profile_card_subtitle">
                      <p className="p_LightGrey12 mr-2">Service Provider</p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : null}
    </>
  );
};

export default AllAplicantCard;
