import React, { useState, useEffect } from 'react'
import Axios from "axios";
import { useAuth } from "../../../../../Utils/Auth";
import host_img from "./../../../../../Assets/images/events/host.png";
import { CourseCardSkeleton } from '../../../../../Components/SkeletonLoader/ProgramDashboard';
// import search from "./../../../../../Assets/images/icons/search.png";
import Card from "./Card";
import './course.css'
import { useParams } from 'react-router-dom';

const ViewCourse = ({ setLearningState }) => {
  const { setSessionExpire } = useAuth();
  const [courses, setCourses] = useState([]);
  const { programid } = useParams();
  const [isLoading,setIsLoading] = useState(false);
  const fetchCourses = async () => {
    setIsLoading(true)
    await Axios({
      url: `/api/mentorModules/courses/by-program/${programid}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {setCourses(res.data.data)
          setIsLoading(false)
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };

  useEffect(() => {
    fetchCourses();
    // fetchCourses2();
  }, [])
  return <div className="incubation_program_root">
    {/* <div className="incubation_prorgram_search_head">
      <div className="row">
        <div className="col-lg-8 col-md-8 col-10">
          <div className="incubation_programm_searchBoxDiv">
            <input type="text" name="" id="" />
            <button className="p_Bold_secBlue14">
              <img src={search} alt="search" />
              Search
            </button>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-2">
          
        </div>
      </div>
    </div> */}
    <div className="incubation_program_main mt-8">
      <div className="row">
        <div className="col-12 point mb-24" onClick={() => setLearningState(2)}>
          <div className="onboarding_cards incubation_create_courseFirst2">
            <img src={host_img} alt="host_img" className='mr-16' />
            <p className='mt-2'>Create A Course</p>
          </div>
        </div>
        {isLoading?<CourseCardSkeleton/>:(<>
        {courses && courses.length > 0
          ? courses.map((course, i) => (<>
            {
              !course.is_deleted ?
                <Card
                  key={i}
                  course={course}
                  setLearningState={setLearningState}
                  fetchCourses={fetchCourses}
                /> : null
            }
          </>
          ))
          : <p>No courses found</p>}</>)}
      </div>
    </div>
  </div>

}

export default ViewCourse