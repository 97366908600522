import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import QuestionComp from './QuestionComp'
import { toast } from 'react-toastify'
import Axios from "axios"
import { Tooltip } from '@mui/material'
import { Delete, FileCopy } from '@mui/icons-material'
import DeleteModal from '../../../../../../Utils/DeleteModal/DeleteModal'

const AssesmentEditModule = (props) => {
    const { data, i, addQuestion, showAnsKey, setAnsKey, assessment, getAssessment, setShowAddQ } = props;
    const [updateQ, setUpdateQ] = useState(false)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = useNavigate();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const module_id = query.get('module')
    const course_id = query.get('course')
    const [answer, setAnswer] = useState("")
    const { programid } = useParams()
    const [eQuestion, setEQuestion] = useState(data.question)
    const [eQuestion1, setEQuestion1] = useState(data.options.option_one)
    const [eQuestion2, setEQuestion2] = useState(data.options.option_two)
    const [eQuestion3, setEQuestion3] = useState(data.options.option_three)
    const [eQuestion4, setEQuestion4] = useState(data.options.option_four)
    const [eQuestion5, setEQuestion5] = useState(data.options.option_five)
    const returnCount = (val) => {
        return val ? 1 : 0
    }

    const [count, setCount] = useState([1]);

    const setAnswerKeyVal = (e) => {        
        const val = e.target.value;

        if (val === "1") {
            setAnswer(eQuestion1)
        }
        else if (val === "2") {
            setAnswer(eQuestion2)
        }
        else if (val === "3") {
            setAnswer(eQuestion3)
        }
        else if (val === "4") {
            setAnswer(eQuestion4)
        }
        else if (val === "5") {
            setAnswer(eQuestion5)
        }

    }

    useEffect(() => {
        const newVal = returnCount(eQuestion1) + returnCount(eQuestion2) + returnCount(eQuestion3) + returnCount(eQuestion4) + returnCount(eQuestion5);
        for (let i = 1; i < newVal; i++) {
            setCount((prev) => [...prev, 1]);
        }
        // console.log(newVal)
    }, [])

    const updateQuestion = async (e) => {
        // console.log(answer)
        e.preventDefault();
        if (answer === "") {
            return toast.warning("Please select answer key")
        }
        if (count.length < 2) {
            return toast.warning("At least 2 Question should be there")
        }
        await Axios({
            url: `/api/mentorModules/assessment/update/${assessment._id}/${data._id}`,
            method: "put",
            data: {
                question: eQuestion,
                option_type: "MCQ",
                options: {
                    option_one: eQuestion1,
                    option_two: eQuestion2,
                    option_three: eQuestion3,
                    option_four: eQuestion4,
                    option_five: eQuestion5,
                },
                answer: answer
            }
        }).then(() => {
            getAssessment();
            // setEQuestion("")
            // setEQuestion1("")
            // setEQuestion2("")
            // setEQuestion3("")
            // setEQuestion4("")
            // setEQuestion5("")
            // setAnswer("")
            // setCount([1])
            setAnsKey(false)
            setUpdateQ(false)
            // setAnsKey()
        }).catch(() => {
            toast.error("Something went wrong, try again");
        })
    }

    const cloneQuestion = async (id) => {
        await Axios({
            url: `/api/mentorModules/assessment/question/clone/${assessment._id}/${id}`,
            method: "put",
        })
            .then(() => {
                toast.success("Question Cloned");
                getAssessment();
            })
            .catch(() => {
                toast.error("Something went wrong, try again");
            });
    }

    const deleteQuestion = async () => {
        await Axios({
            url: `/api/mentorModules/assessment/delete/${assessment._id}/${data._id}`,
            method: "delete",
        })
            .then(() => {
                toast.success("Question Cloned");
                getAssessment();
            })
            .catch(() => {
                toast.error("Something went wrong, try again");
            });
    }

    return <>
        <div className="assesmentEditHead">
            <div className="assmentEditHeadmain0">
                <div className="assesmentEditLEft">
                    <p className='body1 color_dark_black'>{i + 1}.  {data.question}</p>
                </div>
                <div className="assesmentEditright d-flex align-items-center">
                    <Tooltip title="Delete">
                        <p className="modulecomp_icons point" onClick={handleOpen}><Delete /></p>
                    </Tooltip>
                    <Tooltip title="Clone">
                        <p className="modulecomp_icons point" onClick={() => cloneQuestion(data._id)}><FileCopy /></p>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <p
                            className={`point caption pr-6 modulecomp_icons`}
                            onClick={() => {
                                navigate(`/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}&question=${data._id}`)
                                setUpdateQ(!updateQ)
                                setShowAddQ(false)
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                fill="#989898"
                                className={`bi bi-pencil-square`}
                                viewBox="0 0 16 16"
                            >
                                <path d="M15.502 1.94a.5.5 0 010 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 01.707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 00-.121.196l-.805 2.414a.25.25 0 00.316.316l2.414-.805a.5.5 0 00.196-.12l6.813-6.814z"></path>
                                <path
                                    fillRule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 002.5 15h11a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H9a.5.5 0 000-1H2.5A1.5 1.5 0 001 2.5v11z"
                                ></path>
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className={`bi bi-chevron-down mx-2 ${updateQ ? 'rotateAnsKey' : 'rotateAnsKey2'}`}
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                            </svg>
                        </p>
                    </Tooltip>
                    {/* <p className='caption color_dark_blue point' onClick={() => {
                        navigate(`/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}&question=${data._id}`)
                        setUpdateQ(!updateQ)
                        setShowAddQ(false)
                    }}>Edit</p> */}
                </div>
            </div>
            <div className="assmentEditHeadmain">
                {
                    updateQ ? <QuestionComp
                        question={eQuestion}
                        question1={eQuestion1}
                        question2={eQuestion2}
                        question3={eQuestion3}
                        question4={eQuestion4}
                        question5={eQuestion5}
                        setQuestion={setEQuestion}
                        setQuestion1={setEQuestion1}
                        setQuestion2={setEQuestion2}
                        setQuestion3={setEQuestion3}
                        setQuestion4={setEQuestion4}
                        setQuestion5={setEQuestion5}
                        count={count}
                        setCount={setCount}
                        setAnsKey={setAnsKey}
                        showAnsKey={showAnsKey}
                        addQuestion={addQuestion}
                        setAnswerKeyVal={setAnswerKeyVal}
                        pos={i}
                        updateQ={updateQ}
                        updateQuestion={updateQuestion}
                        answer={answer}
                    /> : null
                }
            </div>

        </div>
        <DeleteModal
            open={open}
            handleClose={handleClose}
            deleteFunction={deleteQuestion}
        />
    </>
}

export default AssesmentEditModule