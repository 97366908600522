import React, { useState } from "react";
import "./../../authentication.css";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
// import ClearIcon from "@mui/icons-material/Clear";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import Axios from "axios";
import LocationComponent from "../../../../Utils/Location/index";
// import industries2 from "../../../../Pages/Lists/Industries2.json";
// import Multiselect from "multiselect-react-dropdown";
import industries from "./../../../Lists/Industries.json";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@material-ui/core";



const Step1 = (props) => {
  const {
    legalname,
    setlegalname,
    nameOfCorporateType,
    setnameOfCorporateType,
    typeOfCorporate,
    settypeOfCorporate,
    bio,
    setbio,
    companyWebsite,
    setcompanyWebsite,
    linkedin,
    setlinkedin,
    IncorporationDate,
    setIncorporationDate,
    industry,
    setIndustry,
    otherIndustry,
    setOtherIndustry,
    description,
    setdescription,
    country,
    setcountry,
    state,
    setstate,
    city,
    setcity,
    states,
    cities,
    setstates,
    setcities,
    setservices,
    services,
    companyLogo,
    setCompanyLogoUrl,
    profile,
    setProfile,
  } = props;
  // const [options] = useState(industries2);

  const [islogoloading, setislogoloading] = useState(false);
  


  
  const [benefit, setbenefit] = useState("");
  const addObjective = (arr, seto, v, setv) => {
    if (arr) {
      seto((prev) => [...prev, v]);
      setv("");
    }
  };
  const removeObjective = (arr, seto, id) => {
    const newArr = arr.filter((obj, i) => {
      return id !== i;
    });
    seto(newArr);
  };

  const handleLogoUpload = (e) => {
    const image = e.target.files[0];

    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("companyLogo", image);
    setislogoloading(true);
    Axios({
      method: "post",

      url: "/api/upload/companylogo",

      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          setCompanyLogoUrl(data.location);
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }
        setProfile({
          ...profile,
          companyLogo: data.location,
        });
        setislogoloading(false);
        toast.success("Company logo uploaded", { position: "bottom-right" });
        // setProgress(25);
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };
  // const addInputEvent3 = (e) => {
  //   setInterest(e.map((i) => i.label));
  // };

  return (
    <form>
      {/* <p className="text-dark mt-2">Company Details</p> */}
      <div className="register_page_form_upload">
        {islogoloading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            {companyLogo ? (
              <>
                <img src={companyLogo} alt="logo" className="companyLogo" />
                <p
                  className="text-dark mt-2"
                  onClick={() => setCompanyLogoUrl(null)}
                  style={{ cursor: "pointer" }}
                >
                  Delete
                </p>
              </>
            ) : (
              <>
                <label htmlFor="clogo">
                  <div className="register_page_form_com_logo" onClick={{}}>
                    Upload
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    name="profile_pic"
                    id="clogo"
                    value={companyLogo}
                    onChange={(e) => handleLogoUpload(e)}
                  />
                </label>
                <p className="register_page_form_upload-text mt-1">
                  Company Logo
                </p>
              </>
            )}
          </>
        )}
      </div>

      <div className="register_page_form row">
        <div className="col-12">
          <label className="auth_label">Legal Name of Corporate*</label>
          <input
            placeholder="Enter Name"
            required
            type="text"
            name="legalname"
            className="auth_input"
            value={legalname}
            onChange={(e) => {
              setlegalname(e.target.value);
            }}
          />
        </div>
        <div className="col-12">
          <label className="auth_label">
            Brand name*
          </label>
          <input
            placeholder="Enter Name"
            required
            type="text"
            className="auth_input"
            name="nameOfCorporateType"
            value={nameOfCorporateType}
            onChange={(e) => {
              setnameOfCorporateType(e.target.value);
            }}
          />
        </div>
        <div className="col-12">
          <label className="auth_label">Corporate Type*</label>
          <select
            className="auth_input"
            name="typeOfCorporate"
            value={typeOfCorporate}
            onChange={(e) => settypeOfCorporate(e.target.value)}
          >
            <option className="" value="">
              Select Corporate Type
            </option>
            <option className="" value="Corporate Company">
              Corporate Company
            </option>
            <option className="" value="Corporate Business Unit">
              Corporate Business Unit
            </option>
            <option className="" value="Corporate Venture">
              Corporate Venture
            </option>
            <option className="" value="Corporate Incubator">
              Corporate Incubator
            </option>
            <option className="" value="Other">
              Others
            </option>
          </select>
        </div>
        <div className="col-12">
          <label className="auth_label">Corporate Profile*</label>
          <textarea
            required
            name="short_desc"
            className="auth_input auth_textarea col-12"
            placeholder="Write  a short description"
            value={bio}
            onChange={(e) => setbio(e.target.value)}
          ></textarea>
        </div>
        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
              <label className="auth_label">Company&#39;s Website</label>
              <input
                placeholder="Enter Company’s Website"
                required
                type="text"
                className="auth_input"
                value={companyWebsite}
                onChange={(e) => setcompanyWebsite(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <label className="auth_label">LinkedIn Profile</label>
              <input
                placeholder="LinkedIn Profile Url"
                required
                type="text"
                className=" auth_input"
                value={linkedin}
                onChange={(e) => setlinkedin(e.target.value)}
              />
            </div>
          </div>
        </div>
        </div>
        <div className="col-12">
          <div className="row">
          <div className="col-12 col-md-6">
            <div>
              <label>Incorporation Date*</label>
              <input
                required
                id="sinc"
                type="text"
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                className="auth_input"
                placeholder="Enter Incorporation Date"
                value={IncorporationDate}
                onChange={(e) => setIncorporationDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <label>Industry</label>
              <select
                className="auth_input auth_select"
                name="industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
              >
                <option value="">Select Industry</option>
                {industries.map((item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        </div>
        {industry === "Other" ? (
          <>
            <div className="col-12">
              <label>Industry</label>
              <input
                type="text"
                placeholder="Please specify your industry"
                className="auth_input"
                value={otherIndustry}
                onChange={(e) => setOtherIndustry(e.target.value)}
              />
            </div>
          </>
        ) : null}


        <div className="col-12">
          <label className="auth_label">Short Description*</label>
        <textarea
          required
          id="sdesc"
          name="short_desc"
          className="auth_input auth_textarea"
          placeholder="Write  a short description"
          value={description}
          onChange={(e) => setdescription(e.target.value)}
        ></textarea>
        </div>
        
        <div className="col-12">
          <label className="auth_label">Location*</label>
            <LocationComponent
              country={country}
              setcountry={setcountry}
              state={state}
              setstate={setstate}
              city={city}
              setcity={setcity}
              states={states}
              cities={cities}
              setstates={setstates}
              setcities={setcities}
            />
        </div>

        
        <div className="col-12">
          <label className="auth_label">Core Products/Services</label>
            <div className="goalsdiv">
              <input
                type="text"
                name="objective"
                className="auth_input"
                placeholder="Enter your Products/Services"
                id="objective"
                value={benefit}
                onChange={(e) => setbenefit(e.target.value)}
              />
              <Tooltip title="Add More Rows">
                <button
                  type="button"
                  onClick={() =>
                    addObjective(
                      services,
                      setservices,
                      benefit,
                      setbenefit
                    )
                  }
                >
                  <AddIcon />
                </button>
              </Tooltip>
            </div>

            <div className="goalsscroll">
              {services
                ? services.map((obj, i) => {
                    return (
                      <>
                        <span className="badge badge-pill badge-dark goalsvalues program_box ml-3 mt-3" key={i}>
                          <p className="program_text">{obj}</p>
                          <Tooltip title="Delete Row">
                            <button
                              type="button"
                              title="Remove"
                              onClick={() =>
                                removeObjective(
                                  services,
                                  setservices,
                                  i
                                )
                              }
                              className="program_btn"
                            >
                              x 
                            </button>
                          </Tooltip>
                        </span>
                      </>
                    );
                  })
                : null}
            </div>
          </div>

      </div>
    </form>
  );
};

export default Step1;
