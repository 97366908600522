/** @format */

import React from "react";
// import Checkbox from "./elements/Checkbox";
import Input from "./Elements/Input";
import Select from "./Elements/Select";
import PropTypes from "prop-types";

const Element = ({
  field: {
    field_type,
    field_id,
    field_label,
    field_placeholder,
    field_value,
    field_options,
    isDropDown,
  },
  key,
}) => {
  switch (field_type) {
    case "text":
      return (
        <Input
          key={key}
          field_type={field_type}
          field_id={field_id}
          field_label={field_label}
          field_placeholder={field_placeholder}
          field_value={field_value}
        />
      );
    case "number":
      return (
        <Input
          key={key}
          field_type={field_type}
          field_id={field_id}
          field_label={field_label}
          field_placeholder={field_placeholder}
          field_value={field_value}
        />
      );
    case "select":
      return (
        <Select
          key={key}
          field_id={field_id}
          field_label={field_label}
          field_placeholder={field_placeholder}
          field_value={field_value}
          field_options={field_options}
          isDropDown={isDropDown}          
        />
      );
    // case "checkbox":
    //   return (
    //     <Checkbox
    //       field_id={field_id}
    //       field_label={field_label}
    //       field_value={field_value}
    //     />
    //   );

    default:
      return null;
  }
};

Element.propTypes = {
  field_id: PropTypes.number.isRequired,
  field_label: PropTypes.string.isRequired,
  field_placeholder: PropTypes.string.isRequired,
  field_value: PropTypes.any.isRequired,
  field_type: PropTypes.string.isRequired,
  key: PropTypes.number.isRequired,
  field_options: PropTypes.any.isRequired,
  isDropDown: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
};
export default Element;
