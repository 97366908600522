/** @format */

import Axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../authentication.css";
import "./getintouch.css";
// import countries from "../Lists/countries2.json";
import BackgroundImg from "../../backgroundImg";
import Location from "./../../../Utils/Location";
import OTPService from "./../../../Utils/OTP";

export default function Login() {
  //   const { setRole, setLoadCurrent, loadCurrent } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [companyName, setcompanyName] = useState("");
  // const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [isphoneVerified, setphoneVerified] = useState(false);
  const [companySize, setcompanySize] = useState("");
  // const [country] = useState(countries);
  const [contactPerson, setContactPerson] = useState("");
  const [message, setMessage] = useState("");

  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    // setLocation("lko");
    if (
      companyName.length === 0 ||
      companySize === "" ||
      contactPerson === "" ||
      // location === "" ||
      country === "" ||
      state === "" ||
      city === "" ||
      isphoneVerified === "" ||
      message === "" ||
      email.length === 0
    ) {
      return toast.error("Please provide complete details", {
        position: "bottom-right",
      });
    }

    const data = {
      contactPerson,
      message,
      companyName,
      companySize,
      location,
      email,
      phone,
    };
    await Axios.post("/api/corporate/signup", data)
      .then(() => {
        toast.success("Success", { position: "bottom-right" });
        navigate(`/thankyou/152154`);
      })
      .catch((res) => {
        toast.error(res.response.data.message, { position: "bottom-right" });
      });
  };
  return (
    <>
      <div className="pt-1">
        <div className="container row">
          <div className="col-12 col-md-2"></div>
          <div
            className="col-12 col-md-8"
            style={{ padding: "10px", background: "black" }}
          >
            <div className="auth_header">
              <h1 className="h2_white_30">GET IN TOUCH WITH US</h1>
            </div>

            <div className="auth_form">
              <form onSubmit={handleOnSubmit} className="row">
                <input
                  id="outlined-basic"
                  placeholder="Contact Person"
                  onChange={(e) => setContactPerson(e.target.value)}
                  required
                  type="text"
                  className="auth_input col-12"
                  value={contactPerson}
                />
                <input
                  id="outlined-basic"
                  placeholder="Company Name"
                  onChange={(e) => setcompanyName(e.target.value)}
                  required
                  type="text"
                  className="auth_input col-12"
                  value={companyName}
                />
                <input
                  id="outlined-basic"
                  placeholder="Work Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="auth_input col-12"
                  value={email}
                />

                <select
                  className="auth_input col-12"
                  name="grossValue"
                  value={companySize}
                  onChange={(e) => setcompanySize(e.target.value)}
                >
                  <option className="color_black" value="">
                    Company Size
                  </option>

                  <option className="color_black" value="<200">
                    &lt; 200
                  </option>
                  <option className="color_black" value="2000 to 5000">
                    200-1000
                  </option>
                  <option className="color_black" value="1000-5000">
                    1000-5000
                  </option>
                  <option className="color_black" value=">5000">
                    &gt;5000
                  </option>
                </select>

                <Location
                  className="col-12"
                  country={country}
                  setcountry={setcountry}
                  state={state}
                  setstate={setstate}
                  city={city}
                  setcity={setcity}
                  states={states}
                  setstates={setstates}
                  cities={cities}
                  setcities={setcities}
                />

                {/* <select
              className="auth_input"
              name="grossValue"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option className="color_black" value="">
                Country
              </option>
              {country.map((option, i) => (
                <option className="color_black" key={i} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select> */}
                <textarea
                  id="outlined-basic"
                  placeholder="Message"
                  type="email"
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className="auth_input col-12"
                  value={message}
                />

                <div className="col-12">
                  <OTPService
                    className="auth_input"
                    phone={phone}
                    setPhone={setPhone}
                    setphoneVerified={setphoneVerified}
                  />
                </div>

                <br />

                <div className="loginbtns mt-3" style={{ width: "100%" }}>
                  <button
                    className="auth_CTA gradient_CTA w_49"
                    onClick={() => navigate("/role?state=signup")}
                  >
                    BACK
                  </button>
                  <button className="auth_CTA gradient_CTA w_49" type="submit">
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 col-md-2"></div>
        </div>
      </div>
      <BackgroundImg />
    </>
  );
}
