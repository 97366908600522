import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import "./index.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../Utils/Auth";
// import logo from "./../../../Assets/images/events/logo.png";
import calendar from "./../../../Assets/images/events/calendar.png";
import clock from "./../../../Assets/images/events/clock.png";
import amount from "./../../../Assets/images/events/amount.png";
// import Avatar from '@mui/material/Avatar';
// import AvatarGroup from '@mui/material/AvatarGroup';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import { loadStripe } from "@stripe/stripe-js";
import PlanModal from "./../../../Components/Modal/PlanUgrade";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import moment from "moment-timezone";
import { CopyToClipboard } from 'react-copy-to-clipboard';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const index = (props) => {
  const { id_event } = useParams();
  const { eventId } = props;
  const key = id_event ? id_event : eventId;
  const [event, setevent] = useState(null);
  const { setSessionExpire, user } = useAuth();
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [openP, setPOpen] = useState(false);
  const handlePOpen = () => setPOpen(true);
  const handlePClose = () => setPOpen(false);
  const [disable, setDisable] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useNavigate();
  const [isRegistered, setisRegistered] = useState(false);

  const fetchEvent = async () => {
    await axios({
      method: "get",
      url: `/api/events/key/${key}`,
    })
      .then((res) => {
        setevent(res.data);
        setisRegistered(res.data.isRegistered);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        if (error.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const handleStart = (key) => {
    window.open(
      `/jitsi-event/${key}?title=${event.event.title}&host=${event.event.hostCompany}`
    );
  };

  const get_Date = (date) => {
    let d = new Date(date);
    return d.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  const get_time = (date, time, timezone) => {
    let datetime = date + "T" + time;
    var t = new Date(datetime).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return t + " " + timezone.split(" ", 3)[0];
  };

  const handleFreeRegister = async (id) => {
    if (user.plan === "Free") {
      handlePOpen();
      return;
    }
    await axios({
      url: `/api/events/participate/${id}`,
      method: "post",
    })
      .then((res) => {
        if (res.status === 201) {
          handleClose();
          toast.success("Registered", { position: "bottom-right" });
          fetchEvent();
        }
      })
      .catch(() => {
        toast.error("Could not register, try later", {
          position: "bottom-right",
        });
      });
  };

  const handleRegister = async (id) => {
    if (user.plan === "Free") {
      handlePOpen();
      return;
    }
    setDisable(true);
    if (stripe) {
      await axios({
        url: `/api/payment/event-checkout/${id}`,
        method: "post",
      })
        .then((res) => {
          if (res.status === 200) {
            stripe.redirectToCheckout({ sessionId: res.data.id });
            setLoader(false);
            handleClose();
          }
        })
        .catch(() => {
          toast.error("Could not register, try later", {
            position: "bottom-right",
          });
          setDisable(false);
        });
    }
  };

  const handleJoin = (key) => {
    if (event.diff === "Live")
      window.open(
        `/jitsi-event/${key}?title=${event.event.title}&host=${event.event.hostCompany}`
      );
    else {
      toast.error(event.diff, { position: "bottom-right" });
    }
  };

  useEffect(() => {
    async function fetchConfig() {
      const stripepromise = await loadStripe(
        "pk_live_51JqTdkGcUuatUK7QHwIvQjp4PpJ4P7lYX1wutnVX2GysCqBWQd7m7nHTACDrPS9YkJHqN9OtL0Bcl90I1mWxX3x800Z9bUj5KC"
      );
      setStripe(stripepromise);
    }
    fetchConfig();
  }, []);

  useEffect(() => {
    fetchEvent();
  }, [key]);

  if (event === null || event.event===null) return <p>Loading...</p>;
  // return (
  //   <>
  //     <h1>{event.event.title}</h1>
  //     <p>{event.event.description}</p>
  //   </>
  // );
  else
    return (
      <>
        <div className="container ">
          <PlanModal open={openP} handleClose={handlePClose} />
          <div>
            <div
              className="back_CTA pointer mt-2 mb-3"
              onClick={() => {
                history(`/dashboard/${user.type}?tab=6`);
              }}
            >
              <p className="caption color_light_grey">
                <span>
                  <KeyboardArrowLeft />
                </span>
                <span>BACK</span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="event-page-box p-5">
                <div className="event-box-pill">
                  {event.event.keywords.map((obj, ind) => {
                    return (
                      <span
                        key={ind}
                        style={{ marginRight: "5px" }}
                        className="badge badge-pill badge-light"
                      >
                        {obj}
                      </span>
                    );
                  })}
                  {/* <span className="badge badge-pill badge-light">Cryptocurrency</span> */}
                </div>
                <h4 className="event-box-head mt-3 mb-3">
                  {event.event.title}
                </h4>
                <div className="event-box-organise">Organised by</div>
                <div className="event-box-image mt-3">
                  <img
                    src={event.event.hostId && event.event.hostId.avatar ? event.event.hostId.avatar:null}
                    alt="logo"
                    className="mr-3"
                  />
                  {event.event.hostCompany}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="event-banner">
                <img src={event.event.thumbnail} alt="banner" />
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-5">
            <div className="col-12 col-md-4 mt-5">
              <div className="event-page-detail p-5">
                <div className="event-page-detail-section">
                  <img src={calendar} alt="calendar" />
                  <span>{moment.utc(`${event.event.eventTime.date}`).format('DD/MM/YYYY')}</span>
                </div>
                <div className="event-page-detail-section">
                  <img src={clock} alt="clock" />
                  <span>
                    {get_time(
                      event.event.eventTime.date,
                      event.event.eventTime.time,
                      event.event.eventTime.timezone
                    )}
                  </span>
                </div>
                <div className="event-page-detail-section">
                  <img src={amount} alt="amount" />
                  <span>
                    {event.event.isFree
                      ? "Free"
                      : "EUR " + event.event.eventFees}
                  </span>
                </div>
                <div className="event-page-detail-section">
                  <img src={clock} alt="clock" />
                  <span>{event.event.eventTime.duration}</span>
                  {/* <span>{event.diff}</span> */}
                </div>

                <div className="mt-3 d-flex justify-content-center">
                  {user && event.event.hostId && user.id === event.event.hostId._id ? (
                    <div className="d-flex justify-contnetn-center">
                      <div
                      className="mx-1"
                      onClick={() => handleStart(event.event.key)}
                      style={{cursor:'pointer'}}
                    >
                      <div className="primaryFilled_activeCta">START</div>
                    </div>
                    <CopyToClipboard text={`https://incubation.zefyron.com/twf-event/${event?.event._id}/${key}/${event?.event.title.replace(/\s/g, "")}`}
                      onCopy={() => toast.success('Link copied to clipboard')}>
                       <div
                      className="mx-1" style={{cursor:'pointer'}}>
                      <div className="primaryOutlined_activeCta ">SHARE</div>
                    </div>
                    </CopyToClipboard>
                    </div>
                  ) : (
                    <>
                      {event.isExpired ? (
                        <div
                          className="gradient_btn_main"
                          aria-disabled="true"
                        >
                          <div className="gradient_btn_light">EXPIRED</div>
                        </div>
                      ) : isRegistered ? (
                        <>
                          <div
                            className="gradient_btn_main"
                            onClick={() => handleJoin(event.key)}
                          >
                            <div className="gradient_btn_light">{event.diff === "Live" ? "JOIN" : event.diff}</div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="gradient_CTA"
                          onClick={() => handleOpen()}
                        >
                          REGISTER NOW
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 w-100 mt-5">
              <div className="event-main p-3">
                <p className="event-main-head">About event</p>
                <div className="event-main-line"></div>
                <p className="event-main-text">{event.event.description}</p>
                <p className="event-main-sidehead">Objectives</p>
                <div className="ml-4">
                  <ul>
                    {event.event.objectives.map((obj, ind) => {
                      return (
                        <li key={ind} className="mb-3">
                          {obj}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className="event-main-sidehead">Criteria</p>
                <div>
                  <ol>
                    {event.event.criteria.map((obj, ind) => {
                      return (
                        <li key={ind} className="mb-3">
                          {obj}
                        </li>
                      );
                    })}
                  </ol>
                </div>
                {/* <p className="event-main-sidehead">Documents</p> */}
                <div>
                  {/* <ol>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                      <li className="mb-3">Accomplish 10X Performance Results</li>
                    </ol>
                    */}
                </div>
                {/*  <p className="event-main-sidehead">Participants</p>
                   <AvatarGroup max={4} style={{justifyContent: "flex-end"}}>
                    
                      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                      <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                      <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                      <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                      <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                    </AvatarGroup>  */}
              </div>
            </div>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 300,
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <div className="close-modal-main" style={{position: "absolute",right:"0"}}>
                  <button onClick={handleClose}>
                    <CancelIcon />
                  </button>
                </div>
                <div className="modal-main-text">
                  <div className="modalEvent-title">
                    <h6>EVENT TITLE: {event.title}</h6>
                  </div>
                  <div className="modalEvent-date-details">
                    <div>
                      <BusinessCenterIcon />{" "}
                      <p>{get_Date(event.event.eventTime.date)}</p>
                    </div>
                    <div>
                      <WatchLaterIcon />{" "}
                      <p>
                        {get_time(
                          event.event.eventTime.date,
                          event.event.eventTime.time,
                          event.event.eventTime.timezone
                        )}
                      </p>
                    </div>

                    <div>
                      <HourglassFullIcon />
                      <p>{event.diff}</p>
                    </div>
                  </div>
                  <div className="modalEvent-paybleAmount">
                    <p>Total Amount Payable</p>
                    <h5>
                      {" "}
                      {event.event.isFree
                        ? "Free"
                        : "EUR " + event.event.eventFees}{" "}
                    </h5>
                  </div>
                  {loader ? <></> : null}
                  <div className="modalPay-options-main">
                    <div className="modalpay-btn-main">
                      {event.event.isFree ? (
                        <button
                          className="gradient_CTA"
                          onClick={() => handleFreeRegister(event.event._id)}
                        >
                          REGISTER
                        </button>
                      ) : (
                        <>
                          {loader ? (
                            <></>
                          ) : (
                            <button
                              className="gradient_CTA"
                              disabled={disable}
                              onClick={() => handleRegister(event.event._id)}
                            >
                              PAY
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      </>
    );
};

export default index;
