import React, { useState, useEffect, useRef } from "react";
// import menu from "../../../../../Assets/Images/icons/menu.png";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../../Utils/Auth";
import "./index.css";
import attachbtn from "../../../Assets/images/paperclip (1) 1.png";
import sendbtn from "../../../Assets/images/sendbtn.png";
import ScrollableFeed from "react-scrollable-feed";
import Axios from "axios";

import io from "socket.io-client";
import { useLocation } from "react-router-dom";
// import Loader from "../../../../../components/Loader";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { nanoid } from "nanoid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import VideocamIcon from "@mui/icons-material/Videocam";
import PhotoIcon from "@mui/icons-material/Photo";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "5px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Messages() {
  const socket = useRef();
  const scrollRef = useRef();
  const { user, setSessionExpire } = useAuth();
  const [selectedChat, setSelectedChat] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [openChatBox, setOpenChatBox] = useState(false);
  const [selectedChatId, setselectedChatId] = useState(null);
  const [currentReceiver, setCurrentReceiver] = useState(null);
  const [receiverId, setreceiverId] = useState(null);
  const [messageText, setmessageText] = useState("");
  const { programid } = useParams();
  const [sendingAttach, setsendingAttach] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [membertype, setmembertype] = useState("startup");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAttachClose = () => {
    setAnchorEl(null);
  };

  const openAttach = Boolean(anchorEl);
  const id = openAttach ? "simple-popover" : undefined;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setMeet_id(nanoid());
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addNewMessage = (newMessage) => {
    if (sessionStorage.getItem("receiverId") === newMessage.from) {
      setSelectedChat((prev) => [...prev, newMessage]);
    }
  };

  const [meet_id, setMeet_id] = useState("");

  const handleChatSelect = async (id, receiver) => {
    // console.log(id)
    // console.log(receiver)
    setCurrentReceiver(receiver);
    setreceiverId(id);
    setOpenChatBox(true);
    await Axios({
      method: "get",
      url: `/api/incchat/chats/${id}/${programid}`,
    })
      .then((res) => {
        setSelectedChat(res.data.data);
        setselectedChatId(res.data.chatid);
        // sessionStorage.setItem("receiverId", receiver.id);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    socket.current = io("/");

    socket.current.on("getMessage", (data) => {
      const newMessage = {
        profilePhoto: data.avatar,
        from: data.senderId,
        message: data.text,
      };
      addNewMessage(newMessage);
    });
  }, []);
  useEffect(() => {
    user && socket.current.emit("addUser", user.id);
    socket.current.on("getUsers", () => {});
  }, [user]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behaviour: "smooth",
    });
  }, [selectedChat]);
  let searchQuery = useQuery();
  let isFounderChat = searchQuery.get("founderChat");
  useEffect(() => {
    if (isFounderChat === "true") console.log(true);
  }, [isFounderChat]);

  const getstartups = () => {
    Axios({
      method: "get",
      url: `/api/incchat/startups/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getmentors = () => {
    Axios({
      method: "get",
      url: `/api/incchat/mentors/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getinvestors = () => {
    Axios({
      method: "get",
      url: `/api/incchat/investors/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getincubator = () => {
    Axios({
      method: "get",
      url: `/api/incchat/incubator/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getsp = () => {
    Axios({
      method: "get",
      url: `/api/incchat/sp/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getstudents = () => {
    Axios({
      method: "get",
      url: `/api/incchat/student/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const getPreStartup = () => {
    Axios({
      method: "get",
      url: `/api/incchat/prestartup/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getstartups();
  }, []);

  const getIndividual = () => {
    Axios({
      method: "get",
      url: `/api/incchat/individual/${programid}`,
    })
      .then((res) => {
        setRecentChats(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    getstartups();
  }, []);

  const sendAttachment = (e) => {
    const file = e.target.files[0];

    let type;
    if (file) {
      const fileInfo = file.name.split(".");
      type = fileInfo[1];
    }
    if (
      file &&
      type != "ppt" &&
      type != "pptx" &&
      type != "pdf" &&
      type != "docx" &&
      type != "doc" &&
      type != "xls" &&
      type != "xlsx" &&
      type != "html" &&
      type != "txt" &&
      type != "jpg" &&
      type != "jpeg" &&
      type != "png" &&
      type != "mp4"
    )
      return toast.error("File format not supported");
    let attachmentType;
    if (type === "jpeg" || type === "jpg" || type === "png")
      attachmentType = "image";
    else if (type === "mp4") attachmentType = "video";
    else attachmentType = "attachment";

    const formdata = new FormData();
    formdata.append("chat-attachment", file);
    handleAttachClose();
    setsendingAttach(true);
    Axios({
      method: "post",
      url: `/api/incchat/attach/${selectedChatId}?type=${attachmentType}`,
      data: formdata,
    })
      .then((res) => {
        if (res) {
          handleChatSelect(receiverId, currentReceiver);
          setsendingAttach(false);
        }
      })
      .catch(() => {
        toast.error("Error in upload");
        setsendingAttach(false);
      });
  };

  const deleteMessage = async (id) => {
    console.log("delete", id);
    await Axios.delete(`/api/chat/delete/${id}`)
      .then((res) => {
        if (res) handleChatSelect(selectedChatId, currentReceiver);
      })
      .catch(() => {
        toast.error("Something went wrong", { position: "bottom-right" });
      });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (
      messageText == null ||
      messageText === "" ||
      messageText === " " ||
      messageText === undefined
    ) {
      return;
    }

    socket.current.emit("sendMessage", {
      senderId: user.id,
      receiverId: currentReceiver._id,
      text: messageText,
      avatar: currentReceiver.avatar,
    });

    // setSelectedChat((prev) => [
    //   ...prev,
    //   {
    //     from: user.id,
    //     message: messageText,
    //     date: Date.now(),
    //     type: "text",
    //   },
    // ]);

    await Axios({
      method: "post",
      url: `/api/incchat/chats`,
      data: {
        message: messageText,
        connectID: selectedChatId,
      },
    }).then((res) => {
      if (res.data.success) handleChatSelect(receiverId, currentReceiver);
      setmessageText("");
    });
  };
  // const [linkMessage] = useState("You started a video call");
  const sendVideoLink = async () => {
    socket.current.emit("sendMessage", {
      senderId: user.id,
      receiverId: currentReceiver._id,
      text: `https://incubation.zefyron.com/meet/${meet_id}`,
      avatar: currentReceiver.avatar,
      // profilePic: profile.profilePic,
    });
    setSelectedChat((prev) => [
      ...prev,
      {
        from: user.id,
        message: `https://incubation.zefyron.com/meet/${meet_id}`,
        date: Date.now(),
        type: "meet-link",
      },
    ]);

    await Axios({
      method: "post",
      url: `/api/chat`,
      data: {
        message: `https://incubation.zefyron.com/meet/${meet_id}`,
        connectID: selectedChatId,
        type: "meet-link",
      },
    }).then(() => {
      setmessageText("");
      handleClose();
    });
  };

  function MessageList(recentChats) {
    return (
      <div className="dashboard01_block mt-0 mh-80 messages_recent_block">
        <div className="row no-margin align-items-center">
          <div className="col-10 no-padding">
            <h5 className="p_spartan_20 mt-2">Messages</h5>
          </div>
          <div className="col-2" style={{ textAlign: "right" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill=""
              className="bi bi-plus-circle-fill messages_recent_block_add_svg"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
            </svg>
          </div>
        </div>
        <hr style={{ margin: "1rem -10px" }} />
        <div className="row no-margin align-items-center">
          <div className="col-10 no-padding">
            <input
              type="text"
              className="bold"
              placeholder="Search"
              style={{ border: "none", padding: "10px 5px" }}
            />
          </div>
          <div className="col-2" style={{ textAlign: "right" }}>
            <SearchIcon className="p_button" />
          </div>
        </div>

        <select
          className="auth_input auth_select"
          name="program_type"
          value={membertype}
          onChange={(e) => {
            setmembertype(e.target.value);
            if (e.target.value === "mentor") {
              getmentors();
            }
            if (e.target.value === "startup") {
              getstartups();
            }
            if (e.target.value === "investor") {
              getinvestors();
            }
            if (e.target.value === "incubator") {
              getincubator();
            }
            if (e.target.value === "serviceprovider") {
              getsp();
            }
            if (e.target.value === "student") {
              getstudents();
            }
            if(e.target.value === "prestartup"){
              getPreStartup()
            }
            if(e.target.value === "individual"){
              getIndividual()
            }
          }}
        >
          <option value="startup">Startups</option>
          <option value="mentor">Mentors</option>
          <option value="investor">Investors</option>
          <option value="serviceprovider">Service Providers</option>
          <option value="student">Students</option>
          {user.type !== "incubator" ? (
            <option value="incubator">Incubator</option>
          ) : null}
          <option value="prestartup">Pre Startup</option>
          <option value="individual">Individual</option>
        </select>
        <div>
          {recentChats.length > 0 ? (
            <>
              {recentChats.map((item, i) => (
                <>
                  {membertype === "startup" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.user_id._id, item.user_id)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.user_id ? item.user_id.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.user_id ? item.user_id.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : membertype === "mentor" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.mentor._id, item.mentor)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.mentor ? item.mentor.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.mentor ? item.mentor.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : membertype === "investor" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.investor._id, item.investor)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.investor ? item.investor.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.investor ? item.investor.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : membertype === "incubator" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() => handleChatSelect(item.id._id, item.id)}
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.id ? item.id.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.id ? item.id.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : membertype === "serviceprovider" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.spid._id, item.spid)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.spid ? item.spid.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.spid ? item.spid.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : membertype === "student" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.studentid._id, item.studentid)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.studentid ? item.studentid.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.studentid ? item.studentid.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : membertype === "prestartup" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.preStartid._id, item.preStartid)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.preStartid ? item.preStartid.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.preStartid ? item.preStartid.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ):membertype === "individual" ? (
                    <>
                      {" "}
                      <div
                        className="row my-1 mx-0 align-items-center messages_recent_block_item"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() =>
                          handleChatSelect(item.individualid._id, item.individualid)
                        }
                      >
                        <div className="width_auto no-padding">
                          <Avatar
                            src={item.individualid ? item.individualid.avatar : null}
                            className="dashboard_block_item_img"
                            sx={{ width: 45, height: 45 }}
                          />
                        </div>
                        <div className="col">
                          <p className="p_small_black_bold no-margin no-padding">
                            {item.individualid ? item.individualid.name : null}
                          </p>
                        </div>
                      </div>
                    </>
                  ):null}
                </>
              ))}
            </>
          ) : (
            <>
              <div className="text-center">{/* <Loader /> */}</div>
            </>
          )}
        </div>
      </div>
    );
  }

  function ChatBox() {
    const getTime = (date) => {
      var d = new Date(date);
      var t = d.toLocaleTimeString([], { timeStyle: "short" });
      var dd = d.toLocaleDateString("en-US", { dateStyle: "short" });
      return dd + " " + t;
    };

    return (
      <div className="dashboard01_block mt-0">
        {currentReceiver ? (
          <>
            <div className="row no-margin align-items-center">
              <div className="width_auto">
                <Avatar
                  src={currentReceiver.avatar}
                  className="dashboard_block_item_img"
                  alt="avatar"
                />
              </div>
              <div className="col no-padding">
                <h5 className="p_spartan_20">{currentReceiver.name}</h5>
              </div>
              <div
                className="col-2 "
                style={{ textAlign: "right", display: "none" }}
              >
                <button
                  className="message_meet_CTA"
                  onClick={() => handleOpen()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill=""
                    className="bi bi-people-fill messages_recent_block_add_svg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fillRule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                  &nbsp; Meet
                </button>
              </div>
            </div>
            <hr style={{ margin: "0.5rem -10px" }} />
          </>
        ) : null}

        <div className="chat_message_box">
          {selectedChat.length > 0 ? (
            <ScrollableFeed>
              {selectedChat.map((message, i) => (
                <div
                  ref={scrollRef}
                  key={i}
                  className={
                    user.id == message.from
                      ? "chatmessage self_message d-flex align-items-center"
                      : "chatmessage other_message  d-flex align-items-center"
                  }
                >
                  <div
                    className={
                      user.id == message.from
                        ? "mdmsg d-flex align-items-center flex-row-reverse"
                        : "mdmsg  d-flex align-items-center"
                    }
                  >
                    <div>
                      <div className="mesage_text">
                        {message.type === "text" ? (
                          <>
                            <p className="no-margin">{message.message}</p>
                          </>
                        ) : message.type === "attachment" ? (
                          <>
                            <div
                              className={
                                "mdmsg d-flex align-items-center flex-row-reverse mdmsg  d-flex align-items-center"
                              }
                            >
                              <div className="message_doc">
                                <div className="message_doc-main">
                                  <div className="doc_download_main">
                                    <a
                                      href={`${message.filelink}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button>
                                        <FileDownloadIcon />
                                      </button>
                                    </a>
                                  </div>
                                  <div className="doc-name-type-main">
                                    <p className="docname">
                                      {message.filename}
                                    </p>
                                    <div className="time-type">
                                      <p className="doctype font10 ">
                                        {" "}
                                        {getTime(message.createdAt)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : message.type === "image" ? (
                          <>
                            <a
                              href={`${message.filelink}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={message.filelink}
                                className="image-fluid"
                                style={{
                                  maxHeight: "150px",
                                  maxWidth: "200px",
                                }}
                              />
                            </a>
                            <p className="p_small_black_10 chat_time">
                              {getTime(message.createdAt)}
                            </p>
                          </>
                        ) : message.type === "video" ? (
                          <>
                            <video width="360" height="240" controls>
                              <source src={message.filelink} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            <p className="p_small_black_10 chat_time">
                              {getTime(message.createdAt)}
                            </p>
                          </>
                        ) : message.type === "meet-link" ? (
                          <>
                            <p>Started a video call, click to join</p>
                            <p className="no-margin">
                              <a
                                href={`${message.message}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: "inherit" }}
                              >
                                JOIN
                              </a>
                            </p>
                            <p className="p_small_black_10 chat_time">
                              {getTime(message.createdAt)}
                            </p>
                          </>
                        ) : null}
                      </div>
                      <p className="chat_time">{getTime(message.createdAt)}</p>
                    </div>
                    {user.id === message.from ? (
                      <div className="deletemsg" type="button">
                        <button
                          onClick={() => {
                            deleteMessage(message._id);
                          }}
                        >
                          <CancelIcon />
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </ScrollableFeed>
          ) : (
            " "
          )}
        </div>

        <div>
          <form onSubmit={handleSendMessage}>
            <label className="message_send_label">
              <input
                placeholder="Enter text here..."
                type={"text"}
                className="chat_input"
                onChange={(e) => setmessageText(e.target.value)}
                value={messageText}
              />

              <div className="sendFileMsgMain">
                <button
                  className="message_btn attach_btn"
                  // onClick={() => {
                  //   showIconbar ? setShowIconBar(false) : setShowIconBar(true);
                  // }}
                  id="showIconbtn"
                >
                  {sendingAttach ? (
                    <CircularProgress size={30} />
                  ) : (
                    <>
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        className=""
                        onClick={handleClick}
                      >
                        <img
                          src={attachbtn}
                          alt="attach"
                          className="send_btn_img_atch"
                        />
                      </Button>
                      <Popover
                        id={id}
                        open={openAttach}
                        anchorEl={anchorEl}
                        onClose={handleAttachClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="message_attachment_popper">
                          <div title="Document" htmlFor="docDoc">
                            <label htmlFor="docDoc">
                              <SummarizeIcon />
                            </label>
                            <input
                              type="file"
                              accept="application/doc,
                        application/ms-doc,
                        application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                        application/pdf,
                        application/excel,
                        application/vnd.ms-excel,
                        application/x-excel,
                        application/x-msexcel,
                        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                        application/mspowerpoint,
                        application/powerpoint,
                        application/vnd.ms-powerpoint,
                        application/x-mspowerpoint,
                        application/vnd.openxmlformats-officedocument.presentationml.presentation"
                              id="docDoc"
                              name="docDoc"
                              onChange={(e) => sendAttachment(e)}
                              style={{ display: "none" }}
                            />
                          </div>
                          <div title="Video">
                            <label htmlFor="videoDoc">
                              <VideocamIcon />
                            </label>
                            <input
                              type="file"
                              accept="video/mp4"
                              id="videoDoc"
                              name="videoDoc"
                              onChange={(e) => sendAttachment(e)}
                              style={{ display: "none" }}
                            />
                          </div>
                          <div title="Image">
                            <label htmlFor="photodoc">
                              <PhotoIcon />
                            </label>
                            <input
                              type="file"
                              accept="image/*"
                              id="photodoc"
                              name="photodoc"
                              onChange={(e) => sendAttachment(e)}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </Popover>
                    </>
                  )}
                </button>
              </div>

              <button
                className="message_btn send_btn"
                onClick={(e) => {
                  handleSendMessage(e);
                }}
              >
                <img src={sendbtn} alt="send" className="send_btn_img" />
              </button>
            </label>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="messages_root">
      <div className="row no-margin">
        <div className="col-md-4 col-sm-12 no-padding">
          {MessageList(recentChats)}
        </div>
        <div className="col-md-8 col-sm-12 no-padding">
          {openChatBox ? ChatBox() : null}
        </div>
      </div>

      <Modal
        className="Modal_root"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center">
            <p className="p_small_white">Share meeting link</p>
            <p className="p_small_white p_button">
              <a
                style={{ color: "inherit" }}
                href={`https://incubation.zefyron.com/meet/${meet_id}`}
                target="_blank"
                rel="noreferrer"
              >{`https://incubation.zefyron.com/meet/${meet_id}`}</a>
              <>&nbsp;&nbsp;&nbsp;</>{" "}
              <span>
                {" "}
                <CopyToClipboard
                  text={`https://incubation.zefyron.com/meet/${meet_id}`}
                >
                  <ContentCopyIcon />
                </CopyToClipboard>
              </span>
            </p>
            <a
              style={{ color: "inherit", textDecoration: "none" }}
              href={`https://incubation.zefyron.com/meet/${meet_id}?host=true`}
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="gradient_CTA"
                onClick={() => sendVideoLink(meet_id)}
              >
                START VIDEO CALL
              </button>
            </a>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
