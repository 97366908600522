import React, { useState } from "react";
import { useAuth } from "../../../Utils/Auth";
import Axios from "axios";
import { toast } from "react-toastify";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

const index = ({ programid, handleClose, getmeets }) => {
  const [time, setTime] = useState(new Date());
  const { setSessionExpire } = useAuth();
  const [startup, setstartup] = useState(false);
  const [mentor, setmentor] = useState(false);
  const [title, setTitle] = useState("");

  const createmeet = async () => {
    if (title.length === 0) return toast.warning("Title is missing");
    await Axios({
      method: "post",
      url: `/api/incmeet/create/${programid}`,
      data: {
        schedule: time,
        title,
        startup,
        mentor,
      },
    })
      .then((res) => {
        if (res.data.success) toast.success("Meeting scheduled");
        getmeets();
        handleClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        } else {
          toast.error(err.response.data.error);
        }
        handleClose();
      });
  };

  return (
    <>
      <div className="col-12">
        <label>Title</label>
        <input
          placeholder="Enter Title"
          required
          type="text"
          className="auth_input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="auth_back_loginform col-12">
        <div className="h5" style={{ color: "#262C5B" }}>
          Schedule Meet
        </div>
        <p className="overline pb-1">Link will be shared with the schedule</p>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={time}
            onChange={(v) => setTime(v)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </div>
      <label> For </label>
      <div className="col-12">
        <label> Startup</label>
        <Checkbox
          checked={startup}
          onClick={(e) => setstartup(e.target.checked)}
        />
      </div>
      <div className="col-12">
        <label> Mentor</label>
        <Checkbox
          checked={mentor}
          onClick={(e) => setmentor(e.target.checked)}
        />
      </div>

      <button
        onClick={() => createmeet()}
        className="gradient_CTA width_fit mt-3 color-black"
        type="button"
      >
        SCHEDULE
      </button>
    </>
  );
};

export default index;
