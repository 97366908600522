import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "axios";
import Query from "../../../../../Utils/Query/Query";
import { Avatar } from "@mui/material";
import { List, ListItemButton, ListItemText, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import { toast } from "react-toastify";
import Loader from "../../../../../Components/Loader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const ViewEmployeeProfile = () => {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: "#E4E4E4",
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#E4E4E4"
            }
          },
          colorPrimary: {
            "&.Mui-checked": {
              // Controls checked color for the thumb
              color: "#E4E4E4"
            }
          },
          track: {
            // Controls default (unchecked) color for the track
            backgroundColor: "#1d1d1d",
            ".Mui-checked.Mui-checked + &": {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: "linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)"
            }
          }
        }
      }
    }
  });
  const [openList, setOpenList] = useState({});
  const [employee, setEmployee] = useState(null);
  const [permissions, setPermissions] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { setSessionExpire } = useAuth();
  const query = Query()
  const employeeId = query.get('employee')
  const getDetail = async () => {
    setIsLoading(true)
    await Axios({
      method: "get",
      url: `/api/employee/emp/${employeeId}`,
    })
      .then((res) => {
        setEmployee(res.data.data)
        setPermissions(res.data.data.id)
        window.scrollTo(0, 0);
        setIsLoading(false)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
          setIsLoading(false)
        }
      });
  };

  useEffect(() => {
    if (employeeId) {
      getDetail();
    }
  }, [employeeId]);

  const handleListOpen = (list) => {
    setOpenList((prevList) => ({ ...!prevList, [list]: !prevList[list] }));
  };

  const handleChangePermission = async (e) => {
    setPermissions((prevPermissions) => ({ ...prevPermissions, [e.target.name]: e.target.checked }))

    await Axios({
      method: "put",
      url: `/api/incubator/employee/access/${employeeId}`,
      data: {
        [e.target.name]: e.target.checked
      }
    })
      .then(() => {
        // console.log(res.data)+
        toast.success(`Permission successfully updated`)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
          toast.error(`Something went wrong`)
        }
      });
  }
  return (
    <>
      {
        isLoading ? <Loader /> : <ThemeProvider theme={theme}>
          {
            employee && <div>
              <div
                className="connection_profile_root"
                id="connection_profile_root">
                <div className="row mt-2 mb-24 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                  <div className="row no-margin align-items-center col-8">
                    <div className="width_auto no-padding">
                      <Avatar
                        src={employee?.profile_pic}
                        className=""
                        alt="Profile"
                        sx={{ width: 90, height: 90 }}
                      />
                    </div>
                    <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                      <p className="p_spartan_20">{employee?.name}</p>
                      <p className="bold">Employee | {employee?.location.country}</p>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-4 mx-2">
                        <p className="grey bold">Company description in about 50-100 words. Lorem ipsum is a sample text used for printing and designing. Its just to show how the text appears in the field. Company description in about 50-100 words. Lorem ipsum is a sample text used for printing and designing. Its just to show how the text appears in the field.AI technologyCryptoAI technology Crypto</p>
                    </div> */}
                <div className="row mx-2 my-3">
                  <p className="p_grey_chip">{employee?.department}</p>
                  <p className="p_grey_chip">{employee?.designation}</p>
                </div>
              </div>
              <div className="my-3">
                <p>Assign Permissions</p>
                <div>
                  <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <ListItemButton onClick={() => handleListOpen('Connections')}>
                      <ListItemText primary="Connections" />
                      {openList['Connections'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['Connections']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Connections</div>
                        <div>
                          <Switch 
                          value={permissions.connections} 
                          checked={permissions.connections} 
                          color="warning" name="connections"
                           onChange={handleChangePermission} />
                        </div>
                      </div>

                    </Collapse>

                    <ListItemButton onClick={() => handleListOpen('Valuation')}>
                      <ListItemText primary="Valuation" />
                      {openList['Valuation'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['Valuation']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Valuation Tool</div>
                        <div><Switch value={permissions.valuation_tool} checked={permissions.valuation_tool} color="warning" name="valuation_tool" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>

                    <ListItemButton onClick={() => handleListOpen('Mentorship')}>
                      <ListItemText primary="Mentorship" />
                      {openList['Mentorship'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['Mentorship']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Mentorship Tool</div>
                        <div><Switch value={permissions.mentorship_tool} checked={permissions.mentorship_tool} color="warning" name="mentorship_tool" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>

                    <ListItemButton onClick={() => handleListOpen('Events')}>
                      <ListItemText primary="Events" />
                      {openList['Events'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['Events']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Events</div>
                        <div><Switch value={permissions.events_and_network} checked={permissions.events_and_network} color="warning" name="events_and_network" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>

                    <ListItemButton onClick={() => handleListOpen('Incubation')}>
                      <ListItemText primary="Incubation" />
                      {openList['Incubation'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['Incubation']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Incubation Tools</div>
                        <div><Switch value={permissions.incubation_tool} checked={permissions.incubation_tool} color="warning" name="incubation_tool" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>

                    <ListItemButton onClick={() => handleListOpen('Co-working')}>
                      <ListItemText primary="Co-working" />
                      {openList['Co-working'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['Co-working']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Co-working</div>
                        <div><Switch value={permissions.co_working} checked={permissions.co_working} color="warning" name="co_working" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>
                    <ListItemButton onClick={() => handleListOpen('finance')}>
                      <ListItemText primary="Finance" />
                      {openList['finance'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['finance']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Finance</div>
                        <div><Switch value={permissions.finance} checked={permissions.finance} color="warning" name="finance" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>
                    <ListItemButton onClick={() => handleListOpen('membership')}>
                      <ListItemText primary="Membership" />
                      {openList['membership'] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openList['membership']} timeout="auto" unmountOnExit style={{ background: "#F8F9FA" }}>

                      <div className='rounded shadow-sm d-flex align-items-center justify-content-between py-2 px-3 m-3 bg-white'>
                        <div>Membership</div>
                        <div><Switch value={permissions.membership} checked={permissions.membership} color="warning" name="membership" onChange={handleChangePermission} /></div>
                      </div>

                    </Collapse>
                  </List>

                </div>
              </div>
            </div>
          }
        </ThemeProvider>
      }
    </>
  );
}

export default ViewEmployeeProfile