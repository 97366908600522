import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import OTPService from "../../../../Utils/OTP";
import Tooltip from "@mui/material/Tooltip";

const Step3 = (props) => {
  // const { user } = useAuth();
  const {
    handleIDUpload,
    photoId,
    setPhotoID,
    idType,
    setIDType,
    isdocuploading,
    phone,
    setPhone,
    setphoneVerified,
  } = props;



  return (
    <form>
      <div className="col-12 p-0">
          <label>Select a Photo ID*</label>
          <select
            className="auth_input auth_select"
            name="photo_id"
            value={idType}
            onChange={(e) => setIDType(e.target.value)}
          >
            <option value="">Select Photo ID you want to upload</option>
            <option value="Driving Licence">Driving license</option>
            <option value="Voter ID">Voter ID Card</option>
            <option value="Passport">Passport</option>
            <option value="Others">Others</option>
          </select>
        </div>

        {idType.length > 1 && !photoId ? (
          <>
            {isdocuploading ? (
              <CircularProgress style={{ color: "black" }} />
            ) : (
              <>
                <input
                  type="file"
                  className="auth_file_choose col-12"
                  accept="application/pdf, image/*"
                  name="main_doc"
                  id="documents"
                  style={{ display: "none" }}
                  onChange={(e)=>handleIDUpload(e)}
                />
                <label htmlFor="documents" style={{cursor:"pointer"}}>
                  <Tooltip title="Click here to add document" placement="left">
                    <div className="d-flex pl-3 align-items-center ">
                      <p className="body1" style={{color:"#636363"}}>
                        <strong>Attach</strong>
                      </p>
                      
                      <svg className=" ml-16 " width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B"/>
                      </svg>
                    </div>
                  </Tooltip>
                </label>
              </>
            )}
          </>
        ) : (
          ""
        )}
        {photoId ? (
          <>
            <div className="col-12 mt-16">
              <p className="body1" style={{color:"#636363"}}>
                
                <svg className="mr-16" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.2539 10.4735H5.67393C3.58393 10.4735 1.72393 8.78671 1.52393 6.49356C1.29393 3.86968 3.16393 1.65371 5.50393 1.65371H17.8639C19.1739 1.65371 20.3639 2.69003 20.4939 4.12325C20.6439 5.77696 19.4739 7.16607 18.0039 7.16607H7.50393C6.95393 7.16607 6.50393 6.66996 6.50393 6.0636C6.50393 5.45724 6.95393 4.96113 7.50393 4.96113H16.2539C16.6639 4.96113 17.0039 4.58629 17.0039 4.13427C17.0039 3.68226 16.6639 3.30742 16.2539 3.30742H7.64393C6.33393 3.30742 5.14393 4.34374 5.01393 5.77696C4.86393 7.43066 6.03393 8.81978 7.50393 8.81978H17.8339C19.9239 8.81978 21.7839 7.133 21.9839 4.83985C22.2139 2.20494 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.31519 0.0239288 5.46826C-0.276071 9.0954 2.28393 12.1272 5.50393 12.1272H16.2539C16.6639 12.1272 17.0039 11.7524 17.0039 11.3003C17.0039 10.8483 16.6639 10.4735 16.2539 10.4735Z" fill="#6D7D8B"/>
                </svg>

                {photoId.docType} 
                <span
                className="overline ml-16"
                  style={{ cursor: "pointer",color: "#F53939" }}
                  onClick={() => {
                    setIDType(" ");
                    setPhotoID(null);
                  }}
                >
                  Delete
                </span>
              </p>
              &nbsp;
            </div>
          </>
        ) : null}

    
    <div className="register_page_form row px-3 py-0">
      <label>Phone Number*</label>
      <OTPService
        phone={phone}
        setPhone={setPhone}
        setphoneVerified={setphoneVerified}
        className="col-12"
      />
    </div>
    </form>
  );
};

export default Step3;
