import React, { useState } from 'react'
import Card from './Card'
import './received.css'

const Received = () => {
    const [received, setReceived] = useState([])
    return (
        <div className='invoicing_received_root'>
            <div className="incubator_assessment_tableRoot">
                <table className="incubator_assessment_table">
                    <thead className="incubator_assessment_thead">
                        <tr className="incubator_assessment_thead_tr">
                            <td className="incubator_assessment_thead_td incubator_assessment_td_sub_by">
                                <p className="body2 color_dark_grey2">Recieved from</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_title">
                                <p className="body2 color_dark_grey2">Tools</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_courseName">
                                <p className="body2 color_dark_grey2">Date</p>
                            </td>
                            <td className="incubator_assessment_thead_td incubator_assessment_td_sub_on">
                                <p className="body2 color_dark_grey2">Amount</p>
                            </td>
                        </tr>
                    </thead>
                    <tbody className="incubator_assessment_tbody">
                        {
                            received && received.length > 0 ?
                                received.map((data, i) => <Card
                                    key={i}
                                    data={data}
                                    setReceived={setReceived}
                                />) : null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Received