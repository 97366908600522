import { Avatar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
// import moment from "moment-timezone";
import { useAuth } from "../../../Utils/Auth";
import AvatarGroup from '@mui/material/AvatarGroup';

export default function Events({seteventState}) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const get_Date = (date) => {
    let d = new Date(date);
    return d.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year:'numeric'
    });
  };


  const [events, setevents] = useState([]);
  const { setSessionExpire } = useAuth();
  const getEvents = async () => {
    await axios({
      url: "/api/events/get/upcomming",
      method: "get",
    })
      .then((res) => {
        setevents(res.data.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div className="Dashboard01_events dashboard01_block mt-0">
      <div className="row justify-content-between mx-1 my-1">
        <h5 className=" subtitle w_fit">Events</h5>
        <Link to={`/dashboard/${user.type}?tab=6`}>
          <p className="w_fit bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg></p>
        </Link>
      </div>
        {events && events.length > 0 ? (
          <>
          <div className="row_align">
            {events.slice(0,2).map((event, i) => (
              <div
                key={i}
                className={``}
              >
                <div className="events01_block_list flex-column" onClick={()=>seteventState(1)}> 
                {/* <Link
                              to={`/dashboard/${user.type}?tab=6&event={event.key}`}
                            > */}
                  <div className="row pb-1">
                    <div className="col-2">
                      <Avatar src={event.hostId.avatar} alt="Feed" variant="rounded" style={{background: "rgba(0,0,0,0.1)"}} />
                    </div>
                  <div className="col-10">
                      <p className="body2">{event.title}</p>
                      <p className="overline py-0">
                      {get_Date(event.eventTime.date)}
                      </p>
                    </div>
                  </div>
                <div className="row mt-1">
                  <div className="col-10">
                    {/* {event.description.substring(0,50)+ "..."} */}
                    <p className="overline">{event.participants?event.participants.length:0} Members are attending</p>
                    <AvatarGroup className="w_fit" max={4}>
                      {event.participants?event.participants.map((part,i)=>
                      <Avatar key={i} sx={{width:24,height:24}} alt={part.name} src={part.avatar} />
                      ):null}
                    </AvatarGroup>
                  </div>
                <div className="col-2 d-flex align-items-center">
                <div onClick={()=>{seteventState(1); navigate(`/dashboard/${user.type}?tab=6&event=${event.key}`)}} style={{cursor:"pointer"}} >
                  <p className="w_fit bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg></p>
                  </div>
                </div>
                </div>
                </div>
              </div>
             ))} 
            </div>
          </>
        ) : ( 
          <div className="events01_block_list mt-2">
            <Link to={`/dashboard/${user.type}?tab=6`}>
              <p className="text-center mx-auto"
              onClick={()=>seteventState(3)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus mb-1" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                Create New Event
              </p>
                </Link>
      </div>
         )} 
                {/* <hr/>
      <div className=" mt-2">
      <Link to={`/dashboard/${user.type}?tab=6`}>
            <p className="text-center mx-auto"
             onClick={()=>seteventState(1)}
            >
                See all events</p>
                </Link>
      </div> */}
    </div>
  );
}