import React, { useState, useEffect } from 'react';
// import chart from '../../../Assets/images/lead-generation.svg';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import Axios from "axios";
import { toast } from 'react-toastify';
import { useAuth } from '../../../Utils/Auth'
// import {format,parseISO} from 'date-fns'
import { KeyboardBackspace } from "@mui/icons-material"
import { useNavigate } from 'react-router-dom';
import CategoryCard from './CategoryCard';
import Typography from '@mui/material/Typography';

// import ProgramImg from '../../../Assets/images/leads/programs.svg';
// import EventImg from '../../../Assets/images/leads/events.svg';
// import FundImg from '../../../Assets/images/leads/funds.svg';
// import CounselingImg from '../../../Assets/images/leads/counseling.svg';
// import AssetImg from '../../../Assets/images/leads/assets.svg';
import Query from '../../../Utils/Query/Query'
import './category.css';
// import chart from '../../../Assets/images/lead-generation.svg';
// import LinearProgress,{linearProgressClasses } from '@mui/material/LinearProgress';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
// import { styled } from '@mui/material/styles';
import PieChart from './PieChart';
// const BorderLinearProgress = styled(CircularProgress)(({ theme }) => ({
//      height: 20,
//      borderRadius: 8,

//      [`&.${circularProgressClasses.colorPrimary}`]: {
//        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800],
//      },
//      [`& .${circularProgressClasses.bar}`]: {
//        borderRadius: 8,
//        background: theme.palette.mode === 'light' ? 'linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)' : 'linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%)',
//      },
//    }));

const style = {
     position: "absolute",
     top: "50%",
     left: "50%",
     transform: "translate(-50%, -50%)",
     width: "40%",
     bgcolor: "background.paper",
     boxShadow: 24,
     p: 4,
     outline: 'none'
};
const LeadCategory = ({ setGenerationStep, target,tab }) => {
     const query = Query();
     const targetId = query.get('target')
     const navigate = useNavigate()
     const { user } = useAuth()
     const [modalopen, setModalOpen] = useState(false);
     // const handleOpen = () => setModalOpen(true);
     const handleClose = () => setModalOpen(false);
     // console.log(handleOpen)
     const [targetName, setTargetName] = useState('');
     const [startDate, setStartDate] = useState('');
     const [endDate, setEndDate] = useState('');
     const [amount, setAmount] = useState(0);
     const [categories, setCategories] = useState([]);
     const [, setIsLoading] = useState(false)
     const [totalAchievedAmount, setTotalAchievedAmount] = useState(0)
     const [categoriesAnalytics, setCategoriesAnalytics] = useState([])


     const fetchCategories = async () => {
          setIsLoading(true)
          const { data } = await Axios({
               method: "get",
               url: `/api/leadGeneration/leadCategory/${targetId}`,
          })
          if (data.success) {
               setCategories(data.data)
               let achievedAmount = 0
               const categoriesPei = []
               data.data.map(category => {
                    console.log(category)
                    const analytics = {
                         name: category.name,
                         value: category?.category_amount ? category?.category_amount : 0
                    }
                    if(category?.category_amount  > 0){
                         achievedAmount = achievedAmount + category?.category_amount
                    }
                    
                    categoriesPei.push(analytics)
               })
               setCategoriesAnalytics(categoriesPei)
               setTotalAchievedAmount(achievedAmount)
               setIsLoading(false)
          } else {
               setIsLoading(false)
               toast.error('Error while fetching targets')
          }
     }

     const handleTargetCreate = async () => {
          if (targetName.length <= 0 || startDate.length <= 0 || endDate.length <= 0 || amount <= 0) return toast.warning('Some fields are missings')

          const { data } = await Axios({
               method: "post",
               url: `/api/leadGeneration/create/leadTarget`,
               data: {
                    name: targetName,
                    startDate: startDate,
                    endDate: endDate,
                    amount: amount,
                    user_id: user.id
               }
          })
          if (data.success) {
               handleClose();
               toast.success('Target created');
               setTargetName('')
               setStartDate('')
               setEndDate('')
               setAmount('')
          } else {
               toast.success('Something went wrong');
          }
     }

     useEffect(() => {
          fetchCategories()
     }, [])

     return <div className="row">
          <div className="viewReq_head col-12">
               <button className='transparent_cta btncta' onClick={() => {
                    setGenerationStep(0)
                    navigate(-1)
               }}><KeyboardBackspace /> Back</button>
          </div>

          <div className="target_card px-4 py-2 w-100 mx-1 d-flex align-items-center justify-content-between">
               <div>
                    {/* <img src={chart} alt="host_img" width="4%" height="4%" /> */}
                    <h5 className='text-uppercase'>{target.name}</h5>
                    <div className='d-flex align-items-center my-2'>
                         <p>Duration : </p>
                         <p className='mx-2 text-capitaliz'>{target.duration}</p>
                    </div>
               </div>

               <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center my-3 mx-1'>
                         <p>Target Amount : </p>
                         <h5 className='mx-2'>€ {target.amount} </h5>
                    </div>

                    <div className='d-flex align-items-center my-3 mr-4'>
                         <p>Achieved Amount : </p>
                         <h5 className='mx-2'>€ {totalAchievedAmount ? totalAchievedAmount : 0} </h5>
                    </div>
               </div>

               <div>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                         <CircularProgress
                              variant="determinate"
                              value={Math.round((totalAchievedAmount / target.amount) * 100)}
                              thickness={4}
                              size={100}
                              sx={{
                                   color: (theme) => (theme.palette.mode === 'light' ? 'rgb(255,92,0)' : 'rgb(255,92,0)'),
                                   [`& .${circularProgressClasses.circle}`]: {
                                        strokeLinecap: 'round',
                                   },
                              }}
                         />
                         <Box
                              sx={{
                                   top: 0,
                                   left: 0,
                                   bottom: 0,
                                   right: 0,
                                   position: 'absolute',
                                   display: 'flex',
                                   alignItems: 'center',
                                   justifyContent: 'center',
                              }}
                         >
                              {
                                   totalAchievedAmount>0 && <Typography variant="caption" component="div" color="text.secondary">
                                   {`${Math.round((totalAchievedAmount / target.amount) * 100)}%`}
                              </Typography>
                              }
                         </Box>
                    </Box>
               </div>
          </div>
          <div>
               <h5 className='p_black20 m-3'>Categories</h5>
               <div className='row mx-1 my-3'>
                    {
                         categories.map(category => <CategoryCard category={category} key={category._id} setGenerationStep={setGenerationStep} tab={tab} targetId={target._id} />
                         )
                    }

               </div>
          </div>
          <div className='col-12 '>
               {
                    totalAchievedAmount > 0 && <div className='col-6 target_card mb-4'>
                         {
                              <PieChart totalcount={totalAchievedAmount} data={categoriesAnalytics} />
                         }

                    </div>
               }

          </div>


          <Modal aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
               open={modalopen}
               onClose={handleClose}
               closeAfterTransition>

               <Box sx={style}>
                    <div className="close-modal-main">
                         <button onClick={handleClose}>
                              <CancelIcon />
                         </button>
                    </div>
                    <div className="modal-main-text">
                         <div className="incubator_create_program_form_root mt-3">
                              <form className="incubator_create_program_form">
                                   <div className="register_page_form row">
                                        <div className="col-12">
                                             <label className="auth_label p_secNaviBlue12 mt-0">
                                                  Target Name
                                             </label>
                                             <input
                                                  placeholder="Enter the target name"
                                                  type="text"
                                                  className="auth_input mt-0"
                                                  value={targetName}
                                                  onChange={(e) => setTargetName(e.target.value)}
                                             />
                                        </div>
                                        <div className="col-12 col-md-6 col-12 my-2">
                                             <label className="auth_label">Start Date</label>
                                             <input
                                                  type="text"
                                                  onFocus={(e) => {
                                                       e.target.type = "date";
                                                  }}
                                                  className="w_100 auth_input"
                                                  placeholder="Start date"
                                                  // value={programStartDate}
                                                  defaultValue={startDate}
                                                  onChange={(e) => setStartDate(e.target.value)}
                                             />
                                        </div>
                                        <div className="col-12 col-md-6 col-12 my-2">
                                             <label className="auth_label">End Date</label>
                                             <input
                                                  type="text"
                                                  onFocus={(e) => {
                                                       e.target.type = "date";
                                                  }}
                                                  className="w_100 auth_input"
                                                  placeholder="End end"
                                                  // value={programStartDate}
                                                  defaultValue={endDate}
                                                  onChange={(e) => setEndDate(e.target.value)}
                                             />
                                        </div>
                                        <div className="col-12 my-2">
                                             <label className="auth_label p_secNaviBlue12 mt-0">
                                                  Amount
                                             </label>
                                             <input
                                                  placeholder="Enter the target name"
                                                  type="text"
                                                  className="auth_input mt-0"
                                                  value={amount}
                                                  onChange={(e) => setAmount(e.target.value)}
                                             />
                                        </div>
                                   </div>
                                   <button
                                        type="button"
                                        className="primaryFilled_activeCta float-right my-3"
                                        style={{ padding: "10px 20px", margin: ".5rem" }}
                                        onClick={handleTargetCreate}
                                   >
                                        Create
                                   </button>
                              </form>
                         </div>
                    </div>
               </Box>

          </Modal>
     </div>
}

export default LeadCategory;