import React, { useState, useEffect,useRef } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "axios";
// import Avatar from "@mui/material/Avatar";
import "./index.css";
import { LinkedIn } from "@mui/icons-material";
import Feedback from "./FeedBack";
import { useParams } from "react-router-dom";
import { Rating } from "@mui/material";
import Pdf from "react-to-pdf"
import { ParticipantsDetails } from "../../../../../Components/SkeletonLoader/ParticipantsSkeleton";

import dateDiff from "../../../../../Utils/getDays/calcDate";


const Applicantcard = ({ setstep, startupid,setparticipantid }) => {
  const ref = useRef();
  const [startup, setstartup] = useState(null);
  const [ratingAvg, setRatingAvg] = useState(0);
  const [length, setLength] = useState(0);
  const [paticipantFeed, setParticipantFeed] = useState(null);
  const [documents, setDocuments] = useState([]);

  const { setSessionExpire } = useAuth();

  const { programid } = useParams();

  const getFeedback = async (userid) => {
    await Axios({
      method: "get",
      url: `/api/incubatorProgram/startup/feedback/${programid}/${userid}`,
    })
      .then((res) => {
        setParticipantFeed(res.data.data);
        const { data } = res.data;
        var accessibility = data.accessibility;
        var active_participation = data.active_participation;
        var overall_experience = data.overall_experience;
        var professionalism = data.professionalism;

        var accessibilitySum =
          accessibility.one * 1 +
          accessibility.two * 2 +
          accessibility.three * 3 +
          accessibility.four * 4 +
          accessibility.five * 5;
        var active_participationSum =
          active_participation.one * 1 +
          active_participation.two * 2 +
          active_participation.three * 3 +
          active_participation.four * 4 +
          active_participation.five * 5;
        var overall_experienceSum =
          overall_experience.one * 1 +
          overall_experience.two * 2 +
          overall_experience.three * 3 +
          overall_experience.four * 4 +
          overall_experience.five * 5;
        var professionalismSum =
          professionalism.one * 1 +
          professionalism.two * 2 +
          professionalism.three * 3 +
          professionalism.four * 4 +
          professionalism.five * 5;

        var accessibilityAvg =
          accessibilitySum /
          (accessibility.one +
            accessibility.two +
            accessibility.three +
            accessibility.four +
            accessibility.five);

        var active_participationAvg =
          active_participationSum /
          (active_participation.one +
            active_participation.two +
            active_participation.three +
            active_participation.four +
            active_participation.five);

        var overall_experienceAvg =
          overall_experienceSum /
          (overall_experience.one +
            overall_experience.two +
            overall_experience.three +
            overall_experience.four +
            overall_experience.five);

        var professionalismAvg =
          professionalismSum /
          (professionalism.one +
            professionalism.two +
            professionalism.three +
            professionalism.four +
            professionalism.five);
        setLength(
          professionalism.one +
          professionalism.two +
          professionalism.three +
          professionalism.four +
          professionalism.five
        );
        var avg =
          (accessibilityAvg +
            active_participationAvg +
            overall_experienceAvg +
            professionalismAvg) /
          4;
        setRatingAvg(avg.toFixed(1));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  const getDetail = async () => {
    await Axios({
      method: "get",
      url: `/api/startup/user-by-id/${startupid}`,
    })
      .then((res) => {
        setstartup(res.data.data);
        window.scrollTo(0, 0);
        getFeedback(res.data.data.id._id);
        getDocuments()
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };
  const getDocuments = async () => {
    await Axios({
      method: "get",
      url: `/api/incubatorProgram/incubator/documents/${programid}/${startupid}`,
    })
      .then((res) => {
        setDocuments(res.data.data.documents)
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };

  useEffect(() => {
    console.log(startupid)
    getDetail();
  }, []);

  const [open, setopen] = React.useState(false);
  const handleOpen = () => {
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  if (startup === null) return <ParticipantsDetails/>;

  return (
    <>
      <Feedback open={open} handleClose={handleClose} />
      <div className="col-12 participants_profile_head d-flex justify-content-between align-items-center">
        <span
          className="cta color_light_grey"
          onClick={() => {
            setparticipantid("")
            setstep(0);

          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-left mx-1"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
            />
          </svg>
          Back
        </span>
        <Pdf targetRef={ref} filename={`${startup.companyName}.pdf`} x={.5} y={0.5} scale={.7}>
                {({ toPdf }) => <button onClick={toPdf} className="primaryOutlined_activeCta" style={{fontSize:'.7rem'}}>Download Profile</button>}
        </Pdf>
      </div>
      <div className="col-12 row" ref={ref}>
        <div className="col-12 col-lg-8">
          <div
            className="partipant_profile_main_left"
            id="connection_profile_root"
          >
            <div className="partipant_profile_main_left_head">
              <div className="dashboard01_connection_profile_head_img">
                <img src={startup.logo} alt="logo" />
                <div className="ml-12">
                  <p className="subtitle color_dark_blue">
                    {startup.companyName}
                  </p>
                  <p className="caption color_dark_grey2">
                    Startup | {startup.location.country} | enrolled on {new Date(startup.updatedAt).toLocaleDateString('en-GB')} ({dateDiff(startup.updatedAt)})
                  </p>
                </div>
              </div>
              {ratingAvg > 0 ? (
                <div className="participant_profile_right_rating_root">
                  <div className="participant_profile_right_rating_main">
                    <h5 className="color_dark_black2">{ratingAvg}</h5>
                    <div className="incubator_aly_rating__">
                      <Rating
                        name="read-only"
                        value={ratingAvg}
                        readOnly
                        className="incubator_aly_rating_read"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="mt-40">
              <p className="body1">{new Date(startup.updatedAt).toLocaleDateString('en-GB')}</p>
              <p className="body2 grey">{dateDiff(startup.updatedAt)}</p>
            </div> */}
            <div className="mt-40">
              <p className="body2 grey">{startup.description}</p>
            </div>

            <div className="mt-40">
              <p className="incubation_chips caption">{startup.industry} </p>
            </div>
            {
              documents.length > 0 ? <>
                <hr />
                <div className="mt-40">
                  <p className="title">Documents</p>
                  <div className="d-flex align-items-center mt-16">
                    {
                      documents.map((doc, i) => <p className="incubation_chips caption" key={i}><a href={doc.doc_link} target="_blank" rel="noopener noreferrer">{doc.doc_name} <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-box-arrow-up-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.636 3.5a.5.5 0 00-.5-.5H1.5A1.5 1.5 0 000 4.5v10A1.5 1.5 0 001.5 16h10a1.5 1.5 0 001.5-1.5V7.864a.5.5 0 00-1 0V14.5a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5h6.636a.5.5 0 00.5-.5z"
                        ></path>
                        <path
                          fillRule="evenodd"
                          d="M16 .5a.5.5 0 00-.5-.5h-5a.5.5 0 000 1h3.793L6.146 9.146a.5.5 0 10.708.708L15 1.707V5.5a.5.5 0 001 0v-5z"
                        ></path>
                      </svg></a></p>)
                    }
                  </div>

                </div>
              </> : null
            }


            <hr />
            <div className="partipant_profile_main_review_main mt-40">
              <div className="partipant_profile_main_review_head mb-24">
                <p className="title">Ratings</p>
                <button
                  className="primaryFilled_activeCta"
                  onClick={handleOpen}
                >
                  REVIEW
                </button>
              </div>
              <div className="incubator_analytics_rating_progress w_100">
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Accessibility</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.accessibility_percent
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.accessibility_percent >=
                          87 ? (
                        <p className="overline color_dark_grey2 pr-16 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.accessibility_percent < 87 ? (
                        <p className="overline color_dark_grey2 ml-8">
                          {length} members
                        </p>
                      ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Active participation</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.active_participation_percent
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.active_participation_percent >=
                          87 ? (
                        <p className="overline color_dark_grey2 pr-16 ml-8">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.active_participation_percent <
                        87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                    {/* <p className="overline color_dark_grey2 ml-8">{length} members</p> */}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main mb-16">
                  <p className="overline">Professionalism</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.professionalism_percent
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.professionalism_percent >=
                          87 ? (
                        <p className="overline color_dark_grey2 ml-8 pr-16">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.professionalism_percent <
                        87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="incubator_analytics_rating_proQuality_main">
                  <p className="overline">Overall Experience</p>
                  <div className="incubator_analytics_rating_progress_main">
                    <div
                      className="incubator_analytics_rating_pr"
                      style={{
                        width: `${paticipantFeed === null
                          ? 0
                          : paticipantFeed.overall_experience_percent
                          }%`,
                      }}
                    >
                      {paticipantFeed ===
                        null ? null : paticipantFeed.overall_experience_percent >=
                          87 ? (
                        <p className="overline color_light_grey ml-8 pr-16">
                          {length} members
                        </p>
                      ) : null}
                    </div>
                    {paticipantFeed ===
                      null ? null : paticipantFeed.overall_experience_percent <
                        87 ? (
                      <p className="overline color_dark_grey2 ml-8">
                        {length} members
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="partipant_profile_main_fund"
              style={{ marginTop: 130 }}
            >
              <p className="title">Funding to Raise</p>
              <div className="partipant_profile_main_fund_INR d-flex">
                <p className="INR">INR</p>
                <p className="INR INR50" style={{ marginLeft: 26 }}>
                  {startup.fundraise}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          {startup.id ? (
            <div className="participants_right_main_founder_root mb-24">
              <div className="participants_right_main_founder_head mb-16">
                <p className="title">Founder Details</p>
              </div>
              <div className="participants_right_main_founder_body">
                <img src={startup.profilePic} alt="profile" />
                <div className="participants_right_main_right ml-16">
                  <p className="subtitle">
                    {startup.id.firstname} {startup.id.lastname}
                  </p>
                  <p className="caption grey">{startup.contact.jobTitle}</p>
                  <p>
                    <a
                      href={startup.socialConnects.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedIn style={{ fill: "#0077B5" }} />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="connection_profile_root_highligts">
            <div className="participants_profile_root_highligts_head">
              <p className="subtitle">Highlights</p>
            </div>
            <div className="participants_profile_root_highligts_table_main">
              <table className="participants_profile_root_highligts_table">
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Industry
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                    {startup.industry}
                  </td>
                </tr>
                <tr>
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Location
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                    {startup.location.country}
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Audience
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                    {startup.audience}
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Employees
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                    {startup.employees}
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Funding Status
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                    {startup.fundingSeries}
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Growth
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                    {startup.growth}
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Incorporation Date
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td">
                    {new Date(startup.incorporationDate).toLocaleDateString(
                      "nl",
                      { year: "numeric" }
                    )}
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Website
                  </td>
                  <td
                    className="body2 color_dark_grey2 participants_profile_root_highligts_td"
                    style={{ wordBreak: "break-all" }}
                  >
                    <a
                      href={`${startup.website}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {startup.website}
                    </a>
                  </td>
                </tr>
                <tr className="participants_profile_root_highligts_table_tr">
                  <td className="body2 participants_profile_root_highligts_ftd">
                    Contact
                  </td>
                  <td className="body2 color_dark_grey2 participants_profile_root_highligts_td ">
                    {startup.contact.phone}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Feedback
        open={open}
        handleClose={handleClose}
        startup={startup}
        getDetail={getDetail}
      />
    </>
  );
};

export default Applicantcard;
