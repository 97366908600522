import { AddBox } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { useAuth } from "../../../../../Utils/Auth";

export default function ModuleComponent({
  setAddSessionState,
  data,
  courseId,
  index,
  getModules,
}) {
  const navigate = useNavigate();
  // const { user } = useAuth();
  const [isEdit, setisEdit] = useState(false);
  const [moduleName, setModulelName] = useState("");
  const [moduleDesc, setModulelDesc] = useState("");
  const [sessions, setsessions] = useState(null);
  const [assesments, setassesments] = useState(null);
  const [assignment, setAssignment] = useState(null);

  const {programid} = useParams()

  React.useEffect(() => {
    if (data) {
      if (data.title) {
        setModulelName(data.title);
      }
      if (data.description) {
        setModulelDesc(data.description);
      }
    }
  }, [data]);

  const updateModule = async (e) => {
    e.preventDefault();
    await axios({
      url: `/api/mentorModules/module/update/${data._id}`,
      method: "put",
      data: {
        title: moduleName,
        description: moduleDesc,
        course_id: courseId,
      },
    })
      .then(() => {
        toast.success("Module Updated");
        getModules();
        setisEdit(false);
      })
      .catch(() => {
        toast.error("Something went wrong, try again");
      });
  };

  const getSessions = async () => {
    await axios({
      url: `/api/mentorModules/sessions/module/${data._id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data) {
          setsessions(res.data.session);
          setassesments(res.data.assessment);
          setAssignment(res.data.assignment);
        }
      })
      .catch(() => { });
  };
  React.useEffect(() => {
    getSessions();
  }, [data]);

  return (
    <div>
      <div className="module_main_div">
        <div className=" row justify-content-between ">
          <p className="subtitle col-5">
            {index + 1}. {data.title}
          </p>
          <p className="body1 col-4">{sessions && sessions.length} sessions</p>
          <p
            className="point caption m-auto"
            onClick={() => setisEdit(!isEdit)}
            style={{ cursor: "pointer" }}
          >
            Edit{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-down mx-2"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </p>
        </div>
        {isEdit ? (
          <div className="my-4">
            <div className="col-12 create_course_form_maindiv my-2">
              <label htmlFor="Module 1" className="caption color_dark_blue">
                Module {index + 1}
              </label>
              <input
                type="text"
                className="body1"
                placeholder="Enter Title"
                value={moduleName}
                onChange={(e) => setModulelName(e.target.value)}
              />
            </div>
            <div className="col-12 create_course_form_maindiv">
              <label htmlFor="course_desc" className="caption color_dark_blue">
                Description
              </label>
              <textarea
                type="text"
                className="body1"
                placeholder="Enter industry or keyword"
                value={moduleDesc}
                onChange={(e) => setModulelDesc(e.target.value)}
              />
            </div>
            <button
              className="primaryFilled_activeCta"
              onClick={(e) => updateModule(e)}
            >
              Save
            </button>
          </div>
        ) : null}
        {sessions && sessions.length > 0 ? (
          <div className="my-3 mx-4">
            <h6 className="body1">Sessions</h6>
            {sessions.map((ses, i) => (
              <>
                <div className="row justify-content-between mx-0 col-lg-4 col-sm-6 col-xs-12 px-0 my-2" key={i}>
                  <p className="body2">{ses.session_name}</p>
                  <p className="caption" style={{ cursor: "pointer" }} onClick={() => {
                    navigate(
                      `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}&session=${ses._id}`
                    );
                    setAddSessionState(1)

                  }} >Edit</p>
                </div>
                <hr />
              </>
            ))}
          </div>
        ) : null}

        {assesments ? (
          <>
            <div className="mx-4 my-3">
              <h6 className="body1">Assessment</h6>
              <div className="row justify-content-between mx-0 my-1 col-lg-4 col-sm-6 col-xs-12 px-0">
                <p className="body2">{assesments.name}</p>
                <p className="caption" style={{ cursor: "pointer" }} onClick={() => {
                  navigate(
                    `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}&assesment=${assesments._id}`
                  );
                  setAddSessionState(2)

                }} >Edit</p>
              </div>
            </div>
          </>
        ) : null}

        {assignment ? (
          <>
            <div className="mx-4 my-3">
              <h6 className="body1">Assignment</h6>
              <div className="row justify-content-between mx-0 my-1 col-lg-4 col-sm-6 col-xs-12 px-0">
                <p className="body2">{assignment.name}</p>
                <p className="caption" style={{ cursor: "pointer" }} onClick={() => {
                  navigate(
                    `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}&assignment=${assignment._id}`
                  );
                  setAddSessionState(3)

                }} >Edit</p>
              </div>
            </div>
          </>
        ) : null}

        <div className="col-12 allbtns_module_main mt-3">
          <button
            className="cta color_dark_blue"
            onClick={(e) => {
              e.preventDefault();
              setAddSessionState(1);
              navigate(
                `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}`
              );
            }}
          // title="New Session will be added of previous one until new module doesn't create"
          >
            <AddBox style={{ marginRight: 7 }} /> Add Session
          </button>
          {assesments ? null : (
            <button
              className="cta color_dark_blue"
              onClick={(e) => {
                e.preventDefault();
                setAddSessionState(2);
                navigate(
                  `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}`
                );
              }}
            >
              <AddBox style={{ marginRight: 7 }} /> Add Assesment
            </button>
          )}
          {assignment ? null : (
            <button
              className="cta color_dark_blue"
              onClick={(e) => {
                e.preventDefault();
                setAddSessionState(3);
                navigate(
                  `/service/incprogram/${programid}?tab=6&course=${courseId}&module=${data._id}`
                );
              }}
            >
              <AddBox style={{ marginRight: 7 }} /> Add Assignment
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
