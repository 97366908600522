import React, { useEffect, useState } from 'react'
import cloud_upload from "../../../../../Assets/images/upload_doc.png";
import Delete from "../../../../../Assets/images/incubatorDash/delete.png"
import { toast } from 'react-toastify';
import Axios from "axios"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useAuth } from "../../../../../Utils/Auth";

const CreateAssignment = ({ setAddSessionState }) => {
    const navigate = useNavigate();
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const module_id = query.get('module')
    const assignment_id = query.get('assignment')
    const course_id = query.get('course')
    // const { user } = useAuth();
    const { programid } = useParams();
    const [assignmentName, setAssignmentName] = useState("");
    const [assignmentInstruction, setAssignmentInstruction] = useState("");
    const [assignmentDocUrl, setAssignmentDocUrl] = useState("");
    const [assignmentupdateBool, setAssignmentupdateBool] = useState(false);

    const getAssignment = async (id) => {
        await Axios({
            url: `/api/mentorModules/assignment/${id}`,
            method: "get",
        }).then((res) => {
            setAssignmentupdateBool(true)
            const { data } = res.data;
            setAssignmentName(data.name)
            setAssignmentInstruction(data.instructions)
            setAssignmentDocUrl(data.document)
        }).catch(() => {
            toast.error('Try again')
        });
    }

    useEffect(() => {
        if (assignment_id) {
            getAssignment(assignment_id)
        }
    }, [])

    const handleDocUpload = async (e) => {
        const doc = e.target.files[0];
        if (doc.size > 2 * 1024 * 1024) {
            toast.error("File size exceeded 2MB!", { position: "bottom-right" });
            return;
        }
        const formdata = new FormData();
        formdata.append("material", doc);
        await Axios({
            method: "post",
            url: `/api/mentorModules/material/upload`,
            data: formdata,
        }).then((res) => {
            const { data } = res;
            setAssignmentDocUrl(data.location);
            toast.success("File uploaded", { position: "bottom-right" });
        }).catch(() => {
            toast.error("Error in upload", { position: "bottom-right" });
        });
    }

    const CreateAssign = async (e) => {
        e.preventDefault();
        await Axios({
            url: `/api/mentorModules/assignment/create/${course_id}/${module_id}`,
            method: "post",
            data: {
                name: assignmentName,
                instructions: assignmentInstruction,
                document: assignmentDocUrl
            },
        }).then(() => {
            setAddSessionState(0)
            toast.success("Assigment Created");
            navigate(
                `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
            );
            // setassessment(res.data.data);
        }).catch((err) => {
            toast.error('Try again')
            console.log(err)
        }
        );
    }

    const UpdateAssignment = async (e) => {
        e.preventDefault();
        await Axios({
            url: `/api/mentorModules/assignment/update/${assignment_id}`,
            method: "put",
            data: {
                name: assignmentName,
                instructions: assignmentInstruction,
                document: assignmentDocUrl
            },
        }).then(() => {
            setAddSessionState(0)
            toast.success("Assigment Updated");
            navigate(
                `/service/incprogram/${programid}?tab=6&course=${course_id}&module=${module_id}`
            );
            // setassessment(res.data.data);
        }).catch(() => {
            toast.error('Try again')
        });
    }


    return <div className='mt-4'>

        <h4>Add assignment</h4>
        <div className="create_assignment_form_main">
            <div className="row">
                <div className="col-12 create_course_form_maindiv">
                    <label htmlFor="course_title" className='caption color_dark_blue'>Name of assigment</label>
                    <input
                        type="text"
                        className='body1'
                        placeholder='Enter the name of assigment'
                        value={assignmentName}
                        onChange={(e) => setAssignmentName(e.target.value)}
                    />
                </div>
                <div className="col-12 create_course_form_maindiv">
                    <label
                        htmlFor="course_desc"
                        className="caption color_dark_blue"
                    >
                        Instructions
                    </label>
                    <textarea
                        type="text"
                        className="body1"
                        placeholder="Add Instructions for your assignment"
                        value={assignmentInstruction}
                        onChange={(e) => setAssignmentInstruction(e.target.value)}
                    />
                </div>
                <div className="col-12 create_course_form_maindiv">
                    {
                        assignmentDocUrl ? <div className="program_upload_imglater">
                            <div className="d-flex align-items-center">
                                <div className="program_upload_imgImg">
                                    <img src={cloud_upload} alt="cloud_upload" style={{ padding: "20px" }} />
                                </div>
                                <div className="program_upload_imgName">
                                    <p className='caption color_dark_blue'>{assignmentName}</p>
                                </div>
                            </div>
                            <div className="program_upload_imgdlt">
                                <img src={Delete} alt="cloud_upload" style={{ padding: "20px", cursor: "pointer" }}
                                    onClick={() => setAssignmentDocUrl("")}
                                />
                            </div>
                        </div> : <div className="program_upload_img">
                            {/* <label htmlFor="uplaodImg"> */}
                            <img src={cloud_upload} alt="cloud_upload" style={{ padding: "20px" }} />
                            <p className="caption color_dark_blue">Sample Upload Document</p>
                            {/* </label> */}
                            <input
                                type="file"
                                id="uplaodImg"
                                onChange={handleDocUpload}
                                accept="image/*, application/pdf,text/plain,application/msword,.doc, .docx"
                            />
                        </div>
                    }

                </div>
            </div>
        </div>
        <div className="create_course_form_maindivBtn">
            {
                assignmentupdateBool ? <button className='primaryFilled_activeCta' onClick={UpdateAssignment}>Update</button> : <button className='primaryFilled_activeCta' onClick={CreateAssign}>Save</button>
            }

        </div>
    </div>
}

export default CreateAssignment