import React , { useState, useEffect } from "react"; 
import "./index.css";
import { useNavigate } from "react-router-dom"; // ,
import { useAuth } from "../../../Utils/Auth";
// import axios from "axios";




export default function card({report,setValuationStep,setActiveStep}) {
  const { user } = useAuth(); //  ,setSessionExpire
  const navigate = useNavigate();
  const [progress, setProgress] = useState(100);
  const returnProgress = (val) => {
      return val ? 1 : 0;
  }

  const getReportProgress = () => {
      const getProgress = returnProgress(report.finFilled && report.labFilled) + returnProgress(report.quesFilled) + returnProgress(report.transFilled);
      setProgress(getProgress * (100 / 3));
  }

  useEffect(() => {
      getReportProgress()
  }, [])
 
  return (
    <div className="val-dash-box d-flex align-items-center justify-content-between" style={{marginBottom: "16px"}}>
            <div className="d-flex align-items-center w-fit">
              <div className="overline val-dash-percentbox" style={{color: "#000000"}}>{Math.floor(progress)} %</div>
              <span className="body2" style={{color: "#262C5B"}}>{report.reportTitle}</span>
            </div>
            <div className="d-flex align-items-center w-fit" onClick={()=>{
              localStorage.setItem('valuation_id', report._id);
              localStorage.setItem('valuation_title', report.reportTitle)
              localStorage.setItem('valuation_cretedDate', report.reportDate)
              setValuationStep(3);
              Math.floor(progress) === 0 ? setActiveStep(0) : Math.floor(progress) === 33 ? setActiveStep(1) : setActiveStep(2)
              navigate(`/dashboard/${user.type}?tab=4`);
            }}
            style={{cursor:"pointer"}}
            >
              {/* <Link to={} > */}
              <span className="caption" style={{color: "#989898"}}>View</span>
              {/* </Link> */}
            </div>
          </div>
  );
}
