import React, { useState } from 'react'
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Allreq from "./AllReq/Allreq"
import Declined from "./Declined/Declined"
import ViewReq from './ViewReq';

const RealEstateReq = () => {
  const [value, setValue] = React.useState("1");
  const [view, setView] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {
        view === 0 ?
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs"
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="All Requests" value="1" />
                  <Tab label="declined" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Allreq
                  setView={setView}
                />
              </TabPanel>
              <TabPanel value="2">
                <Declined
                  setView={setView}
                />
              </TabPanel>
            </TabContext>
          </Box>
          : <ViewReq
            setView={setView}
            view={view}
          />
      }

    </>
  )
}

export default RealEstateReq