import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Avatar } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Utils/Auth';
const MemberCard = ({member,list}) => {
     console.log(member)
     const navigate = useNavigate()
     const { user } = useAuth();
     return (
          <List component="div" disablePadding key={member._id} onClick={()=> navigate(`/dashboard/${user.type}?tab=7&mm=1&${list}=${list==='serviceprovider'|| list==='employee' ? member.user_id._id : member.userProfile._id }`)}>
                                
          <ListItemButton sx={{ pl: 4 }}>
               <ListItemIcon>
                    <Avatar
                         src={member.userProfile.profilePic}
                         alt="connection"
                         className="dashboard_01_connections_logo"/>
               </ListItemIcon>
                    <ListItemText sx={{textTransform:'capitalize'}} primary={`${member.userProfile.companyName}`}/>
               </ListItemButton>
          </List>
     )
}

export default MemberCard;