import React from 'react'
import Analytics from "./Analytics/Analytics"
import MyLeases from "./MyLeases/MyLeases"
import MyRealEstate from "./MyRealEstate/MyRealEstate"
import RealEstateReq from "./RealEstateReq/RealEstateReq"
import MyAssets from "./MyAssets/MyAssets"
import AssetsReq from "./AssetReq/AssestReq"

const CoworkingMain = ({ coworkingStep, setCoWorkingStep, setCoWorkingBool }) => {
    return (
        <div className="incubator_coworking_root">
            {
                coworkingStep === 1 ? <Analytics
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                    setCoWorkingBool={setCoWorkingBool}
                /> : coworkingStep === 2 ? <MyLeases
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                    setCoWorkingBool={setCoWorkingBool}
                /> : coworkingStep === 3 ? <MyRealEstate
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                    setCoWorkingBool={setCoWorkingBool}
                /> : coworkingStep === 4 ? <RealEstateReq
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                    setCoWorkingBool={setCoWorkingBool}
                /> : coworkingStep === 5 ? <MyAssets
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                    setCoWorkingBool={setCoWorkingBool}
                /> : coworkingStep === 6 ? <AssetsReq
                    coworkingStep={coworkingStep}
                    setCoWorkingStep={setCoWorkingStep}
                    setCoWorkingBool={setCoWorkingBool}
                /> : <div className='coming_soon_root'>
                    <h4 style={{ color: "#988e83" }}>Coming Soon...</h4>
                </div>
            }
        </div>
    )
}

export default CoworkingMain