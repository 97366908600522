/** @format */

import React, { useEffect, useState } from "react";
// import { useAuth } from "../../Utils/Auth";
import axios from "axios";
// import Loader from "../../Components/Loader";
import CircularProgress from '@mui/material/CircularProgress';
import NotificationCard from "./NotificationCard";
import "./notification.css";
import { useAuth } from "../../Utils/Auth";

const WhiteNotifications = () => {
  const [notification, setnotification] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const {setSessionExpire} = useAuth();

  const getNotificationsNew = async () => {
  await axios({
      method: "GET",
      url: `/api/notification`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res)=>{
      setnotification(res.data);
    }).catch((err)=>{
      if(err.response && err.response.status === 401){
        setSessionExpire(true)
      }
    })
  };

  //   const deleteNotification = async (key) => {
  //     const res = await axios({
  //       method: "DELETE",
  //       url: `/api/notification/${key}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     getNotifications();
  //   };
  // useEffect(() => {
  //   getNotifications();
  // }, [notification]);

  useEffect(() => {
    getNotificationsNew();
  }, []);

  return (
    <div className="notification_panel_root notification_panel_root_white">
        <h5 className="subtitle text-left mx-2">Notifications</h5>
      {notification ? (
        <>
          {notification.length !== 0 ? (
            <>
              {notification.slice(0, seeMore ? 15 : 7).map((value) => {
                return (
                  <>
                    <NotificationCard isBgWhite={true} props={value} getNotificationsNew={getNotificationsNew}/>
                  </>
                );
              })}
              <div className="text-left">
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    fontSize: "15px",
                    color: "#000",
                    margin:'5px 0'
                  }}
                  onClick={() => setSeeMore(!seeMore)}
                >
                  <p className="no-margin bold">View {seeMore ? "less" : "all"}</p>
                </button>
              </div>
            </>
          ) : (
            <>
              <p
                style={{
                  color: "#000",
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                No new notifications
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <div className="text-center">
          <CircularProgress />
          </div>
        </>
      )}
    </div>
  );
};

export default WhiteNotifications;
