import React from 'react'
import search from "./../../../../Assets/images/icons/search.png";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Active from './Active/Active';
import Completed from './Completed/Completed';
import ViewEstate from './ViewEstate';
import ViewAsset from './ViewAsset';

const MyLeases = () => {
  const [value, setValue] = React.useState("1");
  const [viewLeases, setViewLeases] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      {
        viewLeases === 0 ?
          <div className='coworking_myleases_root'>
            <div className="incubation_prorgram_search_head myLeases_head">
              <div className="incubation_programm_searchBoxDiv myLeases_head_search w_75">
                <input type="text" name="" id="" placeholder='Type here to search' />
                <button className="p_Bold_secBlue14">
                  <img src={search} alt="search" />
                  Search
                </button>
              </div>
            </div>
            <div className="coworking_myLeases_main mt-24">
              <Box className="service-tabs-main dashboard01_home dashbaord_connections_inner_tabs">
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Active" value="1" />
                      <Tab label="Completed" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <Active 
                      viewLeases={viewLeases}
                      setViewLeases={setViewLeases}
                    />
                  </TabPanel>
                  <TabPanel value="2">
                    <Completed 
                      viewLeases={viewLeases}
                      setViewLeases={setViewLeases}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </div>

          </div> : viewLeases === 1 ? <ViewEstate
            viewEstate={viewLeases}
            setViewLeases={setViewLeases}
          /> : viewLeases === 2 ? <ViewAsset
            viewEstate={viewLeases}
            setViewLeases={setViewLeases}
          /> : null
      }
    </>
  )
}

export default MyLeases