import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Utils/Auth';
import Query from '../../Utils/Query/Query';
import Axios from "axios"
import { KeyboardArrowLeft } from '@mui/icons-material';
import { toast } from 'react-toastify';
import IncubatorPrograms from "../../Dashboard/Components/Connections/IncubatorPrograms"
import { Avatar } from '@mui/material';

const IncubatorProfile = ({ setstep, fetchInvites }) => {
    const { user, setSessionExpire } = useAuth()
    const query = Query();
    const connectId = query.get("connect");
    const incubator = query.get("incubator");
    const [profile, setprofile] = useState(null)

    const getIncubator = async () => {
        await Axios({
            method: "get",
            url: `/api/incubator/user-by-id/${incubator}`,
        }).then((res) => {
            setprofile(res.data.data);
            window.scrollTo(0, 0);
        }).catch((err) => {
            if (err.response && err.response.status === 401) {
                setSessionExpire(true);
            }
        });
    };
    const cancelRequest = () => {
        Axios({
            url: `/api/mentor/delete/request/${connectId}`,
            method: "delete",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const { data } = res;
                if (data.success) {
                    toast.success("Request cancelled");
                    fetchInvites()
                    setstep(1)
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    setSessionExpire(true);
                }
                toast.error(err.response.data.error);
            });
    };
    const acceptrequest = () => {
        Axios({
            url: `/api/mentor/accept/${connectId}`,
            method: "get",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const { data } = res;
                if (data.success) {
                    toast.success("Request Accepted");
                    fetchInvites();
                    setstep(1)
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    setSessionExpire(true);
                }
                toast.error(err.response.data.error);
            });
    };

    useEffect(() => {
        if (incubator) {
            getIncubator()
        }
    }, [incubator])
    return (
        <>
            <div>
                <div
                    className="back_CTA pointer"
                    onClick={() => {
                        setstep(1)
                        history(`/dashboard/${user.type}?tab=5&ms=1&step=1`);
                    }}
                >
                    <p className="caption color_light_grey">
                        <span>
                            <KeyboardArrowLeft />
                        </span>
                        <span>BACK</span>
                    </p>
                </div>
            </div>
            <hr />
            {
                profile ? <div className="row">
                    <div className="col-lg-8">
                        <div
                            className="connection_profile_root"
                            id="connection_profile_root"
                        >
                            <div className="row mt-2 align-items-center justify-content-between connection_profile_head dashboard01_connection_profile_head">
                                <div className="row no-margin align-items-center col-8">
                                    <div className="width_auto no-padding">
                                        <Avatar
                                            src={profile.logo}
                                            className=""
                                            alt="Profile"
                                            sx={{ width: 90, height: 90 }}
                                        />
                                    </div>
                                    <div className="col-lg-8 col-sm-8 col-md-8 col-xs-8">
                                        <p className="p_spartan_20">{profile.name}</p>
                                        <p className="bold">
                                            Incubator | {profile.location.country}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-md-6 text-right"></div>
                            </div>
                            <div className="mt-4 mx-2">
                                <p className="grey bold">{profile.incDescription}</p>
                            </div>

                            <div className="row mx-2 my-3">
                                {profile.interestedIndustries ? (
                                    <>
                                        {profile.interestedIndustries.map((industry, i) => (
                                            <p className="p_grey_chip" key={i}>
                                                {industry}{" "}
                                            </p>
                                        ))}
                                    </>
                                ) : null}
                            </div>
                            <hr />
                            <div>
                                <div className="row mx-0">
                                    <button
                                        className="gradient_CTA width_fit mx-2"
                                        onClick={() => acceptrequest(profile.id)}
                                    >
                                        ACCEPT REQUEST
                                    </button>
                                    <button
                                        className="twf_gradoutlinedbtn text-dark width_fit "
                                        onClick={() => cancelRequest(profile.id)}
                                    >
                                        DECLINE
                                    </button>

                                </div>


                            </div>
                            <div>
                                <p className="p_spartan_20 mt-40">Current Programs</p>
                                <IncubatorPrograms id={profile.id} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="connection_profile_root_highligts">
                            <div className="connection_profile_root_highligts_head">
                                <p className="p_spartan_20">Highlights</p>
                            </div>
                            <div className="connection_profile_root_highligts_desc">
                                <table>
                                    <tr>
                                        <td className="td_left">Website</td>
                                        <td className="td_right" style={{ wordBreak: "break-all" }}>
                                            <a
                                                href={`${profile.companyWebsite}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {profile.companyWebsite}{" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-box-arrow-up-right"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                                    />
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td_left">LinkedIn </td>
                                        <td className="td_right" style={{ wordBreak: "break-all" }}>
                                            <a
                                                href={`${profile.linkedProfile}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {profile.name}{" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-box-arrow-up-right"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                                    />
                                                </svg>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> : null
            }

        </>
    );
}

export default IncubatorProfile