import Axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import Component from "./Component";

export default function Events({seteventState}) {
  const { user } = useAuth();
  


  const [programs, setprograms] = useState([]);
  const { setSessionExpire } = useAuth();
  const fetchprograms = async () => {
    await Axios({
      url: `/api/incubatorProgram/programs`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) setprograms(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setSessionExpire(true);
        }
      });
  };


  useEffect(() => {
    fetchprograms();
  }, []);
  
  // useEffect(() => {
  //   console.log(programs,seteventState);
  // });

  return (
    <div className="Dashboard01_events dashboard01_block mt-0">
      <div className="row justify-content-between no-margin">
        <h5 className="w_fit subtitle">My Programs</h5>
        <Link to={`/dashboard/${user.type}?tab=7&ps=1`}>
          <p className="w_fit bold" style={{color: "#262C5B"}}>
            {/* <span style={{margin:"0px 10px 8px 0"}}>View All</span> */}
            <svg style={{margin:"4px 0px 0px 0"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right mb-2" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            </p>
        </Link>
      </div>
      <div className="mt-16">
      <div className="row">

      {programs && programs.length > 0
            ? programs.length > 1 ? (programs.slice(0,2).map((program, i) => (
              <Link to={`/service/incprogram/${program._id}?tab=1`} className="col-6" key={i}>
               <Component id={program._id} title={program.title} duration={program.duration} startDate={program.startDate} endDate={program.endDate} />
              </Link>
              )))
              :(
                programs.slice(0,1).map((program, i) => (
                  <>
                  <Link to={`/service/incprogram/${program._id}?tab=1`} className="col-6" key={i}>
                  <Component id={program._id} title={program.title} duration={program.duration} startDate={program.startDate} endDate={program.endDate} />
                  </Link>
                <div className="col-6" key={i}>
                <div className="program-box w-100 d-flex justify-content-between d-block pt-3 pb-3">
                <div className="row d-flex justify-content-between">
                  <div className="col-12 d-flex align-items-center">
                      <svg className="mr-2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.81393 6.08384C4.96947 6.31091 4.30711 6.98271 4.08434 7.838C3.97189 8.26965 3.97189 24.523 4.08434 24.9547C4.31001 25.821 4.97371 26.4869 5.83541 26.7113C6.0943 26.7788 7.29766 26.7878 16 26.7878C24.5884 26.7878 25.9081 26.7781 26.157 26.7133C27.0233 26.4876 27.6892 25.8239 27.9137 24.9622C28.0275 24.525 28.0294 12.2714 27.9156 11.8347C27.7087 11.0402 27.191 10.4673 26.3787 10.1341C26.1178 10.027 26.0951 10.0265 20.9709 10.0016L15.8251 9.9766L15.6649 9.8623C15.5558 9.78456 15.2443 9.3132 14.6907 8.3885C13.6293 6.61571 13.3288 6.30356 12.4682 6.0794C12.0517 5.97089 6.21944 5.97478 5.81393 6.08384ZM15.6607 6.91661C15.7002 6.97841 15.9177 7.33816 16.1441 7.71595L16.5557 8.40289H20.999C23.4428 8.40289 25.4422 8.3909 25.4422 8.37626C25.4422 8.36158 25.3866 8.23988 25.3187 8.10574C25.1401 7.75342 24.6946 7.2953 24.3537 7.1136C23.7603 6.79731 23.8675 6.8042 19.5308 6.8042H15.5888L15.6607 6.91661ZM16.4378 14.9311C16.5096 14.9847 16.6147 15.0907 16.6713 15.1665C16.7684 15.2964 16.7752 15.3813 16.7891 16.6474L16.8039 17.9905L18.147 18.0052C19.413 18.0192 19.4979 18.0259 19.6279 18.123C19.89 18.3189 19.9717 18.4785 19.9717 18.7944C19.9717 19.1103 19.89 19.2699 19.6279 19.4657C19.4979 19.5628 19.413 19.5696 18.147 19.5835L16.8039 19.5983L16.7891 20.9414C16.7752 22.2074 16.7684 22.2923 16.6713 22.4223C16.4755 22.6844 16.3159 22.7661 16 22.7661C15.6841 22.7661 15.5245 22.6844 15.3286 22.4223C15.2315 22.2923 15.2248 22.2074 15.2108 20.9414L15.1961 19.5983L13.853 19.5835C12.5869 19.5696 12.502 19.5628 12.3721 19.4657C12.11 19.2699 12.0282 19.1103 12.0282 18.7944C12.0282 18.4785 12.11 18.3189 12.3721 18.123C12.502 18.0259 12.5869 18.0192 13.8553 18.0052L15.2006 17.9904V16.722C15.2006 15.9448 15.2213 15.3992 15.2539 15.313C15.3243 15.1273 15.4902 14.9511 15.6752 14.8654C15.8743 14.7732 16.2722 14.8075 16.4378 14.9311Z" fill="url(#paint0_linear_2846_21783)"/>
                      <defs>
                      <linearGradient id="paint0_linear_2846_21783" x1="26.7755" y1="6" x2="0.661308" y2="11.841" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#262C5B"/>
                      <stop offset="1" stopColor="#580167"/>
                      </linearGradient>
                      </defs>
                      </svg>

                  <div className="caption" style={{color:"#262C5B"}}>
                  Some Heading
                  </div>
                  </div>
                  <div className="col-12 mt-2">
                  <div className="overline" style={{color:"#989898"}}>
                  A small message to encourage incubator to create more courses A small message to encourage incubator to create more courses
                  </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                  <div className="d-flex mt-2" onClick={()=>seteventState(3)}>

                  <div className="caption mr-2" style={{color:"#565656"}}>
                  <Link to={`/dashboard/${user.type}?tab=7&ps=2`}>

                  Create Now
                  </Link>
                  </div>
                  <Link to={`/dashboard/${user.type}?tab=7&ps=2`}>

                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.34066 7.91648H10.649L6.58232 12.3898C6.25732 12.7473 6.25732 13.334 6.58232 13.6915C6.90732 14.049 7.43232 14.049 7.75732 13.6915L13.249 7.65065C13.574 7.29315 13.574 6.71565 13.249 6.35815L7.76566 0.308151C7.60996 0.136503 7.39859 0.0400391 7.17816 0.0400391C6.95773 0.0400391 6.74635 0.136503 6.59066 0.308151C6.26566 0.665651 6.26566 1.24315 6.59066 1.60065L10.649 6.08315H1.34066C0.882324 6.08315 0.507324 6.49565 0.507324 6.99982C0.507324 7.50398 0.882324 7.91648 1.34066 7.91648Z" fill="#565656"/>
                    </svg>
                    </Link>

                  </div> 

                  </div>
                </div>
                </div>
                </div>
                </>
                )))
            : (
              <div className="col-6">
                <div className="program-box w-100 d-flex justify-content-between d-block pt-3 pb-3">
                <div className="row d-flex justify-content-between">
                  <div className="col-12 d-flex align-items-center">
                      <svg className="mr-2" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.81393 6.08384C4.96947 6.31091 4.30711 6.98271 4.08434 7.838C3.97189 8.26965 3.97189 24.523 4.08434 24.9547C4.31001 25.821 4.97371 26.4869 5.83541 26.7113C6.0943 26.7788 7.29766 26.7878 16 26.7878C24.5884 26.7878 25.9081 26.7781 26.157 26.7133C27.0233 26.4876 27.6892 25.8239 27.9137 24.9622C28.0275 24.525 28.0294 12.2714 27.9156 11.8347C27.7087 11.0402 27.191 10.4673 26.3787 10.1341C26.1178 10.027 26.0951 10.0265 20.9709 10.0016L15.8251 9.9766L15.6649 9.8623C15.5558 9.78456 15.2443 9.3132 14.6907 8.3885C13.6293 6.61571 13.3288 6.30356 12.4682 6.0794C12.0517 5.97089 6.21944 5.97478 5.81393 6.08384ZM15.6607 6.91661C15.7002 6.97841 15.9177 7.33816 16.1441 7.71595L16.5557 8.40289H20.999C23.4428 8.40289 25.4422 8.3909 25.4422 8.37626C25.4422 8.36158 25.3866 8.23988 25.3187 8.10574C25.1401 7.75342 24.6946 7.2953 24.3537 7.1136C23.7603 6.79731 23.8675 6.8042 19.5308 6.8042H15.5888L15.6607 6.91661ZM16.4378 14.9311C16.5096 14.9847 16.6147 15.0907 16.6713 15.1665C16.7684 15.2964 16.7752 15.3813 16.7891 16.6474L16.8039 17.9905L18.147 18.0052C19.413 18.0192 19.4979 18.0259 19.6279 18.123C19.89 18.3189 19.9717 18.4785 19.9717 18.7944C19.9717 19.1103 19.89 19.2699 19.6279 19.4657C19.4979 19.5628 19.413 19.5696 18.147 19.5835L16.8039 19.5983L16.7891 20.9414C16.7752 22.2074 16.7684 22.2923 16.6713 22.4223C16.4755 22.6844 16.3159 22.7661 16 22.7661C15.6841 22.7661 15.5245 22.6844 15.3286 22.4223C15.2315 22.2923 15.2248 22.2074 15.2108 20.9414L15.1961 19.5983L13.853 19.5835C12.5869 19.5696 12.502 19.5628 12.3721 19.4657C12.11 19.2699 12.0282 19.1103 12.0282 18.7944C12.0282 18.4785 12.11 18.3189 12.3721 18.123C12.502 18.0259 12.5869 18.0192 13.8553 18.0052L15.2006 17.9904V16.722C15.2006 15.9448 15.2213 15.3992 15.2539 15.313C15.3243 15.1273 15.4902 14.9511 15.6752 14.8654C15.8743 14.7732 16.2722 14.8075 16.4378 14.9311Z" fill="url(#paint0_linear_2846_21783)"/>
                      <defs>
                      <linearGradient id="paint0_linear_2846_21783" x1="26.7755" y1="6" x2="0.661308" y2="11.841" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#262C5B"/>
                      <stop offset="1" stopColor="#580167"/>
                      </linearGradient>
                      </defs>
                      </svg>

                  <div className="caption" style={{color:"#262C5B"}}>
                  Some Heading
                  </div>
                  </div>
                  <div className="col-12 mt-2">
                  <div className="overline" style={{color:"#989898"}}>
                  A small message to encourage incubator to create more courses A small message to encourage incubator to create more courses
                  </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end">
                  <div className="d-flex mt-2" onClick={()=>seteventState(3)}>
                  <div className="caption mr-2" style={{color:"#565656"}}>
                  Create Now
                  </div>
                    <Link to={`/dashboard/${user.type}?tab=7&ps=5`}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.34066 7.91648H10.649L6.58232 12.3898C6.25732 12.7473 6.25732 13.334 6.58232 13.6915C6.90732 14.049 7.43232 14.049 7.75732 13.6915L13.249 7.65065C13.574 7.29315 13.574 6.71565 13.249 6.35815L7.76566 0.308151C7.60996 0.136503 7.39859 0.0400391 7.17816 0.0400391C6.95773 0.0400391 6.74635 0.136503 6.59066 0.308151C6.26566 0.665651 6.26566 1.24315 6.59066 1.60065L10.649 6.08315H1.34066C0.882324 6.08315 0.507324 6.49565 0.507324 6.99982C0.507324 7.50398 0.882324 7.91648 1.34066 7.91648Z" fill="#565656"/>
                    </svg>
                    </Link>
                  </div>
                  </div>
                </div>
                </div>
                </div>
            )
      }
        
      {/* <div className="col-6">
      <div className="program-box w-100 d-flex justify-content-between pt-4 pb-4">
      <div className="row">
        <div className="col-7" style={{borderRight: "2px solid #E6E6E6"}}>
        <div className="caption">
          Develop a Successful blockchain tech
        </div>
        <div className="overline mt-3">
          Participants
        </div>
        <div className="w_fit">
        <AvatarGroup max={4} className="">
          <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          <Avatar sx={{ width: 24, height: 24 }} alt="Travis Howard" src="/static/images/avatar/2.jpg" />
          <Avatar sx={{ width: 24, height: 24 }} alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </AvatarGroup>
        </div>
        </div>
        <div className="col-5 d-flex jutify-content-center">
        <div className="d-flex flex-column justify-content-center ">
          <div className="mb-2">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99594 0.332031C3.31594 0.332031 0.335938 3.3187 0.335938 6.9987C0.335938 10.6787 3.31594 13.6654 6.99594 13.6654C10.6826 13.6654 13.6693 10.6787 13.6693 6.9987C13.6693 3.3187 10.6826 0.332031 6.99594 0.332031ZM9.66927 9.66537C9.6076 9.72717 9.53434 9.7762 9.45369 9.80965C9.37304 9.84311 9.28658 9.86033 9.19927 9.86033C9.11196 9.86033 9.0255 9.84311 8.94485 9.80965C8.86421 9.7762 8.79095 9.72717 8.72927 9.66537L6.53594 7.47203C6.47295 7.41029 6.42283 7.33666 6.3885 7.25541C6.35417 7.17416 6.3363 7.0869 6.33594 6.9987V4.33203C6.33594 3.96536 6.63594 3.66536 7.0026 3.66536C7.36927 3.66536 7.66927 3.96536 7.66927 4.33203V6.72537L9.66927 8.72537C9.92927 8.98537 9.92927 9.40537 9.66927 9.66537Z" fill="#262C5B"/>
          </svg>
          <span className="overline ml-3" style={{color:"#262C5B"}}>2 months</span>
          </div>
          <div className="mb-2">
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.666504 2.62255C0.666504 2.1039 0.872535 1.6065 1.23927 1.23976C1.60601 0.873023 2.10341 0.666992 2.62206 0.666992H13.3776C13.8963 0.666992 14.3937 0.873023 14.7604 1.23976C15.1271 1.6065 15.3332 2.1039 15.3332 2.62255V4.08922H0.666504V2.62255Z" fill="#262C5B"/>
          <path d="M0.666504 5.06738H15.3332V9.46738C15.3332 9.98603 15.1271 10.4834 14.7604 10.8502C14.3937 11.2169 13.8963 11.4229 13.3776 11.4229H2.62206C2.10341 11.4229 1.60601 11.2169 1.23927 10.8502C0.872535 10.4834 0.666504 9.98603 0.666504 9.46738V5.06738ZM11.4221 8.97849C11.2924 8.97849 11.168 9.03 11.0764 9.12169C10.9847 9.21337 10.9332 9.33772 10.9332 9.46738C10.9332 9.59704 10.9847 9.7214 11.0764 9.81308C11.168 9.90476 11.2924 9.95627 11.4221 9.95627H13.3776C13.5073 9.95627 13.6316 9.90476 13.7233 9.81308C13.815 9.7214 13.8665 9.59704 13.8665 9.46738C13.8665 9.33772 13.815 9.21337 13.7233 9.12169C13.6316 9.03 13.5073 8.97849 13.3776 8.97849H11.4221Z" fill="#262C5B"/>
          </svg>
          <span className="overline ml-3" style={{color:"#262C5B"}}>Free</span>
          </div>
          <div className="">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#E2E8F0" stroke="white"/>
          </svg>
          <span className="overline ml-3" style={{color:"#262C5B"}}>Active</span>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div> */}
      </div>
      </div>
    </div>
  );
}