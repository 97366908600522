import React, {useState, useEffect} from "react"; // , useEffect
import "./../authentication.css";
import "./index.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
// import Location from "../../../Utils/Location";
// import industries2 from "../../Lists/Industries2.json";
// import Multiselect from "multiselect-react-dropdown";
// import Axios from "axios";
// import { toast } from "react-toastify";
// import ClearIcon from "@mui/icons-material/Clear";
// import { useNavigate } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";
// import OTPService from "../../../Utils/OTP";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Form from "./form";
import Step1 from "./Regsteps/Step1";
import Step2 from "./Regsteps/Step2";
import Step3 from "./Regsteps/Step3";

import Navbar from "../../../Layout/Navbar/Navbar";
import { useAuth } from "../../../Utils/Auth";
import NotFoundPage from "./../../404Pages";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import industries2 from "../../Lists/Industries2.json";

const Register_investor = () => {
  
  const { user } = useAuth();
  const navigate = useNavigate();

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Step1
          profilePic={profilePic}
          setProfilePic={setProfilePic}
          islogoloading={islogoloading}
          setislogoloading={setislogoloading}
          handlePicUpload={handlePicUpload}
          linkedProfile={linkedProfile}
          setlinkedProfile={setlinkedProfile}
          category={category}
          setcategory={setcategory}
          jobrole={jobrole}
          setJobrole={setJobrole}
          bio={bio}
          setbio={setbio}
          country={country}
          setcountry={setcountry}
          state={state}
          setstate={setstate}
          city={city}
          setcity={setcity}
          states={states}
          setstates={setstates}
          cities={cities}
          setcities={setcities}
          options={options}
          fund={fund}
          setfund={setfund}
          investedIndustry={investedIndustry}
          setinvestedIndustry={setinvestedIndustry}
          investedmodel={investedmodel}
          setinvestedmodel={setinvestedmodel}
          investedstage={investedstage}
          setinvestedstage={setinvestedstage}
          amount={amount}
          setamount={setamount}
          />
        );

      case 1:
        return (
          <Step2
          options={options}
          interestedindustry={interestedindustry}
          addInputEvent3={addInputEvent3}
          interestedmodel={interestedmodel}
          setinterestedmodel={setinterestedmodel}
          interestedstage={interestedstage}
          setinterestedstage={setinterestedstage}
          chequsize={chequsize}
          setchequsize={setchequsize}
          investmentcriteria = {investmentcriteria}
          handleMultiselect={handleMultiselect}
          support={support}
          handleMultiselectsupport={handleMultiselectsupport}
          enablingtechnologies={enablingtechnologies}
          handleMultiselecttech={handleMultiselecttech}
          showother={showother}
          otherenabling={otherenabling}
          setotherenabling={setotherenabling}
          



          // experiencetime = {experiencetime}
          // setexperiencetime = {setexperiencetime}
          // experience = {experience}
          // setExperience = {setExperience}
          // preferredtime = {preferredtime}
          // setpreferredtime = {setpreferredtime}
          // motivation = {motivation}
          // setmotivation = {setmotivation}
          // othermotivation = {othermotivation}
          // setothermotivation = {setothermotivation}
          />
        );

      case 2:
        return (
          <Step3
          handleIDUpload={handleIDUpload}
          setPhotoID={setPhotoID}
          photoId = {photoId} 
          phone={phone}
          setPhone={setPhone}
          setphoneVerified={setphoneVerified}
          idType ={idType}
          setIDType = {setIDType}
          isdocuploading ={isdocuploading}
          setisdocuploading = {setisdocuploading}
          />
        );
    }
  }

  // STEP 1
  const [profilePic, setProfilePic] = useState(null);
  const [islogoloading, setislogoloading] = useState(false);
  const [linkedProfile, setlinkedProfile] = useState("");
  const [category, setcategory] = useState("");
  const [jobrole, setJobrole] = useState("");
  const [bio, setbio] = useState("");
  const [country, setcountry] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [states, setstates] = useState(null);
  const [cities, setcities] = useState(null);
  const [options] = useState(industries2);

  
  const [fund, setfund] = useState("");
  const [investedIndustry, setinvestedIndustry] = useState([]);
  const [investedmodel, setinvestedmodel] = useState("");
  const [investedstage, setinvestedstage] = useState("");
  const [amount, setamount] = useState("");


  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg"
    ) {
      toast.error("Upload jpg/png/jpeg File!", { position: "bottom-right" });
      return;
    }
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!");
      return;
    }
    const formdata = new FormData();
    formdata.append("profilepic", image);
    setislogoloading(true);
    Axios({
      method: "post",
      url: " /api/investor/profilepic",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setProfilePic(data.location);
        setislogoloading(false);
        toast.success("Profile Image upload");
      })
      .catch(() => {
        toast.error("Error in upload");
        setislogoloading(false);
      });
  };


  // STEP 2
  const [interestedindustry, setinterestedindustry] = useState([]);
  const [interestedmodel, setinterestedmodel] = useState("");
  const [interestedstage, setinterestedstage] = useState("");
  const [chequsize, setchequsize] = useState("");
  // const [motivation, setmotivation] = useState("");
  // const [othermotivation, setothermotivation] = useState("");
  // const [experiencetime, setexperiencetime] = useState("");
  // const [experience, setExperience] = useState("");
  // const [preferredtime, setpreferredtime] = useState("");
  const [investmentcriteria, setinvestmentcriteria] = useState([
    { i: 0, name: "Conducive Macro Environment and Industry Outlook", isSelected: false },
    { i: 1, name: "Clear compelling need for the product/service established.", isSelected: false },
    { i: 2, name: "Superiority of the solution",  isSelected: false, },
    { i: 3, name: "Addressable Market Size & Growth.", isSelected: false },
    { i: 4, name: "Ability to reach the addressable market", isSelected: false },
    { i: 5, name: "Unit economics established", isSelected: false },
    { i: 6, name: "Operational efficiencies built in (Built to scale)", isSelected: false },
    { i: 7, name: "Commitment Personality and ability of the founding team", isSelected: false },
    { i: 8, name: "Track record of the founding team", isSelected: false },
    { i: 9, name: "Business plan", isSelected: false },
    { i: 10, name: "Effect of the presentation start-up company of the founding team", isSelected: false },
    { i: 11, name: "Awards and recognition", isSelected: false },
    { i: 12, name: "Existing investors", isSelected: false },
    { i: 13, name: "Valuation", isSelected: false },
    { i: 14, name: "Due Diligence process", isSelected: false },
    { i: 15, name: "Online/PR presence of the start-up company", isSelected: false },
  ]);
  const [support, setsupport] = useState([
    { i: 0, name: "Help with connects to drive business/build efficiencies", isSelected: false },
    { i: 1, name: "Help with connects for next stage investment", isSelected: false },
    { i: 2, name: "Strategic advisory support (refining existing and generate new business ideas modelling of competition strategy choice of market entry strategy)",  isSelected: false, },
    { i: 3, name: "Operational advice and support to business processes (Marketing and sales human resources management Research and development production procurement)", isSelected: false },
  ]);
  const [enablingtechnologies, setenablingtechnologies] = useState([
    {
      i: 0,
      name: "Chemical processes (e.g. biofuels and biochemicals, chemical manufacturing, solvent/catalyst, etc.)",
      isSelected: false,
    },
    {
      i: 1,
      name: "Cognitive technologies, AI, Analytics and Machine Learning, Big Data and Autonomy",
      isSelected: false,
    },
    {
      i: 2,
      name: "Advanced materials and nanotech (e.g. graphenes, biomaterials, nanocomposites, etc.)",
      isSelected: false,
    },
    {
      i: 3,
      name: "Internet of Things, Sensors and Instrumentation, Geomatics & GIS",
      isSelected: false,
    },
    {
      i: 4,
      name: "Synthetic biology/Genomics/Biotech/bioengineering/bio-processing",
      isSelected: false,
    },
    { i: 5, name: "Financial Model", isSelected: false },
    {
      i: 6,
      name: "Advanced manufacturing (e.g. 3D printing, etc.)",
      isSelected: false,
    },
    {
      i: 7,
      name: "Other - Please specify",
      isSelected: false,
    },
  ]);
  const [showother, setshowother] = useState(false);
  const [otherenabling, setotherenabling] = useState("");

  
  const handleMultiselecttech = (e, name, i) => {
    let newArray = [];
    if (i === 7) {
      setshowother(!showother);
    }
    enablingtechnologies.map((v, index) => {
      if (index === i) {
        newArray.push({ i, name, isSelected: e.target.checked });
      } else newArray.push(v);
    });
    setenablingtechnologies(newArray);
  };
  const addInputEvent3 = (e) => {
    setinterestedindustry(e.map((i) => i.label));
  };
  const handleMultiselect = (e, name, i) => {
    let newArray = [];
    investmentcriteria.map((v, index) => {
      if (index === i) {
        newArray.push({ i, name, isSelected: e.target.checked });
      } else newArray.push(v);
    });
    setinvestmentcriteria(newArray);
  };
  const handleMultiselectsupport = (e, name, i) => {
    let newArray = [];
    support.map((v, index) => {
      if (index === i) {
        newArray.push({ i, name, isSelected: e.target.checked });
      } else newArray.push(v);
    });
    setsupport(newArray);
  };

  useEffect(()=>{
    console.log(enablingtechnologies);
  });

  // STEP 3
  const [phone, setPhone] = useState(null);
  const [phoneVerified, setphoneVerified] = useState(false);
  const [photoId, setPhotoID] = useState("");
  const [idType, setIDType] = useState("");
  const [isdocuploading, setisdocuploading] = useState(false);

  const handleIDUpload = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    if (
      image.type !== "image/jpg" &&
      image.type !== "image/png" &&
      image.type !== "image/jpeg" &&
      image.type !== "application/pdf"
    ) {
      toast.error("Only Image And PDF files are allowed!", {
        position: "bottom-right",
      });
      return;
    }
    const formdata = new FormData();
    formdata.append("photoID", image);
    setisdocuploading(true);
    Axios({
      method: "post",
      url: " /api/upload/photoID",
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        if (data) {
          const filename = {
            docType: idType,
            downloadUrl: data.location,
          };
          setPhotoID(filename);
          // setProfile({
          //   ...profile,
          //   photoId: filename,
          // });
        } else {
          toast.error("Error in upload", { position: "bottom-right" });
        }
        setisdocuploading(false);
        toast.success("Photo ID Uploaded", { position: "bottom-right" });
      })
      .catch(() => {
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  
  const [terms, setterms] = useState(true);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!terms) {
      toast.error("Please agree the Terms & Conditions", {
        position: "bottom-right",
      });
      return;
    }
    // console.log(phoneVerified);
    if (!phoneVerified) return toast.warning("Verify your Phone Number");
    if(
      // linkedProfile.length === 0 || 
      category.length === 0 ||
      fund.length === 0 ||
      jobrole.length === 0 || 
      bio.length === 0 ||
      investedIndustry.length === 0 ||
      investedmodel.length === 0 || 
      investedstage.length === 0 ||
      amount.length === 0 
      )
      return toast.warning("Personal details missing");

    if (profilePic === null) return toast.warning("Upload your image");
    if (country.length === 0) return toast.warning("Select Country");
    if (states && states.length > 0 && (!state || state.length === 0))
      return toast.warning("Select state ");
    else if (cities && cities.length > 0 && (!city || city.length === 0))
      return toast.warning("Select city ");
    // else if (interest.length === 0)
    //   return toast.warning("No industries selected");
    // if (photoId === null) return toast.warning("Upload your photoid");
    // if (grossValue.length === 0)
    //   return toast.warning("Enter current fund size");
    // if (businessmodel.length === 0)
    //   return toast.warning("Enter Business model");
    // if (investortype.length === 0) return toast.warning("Select investor type");
    // if (stage.length === 0) return toast.warning("Select stage");
    // if (chequesize.length === 0) return toast.warning("Enter Cheque size");


    if(
      interestedindustry.length === 0 || 
      interestedmodel.length === 0 ||
      interestedstage.length === 0 ||
      chequsize.length === 0 
      )
      return toast.warning("Interests missing");

    if (showother) {
      enablingtechnologies.push({
        i: 8,
        name: otherenabling,
        isSelected: true,
      });
    }

    // if(
    //   enablingtechnologies.length === 0
    //   )
    //   return toast.warning("enablingtechnologies missing");
    Axios({
      method: "post",

      url: "/api/investor/onboard",

      data: {
        profilePic,
        linkedProfile,
        investortype: category,
        fund,
        jobrole,
        bio,
        country,
        state,
        city,
        investedIndustry,
        investedmodel, 
        investedstage,
        amount,
        interestedindustry, 
        interestedmodel,
        interestedstage,
        chequsize,
        investmentcriteria,
        support,
        enablingtechnologies,
        // investedValue: grossValue,
        photoId,
        phone,
        // investortype,
        // businessmodel,
        // stage,
        // chequesize,
        // enablingtechnologies,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Investor registered");
          navigate("/thankyou");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };




  if (!user) return <NotFoundPage />;

  return (
    <>
      <div className="regis">
        <Navbar />

        <div className="register_page">
          
          {/* -------------STEP 1------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-head w-100">
                  PERSONAL DETAILS
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                  About yourself
                  </p>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead w-100">
                    INTERESTS 
                  </a>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                  DOCUMENTS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part1">{getStepContent(0)}</div>
              </div>
            </div>
          </div>

          
          {/* -------------STEP 2------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 pb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead w-100">
                  PERSONAL DETAILS
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-head w-100">
                    INTERESTS 
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                  About your investment interests
                  </p>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-sidehead w-100">
                  DOCUMENTS
                  </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part2">{getStepContent(1)}</div>
              </div>
            </div>
          </div>

          
          {/* -------------STEP 3------------------ */}
          <div className="container pt-5">
            <div className="row mt-5 pt-5 mb-5 d-flex align-items-center">
              <div className="col-12 col-md-4">
              <div className="register_page_sideborder">
                  <div className="mb-16">
                  <a href="#part1" className="register_page_sideborder-sidehead w-100">
                  PERSONAL DETAILS
                  </a>
                  </div>
                  <div className="mb-16">
                  <a href="#part2" className="register_page_sideborder-sidehead w-100">
                    INTERESTS 
                  </a>
                  </div>
                  <div>
                  <a href="#part3" className="register_page_sideborder-head w-100">
                  DOCUMENTS
                  </a>
                  <p className="register_page_sideborder-sidehead-active m-0">
                  Verification
                  </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-1"></div> */}
              <div className="col-12 col-md-8">
                <div className="register_page_form" id="part3">{getStepContent(2)}</div>
              </div>
            </div>
          </div>

        {/* -------------REGISTER------------------ */}
        <div className="container mb-40">
          <div className="row">
            <div className="col-12 col-md-4">
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex mb-14" style={{ marginTop: "163px" }}>
                <div className="">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM9 5V7H11V5H9Z" fill="#989898"/>
                  </svg>
                </div>
                <div className="">
                  <p style={{marginLeft:"6px"}}>
                  All the documents submitted here will be treated as strictly confidential and will not be used/disclosed anywhere
                  </p>
                </div>

              </div>
                <div
                  className="w-100"
                >
                  <label
                    htmlFor="terms_condition"
                    className="text-dark text_align_start"
                  >
                    <Checkbox
                      defaultChecked
                      onClick={() => setterms(!terms)}
                      sx={{
                        color: `#262C5B`,
                        "&.Mui-checked": {
                          color: `#262C5B`,
                        },
                      }}
                      className="mr-8"
                    />
                    By signing up, you agree to Zefyron Incubation&apos;s{" "}
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      rel="nonreferrer"
                      className="text-dark"
                    >
                      Terms & Conditions{" "}
                    </Link>
                  </label>
                  <div className="w-100">
                  <button
                    onClick={(e) => handleOnSubmit(e)}
                    className="gradient_CTA"
                    style={{ width: "50%" }}
                  >
                    {" "}
                    Register{" "}
                  </button>
                  </div>
                </div>
            </div>
          </div>
        </div>

        </div>
      </div>
    </>
  );

  // return (
  //   <div className="row mt-3">
  //     <div className="col-12 col-md-2"></div>
  //     <div className="col-12 col-md-8 p-5">
  //       <div className="auth_upload">
  //         {profilePic ? (
  //           <>
  //             <img src={profilePic} alt="logo" className="companyLogo" />
  //             <p
  //               className="p_small_black mt-1"
  //               style={{ cursor: "pointer" }}
  //               onClick={() => setProfilePic(null)}
  //             >
  //               Delete
  //             </p>
  //           </>
  //         ) : (
  //           <>
  //             {islogoloading ? (
  //               <CircularProgress style={{ color: "black" }} />
  //             ) : (
  //               <>
  //                 <label htmlFor="clogo">
  //                   <div className="com_logo_">Upload</div>
  //                   <input
  //                     type="file"
  //                     accept="image/*"
  //                     name="profile_pic"
  //                     id="clogo"
  //                     onChange={(e) => handlePicUpload(e)}
  //                   />
  //                 </label>
  //                 <p className="p_small_black mt-1">Profile Photo</p>
  //               </>
  //             )}
  //           </>
  //         )}
  //       </div>
  //       <form onSubmit={handleOnSubmit} className="row">

  //         <div className="d-flex justify-content-center w-100 mt-3">
  //           <button type="submit" className="btn">
  //             {" "}
  //             Submit{" "}
  //           </button>
  //         </div>
  //       </form>
  //     </div>
  //     <div className="col-12 col-md-2"></div>
  //   </div>
};

export default Register_investor;
